<template>
  <div>
    <report-section-title>Acciones para mejorar</report-section-title>

    <div class="text-container">
      <h1>Impulsando el desempeño y la motivación</h1>
      <br />
      <h3>Sección clave para el desarrollo como líder:</h3>
      <p>
        En esta sección, tus colaboradores han tenido la voz para seleccionar,
        entre un conjunto de acciones específicas, las 3 acciones que cada uno
        considera más relevantes que tú como líder puedes realizar para
        contribuir al impulso del desempeño y la motivación de tu personal.
      </p>
      <h3>Análisis de las prioridades:</h3>
      <ul>
        <li>
          <strong>Frecuencia: </strong>Representa la cantidad de colaboradores
          que han escogido cada acción, destacando las opciones más populares
          entre el equipo.
        </li>
        <li>
          <strong>Porcentaje: </strong>Indica la importancia relativa de cada
          acción, en base al total de participantes en la evaluación.
        </li>
      </ul>
      <br />
      <h3>Aprovechando las valiosas perspectivas:</h3>
      <p>
        La información recopilada en esta sección te brinda una visión
        invaluable de las prioridades y expectativas de tu personal. Al analizar
        la frecuencia y el porcentaje de cada acción, puedes:
      </p>
      <ul>
        <li>
          <strong>Identificar las áreas de mayor impacto: </strong>Enfócate en
          las acciones que han sido seleccionadas por un mayor número de
          colaboradores, ya que representan las necesidades y deseos más comunes
          del equipo.
        </li>
        <li>
          <strong>Comprender la importancia relativa: </strong>Presta atención
          al porcentaje de cada acción para comprender su relevancia en el
          contexto general de las motivaciones del equipo.
        </li>
        <li>
          <strong>Desarrollar planes de acción estratégicos: </strong>Utiliza
          los resultados como base para diseñar planes de acción específicos y
          tangibles que aborden las áreas priorizadas por tus colaboradores.
        </li>
      </ul>
      <br />
      <h3>Fomentando el diálogo y la colaboración:</h3>
      <p>
        Comparte los resultados de esta sección con tu equipo y genera un
        espacio de diálogo abierto y honesto. Conversen sobre las acciones
        seleccionadas, comprendan las perspectivas detrás de cada elección y
        trabajen en conjunto para desarrollar soluciones creativas que impulsen
        el desempeño y la motivación de todos.
      </p>
      <h3>Recuerda:</h3>
      <ul>
        <li>
          La clave está en escuchar atentamente las voces de tus colaboradores y
          utilizar sus valiosos aportes para convertirte en un líder aún más
          efectivo.
        </li>
        <li>
          Al tomar en cuenta las prioridades del equipo, podrás crear un
          ambiente de trabajo más positivo, productivo y motivador.
        </li>
      </ul>
      <br />
      <h3>Compromiso con la mejora continua:</h3>
      <p>
        Esta sección representa una oportunidad única para el crecimiento y el
        desarrollo como líder. Al analizar las acciones seleccionadas por tu
        equipo y tomar medidas estratégicas para abordarlas, estarás demostrando
        tu compromiso con la mejora continua y la creación de un entorno laboral
        excepcional.
      </p>
      <h1>Tus Resultados</h1>
    </div>
    <table>
      <thead>
        <tr>
          <th style="width: 50%; text-align: center">Acciones</th>
          <th style="width: 25%; text-align: center">Frecuencia</th>
          <th style="width: 25%; text-align: center">Porcentaje</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, aspect) in filteredAspects" :key="aspect">
          <td>{{ getAspectLabel(aspect) }}</td>
          <td style="text-align: center">{{ data.frequency }}</td>
          <td style="text-align: center">{{ data.percentage }}%</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { applicant } from "@/model/one-to-one/questions.js";
import assessmentMixin from "@/mixins/assessmentMixin";

export default {
  name: "AspectTable",
  mixins: [assessmentMixin],
  props: {
    answerCounts: {
      type: Object,
      required: true
    },
    applicantsCount: {
      type: Number,
      required: true
    }
  },
  computed: {
    filteredAspects() {
      const totalApplicants = this.applicantsCount;
      return Object.entries(this.answerCounts.aspects).reduce(
        (obj, [aspect, count]) => {
          const frequency = count;
          const percentage = ((count / totalApplicants) * 100).toFixed(2);
          return { ...obj, [aspect]: { frequency, percentage } };
        },
        {}
      );
    }
  },
  methods: {
    calculatePercentage(count) {
      const applicantsCount = this.answerCounts.applicantsCount || 0;
      if (applicantsCount === 0) return 0;
      return ((count / applicantsCount) * 100).toFixed(2);
    },
    getAspectLabel(aspectKey) {
      const aspect = applicant.find(item => item.key === "aspects");
      if (aspect) {
        const item = aspect.items.find(item => item.id === parseInt(aspectKey));
        return item ? item.text : "";
      }
      return "";
    }
  }
};
</script>

<style scoped>
table {
  width: 90%;
  margin: auto;
  border-collapse: collapse;
}

.text-container {
  width: 90%;
  text-align: justify;
  margin: 0 auto;
}

h1 {
  text-align: center;
}

h2 {
  width: 90%;
  margin: auto;
  padding: 10px;
  border: 1px solid #dddddd;
  text-align: center;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.table-container {
  margin-bottom: 20px;
}

div {
  list-style-type: none;
  text-align: justify;
}
</style>
