import { render, staticRenderFns } from "./FactorBadge.vue?vue&type=template&id=aca1a57c&scoped=true"
import script from "./FactorBadge.vue?vue&type=script&lang=js"
export * from "./FactorBadge.vue?vue&type=script&lang=js"
import style0 from "./FactorBadge.vue?vue&type=style&index=0&id=aca1a57c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aca1a57c",
  null
  
)

export default component.exports