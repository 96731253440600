<template>
  <div>
    <GChart type="BarChart" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  name: "LeadershipDevelopmentChart",
  components: {
    GChart
  },
  props: {
    applicantCount: {
      type: Object,
      required: true
    }
  },
  computed: {
    chartData() {
      const data = [
        ["Sistema", "Puntuacion"],
        ["Sistema Emocional", this.applicantCount.emotionsum],
        ["Sistema Mental", this.applicantCount.mindsum],
        ["Sistema Corporal", this.applicantCount.behaviorsum]
      ];
      return data;
    },
    chartOptions() {
      return {
        legend: "none",
        title: "",
        viewWindow: { min: 0, max: 35 },
        hAxis: { title: "", minValue: 0, maxValue: 35 },
        vAxis: { title: "" },
        scaleType: "mirrorLog",
        bars: "horizontal"
      };
    }
  }
};
</script>
