<template>
  <v-container v-if="!processingRequest">
    <v-row>
      <v-col cols="3" class="hidden-sm-and-down" />
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="primary-title text-h6 secondary primary--text">
            Instrucciones
          </v-card-title>
          <v-card-text class="text-justify pt-2 pb-2">
            <start-text-disc v-if="modelType === 'DISC'" />
            <start-text-pgc v-else-if="modelType === 'PGC'" />
            <start-text-oto v-else-if="modelType === 'ONE_TO_ONE'" />
            <start-text-fot v-else-if="modelType === 'F1_TEAM'" />
            <start-text-slt v-else-if="modelType === 'SELF_LEADERSHIP'" />
            <start-text-rdisc v-else-if="modelType === 'ROLE_DISC'" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3" class="hidden-sm-and-down" />
      <v-col cols="3" class="hidden-sm-and-down" />
      <v-col cols="12" md="6" class="align-content-center pt-0">
        <v-btn
          block
          class="secondary--text"
          color="primary"
          large
          :to="nextUrl"
        >
          Continuar
        </v-btn>
      </v-col>
      <v-col cols="3" class="hidden-sm-and-down" />
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";

import assessmentMixin from "@/mixins/assessmentMixin";
import StartTextDISC from "./components/disc/StartTextDISC.vue";
import StartTextPGC from "./components/pgc/StartTextPGC.vue";
import StartTextOTO from "./components/one-to-one/StartTextOTO.vue";
import StartTextFOne from "./components/fone-team/StartTextFOne.vue";
import StartTextSelfLead from "./components/self-leadership/StartTextSelfLead.vue";
import StartTextRoleDISC from "./components/role-disc/StartTextRoleDISC.vue";

export default defineComponent({
  mixins: [assessmentMixin],
  components: {
    "start-text-disc": StartTextDISC,
    "start-text-pgc": StartTextPGC,
    "start-text-oto": StartTextOTO,
    "start-text-fot": StartTextFOne,
    "start-text-slt": StartTextSelfLead,
    "start-text-rdisc": StartTextRoleDISC
  },
  created() {
    this.loadAssessment()
      .then(async assessment => {
        const { status, modelType, results } = assessment;

        // Verificar si la prueba ya fue aplicada
        if (
          status === "SUBMITTED" ||
          (status === "SENT" &&
            results &&
            ((["ONE_TO_ONE"].includes(modelType) &&
              results[this.applicantId || "assessed"]) ||
              (["F1_TEAM"].includes(modelType) &&
                results[this.assessedId || "applicant"])))
        ) {
          this.$store.dispatch("clearAssessment");
          this.showErrorAndLeave("La prueba ya fue aplicada");
        }
      })
      .catch(_ => {
        this.$store.dispatch("clearAssessment");
        this.showErrorAndLeave(
          "Ha ocurrido un error leyendo los datos de la prueba"
        );
      });
  },
  computed: {
    nextUrl() {
      if (this.modelType === "ONE_TO_ONE" && !!this.applicantId) {
        return this.assessmentQuestionsURL;
      } else if (this.modelType === "F1_TEAM" && !!this.assessedId) {
        return this.assessmentQuestionsURL;
      } else {
        return this.assessmentFormURL;
      }
    }
  }
});
</script>

<style scoped>
.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 0.87);
}
</style>
