import { render, staticRenderFns } from "./FOneQuestions.vue?vue&type=template&id=4afdf03f&scoped=true"
import script from "./FOneQuestions.vue?vue&type=script&lang=js"
export * from "./FOneQuestions.vue?vue&type=script&lang=js"
import style0 from "./FOneQuestions.vue?vue&type=style&index=0&id=4afdf03f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4afdf03f",
  null
  
)

export default component.exports