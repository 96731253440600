<template>
  <div>
    <GChart type="BarChart" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  name: "LeadershipDevelopmentChart",
  components: {
    GChart
  },
  props: {
    assessedCounts: {
      type: Object,
      required: true
    }
  },
  computed: {
    chartData() {
      const data = [
        ["Aspectos", "Puntuacion"],
        ["Tareas", this.assessedCounts.job],
        ["Motivación", this.assessedCounts.motivation],
        ["Relaciones", this.assessedCounts.relationships]
      ];
      return data;
    },
    chartOptions() {
      return {
        legend: "none",
        title: "",
        viewWindow: { min: 0, max: 35 },
        hAxis: { title: "", minValue: 0, maxValue: 35 },
        vAxis: { title: "" },
        scaleType: "mirrorLog",
        bars: "horizontal"
      };
    }
  }
};
</script>

<style scoped>
/* Aquí puedes incluir estilos específicos para este componente */
</style>
