export default [
  {
    id: "ready_to_know",
    label: "¿Listo para conocer tus resultados?"
  },
  {
    id: "four_dimensions",
    label: "Cuatro dimensiones del comportamiento normal"
  },
  {
    id: "behavior_style_comparative",
    label: "Comparativo de los estilos de comportamiento"
  },
  {
    id: "natural_style",
    label: "Estilo Natural"
  },
  {
    id: "highlighted_talents",
    label: "Talentos destacados",
    reportType: "EXTENDED_DISC"
  },
  {
    id: "prevailing_trends",
    label: "Tendencias predominantes",
    reportType: "EXTENDED_DISC"
  },
  {
    id: "possible_barriers",
    label: "Oportunidades de desarrollo",
    reportType: "EXTENDED_DISC"
  },
  {
    id: "communication_style",
    label: "Estilo de comunicación"
  },
  {
    id: "favorable_context",
    label: "Contexto favorable"
  },
  {
    id: "adaptive_style",
    label: "Respuesta Adaptativa"
  },
  {
    id: "advices_for_good_communication",
    label: "Consejos para una buena comunicación"
  },
  {
    id: "naural_style_descriptors",
    label: "Descriptores del Estilo Natural"
  },
  {
    id: "factors_power",
    label: "Poder de los Factores",
    reportType: "EXTENDED_DISC"
  },
  {
    id: "factors_power",
    label: "Poder de los Colores",
    reportType: "SIMPLIFIED_DISC"
  },
  {
    id: "personal_graphic",
    label: "Mapa de Comportamiento"
  },
  {
    id: "strengthening_project",
    label: "Proyecto de Fortalecimiento"
  }
];
