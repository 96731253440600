import authService from "@/services/authService";

export default {
  state: () => ({
    user: null,
    accountInfo: null,
    redirectRoute: null
  }),
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setRedirectRoute(state, route) {
      state.redirectRoute = route;
    },
    setAccountInfo(state, accountInfo) {
      state.accountInfo = accountInfo;
    },
    setPurchaseRequired(state, purchaseRequired) {
      state.accountInfo.purchaseRequired = purchaseRequired;
    }
  },
  actions: {
    recoverPassword({}, payload) {
      return new Promise((resolve, reject) => {
        authService
          .changePassword(payload.code, payload.password)
          .then(() => resolve())
          .catch(error => reject(error));
      });
    },
    changePasswordLogin({}, payload) {
      return new Promise((resolve, reject) => {
        authService
          .changePasswordLogin(payload.password, payload.newPassword)
          .then(() => resolve())
          .catch(error => reject(error));
      });
    },
    verifyCodeChangePassword({}, payload) {
      return new Promise((resolve, reject) => {
        authService
          .verifyChangePasswordCode(payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => reject(error));
      });
    },
    forgotPassword({}, email) {
      return new Promise((resolve, reject) => {
        authService
          .forgotPassword(email)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    signUserIn({ commit }, payload) {
      return new Promise((resolve, reject) => {
        authService
          .signIn(payload.email, payload.password)
          .then(response => {
            commit("setUser", response.user);
            resolve();
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    signUserOut({ commit }) {
      return new Promise((resolve, reject) => {
        authService
          .signOut()
          .then(() => {
            commit("setUser", null);
            commit("setAccountInfo", null);
            resolve();
          })
          .catch(error => reject(error));
      });
    },
    signUserUp({ commit }, data) {
      return new Promise((resolve, reject) => {
        authService
          .signUp(data)
          .then(user => {
            commit("setUser", user);
            resolve();
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    saveBillingInfo({}, billingInfo) {
      return authService.saveBillingInfo(
        this.getters.accountInfo.id,
        billingInfo
      );
    },
    setRedirectRoute({ commit }, route) {
      commit("setRedirectRoute", route);
    },
    clearRedirectRoute({ commit }) {
      commit("setRedirectRoute", null);
    },
    getAccountInfo({ commit, getters }) {
      return new Promise((resolve, reject) => {
        const user = getters.user;
        if (user) {
          authService
            .getAccountInfo(user.id)
            .then(accountInfo => {
              commit("setAccountInfo", accountInfo);
              resolve(accountInfo);
            })
            .catch(error => reject(error));
        } else {
          commit("setAccountInfo", null);
          resolve(null);
        }
      });
    },
    finishCheckout({ dispatch }) {
      return dispatch("getAccountInfo");
    }
  },
  getters: {
    isUserAuthenticated(state) {
      const currentUser = state.user;
      return currentUser !== null && currentUser !== undefined;
    },
    user(state) {
      return state.user;
    },
    redirectRoute(state) {
      return state.redirectRoute;
    },
    accountInfo(state) {
      return state.accountInfo;
    },
    purchaseRequired(state) {
      return state.accountInfo && state.accountInfo.purchaseRequired;
    },
    multiuserEnabled(state) {
      const { accountInfo } = state;
      return (
        accountInfo && accountInfo.features && accountInfo.features.multiuser
      );
    }
  }
};
