<template>
  <div>
    <v-row class="graphNatural" justify="center">
      <v-col
        align="center"
        cols="3"
        md="2"
        v-for="(data, factor) in factors"
        :key="factor"
      >
        <vertical-bar
          :number="values[data.letter].percentage"
          :color="data.color"
        />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col
        align="center"
        cols="3"
        md="2"
        v-for="(_, factor) in factors"
        :key="factor"
      >
        <v-row>
          <v-col cols="3" />
          <v-col cols="6" class="pa-0 mt-2">
            <img
              width="100%"
              :src="require(`@/assets/card-${factor}-es.png`)"
            />
          </v-col>
          <v-col cols="3" />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import factors from "@/model/disc/factors";

import VerticalBar from "./VerticalBar.vue";

export default {
  name: "naturalGraph",
  components: {
    "vertical-bar": VerticalBar
  },
  props: {
    values: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      factors
    };
  }
};
</script>
<style scoped>
.graphNatural {
  display: flex;
  align-items: flex-end;
}
</style>
