<template>
  <div class="card-container">
    <img
      class="elevation-24 card-image"
      :src="require(`@/assets/card-${factor}-es.png`)"
    />
    <div class="white--text font-weight-light text-left card-description pt-2">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardFactor",
  props: {
    factor: {
      type: String,
      required: true
    }
  }
});
</script>

<style scoped>
.card-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.card-description {
  max-width: 12em;
}
.card-image {
  max-width: 12em;
  min-width: 5em;
}
@media print {
  .card-description {
    font-size: 0.9em;
    max-width: 95%;
  }
  .card-image {
    max-width: 95%;
    min-width: 4em;
  }
}
</style>
