import { render, staticRenderFns } from "./CheckoutController.vue?vue&type=template&id=36215042"
import script from "./CheckoutController.vue?vue&type=script&lang=js"
export * from "./CheckoutController.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports