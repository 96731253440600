<template>
  <g>
    <template v-for="(c, index) in data.content">
      <o-octagon-node-circle
        v-if="!!c && c.type === 'circle'"
        :data="{ ...c, label: data.label }"
        :key="`circle-${index}`"
        :highlight="highlight"
        @mouseenter="$emit('mouseenter', $event)"
        @mouseleave="$emit('mouseleave', $event)"
      />

      <o-octagon-node-path
        v-else-if="!!c && c.type === 'path'"
        :data="{ ...c, label: data.label }"
        :key="`path-${index}`"
        :highlight="highlight"
        @mouseenter="$emit('mouseenter', $event)"
        @mouseleave="$emit('mouseleave', $event)"
      />
    </template>
  </g>
</template>

<script lang="js">
import { defineComponent } from "vue";

import OctagonNodeCircle from "./OctagonNodeCircle";
import OctagonNodePath from "./OctagonNodePath";

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    },
    highlight: Boolean
  },
  components: {
    "o-octagon-node-circle": OctagonNodeCircle,
    "o-octagon-node-path": OctagonNodePath
  }
});
</script>
