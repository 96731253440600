<template>
  <!-- <div class="bubble" :style="{ backgroundColor: color }">
    <img class="image" :src="require(`@/assets/${image}`)" />
  </div> -->
  <div class="bubble">
    <img class="image" :src="require(`@/assets/${image}`)" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReportIcon",
  props: {
    image: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false
    }
  }
});
</script>

<style scoped>
.bubble {
  min-width: 1em;
  max-width: 5em;
  min-height: 1em;
  max-height: 5em;
  border-radius: 50px;
  margin: 0em;
}
.image {
  /* position: relative;
  top: -1.7em;
  left: -1.7em; */
  max-height: 5em;
  max-width: 5em;
}
</style>
