<template>
  <div class="px-0 pt-0 pb-4 badge-container">
    <h1
      class="letter"
      :style="{
        backgroundColor: factors[factor].color
      }"
    >
      {{ factors[factor].letter }}
    </h1>
    <h2
      class="badge-title"
      :class="bold ? 'bold' : ''"
      :style="{
        color: fullcolor && factors[factor].color
      }"
    >
      {{ factors[factor].name }}
    </h2>
  </div>
</template>

<script>
import factors from "@/model/disc/factors";

export default {
  name: "FactorBadge",
  props: {
    factor: {
      type: String,
      required: true
    },
    bold: {
      type: Boolean,
      required: false,
      default: false
    },
    fullcolor: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      factors: factors
    };
  }
};
</script>

<style scoped>
.badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.letter {
  width: 1.5em;
  border-radius: 25px;
  color: white;
}
.badge-title {
  text-align: center;
  text-transform: uppercase;
}
.bold {
  font-size: 1.5em;
  font-weight: 700;
}
@media print {
  .letter {
    font-size: 1.3em;
  }
  .badge-title {
    font-size: 1.25em;
  }
}
</style>
