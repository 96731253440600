<template>
  <v-tooltip bottom :activator="activator" :value="visible">
    <template v-slot:activator="{ on, attrs }" v-if="!activator">
      <slot v-bind="attrs" v-on="on"></slot>
      <!-- <v-btn v-bind="attrs" v-on="on">Tooltip</v-btn> -->
    </template>
    <span>{{ value }}</span>
  </v-tooltip>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Tooltip",
  props: {
    value: {
      type: String,
      required: true
    },
    activator: {
      type: String,
      required: false,
      default: undefined
    },
    visible: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {}
});
</script>

<style lang="css" scoped></style>
