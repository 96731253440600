export default [
  {
    key: "beginner",
    label: "Principiantes", //entre 7 y 9 puntos
    range: { min: 7, max: 9 },
    level: 1
  },
  {
    key: "distrustful",
    label: "Desconfiados", //entre 10 y 12 puntos
    range: { min: 10, max: 12 },
    level: 2
  },
  {
    key: "misaligned",
    label: "Desalineados", //entre 13 y 15 puntos
    range: { min: 13, max: 15 },
    level: 3
  },
  {
    key: "unsure",
    label: "Inseguros", //entre 16 y 19 puntos
    range: { min: 16, max: 19 },
    level: 4
  },
  {
    key: "capable",
    label: "Capaces", //entre 20 y 22 puntos
    range: { min: 20, max: 22 },
    level: 5
  },
  {
    key: "bounded",
    label: "Integrados", //entre 23 y 25 puntos
    range: { min: 23, max: 25 },
    level: 6
  },
  {
    key: "innovative",
    label: "Innovadores", //entre 26 y 28 puntos
    range: { min: 26, max: 28 },
    level: 7
  }
];
