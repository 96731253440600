<template>
  <div>
    <div>
      <report-section-title>Acciones</report-section-title>
      <p class="applicants">{{ data.length }} Aplicantes</p>
    </div>
    <div>
      <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
    </div>
    <div>
      <div class="legend">
        <div class="label">
          <div class="p1"></div>
          <p>
            Actúo dándome cuenta de lo que hago y consciente de sus
            consecuencias
          </p>
        </div>
        <div class="label">
          <div class="p1"></div>
          <p>
            Tengo buenos hábitos de salud (alimentación, deporte, sueño,
            relajación)
          </p>
        </div>
        <div class="label">
          <div class="p1"></div>
          <p>Asumo activamente el cambio y la innovación</p>
        </div>
        <div class="label">
          <div class="p1"></div>
          <p>Manejo los retos cotidianos sin que lleguen a producirme estrés</p>
        </div>
        <div class="label">
          <div class="p1"></div>
          <p>
            Aprendo y me adapto rápidamente, aunque sean circunstancias
            difíciles
          </p>
        </div>
        <div class="label">
          <div class="p1"></div>
          <p>Planifico mis metas y persisto hasta lograrlas</p>
        </div>
        <div class="label">
          <div class="p1"></div>
          <p>
            Soy auténtico/a en mi forma de ser incluso cuando me siento
            vulnerable
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";
import questions from "@/model/self-leadership/questions.js";

export default {
  name: "LeaderBehaviorGraph",
  components: {
    GChart
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chartData: this.calculateChartData()
    };
  },
  methods: {
    calculateChartData() {
      const data = [
        [
          "Pregunta",
          "Promedio de Respuesta",
          { role: "style" },
          { role: "annotation" }
        ]
      ];

      // colors for the bars
      const colors = ["#9edbdd"];

      // Itinerate over each question in the behavior category
      const behaviorQuestions = questions.find(
        category => category.key === "behavior"
      );
      behaviorQuestions.items.forEach((question, index) => {
        let sum = 0;
        let count = 0;

        // Itinerate over each result and calculate the average
        this.data.forEach(result => {
          const response = result.results.behavior[index];
          if (!isNaN(response)) {
            sum += response;
            count++;
          }
        });

        // Avergare calculations
        if (count > 0) {
          const avg = sum / count;
          data.push([question, avg, colors[index % colors.length], avg]);
        }
      });

      return data;
    }
  },
  computed: {
    chartOptions() {
      return {
        legend: "none",
        title: "",
        vAxis: { title: "Promedio de Respuesta" },
        hAxis: { title: "Preguntas" },
        bars: "vertical",
        chartArea: { width: "80%", height: "70%" }
      };
    }
  },
  watch: {
    data: {
      handler() {
        this.chartData = this.calculateChartData();
      },
      deep: true
    }
  }
};
</script>

<style scoped>
div {
  font-family: "Roboto", sans-serif;
  background-color: white;
  margin: 0 auto;
}
.applicants {
  font-size: 1rem;
  font-weight: bold;
  text-align: right;
  margin-right: 10%;
  margin-bottom: 0%;
}
.legend {
  display: flex;
  justify-content: space-around;
  width: 75%;
}
.label {
  display: inline-flex;
  margin-right: 10px;
}
.p1 {
  width: 15px;
  height: 15px;
  background-color: #9edbdd;
}
p {
  font-size: 1rem;
  font-weight: bold;
  margin-left: 10px;
}
</style>
