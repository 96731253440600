import messageDialogMixin from "./messageDialogMixin";

export default {
  mixins: [messageDialogMixin],
  data() {
    return {
      assessmentId: null,
      assessment: {}
    };
  },
  created() {
    this.assessmentId = this.$route.params.assessmentId;

    if (this.$store.getters.assessment) {
      this.assessment = this.$store.getters.assessment;
    }

    switch (this.assessment.modelType) {
      case "ONE_TO_ONE":
        if (this.$route.params.applicantId) {
          this.$store.dispatch(
            "setApplicantId",
            this.$route.params.applicantId
          );
        }
        break;
      case "F1_TEAM":
        if (this.$route.params.applicantId) {
          this.$store.dispatch("setAssessedId", this.$route.params.applicantId);
        }
        break;
    }
  },
  computed: {
    applicantId() {
      return this.$store.getters.applicantId;
    },
    assessedId() {
      return this.$store.getters.assessedId;
    },
    assessmentStartURL() {
      return `/assessment/${this.assessmentId}/start`;
    },
    assessmentFormURL() {
      return `/assessment/${this.assessmentId}/form`;
    },
    assessmentQuestionsURL() {
      return `/assessment/${this.assessmentId}/questions`;
    },
    assessmentSubmitURL() {
      return `/assessment/${this.assessmentId}/submit`;
    },
    modelType() {
      return this.assessment ? this.assessment.modelType : null;
    },
    processingRequest() {
      return this.$store.getters.processingRequest;
    }
  },
  methods: {
    async loadAssessment() {
      try {
        const assessment = await this.$store.dispatch(
          "getAssessment",
          this.assessmentId
        );

        this.assessment = assessment;
        return assessment;
      } catch (error) {
        this.$store.dispatch("clearAssessment");
        throw error;
      }
    },
    getAndValidateAssessment() {
      const { assessment } = this.$store.getters;
      if (assessment && assessment.id === this.assessmentId) {
        this.assessment = assessment;
        return true;
      } else {
        this.$store.dispatch("clearAssessment");
        this.showErrorAndLeave(
          "Ha ocurrido un error leyendo los datos de la prueba"
        );
      }
    },
    showErrorAndBack(message) {
      this.assessment = {};
      this.errorDialog(message, () => {
        this.$router.go(-1);
      });
    },
    showErrorAndLeave(message) {
      this.assessment = {};
      this.errorDialog(message, () => {
        window.location = "https://liderlab.org";
      });
    }
  }
};
