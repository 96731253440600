<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>Niveles de madurez</th>
          <th>Equipo</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(level, index) in reversedLevels" :key="index">
          <td>{{ level.level }}</td>
          <td style="text-align: center">
            <span
              v-if="
                assessedByLevel[level.level] !== undefined &&
                assessedByLevel[level.level] !== 0
              "
            >
              {{ level.level === currentLevel ? `(L) ` : ""
              }}{{ assessedByLevel[level.level] }}
            </span>
            <span v-else-if="level.level === currentLevel">(L)</span>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
  </div>
</template>

<script>
import levels from "@/model/fone-team/levels";

export default {
  props: {
    applicantCount: {
      type: Number,
      required: true
    },
    assessedByLevel: {
      type: Object,
      required: true
    }
  },
  computed: {
    levels() {
      return levels;
    },
    reversedLevels() {
      return this.levels.slice().reverse();
    },
    currentLevel() {
      const currentLevel = this.levels.find(
        level =>
          this.applicantCount >= level.range.min &&
          this.applicantCount <= level.range.max
      );
      return currentLevel ? currentLevel.level : null;
    }
  }
};
</script>

<style scoped>
.component-container {
  margin: 30px;
  padding-bottom: 30px;
}
table {
  width: 20%;
  margin: auto;
  border-collapse: collapse;
}

.text-container {
  width: 90%;
  text-align: justify;
  margin: 0 auto;
}

h1 {
  text-align: center;
}

h2 {
  width: 90%;
  margin: auto;
  padding: 10px;
  border: 1px solid #dddddd;
  text-align: center;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

.table-container {
  margin-bottom: 20px;
}

div {
  list-style-type: none;
  text-align: justify;
  margin-bottom: 20px;
}
</style>
