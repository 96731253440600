import store from "@/store/index.js";

export default {
  beforeRouteEnter(to, from, next) {
    const accountInfo = store.getters.accountInfo;
    if (accountInfo && accountInfo.features && accountInfo.features.multiuser) {
      next();
    } else {
      next(vm => {
        vm.errorDialog("No tienes permisos para gestionar usuarios.", () => {
          vm.$router.push("/");
        });
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.isAuthorized) {
      next();
    } else {
      this.errorDialog("No tienes permisos para gestionar usuarios.", () => {
        next(false);
      });
    }
  }
};
