<template>
  <div class="d-none d-sm-block d-print-block pt-8 no-breakable">
    <v-row>
      <v-col
        align="center"
        cols="12"
        class="primary white--text ma-1 table-title"
      >
        {{ title }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="accent white--text ma-1 table-cell"
        v-for="column in columns"
        :key="`header-${column.label.split(' ').join('')}`"
        :cols="column.cols"
      >
        <div
          v-if="headerSize === 'small'"
          :class="{
            xs: $vuetify.breakpoint.xs,
            sm: $vuetify.breakpoint.sm,
            md: $vuetify.breakpoint.md,
            lg: $vuetify.breakpoint.lg,
            xl: $vuetify.breakpoint.xl
          }"
          v-html="column.label"
        />
        <h3 v-else-if="headerSize === 'large'" v-html="column.label" />
      </v-col>
    </v-row>

    <v-row v-for="(row, i) in rows" :key="`row-${i}`">
      <v-col
        class="background darken-1 primary--text ma-1 table-cell"
        v-for="(data, j) in row"
        :key="`data-${i}-${j}`"
        :cols="data.cols"
      >
        {{ data.value }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlanTable",
  props: {
    title: {
      type: String,
      required: false
    },
    /* { label: String, cols: Number, size: "small" (default) | "large" } */
    columns: {
      type: Array,
      required: true
    },
    nRows: {
      type: Number,
      required: false
    },
    /* { value: String, cols: Number} */
    data: {
      type: Array,
      required: false
    },
    headerSize: {
      type: String,
      required: false,
      default: "small"
    }
  },
  computed: {
    rows() {
      if (this.data) {
        return this.data;
      }

      const result = [];
      for (let i = 0; i < this.nRows; i++) {
        result.push([...this.columns]);
      }

      return result;
    }
  }
});
</script>

<style scoped>
.table-title {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  font-size: 1.5em;
  font-weight: 500;
}
.table-cell {
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.xs {
  font-size: 0.5em;
  font-weight: 500;
  color: white !important;
}
.sm {
  font-size: 0.7em;
  font-weight: 500;
  color: white !important;
}
.md {
  font-size: 0.7em;
  font-weight: 500;
  color: white !important;
}
.lg {
  font-size: 0.8em;
  font-weight: 600;
  color: white !important;
}
.xl {
  font-size: 0.8em;
  font-weight: 600;
  color: white !important;
}
@media print {
  .no-breakable {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
</style>
