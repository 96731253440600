<template lang="html">
  <assessment-back-next-card-layout
    title="Este cargo requiere"
    :step="step"
    :steps="steps"
    @next="next"
    @back="back"
  >
    <v-form lazy-validation>
      <v-row class="text-center">
        <v-col
          cols="12"
          v-for="(item, index) in questions[step].attributes"
          :key="index"
        >
          <v-row class="align-center">
            <v-col cols="6" class="subtitle-1 text-left pl-4 py-2">
              {{ item.name }}
            </v-col>

            <v-col xs3 class="text-center py-2">
              <v-btn
                icon
                small
                class="elevation-0"
                @click="selectAttribute(index)"
              >
                <v-icon v-if="answers[step].index === index">
                  fas fa-circle
                </v-icon>
                <v-icon v-else>far fa-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col cols="12" v-if="error" class="red--text text-center pt-3">
          {{ error }}
        </v-col>
      </v-row>
    </v-form>
  </assessment-back-next-card-layout>
</template>

<script>
import { defineComponent } from "vue";

import assessmentMixin from "@/mixins/assessmentMixin";
import assessmentModel from "@/model/roledisc/assessment.js";

export default defineComponent({
  mixins: [assessmentMixin],
  data() {
    return {
      answers: null,
      error: false,
      questions: assessmentModel.questions
    };
  },
  created() {
    if (this.getAndValidateAssessment()) {
      this.answers = this.$store.getters.answers;
      if (!this.answers) {
        this.answers = [];
        for (let i = 0; i < this.steps; i++) {
          this.answers.push({
            index: -1
          });
        }
      }
    }
  },
  computed: {
    step() {
      return this.$store.getters.currentStep;
    },
    steps() {
      return assessmentModel.questions.length;
    }
  },
  methods: {
    back() {
      this.error = false;
      if (this.step == 0) {
        this.$store.dispatch("setAnswers", this.answers);
        this.$router.push(this.assessmentFormURL);
      } else {
        this.$store.dispatch("back");
      }
    },
    isAttributeSelected(index) {
      return this.answers[this.step].index === index;
    },
    isValidSelection() {
      return this.answers[this.step].index >= 0;
    },
    next() {
      if (this.isValidSelection()) {
        if (this.step === this.steps - 1) {
          this.$store.dispatch("setAnswers", this.answers);
          this.$router.push(this.assessmentSubmitURL);
        } else {
          this.$store.dispatch("next");
        }
      } else {
        this.error = "Debe seleccionar las opciones descritas en el enunciado";
      }
    },
    selectAttribute(index) {
      this.error = false;
      if (this.isAttributeSelected(index)) {
        this.answers[this.step].index = -1;
        this.answers[this.step].attribute = null;
      } else {
        this.answers[this.step].index = index;
        this.answers[this.step].attribute =
          this.questions[this.step].attributes[index];
      }
    }
  }
});
</script>

<style lang="css" scoped>
.theme--light.v-sheet {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-icon {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 0.87);
}
</style>
