<template>
  <v-container fluid class="white py-0 px-0" id="printPage">
    <report-header id="ready_to_know" class="topMarginNegative80">
      <template v-slot:data>
        <applicant-data :data="applicantData" />
      </template>

      <template v-slot:controls>
        <v-row justify="end" align="center">
          <v-col cols="4" class="d-flex justify-end pr-sm-10 pr-4">
            <v-btn elevation="2" @click="onPrint">Imprimir</v-btn>
          </v-col>
        </v-row>
      </template>
    </report-header>

    <section-ready-to-know :position="thisRole" />

    <section-behavior-graph :results="results" :position="thisRole" />
    <section-generic-bullet-content
      id="adaptive_style"
      title="Este cargo requiere de:"
      icon="ICONOS-10.png"
      v-if="isValidProfile"
      :content="roleBullets"
    />
    <section-factors-power
      class="pagebreakBefore"
      id="factors_power"
      :data="data"
    />
    <section-personal-graphic
      id="personal_graphic"
      class="pagebreakBefore"
      :data="profile"
    />
  </v-container>
</template>

<script lang="js">
import { defineComponent } from "vue";

import { formatDate } from "@/utils/date";

import ApplicantData from "../common/ApplicantData.vue";
import ReportHeader from "../common/ReportHeader.vue";
import SectionReadyToKnow from "./components/SectionReadyToKnow.vue";
import SectionBehaviorGraph from "./components/SectionBehaviorGraph.vue";
import SectionPersonalGraphic from "./components/SectionPersonalGraphic";
import SectionFactorsPower from "./components/SectionFactorsPower.vue";
import SectionGenericBulletContent from "@/views/reports/common/disc/SectionGenericBulletContent.vue";


import assessmentMixin from "@/mixins/assessmentMixin";

export default defineComponent({
  name: "roleDISCReport",
  mixins: [assessmentMixin],
  components: {
    "applicant-data": ApplicantData,
    "report-header": ReportHeader,
    "section-ready-to-know": SectionReadyToKnow,
    "section-behavior-graph": SectionBehaviorGraph,
    "section-factors-power": SectionFactorsPower,
    "section-personal-graphic": SectionPersonalGraphic,
    "section-generic-bullet-content": SectionGenericBulletContent
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    onPrint() {
      window.print();
    }
  },
  computed: {
    thisRole() {
        return this.data ? this.data.position : "-";
    },
    applicantData() {
      return [
        { label: "Aplicante", value: this.applicantName },
        { label: "Correo Electrónico", value: this.applicantEmail },
        { label: "Fecha de Aplicación", value: formatDate(this.submittedAt) }
      ];
    },
    applicantName() {
      return this.data ? this.data.applicantName : "-";
    },
    applicantEmail() {
      return this.data ? this.data.applicantEmail : "-";
    },
    submittedAt() {
      return this.data ? this.data.submittedAt : null;
    },
    results() {
      return this.data ? this.data.results : null;
    },
    profile() {
      return this.data && this.data.results
        ? this.data.results
        : null;
    },
    profileId() {
      return this.profile ? this.profile.id : 0;
    },
    profileName() {
      return this.profile ? this.profile.name : "-";
    },
    roleBullets() {
      return this.profile && this.profile.roleResponse
    },
    isValidProfile() {
      return ![75, 76].includes(this.printrofileId);
    },
    isSameProfile() {
      const profile = this.profile;

      return (
        profile === profile.id
      );
    },
    styleDescription() {
      return this.isValidProfile
        ? undefined
        : "Los resultados vinculados al <b>ESTILO DEL CARGO</b> no concuerdan con ningún perfil válido, esto puede suceder por diversas razones. Por favor ponte en contacto con la persona que te envió la prueba para que coordinen una nueva aplicación del test.";
    }
  }
});
</script>

<style scoped>
@media print {
  .topMarginNegative80 {
    margin-top: -80px;
  }
  .pagebreakBefore {
    break-before: always !important;
    page-break-before: always !important;
  }
  html {
    overflow-y: auto;
  }
}
</style>
