<template>
  <div>
    <p><strong>Bienvenido/a!</strong></p>
    <p>
      Este test te permitirá descubrir tu nivel de autoliderazgo y cómo puedes
      incrementar tu potencial.
    </p>
    <p><strong>Instrucciones:</strong></p>
    <ol>
      <li>
        <strong
          >Elige de la siguiente escala el valor que mejor describa la
          frecuencia con la que realizas cada comportamiento.</strong
        >
      </li>
      <li>
        <strong>Sé honesto/a contigo mismo/a.</strong> Cuanto más se ajusten tus
        respuestas a tu realidad actual, mayores beneficios obtendrás en tu
        SISTEMA DE INTELIGENCIA PERSONAL y tu autoliderazgo será más poderoso.
      </li>
      <li>
        <strong>No analices demasiado tus respuestas.</strong> Confía en tu
        intuición y elige la opción que mejor te represente.
      </li>
    </ol>
    <br />
    <p><strong>¿Estás listo/a? ¡Empecemos!</strong></p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "StartTextSelfLead"
});
</script>
