export const jobFeedback = [
  {
    key: "non-developed",
    label: "No Desarrollado", //entre 7 y 19 puntos
    range: { min: 7, max: 19 },
    items: [
      "Los comportamientos de liderazgo 1 a 1 centrados en la tarea no están desarrollados.",
      "Para lograr mayor eficiencia y éxito, es fundamental mejorar la manera de delegar, dirigir, evaluar y enfocar el esfuerzo de las personas hacia el logro de los objetivos. Además, es importante brindar apoyo a los colaboradores para que realicen sus tareas de forma adecuada.",
      "Identificar las competencias de los colaboradores permitirá asignar correctamente las responsabilidades al personal.",
      "Es crucial evitar que las correcciones de errores afecten la autoestima del personal y, en cambio, aprovechar los aciertos para estimular la excelencia.",
      "Ofrecer claridad en metas, tareas y normas, junto con un plan adecuado para fomentar la autonomía, ayudará a los colaboradores a obtener mejores resultados.",
      "Finalmente, mejorar la competencia en la dirección del personal puede llevar a niveles más altos de rendimiento y excelencia."
    ]
  },
  {
    key: "mid-developed",
    label: "Medianamente Desarrollado", //entre 20 y 31 puntos
    range: { min: 20, max: 31 },
    items: [
      "Tu gestión de liderazgo con los colaboradores en la ejecución de tareas es buena, pero aún hay oportunidades de desarrollo.",
      "Para fortalecer aún más, considera mejorar la manera de delegar, dirigir, evaluar y enfocar el esfuerzo de las personas hacia los objetivos.",
      "Aunque brindas apoyo eficiente a tus colaboradores, asegúrate de conocer sus competencias para asignar tareas adecuadamente.",
      "Además, aprovecha tanto los errores como los aciertos de tu personal para fomentar el progreso compartido y fortalecer la autoestima.",
      "Ofrecer mayor claridad en metas, tareas y normas, junto con un plan para fomentar la autonomía, ayudará a tus colaboradores a lograr mejores resultados."
    ]
  },
  {
    key: "advanced",
    label: "Muy Desarrollado", //entre 32 y 35
    range: { min: 32, max: 35 },
    items: [
      "Tu gestión de liderazgo con los colaboradores en la ejecución de tareas es excelente.",
      "Implementas buenas prácticas al delegar, dirigir, evaluar y enfocar el esfuerzo de las personas hacia los objetivos.",
      "Eres un líder activo en apoyar a tus colaboradores para que realicen sus tareas de forma eficiente.",
      "Además, conoces con precisión las competencias de tus colaboradores y sabes cómo fomentar su autonomía.",
      "Utilizas oportunamente los errores y los aciertos de tu personal para el progreso compartido y el fortalecimiento de la autoestima.",
      "En resumen, manejas de forma óptima los comportamientos dirigidos a la ejecución de las tareas."
    ]
  }
];

export const motivationFeedback = [
  {
    key: "non-developed",
    label: "No Desarrollado", //entre 7 y 19 puntos
    range: { min: 7, max: 19 },
    items: [
      "Aún no has desarrollado la competencia necesaria para fortalecer la motivación y construir un buen ambiente laboral.",
      "Esta debilidad podría estar afectando la motivación del personal.",
      "La falta de participación de los empleados en la toma de decisiones puede generar desánimo.",
      "Además, las diferencias entre los miembros del equipo pueden estar causando conflictos.",
      "Es posible que el personal dependa demasiado de un liderazgo directivo, lo que no favorece la autonomía.",
      "Además, el ambiente laboral podría ser rígido y resistente al cambio y la creatividad. No aprovechar las oportunidades para expresar aliento, confianza y elogios por el trabajo bien hecho también podría ser una causa de desmotivación.",
      "Recuerda que un ambiente laboral positivo y motivador es esencial para el éxito de cualquier organización.",
      "¡Ánimo!"
    ]
  },
  {
    key: "mid-developed",
    label: "Medianamente Desarrollado", //entre 20 y 31 puntos
    range: { min: 20, max: 31 },
    items: [
      "En general, demuestras una buena ejecución en los comportamientos de apoyo motivacional hacia tus colaboradores. A continuación, se detallan tus acciones:",
      "Participación y Toma de Decisiones: En ocasiones, brindas oportunidades para que tus colaboradores participen en la toma de decisiones e intentas incorporar sus sugerencias. Esto es positivo, ya que fomenta la sensación de pertenencia y empoderamiento.",
      "Manejo de Diferencias: Sin embargo, debes estar atento a las diferencias entre los miembros del equipo. En algunas ocasiones, estas diferencias pueden tornarse conflictivas. Es importante abordarlas de manera constructiva para mantener un ambiente armonioso.",
      "Autonomía y Resolución: Buscas que tus colaboradores sean personas autónomas y resolutivas. Esta actitud es valiosa, ya que promueve la responsabilidad individual y la capacidad de resolver problemas de manera independiente.",
      "Flexibilidad e Innovación: Intentas crear un ambiente flexible que fomente la innovación, la creatividad y esté abierto al cambio. Aunque en ocasiones prevalece lo tradicional o lo conocido, es positivo que busques un equilibrio entre ambos enfoques.",
      "Reconocimiento y Aliento: Aprovechas oportunidades para expresar aliento, confianza y elogios por el trabajo bien hecho. Este reconocimiento contribuye a mantener la motivación y el compromiso de tus colaboradores.",
      "Recuerda que un liderazgo motivador y un ambiente laboral positivo son fundamentales para el éxito del equipo.",
      "¡Sigue aprendiendo y fortaleciendo tu liderazgo!."
    ]
  },
  {
    key: "advanced",
    label: "Muy Desarrollado", //entre 32 y 35
    range: { min: 32, max: 35 },
    items: [
      "Tu desempeño en los comportamientos de soporte motivacional con tus colaboradores es excelente. Aquí se detallan tus acciones:",
      "Conexión Personal: Demuestras un genuino interés en conocer las motivaciones de tus colaboradores. Utilizas esta información de manera positiva para mantener un ambiente laboral lleno de entusiasmo.",
      "Participación y Toma de Decisiones: Brindas oportunidades para que tus colaboradores participen en la toma de decisiones. Además, eres capaz de incorporar sus sugerencias, evitando que las diferencias se conviertan en conflictos.",
      "Autonomía y Resolución: Buscas que tus colaboradores sean personas autónomas y resolutivas. Esta actitud fomenta la responsabilidad individual y la capacidad de resolver problemas de manera independiente.",
      "Flexibilidad e Innovación: Creas un ambiente flexible que promueve la innovación, la creatividad y está abierto al cambio. Aunque a veces prevalece lo tradicional, buscas un equilibrio que impulse el progreso.",
      "Reconocimiento y Celebración: Aprovechas las oportunidades para expresar aliento, confianza y elogios por el trabajo bien hecho. Además, celebras los logros, reconociendo su importancia.",
      "En resumen, comprendes la relevancia de un buen clima laboral y te esmeras en construir uno lleno de entusiasmo.",
      "¡Sigue así!"
    ]
  }
];

export const relationshipFeedback = [
  {
    key: "non-developed",
    label: "No Desarrollado", //entre 7 y 19 puntos
    range: { min: 7, max: 19 },
    items: [
      "La competencia para fortalecer la cercanía con tu equipo es un aspecto crucial en cualquier entorno laboral. Aunque todavía no la has desarrollado plenamente, es importante reconocer esta debilidad y trabajar en superarla. Veamos cómo abordar este desafío:",
      "Relación Cercana y Confianza: La falta de una relación cercana y de confianza con tu personal puede tener consecuencias significativas. Es fundamental construir vínculos sólidos para fomentar la colaboración y el compañerismo.",
      "Vacíos de Poder: La ausencia de una conexión sólida puede dejar vacíos de poder que otros podrían aprovechar. Fortalecer la relación con tu equipo es esencial para evitar estas vulnerabilidades.",
      "Comunicación y Aprecio: La falta de conexión y comunicación puede generar un clima laboral conflictivo y tenso. Demuestra aprecio y reconocimiento hacia tus colaboradores para fortalecer la confianza.",
      "Cuidado Emocional: El bienestar emocional del personal es fundamental. Asegúrate de que se sientan valorados y apoyados en su entorno laboral. Invertir en la cercanía y confianza con tu equipo no solo mejora el ambiente de trabajo, sino también la productividad y la satisfacción de todos.",
      "¡Cuida a tu personal y cosecharás los frutos de una relación sólida!"
    ]
  },
  {
    key: "mid-developed",
    label: "Medianamente Desarrollado", //entre 20 y 31 puntos
    range: { min: 20, max: 31 },
    items: [
      "Demuestras una buena habilidad en la ejecución de comportamientos de apoyo emocional con tus colaboradores, y esta destreza puede seguir creciendo.",
      "Conocimiento y Necesidades: Tu enfoque en conocer a tus colaboradores y comprender sus necesidades es valioso. Proporcionar apoyo basado en esta comprensión es fundamental para fortalecer la relación.",
      "Conexión y Cercanía: Intentas conectarte con tus colaboradores y crear cercanía. Pero, a pesar de tus esfuerzos, a veces el clima laboral puede estar tenso. Continúa perseverando.",
      "Comunicación y Malentendidos: La comunicación con el personal es una prioridad, pero los malentendidos pueden surgir. Aborda estos problemas con claridad y empatía para evitar conflictos.",
      "Celebración de Éxitos: Reconocer y celebrar los logros de tu personal es esencial. Esto fortalece la moral y fomenta un ambiente positivo.",
      "Estímulo al Trabajo Colaborativo: Tu apoyo al trabajo en equipo, solidaridad y compañerismo es alentador. Sigue promoviendo la colaboración para construir un entorno de confianza.",
      "Importancia del Clima Laboral: Eres consciente de la relevancia del clima laboral. Trabaja en mantener la autenticidad y la armonía en todas las interacciones.",
      "Continúa cultivando una relación positiva con tus colaboradores.",
      "Tu compromiso y esfuerzo contribuirán a un ambiente laboral más saludable y productivo.",
      "¡Sigue aprendiendo y fortaleciendo tu liderazgo!"
    ]
  },
  {
    key: "advanced",
    label: "Muy Desarrollado", //entre 32 y 35
    range: { min: 32, max: 35 },
    items: [
      "Tienes una habilidad destacada en la ejecución de comportamientos de apoyo emocional y relacional con tus colaboradores.",
      "Tu enfoque en conocerlos como personas y comprender sus necesidades para brindarles apoyo es admirable. Eres una persona empática y compasiva, lo cual contribuye a un ambiente de trabajo positivo.",
      "Además, tu disposición para establecer vínculos y resolver conflictos de manera activa fortalece las relaciones con tus colaboradores.",
      "Celebrar los logros personales y estimular la solidaridad y el compañerismo también son aspectos valiosos que aportas al equipo.",
      "Tu dedicación a una comunicación sincera y abierta, así como tu aprecio por la autenticidad y la vulnerabilidad, son cualidades que fomentan un clima laboral armonioso.",
      "¡Sigue cultivando estas habilidades y construyendo un entorno positivo para todos!"
    ]
  }
];

export const integralFeedback = [
  {
    key: "non-developed",
    label: "No Desarrollado", //entre 21 y 56 puntos
    range: { min: 21, max: 56 },
    items: [
      "Tu liderazgo 1 a 1 presenta varias oportunidades de mejora. Los comportamientos relacionados con la tarea, la motivación y las relaciones no están generando los resultados que esperas. Sin embargo, tienes una gran oportunidad para aprender estrategias adecuadas en cada uno de estos aspectos del liderazgo interpersonal.",
      "Recuerda que el liderazgo 1 a 1 es una oportunidad para desarrollar y fortalecer tus habilidades de liderazgo. Es importante que aproveches esta oportunidad para desarrollar y fortalecer tus habilidades de liderazgo.",
      "Considera adoptar mejores prácticas que agreguen valor a tus colaboradores, impulsen su progreso y autonomía, y al mismo tiempo fortalezcan tu liderazgo y amplíen tu nivel de influencia. Además, ten en cuenta que estás dejando vacíos de poder que podrían ser llenados por líderes informales con actitudes negativas.",
      "Es importante evaluar si el ambiente laboral refleja el nivel de desempeño, excelencia y entusiasmo que deseas. Aprovecha esta oportunidad para crecer y desarrollarte como líder.",
      "¡Mucho éxito!"
    ]
  },
  {
    key: "mid-developed",
    label: "Medianamente Desarrollado", //entre 57 y 92 puntos
    range: { min: 57, max: 92 },
    items: [
      "Tu liderazgo 1 a 1 es sólido y presenta oportunidades de desarrollo que te permitirán fortalecer tu rol como líder y ampliar tu nivel de influencia. A continuación, se desglosan tus comportamientos y áreas de enfoque:",
      "Comportamientos Directivos y de Soporte Motivacional:Guías para tu Colaborador: La mayor parte del tiempo, brindas dirección y apoyo necesarios para que tu personal realice su trabajo de manera efectiva y alcance sus objetivos.",
      "Vacíos de Poder: Aunque tratas de no dejar vacíos de poder, a veces se te pasan por alto buenas oportunidades para conectar más profundamente con tu equipo.",
      "Ambiente Laboral y Manejo de Diferencias:",
      "Construcción de un Buen Ambiente Laboral: Tienes en cuenta la importancia de crear un entorno positivo para tus colaboradores.",
      "Manejo de Diferencias: En general, puedes manejar las diferencias de manera adecuada.",
      "Autonomía y Resultados:",
      "Estrategias para  Autonomía: Utilizas estrategias que permiten a las personas lograr autonomía en el desempeño de sus tareas.",
      "Enfoque en Resultados: Te aseguras de que el trabajo se realice correctamente y se obtengan los resultados deseados.",
      "Comunicación y Aprendizaje:",
      "Comunicación Efectiva: Tratas de que la comunicación sea efectiva entre tú y tu equipo.",
      "Aprendizaje a partir de Errores: Intentas capitalizar los errores como oportunidades de reflexión y aprendizaje.",
      "Participación y Presencia:",
      "Presencia en Momentos Difíciles: Intentas estar presente en los momentos difíciles.",
      "Participación en Decisiones: Buscas que tu personal participe en la toma de decisiones.",
      "En general, te desempeñas bien en tu rol de líder 1 a 1, pero hay margen para lograr niveles aún mejores de desarrollo.",
      "¡Sigue trabajando en estas áreas y continúa creciendo como líder!"
    ]
  },
  {
    key: "advanced",
    label: "Muy Desarrollado", //entre 93 y 105
    range: { min: 93, max: 105 },
    items: [
      "Tu liderazgo 1 a 1 es excelente. Tus comportamientos directivos, de soporte motivacional y apoyo emocional son adecuadas guías para tu colaborador. Le brindas la dirección y el apoyo necesario para realizar bien su trabajo y alcanzar sus objetivos. Además, no dejas vacíos de poder que puedan ser llenados por líderes informales negativos.",
      "Creas un ambiente de entusiasmo, confianza y respeto. Eres una persona coherente que apoya tus palabras con tus acciones. Tienes la suficiente madurez emocional para aceptar las diferencias e integrarlas con paciencia, respeto y tolerancia.",
      "Manejas estrategias que permiten a la persona lograr autonomía en el desempeño de sus tareas. Buscas garantizar que el trabajo se haga bien y que se obtengan los resultados.",
      "Eres un líder en quien se puede contar en los momentos difíciles.",
      "Estás pendiente de tu personal y de sus necesidades, buscando ser un líder que agrega valor en su entorno.",
      "Te conectas y generas cercanía, cuidando de que la comunicación sea efectiva. Sabes cómo capitalizar los errores de la gente en reflexión y aprendizaje, fortaleciendo al mismo tiempo la autoestima del personal. Aprecias y estimulas la participación de tu personal en la toma de decisiones.",
      "En general, te desempeñas de forma óptima en tu rol de líder con tu personal.",
      "¡Sigue así!"
    ]
  }
];
