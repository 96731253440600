import { calculateKey, attachSections } from "@/model/common/disc/utils";
import profiles from "@/model/common/disc/profiles";
import profileDescriptions from "@/model/roledisc/content/profileDescriptions";

function processProfile(profileData, sections) {
  profileData.key = calculateKey(profileData.values);

  const selectedProfile = profiles.find(
    profile => profile.key == profileData.key
  );

  profileData.id = selectedProfile.id;
  profileData.code = selectedProfile.code;
  profileData.name = selectedProfile.name;

  attachSections(selectedProfile, profileData, sections);
}

export default {
  populate(data) {
    processProfile(data.results, {
      roleResponse: profileDescriptions
    });
  }
};
