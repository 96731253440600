<template>
  <div class="component-container">
    <report-section-title>
      Matriz del nivel de madurez del equipo por variable
    </report-section-title>
    <div class="table-container">
      <table>
        <thead>
          <th style="text-align: center">Nivel de madurez</th>
          <th
            v-for="factor in factors"
            :key="`footer-${factor.key}`"
            style="text-align: center"
          >
            {{ factor.label }}
          </th>
        </thead>
        <tbody>
          <tr v-for="level in maturityLevels" :key="level.key">
            <td style="text-align: center">
              <strong>{{ level.label }}</strong>
            </td>
            <td
              v-for="factor in factors"
              :key="factor.key"
              style="text-align: center"
            >
              <span
                v-if="
                  applicantAnswers[factor.key] === level.value &&
                  assessedCountByFactor[factor.key][level.value] !==
                    undefined &&
                  assessedCountByFactor[factor.key][level.value] !== 0
                "
              >
                (L) {{ assessedCountByFactor[factor.key][level.value] }}
              </span>
              <span v-else-if="applicantAnswers[factor.key] === level.value"
                >(L)</span
              >
              <span
                v-else-if="
                  assessedCountByFactor[factor.key] !== undefined &&
                  assessedCountByFactor[factor.key][level.value] !==
                    undefined &&
                  assessedCountByFactor[factor.key][level.value] !== 0
                "
              >
                {{ assessedCountByFactor[factor.key][level.value] }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { maturityLevels, factors } from "@/model/fone-team/maturityLevels.js";

export default {
  name: "LevelArray",
  props: {
    applicantAnswers: {
      type: Object,
      required: true
    },
    assessedCountByFactor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      factors,
      maturityLevels
    };
  }
};
</script>

<style scoped>
.component-container {
  margin: 30px;
  padding-bottom: 30px;
}
table {
  width: 80%;
  margin: auto;
  border-collapse: collapse;
}

.text-container {
  width: 90%;
  text-align: justify;
  margin: 0 auto;
}

h1 {
  text-align: center;
}

h2 {
  width: 90%;
  margin: auto;
  padding: 10px;
  border: 1px solid #dddddd;
  text-align: center;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

.table-container {
  margin-bottom: 20px;
}

div {
  list-style-type: none;
  text-align: justify;
  margin-bottom: 20px;
}
</style>
