<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="0" md="1" />
      <v-col align="center" cols="12" md="10">
        <div class="pt-sm-4 section-title" :class="textColor">
          <slot />
        </div>
        <div :class="lineColor" />
      </v-col>
      <v-col cols="0" md="1" />
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReportSectionTitle",
  props: {
    theme: {
      type: String,
      required: false,
      default: "light"
    }
  },
  computed: {
    textColor() {
      return this.theme === "dark" ? "white--text" : "primary--text";
    },
    lineColor() {
      return this.theme === "dark" ? "light-divider" : "dark-divider";
    }
  }
});
</script>
<style scoped>
.section-title {
  font-size: 2em;
  font-weight: 600;
}
@media screen {
  .dark-divider {
    border-top: 4px solid var(--v-primary-base);
    border-radius: 5px;
    width: 20%;
    margin-top: 0.5em;
  }
  .light-divider {
    border-top: 4px solid #fff;
    border-radius: 5px;
    width: 20%;
    margin-top: 0.5em;
  }
}
@media print {
  .lineDividerDark {
    border-top: 4px solid var(--v-primary-base);
    border-radius: 5px;
    width: 20%;
    margin-top: 0.1em;
  }
  .lineDividerLight {
    border-top: 4px solid #fff;
    border-radius: 5px;
    width: 20%;
    margin-top: 0.5em;
  }
}
</style>
