import axios from "axios";

import firebaseConfig from "@/config/firebaseConfig";
import firebase from "@/plugins/firebase";
import f from "@/plugins/formatter";

const instance = axios.create({
  baseURL: firebaseConfig.apiBaseURL
});

export default {
  listUsers: async () => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
      throw new Error(
        "User list could not be retrieved. Invalid current user."
      );
    }

    const userToken = await currentUser.getIdToken();

    const { data: response } = await instance.get("/user/list", {
      headers: {
        token: userToken
      }
    });

    return {
      data: response
    };
  },
  inviteUser: async invite => {
    const newInvite = f.objectCamelToSnakeCase(invite, true);

    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
      throw new Error("Sesión no válida. No se puede invitar a un usuario.");
    }

    const userToken = await currentUser.getIdToken();

    try {
      const { data: response } = await instance.post(
        "/user/invite",
        newInvite,
        {
          headers: {
            token: userToken
          }
        }
      );

      return {
        data: response
      };
    } catch (error) {
      if (error.response && error.response.data) {
        throw new Error(error.response.data.message);
      }

      throw new Error("Ha ocurrido un error al invitar a un usuario.");
    }
  },
  getUserInvite(inviteId) {
    return instance.get(`/user/invite/${inviteId}`);
  }
};
