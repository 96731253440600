const initialFilter = {
  searchText: "",
  status: "ALL",
  type: "ALL"
};

const initialMultimap = {};
const initialSelfLeadermap = {};

export default {
  state: () => ({
    filter: { ...initialFilter },
    multimap: { ...initialMultimap },
    selfLeadermap: { ...initialSelfLeadermap }
  }),
  mutations: {
    initFilter(state) {
      state.filter = { ...initialFilter };
    },
    initMultimap(state) {
      state.multimap = { ...initialMultimap };
    },
    initSelfLeadermap(state) {
      state.selfLeadermap = { ...initialSelfLeadermap };
    },
    setFilter(state, payload) {
      state.filter = {
        ...state.filter,
        ...payload
      };
    },
    setMultimap(state, payload) {
      state.multimap = {
        ...payload
      };
    },
    setSelfLeadermap(state, payload) {
      state.selfLeadermap = {
        ...payload
      };
    }
  },
  actions: {
    initDashboard({ commit }) {
      commit("initFilter");
      commit("initMultimap");
      commit("initSelfLeadermap");
    },
    setFilter({ commit }, payload) {
      commit("setFilter", payload);
    },
    setMultimap({ commit }, payload) {
      commit("setMultimap", payload);
    },
    setSelfLeadermap({ commit }, payload) {
      commit("setSelfLeadermap", payload);
    }
  },
  getters: {
    filter(state) {
      return state.filter;
    },
    multimap(state) {
      return state.multimap;
    },
    selfLeadermap(state) {
      return state.selfLeadermap;
    }
  }
};
