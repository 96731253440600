<template>
  <div>
    <report-section-title>
      Visión Compartida del Liderazgo 1 a 1
    </report-section-title>
    <!-- <p>Cantidad de colaboradores que participaron: {{ applicants.length }}</p> -->
    <div class="text-container">
      <h1>Tabla Comparativa: Respuestas del Líder y Colaboradores</h1>
      <br />
      <p>
        Esta sección presenta las respuestas proporcionadas por ti y tus
        colaboradores sobre la evaluación de tu liderazgo. La tabla comparativa
        muestra las respuestas de cada participante para cada pregunta, junto
        con el número de colaboradores que seleccionaron cada opción. Si varias
        personas seleccionaron la misma respuesta, el número total de personas
        que la seleccionaron se muestra junto a la opción correspondiente.
      </p>
      <p><strong>Interpretación de la Tabla:</strong></p>
      <ul>
        <li>
          <strong>Filas:</strong> Representan cada pregunta de la evaluación.
        </li>
        <li>
          <strong>Columnas:</strong> Muestran las diferentes opciones de
          respuesta para cada pregunta.
        </li>
        <li>
          <strong>Celdas:</strong> Indican el número de colaboradores que
          seleccionaron una opción de respuesta específica y la opción escogida
          por ti que está representada como (L).
        </li>
      </ul>
      <br />
      <h3>Ejemplo:</h3>
      <br />
      <table>
        <tbody>
          <tr>
            <td>
              <p>Pregunta</p>
            </td>
            <td>
              <ol>
                <li>Nunca</li>
              </ol>
            </td>
            <td>
              <ol start="2">
                <li>Raramente</li>
              </ol>
            </td>
            <td>
              <ol start="3">
                <li>A veces</li>
              </ol>
            </td>
            <td>
              <ol start="4">
                <li>Frecuentemente</li>
              </ol>
            </td>
            <td>
              <ol start="5">
                <li>Siempre</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              Me fija metas y objetivos claros. Me define en detalle las tareas
              y los criterios de éxito.
            </td>
            <td></td>
            <td></td>
            <td style="text-align: center">5</td>
            <td style="text-align: center">1 (L)</td>
            <td style="text-align: center">4</td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        En este ejemplo, 5 colaboradores seleccionaron la opción 3 "A veces", 1
        colaborador y el líder evaluado (L) seleccionaron la opción 4
        "Frecuentemente" y 4 colaboradores seleccionaron la opción 5 "Siempre".
      </p>
      <p>
        La cantidad de colaboradores que participaron en la evaluación es el
        total de la suma de todos los números que aparecen en las celdas. Si
        quieres saber cuántos colaboradores en total participaron en la
        evaluación solo debes sumar los números de las celdas. En el caso del
        ejemplo sería:
      </p>
      <ol>
        <li>Nunca = 0</li>
        <li>Raramente = 0</li>
        <li>A veces = 5</li>
        <li>Frecuentemente = 1</li>
        <li>Siempre = 4</li>
      </ol>
      <br />
      <p>Total de colaboradores que participaron en la evaluación = 10</p>
      <h3>Análisis:</h3>
      <p>
        La tabla comparativa permite realizar un análisis detallado de las
        percepciones de los colaboradores sobre tu liderazgo 1 a 1. Al comparar
        tus respuestas con las de tus colaboradores, se pueden identificar áreas
        de coincidencia y discrepancia.
      </p>
      <h3>Conclusiones:</h3>
      <p>
        La información proporcionada en la tabla comparativa es valiosa para
        comprender la visión compartida de tu liderazgo dentro del equipo. Los
        resultados de la evaluación pueden utilizarse para desarrollar planes de
        acción y mejorar la comunicación y la colaboración con tu personal.
      </p>
      <h3>Recomendaciones:</h3>
      <ul>
        <li>
          Se recomienda analizar la tabla comparativa en conjunto con el
          personal para discutir los resultados y obtener una comprensión más
          profunda de las percepciones del equipo.
        </li>
        <li>
          Se pueden utilizar los resultados de la evaluación para identificar
          áreas de mejora en el liderazgo y desarrollar planes de acción
          específicos.
        </li>
        <li>
          Es importante fomentar una comunicación abierta y honesta con tus
          colaboradores para abordar cualquier discrepancia en las percepciones.
        </li>
      </ul>
      <br />
      <h1>Tus resultados de Visión Compartida del Liderazgo 1 a 1</h1>
    </div>
    <div
      v-for="(section, index) in sections"
      :key="index"
      class="table-container"
    >
      <h2>{{ section.label }}</h2>
      <table>
        <thead>
          <tr>
            <th style="width: 50%; text-align: center">Pregunta</th>
            <th style="text-align: center">1. Nunca</th>
            <th style="text-align: center">2. Raramente</th>
            <th style="text-align: center">3. A veces</th>
            <th style="text-align: center">4. Frecuentemente</th>
            <th style="text-align: center">5. Siempre</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in section.items" :key="`item-${index}`">
            <td>
              <strong>
                {{
                  section.key === "job, motivation, relationships"
                    ? section.item
                    : item
                }}
              </strong>
            </td>
            <td style="text-align: center" v-for="n in 5" :key="`cell-${n}`">
              <span v-if="assessedAnswers[section.key][index] === n">
                (L)
              </span>
              <span
                v-if="
                  answerCounts[section.key][index][n] !== undefined &&
                  answerCounts[section.key][index][n] !== 0
                "
              >
                {{ answerCounts[section.key][index][n] }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { applicant } from "@/model/one-to-one/questions.js";
import assessmentMixin from "@/mixins/assessmentMixin";

export default {
  name: "SharedVision",
  mixins: [assessmentMixin],
  props: {
    assessedAnswers: {
      type: Object,
      required: true
    },
    applicants: {
      type: Array,
      required: true
    },
    answerCounts: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sections: applicant.filter(section =>
        ["job", "motivation", "relationships"].includes(section.key)
      )
    };
  }
};
</script>

<style scoped>
table {
  width: 90%;
  margin: auto;
  border-collapse: collapse;
}

.text-container {
  width: 90%;
  text-align: justify;
  margin: 0 auto;
}

h1 {
  text-align: center;
}

h2 {
  width: 90%;
  margin: auto;
  padding: 10px;
  border: 1px solid #dddddd;
  text-align: center;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.table-container {
  margin-bottom: 20px;
}

div {
  list-style-type: none;
  text-align: justify;
}
</style>
