<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="page-title font-weight-black">Comprar {{ typeName }}</h2>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col
        class="d-flex justify-center"
        cols="12"
        md="3"
        v-for="product in products"
        :key="product.id"
      >
        <o-card-layout
          :title="product.name"
          :subtitle="product.description"
          color="white"
          max-width="300"
          classname="d-flex flex-column justify-space-between"
        >
          <div class="d-flex flex-column justify-space-between">
            <ul class="card-description text-left">
              <li v-for="(item, index) in product.features" :key="index">
                {{ item.name }}
              </li>
            </ul>

            <v-container class="text-center" v-if="isPack">
              <v-row>
                <v-col>
                  <v-btn color="primary" fab small outlined @click="add(-1)">
                    <v-icon>fas fa-minus</v-icon>
                  </v-btn>
                </v-col>

                <v-col>
                  <o-text-field
                    class="quantity-input"
                    hide-details
                    :clearable="false"
                    :value="quantity ? quantity.toString() : '0'"
                  ></o-text-field>
                </v-col>

                <v-col>
                  <v-btn color="primary" fab small @click="add(1)">
                    <v-icon>fas fa-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>

          <template v-slot:actions>
            <div class="d-flex flex-column align-stretch flex-grow-1">
              <v-divider class="my-2" />

              <h2 class="font-weight-black">
                USD {{ getProductPrice(product) | currency }} / año
              </h2>

              <v-btn block color="primary" @click="selectProduct(product)">
                Continuar
              </v-btn>
            </div>
          </template>
        </o-card-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import { defineComponent } from "vue";

import { PRODUCT_TYPE } from "@/model/shop/constants";
import messageDialogMixin from "@/mixins/messageDialogMixin";

export default defineComponent({
  mixins: [messageDialogMixin],
  props: {},
  data() {
    return {
      type: null,
      products: null,
      quantity: 1
    };
  },
  computed: {
    typeName() {
      const typeName = PRODUCT_TYPE[this.type];
      return typeName ? typeName : "";
    },
    isPack() {
      return this.type === "pack";
    }
  },
  created() {
    this.type = this.$route.params.type;
    this.$store
      .dispatch("listProducts", this.type)
      .then(products => (this.products = products))
      .catch(error => this.errorDialog(error));
  },
  methods: {
    add(qty) {
      if (this.quantity + qty > 0) {
        this.quantity += qty;
      }
    },
    getProductPrice(product) {
      return product.price ? product.price.amount * this.quantity : 0;
    },
    getProductPriceId(product) {
      return product.price ? product.price.id : null;
    },
    selectProduct(product) {
      const priceId = this.getProductPriceId(product);

      if (priceId) {
        this.$store
          .dispatch("goToProcessPayment", {
            priceId,
            type: this.type,
            quantity: this.quantity
          })
          .then(() => {})
          .catch(error => this.errorDialog(error.message));
      } else {
        this.errorDialog(
          "Ha ocurrido un error crítico debido a la configuración del producto"
        );
      }
    }
  }
});
</script>

<style>
.page-title {
  text-align: center !important;
  text-transform: uppercase !important;
}
.quantity-input input {
  text-align: center;
}
</style>
