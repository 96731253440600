const HEADER_HEIGHT = 80;
const HIDDEN_LEFT_COL_PADDING = 12;
const MID_COL_PADDING = 12;
const CARD_PADDING = 16;
const COUNTER_SECTION_HEIGHT = 78;
const FILTER_SECTION_HEIGHT = 86;
const TABLE_CONTAINER_PADDING = 12;
const TABLE_HEADER_HEIGHT = 32;
const TABLE_ROW_HEIGHT = 37;
const PAGINATION_BAR_HEIGHT = 59;
const HIDDEN_RIGHT_COL_PADDING = 12;
const HIDDEN_CARD_ACTIONS = 16;
const FOOTER_HEIGHT = 32;

const NET_OCCUPIED_VERTICAL_SPACE =
  HEADER_HEIGHT +
  HIDDEN_LEFT_COL_PADDING * 2 +
  MID_COL_PADDING +
  CARD_PADDING * 2 +
  COUNTER_SECTION_HEIGHT +
  FILTER_SECTION_HEIGHT +
  TABLE_CONTAINER_PADDING * 2 +
  HIDDEN_RIGHT_COL_PADDING * 2 +
  HIDDEN_CARD_ACTIONS * 2 +
  FOOTER_HEIGHT;

const MIN_TABLE_HEIGHT = TABLE_HEADER_HEIGHT + TABLE_ROW_HEIGHT * 5;

export const PAGE_SIZE = 5;

export const getTableHeight = (window, includeFooter) => {
  const vh = window.innerHeight;

  const paginationBarHeight = includeFooter ? PAGINATION_BAR_HEIGHT : 0;

  const initialTableHeight =
    vh - NET_OCCUPIED_VERTICAL_SPACE - paginationBarHeight;

  return initialTableHeight > MIN_TABLE_HEIGHT
    ? initialTableHeight
    : MIN_TABLE_HEIGHT;
};
