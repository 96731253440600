<template>
  <div>
    <v-row no-gutters>
      <v-col cols="3" />
      <v-col
        cols="6"
        align="center"
        class="number font-weight-medium"
        :style="{ color: color }"
      >
        {{ number }}%
      </v-col>
      <v-col cols="3" />
    </v-row>
    <v-row>
      <v-col cols="3" />
      <v-col
        cols="6"
        class="bar"
        :style="{ backgroundColor: color, height: height + 'px' }"
      >
      </v-col>
      <v-col cols="3" />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "verticalBar",
  props: {
    color: String,
    number: Number
  },
  computed: {
    height() {
      return this.number * 4;
    }
  }
};
</script>

<style scoped>
.bar {
  background-color: var(--v-primary-base);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.number {
  font-size: 1.5em;
}
</style>
