<template>
  <v-container fluid class="white py-0 px-0" id="printPage">
    <report-header id="ready_to_know">
      <template v-slot:data>
        <applicant-data :data="applicantData" />
      </template>
      <template v-slot:controls>
        <v-row justify="end" align="center">
          <v-col cols="4" class="d-flex justify-end pr-sm-10 pr-4">
            <v-btn elevation="2" @click="onPrint">Imprimir</v-btn>
          </v-col>
        </v-row>
      </template>
    </report-header>

    <section-intro />

    <div class="container">
      <v-row class="flex-container">
        <v-col cols="6" class="flex-item">
          <leadership-development-table :applicantCount="applicantCount" />
        </v-col>
        <v-col cols="6" class="flex-item">
          <leadership-development-chart :applicantCount="applicantCount" />
        </v-col>
      </v-row>
    </div>

    <behavior-tables
      :applicantCount="applicantCount"
      :answerCounts="answerCounts"
    />
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import { formatDate } from "@/utils/date";
import ApplicantData from "../common/ApplicantData.vue";
import ReportHeader from "../common/ReportHeader.vue";
import SectionIntro from "./components/SectionIntro.vue";
import LeadershipDevelopmentChart from "./components/LeadershipDevelopmentChart.vue";
import LeadershipDevelopmentTable from "./components/LeadershipDevelopmentTable.vue";
import LeadershipBehaviorTables from "./components/LeadershipBehaviorTables.vue";

export default defineComponent({
  name: "SLReport",
  components: {
    "applicant-data": ApplicantData,
    "report-header": ReportHeader,
    "section-intro": SectionIntro,
    "leadership-development-chart": LeadershipDevelopmentChart,
    "leadership-development-table": LeadershipDevelopmentTable,
    "behavior-tables": LeadershipBehaviorTables
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      applicants: []
    };
  },
  computed: {
    answerCounts() {
      return this.data && this.data.results
        ? this.data.results.answerCounts
        : null;
    },
    applicantCount() {
      return this.data && this.data.results
        ? this.data.results.applicantCount
        : null;
    },
    applicantData() {
      return [
        { label: "Evaluado", value: this.data.applicantName },
        { label: "Correo Electrónico", value: this.data.applicantEmail },
        { label: "Fecha de Aplicación", value: formatDate(this.submittedAt) }
      ];
    },
    submittedAt() {
      return this.data ? this.data.submittedAt : null;
    }
  },
  methods: {
    onPrint() {
      window.print();
    }
  }
});
</script>
