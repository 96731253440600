export default [
  {
    key: "insubordinate",
    label: "Insubordinado",
    primaryColor: "#b60000",
    secondaryColor: "#b60000",
    primaryFontColor: "#ffffff",
    secondaryFontColor: "#ffffff",
    description:
      "Capitaliza el descontento de las personas a su favor. Influye activamente para desalinear a los demás. En franca oposición con la autoridad. Prefiere tener el control que lograr resultados. Se rodea de gente manipulable. Líder negativo."
  },
  {
    key: "helpless",
    label: "Desvalido",
    primaryColor: "#ff2525",
    secondaryColor: "#b60000",
    primaryFontColor: "#ffffff",
    secondaryFontColor: "#ffffff",
    description:
      "Improductivo. Miente y se excusa para cubrir sus fallas. Se victimiza frente a su falta de productividad. Consciente de sus faltas, pero no hace nada para remediarlas. No cumple con sus funciones, pero reclama sus derechos. Abandona sus responsabilidades amparándose en causas legales. Sin poder de influencia. Sus intereses personales y su baja energía interfieren de forma negativa en su autoridad."
  },
  {
    key: "rebel",
    label: "Rebelde",
    primaryColor: "#ffa600",
    secondaryColor: "#ffea00",
    primaryFontColor: "#ffffff",
    secondaryFontColor: "#000000",
    description:
      "Individualista. Actitud arrogante o altanera. Poco cooperativo. Eficiente en las funciones operativas, pero rebelde con la autoridad. Controlador. Compite y argumenta para imponerse. Estilo de liderazgo impositivo. Impulsivo. Poco reflexivo. Humilla o maltrata con su actitud. Es reactivo con tendencia a la impaciencia y/o agresividad."
  },
  {
    key: "unmotivated",
    label: "Desmotivado",
    primaryColor: "#ffea00",
    secondaryColor: "#ffea00",
    primaryFontColor: "#000000",
    secondaryFontColor: "#000000",
    description:
      "No muestra interés. Cumple órdenes selectivamente. No cumple con los plazos. Responde a su ritmo. Bajo rendimiento laboral. Se guarda las cosas que no le gustan y actúa con resentimiento. Habla de su descontento con sus compañeros a manera de queja. Su actitud afecta su liderazgo. Proclive a la crítica y no propone nada en concreto. Mantiene su posición de forma pasiva sin asumir la responsabilidad."
  },
  {
    key: "loyal",
    label: "Leal",
    primaryColor: "#deffb7",
    secondaryColor: "#417600",
    primaryFontColor: "#000000",
    secondaryFontColor: "#ffffff",
    description:
      "Obediente. Dependiente del líder. Cumple con sus tareas rutinarias eficientemente, pero no va más allá. Bajo nivel de influencia social. Es más seguidor que líder. Tiende a ser más operativo que táctico o estratégico. Prefiere lo estable y tradicional."
  },
  {
    key: "brave",
    label: "Valiente",
    primaryColor: "#c1ff79",
    secondaryColor: "#417600",
    primaryFontColor: "#000000",
    secondaryFontColor: "#ffffff",
    description:
      "Productivo en las tareas rutinarias. Ofrece soluciones a los problemas comunes. Interés por mejorar. Toma la iniciativa de forma insegura enfrentando sus miedos. Se siente vulnerable con el manejo de la autoridad. Le falta consolidar el liderazgo."
  },
  {
    key: "confident",
    label: "Seguro",
    primaryColor: "#91d544",
    secondaryColor: "#417600",
    primaryFontColor: "#000000",
    secondaryFontColor: "#ffffff",
    description:
      "Propone ideas. Aporta. Seguro de sí mismo. Busca destacar dando lo mejor de sí. Se integra al equipo manteniendo su aspiración por crecer y destacarse. Con disposición a colaborar. Asume rol de liderazgo. Alta productividad. Ambicioso y con deseos de superación."
  },
  {
    key: "authentic",
    label: "Auténtico",
    primaryColor: "#64a718",
    secondaryColor: "#417600",
    primaryFontColor: "#ffffff",
    secondaryFontColor: "#ffffff",
    description:
      "Autónomo. Consigue sus objetivos de forma óptima y coopera con los demás. Entiende y cumple las normas e inspira a otros a seguirlas. Se integra al equipo. Va más allá de sus funciones y ayuda a los demás a lograr sus objetivos, a integrarse y a destacarse. Busca mejorar en pro de la armonía. Reflexivo. Alto nivel de influencia social."
  },
  {
    key: "aware",
    label: "Consciente",
    primaryColor: "#417600",
    secondaryColor: "#417600",
    primaryFontColor: "#ffffff",
    secondaryFontColor: "#ffffff",
    description:
      "Innovador, reta el estatus quo y estimula a otros a ser creativos. Ayuda a los demás a lograr mayores niveles de excelencia. Aporta en la expansión del negocio y del éxito compartido. Agrega valor constantemente en su entorno y fuera de él, sin reclamar méritos. Los demás le importan de forma genuina y activa. Líder transpersonal."
  }
];
