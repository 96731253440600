<template lang="html">
  <div class="mt-4 mb-8">
    <h2>Nivel de Convergencia</h2>

    <v-row class="my-2">
      <v-col cols="3" class="py-1" />
      <v-col
        cols="6"
        class="py-1 convergence-level"
        :style="{
          backgroundColor: level.color,
          color: level.fontColor
        }"
      >
        {{ level.text }} - {{ convergence }}%
      </v-col>
      <v-col cols="3" class="py-1" />
    </v-row>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import levels from "@/model/pgc/convergenceLevels";

export default defineComponent({
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    convergence() {
      if (!this.data) {
        return 0;
      }

      const convergence = this.data.slice(4).reduce(
        (accum, current) => (accum + current.value),
        0
      );

      return convergence;
    },
    level() {
      return levels.find(level => level.threshold >= this.convergence)
    }
  }
});
</script>

<style scoped>
.convergence-level {
  border: 1px solid #000;
}
</style>
