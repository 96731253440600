<template>
  <div>
    <div
      class="text"
      :class="{
        'no-breakable': noBreakable
      }"
    >
      <slot />
    </div>
    <template v-if="!!nItems && nItems > 0">
      <br />
      <div class="text pl-6 pb-10" v-for="i in nItems" :key="i">
        {{ letters[i - 1] }}.-
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlanItem",
  props: {
    nItems: {
      type: Number,
      required: false
    },
    noBreakable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      letters: ["a", "b", "c", "d", "e"]
    };
  }
});
</script>

<style scoped>
.text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--v-primary-base);
  text-align: justify;
}
@media print {
  .no-breakable {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
</style>
