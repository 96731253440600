<template>
  <div>
    <div class="white margin-top pb-16">
      <v-container fluid>
        <report-section-title>
          Proyecto de fortalecimiento del liderazgo
        </report-section-title>
      </v-container>

      <v-container fluid>
        <v-row no-gutters>
          <v-col no-gutters cols="0" md="1" />
          <v-col no-gutters cols="12" md="10" class="px-5 px-sm-5 px-md-0">
            <div>
              <p class="paragraph pb-10 no-breakable">
                Esta sección está diseñada para fortalecer tu potencial y
                autodescubrimiento. Si estás realizando coaching, mentoría,
                psicoterapia o cuentas con algún tipo de acompañamiento,
                comparte las reflexiones de este apartado para que logres tu
                progreso como líder de forma más ágil.
              </p>

              <plan-item class="pb-16 mb-16">
                1.- Reflexiona sobre tus resultados
              </plan-item>

              <plan-item :n-items="3">
                2.- Lee el estilo <strong>NATURAL</strong> y escribe aquí las
                tres frases que mejor te describen en tu rol de líder:
              </plan-item>

              <plan-item :n-items="3">
                3.- Repasa la <strong>RESPUESTA ADAPTATIVA</strong> y escribe
                aquí las tres frases que mejor te describen en tu rol de líder:
              </plan-item>

              <plan-item :n-items="3">
                4.- Menciona 3 aspectos del estilo <strong>NATURAL</strong> que
                te gustaría fortalecer en tu liderazgo:<br /><br />
              </plan-item>

              <plan-item :n-items="3">
                5.- Menciona 3 aspectos de la
                <strong>RESPUESTA ADAPTATIVA</strong> que te gustaría fortalecer
                en tu liderazgo:
              </plan-item>

              <plan-item>
                6.- Desarrolla un proyecto de fortalecimiento para tu liderazgo
                tomando en cuenta las respuestas a las preguntas 4 y 5:
                <br />

                <xs-rotation-message />

                <plan-table
                  title="Proyecto de fortalecimiento del liderazgo"
                  :columns="projectTableColumns"
                  :n-rows="3"
                />
              </plan-item>
            </div>
          </v-col>
          <v-col cols="0" md="1" />
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import PlanItem from "@/views/reports/common/disc/PlanItem";
import PlanTable from "@/views/reports/common/disc/PlanTable";

export default {
  name: "SectionLeadershipPlan",
  components: {
    "plan-item": PlanItem,
    "plan-table": PlanTable
  },
  data() {
    return {
      projectTableColumns: [
        { label: "Aspecto", cols: 1.5 },
        { label: "Objetivo", cols: 1.5 },
        { label: "Acciones", cols: 1.5 },
        { label: "Evidencia de progreso", cols: 3 },
        { label: "Plazo", cols: 4 }
      ]
    };
  }
};
</script>

<style scoped>
.paragraph {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--v-primary-base);
  text-align: justify;
  margin-bottom: 1em;
}
@media print {
  .margin-top {
    margin-top: 3em;
  }
}
</style>
