<template>
  <div>
    <div>
      <report-section-title>Resultados Generales</report-section-title>
      <p class="applicants">{{ data.length }} Aplicantes</p>
    </div>
    <div>
      <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
    </div>
    <div>
      <div class="legend">
        <div class="label">
          <div class="p1"></div>
          <p>Emoción</p>
        </div>
        <div class="label">
          <div class="p2"></div>
          <p>Mente</p>
        </div>
        <div class="label">
          <div class="p3"></div>
          <p>Acciones</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  name: "LeaderGeneralGraph",
  components: {
    GChart
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chartData: this.calculateChartData()
    };
  },
  methods: {
    calculateChartData() {
      const data = [
        ["Aspecto", "Promedio", { role: "style" }, { role: "annotation" }]
      ];

      const emotionsAverage = this.calculateAverage("emotionsum");
      const mindAverage = this.calculateAverage("mindsum");
      const behaviorAverage = this.calculateAverage("behaviorsum");

      const averages = [emotionsAverage, mindAverage, behaviorAverage];
      const colors = ["#cda349", "#37526d", "#9edbdd"];

      const aspects = ["Emoción", "Mente", "Acciones"];

      for (let i = 0; i < aspects.length; i++) {
        data.push([aspects[i], averages[i], colors[i], averages[i]]);
      }

      return data;
    },
    calculateAverage(property) {
      const sum = this.data.reduce(
        (total, applicant) =>
          total + applicant.results.applicantCount[property],
        0
      );

      const average = sum / this.data.length;
      return average;
    }
  },
  computed: {
    chartOptions() {
      return {
        legend: "none",
        title: "",
        vAxis: { title: "Promedio de Respuesta" },
        hAxis: { title: "Aspecto" },
        bars: "vertical",
        chartArea: { width: "80%", height: "70%" }
      };
    }
  },
  watch: {
    data: {
      handler() {
        this.chartData = this.calculateChartData();
      },
      deep: true
    }
  }
};
</script>

<style scoped>
div {
  font-family: "Roboto", sans-serif;
  background-color: white;
  padding-bottom: 10px;
}
.applicants {
  font-size: 1rem;
  font-weight: bold;
  text-align: right;
  margin-right: 10%;
  margin-bottom: 0%;
}
.legend {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.label {
  display: inline-flex;
  margin-right: 10px;
}
.p1 {
  width: 15px;
  height: 15px;
  background-color: #cda349;
}
.p2 {
  width: 15px;
  height: 15px;
  background-color: #37526d;
}
.p3 {
  width: 15px;
  height: 15px;
  background-color: #9edbdd;
}
p {
  font-size: 1rem;
  font-weight: bold;
  margin-left: 10px;
}
</style>
