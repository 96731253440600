<template>
  <v-checkbox
    dense
    :disabled="disabled"
    :label="label"
    :required="required"
    :rules="computedRules"
    :type="type"
    :value="value"
    @change="onChange"
    @click="onClick"
  ></v-checkbox>
</template>

<script>
import { defineComponent } from "vue";

import validations from "@/plugins/validations";

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: "text"
    },
    value: Boolean
  },
  computed: {
    computedRules() {
      const rules = [];

      if (this.required) {
        rules.push(validations.form.required_field);
      }

      if (this.rules) {
        rules.push(...this.rules);
      }

      return rules;
    }
  },
  methods: {
    onChange(event) {
      this.$emit("input", !!event);
    },
    onClick(event) {
      this.$emit("click", event);
    }
  }
});
</script>
