<template>
  <v-btn icon large color="primary" :to="path">
    <v-icon left>fas fa-arrow-alt-circle-left</v-icon>
  </v-btn>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BackButton",
  props: {
    path: {
      type: String,
      required: true
    }
  }
});
</script>
