export default {
  1.1: [
    "Innovador y moderno.",
    "Orientado al futuro.",
    "Libre de supervisión, control y detalles.",
    "Dinámico.",
    "Con desafíos y retos en el día a día",
    "Oportunidades para asumir riesgos, tomar decisiones y resolver problemas.",
    "Creativo, en donde se puedan desarrollar nuevas ideas."
  ],
  1.2: [
    "Innovador y moderno.",
    "Orientado al futuro.",
    "Libre de supervisión, control y detalles.",
    "Dinámico.",
    "Con desafíos y retos en el día a día",
    "Oportunidades para asumir riesgos, tomar decisiones y resolver problemas.",
    "Creativo, en donde se puedan desarrollar nuevas ideas."
  ],
  2.1: [
    "Libre de restricciones y detalles.",
    "Contacto con mucha gente.",
    "En el que se tomen decisiones rápidas.",
    "Con libertad de expresión y movimiento.",
    "Ambiente democrático que valore la amistad.",
    "Social, dinámico y repleto de actividades.",
    "Cargado de entusiasmo y optimismo.",
    "De integración e inclusión.",
    "Lleno de profundidad emocional."
  ],
  2.2: [
    "Libre de restricciones y detalles.",
    "Contacto con mucha gente.",
    "En el que se tomen decisiones rápidas.",
    "Con libertad de expresión y movimiento.",
    "Ambiente democrático que valore la amistad.",
    "Social, dinámico y repleto de actividades.",
    "Cargado de entusiasmo y optimismo.",
    "De integración e inclusión.",
    "Lleno de profundidad emocional."
  ],
  3.1: [
    "Entorno estable y predecible.",
    "En donde las reglas estén claras.",
    "En el que pueda desarrollarse a largo plazo.",
    "Con personas que aclaren dudas y ofrezcan instrucciones claras.",
    "En donde todo esté organizado, ordenado y limpio.",
    "Con cambios planificados y tiempo para adaptarse y reflexionar antes de tomar acción.",
    "Amigable, amable, libre de conflictos y confrontaciones.",
    "Tradicional, familiar y conocido."
  ],
  3.2: [
    "Entorno estable y predecible.",
    "En donde las reglas estén claras.",
    "En el que pueda desarrollarse a largo plazo.",
    "Con personas que aclaren dudas y ofrezcan instrucciones claras.",
    "En donde todo esté organizado, ordenado y limpio.",
    "Con cambios planificados y tiempo para adaptarse y reflexionar antes de tomar acción.",
    "Amigable, amable, libre de conflictos y confrontaciones.",
    "Tradicional, familiar y conocido."
  ],
  4.1: [
    "En donde se aplique el pensamiento crítico, prevalezca el análisis y la objetividad.",
    "Con normas y protocolos claros.",
    "En el que se cumplan los procesos, en favor de la calidad.",
    "Familiar, con espacios privados, para pensar en solitario.",
    "Con poco contacto con extraños.",
    "Ambiente lógico y racional más que emocional.",
    "Controlado y predecible.",
    "Con personas disponibles para aclarar dudas y dar apoyo en la toma de decisiones."
  ],
  4.2: [
    "En donde se aplique el pensamiento crítico, prevalezca el análisis y la objetividad.",
    "Con normas y protocolos claros.",
    "En el que se cumplan los procesos, en favor de la calidad.",
    "Familiar, con espacios privados, para pensar en solitario.",
    "Con poco contacto con extraños.",
    "Ambiente lógico y racional más que emocional.",
    "Controlado y predecible.",
    "Con personas disponibles para aclarar dudas y dar apoyo en la toma de decisiones."
  ],
  5: [
    "Creativo e innovador, que garantice el logro del éxito a través de resultados inmediatos.",
    "En donde se pueda cuestionar el estatus quo en pro del progreso.",
    "En donde se expresen ideas y opiniones libremente.",
    "Ambiente dinámico, de ritmo rápido.",
    "Que permita conocer mucha gente.",
    "Con desafíos y nuevos retos para resolver.",
    "Con buen humor, entusiasmo y activo.",
    "Moderno y orientado al futuro.",
    "Libre de control y detalles.",
    "Competitivo."
  ],
  6: [
    "Innovador y moderno.",
    "En donde se puedan promover ideas creativas libremente.",
    "Que permita conocer mucha gente.",
    "Audaz, dinámico, positivo y de ritmo rápido.",
    "Libre de control y detalles.",
    "Con alto nivel de riesgo en la toma de decisiones.",
    "Con desafíos y nuevos retos para resolver, manteniendo el buen humor.",
    "Que garantice el logro del éxito a través de resultados inmediatos.",
    "Orientado al futuro.",
    "Competitivo.",
    "Abierto al cambio."
  ],
  7: [
    "En donde se puedan promover ideas creativas libremente.",
    "Informal y relajado, con mucho contacto con personas.",
    "Democrático, libre de control, rutinas y detalles.",
    "Ambiente dinámico, activo y positivo.",
    "En donde se puedan hacer nuevas amistades.",
    "Abierto al emprendimiento y la innovación.",
    "Moderno y orientado al futuro."
  ],
  8: [
    "Con independencia en la toma de decisiones pero que también se trabaje en equipo.",
    "En donde se debatan ideas novedosas, con respeto.",
    "Con retos desafiantes y sistemas probados.",
    "Que permita desarrollar actividades novedosas, con tiempo para planificar.",
    "En donde se apliquen planes paso a paso, con flexibilidad.",
    "Desafiante y confiable a la vez.",
    "Ambiente retador y que al mismo tiempo permita la reflexión.",
    "Con sentido de urgencia, pero teniendo en cuenta los detalles y a la gente."
  ],
  9: [
    "Con retos desafiantes y sistemas probados.",
    "En donde se debatan ideas novedosas con respeto.",
    "Que permita tomar decisiones independientes y que también se trabaje en equipo.",
    "Con sentido de urgencia, pero teniendo en cuenta los detalles y a la gente.",
    "Que permita desarrollar actividades novedosas, con tiempo para diseñar un plan.",
    "En donde se pueda aplicar un plan paso a paso de forma flexible.",
    "Ambiente desafiante y confiable a la vez.",
    "Armonioso, estable y amistoso, pero activo y con energía.",
    "Retador y que al mismo tiempo permita la reflexión."
  ],
  10: [
    "En donde se permita la participación en grupo.",
    "Que las decisiones se tomen de manera reflexiva y con sentido de urgencia.",
    "Con retos desafiantes y sistemas probados.",
    "Entorno para debatir ideas novedosas con respeto.",
    "Que tenga en cuenta a los detalles y a las personas, para lograr resultados.",
    "En donde se permita desarrollar actividades novedosas, con tiempo para planificar.",
    "Desafiante, estable y confiable a la vez.",
    "Armonioso y amistoso, pero activo y dinámico.",
    "Que ofrezca tiempo para relajarse y reflexionar."
  ],
  11: [
    "En donde se permita ejercer el liderazgo, con libertad de movimiento.",
    "En el que se tomen decisiones rápidas basadas en análisis.",
    "Ambiente profesional.",
    "En el que se resuelvan problemas complejos con sistemas creativos y probados.",
    "Un lugar que aprecie el sentido de logro, la visión de futuro, la objetividad y la calidad.",
    "En donde la sensibilidad emocional no sea importante.",
    "Basado en funciones, retos y procedimientos, más que en relaciones interpersonales.",
    "En donde se desarrollen soluciones novedosas basadas en indicadores.",
    "Que impulse el cambio con criterio de calidad.",
    "Que ofrezca tiempo y apoyo adecuado para reflexionar, cuando haya dudas."
  ],
  12: [
    "Ambiente profesional en los que se resuelvan problemas complejos.",
    "En donde se apliquen métodos creativos y sistemas probados.",
    "Con tareas claras y específicas, pero con libertad para innovar.",
    "Un lugar que aprecie el sentido de logro, la visión de futuro, la objetividad y la calidad.",
    "En donde la sensibilidad emocional no sea importante.",
    "Con libertad de movimiento para tomar decisiones rápidas basadas en análisis.",
    "Centrado en funciones, retos y procedimientos, más que en relaciones interpersonales.",
    "En donde se desarrollen soluciones novedosas basadas en indicadores.",
    "Que impulse el cambio con criterio de calidad.",
    "Que ofrezca el tiempo y el apoyo adecuado para reflexionar, cuando haya dudas."
  ],
  13: [
    "Ambiente profesional en los que se resuelvan problemas complejos.",
    "En donde se apliquen métodos creativos y sistemas probados.",
    "En donde prioricen el orden y la limpieza.",
    "Con tareas claras y específicas, pero con libertad para innovar.",
    "Un lugar que aprecie el sentido de logro, la visión de futuro, la objetividad y la calidad.",
    "En donde la sensibilidad emocional no sea importante.",
    "Con libertad de movimiento para tomar decisiones basadas en análisis.",
    "Centrado en funciones, retos y procedimientos, más que en relaciones interpersonales.",
    "Que ofrezca el tiempo y el apoyo adecuado para reflexionar, cuando haya dudas.",
    "En donde se desarrollen soluciones novedosas basadas en indicadores.",
    "Que impulse el cambio con criterio de calidad."
  ],
  14: [
    "Informal y relajado, en el que se relacione con personas, de forma estable y cercana.",
    "Sincero y agradable; con pocos conflictos y poco hostil.",
    "En donde se valoren la comunicación, la confianza y la credibilidad.",
    "Con diversidad, dinámico y un buen nivel de estabilidad.",
    "Que estimule el trabajo en equipo y genere relaciones estables con muchas personas.",
    "Con líderes que den prioridad a las necesidades de las personas.",
    "Contexto democrático con libertad de expresión y de movimiento."
  ],
  15: [
    "Sincero, de confianza y agradable; con pocos conflictos y poco hostil.",
    "En donde se valore la comunicación y la credibilidad.",
    "Informal y relajado.",
    "En el que pueda relacionarse con personas, de forma estable y cercana.",
    "Con diversidad y dinámico y con un buen nivel de estabilidad.",
    "Que estimule el trabajo en equipo y genere relaciones estables con muchas personas.",
    "Con líderes que den prioridad a las necesidades de las personas.",
    "Democrático, con libertad de expresión, estable y predecible."
  ],
  16: [
    "Informal, alegre y relajado, en el que pueda relacionarse de forma estable y profunda.",
    "Sincero, de confianza y agradable; con pocos conflictos y poco hostil.",
    "En donde se valoren la comunicación, la cercanía y la credibilidad.",
    "Con estabilidad, que ofrezca tiempo para adaptarse al cambio, de forma planificada.",
    "Que estimule el trabajo en equipo.",
    "Con líderes que den prioridad a las necesidades de las personas.",
    "Democrático, con libertad de expresión y predecible."
  ],
  17: [
    "Con libertad de movimiento, para tomar decisiones bien sustentadas.",
    "Amigable, con contacto con mucha gente experta.",
    "Especializado, que cuide de la calidad y del buen clima.",
    "Dinámico y activo, que cumpla con las normas y los protocolos probados.",
    "Democrático, con buena comunicación, que permita la participación de las personas.",
    "Que disponga de información de alto nivel.",
    "Entorno para realizar proyectos con energía y positivismo.",
    "Variado, dinámico y analítico."
  ],
  18: [
    "Especializado, que cuide de la calidad y del buen clima.",
    "Con libertad de movimiento, para tomar decisiones bien sustentadas.",
    "Amigable, con contacto con gente experta y equipos de alto desempeño.",
    "Dinámico y activo, que cumpla con las normas y los protocolos probados.",
    "Democrático, con buena comunicación, que permita la participación de las personas.",
    "Que disponga de información de alto nivel.",
    "Entorno para realizar proyectos con energía y positivismo.",
    "Variado, dinámico y analítico."
  ],
  19: [
    "Especializado, que cuide de la calidad y del buen clima.",
    "Con libertad de movimiento, para tomar decisiones bien sustentadas.",
    "Amigable, con contacto con gente experta y equipos de alto desempeño.",
    "Dinámico y activo, que cumpla con las normas y los protocolos probados.",
    "Que permita la participación en la toma de decisiones.",
    "Que disponga de información de alto nivel.",
    "En donde se realicen proyectos que escalen en nivel de complejidad.",
    "Lógico y analítico con buen nivel de optimismo.",
    "Con proyectos pensados detalladamente.",
    "Precavido y que se anticipe a los problemas y desafíos.",
    "En donde se puedan realizar investigaciones.",
    "Con gente experta y soporte de un equipo técnico."
  ],
  20: [
    "Predecible, precavido y estable.",
    "Sincero, privado y familiar.",
    "Con pocas personas.",
    "Que ofrezca toda la información necesaria.",
    "Que dé tiempo suficiente, para asumir las funciones y tomar decisiones.",
    "Que tome el cuidado de la seguridad de las personas y de la información.",
    "Con manuales de normas y procedimientos al alcance de la mano, de forma anticipada.",
    "Que tenga sistemas y protocolos establecidos y probados con éxito.",
    "Que permita controlar todas las etapas de las tareas.",
    "Con indicadores y referencias detalladas, para tomar decisiones sustentadas."
  ],
  21: [
    "Predecible, precavido y estable.",
    "Sincero, privado y familiar.",
    "Que ofrezca mucha información para decidir y tiempo para asumir los cambios.",
    "Que tome el cuidado de la seguridad de las personas y la calidad, en todos los procesos.",
    "Profesional o académico, que permita la investigación.",
    "Que priorice la claridad, a través de regulaciones e información detallada.",
    "Con manuales de normas y procedimientos al alcance de la mano, de forma anticipada.",
    "Que tenga sistemas y protocolos establecidos y probados con éxito.",
    "Que permita controlar todas las etapas del proyecto.",
    "Con indicadores y referencias detalladas, para tomar decisiones sustentadas.",
    "Objetivo y lógico más que emocional."
  ],
  22: [
    "Predecible, controlado, precavido y estable.",
    "Sincero, privado y con un equipo de especialistas.",
    "Que ofrezca toda la información necesaria, detallada y concreta sobre las expectativas.",
    "Que oriente con manuales y estándares de calidad, desde el inicio.",
    "Con indicadores y referencias detalladas, para tomar decisiones sustentadas.",
    "Con claridad, a través de regulaciones e información minuciosa.",
    "Sistemas y protocolos establecidos y probados con éxito.",
    "Que ofrezca el tiempo suficiente, para analizar los cambios y tomar decisiones.",
    "Objetivo, en donde prevalezca el pensamiento crítico y no las emociones.",
    "Profesional, especializado o académico, que permita la investigación.",
    "Con planes claros que se cumplan de principio a fin.",
    "Amable y libre de confrontaciones."
  ],
  23: [
    "Dinámico, con libertad de movimiento, libre de controles y detalles.",
    "Variado socialmente, con contacto con muchas personas a quienes pueda apoyar.",
    "Con posibilidad de impulsar positivamente a los demás.",
    "Abierto al cambio y a la innovación, con cierto grado de estabilidad.",
    "Que enfrente las crisis de forma directa y rápida, considerando a las personas.",
    "Que ofrezca retos y desafíos con un clima amable y amigable.",
    "Democrático, con información clara sobre las responsabilidades.",
    "Que impulse el logro de resultados rápidos e innovadores, a través del equipo.",
    "Que motive a la gente a usar métodos probados para lograr los objetivos ágilmente."
  ],
  24: [
    "Dinámico, con libertad de movimiento, libre de controles y detalles.",
    "Variado socialmente, con contacto con muchas personas claves.",
    "Abierto al cambio y a la innovación, con cierto grado de estabilidad.",
    "Que atienda las crisis de forma directa y ágil, considerando a la gente y a los protocolos.",
    "Con retos y desafíos, en un clima amable y amigable.",
    "Democrático, con visión de futuro e información clara sobre las responsabilidades.",
    "Que impulse el logro de resultados rápidos e innovadores, a través del equipo.",
    "Que motive a la gente a usar métodos probados para lograr los objetivos ágilmente.",
    "Con procesos ágiles que ayude a ganar tiempo en el logro de los resultados."
  ],
  25: [
    "Dinámico y entusiasta, con poder para tomar decisiones y trabajar en equipo.",
    "Que favorezca los proyectos novedosos.",
    "Con tiempo para diseñar e implementar un plan detallado a buen ritmo.",
    "Confiable y retador, que permita la reflexión y tome en cuenta los detalles.",
    "Que considere las necesidades de las personas, pero con sentido de urgencia.",
    "Con desafíos que se enfrenten con creatividad y metodologías probadas.",
    "Competitivo pero respetuoso.",
    "Armonioso, amable y con buen nivel de energía."
  ],
  26: [
    "Dinámico y entusiasta, con poder para tomar decisiones.",
    "Que se trabaje en equipo.",
    "Que atienda las crisis de forma directa y ágil, considerando a la gente y a los protocolos.",
    "Con proyectos novedosos y tiempo para diseñar un plan e implementarlo a buen ritmo.",
    "Confiable y retador.",
    "Que permita la reflexión y tome en cuenta los detalles.",
    "Que considere las necesidades de las personas con sentido de urgencia.",
    "Con desafíos que se enfrenten con creatividad y metodologías probadas.",
    "Competitivo pero respetuoso.",
    "Armonioso, amable y con buen nivel de energía."
  ],
  27: [
    "Que enfrente las crisis de forma directa y rápida.",
    "Dinámico, flexible, activo, espontaneo, competitivo y entusiasta.",
    "Con poder para tomar decisiones.",
    "Libre de controles, detalles y supervisión estrecha.",
    "Con retos y proyectos novedosos, que favorezca el logro del éxito.",
    "Moderno, orientado al futuro, con visión global y con sentido de urgencia.",
    "Con variedad de personas, que permita la expresión de ideas, en muchos temas.",
    "Que impulse el cambio y se pueda cuestionar el estatus quo.",
    "Que actualice protocolos con creatividad y criterio."
  ],
  28: [
    "Que favorezca su liderazgo y autoridad.",
    "En el que se puedan expresar ideas y opiniones, en una diversidad de temas.",
    "Dinámico, espontáneo, entusiasta, flexible, retador, libre de detalles y controles.",
    "Que permita cuestionar el estatus quo, innovar con los procesos y mejorarlos.",
    "En el que se pueda aportar criterio y creatividad.",
    "Que enfrente las crisis de forma rápida y directa, manteniendo el cuidado de la calidad.",
    "En donde se pueda conocer gente nueva y mantener contacto con personas conocidas.",
    "Con novedades y cambios.",
    "Que garantice el éxito y la excelencia, en la obtención rápida de resultados.",
    "Moderno y orientado al futuro.",
    "Ambiente especializado para prever y resolver problemas.",
    "Competitivo, con un equipo de profesionales, que estimule el progreso y la preparación."
  ],
  29: [
    "Retador y desafiante, con tareas especializadas.",
    "Que permita la responsabilidad y poder sobre las decisiones.",
    "Con líderes que orienten de forma directa y específica.",
    "Con libertad de movimiento.",
    "Con expertos accesibles para consultas cada vez que se tengan dudas.",
    "Que permita conocer profesionales especializados.",
    "Con facilidad para mantener contacto con personas conocidas.",
    "Con libertad para crear e innovar sistemas y procesos.",
    "En donde se asuman riesgos calculados con un dinamismo equilibrado.",
    "Que impulse resultados con sentido de urgencia.",
    "Con tiempo para analizar información y recabar hechos."
  ],
  30: [
    "Retador y desafiante con tareas especializadas.",
    "Dinámico, motivante y con diversidad, en la solución de nuevos problemas.",
    "Que impulse resultados de calidad, con sentido de urgencia.",
    "Con líderes que orienten de forma directa y específica.",
    "Que ofrezca libertad de movimiento.",
    "Que permita la responsabilidad y poder sobre las decisiones.",
    "Con expertos accesibles para consultas cada vez que se tengan dudas.",
    "Con información, indicadores y data disponible para analizar.",
    "Que permita conocer personas claves y mantener contacto con gente conocida.",
    "Con libertad para crear e innovar sistemas y procesos.",
    "En donde se asuman riesgos calculados con un dinamismo equilibrado.",
    "De logros, cambios, retos y con visión de futuro."
  ],
  31: [
    "Retador y desafiante, con cierto nivel de estabilidad.",
    "Con sistemas y metodologías probadas.",
    "Que permita innovar en los protocolos, para lograr cada vez mayores niveles de calidad.",
    "Que permita la responsabilidad y poder sobre las decisiones.",
    "En donde se trabaje en equipo y también de forma independiente.",
    "Con proyectos novedosos y tiempo para organizar y aplicar un plan paso a paso.",
    "Confiable pero retador.",
    "Dinámico y reflexivo.",
    "Con visión global pero que cuide los detalles y las necesidades de las personas.",
    "En donde se analice información, para tomar decisiones rápidas y de calidad."
  ],
  32: [
    "En donde se innove en los protocolos, para lograr cada vez mayores niveles de calidad.",
    "Con retos desafiantes para ser afrontados con sistemas y metodologías probadas.",
    "Con responsabilidad y poder sobre las decisiones y que a la vez se trabaje en equipo.",
    "Que estimule la innovación, el pensamiento analítico y el uso de metodologías exitosas.",
    "Con proyectos novedosos y tiempo para organizar y aplicar un plan paso a paso.",
    "Desafiante, pero con cierto nivel de estabilidad.",
    "Confiable y retador.",
    "Dinámico y reflexivo.",
    "Con visión global, pero que cuide de los detalles.",
    "Que garantice los resultados de calidad y ayude a las personas.",
    "En donde se analice información, para tomar decisiones rápidas y de calidad."
  ],
  33: [
    "Privado con gente conocida.",
    "Armonioso y amistoso, pero activo, con energía y que aprecie la preparación personal.",
    "Con retos para ser afrontados con sistemas y metodologías probadas.",
    "Con proyectos novedosos y tiempo para organizar y aplicar un plan paso a paso.",
    "Que permita la responsabilidad y poder sobre las decisiones.",
    "Que se trabaje en equipo.",
    "Con visión global y de buen ritmo.",
    "Que cuide los detalles, la calidad y las necesidades de las personas.",
    "Que permita innovar en los protocolos.",
    "En donde se logren cada vez mayores niveles de calidad y resultados más rápidos.",
    "Que estimule la innovación, el pensamiento analítico y el uso de metodologías exitosas.",
    "Desafiante con cierto nivel de estabilidad.",
    "Confiable pero retador.",
    "Dinámico y reflexivo.",
    "En donde se analice información para tomar decisiones rápidas, manteniendo la calidad."
  ],
  34: [
    "En donde se pueda analizar e innovar procesos y protocolos.",
    "En el que se pueda agilizar los resultados y mejorar la calidad.",
    "Técnico y profesional con retos para ser afrontados con sistemas exitosos.",
    "Con proyectos novedosos y tiempo para implementar con disciplina un plan.",
    "Que permita la responsabilidad y poder sobre las decisiones.",
    "En el que se pueda progresar.",
    "Con visión global y de buen ritmo, pero que cuide los detalles y la calidad.",
    "Que estimule la innovación, el pensamiento analítico y el uso de metodologías exitosas.",
    "Desafiante con cierto nivel de estabilidad.",
    "Dinámico y reflexivo.",
    "En donde se analice información para tomar decisiones rápidas, manteniendo la calidad."
  ],
  35: [
    "Democrático, dinámico y de ritmo rápido en la toma de decisiones.",
    "Orientado al futuro y moderno.",
    "Con foco en los resultados a través del equipo, matizado con estabilidad y reflexión.",
    "En donde se conozca gente nueva y se mantenga el contacto con personas queridas.",
    "En donde se trabaje en equipo y se atiendan las necesidades de las personas.",
    "Libre de control, de detalles y vigilancia.",
    "De innovación, retos, desafíos, creatividad y abierto al cambio.",
    "Ambiente amigable y confiable."
  ],
  36: [
    "Democrático, dinámico, con libertad de movimiento y poco control.",
    "Orientado al futuro, moderno y diverso.",
    "Con foco en las personas y en resultados, matizado con estabilidad y reflexión.",
    "En donde se conozca gente nueva y se mantenga el contacto con personas queridas.",
    "En el que se trabaje en equipo, libre de conflictos y confrontaciones.",
    "Con retos y desafíos.",
    "Que atienda las necesidades de las personas y sus emociones.",
    "Amigable y confiable."
  ],
  37: [
    "Amigable, en donde pueda ejercer su liderazgo.",
    "Democrático, dinámico, con libertad de movimiento y poco control.",
    "Con diversidad y abierto al cambio.",
    "En donde se conozca gente nueva y se mantenga el contacto con personas conocidas",
    "En el que se trabaje en equipo.",
    "Orientado al futuro, moderno y diverso.",
    "Con foco en las personas y en resultados, matizado con cierto grado de estabilidad.",
    "Libre de conflictos y confrontaciones, pero con retos y desafíos.",
    "Que atienda las necesidades de las personas para favorecer la obtención de resultados."
  ],
  38: [
    "En donde se trabaje en equipo, con dinamismo y energía.",
    "Moderno, jovial, con visión de futuro, amigable y servicial.",
    "Diverso, con foco en las personas.",
    "En donde se conozca gente nueva y se mantenga el contacto con personas cercanas.",
    "Democrático, con libertad de movimiento y poco control.",
    "Con personas disponibles para consultar dudas.",
    "Libre de conflictos y confrontaciones.",
    "Que atienda las necesidades de las personas de forma activa."
  ],
  39: [
    "Amigable, que esté libre de rutinas, detalles y controles.",
    "Innovador, con riesgos, desafíos, visión de futuro y retos profesionales.",
    "Dinámico, de ritmo rápido, diverso y creativo.",
    "En el que se diseñen mejores protocolos para lograr resultados.",
    "Democrático, en donde se puedan expresar ideas libremente.",
    "Con profesionales creativos y con alto nivel de rendimiento."
  ],
  40: [
    "Creativo.",
    "Con visión de futuro, retos profesionales e innovador en los protocolos.",
    "Amigable, personal y agradable; que esté libre de rutinas, detalles y control.",
    "Que tome en cuenta su liderazgo y la obtención de resultados de calidad.",
    "Con desafíos y riesgos, con cierto nivel de cautela.",
    "Democrático, en donde se puedan expresar ideas libremente.",
    "Con profesionales creativos y alto nivel de rendimiento.",
    "Dinámico y con diversidad, que ofrezca tiempo para analizar y cuidar de la calidad.",
    "Con facilidad para relacionarse con mucha gente clave."
  ],
  41: [
    "Amigable, dinámico, profesional y retador. Libre de rutinas, detalles y control.",
    "Creativo, con visión de futuro, retos profesionales e innovador en los protocolos.",
    "Que tome en cuenta los aportes en la toma de decisiones.",
    "Con desafíos y riesgos con cierto nivel de cautela.",
    "Democrático, en donde se pueda expresar ideas libremente.",
    "Profesional y que ofrezca tiempo para analizar y cuidar de la calidad, ágilmente.",
    "Con alto nivel de rendimiento.",
    "Que facilite el contacto con mucha gente clave."
  ],
  42: [
    "Amigable, confortable, diplomático y profesional.",
    "Cuidadoso de la calidad, como soporte en la ejecución de las tareas.",
    "Ágil y dinámico en la rutina, y cauteloso en situaciones complejas.",
    "Estimulante y retador en el logro de resultados, con altos estándares de calidad.",
    "Que cumpla los procesos y atienda las necesidades de las personas.",
    "Profesional y que ofrezca tiempo para analizar y cuidar de la calidad, ágilmente.",
    "Que facilite el contacto con personas y el trabajo en equipo."
  ],
  43: [
    "Que facilite el contacto con personas claves y el trabajo en equipo.",
    "Estable, amigable, sincero, privado, personal y agradable.",
    "Con pocos conflictos y poco hostil.",
    "Con libertad de movimiento y de expresión.",
    "Que favorezca el desarrollo profesional.",
    "Un lugar en donde se pueda hacer carrera.",
    "Que provea información clara y detallada.",
    "Que cuide los procedimientos, para garantizar altos niveles de calidad.",
    "Que favorezca la toma de decisiones basada en análisis y trabajo de equipo."
  ],
  44: [
    "Que facilite el contacto con personas claves y el trabajo en equipo.",
    "Que favorezca el desarrollo profesional.",
    "Que cuide los protocolos y métodos, para garantizar altos niveles de calidad.",
    "Estable, amigable, sincero, privado, personal y agradable.",
    "Con pocos conflictos y poco hostil.",
    "Con libertad de movimiento y de expresión.",
    "Un lugar en donde se pueda hacer carrera.",
    "Que provea información clara y detallada.",
    "Que favorezca la toma de decisiones basada en análisis y trabajo de equipo.",
    "Con retos técnicos."
  ],
  45: [
    "Estable, amigable, sincero, privado, personal y agradable.",
    "Que facilite el contacto con personas claves.",
    "Que favorezca el desarrollo profesional.",
    "Con pocos conflictos y poco hostil.",
    "Con libertad para expresarse de forma respetuosa.",
    "En donde se pueda hacer carrera, progresar y aprender.",
    "Que provea información clara y detallada.",
    "Que favorezca la toma de decisiones basada en análisis y trabajo de equipo.",
    "Que aplique los sistemas, para garantizar la calidad."
  ],
  46: [
    "Amigable, que permita vincularse con personas claves.",
    "Que beneficie el control de calidad y el respeto a las normas y procesos.",
    "Sincero, privado, personal y agradable, con pocos conflictos y poco hostil.",
    "Que favorezca el desarrollo profesional y reconozca los logros alcanzados.",
    "Con libertad de movimiento y personas que cuiden de la calidad.",
    "En donde se pueda hablar libremente, educado y diplomático.",
    "Que provea información clara y detallada para tomar decisiones.",
    "Estable y a la vez lleno de diversidad.",
    "Que favorezca la toma de decisiones basada en análisis y trabajo de equipo."
  ],
  47: [
    "Amigable que permita innovar con procedimientos y metodologías probadas.",
    "Que beneficie el trabajo de equipo.",
    "En donde las decisiones se tomen de manera reflexiva y sin demoras.",
    "Que facilite el contacto con personas enfocadas en la obtención de resultados.",
    "Estable pero que se puedan promover ideas innovadoras.",
    "que permita desarrollar proyectos novedosos.",
    "Con tiempo para diseñar y aplicar un plan paso a paso.",
    "Que ofrezca tiempo para la reflexión.",
    "Que tome en cuenta los detalles, pero a buen ritmo.",
    "En donde se debatan ideas creativas de forma respetuosa.",
    "Que se ocupe de las necesidades de la gente, para así facilitar la obtención de resultados.",
    "Armonioso, confiable, estable, sincero, positivo, amistoso, activo y con energía."
  ],
  48: [
    "Que ofrezca información detallada sobre los sistemas establecidos.",
    "Amigable, que permita innovar con procedimientos y metodologías probadas.",
    "Que facilite el contacto con personas enfocadas en la obtención de resultados.",
    "Que aclare, de forma detallada, las áreas claves de responsabilidad.",
    "Con un ritmo amable, positivo y a la vez dinámico para actuar.",
    "Que controle, positivamente, la presión y las urgencias.",
    "Con respaldo en procesos exitosos para enfrentar los problemas difíciles.",
    "Que beneficie el trabajo de equipo y la creatividad.",
    "En donde las decisiones se tomen de manera reflexiva, lógica y sin demoras."
  ],
  49: [
    "Amigable, que permita vincularse, hacer equipo y que brinde retos.",
    "Que ofrezca información detallada sobre los sistemas establecidos.",
    "En donde se puedan construir y mantener redes y contactos cercanos.",
    "Que controle, positivamente, la presión y las urgencias.",
    "Sincero, de confianza, personal, creativo y agradable.",
    "Con pocos conflictos y poco hostil.",
    "En donde la comunicación y la credibilidad sean valores importantes.",
    "Que atienda las necesidades de las personas y se preocupe por la gente.",
    "Con libertad de expresión.",
    "En donde se pueda conversar y debatir ideas, de forma amigable y abierta.",
    "Que ofrezca orientación cuando haya dudas en la toma de decisiones.",
    "Con respaldo en procesos exitosos para enfrentar los problemas difíciles.",
    "Que aclare, con detalle, las áreas claves de responsabilidad.",
    "Con sistemas y métodos probados y con posibilidad de innovar en ellos.",
    "Que facilite la toma de decisiones reflexivas y lógicas, con cierto grado de creatividad."
  ],
  50: [
    "Amigable, que permita vincularse, hacer equipo y que brinde retos.",
    "Que ofrezca información detallada sobre los sistemas establecidos.",
    "Que aclare, con detalle, las áreas claves de responsabilidad.",
    "Que facilite la toma de decisiones reflexivas y lógicas, con cierto grado de creatividad.",
    "Con un ritmo amable y positivo para actuar, pero a la vez dinámico.",
    "Que controle, positivamente, la presión y las urgencias.",
    "Con respaldo en procesos exitosos, para enfrentar los problemas difíciles.",
    "Que ofrezca orientación cuando haya dudas en la toma de decisiones.",
    "Sincero, de confianza, personal, creativo y agradable.",
    "Con pocos conflictos y poco hostil.",
    "En donde la comunicación y la credibilidad sean valores importantes.",
    "Que atienda las necesidades de las personas y se preocupe por la gente.",
    "Con sistemas y métodos probados y con posibilidad de innovar en ellos."
  ],
  51: [
    "Sincero, personal y agradable; con pocos conflictos y poco hostil.",
    "Amigable, que permita vincularse, hacer equipo y cuidar de la calidad.",
    "Que favorezca el desarrollo profesional.",
    "Que ofrezca información detallada sobre los sistemas y las responsabilidades.",
    "Que aclare la línea de autoridad.",
    "Estable, que ofrezca tiempo y explicaciones detalladas para afrontar el cambio.",
    "Que controle, positivamente, la presión y las urgencias.",
    "En donde la ética sea un valor importante.",
    "Alegre y relajado, abierto a la comunicación."
  ],
  52: [
    "Sincero, personal y agradable; con pocos conflictos y poco hostil.",
    "Que ofrezca información amplia y detallada.",
    "Claro en la comunicación, sin ambigüedades.",
    "Con un alto sentido de confianza y credibilidad.",
    "Amigable, que permita vincularse, hacer equipo y cuidar de la calidad.",
    "Que favorezca el desarrollo profesional.",
    "Estable y predecible.",
    "Que ofrezca tiempo y explicaciones detalladas para afrontar el cambio.",
    "Que ofrezca información detallada sobre los sistemas y las responsabilidades.",
    "Que aclare la línea de autoridad.",
    "Que controle, positivamente, la presión y las urgencias.",
    "En donde la ética y la calidad tengan prioridad."
  ],
  53: [
    "Que ofrezca información detallada sobre los sistemas y las responsabilidades.",
    "Que aclare la línea de autoridad.",
    "Que ofrezca tiempo y explicaciones detalladas para adaptarse al cambio.",
    "Claro en la comunicación, sin ambigüedades.",
    "Con un alto sentido de confianza y credibilidad.",
    "Que favorezca el desarrollo profesional.",
    "Estable y predecible.",
    "En donde la ética y la calidad tengan prioridad.",
    "Sincero, privado, personal y agradable; con pocos conflictos y poco hostil.",
    "Que ofrezca soporte para aclarar dudas y superar las inseguridades para decidir."
  ],
  54: [
    "Que ofrezca información detallada sobre los sistemas y las responsabilidades.",
    "Que aclare la línea de autoridad.",
    "Claro en la comunicación, sin ambigüedades.",
    "Con un alto sentido de confianza y credibilidad.",
    "Que ofrezca tiempo y explicaciones detalladas, para adaptarse al cambio.",
    "Que favorezca el desarrollo profesional.",
    "Estable y predecible.",
    "En donde la ética, la gentileza y la calidad tengan prioridad.",
    "Sincero, privado, personal, amigable y agradable; con pocos conflictos y poco hostil.",
    "Que ofrezca soporte para aclarar dudas y superar las inseguridades para decidir.",
    "Con libertad de movimiento."
  ],
  55: [
    "Que facilite información detallada sobre las normas, los sistemas y las responsabilidades.",
    "Que ofrezca libertad para proponer nuevas ideas o modificaciones a los sistemas.",
    "Que ofrezca tiempo para reflexionar activamente las decisiones.",
    "Que se preocupe por la estabilidad y el logro de resultados.",
    "Ambiente privado para pensar en ideas creativas y soluciones.",
    "Que controle los estándares de calidad.",
    "Que ofrezca soporte para aclarar dudas."
  ],
  56: [
    "Que facilite información detallada sobre las normas, sistemas y responsabilidades.",
    "Estable, libre de conflictos y democrático.",
    "Que ofrezca libertad para proponer modificaciones a los sistemas.",
    "Ambiente privado para analizar información, hechos e indicadores.",
    "Que brinde el tiempo necesario para analizar y reflexionar activamente las decisiones.",
    "Que de tiempo para asumir los cambios.",
    "Que tome en cuenta la estabilidad y la motivación al logro.",
    "Con soporte para aclarar dudas.",
    "Que controle los estándares de calidad y cuide de la seguridad.",
    "Que cuide de las necesidades de la gente."
  ],
  57: [
    "Sincero, con pocas personas, más bien íntimo y privado.",
    "Estable y predecible, que facilite detalles y tiempo, para adaptarse a los cambios.",
    "Que ofrezca información detallada sobre los sistemas, normas y responsabilidades.",
    "Que ofrezca libertad para proponer modificaciones a los sistemas.",
    "Libre de conflictos y democrático.",
    "Que ofrezca el tiempo necesario para analizar y reflexionar activamente las decisiones.",
    "Que cuide de las necesidades de la gente.",
    "Que ofrezca soporte para aclarar dudas.",
    "Ambiente privado para analizar información, hechos e indicadores.",
    "Que controle los estándares de calidad y cuide de la seguridad."
  ],
  58: [
    "Que ofrezca información detallada sobre los protocolos y las responsabilidades.",
    "Estable que facilite detalles y tiempo, para adaptarse a los cambios.",
    "Que ofrezca el tiempo necesario para analizar y reflexionar las decisiones.",
    "Sincero, con personas de confianza, cercanas.",
    "Ambiente privado para analizar información, hechos e indicadores.",
    "Que controle los estándares de calidad y cuide de la seguridad.",
    "Que cuide de las necesidades de la gente.",
    "Con libertad para proponer modificaciones a los sistemas.",
    "Libre de conflictos y democrático.",
    "Que ofrezca soporte para aclarar dudas."
  ],
  59: [
    "Que ofrezca información y explicaciones, sobre las responsabilidades.",
    "Que dé espacio para moverse creativa y libremente.",
    "Abierto a progresar a mayores niveles de liderazgo, poder y responsabilidad.",
    "Con gente experta que cuide de la calidad.",
    "Con espacio privado para pensar en solitario.",
    "Con desafíos y retos para afrontarlos detalladamente.",
    "Que permita probar nuevos procedimientos, para mejorar la calidad.",
    "En donde abunde la información y se pueda investigar.",
    "Que brinde apoyo en las demoras."
  ],
  60: [
    "Que ofrezca información sobre las expectativas y responsabilidades.",
    "Que favorezca el análisis y la creatividad.",
    "Con contacto con mucha gente experta, que cuide de la calidad.",
    "En donde se puedan probar nuevos métodos para elevar la calidad.",
    "Con espacio privado para pensar en solitario.",
    "Que dé libertad de movimiento, manteniendo un plan claro.",
    "Entusiasta y desafiante, que respete las normas establecidas.",
    "En donde abunde la información y se pueda investigar.",
    "Que brinde apoyo en las demoras."
  ],
  61: [
    "Que ofrezca información y explicaciones detalladas, sobre las tareas y responsabilidades.",
    "Que dé espacio para moverse creativa y libremente.",
    "Retador y desafiante.",
    "Con contacto con mucha gente experta, que cuide de la calidad.",
    "Activo y dinámico que permita la privacidad.",
    "En donde abunde la información y se pueda investigar.",
    "Que brinde apoyo en las demoras."
  ],
  62: [
    "Que ofrezca información sobre las expectativas y responsabilidades.",
    "Ambiente con gente experta, que cuide de la calidad.",
    "Que favorezca el análisis, la creatividad y el contacto con mucha gente.",
    "En donde abunde la información y se pueda investigar.",
    "Un lugar para probar nuevos métodos, con el propósito de elevar la calidad.",
    "Con libertad de movimiento, pero con planes claros.",
    "Con espacio privado para pensar en solitario.",
    "Ambiente entusiasta, que proponga desafíos, para afrontarlos detalladamente.",
    "Que brinde apoyo en las demoras."
  ],
  63: [
    "Que ofrezca información sobre las expectativas y responsabilidades.",
    "Ambiente social, con gente experta, que favorezca el análisis y la reflexión.",
    "Con libertad de expresión.",
    "Con libertad de movimiento y exento de burocracia.",
    "Amistoso, con buen clima social, sin muchos conflictos ni confrontaciones.",
    "En donde se cumplan las normas y los procedimientos con entusiasmo.",
    "Que brinde apoyo en la toma de decisiones.",
    "Estable y predecible, con algunas oportunidades de cambio y diversidad.",
    "Que entrene y motive a la gente en el cumplimiento de los protocolos.",
    "Que premie el respeto a la seguridad y a la calidad.",
    "Que facilite el desarrollo y el aprendizaje continuo."
  ],
  64: [
    "Que premie el respeto a la seguridad y a la calidad.",
    "Ambiente social, con gente experta, que favorezca el análisis y la reflexión.",
    "Que facilite el desarrollo y el aprendizaje continuo.",
    "Con libertad de expresión.",
    "Que ofrezca información sobre las expectativas y responsabilidades.",
    "Con libertad de movimiento y exento de burocracia.",
    "Amistoso, con buen clima social, sin muchos conflictos ni confrontaciones.",
    "En donde se cumplan los procedimientos, con entusiasmo y optimismo.",
    "Que brinde apoyo en la toma de decisiones.",
    "Estable y predecible, con algunas oportunidades de cambio y diversidad.",
    "Que entrene y motive a la gente en el cumplimiento de los protocolos."
  ],
  65: [
    "Que ofrezca métodos y procedimientos probados y a la vez sea dinámico.",
    "Activo y libre de conflictos.",
    "Amigable y especializado; con un equipo de alto nivel de rendimiento.",
    "Que facilite toda la información disponible para planificar y decidir.",
    "Democrático, con libertad de movimiento.",
    "Abierto a la comunicación y receptivo a los aportes.",
    "Que facilite tiempo suficiente para adaptarse a los cambios.",
    "Ambiente social, con gente experta, que favorezca el análisis, en un clima positivo.",
    "Que entrene y motive a la gente en el cumplimiento de los protocolos."
  ],
  66: [
    "Que se preocupe sinceramente de las necesidades de la gente.",
    "Que facilite tiempo suficiente para adaptarse a los cambios.",
    "Que ofrezca información sobre las expectativas y responsabilidades.",
    "Libre de conflictos y hostilidades.",
    "Amigable, amable y especializado; con un equipo de alto nivel de rendimiento.",
    "Que facilite toda la información disponible para planificar y decidir.",
    "Seguro, que cuide de la calidad y cumpla con las normas y protocolos.",
    "Racional y lógico más que emocional.",
    "Que brinde apoyo en la toma de decisiones.",
    "Que permita la reflexión en privado, con tiempo suficiente, antes de decidir."
  ],
  67: [
    "En donde la creatividad, el análisis y el pensamiento crítico sean importantes.",
    "En donde se puedan correr riesgos calculados.",
    "Especializado para lograr resultados de calidad, de forma metódica y rápida.",
    "Que permita mejorar los protocolos y las normas, en pro de la calidad.",
    "Que ofrezca libertad para innovar en los sistemas, con un clima amable.",
    "Que emprenda proyectos, con visión de futuro.",
    "Objetivo y cuidadoso de los detalles.",
    "Con información y explicaciones detalladas, sobre las tareas y responsabilidades.",
    "Con libertad de movimiento, pero con controles establecidos.",
    "Que de tiempo para sopesar las fortalezas y las debilidades de las opciones",
    "Que facilite el seguimiento de los planes, de principio a fin."
  ],
  68: [
    "Que desarrolle ideas y logre resultados con excelencia, en un clima amable.",
    "Con información y explicaciones detalladas, sobre las tareas y responsabilidades.",
    "Con libertad de movimiento.",
    "En donde la creatividad, el análisis y el pensamiento crítico, sean importantes.",
    "En donde se puedan correr riesgos calculados.",
    "Con espacios privados para pensar en solitario.",
    "Con protocolos, normas y estándares de calidad establecidos, abiertos a ser mejorados.",
    "Con libertad para innovar, meticulosamente, en los sistemas.",
    "Que emprenda proyectos, con visión de futuro.",
    "Especializado, objetivo y cuidadoso de los detalles.",
    "Con tiempo para sopesar las fortalezas y las debilidades de las opciones.",
    "Que haga seguimiento de principio a fin."
  ],
  69: [
    "Con información y explicaciones detalladas, sobre las tareas y responsabilidades.",
    "Con libertad de movimiento.",
    "En donde se emprendan proyectos, con visión de futuro.",
    "Objetivo y cuidadoso de la calidad.",
    "En donde la creatividad y el análisis sean importantes.",
    "En donde se corran riesgos calculados.",
    "Especializado, que favorezca el logro rápido de resultados de calidad, de forma metódica.",
    "Con gente experta.",
    "Con espacios privados para pensar en solitario.",
    "Con protocolos, normas y estándares de calidad establecidos, abiertos a ser mejorados.",
    "Con libertad para innovar en los sistemas, manteniendo un clima amable.",
    "Con desafíos y retos para afrontarlos de forma meticulosa.",
    "Con tiempo para sopesar las opciones.",
    "En donde se haga seguimiento de principio a fin."
  ],
  70: [
    "Que ofrezca información detallada sobre las normas y los protocolos.",
    "Que aclare las expectativas y las responsabilidades.",
    "Que ofrezca datos, hechos y evidencias para analizar.",
    "Lógico, racional, metódico, práctico y poco emocional.",
    "Organizado y limpio en donde prevalezca la disciplina de forma activa.",
    "Con gente especializada y con espacios privados para pensar en solitario.",
    "Sincero, con pocas personas, más bien íntimo.",
    "Que ofrezca el tiempo necesario para analizar activamente las decisiones.",
    "Que facilite soporte para aclarar dudas."
  ],
  71: [
    "Un lugar amigable que ofrezca retos.",
    "Servicial y dinámico.",
    "Ambiente social, en donde se resuelvan problemas y se enfrenten desafíos.",
    "Abierto al cambio y a la reflexión.",
    "Que permita la creatividad y el orden.",
    "Que ofrezca novedades, con un cierto nivel de estabilidad.",
    "Relajado y de puertas abiertas."
  ],
  72: [
    "Con libertad de movimiento.",
    "Democrático, que facilite información detallada.",
    "Amigable, que ofrezca retos.",
    "Que asegure un buen nivel de control de calidad.",
    "Que favorezca la comunicación sustentada en análisis, lógica y dinamismo.",
    "En donde se reúna gente experta, para debatir ideas de soluciones de calidad.",
    "Activo y dinámico con lugar para la privacidad, cuando sea necesario."
  ],
  73: [
    "Innovador y al mismo tiempo ordenado y limpio.",
    "En donde se alcancen resultados excelentes, en un clima gentil.",
    "Que brinde información detallada, para realizar nuevos diseños.",
    "Con libertad de movimiento, para abordar soluciones de calidad",
    "En donde la creatividad, la lógica y la razón predominen sobre lo emocional.",
    "Que explore los retos tanto de forma global como de detalles.",
    "Democrático, que aclare las expectativas y que asegure la calidad.",
    "En donde se reúna gente experta, para debatir de calidad y soluciones.",
    "Con privacidad, cuando sea necesario pensar en solitario."
  ],
  74: [
    "Amable, en donde se pueda consultar las normas, procedimientos y protocolos.",
    "Que permita vincularse con los demás y desarrollarse profesionalmente.",
    "Que brinde información detallada sobre las responsabilidades.",
    "Libre de conflictos y poco hostil.",
    "En donde se reúna gente experta, para conversar de calidad, con respeto.",
    "Sincero, personal y agradable.",
    "Con privacidad para pensar en solitario.",
    "En donde se equilibre la lógica, la razón, la empatía y las emociones.",
    "Con los niveles de autoridad claros.",
    "Estable, con tiempo para planificar y adaptarse.",
    "Democrático, que aclare las expectativas al detalle y asegure la calidad."
  ]
};
