<template>
  <div>
    <report-section-title>Liderazgo 1 a 1: Autoevaluación</report-section-title>
    <div v-for="(table, index) in tables" :key="index" class="table-container">
      <h2>{{ table.title }}</h2>
      <table>
        <tbody>
          <tr v-for="(feedback, index) in table.feedback" :key="index">
            <td>
              <strong>{{ feedback.label }}</strong>
              <div v-for="(item, index) in feedback.items" :key="index">
                {{ item }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  jobFeedback,
  motivationFeedback,
  relationshipFeedback
} from "@/model/one-to-one/feedbacks.js";

export default {
  name: "LeadershipBehaviorTables",
  props: {
    assessedCounts: {
      type: Object,
      required: true
    }
  },
  computed: {
    tables() {
      return [
        {
          title: "RESULTADOS VINCULADOS CON LA TAREA",
          feedback: this.getFeedbacks(jobFeedback, this.assessedCounts.job)
        },
        {
          title: "RESULTADOS VINCULADOS CON LA MOTIVACIÓN",
          feedback: this.getFeedbacks(
            motivationFeedback,
            this.assessedCounts.motivation
          )
        },
        {
          title: "RESULTADOS VINCULADOS CON LA RELACIÓN",
          feedback: this.getFeedbacks(
            relationshipFeedback,
            this.assessedCounts.relationships
          )
        }
        /*   {
          title: "RESULTADOS INTEGRALES Y RECOMENDACIONES",
          feedback: this.getFeedbacks(
            integralFeedback,
            this.assessedCounts.job +
              this.assessedCounts.motivation +
              this.assessedCounts.relationships 
          )
        } */
      ];
    }
  },
  methods: {
    getFeedbacks(feedbacks, score) {
      return feedbacks.filter(
        feedback => score >= feedback.range.min && score <= feedback.range.max
      );
    }
  }
};
</script>

<style scoped>
table {
  width: 90%;
  margin: auto;
  border-collapse: collapse;
}

h2 {
  width: 90%;
  margin: auto;
  padding: 10px;
  border: 1px solid #dddddd;
  text-align: center;
  font-size: 1.2rem;
}

td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.table-container {
  margin-bottom: 20px;
}

div {
  list-style-type: none;
  text-align: justify;
}
</style>
