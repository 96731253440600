<template>
  <div>
    <template v-if="isAssessed">
      <div>
        <p>¡Hola! Por favor lee las instrucciones antes de comenzar.</p>
        <p>Diagnóstico de madurez del equipo:</p>
        <p>
          Con esta herramienta conocerás el nivel de autonomía e innovación de
          tu equipo y comprenderás cómo incrementar su desempeño.
        </p>
        <p>Instrucciones</p>
        <ol>
          <li>
            Preparación:
            <ul>
              <li>Lee detenidamente estas instrucciones antes de comenzar.</li>
              <li>
                Asegúrate de tener un espacio tranquilo y libre de
                interrupciones.
              </li>
              <li>
                Dedica aproximadamente 15 minutos para completar el
                cuestionario.
              </li>
            </ul>
          </li>
          <li>
            Cuestionario:
            <ul>
              <li>
                Responde las 7 preguntas seleccionando la opción que mejor
                describa la realidad actual de tu equipo.
              </li>
              <li>
                No analices demasiado tus respuestas. Confía en tu primera
                impresión.
              </li>
              <li>
                Avanza al siguiente ítem solo después de haber respondido el
                anterior.
              </li>
            </ul>
          </li>
        </ol>
        <p>¿Listo/a para comenzar? ¡EMPECEMOS!</p>
      </div>
    </template>

    <template v-else>
      <div>
        <p>¡Hola y gracias por participar en esta evaluación anónima!</p>
        <p>Diagnóstico de madurez del equipo:</p>
        <p>
          Tu colaboración nos permitirá conocer la percepción de los miembros
          del equipo sobre su nivel de desarrollo actual. Esta información será
          fundamental para identificar áreas de mejora y potenciar aún más el
          desempeño del equipo como unidad.
        </p>
        <p>Instrucciones</p>
        <ol>
          <li>
            Para completar la encuesta, sigue estos sencillos pasos:
            <ul>
              <li>
                Lee atentamente cada pregunta y selecciona la opción que mejor
                describa la situación actual del equipo.
              </li>
              <li>
                Básate en tus experiencias cotidianas y sé honesto/a en tus
                respuestas.
              </li>
              <li>
                No analices demasiado tus respuestas. Responde de manera natural
                y espontánea.
              </li>
            </ul>
          </li>
        </ol>
        <p>
          ¡Tu participación es muy importante! Tus respuestas nos ayudarán a
          conocer mejor al equipo y diseñar las mejores estrategias para
          impulsar su desarrollo.
        </p>
        <p>¿Estás listo/a para comenzar? ¡Empecemos!</p>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import assessmentMixin from "@/mixins/assessmentMixin";

export default defineComponent({
  mixins: [assessmentMixin],
  name: "StartTextFOne",
  computed: {
    isAssessed() {
      return this.assessedId === null;
    }
  }
});
</script>
