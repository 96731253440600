export default {
  1.1: [
    "Firmeza, persistencia y determinación.",
    "Darse cuenta de aspectos que a otros se les pasa por alto.",
    "Autodidacta en muchas áreas.",
    "Orientación a resultados.",
    "Espíritu emprendedor.",
    "Rapidez y sentido de urgencia.",
    "Actitud lógica, analítica e incisiva frente a los problemas.",
    "Responsable en el cumplimiento de los plazos.",
    "Pasión por encontrar soluciones satisfactorias a problemas complejos.",
    "Trata situaciones especiales con tiempo y técnica.",
    "Actitud extrovertida, creativa, innovadora y dinámica.",
    "Los retos y riesgos son asumidos sin pensarlo mucho.",
    "Deseos intensos de triunfo.",
    "Autoexigente.",
    "Actitud espontánea, atrevida, valiente y aventurera.",
    "Agente de cambio.",
    "Amplio alcance de actividad.",
    "Autosuficiente e independiente, con gran capacidad resolutiva.",
    "Autoconfianza.",
    "Investiga nuevas, mejores y más rápidas formas de superar los obstáculos.",
    "Toma decisiones rápidas en pro de obtener resultados ágilmente.",
    "Poco influenciable.",
    "Aspira tener autoridad total para asumir sus responsabilidades."
  ],
  1.2: [
    "Suele actuar con firmeza, dinamismo y determinación.",
    "Orientación a resultados.",
    "Con frecuencia se da cuenta de aspectos que a otros se les pasa por alto.",
    "Tiende a ser autodidacta en muchas áreas.",
    "Tiende a ser de espíritu emprendedor.",
    "Suele actuar con sentido de urgencia.",
    "Tiende a ser una persona lógica e incisiva frente a los problemas.",
    "Acostumbra a dar importancia al cumplimiento de los plazos.",
    "Tiende a buscar soluciones satisfactorias a problemas complejos.",
    "Con frecuencia asume retos y riesgos sin pensarlo mucho.",
    "Se mueve habitualmente por el deseo de triunfo.",
    "Tiende a ser exigente tanto para sí como para los demás.",
    "Acostumbra a ser de actitud espontánea, extrovertida, creativa e innovadora.",
    "Suele ser un agente de cambio.",
    "Tiende a ser multitasking.",
    "Con frecuencia es autosuficiente, independiente y con capacidad resolutiva.",
    "Suele investigar nuevas, mejores y más rápidas formas de hacer las cosas.",
    "Probablemente tome decisiones rápidas en pro de obtener resultados ágilmente.",
    "Suele ser poco influenciable.",
    "Prefiere tener la autoridad para asumir sus responsabilidades."
  ],
  2.1: [
    "Actitud extrovertida, segura, espontánea, servicial, optimista y positiva.",
    "Convincente y con capacidad para persuadir.",
    "Hace comentarios agradables, graciosos y encantadores.",
    "Alivia momentos difíciles de forma amable.",
    "Le gusta ser popular y que le reconozcan socialmente.",
    "Socialmente adaptable y amigable.",
    "Confía mucho en las relaciones.",
    "Busca el consenso en la toma de decisiones.",
    "Actitud colaboradora e integradora.",
    "Conquista y mantiene la atención de los demás a través del encanto personal.",
    "Casual e informal, sin dejar de darle la cara a los retos.",
    "Confía en las habilidades de los demás, para agregar valor y asumir compromisos.",
    "Le agrada servir y complacer.",
    "Habilidades sociales.",
    "Crea e integra equipos.",
    "Actitud segura y relajada con las personas.",
    "Crea nuevas amistades y trata con personas de todo tipo.",
    "Valora los sentimientos y las emociones.",
    "Capacidad intuitiva.",
    "Muy hábil para solucionar los problemas de la gente.",
    "Está disponible para los demás.",
    "Negocia los conflictos entre las personas de forma positiva.",
    "Logra la confianza y la apertura de las personas.",
    "Crea ambientes informales y relajados, en donde la gente se siente cómoda.",
    "Trata a los extraños como si fueran amigos de toda la vida.",
    "Impulsa a los demás a lograr sus objetivos.",
    "Proyecta seguridad personal y autoconfianza.",
    "Confía en sus habilidades."
  ],
  2.2: [
    "Suele ser de actitud extrovertida, segura de sí misma, espontánea, optimista y positiva.",
    "Tiende a hacer comentarios agradables, graciosos y encantadores.",
    "Con frecuencia alivia momentos difíciles, de forma amable.",
    "Le gustar ser popular y que le reconozcan socialmente.",
    "Tiende a ser socialmente adaptable y amigable.",
    "Con tendencia a la colaboración.",
    "Busca el consenso en la toma de decisiones.",
    "En general, crea e integra equipos.",
    "Suele conquistar y mantiene la atención de los demás a través del encanto personal.",
    "Tiende a ser casual e informal, sin dejar de darle la cara a los retos.",
    "Confía en las habilidades de los demás, para agregar valor y asumir los compromisos.",
    "Tiende a crear nuevas amistades y tratar con personas de todo tipo.",
    "Suele valorar los sentimientos y las emociones.",
    "Tiende a dedicarse a solucionar los problemas de la gente.",
    "Promueve ideas para el logro de los resultados.",
    "Suele estar disponible para los demás.",
    "Puede ser competente para negociar conflictos entre las personas, de forma positiva.",
    "Comúnmente logra la confianza y la apertura de las personas.",
    "Crea ambientes informales y relajados, en donde la gente se siente cómoda.",
    "Con frecuencia trata a los extraños como si fueran amigos de toda la vida.",
    "Impulsa a los demás a lograr sus objetivos."
  ],
  3.1: [
    "Actitud introvertida, serena, fiable, pacífica y paciente.",
    "Establece rutinas y sigue el patrón de forma consistente.",
    "Actitud lógica y sistemática para resolver sus necesidades actuales.",
    "Ritmo pausado y tranquilo.",
    "Leal.",
    "Carácter reflexivo, conciliador, calmado y consciente.",
    "Actitud disciplinada, detallista y metódica.",
    "Sigue las tradiciones y los lineamientos.",
    "Influye con su amabilidad y actitud de servicio.",
    "Se concentra para aprender y escuchar.",
    "Reconoce los logros de los demás abiertamente.",
    "Proyecta una imagen de calma, madurez, seriedad y control.",
    "Apoya, coopera, sostiene y estabiliza, los ambientes en donde se desenvuelve.",
    "Necesita tiempo para adaptarse a los cambios.",
    "Persiste en las tareas que más le agregan valor al proyecto.",
    "Asume el cambio de forma positiva, cuando tiene tiempo para planificar.",
    "Actitud receptiva, tolerante y paciente con las diferencias, cuando trabaja en equipo.",
    "Prefiere la estabilidad, la rutina y lo conocido, más que la novedad.",
    "Actitud cercana, genera confianza.",
    "Le gusta seguir una planificación clara, paso a paso.",
    "Aprecia el orden y la limpieza.",
    "Planifica su crecimiento a largo plazo.",
    "Implementa el plan para obtener resultados.",
    "Capaz de concentrarse sin distraerse por largo tiempo.",
    "Sin ansias de protagonismo.",
    "Enfrenta los desafíos y problemas de forma seria e inalterable emocionalmente.",
    "Socializa en grupos pequeños, con familiares o amigos cercanos.",
    "Se esmera en mantener relaciones profundas y duraderas."
  ],
  3.2: [
    "Actitud que suele ser introvertida, serena, fiable, pacífica y paciente.",
    "Tiende a establecer rutinas y seguir el patrón de forma consistente.",
    "Ritmo pausado y tranquilo, la mayoría de las veces.",
    "Actitud cooperativa.",
    "Tiende a ser formal y estable.",
    "Carácter reflexivo, conciliador, calmado y consciente.",
    "Suele tener una actitud disciplinada, detallista y metódica.",
    "Comúnmente sigue las tradiciones y los lineamientos.",
    "En general, influye con su amabilidad y actitud de servicio.",
    "Se concentra para aprender y escuchar, la mayoría de las veces.",
    "Tiende a reconocer los logros de los demás.",
    "Suele proyectar una imagen de calma, madurez y control.",
    "Apoya, sostiene y estabiliza los ambientes en donde se desenvuelve.",
    "Tiende a necesitar tiempo para adaptarse a los cambios.",
    "En general persiste en las tareas que más le agregan valor al proyecto.",
    "Asume el cambio de forma positiva, cuando tiene tiempo para planificar.",
    "Actitud receptiva y paciente con las diferencias, cuando trabaja en equipo.",
    "Le gusta seguir el plan paso a paso.",
    "Actitud cercana.",
    "Genera confianza.",
    "Con frecuencia prefiere la estabilidad, la rutina y lo conocido, más que la novedad.",
    "En general, aprecia el orden y la limpieza.",
    "Planifica su crecimiento a largo plazo, la mayoría de las veces.",
    "Suele implementar el plan para obtener resultados.",
    "Tiende a ser capaz de concentrarse sin distraerse por largo tiempo.",
    "Casi siempre enfrenta los desafíos de forma seria e inalterable emocionalmente.",
    "Tiende a socializar en grupos pequeños, con familiares o amigos cercanos.",
    "Suele mantener relaciones profundas y duraderas.",
    "Sin ansias de protagonismo."
  ],
  4.1: [
    "Respeta los patrones, normas y reglas establecidas.",
    "Consistente y consciente en relación con la calidad.",
    "Actitud introvertida, modesta y convencional.",
    "Confiable.",
    "Plantea metas realistas y alcanzables. Asume la responsabilidad.",
    "Busca comprender, objetivamente, una situación, antes de escoger una solución.",
    "Frente a un nuevo reto investiga y analiza toda la información y hechos disponibles.",
    "Quiere entender las implicaciones de todas las opciones antes de decidir.",
    "Prueba y valida antes de proceder.",
    "Enfoque profesional y disciplinado cuando realiza sus tareas.",
    "Desmenuza todos los aspectos de una situación, su visión es más de detalles que global.",
    "Comportamiento meticuloso con el propósito de que todo salga bien la primera vez.",
    "Actitud conservadora frente a la solución de problemas.",
    "Evita cometer errores. Quiere tener todos los posibles riesgos bajo control.",
    "Se enfoca en la organización, la exactitud y la precisión.",
    "Se prepara para dar resultados con altos estándares de calidad.",
    "Se concentra al momento de analizar o realizar una tarea.",
    "No le gustan las interrupciones.",
    "Es consciente de los plazos y cumple con sus compromisos y promesas.",
    "Maneja bien el tiempo.",
    "Actitud disciplinada, diplomática, de convicciones firmes y buenos modales.",
    "Prefiere participar en un grupo pequeño y establecer relaciones estrechas.",
    "Decide de forma rápida y sistemática en situaciones rutinarias.",
    "Aplica la cautela en decisiones especiales e importantes.",
    "No le gustan las confrontaciones.",
    "Prefiere el análisis en vez de la persuasión, para lograr lo correcto.",
    "Paciente."
  ],
  4.2: [
    "Suele respetar los patrones, normas y reglas establecidas.",
    "Confiable.",
    "Tiende a ser consistente y consciente en relación con la calidad.",
    "Con frecuencia es de actitud introvertida, paciente, modesta y convencional.",
    "Suele asumir la responsabilidad.",
    "Habitualmente plantea metas realistas y alcanzables.",
    "Frente a un nuevo reto tiende a investigar y analizar información, antes de decidir.",
    "Busca entender las implicaciones de todas las opciones.",
    "Con frecuencia prueba y valida antes de proceder.",
    "Visión más de detalles que global.",
    "Comportamiento meticuloso con el propósito de que todo salga bien la primera vez.",
    "Actitud que tiende a ser conservadora, frente a la solución de problemas nuevos.",
    "Evita cometer errores, en la medida de lo posible.",
    "Aspira tener los posibles riesgos bajo control.",
    "Se enfoca, con frecuencia, en la exactitud y la precisión.",
    "Tiende a prepararse para obtener resultados, cumpliendo con los estándares de calidad.",
    "Busca comprender las causas de los problemas y seguir procesos probados.",
    "Suele concentrarse para analizar o realizar una tarea y no le gustan las interrupciones.",
    "Actitud disciplinada, diplomática, de convicciones firmes y buenos modales.",
    "Suele cumplir con los plazos y compromisos.",
    "Prefiere participar en un grupo pequeño y establecer relaciones estrechas.",
    "En situaciones rutinarias tiende a decidir de forma rápida y sistemática.",
    "Aplica la cautela en decisiones especiales e importantes."
  ],
  5: [
    "Sentido de urgencia en la obtención de resultados a través de las personas.",
    "Impulsa a las personas a seguirle el ritmo.",
    "Proyecta fuerza, optimismo y convicción a los demás.",
    "Las personas le reconocen como líder ya que sabe persuadir e influenciar.",
    "Se apasiona buscando soluciones a los problemas nuevos.",
    "Interés por conseguir nuevas formas de lograr resultados, más rápidas y mejores.",
    "Actitud confiada y articulada en el manejo de grupos grandes.",
    "Cambia e impulsa el cambio en su entorno, de forma constante.",
    "Enfrenta los desafíos con creatividad, manteniendo la motivación del equipo.",
    "Sabe planificar, lograr recursos y resolver problemas.",
    "Visión global que incluye a la gente.",
    "Influencia a los demás con su amabilidad y motivación por lograr los objetivos.",
    "Actitud directa e influyente para resolver los problemas de la gente.",
    "Actualiza su comportamiento, según la situación. Flexible.",
    "Actitud extrovertida, independiente y con iniciativa.",
    "Delega con determinación, firmeza y encanto personal.",
    "Actitud activa y dinámica que motiva a los demás a lograr los resultados.",
    "Si es necesario asume personalmente las tareas, sólo por el hecho de lograr el resultado.",
    "Actitud competitiva y deseos de triunfo.",
    "Le gusta participar y estar al mando.",
    "Puede gestionar muchas tareas a la vez."
  ],
  6: [
    "Se relaciona con los demás de forma entusiasta.",
    "Influye y motiva a las personas a través de su encanto y firmeza.",
    "Resuelve problemas de forma creativa en especial los conflictos entre las personas.",
    "Actitud positiva para superar los obstáculos que se presentan.",
    "Refleja una imagen amigable y receptiva, mientras tiene todo bajo control.",
    "Si es necesario, es capaz de tomar distancia.",
    "Capacidad resolutiva y activa en la toma de decisiones.",
    "Actitud decidida, expresiva, amigable, extrovertida y optimista.",
    "Confía en los demás y busca ganarse la confianza de la gente.",
    "Tiende a ser informal.",
    "Regula su comportamiento según la ocasión, en pro del logro de los resultados.",
    "Agente de cambio.",
    "Puede aproximarse de diversas maneras a las personas.",
    "Prefiere la variedad que la rutina.",
    "Visión global en donde la gente forma parte.",
    "Visión innovadora de futuro.",
    "Ritmo veloz.",
    "Multitasking.",
    "Busca ser independiente.",
    "Sigue sus propias directrices en la toma de decisiones y al momento de tomar acción.",
    "Tendencia a asumir riesgos y correr con las consecuencias.",
    "Enfoque en la obtención de resultados por medio de las personas.",
    "Potencial para dirigir equipos hacia el logro de las metas.",
    "Convincente."
  ],
  7: [
    "Influye y motiva a las personas a través de su encanto y firmeza.",
    "Consciente de la fuerza que ofrece al equipo para el logro del éxito.",
    "Actitud compresiva y estilo amistoso.",
    "Entusiasta en el emprendimiento.",
    "Popular.",
    "Actitud positiva, optimista y con buen sentido del humor.",
    "Se siente bien tanto con extraños como con personas conocidas.",
    "Aprecia ser parte de un grupo.",
    "Busca la integración, aceptación y reconocimiento social.",
    "Tiende a hacer comentarios agradables y graciosos.",
    "Alivia momentos de tensión con su manera amigable y extrovertida.",
    "Tiende a implicarse cuando las emociones y la creatividad están involucrados.",
    "Actitud abierta y accesible a todos.",
    "Juega bien dentro de un equipo, aunque prefiere el rol de líder que de seguidor.",
    "Refleja una actitud adaptable, pero en el fondo tiene muy claro lo que quiere.",
    "Tiene claro hasta dónde va a ceder.",
    "Anhela el poder y al liderazgo.",
    "Enfrenta la tensión y los obstáculos de forma casual.",
    "Visión global en donde las personas forman parte.",
    "Demuestra su estado de ánimo abiertamente.",
    "Se esfuerza en mantener las amistades.",
    "Actitud espontánea y auténtica.",
    "Prefiere que las personas tomen decisiones rápidas.",
    "Habilidad para resolver problemas entre las personas.",
    "Sabe inspirar a los demás con su visión personal."
  ],
  8: [
    "Impulsa a los demás a lograr las metas.",
    "Consciente de los plazos.",
    "De ritmo rápido y enérgico, equilibrado por la duda y la reflexión.",
    "Propone nuevas ideas y persiste hasta el final.",
    "Se adapta al entorno, si es necesario, para el logro de los objetivos.",
    "Cumple con lo que promete, mientras no interfiera en los resultados.",
    "Aprecia a quienes trabajan en equipo y cumplen con sus tareas.",
    "Analiza desde un punto de vista global y pormenorizado.",
    "Colabora con los demás para avanzar en el alcance de la meta.",
    "Actitud innovadora, creativa, curiosa y reflexiva.",
    "Enfoque en las tareas y retos, con buen sentido de equipo.",
    "Actitud amigable y considerada, cuando todo está en calma.",
    "Individualista, en momentos de tensión.",
    "Cuida de la estabilidad del ambiente, para favorecer el logro de los objetivos.",
    "Asume la responsabilidad de sus tareas y metas.",
    "Maneja, activamente, diversos proyectos al mismo tiempo.",
    "Se integra al equipo, si hay un alto desempeño.",
    "Advierte, de forma activa, cuando hay desviaciones injustificadas.",
    "En situaciones complejas es de actitud ética y leal y espera lo mismo de los demás.",
    "Disponible para la acción.",
    "Se orienta en los resultados buscando la consistencia.",
    "Determinación, tenacidad y persistencia.",
    "Enfrenta de forma creativa las situaciones complejas",
    "Se adapta a los demás, en pro de los resultados, aunque suele ser independiente.",
    "Reflexiona antes de comprometerse.",
    "Cuenta con recursos para enfrentar los obstáculos."
  ],
  9: [
    "Ritmo rápido y enérgico, equilibrado por la duda y la reflexión.",
    "Actitud generosa y bondadosa con los demás, de una manera activa.",
    "Paciente en la rutina.",
    "Acelera el paso o lo ralentiza, según lo demande la situación.",
    "Impulsa a los demás a lograr las metas, de una manera gentil.",
    "Consciente al asumir riesgos.",
    "Se integra cuando considera que el equipo es de alto desempeño.",
    "Colabora con los demás para avanzar en el alcance de la meta.",
    "Actitud ecuánime, juiciosa, considerada y relajada, cuando todo está en calma.",
    "Actitud detallista y metódica en la solución de problemas rutinarios.",
    "Frente a los problemas nuevos tiende a la innovación y creatividad.",
    "Piensa que los resultados y las necesidades de la gente, son igualmente importantes.",
    "En la adversidad el enfoque dependerá de lo que más requiera atención.",
    "Actitud firme que matiza con amabilidad, en situaciones complejas.",
    "Advierte, de forma activa, cuando hay desviaciones injustificadas.",
    "Asume la responsabilidad de sus funciones y objetivos.",
    "Puede enfocar tanto lo global como el detalle, en las diferentes situaciones.",
    "Maneja, activamente, diversos proyectos al mismo tiempo.",
    "Destreza y disposición para enfrentar tanto la rutina como la novedad.",
    "Concilia en momentos de tensión, de forma activa.",
    "Utiliza métodos probados en la rutina,",
    "Enfrenta obstáculos complejos con creatividad.",
    "Buena disposición frente al cambio, sobre todo si cuenta con información previa.",
    "Se adapta al entorno, si es necesario, para el logro de los objetivos.",
    "Cumple con lo que promete, manteniendo el foco en los resultados.",
    "Enfoque en las tareas y retos, con buen sentido de equipo.",
    "Cuida de la estabilidad del ambiente, para favorecer el logro de los objetivos.",
    "En situaciones complejas es de actitud ética y leal y espera lo mismo de los demás.",
    "Reflexiona antes de comprometerse."
  ],
  10: [
    "Adaptación al ritmo del entorno para el logro de los resultados.",
    "Sentido de urgencia en situaciones especiales.",
    "Cierto nivel de firmeza y dinamismo, en la rutina, que equilibra su calma natural.",
    "Actitud ecuánime y considerada, para afrontar los retos.",
    "Actitud generosa y bondadosa con los demás, de una manera activa.",
    "Presiona a las personas con amabilidad, para lograr los objetivos.",
    "Actitud detallista y metódica, para la solución de problemas.",
    "Se enfoca en los resultados, atendiendo las necesidades del equipo.",
    "Afronta los retos con lógica.",
    "Trabaja con persistencia por el logro de sus metas.",
    "Actitud reflexiva e imparcial, en momentos de tensión.",
    "Actitud ética y leal, esperando lo mismo de los demás.",
    "Consciente frente al riesgo.",
    "Mantiene relaciones cercanas en cualquier ambiente.",
    "Actitud persistente, firme y lógica en la búsqueda de resultados.",
    "En la adversidad atiende las necesidades de las personas por encima de los resultados.",
    "Concilia en los conflictos, pero si es necesario, puede asumir una posición firme.",
    "Tiende a apoyarse en sistemas y métodos para orientarse en la ejecución de las tareas.",
    "En la rutina puede tomar la iniciativa con una actitud creativa.",
    "Tolera las dificultades y confía en que la situación va a mejorar.",
    "Actitud diplomática y estabilizadora, de forma activa y enérgica.",
    "Enfrenta obstáculos complejos con los métodos probados.",
    "Reflexiona antes de comprometerse.",
    "Colabora con los demás para avanzar en el alcance de la meta.",
    "Actitud ecuánime, juiciosa, considerada y relajada, cuando todo está en calma.",
    "Prefiere enfrentar el cambio contando con información previa."
  ],
  11: [
    "Foco en los resultados cuidando de los procesos de forma creativa.",
    "Innova tomando en cuenta los hechos y los indicadores.",
    "Visión de futuro de forma objetiva.",
    "Visión global matizada ligeramente por una conciencia del detalle.",
    "Actitud realizadora e implementadora de soluciones creativas.",
    "Impulso por obtener resultados rápidamente.",
    "Si cuenta con tiempo, cuida de cumplir con los protocolos.",
    "Asume riesgos calculando las consecuencias.",
    "Aplica métodos probados para lograr resultados rápidamente.",
    "Impulsa cambios y acciones con autoridad.",
    "Cuida los estándares de calidad de manera dinámica y creativa.",
    "Comportamiento contundente matizado ligeramente con diplomacia.",
    "Rapidez en la toma de decisiones respaldadas con pensamiento crítico y análisis.",
    "Su ritmo marca la pauta en el equipo.",
    "Es de carácter extrovertido más que introvertido.",
    "Su competitividad y su capacidad de asumir riesgos influyen en los demás.",
    "Establece estándares elevados para el desempeño y trabajo de equipo.",
    "Promueve ideas, soluciones, proyectos y situaciones.",
    "Analiza rápidamente todos los aspectos de una solución.",
    "Interés por alcanzar las metas con excelencia e innovación.",
    "Disponible para la acción con una actitud racional.",
    "Consciencia del valor que implican los errores.",
    "Resuelve los desafíos metódicamente sin permitir que las emociones interfieran.",
    "Promueve el cambio."
  ],
  12: [
    "Innova tomando en cuenta los hechos y los indicadores.",
    "Visión global y de detalle.",
    "Foco en la obtención rápida de resultados cuidando los procedimientos y la calidad.",
    "Actitud realizadora e implementadora de soluciones creativas.",
    "Anticipa y resuelve problemas de forma creativa.",
    "Asume riesgos calculando las consecuencias.",
    "Aplica métodos probados para lograr resultados rápidamente.",
    "Actitud directa y contundente matizada con diplomacia y sensibilidad.",
    "Responde con rapidez y objetividad en la toma de decisiones.",
    "Analiza rápidamente todos los aspectos de una solución.",
    "Gerencia el cambio, calculando los costos.",
    "Interés por alcanzar las metas con excelencia e innovación.",
    "Analiza rápidamente mucha información para respaldar sus decisiones.",
    "Disponible para la acción con una actitud racional.",
    "Cuando las demandas son equilibradas se automotiva para lograr los objetivos.",
    "Su ritmo marca la pauta en el equipo.",
    "Aplica sistemas eficientes para lograr los objetivos, de forma rápida.",
    "Respeta las normas y los procedimientos, que facilitan el logro de los resultados.",
    "Proyecta una imagen seria y profesional, de alguien que sabe lo que hace.",
    "Valida la calidad de los resultados con sentido de urgencia.",
    "Impulsa el cambio y la calidad con autoridad y diplomacia.",
    "Cuida los estándares de calidad de manera dinámica y creativa.",
    "Resuelve problemas complejos con creatividad y métodos probados.",
    "Innova en procedimientos para alcanzar mayores niveles de calidad."
  ],
  13: [
    "Confiable y adaptable.",
    "Proyecta una imagen seria y profesional, de alguien que sabe lo que hace.",
    "Diseña nuevas formas para afrontar los problemas, luego de analizar información.",
    "Impulsa el cambio, consciente de los riesgos y calculando los costos.",
    "Posee un enfoque objetivo, dinámico y analítico, para afrontar las tareas.",
    "Evalúa la información disponible para tomar decisiones con cierto sentido de urgencia.",
    "Reta a los demás a encontrar mejores resultados cada vez.",
    "Diseña sistemas para lograr objetivos y resolver problemas.",
    "Afronta los nuevos problemas basándose en las teorías disponibles.",
    "Crea e implementa modelos creativos para obtener resultados.",
    "Comienza los proyectos pensando bien el plan y lo ejecuta de forma dinámica.",
    "Controla la calidad para la obtención de resultados.",
    "Respeta las normas y los procedimientos en pro del logro de la meta.",
    "En ocasiones puede ser flexible.",
    "Utiliza la creatividad y el pensamiento crítico para resolver problemas.",
    "Mejora los procedimientos, con iniciativa e innovación, en pro de la calidad.",
    "Prioriza los planes, la calidad, la producción y la obtención rápida de resultados.",
    "Analiza rápido con miras a lograr soluciones eficientes.",
    "Toma decisiones rápidas en la rutina.",
    "Toma decisiones difíciles analizando los hechos, sin dejarse llevar por lo emocional.",
    "Cambia rápidamente de actividad sin perder el foco.",
    "Actitud precisa y perseverante, en el logro de resultados de calidad.",
    "Visión de detalle y tendencia a la exactitud y precisión, de forma activa.",
    "Trabaja con enfoque y esfuerzo en la obtención rápida de resultados de calidad.",
    "Cumple los procedimientos y las normas, de forma activa.",
    "Visión de futuro enfocada en mejorar las normas y los estándares de calidad.",
    "Domina muchas situaciones a la vez, con orden y disciplina."
  ],
  14: [
    "Beneficia el bienestar de los demás.",
    "Aporta alegría, comprensión, estabilidad y positivismo al equipo.",
    "Logra un buen clima, en donde las personas se sienten felices.",
    "Disponible para las personas.",
    "Paciente.",
    "Promueve ideas para el logro de los resultados.",
    "Actitud amigable y abierta con las personas, sobre todo con amigos cercanos y socios.",
    "Se motiva a seguir ayudando a los demás cuando recibe la aprobación de los demás.",
    "Entrena y aconseja, por su disposición para escuchar y motivar.",
    "Sociable y complaciente. Disfruta de la forma única de ser de cada persona.",
    "Buena compañía, puede ser el alma de la fiesta.",
    "Alivia ambientes tensos a través de comentarios agradables y graciosos.",
    "El reconocimiento público le anima a continuar con su buen trabajo.",
    "Adaptable socialmente.",
    "Se preocupa tanto de la gente como de las cosas.",
    "Construye relaciones positivas y de confianza a través de la calidez humana.",
    "Actitud cooperativa y amable.",
    "Confía en sus habilidades y en la de los otros, para agregar valor y asumir compromisos.",
    "Popular, por su forma liviana de relacionarse.",
    "Ayuda a los demás con empatía y comprensión.",
    "Necesita que le aclaren las expectativas sobre sus funciones.",
    "Aprecia la libertad de movimiento.",
    "Valora las emociones y los sentimientos.",
    "Mantiene amistades a largo plazo.",
    "Cuando está en calma puede cumplir con los sistemas y métodos de forma lógica.",
    "Habilidad para resolver los problemas de la gente.",
    "Negocia los conflictos entre las personas, de forma imparcial, cuando es necesario."
  ],
  15: [
    "Actitud abierta y amigable, pero principalmente con el grupo de personas cercanas.",
    "Actitud colaborativa y generosa, enfocada en las relaciones.",
    "Promueve e implementa ideas para el logro de los resultados.",
    "Prioriza las necesidades de las personas antes que la productividad.",
    "Aporta comprensión, estabilidad y positivismo al equipo.",
    "Apoya al equipo en el logro de la meta compartida.",
    "Crea un clima estable, entusiasta y amable, en donde los demás se sienten confortables.",
    "Disponible para las personas.",
    "De fácil trato.",
    "Se integra al equipo sin dificultad.",
    "Construye relaciones positivas y de confianza a través de la calidez humana.",
    "Actitud cooperativa, gentil y amable.",
    "Comparte el protagonismo con quien necesite más atención o control.",
    "Puede ser paciente y de actitud reflexiva.",
    "Entrena y aconseja, por su disposición para escuchar y motivar.",
    "Sociable y complaciente. Disfruta de la forma única de ser de cada persona.",
    "Puede ser obediente para no generar conflictos.",
    "Buena compañía, puede ser el alma de la fiesta.",
    "Sabe aliviar ambientes tensos a través de comentarios agradables y graciosos.",
    "Sentido de urgencia bajo presión.",
    "Necesita que le aclaren las expectativas sobre sus funciones.",
    "Cuando está en calma puede cumplir con los sistemas y métodos de forma lógica.",
    "Habilidad para resolver los problemas de la gente.",
    "Negocia los conflictos entre las personas, de forma imparcial, cuando es necesario.",
    "Adaptable socialmente."
  ],
  16: [
    "Actitud deliberada, reflexiva, alegre, optimista y amistosa.",
    "Ayuda a los otros en la consecución de sus objetivos.",
    "Construye relaciones positivas y de confianza a través de la calidez humana.",
    "Actitud cooperativa y amable.",
    "Comparte el protagonismo con quien necesite más atención o control.",
    "Actitud paciente y reflexiva.",
    "Actitud colaborativa y generosa, enfocada en las relaciones.",
    "Prioriza las necesidades de las personas.",
    "Inclinación por ayudar a los demás.",
    "Estabiliza, apoya y armoniza en situaciones de presión.",
    "Disponible para las personas.",
    "Entrena, aconseja y asesora.",
    "Apoya al equipo en el logro de la meta compartida.",
    "Actitud tradicionalista y estable.",
    "Aprecia que le expliquen los detalles, antes de enfrentar el cambio.",
    "De ritmo moderado.",
    "Aporta comprensión, estabilidad y positivismo al equipo.",
    "Crea un clima estable, entusiasta y amable, en donde los demás se sienten confortables.",
    "Cumple las normas para la convivencia.",
    "Implementa el plan para obtener resultados, con entusiasmo.",
    "Sociable y complaciente. Disfruta de la forma única de ser de cada persona.",
    "Puede ser obediente para no generar conflictos.",
    "Necesita que le aclaren las expectativas sobre sus funciones.",
    "Actitud compasiva y habilidad perceptiva de los sentimientos de los demás."
  ],
  17: [
    "Calidad y diplomacia en las relaciones sociales.",
    "Optimismo y entusiasmo, de forma objetiva.",
    "Interactúa con las personas de una manera segura y serena.",
    "Demuestra confianza y cercanía a las personas.",
    "Actitud abierta y receptiva con las personas.",
    "Influye con su actitud de confianza y seriedad.",
    "Disponible para ayudar.",
    "Capaz de resolver los conflictos entre las personas.",
    "Buen carácter por lo general.",
    "Crea un ambiente agradable y confortable.",
    "Enfoque en la realización de tareas complejas, estimulando el entusiasmo del equipo.",
    "Motiva y acompaña al equipo a lograr altos estándares de calidad.",
    "Estimula el entusiasmo, cumpliendo, al mismo tiempo, los protocolos y las reglas.",
    "Habilidad para sacar lo bueno de los malos momentos.",
    "Aprende y se adapta.",
    "Prefiere un ritmo rápido.",
    "Capaz de delegar, supervisar y hacer seguimiento, de una forma positiva.",
    "Toma las decisiones con optimismo, considerando lo que piensan los demás.",
    "Respalda las decisiones con el análisis de información e indicadores, en equipo.",
    "Considera y atiende las necesidades de las personas.",
    "Logra las metas a través de las personas, creando un buen clima y cuidando la calidad.",
    "Promueve a las personas y los proyectos.",
    "Busca la aceptación y aprobación de los demás.",
    "Aprecia cuando le reconocen el trabajo bien hecho de forma pública.",
    "Le gusta rodearse de gente experta en lo que hace.",
    "Actitud diplomática a la hora de administrar disciplina.",
    "Colabora y se integra al equipo con buena disposición.",
    "Asume la responsabilidad cuando no cumple los plazos.",
    "Influye positivamente en la integración del equipo.",
    "Nutre activamente las relaciones interpersonales.",
    "Elimina la tensión.",
    "Motiva a las personas a atender los detalles y la calidad.",
    "Capacidad perceptiva y analítica para tomar decisiones."
  ],
  18: [
    "Calidad, positivismo y diplomacia, en las relaciones sociales.",
    "Autoexigencia en el respeto de los plazos.",
    "Equilibrada importancia tanto en las relaciones, como en las normas y los procesos.",
    "Negocia los conflictos entre las personas con una visión de ganancia compartida.",
    "Interactúa con las personas de una manera segura y serena.",
    "Actitud cautelosa ante el riesgo, que se equilibra con una mentalidad abierta al cambio.",
    "Actúa con seguridad y autoconfianza, matizado con tacto y diplomacia.",
    "Estimula el entusiasmo en el cumplimiento de los protocolos y de las reglas.",
    "Asume tareas complejas, cuidando de la integración y motivación del equipo.",
    "Aporta la energía suficiente para llevar los proyectos con éxito, de principio a fin.",
    "Actitud sociable, analítica y reflexiva.",
    "Influye en los demás con encanto personal y profesionalismo.",
    "Motiva a las personas a atender los detalles y la calidad.",
    "Analiza, ágilmente, toda la información disponible antes de tomar decisiones.",
    "Motiva y acompaña al equipo a lograr altos estándares de calidad.",
    "Capaz de delegar, supervisar y hacer seguimiento, de una forma amistosa.",
    "Aprecia cuando le reconocen el trabajo bien hecho, de forma pública.",
    "Le gusta rodearse de gente experta en lo que hace.",
    "Colabora y se integra al equipo, con buena disposición.",
    "Actitud diplomática a la hora de administrar disciplina.",
    "Capacidad perceptiva y analítica que aplica en la solución de los problemas."
  ],
  19: [
    "Cumple las normas, los procedimientos y protocolos establecidos, con entusiasmo.",
    "Capacidad de organización, incluso en las relaciones sociales.",
    "Cumple con los estándares de calidad manteniendo la motivación del equipo.",
    "Actitud diplomática, educada y cooperativa, con el equipo.",
    "Equilibra el ritmo rápido con el cuidado de los estándares de calidad.",
    "Aprecia relacionarse con personas con quien comparte similares ideas.",
    "Asume el paso a paso de un proyecto y cuida de las relaciones interpersonales.",
    "Se integra fácilmente con personas organizadas y enfocadas en la calidad.",
    "Motiva al equipo al logro de la meta compartida.",
    "Mantiene relaciones con personas claves.",
    "Crea un buen ambiente a su alrededor.",
    "Visualiza con detalles el plan de acción.",
    "Enfoque en la precisión, exactitud y cuidado de los detalles.",
    "Actitud rigurosa con la calidad, los sistemas y el orden.",
    "Motiva al equipo con un estilo jovial y profesional.",
    "Persuade al equipo a cumplir con los protocolos.",
    "Proyecta confianza por el manejo profesional de su área de especialidad.",
    "Actitud considerada con las necesidades de los demás.",
    "Decide con cautela, análisis e intuición.",
    "Natural entusiasmo que agrega valor al equipo.",
    "Actitud receptiva, en el ámbito profesional, para conocer nuevas personas.",
    "Prefiere relacionarse de forma cercana con un grupo pequeño de asociados.",
    "Asume los proyectos de principio a fin y cuida que sean hechos bien desde el principio.",
    "Autoexigencia en el respeto de los plazos.",
    "Actitud meticulosa y metódica para afrontar un proyecto.",
    "Negocia los conflictos entre las personas con una visión de ganancia compartida.",
    "Actitud diplomática a la hora de administrar disciplina.",
    "Capaz de delegar, supervisar y hacer seguimiento, de una forma amistosa.",
    "Motiva y acompaña al equipo a lograr altos estándares de calidad.",
    "Aporta la energía suficiente para llevar los proyectos con éxito, de principio a fin.",
    "Asume tareas complejas, cuidando de la integración y motivación del equipo."
  ],
  20: [
    "Cumple con las normas y los procedimientos probados.",
    "Mundo emocional muy sentido, pero poco expresado.",
    "Inicia proyectos y persevera hasta terminarlos.",
    "Actitud conciliadora. No le gustan los conflictos entre las personas.",
    "Organiza y coordina.",
    "Sigue lineamientos y apoya las causas de otros.",
    "Refina e implementa el plan para la obtención de resultados.",
    "Actitud moderada, reservada, meticulosa, metódica, disciplinada y autocontrolada.",
    "Colabora y trabaja en equipo.",
    "Supervisa el cumplimiento de los protocolos y el apego a las normas.",
    "Actitud introvertida, gentil, receptiva, leal y discreta.",
    "Prefiere la privacidad.",
    "Se adapta al ritmo del entorno.",
    "Cuida de las personas, de la información y de los procesos, en pro del producto.",
    "Asume las decisiones de forma tradicionalista y conservadora.",
    "Inspira calma, control y tranquilidad en un ambiente de tensión.",
    "Aprecia que le reconozcan, con sinceridad, el trabajo bien hecho.",
    "Prefiere el cambio planificado paso a paso y no giros bruscos en la rutina.",
    "Actitud diplomática y educada.",
    "Paciente, amable y tolerante.",
    "Prefiere calcular muy bien los riesgos.",
    "Considera los sentimientos de las personas.",
    "Revisa la información y los hechos disponibles, al detalle, antes de tomar acciones.",
    "Prefiere relaciones con personas cercanas, de confianza y que le sean familiares.",
    "Percibe bien a los demás.",
    "Hace seguimiento eficiente.",
    "Concentra los esfuerzos en la atención de las necesidades.",
    "Busca soluciones lógicas a los problemas.",
    "Cede el protagonismo, si es necesario, para evitar conflictos y confrontaciones.",
    "Aprecia contar con tiempo para tomar decisiones bien pensadas."
  ],
  21: [
    "Actitud profesional en su área de especialidad.",
    "Coordina que el plan se lleve a término.",
    "Detallista y estable.",
    "Cumple con sus responsabilidades consciente y cuidadosamente.",
    "Descubre problemas que otros no ven.",
    "Cuida de que las tareas sean hechas bien desde el principio.",
    "Aporta al equipo intuición, pensamiento crítico y analítico.",
    "Revisa a cabalidad las variables involucradas en un proyecto.",
    "Precaución en el cambio. Mide los riesgos.",
    "Decisiones basadas en análisis de hechos y datos, tomando en cuenta a los demás.",
    "Mundo emocional muy sentido, pero poco expresado.",
    "Persistente. Refina e implementa el plan para la obtención de resultados.",
    "Actitud moderada, gentil, reservada, disciplinada y autocontrolada.",
    "Aprecia que le reconozcan, con sinceridad, el trabajo bien hecho.",
    "Supervisa, con amabilidad, el cumplimiento de las normas y los estándares de calidad.",
    "Actitud conciliadora. No le gustan los conflictos entre las personas.",
    "Consciente del tiempo y de los plazos.",
    "Se adapta al ritmo del entorno.",
    "Prefiere el cambio planificado paso a paso y no giros bruscos en la rutina.",
    "Cuidado por las personas, la información y los procesos, para el beneficio del producto.",
    "Actitud diplomática, objetiva, paciente, educada y cooperativa.",
    "Prefiere la privacidad y relacionarse con personas de confianza, que le sean familiares.",
    "Actitud introvertida, meticulosa, metódica, receptiva, leal y discreta.",
    "Asume las decisiones de forma tradicionalista y conservadora.",
    "Estimula al equipo a que analice los hechos e información.",
    "Prefiere que dejen de lado las emociones, para tomar decisiones precisas.",
    "Conoce los productos y los procesos al detalle.",
    "Aprecia contar con tiempo para tomar decisiones bien pensadas."
  ],
  22: [
    "Actitud profesional en su área de especialidad.",
    "Establece y alcanza altos estándares tanto en su comportamiento como en la calidad.",
    "Sensible y alerta frente a los problemas, controles y posibles errores.",
    "Coordina que el plan se lleve a término, manteniendo la calidad.",
    "Cumple con sus responsabilidades consciente y cuidadosamente.",
    "Descubre problemas que otros no ven.",
    "Cuida de que el trabajo sea hecho bien en el primer intento.",
    "Consciente del tiempo y de los plazos.",
    "Ritmo pausado para tomar decisiones.",
    "Precaución en el cambio. Mide los riesgos.",
    "Aporta al equipo pensamiento crítico y analítico.",
    "Revisa a cabalidad las variables involucradas en un proyecto.",
    "Decisiones basadas en análisis de hechos y datos, tomando en cuenta a los demás.",
    "Mundo emocional muy sentido, pero poco expresado.",
    "Toma decisiones difíciles sin interferencia emocional.",
    "Comprende las necesidades de los sistemas de calidad y persevera en ellos.",
    "Refina e implementa el plan para la obtención de resultados.",
    "Actitud moderada, gentil, reservada, disciplinada y autocontrolada.",
    "Supervisa el cumplimiento de las normas y los estándares de calidad.",
    "Cuidado por las personas, la información y los procesos, para el beneficio del producto.",
    "Actitud diplomática, formal, tranquila, objetiva, paciente y educada.",
    "Aprecia que le reconozcan, con sinceridad, el trabajo bien hecho.",
    "Prefiere la privacidad y relacionarse con personas de confianza, que le sean familiares.",
    "Actitud introvertida, meticulosa, metódica y discreta.",
    "Asume las decisiones de forma tradicionalista y conservadora.",
    "Estimula al equipo a que analice los hechos e información y dejen de lado las emociones, para tomar decisiones precisas y exactas.",
    "Aprecia contar con tiempo para tomar decisiones bien pensadas.",
    "Actitud cautelosa frente a las innovaciones o al inicio de un proyecto.",
    "Conoce los productos y los procesos, al detalle.",
    "Aprecia la seguridad y la demostración de destreza.",
    "Actitud conciliadora e imparcial, que estabiliza ambientes de tensión.",
    "Prefiere el cambio planificado paso a paso y no giros bruscos en la rutina."
  ],
  23: [
    "Ritmo rápido para la obtención de resultados.",
    "Visión global y de futuro, en donde las personas están incluidas.",
    "Enfoque en el logro de resultados a través del equipo.",
    "Asume el liderazgo cuando se integra al equipo, en pro del éxito del proyecto.",
    "Contagia a los demás de energía y optimismo realista, para alcanzar los objetivos.",
    "Usa las normas como una referencia flexible.",
    "Realista con las expectativas.",
    "Actitud firme, amigable, gentil y entusiasta.",
    "Asume la responsabilidad por los resultados obtenidos.",
    "Ayuda a los demás a resolver sus problemas, para agilizar el logro de los resultados.",
    "Responde con firmeza o con amabilidad, según lo demande la situación.",
    "Enfoque en resultados, sin descuidar las necesidades de las personas.",
    "Actitud resolutiva frente a los problemas.",
    "Persuade a las personas para que participen en los proyectos.",
    "Enfoque en proyectos realistas, con oportunidad de lograr resultados tangibles.",
    "Se motiva cuando le reconocen sus logros públicamente.",
    "Asume roles de liderazgo, autoridad y poder, con entusiasmo.",
    "Moviliza el cambio y motiva el logro de los objetivos."
  ],
  24: [
    "Actitud inquieta y curiosa con tendencia a buscar nuevos proyectos y retos.",
    "Usa las normas como una referencia flexible.",
    "Se plantea metas, que se esfuerza por lograr.",
    "Enfoque en la obtención de resultados y en la solución de conflictos entre las personas.",
    "Actitud atrevida y arriesgada cuando se siente optimista.",
    "Actitud firma y determinada pero también considerada con las personas.",
    "Atiende las relaciones y las necesidades de las personas, con el foco puesto en el éxito.",
    "Visión global y de futuro, en donde las personas están incluidas.",
    "Motiva al equipo a alcanzar el éxito, a través de su dinamismo y amabilidad.",
    "Toma la iniciativa en los proyectos asumiendo el rol de liderazgo.",
    "Suaviza la presión, si es estratégico para lograr los objetivos.",
    "Compite y puede conquistar niveles de mayor autoridad y liderazgo.",
    "Responde con firmeza o con amabilidad y comprensión, según lo demande la situación.",
    "Toma en cuenta los métodos y los sistemas, si es estratégico en el logro de los resultados.",
    "Coopera con el equipo, para lograr los objetivos.",
    "Moviliza el cambio y motiva el logro de resultados.",
    "Motiva a las personas a que logren sus objetivos y supere sus brechas en el desempeño.",
    "Ritmo rápido para la obtención de resultados, con el foco puesto en las personas.",
    "Alinea al equipo en función de los objetivos.",
    "Actitud firme, amigable, gentil y entusiasta."
  ],
  25: [
    "Acelera el ritmo, si el proyecto lo amerita.",
    "Paciente, amable y gentil en la rutina y cuando el ambiente está en calma.",
    "Colabora con los demás para avanzar en la obtención de los resultados.",
    "Se integra y se lleva bien con las personas en situaciones normales.",
    "Tiende a usar las normas como una referencia flexible.",
    "Atiende las necesidades de la gente para crear un buen clima en pro de los resultados.",
    "Consciente al asumir riesgos.",
    "Actitud activa en la solución de los conflictos entre las personas.",
    "Actitud innovadora y creativa frente a nuevos desafíos.",
    "Actitud detallista y metódica en la solución de problemas rutinarios.",
    "Reflexiona frente al estrés, como primer recurso para calmarse.",
    "Actitud amistosa y firme con al equipo, para la obtención de los objetivos.",
    "Presiona de forma amable la obtención de resultados, cuando el tiempo está a favor.",
    "Tiende a cumplir con sus promesas y compromisos.",
    "Se integra al equipo cuando considera que es de alto desempeño.",
    "Tanto los resultados como las necesidades de las personas le parecen importantes.",
    "Advierte, de forma activa, cuando hay desviaciones injustificadas.",
    "Asume la responsabilidad de sus tareas y metas.",
    "Actitud curiosa, innovadora y creativa matizada por el respeto a las metodologías.",
    "Visión global y de detalle frente a los problemas y proyectos.",
    "Disposición para emprender nuevos proyectos, manteniendo la calma en la rutina.",
    "Actitud trabajadora.",
    "Puede estar al frente de varios retos al mismo tiempo.",
    "Intenta estabilizar activamente en los conflictos.",
    "Asume con liderazgo, ética y lealtad, las situaciones complejas",
    "Enfrenta los cambios de forma activa y positiva, si cuenta con mucha información previa."
  ],
  26: [
    "Actitud firme, que se equilibra con un interés activo por el bienestar de los demás.",
    "Tiene metas y aspiraciones claras, que busca alcanzar con el equipo.",
    "Mantiene un ambiente de armonía y entusiasmo, en pro de lograr resultados rápido.",
    "Brinda soporte a las personas, para que superen sus brechas de desempeño.",
    "Ayuda a que los demás logren los objetivos.",
    "Resuelve problemas complejos cooperando con las personas.",
    "Se integra al equipo cuando considera que es de alto desempeño.",
    "Reflexiona frente al estrés, como primer recurso para calmarse.",
    "Cuida de las necesidades de las personas, en pro del logro de los objetivos comunes.",
    "Sentido de urgencia, para alcanzar resultados innovadores, a través de la gente.",
    "Visión global y de futuro, en donde las personas están incluidas.",
    "Cumple con los procesos, mientras favorezca el logro de la meta, a buen ritmo.",
    "Usa las normas como una referencia flexible.",
    "Actitud atrevida, valiente y arriesgada, cuando se siente en calma.",
    "Detallista, cuando cuenta con tiempo suficiente y el clima es favorable.",
    "Colabora con el equipo, para acelerar el logro de los resultados.",
    "Presiona de forma amable la obtención de resultados, cuando el tiempo está a favor.",
    "Actitud contundente y firme o amable y comprensiva, dependiendo de los resultados.",
    "Suaviza las tensiones, si eso beneficia el logro de los objetivos.",
    "Actitud innovadora, curiosa y creativa.",
    "Valora las rutinas exitosas.",
    "Disfruta enfrentando desafíos y relacionándose con las personas."
  ],
  27: [
    "Toma la iniciativa.",
    "Actitud directiva e independiente.",
    "Negocia los conflictos entre las personas, cuando favorece el logro de los resultados.",
    "Gusto por la diversidad y la variedad.",
    "Actitud innovadora y determinada.",
    "Flexibilidad, encanto personal y actitud motivadora, en circunstancias favorables.",
    "Inspira credibilidad y confianza.",
    "Consciencia del progreso de cada proyecto.",
    "Se propone objetivos ambiciosos que por lo general sobrepasan a los de los demás.",
    "Crea redes sociales y estrecha lazos con personas claves.",
    "Plantea metas ambiciosas y estimula a los demás a trabajar duro para alcanzarlas.",
    "Aborda varios proyectos a la vez, manteniendo la eficiencia en el logro de resultados.",
    "Favorece el logro de los objetivos, en un ambiente amigable y cuidando de la calidad.",
    "Autosuficiente, cuando falla la ayuda de los demás.",
    "Actitud creativa, pionera, influenciadora y preocupada por la calidad.",
    "Ajusta su comportamiento, ante cada situación, en función del logro de los objetivos.",
    "Proyecta energía, fuerza y entusiasmo a los demás.",
    "Hace seguimiento desde el inicio, inspirando entusiasmo, para garantizar los resultados.",
    "Estimula a las personas a cumplir los protocolos, si eso beneficia el logro de los objetivos.",
    "Afronta los obstáculos y la competencia, con optimismo y autoconfianza.",
    "Analiza rápidamente las opciones frente a los problemas.",
    "Actitud extrovertida."
  ],
  28: [
    "Actúa tomando la iniciativa.",
    "Dirige a la gente para lograr los objetivos con excelencia.",
    "Actitud competitiva y gusto por ganar.",
    "Negocia los conflictos entre las personas, cuando favorece el logro de los resultados.",
    "Empuja el logro de los resultados, cuidando de los procesos, de forma dinámica.",
    "Aporta optimismo al equipo, en el logro del éxito.",
    "Motiva y ayuda al equipo a mantenerse enfocado.",
    "Actitud pionera, extrovertida y dinámica, manteniendo el cuidado por la calidad.",
    "Analiza información relevante, con sentido de urgencia, antes de decidir.",
    "Alcanza los resultados cumpliendo los plazos acordados.",
    "Administra adecuadamente los recursos disponibles para el logro de la meta.",
    "Actitud recursiva frente a los problemas y con las relaciones interpersonales.",
    "Se motiva cuando reconocen sus logros.",
    "Toma en consideración las normas, la calidad y los protocolos, en pro de los resultados.",
    "Aspira alcanzar cada vez más autoridad y liderazgo.",
    "Cuida de las relaciones claves.",
    "Delega con un ánimo entusiasta y optimista.",
    "Valora a quienes se adaptan rápidamente a los cambios.",
    "Creatividad para innovar sistemas, para mejorar el rendimiento y la calidad.",
    "Visión global del proyecto, incluyendo la gente y la calidad.",
    "Motiva al equipo a seguir el ritmo en el logro de los objetivos, con conciencia de calidad.",
    "Amigable y optimista en circunstancias favorables.",
    "Asume una gran diversidad de tareas, manteniendo el foco en lo importante.",
    "Prueba nuevos procedimientos, más dinámicos y rápidos, en la solución de problemas.",
    "Actitud atrevida y arriesgada, con cierto grado de cautela, cuando se siente optimista.",
    "Soluciona problemas e innova de forma profesional."
  ],
  29: [
    "Usa sistemas exitosos para lograr resultados, de forma rápida, respetando la calidad.",
    "Prefiere estar en roles con poder y autoridad para decidir e impulsar cambios e ideas.",
    "Visión global y de detalles.",
    "Cuida de que el clima sea positivo para el logro de los objetivos.",
    "Actitud dinámica que persuade al equipo a lograr resultados, cuidando los protocolos.",
    "Automotivación para lograr los objetivos en situaciones favorables.",
    "Creatividad para innovar sistemas, para mejorar el rendimiento y la calidad.",
    "Dirige a la gente para lograr los objetivos con excelencia.",
    "Negocia los conflictos entre las personas, si esto favorece los resultados.",
    "Actitud pionera y dinámica, manteniendo el cuidado por la calidad.",
    "Analiza información relevante, a buen ritmo, antes de decidir.",
    "Alcanza los resultados cumpliendo los plazos acordados.",
    "Administra adecuadamente los recursos disponibles para el logro de una meta.",
    "Favorece los resultados, cuidando de los procesos, de forma enérgica y cautelosa.",
    "Innova tomando en cuenta los hechos y los indicadores.",
    "Actitud directa y contundente matizada con diplomacia y sensibilidad por las personas.",
    "Asume riesgos calculando las consecuencias.",
    "Actitud realizadora e implementadora de soluciones creativas.",
    "Impulsa al equipo a seguir el ritmo en el logro de los objetivos, con conciencia de calidad.",
    "Anticipa y resuelve problemas de forma creativa.",
    "Gerencia el cambio, calculando los costos."
  ],
  30: [
    "Usa sistemas exitosos para lograr resultados, de forma rápida, respetando los estándares.",
    "Capacidad para analizar opciones a un buen ritmo.",
    "Prefiere estar en roles de autoridad, para decidir y promover cambios.",
    "Actitud firme, con cierto interés por las personas, en situaciones favorables.",
    "Enfoque en tareas y resultados con una cuota de entusiasmo y sensibilidad.",
    "Visión global con conciencia de calidad.",
    "Visión objetiva de futuro, con un ligero nivel de optimismo.",
    "Cuida de mantener un clima de energía y análisis, para el logro de los objetivos.",
    "Actitud dinámica que inspira a lograr resultados, cuidando los protocolos.",
    "Automotivación para lograr los objetivos, en situaciones favorables.",
    "Dirige a la gente para alcanzar buenos resultados rápidamente.",
    "Negocia los conflictos entre las personas, si esto favorece los resultados de calidad.",
    "Cumple con los protocolos, que ayudan a la innovación y al dinamismo.",
    "Alcanza los resultados cumpliendo con los plazos acordados",
    "Administra adecuadamente los recursos disponibles.",
    "Aprecia la rapidez en el logro de resultados y una actitud abierta al cambio y a la calidad.",
    "Motiva a las personas a usar los procesos de forma creativa, en pro de los resultados.",
    "Analiza información relevante, a buen ritmo, para resolver los problemas.",
    "Abarca muchos proyectos a la vez.",
    "Asume riesgos evaluando las consecuencias.",
    "Actitud directa y contundente, equilibrada ligeramente con diplomacia y sensibilidad."
  ],
  31: [
    "Presiona a los demás, con gentileza, para obtener resultados.",
    "Concentra los esfuerzos en el logro de los objetivos, discriminando lo que es importante.",
    "En situaciones relajadas muestra una actitud amable y considerada.",
    "Da seguimiento a sus compromisos y promesas.",
    "Iniciativa. Se concentra en el logro de los objetivos.",
    "Transforma la teoría en soluciones prácticas.",
    "Se adapta al equipo en pro de los resultados.",
    "Dirige y organiza la ejecución de las tareas, para garantizar los resultados y la calidad.",
    "Disponible para la acción y el riesgo, calculando los costos objetivamente.",
    "Innova en pro de aumentar el desempeño y los estándares de calidad.",
    "En la calma puede ser paciente y detallista.",
    "En situaciones complejas actúa con sentido de urgencia.",
    "Evalúa minuciosamente a quienes van a incidir en su desempeño.",
    "Reflexiona frente al estrés, como primer recurso para calmarse.",
    "Actitud ética y leal en situaciones favorables, de forma activa.",
    "Responsable con la ejecución de sus tareas y el logro de sus metas.",
    "Advierte, de forma activa, cuando hay desviaciones injustificadas.",
    "Analiza la información y hechos disponibles en pro del logro rápido de los resultados.",
    "Se integra al equipo cuando es de alto desempeño.",
    "Atiende varios proyectos a la vez, trabajando duro para lograr los resultados.",
    "Visión global y de detalles.",
    "Visión de futuro tomando en cuenta los referentes y sistemas probados.",
    "Actitud creativa, metódica y lógica, para enfrentar retos complejos.",
    "Actitud curiosa e innovadora, matizada por cierto nivel de estabilidad."
  ],
  32: [
    "Transforma la teoría en soluciones prácticas.",
    "Enseña conceptos técnicos, de forma aplicable.",
    "Se adapta al entorno para beneficiar el logro de resultados, con consciencia de calidad.",
    "Actitud generosa y bondadosa, de forma activa, para beneficiar el logro de los objetivos.",
    "Presiona a los demás, con gentileza, para obtener resultados.",
    "Sensibilidad interna que matiza una tendencia natural hacia la impulsividad.",
    "Enfrenta retos y se enfoca en los resultados, con un buen sentido de equipo.",
    "Evalúa adecuadamente a las personas, la mayoría de las veces.",
    "Se integra al equipo cuando es de alto desempeño.",
    "Actitud ética y leal de forma activa, en la rutina.",
    "Pensamiento creativo e innovador y a la vez crítico, analítico y metódico.",
    "Interés principal en los resultados, la estabilidad y la calidad.",
    "Da seguimiento a sus compromisos y promesas.",
    "Se adapta al equipo en pro de los resultados.",
    "Coordina la ejecución de las tareas, en función de la calidad y los plazos.",
    "En situaciones relajadas muestra una actitud amable y considerada.",
    "Disponible para la acción y el riesgo, pero calculando los costos objetivamente.",
    "Atiende varios proyectos a la vez, trabajando duro para lograr los resultados.",
    "Responsable con la ejecución de sus tareas y el logro de sus metas.",
    "Visión global y de detalles.",
    "Visión de futuro tomando en cuenta los referentes y sistemas probados.",
    "Actitud curiosa e innovadora, matizada por cierto nivel de estabilidad.",
    "Advierte, de forma activa, cuando hay desviaciones injustificadas.",
    "En la calma puede ser paciente y detallista.",
    "En situaciones tensas o complejas actúa con sentido de urgencia.",
    "Innova en pro de aumentar el desempeño y los estándares de calidad.",
    "Analiza rápidamente la información y hechos disponibles para tomar decisiones.",
    "Se enfoca en lo importante a la hora de decidir.",
    "Cumple con los estándares de calidad, sin perjudicar el clima y el sentido de equipo.",
    "Reflexiona frente al estrés, como primer recurso para calmarse.",
    "Actitud creativa y lógica, para enfrentar retos complejos.",
    "Aprecia el cambio dentro de un ambiente confiable y estable."
  ],
  33: [
    "Crea un clima de logro, mientras coordina que se mantenga la calidad.",
    "Se adapta al ritmo del entorno. Si es necesario, acelera el ritmo en pro de los resultados.",
    "Conecta con el equipo de forma enérgica y amistosa, para facilitar el alcance de la meta.",
    "Toma en cuenta la calidad si beneficia al equipo en el esfuerzo por lograr resultados.",
    "Actitud generosa, gentil y paciente, de forma activa, en condiciones favorables.",
    "Estabiliza, dinámicamente, los momentos de tensión.",
    "Aprecia anticiparse a los cambios de forma dinámica, dentro de un ambiente confiable.",
    "Asume retos, tomando en cuenta al equipo y la calidad.",
    "Siente que tanto los resultados como las personas son importantes.",
    "Decide qué priorizar según la situación.",
    "Se integra al equipo si hay un alto desempeño.",
    "Actitud detallista, analizadora y metódica frente a problemas rutinarios.",
    "Actitud innovadora, lógica y práctica, frente a nuevos desafíos o situaciones complejas.",
    "Advierte, de forma activa, cuando hay desviaciones injustificadas.",
    "Responsable con la ejecución de sus tareas y el logro de sus metas.",
    "Visión global y de detalles.",
    "Visión de futuro tomando en cuenta los referentes y sistemas probados.",
    "Actitud curiosa e innovadora, matizada por cierto nivel de estabilidad.",
    "Se adapta al equipo en pro de los resultados.",
    "Natural impulsividad matizada por un buen nivel de sensibilidad hacia los demás.",
    "Reflexiona frente al estrés, como primer recurso para calmarse.",
    "Presiona a los demás, con gentileza, para obtener resultados.",
    "En la calma puede ser paciente y detallista.",
    "En situaciones complejas actúa con sentido de urgencia.",
    "Actitud ética y leal en situaciones complejas, de forma activa.",
    "Pensamiento creativo e innovador y a la vez crítico, analítico y lógico.",
    "Consciente de sus compromisos y promesas.",
    "Dirige la ejecución de las tareas de forma amable y dinámica.",
    "Disponible para la acción y el riesgo, pero considerando los referentes."
  ],
  34: [
    "Trabaja en equipo si eso favorece el logro rápido de resultados de calidad.",
    "Sentido de urgencia en la rutina, en lo operativo y para el logro de los resultados.",
    "En ciertas situaciones, para cumplir con la calidad, analiza las opciones con disciplina.",
    "Implementa nuevos protocolos para obtener mejores resultados rápidamente.",
    "Interés en la solución de problemas con innovación, creatividad y disciplina.",
    "Puede contar con experiencia y ser profesional en diversas áreas.",
    "Se adapta al ritmo del entorno. Si es necesario, acelera el ritmo en pro de los resultados.",
    "Actitud curiosa, innovadora y creativa, pero con sentido crítico y objetivo.",
    "Dirige la implementación del plan, para obtener resultados rápidos.",
    "Trabaja duro, en múltiples asignaciones.",
    "Implementa varias opciones de solución a nuevos problemas y retos.",
    "Visión global y de detalles.",
    "Visión de futuro tomando en cuenta los referentes y sistemas probados.",
    "Responsable con la ejecución de las tareas y el logro de las metas.",
    "Cumple y hace cumplir las promesas, los plazos y la calidad.",
    "En la calma puede ser paciente y amable.",
    "En situaciones complejas actúa con sentido de urgencia y firmeza.",
    "Pensamiento creativo e innovador y a la vez crítico, lógico, analítico y metódico.",
    "Disponible para la acción, pero considerando los riesgos objetivamente.",
    "Se integra al equipo si hay un alto desempeño.",
    "Advierte, de forma activa, cuando hay desviaciones injustificadas.",
    "Conecta con el equipo de forma enérgica y amistosa, en pro de los resultados y la calidad.",
    "Natural impulsividad matizada con reflexión."
  ],
  35: [
    "Sociable y convincente.",
    "Asume la responsabilidad.",
    "Motiva y persuade con entusiasmo el logro de los objetivos.",
    "Se integra y trabaja en equipo.",
    "Foco en las tareas, relaciones e ideas.",
    "Ritmo rápido frente al cambio.",
    "Independiente cuando se requiere.",
    "Optimista y con buen sentido del humor.",
    "Actitud imaginativa, extrovertida, innovadora, auténtica, amable y generosa.",
    "Asume con naturalidad roles de liderazgo dentro del equipo.",
    "Actitud adaptable y de fácil trato en circunstancias favorables.",
    "Se involucra en actividades en donde las personas demuestran dedicación y constancia.",
    "Hace amistades rápidamente.",
    "Asume con gusto las posiciones de responsabilidad y autoridad.",
    "Es popular y disfruta del reconocimiento público.",
    "Resuelve conflictos y problemas probando soluciones novedosas.",
    "Motiva de forma natural e influye en los demás con sus ideas.",
    "Disfruta de la compañía de las personas.",
    "Busca activamente que le involucren en los proyectos en roles claves.",
    "Tiende a buscar variedad y dinamismo.",
    "Visión global en donde las personas están incluidas.",
    "Planifica, si es posible, a gran escala, con ideas novedosas."
  ],
  36: [
    "Sociable y convincente.",
    "Asume la responsabilidad.",
    "Motiva y persuade con entusiasmo el logro de los objetivos.",
    "Se integra y trabaja en equipo.",
    "Considera a todas las personas en la toma de decisiones.",
    "Paciente y de buen humor cuando se relaciona.",
    "Persistente en el abordaje de las tareas y obtención de resultados.",
    "Logra los objetivos a través de la gente y del apoyo del equipo.",
    "Actitud positiva para negociar conflictos entre las personas.",
    "Habilidades para orientar, aconsejar o entrenar, activamente.",
    "Ritmo equilibrado en situaciones favorables.",
    "Sentido de urgencia cuando está bajo presión.",
    "Supera momentos de aburrimiento en la rutina, si se lo propone.",
    "Actitud respetuosa, cálida, sincera y receptiva con los demás, de forma activa.",
    "Inspira confianza.",
    "Motiva a los demás, a través de su optimismo y energía, para el logro de resultados.",
    "Le interesa comprender a los demás.",
    "Le gusta pertenecer, integrarse y llamar la atención de los otros.",
    "Actitud directa y firme, en ciertas circunstancias.",
    "Se desenvuelve bien con cualquier tipo de grupo o variedad de personas.",
    "Impulsa el rendimiento a través del entusiasmo, energía y gentileza."
  ],
  37: [
    "Enfrenta obstáculos con determinación.",
    "Sociable y convincente.",
    "Asume la responsabilidad.",
    "Motiva y persuade con entusiasmo y energía al equipo, para el logro de los objetivos.",
    "Ritmo rápido frente al cambio y la toma de decisiones.",
    "Independiente cuando se requiere.",
    "Asume con naturalidad roles de liderazgo dentro del equipo.",
    "Actitud adaptable y de fácil trato en circunstancias favorables.",
    "Firme en situaciones complejas.",
    "Se involucra en actividades en donde las personas demuestran dedicación y constancia.",
    "Resuelve conflictos y problemas probando soluciones novedosas.",
    "Tiende a buscar variedad y dinamismo.",
    "Visión global en donde las personas están incluidas.",
    "Planifica, si es posible, a gran escala, con ideas novedosas.",
    "Aborda varias tareas a la vez.",
    "Se aproxima a las personas de diversas maneras.",
    "Regula el comportamiento según lo demanda la situación.",
    "Logra el apoyo de las personas para solucionar los problemas.",
    "Afronta los desafíos con creatividad e innovación.",
    "Hábil resolviendo los conflictos de las personas.",
    "Le gusta pertenecer, integrarse y obtener la atención de los otros.",
    "Actitud dinámica, activa, decidida, ambiciosa, con cierto grado de reflexión.",
    "Sigue los procedimientos, cuando la obtención de los resultados depende de eso.",
    "Altos niveles de exigencia personal y con los demás.",
    "Matiza su actitud exigente con una natural sensibilidad hacia el bienestar de las personas.",
    "Busca activamente participar en la toma de decisiones.",
    "Actitud informal e independiente.",
    "Puede ser persistente, si se lo propone."
  ],
  38: [
    "Actitud amable, colaboradora y entusiasta.",
    "Considera a todas las personas en la toma de decisiones.",
    "Paciente y de buen humor, cuando se relaciona.",
    "Persistente en el abordaje de las tareas y obtención de resultados.",
    "Actitud positiva para negociar conflictos entre las personas.",
    "Habilidades para orientar, aconsejar o entrenar.",
    "Supera momentos de aburrimiento en la rutina, si se lo propone.",
    "Actitud respetuosa, cálida, sincera y receptiva con los demás, de forma activa.",
    "Le interesa comprender a los demás.",
    "Bajo ciertas circunstancias puede ser firme.",
    "Se desenvuelve bien con cualquier tipo de grupo o variedad de personas.",
    "Prefiere compartir con su grupo de confianza.",
    "Impulsa el rendimiento a través del entusiasmo, energía y gentileza.",
    "Sensibilidad personal hacia las necesidades de los demás.",
    "Ejerce el liderazgo con amabilidad y encanto personal.",
    "Cohesiona al equipo de forma activa.",
    "Sociable, leal y amable, con matices de firmeza.",
    "Actitud jovial, creativa y alegre.",
    "Aborda varias tareas a la vez.",
    "Cuida su imagen personal.",
    "Le agrada que le acepten y le quieran.",
    "Se integra con facilidad y trabaja en equipo.",
    "Prioriza atender las necesidades de las personas cercanas.",
    "Ritmo equilibrado al actuar y decidir.",
    "Le agrada que le reconozcan públicamente sus logros.",
    "Toma la iniciativa y asume riesgos, tanto en lo laboral como en lo personal.",
    "Servicial. Ayuda a los demás activamente.",
    "Actitud sincera. Confía en los demás y aprecia que le demuestren confianza.",
    "Buena disposición con las personas.",
    "Apoya al equipo para alcanzar las metas y obtener resultados.",
    "Construye redes de contactos claves.",
    "Usa metodologías probadas para alcanzar los resultados.",
    "Se aproxima a las personas de diversas maneras.",
    "Actitud informal y relajada."
  ],
  39: [
    "Inspira autoridad y confianza.",
    "Le gusta rodearse de personas expertas.",
    "Se preocupa por formarse.",
    "Se enfoca en cumplir con las responsabilidades.",
    "Busca proyectar una imagen profesional.",
    "Flexible y prefiere trabajar con personas similares en ese sentido.",
    "Logra el apoyo y la colaboración de la gente.",
    "Ritmo rápido con foco en resultados inmediatos.",
    "Impulsa el alto rendimiento en el logro de la meta, de principio a fin.",
    "Crea un buen clima a su alrededor.",
    "Se adapta a los cambios con facilidad.",
    "Alinea al equipo con sus objetivos.",
    "Realiza diversas actividades y proyectos a la vez.",
    "Puede ser exigente y firme cuando es necesario.",
    "Motiva a los demás a realizar la tarea y lograr los objetivos.",
    "Forma y orienta a la gente hacia la calidad y los resultados.",
    "Se integra en los diferentes ambientes en los que se mueve.",
    "Facilidad para relacionarse con diversos niveles jerárquicos.",
    "Actitud creativa, optimista, expresiva, espontánea y entusiasta.",
    "Le gusta tener autoridad para tomar decisiones.",
    "Promociona la implementación de buenas prácticas.",
    "Se enfoca en construir una red de personas amplias y claves.",
    "Capacidad de convocatoria y de empuje con el equipo.",
    "Establece límites ante grupos grandes, ya que es más impersonal",
    "Proyecta una imagen de tener todo bajo control.",
    "Busca mejores procedimientos para solucionar los problemas.",
    "Logra resultados a través de su liderazgo."
  ],
  40: [
    "Inspira autoridad, liderazgo y confianza.",
    "Busca proyectar una imagen profesional y rodearse de personas expertas.",
    "Equilibra la atención de las relaciones, con el logro de resultados de calidad.",
    "Motiva a los demás a tomar en cuenta los procesos, en el logro de resultados.",
    "En situaciones complejas tiene un ritmo rápido, pero sin atropellar.",
    "En la rutina analiza, para tomar decisiones.",
    "Creatividad y entusiasmo para proponer o seguir los protocolos de calidad.",
    "Relaja ambientes tensos con optimismo y sentido del humor.",
    "Se preocupa por prepararse y estar a la altura de los retos y desafíos.",
    "Orienta con éxito a la gente, en las tareas claves.",
    "Motiva al equipo, con energía y optimismo, a lograr las metas, cuidando de la calidad.",
    "Negocia los conflictos entre las personas.",
    "Crea un ambiente estimulante, competente, de integración y de logros.",
    "Da importancia a sus sentimientos y a los de los demás.",
    "Estimula al equipo a enfrentar y superar los obstáculos.",
    "Descubre buenas prácticas y motiva a la gente a seguirlas.",
    "Impulsa el alto rendimiento en el logro de la meta, de principio a fin.",
    "Puede ser exigente y firme, en situaciones favorables.",
    "Actitud sistematizada en la planificación, en ocasiones.",
    "Asume roles claves en el equipo, en situaciones de presión.",
    "Construye redes amplias de personas claves.",
    "Cierto nivel de cautela ante los riesgos, que equilibra su impulsividad por seguir adelante.",
    "Abierto a los cambios y retos, a la hora de resolver problemas."
  ],
  41: [
    "Inspira al equipo a cuidar de los detalles y de la calidad para lograr resultados ágilmente.",
    "Genera confianza, haciendo sentir a la gente como compañeros indispensables.",
    "Negocia los conflictos entre las personas de forma positiva.",
    "Se adapta al entorno autorregulando su comportamiento, según la situación.",
    "Autoconfianza en el manejo del liderazgo.",
    "Proyecta una imagen amigable, mientras mantiene el control.",
    "Si es necesario toma distancia para analizar, proyectando una imagen receptiva.",
    "Busca, creativamente, mejorar los procedimientos y protocolos formales.",
    "Consigue resultados a través de las personas.",
    "Impulsa el cambio y el dinamismo.",
    "Asume riesgos sustentados en análisis.",
    "Emprende proyectos con optimismo y creatividad.",
    "Inspira autoridad y confianza.",
    "Actitud decidida, independiente, ambiciosa y optimista.",
    "Se interesa en ganar la confianza de los demás, activamente.",
    "Analiza para innovar en la solución de los problemas.",
    "Busca variedad en su entorno.",
    "Se preocupa por prepararse y estar a la altura de los retos y desafíos.",
    "Se interesa en superar los obstáculos cumpliendo con los protocolos.",
    "Visión de futuro de forma creativa, manteniendo un interés especial en la calidad.",
    "Sabe aproximarse de diferentes maneras a las personas.",
    "Puede ser exigente y firme cuando es necesario.",
    "Actitud competitiva, persistente y convincente.",
    "Le gusta tener autoridad para tomar decisiones."
  ],
  42: [
    "Inspira al equipo a cuidar de los detalles y de la calidad, de forma dinámica.",
    "Delega y hace seguimiento de forma positiva, para asegurarse del cumplimiento.",
    "Cuida de que se cumplan los plazos y la calidad, generando un buen clima.",
    "Gestiona con tacto las situaciones difíciles.",
    "Actitud meticulosa y consciente, en la supervisión de los procesos.",
    "Crea ambientes agradables, confortables, competentes, de integración y de logros.",
    "Planifica y sigue el cronograma, con entusiasmo.",
    "Actitud espontánea y expresiva matizada con diplomacia y gentileza.",
    "Alinea sus emociones para potenciar el rendimiento y la calidad.",
    "Buen manejo del humor. Actitud diplomática y divertida.",
    "Demuestra optimismo y confianza, por el respeto de los protocolos.",
    "En la rutina tiene un ritmo rápido, pero sin atropellar.",
    "Motiva a las personas a lograr resultados excelentes.",
    "Aplomo y autoconfianza.",
    "Relaja ambientes tensos con humor y diplomacia.",
    "Analiza la información disponible, con buen ritmo.",
    "Equilibra la dedicación a las relaciones, con las tareas y el cuidado de la calidad.",
    "Se prepara para estar a la altura de las responsabilidades.",
    "Negocia los conflictos entre las personas de forma positiva.",
    "Sensibilidad por las necesidades de los demás.",
    "Bajo tensión motiva al equipo a rendir con alto desempeño.",
    "Asume un rol clave dentro del equipo cuando hay presión.",
    "Busca activamente la atención de la gente y el reconocimiento de sus logros.",
    "Alinea al equipo a la ejecución de tareas claves, controlando la calidad.",
    "Impulsa a los demás, con dinamismo, a superar los obstáculos."
  ],
  43: [
    "Enfoque sincero y estable, en las relaciones.",
    "Orienta y motiva al equipo a cuidar de los detalles y de la calidad.",
    "Actitud amistosa, amable y gentil.",
    "Promueve la creación de protocolos, para el control eficiente de la calidad.",
    "Se prepara profesionalmente y estimula a los demás a hacerlo también.",
    "Delega y hace seguimiento a lo largo de todo el proyecto.",
    "Cuida, de forma positiva, del control de calidad.",
    "Puede llevarse bien con diferentes estilos de personas.",
    "Orienta y entrena con paciencia a las personas.",
    "En tiempos favorables, estimula a los demás a innovar en las metodologías.",
    "Motiva a seguir los protocolos, normas y procedimientos, de forma positiva.",
    "Amplía su conocimiento constantemente.",
    "Le gusta rodearse de personas que valoren el conocimiento.",
    "Se preocupa por estar a la altura de sus responsabilidades.",
    "Actitud generosa y gentil.",
    "Actitud conciliadora frente a los conflictos.",
    "Se enfoca en ganar-ganar, cuando negocia.",
    "Motiva la colaboración en el equipo.",
    "Buen sentido del humor, cuando el ambiente es relajado.",
    "Genera entusiasmo con su optimismo.",
    "Afronta sus responsabilidades de forma metódica, sistemática y persistente."
  ],
  44: [
    "Actitud profesional que influye positivamente en los demás.",
    "Se lleva bien con diferentes estilos de personas.",
    "Motiva, en los demás, alcanzar altos estándares de calidad.",
    "Se esfuerza en lograr resultados, cumpliendo con los protocolos y trabajando en equipo.",
    "Enfoque sincero y estable, en las relaciones.",
    "Se esmera en cumplir con las promesas.",
    "Se preocupa por estar a la altura de sus responsabilidades.",
    "Se esfuerza por cumplir con los plazos y con los estándares de calidad.",
    "Inspira y motiva a los demás a cuidar de los detalles y de la calidad.",
    "Se prepara profesionalmente y promueve que los demás también lo hagan.",
    "Actitud seria frente a los problemas, tareas y responsabilidades.",
    "Buen sentido del humor cuando el ambiente es relajado.",
    "Promueve la colaboración entre las personas.",
    "Genera entusiasmo y motivación.",
    "Actitud amistosa, generosa, gentil y amable.",
    "Delega y hace seguimiento a lo largo de todo el proyecto.",
    "Motiva a seguir los protocolos, normas y procedimientos, de forma positiva.",
    "Actitud conciliadora frente a los conflictos.",
    "Se enfoca en ganar-ganar, cuando negocia.",
    "Toma decisiones sustentadas en análisis y pensamiento crítico.",
    "Proyecta la imagen de alguien central y clave.",
    "Afronta sus responsabilidades de forma organizada, sistemática y persistente."
  ],
  45: [
    "Se lleva bien con diferentes estilos de personas.",
    "Prefiere relacionarse con un pequeño grupo de personas cercanas.",
    "Se enfoca en las relaciones y en el bienestar de las personas.",
    "Afronta sus responsabilidades de forma metódica, sistemática y persistente.",
    "Motiva y ofrece estabilidad a los demás.",
    "Sede el protagonismo a otros que requieren de más atención o control.",
    "Buena disposición con los demás.",
    "Inspira confianza, receptividad y apertura.",
    "Paciente, por lo general.",
    "Da importancia a las necesidades de los demás y al cuidado de la calidad.",
    "Estimula, de forma positiva, el seguimiento de los protocolos y de las normas.",
    "Entrena a otros para alcanzar altos estándares de calidad, en su área de especialidad.",
    "Considera a los demás y evita molestar a la gente.",
    "Se prepara profesionalmente y promueve que los demás también lo hagan.",
    "Actitud generosa y amable.",
    "Estimula a los demás a innovar en las metodologías y procesos, en situaciones favorables.",
    "Actitud conciliadora frente a los conflictos.",
    "Se enfoca en ganar-ganar, cuando negocia.",
    "Enfoque sincero y estable, en las relaciones.",
    "Motiva a los demás a cuidar de los detalles.",
    "Se preocupa por estar a la altura de sus responsabilidades.",
    "Delega y hace seguimiento a lo largo de todo el proyecto.",
    "Pensamiento crítico y analítico en la toma de decisiones, con cierto nivel de optimismo.",
    "Promueve la colaboración en el equipo."
  ],
  46: [
    "Trabaja en equipo con actitud sociable, cooperativa y optimista.",
    "Conforma equipo y logra que funcione de forma muy organizada.",
    "Enfoque sincero y estable, en las relaciones.",
    "Es una fuente de optimismo y motivación, dentro del equipo.",
    "Detallista en el cuidado del control de la calidad y el respeto a los procedimientos.",
    "Paciente con los demás, por lo general.",
    "Motiva a las personas con entusiasmo y gentileza.",
    "Aplomo personal.",
    "Meticulosidad y cuidado de los detalles.",
    "Actitud amplia y receptiva, a la hora de relacionarse con los demás.",
    "Competente en su área de experticia.",
    "Ayuda a otros a calmarse en momentos de tensión.",
    "Asume con seriedad la calidad y los protocolos.",
    "Buen sentido del humor, de forma adecuada.",
    "Tiene habilidades sociales.",
    "Realiza sus tareas con entusiasmo.",
    "Autodidacta en la adquisición de habilidades y conocimiento.",
    "Paciencia para entrenar y orientar a los demás.",
    "Administrar adecuadamente sus responsabilidades y tareas.",
    "Alinea al equipo a los objetivos compartidos.",
    "Asume las críticas de los demás de forma constructiva.",
    "Se destaca por la calidad en los resultados obtenidos.",
    "Consciente del tiempo y de los plazos.",
    "Competente técnicamente y en las relaciones con las personas.",
    "Ritmo equilibrado, para lograr resultados.",
    "Tiende a confiar e inspirar confianza.",
    "Actitud amistosa, de fácil trato y agradable, en situaciones favorables.",
    "Cumple con las normas y alcanza los estándares de calidad, manteniendo un buen clima.",
    "Entusiasma a la gente a llevar adelante los procesos."
  ],
  47: [
    "Se apasiona cuando siente motivación por algo.",
    "Sensibilidad para atender las necesidades de los demás.",
    "Soluciona problemas, cuidando a las personas involucradas.",
    "Disposición para ayudar y orientar a las personas.",
    "Se relaja con las normas en los asuntos triviales.",
    "En situaciones importantes respeta los sistemas establecidos.",
    "Desarrolla complejos protocolos y le hace seguimiento.",
    "Integra al equipo de forma activa.",
    "Actitud generosa, paciente y bondadosa, de una manera dinámica.",
    "Presiona con gentileza, para lograr los objetivos.",
    "Actitud serena en condiciones normales.",
    "Se enfoca en mantener el ritmo del equipo, para el logro de los resultados.",
    "Se adapta al entorno para lograr los objetivos.",
    "Trabaja duro y con energía, por una causa o por una meta.",
    "Asume el reto de progresar en los sistemas y métodos establecidos.",
    "Atiende al equipo, sin perder de vista los resultados del proyecto.",
    "En la rutina tiene sentido de urgencia.",
    "Colabora con los demás, para avanzar en los resultados, cuando el clima es tenso.",
    "Asume riesgos que no afectan su estabilidad.",
    "En situaciones de tensión se apasiona y se llena de energía.",
    "Resuelve problemas de forma creativa e innovadora.",
    "Influencia de forma positiva en personas negativas o que no colaboran.",
    "Crea un ambiente de cooperación y compañerismo.",
    "Actitud firme y determinada en sus planteamientos y propuestas.",
    "Incluye a la gente para crear soluciones a los problemas.",
    "Apoya al equipo y aporta innovación a los proyectos."
  ],
  48: [
    "Logra resultados únicos, orientado en la gente.",
    "Se apasiona frente a los retos que implican la solución de problemas.",
    "Se orienta a servir a las personas de forma activa.",
    "Buen sentido de equipo.",
    "Ayuda a las personas a lograr sus metas.",
    "Asume responsabilidades con determinación y persistencia.",
    "Asume riesgos que no afectan su estabilidad.",
    "Presiona con gentileza, para obtener los resultados.",
    "Logra resultados a través de la consistencia.",
    "Crea un buen ambiente, mientras sostiene el foco en los resultados.",
    "Planifica y desarrolla sistemas, para los proyectos o solución de problemas.",
    "Asume el reto de mejorar los sistemas establecidos.",
    "Se enfoca en la gente, en el mantenimiento del ritmo y en la creatividad.",
    "Habilidades sociales y de integración.",
    "Se relaja con las normas en los asuntos triviales.",
    "En situaciones importantes respeta los sistemas establecidos.",
    "Actitud paciente, colaborativa y conciliadora de forma activa.",
    "Trabaja duro, con energía y lealtad, por una causa o por una meta.",
    "Motiva a los demás para que mantengan el ritmo y estén enfocados.",
    "Sensibilidad para atender las necesidades de los demás.",
    "Soluciona problemas, cuidando a las personas involucradas.",
    "Visión global y de detalles en los conflictos.",
    "Intermedia y ayuda a conciliar las partes.",
    "Asume un liderazgo dinámico, activo y pionero, en situaciones favorables.",
    "Actitud conciliadora, evitando tomar posiciones, en situaciones tensas.",
    "Aporta al equipo valor con su colaboración.",
    "Actitud generosa y bondadosa, de una manera dinámica.",
    "Apoya al equipo y aporta innovación a los proyectos.",
    "Incluye a la gente para crear soluciones a los problemas.",
    "Crea un ambiente de cooperación y compañerismo."
  ],
  49: [
    "Sensibilidad para atender las necesidades de los demás.",
    "Cuida de su imagen.",
    "Actitud de servicio, con miras a lograr resultados.",
    "Buena disposición con los demás.",
    "Habilidad natural para construir relaciones positivas de forma activa.",
    "Coopera con los demás con el foco puesto en lograr resultados.",
    "Obedece para no generar conflictos, si lo considera necesario.",
    "Considera a los demás y evita molestar a la gente.",
    "Asume riesgos que no afectan su estabilidad.",
    "Se integra con facilidad y trabaja en equipo.",
    "Actitud dinámica y liderazgo activo y pionero, en condiciones favorables.",
    "Actitud conciliadora y evita tomar posiciones, en situaciones tensas.",
    "Se lleva bien con las personas, es alguien fácil de tratar.",
    "Gentil presionando a los demás, para la obtención de resultados.",
    "Busca la aprobación y aceptación social.",
    "Expresa confianza a los demás, de forma sincera.",
    "Apoya al equipo en el esfuerzo por alcanzar las metas.",
    "Aporta creatividad, optimismo y estabilidad al entorno.",
    "Actitud colaborativa, generosa y gentil.",
    "Sentido de urgencia bajo presión.",
    "Se enfoca en las relaciones y en construir redes con personas claves.",
    "Por lo general, es paciente.",
    "Influye con su actitud comprensiva y motivación, en la obtención de resultados.",
    "Los demás buscan su apoyo para un consejo o como coach.",
    "Cuida de los protocolos, para que los resultados sean buenos desde el primer intento.",
    "Asume el reto de mejorar los sistemas establecidos.",
    "Aporta estímulo y entusiasmo al ambiente."
  ],
  50: [
    "Disponible para ayudar o colaborar con los demás.",
    "Se enfoca en las relaciones y en lo social.",
    "Estable y con energía.",
    "Respeta los sistemas y métodos probados.",
    "Se integra con los demás y se lleva bien con la gente.",
    "Actitud dinámica, liderazgo activo y pionero, en condiciones favorables.",
    "Actitud conciliadora y evita tomar posiciones, en situaciones tensas.",
    "Gentil presionando a los demás en la obtención de resultados.",
    "Trabaja bien en equipo.",
    "Aporta valor con su colaboración.",
    "Actitud generosa y amigable con los demás.",
    "Motiva e integra al equipo de forma positiva.",
    "Permite a los demás que piensen a su manera y prueben sus propias ideas.",
    "Estimula la sinceridad entre las personas.",
    "Persistente, lleva el proyecto adelante de principio a fin.",
    "Desplaza el protagonismo a quienes requieren de más atención.",
    "Actitud voluntariosa y colaboradora con el progreso del equipo.",
    "Paciente, de fácil trato y de buen humor, por lo general.",
    "Se maneja bien tanto en grupos grandes como en grupos pequeños.",
    "Construye vínculos positivos con las personas, activamente.",
    "Motiva con su optimismo.",
    "Asume el reto de progresar, en los sistemas y métodos establecidos.",
    "Actitud positiva en la negociación de los conflictos entre las personas.",
    "Coopera con los demás sin perder de vista los resultados.",
    "Asume riesgos que no afectan su estabilidad.",
    "Asume con sentido de urgencia las situaciones que lo ameritan.",
    "Actitud de servicio y apertura, de forma activa.",
    "Mantiene un buen clima y evita los conflictos.",
    "Interés en que todos se sientan atendidos e involucrados.",
    "Los demás buscan su apoyo para un consejo o como coach.",
    "Cuida de los protocolos para que los resultados sean buenos desde el primer intento.",
    "Sensibilidad para atender las necesidades de los demás."
  ],
  51: [
    "Se enfoca en las relaciones y en los sistemas probados.",
    "Sensibilidad para atender las necesidades de los demás.",
    "Considera importante la calidad y los procesos y se esmera en comprenderlos.",
    "Paciente, amigable y servicial, por lo general.",
    "Los demás buscan su apoyo para un consejo o como coach.",
    "Buena disposición con los demás.",
    "Actitud generosa, colaborativa y receptiva, en general.",
    "Cuida de su imagen.",
    "Tradicionalista, prefiere la estabilidad más que el cambio.",
    "Optimista, con tendencia a confiar.",
    "Motiva, de forma positiva, el cuidado de los detalles.",
    "Trabaja bien en equipo.",
    "Le interesa construir y mantener redes y contactos cercanos.",
    "Consulta las normas, procedimientos y protocolos, para decidir.",
    "Actitud analítica y de ritmo rápido, con decisiones rutinarias.",
    "Detallista y reflexivo en situaciones complejas.",
    "Detallista, de pensamiento analítico y actitud metódica.",
    "Enfoca desde todos los ángulos la resolución de los problemas.",
    "Se autocontrola emocionalmente.",
    "Busca la aprobación y el cariño de la gente.",
    "Prefiera relacionarse con un pequeño grupo de personas cercanas.",
    "Comparte el protagonismo con quienes requieren de más atención y control.",
    "Apoya y colabora con las personas, con calidez humana.",
    "Se esfuerza por cumplir los plazos, cuando está bajo presión.",
    "Le interesan los métodos y sistemas más que la teoría.",
    "Prueba y valida toda la información posible antes de proceder.",
    "Actitud consistente, consciente, comprensiva y considerada."
  ],
  52: [
    "Actitud conciliadora, gentil, amable, jovial y servicial.",
    "Se enfoca en las relaciones y en la calidad.",
    "Actitud cautelosa, planificada, atenta y detallista.",
    "Trabaja bien en equipo. Colabora.",
    "De fácil trato y buen sentido del humor, en condiciones normales.",
    "Enfrenta los retos y las tareas de forma seria y profesional.",
    "Se concentra en la ejecución de la tarea y el cuidado de la calidad.",
    "Optimista y amigable, en circunstancias normales.",
    "Sabe seguir lineamientos y normas.",
    "Entusiasta para afrontar la tarea metódicamente.",
    "Analiza, evalúa y reflexiona para tomar decisiones.",
    "Ritmo pausado, pero manteniendo energía y entusiasmo.",
    "Combina ágilmente la paciencia, el razonamiento, la tolerancia y el entusiasmo.",
    "Sigue los protocolos y los procedimientos, manteniendo una actitud positiva.",
    "Consigue de buena manera, que los demás cooperen.",
    "Consistente siguiendo los protocolos.",
    "Establece rutinas de trabajo y va a su ritmo.",
    "Asume el cambio de forma planificada y organizada.",
    "Prefiere la estabilidad y lo conocido.",
    "Se prepara para hacer bien sus tareas.",
    "Habilidad para enseñar a otros.",
    "Inspira confianza por su actitud profesional.",
    "Le gusta tomar un proyecto de principio a fin, cuidando de que salga bien desde el inicio.",
    "Prefiere evitar los problemas de una tarea mal hecha.",
    "Prefiere a las personas cercanas, aunque es de actitud abierta a conocer gente nueva.",
    "Le gusta tener tiempo para reflexionar y tomar decisiones con consciencia.",
    "Se relaciona sin perder de vista el cuidado de la calidad y de los detalles.",
    "La lógica, la investigación y la capacidad para comunicarse, son sus mejores recursos."
  ],
  53: [
    "Se centra en mantener la estabilidad, las tradiciones y los métodos establecidos.",
    "Actitud conciliadora, generosa, diplomática, amable y servicial.",
    "Enfrenta los retos y las tareas de forma seria y profesional.",
    "Le gusta tomar un proyecto de principio a fin, cuidando de que salga bien desde el inicio.",
    "Sociable y capaz de identificar la individualidad de cada persona.",
    "Inspira confianza por su actitud profesional.",
    "Analiza situaciones concretas y cosas tangibles.",
    "Mantiene el ritmo en la ejecución de la tarea.",
    "Actitud cautelosa, modesta, atenta y detallista.",
    "Analiza, evalúa y reflexiona para tomar decisiones.",
    "Se prepara constantemente, para hacer bien sus tareas.",
    "Busca progresar en su área de especialidad.",
    "Buen humor, flexible e informal en climas positivos.",
    "Orienta a los demás en el uso de los sistemas y los estándares de calidad.",
    "Prefiere evitar los problemas de una tarea mal hecha.",
    "Necesita tiempo para adaptarse al cambio.",
    "Asume el cambio de forma planificada y organizada.",
    "Se relaciona sin perder de vista el cuidado de la calidad y el seguimiento de los procesos.",
    "Asume la solución de los problemas, siguiendo los métodos probados.",
    "Se enfoca en las tareas y responsabilidades.",
    "Asume las responsabilidades con compromiso.",
    "No promete más de lo que puede hacer.",
    "Respeta los plazos.",
    "Respeta los estándares de calidad en la ejecución de la tarea.",
    "Le gusta tener tiempo para reflexionar y tomar decisiones con consciencia.",
    "Consistente siguiendo los protocolos.",
    "Establece rutinas de trabajo y va a su ritmo.",
    "Prefiere a las personas cercanas y de confianza.",
    "Actitud cautelosa ante los desconocidos.",
    "Habilidad para enseñar a otros.",
    "Relaja el ambiente con una cuota proporcionada de buen humor.",
    "Pensamiento crítico y capacidad de análisis."
  ],
  54: [
    "Asume las responsabilidades en las que se compromete.",
    "No promete más de lo que puede hacer.",
    "Respeta los plazos.",
    "Amigable y aporta jovialidad al ambiente.",
    "Actitud conciliadora, generosa, diplomática, complaciente, amable y servicial.",
    "Sociable y capaz de identificar la individualidad de cada persona.",
    "Analiza situaciones concretas y cosas tangibles.",
    "Evalúa y reflexiona para tomar decisiones y actuar.",
    "Cuida de lograr los estándares de calidad en sus resultados.",
    "Actitud cautelosa, modesta, atenta y detallista.",
    "Mantiene el ritmo en la ejecución de la tarea.",
    "Soluciona problemas siguiendo los protocolos, sin perder el foco en las personas.",
    "Le gusta tomar un proyecto de principio a fin, cuidando de que salga bien desde el inicio.",
    "Se apega a las formas tradicionales de hacer las cosas.",
    "Asume la solución de los problemas, siguiendo los métodos probados.",
    "Necesita tiempo para adaptarse al cambio.",
    "Asume el cambio de forma planificada y organizada.",
    "Motiva y orienta a los demás a cumplir las normas y alcanzar los estándares de calidad.",
    "Buen humor, flexible e informal, en climas positivos.",
    "Aprecia las demostraciones de amistad.",
    "Inspira confianza por su actitud profesional.",
    "Se prepara constantemente, para hacer bien sus tareas.",
    "Busca progresar en su área de especialidad.",
    "Prefiere evitar los problemas de una tarea mal hecha.",
    "Prefiere a las personas cercanas y de confianza.",
    "Actitud cautelosa ante los desconocidos.",
    "Relaja el ambiente con una cuota proporcionada de buen humor.",
    "Enfrenta los retos y las tareas de forma seria y profesional.",
    "Actitud paciente y confiable con las personas.",
    "Consistente siguiendo los protocolos.",
    "Pensamiento crítico y capacidad de análisis.",
    "Habilidad para enseñar a otros.",
    "Toma decisiones con consciencia.",
    "Establece rutinas de trabajo y va a su ritmo."
  ],
  55: [
    "Se enfuerza por lograr sus metas.",
    "Se integra y trabaja en equipo para acelerar el ritmo.",
    "Proyecta una imagen de que está disponible para colaborar.",
    "Influencia en los demás con su sentido de responsabilidad.",
    "Persiste en el seguimiento de los protocolos.",
    "Actitud curiosa en la búsqueda de soluciones a los problemas.",
    "Se interesa por encontrar la mejor solución.",
    "Consistente con sus habilidades y talentos.",
    "Elige asumir las tareas en lugar de dejárselas a los demás.",
    "Entrena a los demás para que desarrollen competencias.",
    "No necesita que le digan lo que debe hacer.",
    "Innova en los métodos o en la solución de los problemas.",
    "Se compromete en las tareas que puede cumplir.",
    "Hace seguimiento de sus compromisos.",
    "Ve oportunidades en los retos y desafíos.",
    "Se enfoca en alcanzar un excelente rendimiento, cuidando de la calidad.",
    "Actitud recursiva para enfrentar los problemas.",
    "Pensamiento crítico, lógico y capacidad de análisis.",
    "Ve los detalles y lo global de un problema.",
    "Se da cuenta de las fallas y las desviaciones rápidamente.",
    "Da valor a los métodos y sistemas, manteniendo un nivel adecuado de flexibilidad.",
    "Confiable en el logro de resultados.",
    "Se enfoca en lograr que todos ganen.",
    "Actitud colaborativa.",
    "Se esfuerza en hacer las tareas bien desde el primer intento, siguiendo los procedimientos.",
    "Alinea sus metas personales con las del equipo.",
    "Es ejemplo para los demás cuando enfoca las situaciones desde una visión justa.",
    "Exige garantías a los demás de que harán bien sus responsabilidades.",
    "Demuestra buen juicio y ética y espera lo mismo de los demás.",
    "Asume retos que le permiten ver resultados de calidad, rápidamente."
  ],
  56: [
    "Tiene un sentido equilibrado de la competencia.",
    "Actitud meticulosa, detallista y cuidadosa, al realizar las tareas.",
    "Persiste en el seguimiento de los protocolos establecidos.",
    "Se enfoca en mantener un ritmo estable y en obtener resultados de calidad.",
    "Aplica nuevas ideas, cuidando siempre de la calidad.",
    "Influencia en los demás con su sentido de responsabilidad.",
    "Consistente en sus habilidades y talentos.",
    "Aprecia el reconocimiento ocasional y sincero, de sus logros.",
    "Usa la creatividad en la solución de problemas.",
    "Paciente con los demás.",
    "Invita a la reflexión de forma activa.",
    "Sensible al ritmo del entorno y se adapta, enfocándose en los resultados.",
    "Le gusta pensar en ideas y soluciones, en un lugar privado y sin interrupciones.",
    "Enfrenta retos y desafíos, con objetividad e innovación.",
    "Actitud ordenada, limpia y organizada.",
    "Estable y aprecia tener tiempo suficiente para asumir los cambios.",
    "Se llena de valor para enfrentar lo inevitable.",
    "Analiza toda la información y hechos disponibles antes de actuar.",
    "Equilibra su naturaleza humanista con la motivación al logro.",
    "Actitud amable, reflexiva, gentil, trabajadora y diplomática.",
    "Trabaja en equipo. Apoya y colabora.",
    "Profundiza las relaciones con un pequeño grupo de personas cercanas.",
    "Se esfuerza porque los resultados sean buenos en el primer intento.",
    "Cuida de la calidad y de la seguridad en el logro de los objetivos.",
    "Motiva a las personas a lograr los resultados y a cumplir con los procesos.",
    "Firme en el cumplimiento de estándares de calidad y el logro de resultados.",
    "Consciente del tiempo. Cumple con los plazos.",
    "Pensamiento crítico, lógico y capacidad de análisis."
  ],
  57: [
    "Maneja todos los detalles necesarios en cuanto a su nivel de responsabilidad.",
    "Persistente en el seguimiento de los protocolos y métodos.",
    "Consistente en sus habilidades y talentos.",
    "Aprecia el reconocimiento ocasional y sincero, de sus logros.",
    "Enfoque realista de las normas y metodologías.",
    "Actitud detallista, cuidadosa y meticulosa.",
    "Sensible al ritmo del entorno y se adapta manteniendo el foco en los sistemas.",
    "Prefiere realizar sus tareas y analizar la información en privado, sin interrupciones.",
    "Hace equipo con un pequeño grupo de personas cercanas.",
    "Estimula a los demás a ser detallistas, analizar los indicadores y a cumplir con los plazos.",
    "Motiva a usar la lógica y la razón, poniendo a un lado las emociones.",
    "Actitud ordenada, limpia, disciplinada y organizada.",
    "Trabaja duro para lograr resultados y procedimientos que garanticen la calidad.",
    "Se llenará de valor para asumir cambios y enfrentar lo inevitable.",
    "Demuestra experticia en lo que hace.",
    "Enfrenta retos y desafíos con objetividad, de forma activa.",
    "Actitud diplomática y educada, en situaciones favorables.",
    "Evita las confrontaciones.",
    "Evita el favoritismo al evaluar a las personas.",
    "Trabaja bien en equipo. Colabora, coordina y apoya.",
    "Cuida de que los resultados sean buenos desde el primer intento.",
    "Sigue las normas y los procedimientos establecidos.",
    "Combina el pensamiento analítico y la intuición para enfrentar situaciones complejas.",
    "Aprecia tener cierto grado de libertad para modificar y mejorar los sistemas.",
    "Equilibra su naturaleza humanista con su motivación a la excelencia.",
    "Estable y aprecia que le den el tiempo necesario para asumir los cambios."
  ],
  58: [
    "Consistente en sus habilidades y talentos.",
    "Aprecia el reconocimiento ocasional y sincero, de sus logros.",
    "Se enfoca en los sistemas, las normas, la calidad y los resultados.",
    "Maneja todos los detalles necesarios en cuanto a su nivel de responsabilidad.",
    "Enfoque realista de las normas y metodologías.",
    "Actitud detallista, cuidadosa y meticulosa.",
    "Sensible al ritmo del entorno y se adapta, enfocándose en los sistemas.",
    "Actitud ordenada, introvertida, limpia y organizada.",
    "Estable y aprecia que le den el tiempo necesario para asumir los cambios.",
    "Prefiere realizar sus tareas y analizar la información en privado, sin interrupciones.",
    "Quiere analizar toda la información y datos para tomar decisiones.",
    "Trabaja bien en equipo. Colabora, coordina y apoya.",
    "Hace equipo con un pequeño grupo de personas cercanas.",
    "Equilibra su naturaleza humanista con su motivación al logro.",
    "Firme al observar los estándares de calidad, en el logro de los resultados.",
    "Consciente con los tiempos de entrega.",
    "Proyecta y demuestra experticia en lo que hace.",
    "Actitud generosa y gentil, en general.",
    "Aprecia que se cuide de la seguridad y de la calidad en los procesos.",
    "Usa la lógica y la razón, poniendo a un lado las emociones.",
    "Evita las confrontaciones.",
    "Motiva a las personas a cumplir con sus responsabilidades.",
    "Sensible a las necesidades de los demás.",
    "Enfrenta retos y desafíos con objetividad.",
    "Cuida de que los resultados sean buenos desde el primer intento.",
    "Aprecia tener cierto grado de libertad para mejorar los sistemas.",
    "Toma decisiones rutinarias con buen ritmo."
  ],
  59: [
    "Aprecia la compañía de personas motivadas a la excelencia.",
    "Impulsa el logro de resultados de calidad.",
    "Decide y acciona con sentido de urgencia, en el día a día.",
    "En circunstancias especiales decide con cautela.",
    "Analiza para tomar decisiones complejas, manteniendo un optimismo realista.",
    "Mantiene un clima de entusiasmo en situaciones favorables.",
    "Relaja el ambiente con algún comentario divertido inesperado.",
    "Se enfoca en aprovechar el tiempo de forma productiva.",
    "Busca rendir al máximo.",
    "Asume, eficientemente, un proyecto de principio a fin.",
    "Actitud recursiva en la solución de los problemas.",
    "Pensamiento analítico y crítico con visión de futuro.",
    "Tiene un lado creativo e innovador, que le gusta explorar.",
    "Asume muchos compromisos a la vez, midiendo los riesgos.",
    "Cuida de cumplir los plazos.",
    "Integra, rápidamente, teorías e información diversa, para solucionar problemas.",
    "Estimula el trabajo de equipo, en pro de la meta común.",
    "Afronta cambios y retos que le permiten demostrar sus capacidades.",
    "Le gusta ser parte de un equipo de expertos y afrontar retos especializados.",
    "Se concentra en lo importante para decidir.",
    "Calcula los riesgos de forma objetiva.",
    "Piensa creativamente y analiza, para potenciar la calidad.",
    "Hace aportes que favorecen los procesos y los protocolos.",
    "Revisa mucha información, a buen ritmo, para solucionar los problemas.",
    "Impulsa el cambio que favorece el éxito.",
    "Su ritmo marca la pauta y es su principal factor de influencia, en la rutina.",
    "Asume tareas para abordarlas meticulosamente.",
    "Procura que los resultados salgan bien al primer intento."
  ],
  60: [
    "Eficiente, asumiendo un proyecto de principio a fin.",
    "Enfoque claro y sustentado, orientado a las soluciones.",
    "Interés tanto en obtener resultados de calidad como en las personas.",
    "En situaciones de conflictos busca analizar los hechos para comprender.",
    "Negocia conflictos buscando que todos ganen.",
    "Actitud meticulosa en el mantenimiento de los estándares de calidad.",
    "Asume varios compromisos a la vez y se esfuerza en cumplir con los plazos.",
    "Se enfoca en lograr las metas con criterio de calidad.",
    "Estimula el trabajo de equipo en pro de los objetivos compartidos.",
    "Le animan los proyectos especializados y la compañía de gente experta.",
    "Pensamiento analítico y habilidad creativa.",
    "Ritmo rápido en la rutina.",
    "Motiva el cuidado de la calidad y la obtención de resultados.",
    "Prioriza la administración y conservación de los recursos.",
    "Se toma tiempo para analizar y evaluar en situaciones complejas.",
    "Impulsa cambios y novedades con energía, en ocasiones especiales.",
    "Respeta las normas y los estándares de calidad, con cierto nivel de flexibilidad.",
    "Crea, para el equipo, un ambiente de retos y de entusiasmo.",
    "Se adapta y se integra al equipo si eso favorece los resultados de calidad.",
    "Detecta lo bueno y lo malo en cualquier circunstancia.",
    "Es consciente tanto de los hechos como de las necesidades de la gente, para decidir.",
    "Firmeza y juicio frente a los riesgos.",
    "Se esfuerza en cumplir con los resultados cuidando de la calidad."
  ],
  61: [
    "Equilibra su atención entre la calidad, la gente y la obtención de resultados.",
    "Especialista en su área.",
    "Realiza tareas con altos estándares de calidad.",
    "Se conecta e influye en la gente.",
    "Actitud considerada y exigente al mismo tiempo, pero sin incomodar.",
    "Se adapta y se integra al equipo, manteniendo el foco en la calidad.",
    "Detecta lo bueno y lo malo en cualquier circunstancia.",
    "Negocia conflictos entre las personas buscando resultados ganar-ganar.",
    "Equilibra el manejo de las relaciones personales, con el cuidado de la calidad.",
    "Consciente de los plazos y de sus compromisos.",
    "Motiva el cuidado de la calidad y la obtención de resultados.",
    "Prioriza la administración y conservación de los recursos.",
    "Crea un clima retador, positivo, amigable y de alto desempeño.",
    "Hace seguimiento de las labores delegadas.",
    "Toma en cuenta a la gente y a los hechos, para decidir.",
    "Se motiva con los retos exigentes.",
    "Motiva a los demás a dar lo mejor cuando la situación es compleja.",
    "Toma la iniciativa al afrontar tareas difíciles.",
    "Asume riesgos con optimismo realista.",
    "Motiva a las personas a la acción y a seguir las normas y los procesos.",
    "Estimula a las personas a resolver los conflictos, en ambientes tensos.",
    "Aporta la energía suficiente para llevar los planes hasta el final con éxito.",
    "Actitud sociable, enérgica, analítica y reflexiva.",
    "Hace que los demás se proyecten alcanzando el éxito.",
    "Las personas valoran su criterio por su nivel de experticia y trato amigable.",
    "Moderado sentido de urgencia.",
    "Analiza las ventajas y desventajas de las decisiones",
    "Considera las posibilidades y amenazas de los procesos en curso.",
    "Aporta mejoras, activamente, en los protocolos y en los sistemas establecidos.",
    "Impulsa a los demás con diplomacia, energía y optimismo.",
    "Demuestra equilibrio entre la lógica y la razón con la emoción e intuición.",
    "Habitualmente maneja sus sentimientos positivamente y con motivación al logro.",
    "Actitud diplomática, directa y positiva cuando se acerca a las personas."
  ],
  62: [
    "Se adapta y se integra al equipo, manteniendo el foco en la calidad.",
    "Desarrolla habilidades prácticas y competencias, en lo que se propone.",
    "Prioriza la administración y conservación de los recursos.",
    "Le gusta rodearse de gente experta.",
    "Realiza tareas con altos estándares de calidad.",
    "Detecta lo bueno y lo malo en cualquier circunstancia.",
    "Dirige a los demás con tacto y diplomacia.",
    "Hace seguimiento y controla la calidad y los detalles.",
    "Se enfoca en los detalles y en los procesos, manteniendo el entusiasmo de la gente.",
    "Enfoque claro y sustentado, orientado a las soluciones.",
    "Se esfuerza para que los resultados sean buenos desde el primer intento.",
    "Referente para los demás por su profesionalismo, positivismo y trato jovial.",
    "Cuida de la calidad y de las necesidades de las personas.",
    "Pensamiento analítico, con el foco en producir ideas funcionales e innovadoras.",
    "Analiza información para decidir, tomando en cuenta, activamente, a las personas.",
    "Transmite optimismo creando un ambiente de apertura y logro.",
    "Impulsa los resultados con alta calidad, de forma activa.",
    "Promueve el uso adecuado de los procesos y protocolos.",
    "Negocia los conflictos de la gente, con justicia.",
    "Actitud dinámica y receptiva, en situaciones favorables.",
    "Ritmo moderado en la rutina, para actuar y decidir.",
    "Asume muchos compromisos y se esfuerza en cumplir los plazos.",
    "Toma riesgos calculados."
  ],
  63: [
    "Proyecta profesionalismo en la realización de sus tareas.",
    "Ritmo equilibrado, para lograr resultados y tomar decisiones.",
    "Las personas le buscan para que los asesore.",
    "Actitud modesta.",
    "Combina habilidades sociales con cuidado de la calidad.",
    "Fino sentido del humor, resaltando la jovialidad, diplomacia y modales.",
    "Relaja ambientes tensos con buen humor.",
    "Crea un clima amigable y evita las controversias.",
    "Asume con buena actitud el trabajo en equipo.",
    "Se enfoca en la calidad y en las personas, y es capaz de llevarse bien con la mayoría.",
    "Atiende situaciones difíciles con diplomacia.",
    "Actitud analítica y reflexiva.",
    "Le gusta rodearse con quienes comparte los mismos atributos e intereses.",
    "Aporta confianza al equipo con su optimismo y entusiasmo.",
    "Se esfuerza por cumplir con los plazos.",
    "Trata de responder a tiempo, con todos sus compromisos.",
    "Genera entusiasmo con diplomacia.",
    "Se motiva con los retos complejos.",
    "Motiva a la gente a integrarse en pro de la meta común.",
    "Impulsa el logro del éxito, junto al equipo.",
    "Actitud organizada y metódica incluso socialmente.",
    "Aprecia que reconozcan sus logros con sinceridad.",
    "Sabe mantener buenas relaciones y crear un buen ambiente.",
    "Pensamiento lógico, crítico y analítico con un buen nivel de positivismo.",
    "Da soporte y guía sobre estándares de calidad.",
    "Equilibra la tendencia a ser racional con el aspecto emocional."
  ],
  64: [
    "Logra resultados y toma decisiones a un ritmo equilibrado.",
    "Su ritmo es moderado, sin mucha urgencia ni mucha pausa.",
    "Combina habilidades sociales con cuidado de la calidad.",
    "Actitud modesta, sociable y activa.",
    "Habilidades sociales y diplomacia.",
    "Se centra en el cuidado de la calidad y de las personas.",
    "Se lleva bien con gente diversa.",
    "Entusiasma a la gente a cumplir con las normas.",
    "Le gusta rodearse con quienes comparte los mismos intereses y responsabilidades.",
    "Motiva el cumplimiento de los procesos y el logro de los estándares de calidad.",
    "Aporta optimismo y crea conciencia sobre los riesgos y los controles necesarios.",
    "Normalmente maneja buen sentido del humor.",
    "En situaciones complejas actúa con diplomacia.",
    "Crea un clima amigable y evita las controversias.",
    "Pensamiento lógico, crítico y analítico con un buen nivel de positivismo.",
    "Trabaja en equipo, colaborando y creando un buen clima.",
    "Toma en cuenta las necesidades de las personas, sin descuidar la calidad.",
    "Logra que los demás se enfoquen, con entusiasmo, en lo importante de una tarea.",
    "Muestra a los demás los aspectos claves para lograr el éxito.",
    "Proyecta profesionalismo. Las personas le buscan para que los asesore.",
    "Capaz para guiar y entrenar a otros.",
    "Equilibra la tendencia a ser racional con el aspecto emocional.",
    "Actitud detallista y meticulosa.",
    "Aprecia que reconozcan sus logros con sinceridad.",
    "Impulsa a los demás al logro del éxito con excelencia.",
    "Logra, con su seguimiento, que un plan se desarrolle exitosamente.",
    "Atiende situaciones difíciles con diplomacia."
  ],
  65: [
    "Combina habilidades sociales con cuidado de la calidad, en sus responsabilidades.",
    "Aprecia el equilibrio y la estabilidad, en un ambiente dinámico.",
    "Autoexigente con el respeto de los plazos.",
    "Se relaciona con diplomacia y actitud positiva.",
    "Aporta energía, para llevar el plan hasta el final.",
    "Ritmo equilibrado, para decidir y tomar acción.",
    "Realiza tareas cuidando de la calidad.",
    "Dirige con tacto, comprendiendo las necesidades de los demás.",
    "Mantiene un balance entre los procedimientos y las relaciones personales.",
    "Asume los cambios con mentalidad abierta, cuando cuenta con tiempo para adaptarse.",
    "Negocia los conflictos entre las personas, con una visión ganar-ganar.",
    "Actitud que puede ser tanto extrovertida y sociable como analítica y reflexiva.",
    "Asume tareas especializadas, siendo clave en la motivación del equipo.",
    "Influye con su encanto personal y su actitud profesional.",
    "Entusiasta y optimista de forma sincera.",
    "Le motiva que le reconozcan sus logros.",
    "Asume responsabilidades con energía y optimismo, cuidando de la calidad.",
    "Asume los riesgos con cuidado y cautela.",
    "Gestiona con tacto las situaciones difíciles.",
    "Confía en los demás y en sus propias habilidades, en situaciones favorables.",
    "Aprecia contar con datos e información para decidir, sin caer en la obsesión."
  ],
  66: [
    "Se enfoca tanto en la calidad como en las personas.",
    "Paciente.",
    "Actitud diplomática, disciplinada y modesta.",
    "Se lleva bien con una diversidad de gente.",
    "Profesional en lo que hace, muy consciente de la calidad.",
    "Se esfuerza por cumplir con sus tareas y responsabilidades rutinarias.",
    "Actitud modesta, amigable, gentil, amable y reflexiva.",
    "Le gusta prepararse y aprender, continuamente.",
    "Agente de armonía en ambientes tensos.",
    "Se prepara para asumir proyectos complejos.",
    "Aporta estabilidad y equilibrio al entorno.",
    "Consciente al asumir compromisos.",
    "Maneja el tiempo con consciencia.",
    "Actitud empática y positiva, al enseñar a otros.",
    "Transmite la importancia de seguir los procedimientos y respetar las normas.",
    "Dirige con tacto, comprendiendo las necesidades de las personas.",
    "Se esfuerza por progresar y hacer carrera.",
    "Se integra y estimula el sentido de pertenencia",
    "Coopera con el progreso de los demás.",
    "Analiza hechos e información antes de decidir.",
    "Toma en cuenta su intuición y a los demás como elementos claves para decidir.",
    "Aprecia que le aclaren sus responsabilidades con detalle.",
    "Motiva a los demás a que se alineen con sus altas expectativas de calidad.",
    "Se enfoca en obtener resultados de calidad."
  ],
  67: [
    "Cumple con los procesos que funcionan adecuadamente.",
    "Perfecciona aquellos protocolos que pueden mejorar.",
    "Se enfoca en la acción, en la calidad y en la tarea.",
    "Centra esfuerzos en lograr los objetivos, cuidando de mantener un clima amable.",
    "Explora nuevas formas de incrementar la calidad.",
    "Afronta los retos analizando y perseverando hasta encontrar una solución válida.",
    "Toma decisiones reflexionando sobre los hechos y dejando de lado las emociones.",
    "Propone novedosas maneras de afrontar los problemas.",
    "Reta a las personas a conseguir cada vez mejores resultados.",
    "Actitud meticulosa, objetiva y detallista con las soluciones.",
    "Trabaja duro para conseguir los mejores resultados, lo más rápido posible.",
    "Actitud diplomática, objetiva y poco demostrativa emocionalmente.",
    "Prefiere relacionarse con gente de confianza y cercana.",
    "Especial interés por la productividad, manteniendo altos estándares.",
    "Presta atención al orden, a la precisión y a la disciplina.",
    "Aprecia cuando le reconocen de forma sincera sus logros.",
    "Cumple las reglas y actualiza las normas para mejorar los resultados.",
    "Visión de futuro y de detalle, para innovar en las normas y procesos.",
    "Ante temas nuevos o complejos, se detiene para analizar y revisar opciones.",
    "Se prepara y aprende continuamente en su área de interés.",
    "Análisis rápido tomando decisiones muy enfocadas y eficientes, en la rutina.",
    "Aun siendo cuidadoso de las normas, en algunos casos puede ser flexible."
  ],
  68: [
    "Actitud práctica, metódica, incisiva y objetiva.",
    "Aplica protocolos, en la solución de problemas nuevos.",
    "Cuida de la calidad y de los resultados, manteniendo un clima amable.",
    "Actitud reflexiva y capaz de aceptar sus errores.",
    "Se concentra en la ejecución de las tareas y no le gusta que le interrumpan.",
    "Se prepara y aprende continuamente en su área de interés.",
    "Trabaja duro para conseguir resultados de calidad, rápidamente.",
    "Se esfuerza para que los resultados sean buenos desde la primera vez.",
    "Piensa que el tiempo es dinero y que hay que aprovecharlo adecuadamente.",
    "Dedica tiempo al cuidado de la calidad, sin descuidar los resultados.",
    "Trabaja en equipo, mientras eso no afecte la calidad.",
    "Afronta desafíos y retos detalladamente.",
    "Se integra, fácilmente, con personas que comprenden la importancia de la excelencia.",
    "Respeta los métodos, las normar y los procedimientos probados.",
    "Se flexibiliza, si el ambiente se lo exige.",
    "Actitud disciplinada, detallista y perfeccionista.",
    "Aprecia cuando le reconocen de forma sincera sus logros.",
    "Actitud metódica y lógica, en la rutina.",
    "Trata de mantener la diplomacia y los buenos modales, bajo presión.",
    "Actitud honesta y modesta, matizada con cierto nivel de seguridad personal.",
    "Prueba nuevas ideas cuando los métodos establecidos no funcionan.",
    "Calcula los riesgos al tomar decisiones.",
    "Enfrenta los retos con responsabilidad.",
    "Prefiere la intimidad y la privacidad.",
    "Intenta controlar sus impulsos, pensando antes de actuar.",
    "Asume acciones autónomas cuando eso mejora los resultados de calidad.",
    "Evalúa muy bien a las personas, antes de confiar en ellas.",
    "Actitud muy decidida en las situaciones rutinarias.",
    "Ante temas nuevos o complejos, se detiene para analizar y revisar opciones.",
    "Actitud lógica, racional, crítica, intuitiva y perceptiva.",
    "Actitud resolutiva frente a los problemas."
  ],
  69: [
    "Aplica métodos y protocolos en la solución de problemas nuevos.",
    "Enfoque en la acción y en la tarea, manteniendo un clima amable.",
    "Actitud realizadora y recursiva frente a los retos y desafíos.",
    "Da importancia a los procesos, la calidad y la obtención rápida de resultados.",
    "Asume nuevos retos, analizando la información y hechos disponibles.",
    "Prefiere relacionarse con un grupo pequeño de personas expertas.",
    "Afronta retos con creatividad, análisis y lógica.",
    "Calcula los riesgos, para resolver los problemas o diseñar protocolos.",
    "Buen ritmo de acción, manteniendo cuidado por la calidad.",
    "Cumple con las normas y los procedimientos, de forma ágil.",
    "Propone ingeniosas maneras de afrontar los problemas o los proyectos.",
    "Se prepara y aprende continuamente en su área de interés.",
    "Trabaja duro, para lograr los objetivos rápidamente.",
    "Actitud consciente, objetiva, dinámica y consistente.",
    "Actitud conciliadora en situaciones favorables.",
    "Resuelve los conflictos de forma imparcial y objetiva.",
    "Visión de futuro para plantear controles y protocolos.",
    "Visión global y de detalle, al enfrentar desafíos.",
    "Aprecia que reconozcan sinceramente su esfuerzo y logros.",
    "Análisis rápido, para tomar decisiones muy enfocadas y eficientes, en la rutina.",
    "Aun siendo cuidadoso de las normas, puede ser flexible en ocasiones.",
    "Toma decisiones manteniendo un enfoque analítico, creativo y preciso.",
    "Muy consciente del tiempo y de los plazos.",
    "En ocasiones normales puede ser paciente.",
    "Aprecia que le ofrezcan guías precisas sobre sus responsabilidades y expectativas.",
    "Una vez que entiende sus responsabilidades prefiere tener libertad de movimiento.",
    "Actitud discreta y reflexiva en circunstancias favorables.",
    "Se toma tiempo para pensar en nuevas formas de hacer las cosas.",
    "Diseña mejores procesos, para alcanzar mayores estándares de calidad.",
    "Se enfoca en lo realmente importante de un proyecto, plan o problema."
  ],
  70: [
    "Se enfoca en resolver problemas cuidando de los estándares.",
    "Se esfuerza en aplicar métodos que garanticen la calidad.",
    "Evalúa con objetividad a los demás, evitando el favoritismo.",
    "Se inclina por realizar tareas que impliquen un elevado análisis.",
    "Actitud honesta, reflexiva y modesta.",
    "Cuida de la calidad y del detalle, sin descuidar los resultados.",
    "Trabaja duro para lograr resultados de calidad.",
    "Investiga toda la información disponible, antes de tomar una decisión.",
    "Se esmera porque los resultados salgan bien al primer intento.",
    "Actitud práctica, incisiva y objetiva.",
    "Consciente del tiempo, cumple con los plazos.",
    "Acepta sus errores.",
    "Cuando se concentra en una tarea no le gusta que le interrumpan.",
    "Se integra al equipo si está conformado por personas que aprecian la excelencia.",
    "Respeta las normas, métodos y sistemas probados.",
    "Actitud disciplinada, precisa, exacta y perfeccionista.",
    "Aprecia el orden, la limpieza y la organización.",
    "Actitud diplomática y de buenos modales, por lo general.",
    "Busca soluciones prácticas y que se puedan replicar.",
    "No le gusta que interfieran en su planificación.",
    "Prueba nuevas ideas, cuando los sistemas establecidos no funcionan.",
    "Calcula los riesgos posibles antes de decidir.",
    "Enfrenta los desafíos con responsabilidad.",
    "Piensa antes de actuar.",
    "Prefiere encargarse de los proyectos de principio a fin.",
    "Le gusta ver los resultados cuando realiza un proyecto.",
    "Actitud lógica y racional y también intuitiva y perceptiva.",
    "Capacidad resolutiva frente a los problemas."
  ],
  71: [
    "Actitud directa, gentil y motivadora.",
    "Asume riesgos en lo social, manteniendo la moderación.",
    "Apertura al cambio, con actitud reflexiva.",
    "Inspira confianza y suele confiar en los demás.",
    "Aspira que los demás le lleven el ritmo en las tareas y en el día a día.",
    "Tiene iniciativa y persistencia, principalmente en lo social.",
    "Visión global en donde las personas están incluidas.",
    "Actitud optimista y reflexiva, para asumir riesgos.",
    "Equilibra su actitud frente a los retos con el cuidado de las necesidades de los demás.",
    "Es exigente, con un estilo amable, sociable y positivo.",
    "Asume el liderazgo, si es necesario.",
    "Impulsa el logro de los resultados a través de las personas.",
    "Insiste a los demás para alcanzar las metas, cuidando de no incomodar.",
    "Si es necesario se adapta a la rutina, aunque prefiere la novedad.",
    "Toma decisiones a buen ritmo, considerando a los demás.",
    "Asume algunos sistemas de controles que garanticen el logro de los resultados.",
    "Actitud retadora, optimista y servicial.",
    "Se plantea metas con actitud reflexiva, positiva y que representen un desafío.",
    "Aprecia la innovación, sin perder de vista los métodos tradicionales exitosos.",
    "Le gusta asesorar y ayudar a los demás, de forma activa y estructurada.",
    "Influencia a los demás para que se calmen, en momentos de tensión.",
    "En ciertas ocasiones sabe autocontrolarse.",
    "Aprecia las actividades que involucren construir y mantener redes de personas.",
    "Explora cambios, manteniendo las tradiciones que funcionan.",
    "Actitud cooperativa, para acelerar la solución de los problemas.",
    "Puede adaptar su actitud, según favorezca al ambiente y a los objetivos."
  ],
  72: [
    "Equilibrio entre la inclinación social, obtención de resultados y cuidado de los procesos.",
    "Tiende a mostrar un buen nivel de especialidad, a través de la obtención de resultados.",
    "Cumple con las normas de forma flexible.",
    "Logra que los demás visualicen resultados exitosos.",
    "Visión de futuro de forma realista.",
    "Visión global y de detalle en el abordaje de los retos.",
    "Los demás tienden a valorar su criterio.",
    "Se conecta con los demás de forma amigable, respetuosa y firme.",
    "Entusiasta para competir y ganar, manteniendo una postura sustentada.",
    "Estimula el trabajo de equipo asumiendo el liderazgo.",
    "Realiza seguimiento cercano cuando delega responsabilidades.",
    "Actitud competitiva y analítica para afrontar los retos.",
    "Toma la iniciativa para afrontar tareas y seguir los estándares de calidad.",
    "Estimula a las personas a resolver los conflictos, en los momentos de tensión.",
    "Asume riesgos calculados.",
    "Actitud propositiva.",
    "En condiciones normales se lleva bien con la gente.",
    "Maneja bien sus sentimientos y se orienta a los resultados, en situaciones favorables.",
    "Hace seguimiento del cumplimiento de los estándares de calidad, de forma activa.",
    "En ocasiones puede valerse de la creatividad para afrontar los desafíos.",
    "Su diplomacia y optimismo se combinan con su energía, para influenciar en el entorno.",
    "Disponible para la acción y el cambio, manteniendo el respeto por los procesos.",
    "Propone mejoras creativas para los procesos, que benefician los resultados de calidad.",
    "En ocasiones demuestra un buen equilibrio entre la razón y la lógica con la intuición.",
    "Actitud precisa y exacta de forma activa.",
    "Analiza los pros y contras, las posibilidades y amenazas, de los procesos en curso.",
    "Actitud organizada en lo social."
  ],
  73: [
    "Resuelve los problemas mejorando las metodologías probadas.",
    "Explora inusuales vías para lograr resultados.",
    "En lo operativo y en la rutina actúa con sentido de urgencia.",
    "Cumple las reglas con cierto nivel de flexibilidad.",
    "Propone innovaciones planificadas y con riesgos calculados.",
    "En situaciones complejas se detiene a pensar y diseñar, tomando en cuenta los detalles.",
    "Prueba teorías y modelos antes de decidir.",
    "Le gusta proyectar profesionalismo.",
    "Se prepara para hacerle frente a sus responsabilidades.",
    "Asume las tareas con consciencia y consistencia, de forma activa.",
    "Resuelve los conflictos de forma imparcial y objetiva.",
    "Actitud conciliadora, pero cuando es necesario es firme y contundente.",
    "Visión de futuro, global y de detalle, al momento de plantear soluciones y protocolos.",
    "Se anticipa a la solución de problemas al implementar estándares de calidad.",
    "Paciente en situaciones normales.",
    "Asume una variedad de tareas a las que les hace seguimiento de principio a fin.",
    "Consciente del tiempo y de los plazos.",
    "Aprecia que reconozcan sinceramente su esfuerzo.",
    "Persistente en el seguimiento de los protocolos establecidos, en pro de los resultados.",
    "Valora recibir pautas de cómo se hace una tarea y que luego le den libertad para actuar.",
    "Actitud discreta, realista, lógica, racional y objetiva.",
    "Actitud considerada, buscando mantener la armonía, en situaciones favorables.",
    "Se adapta al ritmo del entorno, si favorece los resultados y la calidad.",
    "Aprecia el orden, la organización y la limpieza.",
    "Asume el cambio con tiempo prudencial, para adaptarse.",
    "Se enfoca en lo importante."
  ],
  74: [
    "Actitud seria, cuando se requiere afrontar tareas y proyectos.",
    "Juicio equilibrado, aportando estabilidad al ambiente.",
    "Brinda apoyo en cualquier situación, siendo amigable y optimista.",
    "Relaja ambientes tensos, con buen humor y alegría, de ser necesario.",
    "Se prepara para afrontar sus responsabilidades.",
    "Unas veces es optimista y otras veces es de actitud crítica.",
    "Sabe integrarse al equipo.",
    "Actitud educada, diplomática y desenvuelta, cuando socializa.",
    "Organiza sus tareas de forma autónoma.",
    "Actitud modesta, amable y gentil.",
    "Paciente con las personas.",
    "Aporta equilibrio, lógica y armonía en los conflictos.",
    "Negocia los problemas entre las personas, con cautela y empatía.",
    "Capaz en su área de especialización.",
    "Revisa información y hechos, antes de tomar decisiones.",
    "Le gusta progresar en su carrera de forma estable.",
    "Se enfoca en las tareas rutinarias o que estén a la mano.",
    "Cuida de la calidad, sin perder de vista las necesidades de la gente.",
    "Equilibra la atención de los sistemas, la calidad y las relaciones."
  ]
};
