<template>
  <div class="container">
    <report-section-title>
      Sistema de Inteligencia Personal
    </report-section-title>
    <div
      v-for="(table, index) in tables"
      :key="index"
      :class="'section-' + index"
    >
      <h3>{{ table.title }}</h3>
      <ul class="items-list">
        <li v-for="(item, i) in table.items" :key="i">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  emotionFeedback,
  mindFeedback,
  behaviorFeedback,
  integralFeedback
} from "@/model/self-leadership/feedbacks.js";

export default {
  name: "LeadershipBehaviorTables",
  props: {
    applicantCount: {
      type: Object,
      required: true
    }
  },
  computed: {
    tables() {
      return [
        {
          title: "SISTEMA EMOCIONAL",
          items: this.getFeedbackItems(
            emotionFeedback,
            this.applicantCount.emotionsum
          )
        },
        {
          title: "SISTEMA MENTAL",
          items: this.getFeedbackItems(
            mindFeedback,
            this.applicantCount.mindsum
          )
        },
        {
          title: "SISTEMA CORPORAL",
          items: this.getFeedbackItems(
            behaviorFeedback,
            this.applicantCount.behaviorsum
          )
        },
        {
          title: "RESULTADOS INTEGRALES Y RECOMENDACIONES",
          items: this.getFeedbackItems(
            integralFeedback,
            this.applicantCount.emotionsum +
              this.applicantCount.mindsum +
              this.applicantCount.behaviorsum
          )
        }
      ];
    }
  },
  methods: {
    getFeedbackItems(feedbacks, score) {
      const feedback = feedbacks.find(
        feedback => score >= feedback.range.min && score <= feedback.range.max
      );
      return feedback ? feedback.items : [];
    }
  }
};
</script>

<style scoped>
.container {
  width: 70%;
  margin: auto;
}
h3 {
  margin: auto;
  padding: 10px;
  text-align: center;
}

.items-list {
  width: 95%;
  margin: auto;
  padding: 0;
  /* list-style-type: none; */
}

li {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--v-primary-base);
  text-align: justify;
  margin-bottom: 1em;
  margin-top: 1em;
}

.section-0 h3 {
  background-color: #cda349;
  color: #000;
}

.section-1 h3 {
  background-color: #37526d;
  color: #ffffff;
}

.section-2 h3 {
  background-color: #9edbdd;
  color: #000;
}

.section-3 h3 {
  background-color: #1c2b39;
  color: #fff;
}
</style>
