export default {
  1.1: [
    "Habla directo y firme.",
    "Opiniones rígidas.",
    "Utiliza la información de forma reservada y estratégica.",
    "Expresa ideas y opiniones sin restricciones.",
    "Prefiere hablar, más que escuchar.",
    "Da órdenes.",
    "Escucha de forma muy selectiva.",
    "Prefiere que vayan al grano sin rodeos.",
    "Actitud argumentativa, incluso de forma inconsciente."
  ],
  1.2: [
    "Tiende a hablar directo y firme.",
    "Suele tener opiniones rígidas.",
    "Con frecuencia usa la información de forma reservada y estratégica.",
    "Tiende a preferir hablar, más que escuchar.",
    "Suele dar órdenes.",
    "Tiende a expresar ideas y opiniones sin restricciones.",
    "Escucha de forma selectiva.",
    "Habitualmente le incomodan los rodeos, prefiere que vayan al grano.",
    "Suele ser de actitud argumentativa."
  ],
  2.1: [
    "Expresa sus ideas y opiniones con entusiasmo.",
    "Competente en la oratoria.",
    "Expresividad al momento de hablar.",
    "Habla mucho y da explicaciones minuciosas.",
    "Promueve ideas y conceptos de forma estimulante.",
    "Transmite la misión global de un proyecto o empresa.",
    "Motiva a las personas con su forma de hablar.",
    "Se comunica bien con cualquier tipo y tamaño de audiencia.",
    "Habla con conocidos y extraños.",
    "Habla de forma directa y abierta.",
    "Promueve ideas para el logro de los resultados."
  ],
  2.2: [
    "Tiende a expresa sus ideas y opiniones con entusiasmo.",
    "Suele ser competente en la oratoria.",
    "Suele ser convincente y hábil para persuadir.",
    "Puede ser expresivo al momento de hablar.",
    "Tiende a hablar mucho y dar explicaciones minuciosas.",
    "Habitualmente promueve ideas y conceptos de forma estimulante.",
    "Suele motivar a las personas con su forma de hablar.",
    "Tiende a dirigirse adecuadamente tanto a grupos grandes como a pocas personas.",
    "Habla con conocidos y extraños.",
    "Tiende a hablar forma directa y abierta."
  ],
  3.1: [
    "Escucha detenidamente.",
    "Habla poco, prefiere escuchar.",
    "Poca expresividad al hablar.",
    "Habla con sinceridad.",
    "Piensa antes de hablar.",
    "Actitud empática.",
    "Puede aferrarse persistentemente a sus opiniones."
  ],
  3.2: [
    "Suele escuchar detenidamente.",
    "Tiende a hablar poco, prefiere escuchar.",
    "Demuestra, la mayor parte del tiempo, poca expresividad al hablar.",
    "Con frecuencia habla con sinceridad.",
    "Suele pensar antes de hablar.",
    "Tiende a tener una actitud empática."
  ],
  4.1: [
    "Planteamientos claros.",
    "Presenta los hechos de forma directa y honrada.",
    "Conversa de forma racional y poco emocional.",
    "Habla pausado.",
    "Aprecia que hablen claro, concreto y en detalle sobre las expectativas.",
    "Pregunta e indaga para comprobar la comprensión.",
    "Dificultad para comenzar o seguir una conversación superficial.",
    "Prefiere hablar cuando considera que tiene algo de valor que aportar.",
    "Cuando le solicitan un consejo se involucra en la conversación más fácilmente.",
    "Comunica hechos de forma lógica, precisa y objetiva."
  ],
  4.2: [
    "Suele hablar claro y presentar los hechos de forma directa y honrada.",
    "Tiende a conversar de forma racional y poco emocional.",
    "Por lo general habla pausado.",
    "Aprecia que hablen claro, concreto y en detalle sobre las expectativas.",
    "Suele preguntar e indagar, para comprobar la comprensión.",
    "Dificultad para comenzar o seguir una conversación superficial.",
    "Prefiere hablar cuando considera que tiene algo de valor que aportar.",
    "Cuando le solicitan un consejo se involucra en la conversación más fácilmente.",
    "Suele comunicar hechos de forma lógica y objetiva."
  ],
  5: [
    "Prefiere hablar, más que escuchar.",
    "Atención selectiva al escuchar.",
    "Habla rápido.",
    "Opiniones firmes y actitud convincente.",
    "Transmite las ideas con determinación y entusiasmo.",
    "Motiva a la gente con su mensaje."
  ],
  6: [
    "Tiende a hablar, más que a escuchar.",
    "Actitud selectiva al escuchar.",
    "Habla rápido.",
    "La comunicación puede fluctuar fácilmente entre un estilo amable y amistoso, a un estilo acalorado y argumentativo.",
    "Opina con determinación y de forma convincente e influyente.",
    "Transmite las ideas con firmeza y entusiasmo.",
    "Motiva a la gente con su mensaje."
  ],
  7: [
    "Disfruta comunicándose con las personas.",
    "Capacidad de convencimiento.",
    "Transmite carisma al comunicarse.",
    "Deja en claro su posición, con firmeza.",
    "Se comunica abiertamente.",
    "Tiende a hablar más que a escuchar, cuando está bajo presión.",
    "Sabe promover nuevas ideas y productos.",
    "Habla rápido.",
    "Comunica su visión personal con entusiasmo."
  ],
  8: [
    "Comunica las opiniones de forma enérgica y firme con cierto nivel de gentileza.",
    "Opina después de haber reflexionado.",
    "Comunicación directa, juiciosa y considerada.",
    "Persistente con sus ideas.",
    "Propone nuevas ideas."
  ],
  9: [
    "El estilo de comunicación puede ser firme y también conciliador",
    "Hablar directo matizado con gentileza.",
    "Opina después de haber reflexionado.",
    "Tiende a comunicarse de una forma activa, juiciosa y considerada."
  ],
  10: [
    "Comunicación considerada, conciliadora y firme.",
    "Reflexiona antes de opinar.",
    "Comunicación franca.",
    "Actitud reservada al comunicarse con los extraños.",
    "Comunicación abierta y expresiva cuando está en confianza.",
    "Actitud ecuánime y al mismo tiempo directa al hablar.",
    "Sabe escuchar de forma activa.",
    "Defiende de forma agresiva sus ideas."
  ],
  11: [
    "Estilo asertivo en la comunicación.",
    "Habla firme y de forma agresiva cuando se torna impaciente.",
    "Actitud reservada con sus ideas creativas.",
    "Prefiere que hablen yendo al grano y se enfoquen en lo importante.",
    "No le gusta que divaguen en información general.",
    "Comunicación franca y crítica, en ocasiones de forma excesiva."
  ],
  12: [
    "Estilo asertivo en la comunicación.",
    "Escucha critica.",
    "Habla firme cuando se torna impaciente.",
    "Actitud reservada con sus ideas.",
    "Prefiere que hablen yendo al grano y se enfoquen en lo importante.",
    "No le gusta que divaguen en información que no venga al caso."
  ],
  13: [
    "De voz suave y ritmo equilibrado.",
    "Comunicación muy directa.",
    "Actitud reservada con las ideas.",
    "Escucha critica.",
    "Debate ideas para diseñar soluciones eficientes.",
    "Prefiere que hablen yendo al grano y se enfoquen en lo importante.",
    "No le gusta que divaguen en información que no venga al caso.",
    "Opina con fundamentos profesionales.",
    "Comunicación fría y objetiva."
  ],
  14: [
    "Transmite el mensaje de forma entusiasta y motivante.",
    "Muy detallista para dar instrucciones.",
    "Habla más de lo necesario.",
    "Le cuesta hablar directo e ir al grano.",
    "Lenguaje corporal expresivo al hablar.",
    "Le gusta hablar de las emociones y de los sentimientos.",
    "Conversa y debate ideas, de forma amigable y abierta.",
    "Sabe escuchar cuando se requiere."
  ],
  15: [
    "Comunicación sincera.",
    "Interés por comprender a su interlocutor.",
    "Sabe escuchar de forma empática y compasiva.",
    "Transmite confianza en la comunicación.",
    "Muy detallista para dar instrucciones.",
    "Le cuesta hablar directo e ir al grano.",
    "Transmite el mensaje de forma entusiasta y motivante.",
    "Propicia conversaciones sobre los sentimientos.",
    "Conversa y debate ideas, de forma amigable y gentil, con su grupo cercano."
  ],
  16: [
    "Comunicación sincera.",
    "Acepta los sentimientos, creencias y valores de los demás.",
    "Expresa ideas amablemente.",
    "Hace recomendaciones, sin imponer ni forzar.",
    "Capaz de calmar a otros, por su forma de escuchar empática y compasiva.",
    "Conversa de asuntos informales y personales.",
    "Interés por comprender a su interlocutor.",
    "Transmite confianza en la comunicación.",
    "Muy detallista para dar instrucciones.",
    "Le cuesta hablar directo e ir al grano.",
    "Conversa y debate ideas, de forma amigable y gentil, con su grupo cercano."
  ],
  17: [
    "Opiniones bien documentadas que comunica de forma influyente y asertiva.",
    "Aprecia que tomen en cuenta su opinión.",
    "Controla la conversación.",
    "En ocasiones puede ser una persona terca con sus ideas.",
    "Tiende a hablar más que escuchar.",
    "Ofrece mucha información al vender ideas o productos.",
    "Amigable al momento de decirle a los demás lo que piensa de ellos."
  ],
  18: [
    "Opiniones bien documentadas que comunica de forma influyente y asertiva.",
    "Aprecia que tomen en cuenta su opinión.",
    "En ocasiones puede ser una persona terca con sus ideas, que matiza con simpatía.",
    "Comunica las normas, los procedimientos y los planes de acción, positivamente.",
    "Comunica de forma articulada los pasos de un proyecto.",
    "Promueve ideas con entusiasmo, en su área de especialidad.",
    "Comunica la cultura y los planes, transmitiendo entusiasmo."
  ],
  19: [
    "Comunica de forma positiva las normas, los procedimientos, las políticas y la cultura.",
    "Comunica sus argumentos con lógica, análisis de datos y con buen nivel de entusiasmo.",
    "Aprecia que le expliquen todo detalladamente, antes de emprender cambios.",
    "Comunica, de forma articulada, los pasos de un proyecto.",
    "Comunica de forma influyente y asertiva, opiniones bien documentadas.",
    "Promueve ideas técnicas, especializadas o complejas, ejerciendo influencia.",
    "Impulsa, amigablemente, ideas y detalles técnicos.",
    "Le cuesta aceptar las ideas de los demás."
  ],
  20: [
    "Prefiere escuchar más que hablar.",
    "Habla enfocándose en las expectativas de las personas y en la información.",
    "Debate ideas con gentileza, lógica y tacto.",
    "Comunicación sincera y respetuosa.",
    "Escucha a las personas y analiza lo que dicen.",
    "Evita las posturas que puedan ser hostiles, para no enfrentar conflictos."
  ],
  21: [
    "Escucha y analiza la información que le aportan.",
    "Comunicación precisa, honesta y clara.",
    "Las personas suelen solicitar sus consejos y recomendaciones.",
    "Prefiere escuchar más que hablar.",
    "Cuando habla prefiere enfocarse en las personas, la información y el análisis de hechos.",
    "Debate ideas con diplomacia, gentileza, objetividad y tacto.",
    "Comunicación sincera y respetuosa."
  ],
  22: [
    "Escucha y analiza la información que le aportan.",
    "Habla pausado.",
    "Comunicación precisa, honesta y clara.",
    "Convence con el análisis, la exactitud y el control emocional.",
    "Escucha con atención.",
    "Debate ideas con diplomacia, gentileza, objetividad y pensamiento crítico."
  ],
  23: [
    "Argumenta y mantiene un buen clima en el debate.",
    "Actitud reservada con la información, en ocasiones.",
    "Tiende a escuchar a los demás de forma selectiva.",
    "Prefiere hablar más que escuchar.",
    "Tiende a hablar rápido.",
    "Comunicación firme o amable, dependiendo de la situación."
  ],
  24: [
    "Informa la visión global del proyecto generando interés.",
    "Argumenta y mantiene un buen clima en el debate.",
    "Maneja información sin compartirla, asumiendo las responsabilidades de otros.",
    "Comunicación firme o amable, dependiendo de la situación."
  ],
  25: [
    "Comunicación firme y de hablar directo, matizada con consideración.",
    "Habla con contundencia, ecuanimidad y optimismo.",
    "Actitud enérgica al comunicar sus opiniones muy bien reflexionadas.",
    "Firme en sus posiciones cuando hay tensión.",
    "Informa la visión global y los detalles del proyecto, generando interés.",
    "Comunicación contundente o amable, dependiendo de la situación.",
    "Debate ideas novedosas de forma respetuosa."
  ],
  26: [
    "Informa la visión global de los proyectos, metódicamente y generando interés.",
    "Actitud enérgica al comunicar sus opiniones muy bien reflexionadas.",
    "Maneja información sin compartirla, asumiendo las responsabilidades de otros.",
    "Comunicación firme, matizada con consideración.",
    "Habla de forma directa con ecuanimidad y optimismo.",
    "Firme en sus posiciones aun en situaciones tensas.",
    "Comunicación contundente o amable, dependiendo de la situación.",
    "Debate ideas novedosas de forma respetuosa."
  ],
  27: [
    "Transmite mensajes influyentes a nuevas audiencias.",
    "Comunicación persuasiva.",
    "Seguridad y confianza al comunicarse.",
    "Habla transmitiendo entusiasmo y encanto personal.",
    "Tiende a hablar, más que escuchar.",
    "Habilidad para comunicarse tanto con grandes como con pequeños grupos.",
    "Informa la visión global de los proyectos generando interés.",
    "Debate ideas novedosas de forma entusiasta."
  ],
  28: [
    "Tiende a hablar, más que escuchar.",
    "Actitud segura y confiada de sus habilidades comunicacionales.",
    "Persuade e influye cuando habla, independientemente del tamaño de la audiencia.",
    "Maneja la información de forma reservada y estratégica."
  ],
  29: [
    "Maneja la información de forma reservada y estratégica.",
    "Comparte ideas técnicas para la solución de problemas con una actitud dinámica.",
    "Prefiere que le hablen de soluciones, procesos y sistemas, de forma rápida y al grano.",
    "Persuade con ideas creativas, prácticas y de calidad, para el logro de los resultados."
  ],
  30: [
    "Maneja la información de forma individualista y reservada.",
    "Aporta ideas creativas y bien pensadas, con entusiasmo y profesionalismo.",
    "Comparte ideas para la solución de problemas de forma estratégica.",
    "Prefiere hablar más que escuchar.",
    "Comunicación asertiva.",
    "Debate ideas, profundamente, hasta encontrar la excelencia en los resultados."
  ],
  31: [
    "Expresa ideas sin involucrarse emocionalmente.",
    "Estilo racional e indirecto para comunicarse.",
    "Puede mejorar la oratoria.",
    "Capacidad para explicar los conceptos técnicos.",
    "Actitud enérgica, al comunicar sus opiniones, si es necesario.",
    "Comunica su opinión una vez que ha analizado y reflexionado, rápidamente, los hechos.",
    "Debate ideas novedosas de forma respetuosa y con cierto grado de formalidad.",
    "Actitud cerrada al comunicar sus ideas, sentimientos y conocimientos."
  ],
  32: [
    "Se comunica adecuadamente sin involucrar las emociones.",
    "Habla directo y firme, y a la vez de estilo diplomático y considerado.",
    "Puede mejorar en la oratoria.",
    "Comunica su opinión una vez que ha analizado, rápidamente, los hechos y reflexionado.",
    "Actitud enérgica al comunicar sus opiniones, si es necesario.",
    "Debate ideas novedosas de forma respetuosa y formal."
  ],
  33: [
    "Se comunica adecuadamente sin involucrar las emociones.",
    "Equilibrio en su estilo de comunicación.",
    "Habla firme y directo matizado con consideración y ecuanimidad.",
    "Usa la lógica para plantear sus ideas.",
    "Puede mejorar en la oratoria.",
    "Comunica su opinión con energía y firmeza, una vez que ha analizado y reflexionado.",
    "Debate ideas novedosas de forma respetuosa y formal.",
    "Prefiere hablar con pequeños grupos de personas conocidas."
  ],
  34: [
    "Estilo de comunicación firme y directo matizado con diplomacia y tacto.",
    "Opina con argumentos sólidos basados en análisis de información y hechos.",
    "Se comunica de forma lógica y racional, sin involucrar las emociones.",
    "Comunica su opinión con energía y firmeza, una vez que ha analizado y reflexionado.",
    "Debate ideas novedosas con diplomacia.",
    "Puede mejorar en la oratoria.",
    "Prefiere hablar con grupos de personas conocidas y expertas.",
    "Actitud reservada con la información.",
    "Escucha critica."
  ],
  35: [
    "Se comunica de una forma positiva, asertiva y directa.",
    "Persuade y convence con sus ideas.",
    "Buena capacidad de oratoria.",
    "Le gusta comunicar sus opiniones y que le escuchen.",
    "Comunica con entusiasmo.",
    "Transmite mensajes optimistas con actitud firme y considerada."
  ],
  36: [
    "Escucha activamente a todas las partes para comprender.",
    "Facilidad de oratoria.",
    "Da sugerencias y puntos de vista, sin imponerlos.",
    "Se mantiene comunicado con los demás.",
    "Argumenta ante las críticas.",
    "Expresa ideas de forma respetuosa, creativa y convincente.",
    "De ser necesario puede comunicarse con firmeza.",
    "Promueve e influye con sus mensajes."
  ],
  37: [
    "Facilidad de oratoria.",
    "Persistente con sus ideas.",
    "Tiende a hablar, más que a escuchar.",
    "Escucha activamente, cuando esto favorece el logro de los resultados.",
    "Se comunica de una forma positiva, asertiva y directa.",
    "Persuade y convence con sus ideas.",
    "Le gusta comunicar sus opiniones y que le escuchen.",
    "Comunica con entusiasmo."
  ],
  38: [
    "Facilidad de oratoria.",
    "Comunicación fluida y elocuente.",
    "Resalta los puntos en común, en lugar de las diferencias.",
    "Influye a través de la persuasión, amabilidad y convicción.",
    "Comunicación expresiva.",
    "Sabe escuchar activamente y puede tener una actitud empática.",
    "Impulsa ideas, servicios y productos."
  ],
  39: [
    "Hábil delegando y comunicándose con grandes audiencias.",
    "Se comunica transmitiendo calidad y energía.",
    "Tendencia a hablar, más que a escuchar",
    "Influye con sus ideas.",
    "Capacidad de oratoria.",
    "Presenta las ideas de forma directa y positiva."
  ],
  40: [
    "Hábil delegando y comunicándose con grandes audiencias.",
    "Expresa sus sentimientos positivamente.",
    "Transmite mensajes de logro y de integración.",
    "Busca crear un buen clima con la comunicación.",
    "Comunica adecuadamente los planes y los proyectos.",
    "Transmite calidad, profesionalismo y energía cuando se comunica."
  ],
  41: [
    "Comunica con encanto, firmeza y profesionalismo.",
    "Convincente con sus ideas.",
    "Prefiere hablar, más que escuchar.",
    "Intransigente con sus ideas, cuando está bajo presión.",
    "Hábil delegando, motivando y comunicándose con grandes audiencias."
  ],
  42: [
    "Comunica con encanto y profesionalismo.",
    "Transmite con ánimo la cultura, los procesos y la importancia de la calidad.",
    "Promueve ideas con claridad y dinamismo.",
    "Comunica con entusiasmo los pasos a seguir en un proyecto complejo.",
    "Detallista al impulsar ideas y productos.",
    "Controla la conversación."
  ],
  43: [
    "Comunica con un estilo modesto, enfático y asertivo, cuando es necesario.",
    "Tiende a hablar mucho, en situaciones de estrés.",
    "Comunica de forma persuasiva, positiva y amigable.",
    "Transmite con ánimo la cultura, los procesos y la importancia de la calidad.",
    "Detallista al impulsar ideas y productos.",
    "Promueve e implementa ideas para el logro de resultados en equipo.",
    "Hace comprender a otros las complejidades de un proyecto."
  ],
  44: [
    "Comunica con un estilo modesto, enfático y asertivo, cuando es necesario.",
    "Se comunica de forma amigable y persuasiva, con foco en la calidad.",
    "Comunica asertivamente su inconformidad, ante los incumplimientos.",
    "Logra la cooperación con mensajes de integración.",
    "Transmite con ánimo la cultura y los procesos.",
    "Detallista al impulsar ideas y productos.",
    "Promueve e implementa ideas para el logro de resultados en equipo.",
    "Hace comprender a otros las complejidades de un proyecto."
  ],
  45: [
    "Transmite confianza en su comunicación.",
    "Habla mucho cuando está bajo presión.",
    "Se comunica de forma amigable y gentil, con foco en la calidad.",
    "Comunica con un estilo persuasivo, modesto y asertivo.",
    "Da muchos detalles y vueltas al momento de impartir instrucciones.",
    "Dificultad para hablar directo e ir al grano.",
    "Comunica asertiva y enfáticamente, cuando es necesario.",
    "Logra la cooperación con mensajes de integración.",
    "Transmite los mensajes de forma positiva.",
    "Hace comprender a otros las complejidades de un proyecto."
  ],
  46: [
    "Se comunica con amabilidad y respeto.",
    "Se comunica de forma amigable y entusiasta, con foco en la calidad.",
    "Hace comprender a otros las complejidades de un proyecto.",
    "Comunica la cultura, las normas y los procesos con entusiasmo.",
    "Transmite confianza en su comunicación.",
    "Comunica con un estilo persuasivo, serio y profesional."
  ],
  47: [
    "Escucha y se esfuerza por comprender todos los puntos de vista.",
    "Se comunica amablemente.",
    "Demuestra empatía sin perder su independencia.",
    "Comunica entusiasmo cuando siente motivación.",
    "Calma a las personas que están fuera de control emocional.",
    "Concilia posiciones de forma activa.",
    "Persuade con la comunicación.",
    "Alinea, con una comunicación positiva e integradora, a los oposicionistas.",
    "Debate ideas novedosas de forma respetuosa."
  ],
  48: [
    "Escucha y se esfuerza por comprender todos los puntos de vista.",
    "Se comunica sincera y amablemente.",
    "Demuestra empatía sin perder su independencia.",
    "Comunica entusiasmo cuando siente motivación.",
    "Calma a las personas que están fuera de control emocional.",
    "Concilia posiciones de forma activa.",
    "Persuade con la comunicación.",
    "Alinea, con una comunicación positiva e integradora, a los oposicionistas.",
    "Debate ideas novedosas de forma respetuosa."
  ],
  49: [
    "Comunica con calidez humana y sinceridad.",
    "Escucha de forma empática y compasiva.",
    "Da instrucciones muy detalladas.",
    "Habla dando muchas vueltas, sin ir al grano.",
    "Escucha y se esfuerza por comprender todos los puntos de vista.",
    "Comunica entusiasmo cuando siente motivación.",
    "Persuade con la comunicación.",
    "Alinea, con una comunicación positiva e integradora.",
    "Demuestra empatía sin perder su independencia.",
    "Concilia posiciones de forma activa."
  ],
  50: [
    "Comunica con calidez humana y sinceridad.",
    "Escucha de forma empática y compasiva.",
    "Actitud de apertura en la comunicación.",
    "Capacidad para comunicarse con cualquier persona.",
    "Firme al dar instrucciones.",
    "Promueve ideas creativas.",
    "Transmite ideas en forma de sugerencias.",
    "Escucha y se esfuerza por comprender todos los puntos de vista.",
    "Comunica entusiasmo, cuando siente motivación."
  ],
  51: [
    "Comunica con calidez humana y sinceridad.",
    "Escucha de forma empática y compasiva.",
    "Escucha para comprender a los demás.",
    "Dificultad para hablar directo e ir al grano.",
    "Da instrucciones muy detalladas.",
    "En grupos grandes o con gente extraña, prefiere escuchar más que hablar."
  ],
  52: [
    "Habla cuando hay algo necesario que decir.",
    "Firme y amable en la comunicación.",
    "Demuestra profesionalismo y optimismo realista cuando se comunica.",
    "Comunica con calidez humana y sinceridad.",
    "Escucha de forma empática.",
    "En grupos grandes o con gente extraña, prefiere escuchar más que hablar."
  ],
  53: [
    "Habla cuando hay algo necesario que decir.",
    "Demuestra profesionalismo y optimismo realista cuando se comunica.",
    "Reclama cuando está inconforme con la calidad.",
    "Prefiere concentrarse en la ejecución de la tarea que hablar.",
    "Debate, con argumentos sólidos, sus puntos de vista.",
    "Paciente en la comunicación.",
    "Firme y amable cuando habla.",
    "En grupos grandes o con gente extraña, prefiere escuchar más que hablar."
  ],
  54: [
    "Habla cuando hay algo necesario que decir.",
    "Prefiere concentrarse en la ejecución de la tarea que hablar.",
    "Reclama cuando está inconforme con la calidad.",
    "Demuestra profesionalismo y optimismo realista cuando se comunica.",
    "Debate, con argumentos sólidos, sus puntos de vista.",
    "Paciente en la comunicación.",
    "Firme y amable cuando habla.",
    "En grupos grandes o con gente extraña, prefiere escuchar más que hablar."
  ],
  55: [
    "Da su punto de vista una vez que ha analizado la información y los hechos disponibles.",
    "Comunica lo que piensa, de una forma considerada y franca.",
    "Poca receptividad con las ideas de los demás.",
    "Comunica con buen juicio.",
    "Comunica ideas nuevas sobre los sistemas establecidos, luego de haberlas analizado.",
    "Comunica con poco entusiasmo.",
    "Actitud empática y abierta a escuchar, en ocasiones."
  ],
  56: [
    "Tacto y diplomacia, con cierto nivel de firmeza, en la comunicación.",
    "Escucha con empatía, consideración y análisis, de forma activa.",
    "Comparte sus ideas con los demás.",
    "Da su punto de vista una vez que ha analizado la información y los hechos disponibles.",
    "Comunica lo que piensa, de una forma considerada y franca.",
    "Poca receptividad con las ideas de los demás.",
    "Poco entusiasmo cuando transmite un mensaje.",
    "Comunica con buen juicio."
  ],
  57: [
    "Tacto y diplomacia, con cierto nivel de firmeza, en la comunicación.",
    "Escucha con empatía, consideración y análisis, de forma activa.",
    "Da su punto de vista una vez que ha analizado la información y los hechos disponibles.",
    "Comunica lo que piensa, de una forma considerada y franca.",
    "Poca receptividad con las ideas de los demás.",
    "Comunica con buen juicio.",
    "Poco entusiasmo y expresividad al hablar.",
    "Actitud reservada con sus ideas."
  ],
  58: [
    "Escucha con empatía, consideración y análisis, de forma activa.",
    "Tacto y diplomacia para comunicarse.",
    "Poca expresividad y entusiasmo al comunicarse.",
    "Comparte sus ideas con los demás.",
    "Da su punto de vista una vez que ha analizado la información y los hechos disponibles.",
    "Comunica lo que piensa, de una forma considerada y sincera."
  ],
  59: [
    "Comunicación firme y directa.",
    "Actitud reservada con sus ideas.",
    "Aporta ideas novedosas de forma meticulosa y sustentadas.",
    "Debate ideas hasta encontrar la mejor solución a los problemas.",
    "Sabe comunicar los pasos de un plan o de un proyecto.",
    "Solicita activamente que los demás sean claros con sus expectativas.",
    "Escucha con atención cuando le brindan información de calidad."
  ],
  60: [
    "Se comunica con las personas de forma positiva.",
    "Ofrece críticas constructivas.",
    "Aporta ideas novedosas.",
    "Cuida de no herir a nadie en circunstancias favorables.",
    "Sabe comunicar los pasos del proyecto y los planes a seguir.",
    "Comunicación asertiva frente a los compromisos.",
    "Comunica las normas y procedimientos de forma positiva.",
    "Se comunica con diplomacia, energía y optimismo.",
    "Aporta ideas novedosas de forma meticulosa y sustentadas.",
    "Actitud reservada con las ideas claves.",
    "Actitud diplomática manejando el buen humor."
  ],
  61: [
    "Actitud encantadora a la hora de comunicar el plan detalladamente.",
    "Comunica las normas y procedimientos generando motivación.",
    "Se comunica con diplomacia, energía y optimismo.",
    "Aporta ideas novedosas de forma dinámica y sustentadas.",
    "Debate ideas hasta encontrar la mejor solución a los problemas.",
    "Comunicación persuasiva e influyente basada en criterio experto.",
    "Actitud diplomática manejando el buen humor."
  ],
  62: [
    "Hace críticas constructivas, buscando no herir a las personas.",
    "Actitud diplomática manejando el buen humor.",
    "Relaja ambientes tensos, con su sentido del humor.",
    "Comunicación asertiva y amable.",
    "Promueve mucho sus propias ideas y soluciones técnicas.",
    "Da instrucciones y recomendaciones con tacto.",
    "Comunicación positiva con la mayoría de las personas.",
    "Amigable al transmitir información técnica, cultural y detalles de un plan.",
    "Se comunica tomando en cuenta la autoestima de las personas, incluso cuando corrige.",
    "Se esfuerza por aclarar a los demás las metas y los objetivos."
  ],
  63: [
    "Se comunica tomando en cuenta la autoestima de las personas, incluso cuando corrige.",
    "Da retroalimentación de forma cuidadosa.",
    "Comunica de forma persuasiva.",
    "Comunica el valor que le da a las personas.",
    "Comunica con encanto y profesionalismo.",
    "Transmite el rol clave de la calidad y de los protocolos, en el logro del éxito.",
    "Habla con pequeños y grandes grupos.",
    "Se comunica tanto con gente conocida como con extraños.",
    "Explica de forma positiva, las complejidades de un proyecto.",
    "Estilo de comunicación lógico, reflexivo y expresivo.",
    "Actitud cerrada a las ideas diferentes."
  ],
  64: [
    "Se comunica con tacto, comprendiendo las necesidades de los demás.",
    "Comunicación racional sin perder de vista las implicaciones emocionales.",
    "Se comunica tomando en cuenta la autoestima de las personas, incluso cuando corrige.",
    "Da retroalimentación de forma cuidadosa.",
    "Comunica de forma persuasiva.",
    "Persuade con optimismo, diplomacia e inclinación a la calidad.",
    "Comunica el valor que le da a las personas.",
    "Comunica con encanto y profesionalismo.",
    "Transmite el rol clave de la calidad y de los protocolos, en el logro del éxito.",
    "Explica de forma positiva, las complejidades de un proyecto.",
    "Estilo de comunicación lógico, reflexivo y expresivo.",
    "Actitud cerrada a las ideas diferentes."
  ],
  65: [
    "Comunica las normas, los procedimientos y estándares de calidad, con entusiasmo.",
    "Transmite con optimismo los valores, las tradiciones y la cultura de su entorno.",
    "Comunicación articulada y positiva, al transmitir los pasos de un plan.",
    "Sabe promover ideas y proyectos.",
    "Se comunica con tacto y optimismo, comprendiendo las necesidades de los demás.",
    "Se comunica con un estilo equilibrado entre lo racional y emocional.",
    "Persuade con optimismo, diplomacia e inclinación a la calidad.",
    "Comunica con encanto y profesionalismo.",
    "Explica de forma positiva, las complejidades de un proyecto."
  ],
  66: [
    "Comunica los procedimientos y estándares de calidad, con un toque de entusiasmo.",
    "Transmite conocimiento de forma empática.",
    "Comunicación articulada al transmitir los pasos de un plan.",
    "Se comunica con tacto y diplomacia, comprendiendo las necesidades de los demás.",
    "Comunicación racional sin perder de vista las implicaciones emocionales.",
    "Se comunica tomando en cuenta la autoestima de las personas, incluso cuando corrige.",
    "Explica con detalles las complejidades de un proyecto.",
    "Estilo de comunicación lógico y reflexivo con un cierto nivel de positivismo.",
    "Escucha con respeto, pero con poca apertura a las nuevas ideas."
  ],
  67: [
    "Debate ideas, para crear respuestas de alta calidad.",
    "Conversa con lógica y firmeza sobre las soluciones de los problemas.",
    "Hace énfasis en los datos y en los hechos cuando se comunica.",
    "No toma en cuenta el aspecto emocional en una conversación.",
    "Actitud reservada con sus ideas.",
    "Propone ideas creativas sobre los sistemas y protocolos."
  ],
  68: [
    "Conversa con lógica y firmeza sobre las soluciones de los problemas.",
    "Actitud reservada con sus ideas.",
    "Oculta sus emociones cuando se comunica.",
    "Hace las preguntas adecuadas para recabar información.",
    "Hace énfasis en los datos y en los hechos cuando se comunica.",
    "Propone ideas prácticas sobre los sistemas y protocolos."
  ],
  69: [
    "Debate sobre análisis, hechos, soluciones e información.",
    "Los debates sobre las personas o sus necesidades le son de menor interés.",
    "No toma en cuenta el aspecto emocional en una conversación.",
    "Conversa con lógica, objetividad y firmeza sobre las soluciones de los problemas.",
    "Comunicación objetiva, directa y honrada, cuando presenta los hechos.",
    "Actitud reservada con sus ideas.",
    "Debate ideas, para crear respuestas de alta calidad.",
    "Propone ideas creativas sobre los sistemas y protocolos."
  ],
  70: [
    "Hace las preguntas adecuadas cuando investiga los hechos.",
    "Aprecia a las personas que hacen uso correcto de la información.",
    "Comunica con objetividad y buen juicio.",
    "Actitud reservada con sus ideas.",
    "Debate sobre análisis, hechos, datos y números.",
    "Actitud reservada con lo que piensa.",
    "Comparte poco la información.",
    "Actitud terca, con sus ideas y puntos de vistas, en ocasiones.",
    "Antes de hablar le gusta sentir seguridad de lo que va a decir.",
    "Tacto y diplomacia, con cierto nivel de firmeza, en la comunicación.",
    "Da su punto de vista una vez que ha analizado la información y los hechos disponibles.",
    "Poca receptividad con las ideas de los demás."
  ],
  71: [
    "Comunicación directa e ideas firmes, transmitidas de forma amable.",
    "Comunica su visión con energía, entusiasmo y gentileza.",
    "Integra las ideas de los demás.",
    "Impulsa ideas creativas para modificar los sistemas, con el fin de acelerar los resultados.",
    "Motiva a reflexionar a los demás.",
    "Promueve la comunicación abierta y sincera.",
    "Le motiva debatir con lógica, ideas creativas."
  ],
  72: [
    "Comunica con entusiasmo los detalles de un plan y los protocolos.",
    "Si lo requiere puede comunicarse tanto con personas conocidas como con extraños.",
    "Actitud reservada con la información y con sus ideas, en ocasiones.",
    "Buena capacidad de oratoria.",
    "Ideas firmes y sustentadas que transmite con entusiasmo y diplomacia.",
    "Expresividad al hablar que modera de forma consciente.",
    "Motiva con su mensaje, a mantener el alto desempeño."
  ],
  73: [
    "Expresa ideas sin involucrarse emocionalmente.",
    "Estilo racional e indirecto para comunicarse.",
    "Debate sobre análisis, hechos, soluciones e información.",
    "Actitud directa y honrada, para comunicar los hechos.",
    "Actitud reservada con sus ideas.",
    "Capacidad para explicar los conceptos técnicos.",
    "Actitud enérgica al comunicar sus opiniones, cuando es necesario.",
    "Comunica su opinión una vez que ha analizado y reflexionado, rápidamente, los hechos.",
    "Debate ideas novedosas de forma respetuosa y con cierto grado de formalidad."
  ],
  74: [
    "Comunicación clara y asertiva, si fuera necesario.",
    "Puede ser entusiasta, promoviendo sus propias ideas.",
    "Transmite experticia en su comunicación.",
    "Persuade a los demás con amabilidad, empatía y un toque de alegría.",
    "Cuando es necesario se comunica con seriedad y profesionalismo.",
    "Se comunica con fluidez."
  ]
};
