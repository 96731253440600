<template>
  <o-page-layout mode="large">
    <report-section-title>
      Diagnóstico Convergencia Persona / Objetivos
    </report-section-title>

    <convergence-level :data="data" />

    <xs-rotation-message />

    <h2>Madurez Psicorrelacional</h2>

    <div class="d-none d-sm-block d-print-block mt-4 mb-8">
      <v-row class="mb-2">
        <v-col cols="6" class="low-cell py-1">Bajo</v-col>
        <v-col cols="6" class="high-cell py-1">Alto</v-col>
      </v-row>
      <v-row>
        <v-col
          cols="0.75"
          class="table-cell"
          v-for="level in data"
          :key="level.key"
          :style="{
            backgroundColor: level.primaryColor,
            color: level.primaryFontColor
          }"
        >
          {{ level.label }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="0.75"
          class="table-cell"
          v-for="level in data"
          :key="level.key"
        >
          {{ level.value }}%
        </v-col>
      </v-row>
    </div>

    <report-section-subtitle
      class="page-break-before"
      text="Patrón de Comportamiento"
    />

    <ul class="justify-text">
      <li v-for="answer in answers" :key="answer">{{ answer }}</li>
    </ul>
  </o-page-layout>
</template>

<script lang="js">
import { defineComponent } from "vue";
import ConvergenceLevel from "./ConvergenceLevel.vue";

export default defineComponent({
  name: "GlobalPattern",
  components: {
    "convergence-level": ConvergenceLevel
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    answers: {
      type: Array,
      required: true
    }
  }
});
</script>

<style scoped>
.table-header {
  border: 1px solid #000;
  text-transform: uppercase;
  font-weight: 700;
}
.table-cell {
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.75em;
}
.justify-text {
  text-align: justify;
}
.low-cell {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  text-align: left;
  background-image: linear-gradient(to right, #b60000, #417600);
  color: #ffffff;
  font-weight: 700;
}
.high-cell {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  text-align: right;
  background-color: #417600;
  color: #ffffff;
  font-weight: 700;
}
@media print, screen and (max-width: 960px) {
  .table-cell {
    font-size: x-small;
    padding: 4px;
  }
}
</style>
