export default {
  1.1: [
    "Es una persona muy firme y determinada.",
    "Actúa con un gran sentido de urgencia y quiere que los demás también lo hagan.",
    "Es responsable en el cumplimiento de los plazos, pero no, necesariamente, en el cumplimiento de las normas.",
    "Da una gran importancia al tiempo.",
    "Es una persona espontánea, extrovertida, independiente y que propicia los cambios.",
    "Le gusta experimentar con nuevas, mejores y más rápidas formas de hacer las cosas.",
    "Puede ser autodidacta en muchas áreas.",
    "Prefiere, ante todo, beneficiar la obtención de resultados más que la armonía en las relaciones o el cumplimiento de las normas.",
    "Es una persona creativa e innovadora.",
    "Es una persona buena resolviendo problemas y asumiendo retos.",
    "Si enfrenta problemas complicados, le gusta encontrar una solución satisfactoria.",
    "Es una persona lógica e incisiva frente a los problemas, incluso de carácter obstinado y terco.",
    "Es una persona de actitud impulsiva a la hora de comenzar algo.",
    "Debe entender que a veces es bueno analizar una idea en todas sus fases, antes de ponerla en práctica.",
    "Se aburre fácilmente con la rutina.",
    "Toma riesgos sin pensarlos. Los retos le atraen y los enfrenta de forma directa.",
    "No se apega a las normas ni a las reglas, le gusta hacer las cosas a su manera.",
    "Es difícil ejercer influencia sobre usted, de hecho, prefiere tener autoridad total para asumir sus responsabilidades.",
    "Es una persona que confía más en sí misma que en los demás.",
    "Es autosuficiente y con gran capacidad resolutiva. Se autogestiona sin problemas.",
    "Es impaciente y cuando está bajo estrés, es de carácter agresivo y presiona a las personas más de lo normal.",
    "Debe aprender a relajarse y autocontrolarse.",
    "Es capaz de llevar adelante varias tareas al mismo tiempo.",
    "Prefiere no tener que analizar mucha información antes de tomar decisiones.",
    "Es de espíritu emprendedor.",
    "Puede ser egoísta.",
    "Es una persona exigente con ella misma y con los demás.",
    "Quiere triunfar en la vida, ya que es una persona muy ambiciosa.",
    "Percibe aspectos en las situaciones que a otros se les pasa por alto.",
    "Tiene capacidad de ver globalmente y de integrar las partes en un todo."
  ],
  1.2: [
    "Es una persona que tiende a ser firme y determinada.",
    "Suele actuar con sentido de urgencia y quiere que los demás también lo hagan.",
    "Tiende a ser responsable en los compromisos y en los plazos, pero no, necesariamente, en el cumplimiento de las normas.",
    "Suele dar importancia al tiempo.",
    "Es una persona que suele ser espontánea, extrovertida, independiente y que tiende a propiciar los cambios.",
    "Le puede gustar experimentar con nuevas, mejores y más rápidas formas de hacer las cosas.",
    "Suele ser una persona que tiene una gran confianza en sí misma.",
    "Puede ser autodidacta en muchas áreas.",
    "Suele preferir beneficiar los resultados más que la armonía en las relaciones o el cumplimiento de las reglas.",
    "Tiende a gustarle participar en actividades creativas.",
    "Tiene habilidades para la solución de problemas y una actitud positiva frente a los retos.",
    "Si los problemas son complicados, continúa hasta conseguirle una solución satisfactoria.",
    "Puede ser una persona lógica e incisiva frente a los problemas, incluso puede llegar a ser una persona obstinada y terca.",
    "Es de actitud con tendencia a lo impulsivo a la hora de comenzar algo.",
    "Debe entender que a veces es bueno analizar una situación en todas sus fases, antes de involucrarse.",
    "Se puede aburrir con la rutina.",
    "Puede tender a ser una persona arriesgada. Los retos le atraen y suele enfrentarlos de forma directa.",
    "No suele apegarse a las normas ni a las reglas, le gusta hacer las cosas a su manera.",
    "La mayor parte de las veces es difícil ejercer influencia sobre usted.",
    "Prefiere, de hecho, tener control total para asumir sus responsabilidades.",
    "Es una persona que suele confiar más en sí misma que en los demás.",
    "Es alguien con tendencia a ser autosuficiente y que suele tener habilidades resolutivas. Se autogestiona sin problemas.",
    "Tiende a ser impaciente y cuando está bajo estrés, puede ser de actitud agresiva y presionar a las personas más de lo normal.",
    "Debe aprender a relajarse y autocontrolarse.",
    "Tiende a ser capaz de llevar adelante varias tareas al mismo tiempo.",
    "Suele preferir no analizar mucha información antes de tomar decisiones.",
    "Suele ser de espíritu emprendedor.",
    "Tiende a ser egoísta.",
    "Puede ser una persona exigente consigo misma y con los demás.",
    "Quiere triunfar en la vida principalmente porque es una persona con tendencia a ser ambiciosa.",
    "Suele percibir aspectos en las situaciones que a otros se les pasa por alto.",
    "Su tendencia es a ver globalmente e integrar las partes en un todo."
  ],
  2.1: [
    "Es una persona positiva, optimista, entusiasta y con buen sentido del humor",
    "Tiene una gran capacidad para hacer comentarios agradables, graciosos y encantadores.",
    "Procura aliviar momentos de tensión.",
    "Es ágil para cambiar estados de ánimo, de serios o tensos, a suaves y positivos, de forma ligera, sutil y suave.",
    "Le gusta ser popular, el alma de la fiesta. Es una persona extrovertida y espontánea.",
    "Le gusta que le reconozcan socialmente y disfruta y se motiva con el reconocimiento público de sus logros.",
    "Tiene una gran capacidad para motivar, entusiasmar y alcanzar resultados a través de las personas.",
    "Le gusta que los demás tomen decisiones rápidamente.",
    "No le gustan las supervisiones estrictas.",
    "Es una persona imprecisa con los tiempos, porque invierte mucha de su energía en relacionarse con los demás.",
    "Le gusta relacionarse, es socialmente adaptable y amigable.",
    "Es una persona que se desenvuelve mejor con otros que sola.",
    "Actitud colaboradora.",
    "Frente al estrés y las presiones, será casual e informal, sin dejar de darle la cara a los retos.",
    "Confía en los demás y en sus habilidades para aportar a las personas y cumplir con los compromisos.",
    "También es una persona que confía mucho en sí misma y en sus habilidades.",
    "Su inclinación principal es a tener nuevas relaciones sociales, tratar con personas, en un ambiente positivo y agradable.",
    "Le da mucha importancia al aspecto emocional y a los sentimientos.",
    "La intuición es un aspecto que tiene muy desarrollado, sobre todo cuando se trata de conocer a la gente.",
    "Puede ser poco realista al evaluar a las personas por su tendencia a confiar en la gente sin discriminar.",
    "Sus promesas y buenas intenciones le sobrepasan.",
    "El tiempo juega en su contra a la hora de cumplir con tantos compromisos adquiridos.",
    "Otro elemento que afecta su cumplimiento es querer complacer a todas las personas.",
    "Es una persona orgullosa de su actitud y deseo de ayudar a los demás.",
    "Aun cuando es una persona que motiva y estimula a los demás, puede tener dificultades a la hora de dirigir.",
    "Por su gran necesidad de ser alguien amigable, le cuesta corregir y aplicar disciplina, cuando es necesario.",
    "Es muy hábil a la hora de solucionar los problemas de la gente. Le gusta estar disponible para los demás.",
    "Es competente a la hora de negociar los conflictos entre las personas.",
    "Logra que los demás se muestren abiertos y confíen.",
    "Demuestra el estrés o preocupación de forma abierta y emotiva.",
    "Cuando está bajo presión puede ser en exceso optimista y poco realista.",
    "Es una persona inatenta a los detalles.",
    "Su peor temor es a que le rechacen socialmente.",
    "Se auto promueve, sobre todo bajo presión.",
    "Tiene la habilidad de crear un ambiente informal y relajado.",
    "Se relaciona bien con personas que acaba de conocer y con sus amigos de siempre.",
    "Tiene una gran cantidad de relaciones que pueden ser claves en su progreso y desarrollo.",
    "Tiene capacidad para influir en los demás y es optimista a la hora de usarla para convencer y persuadir.",
    "Se enfoca en motivar a otros a alcanzar sus objetivos."
  ],
  2.2: [
    "Tiende a ser una persona positiva, optimista, entusiasta y con buen sentido del humor",
    "Suele ser hábil haciendo comentarios agradables, graciosos y encantadores.",
    "Trata de aliviar momentos de tensión.",
    "Tiende a ser ágil para cambiar estados de ánimo, de serios o tensos, a suaves y positivos, de forma ligera, sutil y suave.",
    "Puede que le guste ser popular, el alma de la fiesta. Tiende a ser una persona extrovertida y espontánea.",
    "Tiende a gustarle que le reconozcan socialmente y a disfrutar con el reconocimiento público de sus logros.",
    "Suele ser capaz de motivar, entusiasmar y alcanzar resultados a través de las personas.",
    "Le tiende a gustar que los demás tomen decisiones rápidamente.",
    "Prefiere que no le supervisen de forma estrecha.",
    "Tiende a ser una persona imprecisa con los tiempos por invertir principalmente su energía en relacionarse con los demás.",
    "Tiende a gustarle relacionarse, es socialmente adaptable y es amigable.",
    "Es una persona que suele desenvolverse mejor con otros que sola.",
    "Tiende a ser de actitud colaboradora.",
    "Frente al estrés y las presiones, suele ser casual e informal, sin dejar de darle la cara a los retos.",
    "Tiende a confiar en los demás y en sus habilidades para aportar a las personas y cumplir con los compromisos.",
    "También es una persona que suele confiar en sí misma y en sus habilidades.",
    "Su inclinación principal es a tener nuevas relaciones sociales, tratar con personas, en un ambiente positivo y agradable.",
    "Le da importancia al aspecto emocional y a los sentimientos.",
    "La intuición es un aspecto que puede tener desarrollado, sobre todo cuando se trata de conocer a la gente.",
    "Puede tender a ser poco realista al evaluar a las personas por su tendencia a confiar en la gente.",
    "Sus promesas y buenas intenciones le sobrepasan, en ocasiones.",
    "El tiempo juega en su contra a la hora de cumplir con tantos compromisos adquiridos.",
    "Otro elemento que afecta su cumplimiento es la tendencia a querer complacer a todas las personas.",
    "Tiende a sentir orgullo de su actitud y deseo de ayudar a los demás.",
    "Aun cuando es una persona que motiva y estimula a los demás, puede tender a tener dificultades a la hora de dirigir.",
    "Por su necesidad de ser alguien amigable, le cuesta corregir y aplicar disciplina, cuando es necesario.",
    "Tiende a ser hábil a la hora de solucionar los problemas de la gente. Le suele gustar estar disponible para los demás.",
    "Tiende a ser competente a la hora de negociar los conflictos entre las personas.",
    "Suele lograr que los demás se muestren abiertos y confíen.",
    "Demuestra el estrés o preocupación de forma abierta y emotiva, en ocasiones.",
    "Cuando está bajo presión puede ser optimista de forma poco realista.",
    "Tiende a ser una persona inatenta a los detalles.",
    "Su principal temor suele ser que le rechacen socialmente.",
    "Tiende a auto promoverse, sobre todo bajo presión.",
    "Suele saber cómo crear un ambiente informal y relajado.",
    "Tiende a relacionarse bien con personas que acaba de conocer y con sus amigos de siempre.",
    "Suele tener relaciones que pueden ser claves en su progreso y desarrollo.",
    "Puede ser capaz de influir en los demás con su encanto y persuasión.",
    "Tiende a motivar a otros a alcanzar sus objetivos."
  ],
  3.1: [
    "Actitud positiva como miembro en un equipo.",
    "Es detallista.",
    "Excelente capacidad para seguir lineamientos.",
    "Tiene dificultades a la hora de establecer prioridades.",
    "Es una persona introvertida y se siente insegura en muchas ocasiones.",
    "Es una persona que no es demostrativa emocionalmente.",
    "Tiene actitudes conciliadoras. Los problemas con las personas le incomodan mucho por lo que cede para evitar conflictos.",
    "Prefiere un ambiente estable, que le dé suficiente tiempo para cambiar, de forma planificada y organizada.",
    "Los cambios repentinos no le gustan.",
    "Establece sus rutinas y va a su ritmo, y le gusta seguir el patrón de forma consistente.",
    "Tiene dificultades a la hora de enfrentarse, cotidianamente, con situaciones diversas.",
    "Prefiere lo conocido y se siente cómodo en la rutina.",
    "A la hora de afrontar retos o dificultades, lo hace de forma seria, inalterable, poco emocional.",
    "Una de sus principales virtudes es la paciencia, además de su gran capacidad para armonizar.",
    "Tiende a estabilizar y ser sostenedor en los ambientes en donde se desenvuelve.",
    "Es una persona orientada al servicio.",
    "Es una persona colaboradora.",
    "A la hora de socializar, es hábil en grupos pequeños, con personas conocidas, de confianza.",
    "Tiene mucha facilidad para establecer relaciones profundas.",
    "Le agrada mucho que le vean como una persona equilibrada y estable.",
    "Los demás le perciben como una persona seria, con los pies muy bien puestos sobre la tierra, también como una persona amistosa, metódica y cercana, a quien pueden acercarse.",
    "Es estable y le gusta planificar su crecimiento a largo plazo.",
    "Bajo presión, tiene una actitud reservada, despreocupada, indecisa e inflexible.",
    "Le gustan las tradiciones y tiende a ser una persona conservadora.",
    "De actitud generosa y leal con los demás.",
    "La gente sabe que puede contar con su colaboración.",
    "Es una persona ordenada, limpia, organizada y metódica, lo que le hace alguien fiable.",
    "No tiene ansias de protagonismos, lo que le hace jugar bien dentro de los equipos."
  ],
  3.2: [
    "Su actitud como miembro en un equipo tiende a ser positiva.",
    "Suele ser detallista.",
    "Tiende a preferir participar para un líder y una causa, puede tener capacidad para seguir lineamientos.",
    "Puede tener dificultades a la hora de establecer prioridades.",
    "Es una persona con tendencia a ser introvertida y a sentirse insegura en algunas ocasiones.",
    "Tiende a no demostrar emociones.",
    "Suele tener actitudes conciliadoras. Prefiere evitar los conflictos.",
    "Los problemas con las personas le suelen incomodar.",
    "Puede tenerle miedo a la confrontación, por lo cual suele ceder para evitar problemas.",
    "Tiende a preferir un ambiente estable, que le dé tiempo para cambiar, de forma planificada y organizada.",
    "Los cambios repentinos suelen no gustarle.",
    "Tiende a establecer sus rutinas y suele ir a su ritmo.",
    "Suele preferir seguir el patrón establecido de forma consistente.",
    "Puede tener dificultades a la hora de enfrentarse, cotidianamente, con situaciones diversas.",
    "Tiende a preferir lo conocido y a sentirse más cómodo en la rutina.",
    "A la hora de afrontar retos o dificultades, suele ser una persona seria, inalterable, poco emocional.",
    "Suele ser paciente.",
    "Tiende a armonizar, estabilizar y ser un apoyo en los ambientes en donde se desenvuelve.",
    "Suele ser una persona orientada al servicio.",
    "Tiende a ser una persona colaboradora.",
    "A la hora de socializar, tiende a ser hábil en grupos pequeños, con personas conocidas, de confianza.",
    "Suele tener más facilidad para establecer relaciones profundas que superficiales.",
    "Le puede agradar que le vean como una persona equilibrada y estable.",
    "Los demás le pueden percibir como una persona seria, con los pies puestos sobre la tierra.",
    "Es una persona cercana, a quien pueden acercarse. De actitud amistosa y metódica.",
    "Tiende a ser estable y le suele gustar planificar su crecimiento a largo plazo.",
    "Bajo presión, puede tener una actitud reservada, despreocupada, indecisa e inflexible.",
    "Es una persona con tendencia a ser tradicionalista y conservadora.",
    "Es una persona que puede ser generosa y leal con los demás.",
    "La gente sabe que puede contar, la mayoría de las veces, con su colaboración.",
    "Suele ser una persona ordenada, limpia, organizada y metódica, lo que le hace alguien fiable.",
    "Es una persona que suele no tener ansias de protagonismos, lo que le hace tender a jugar bien dentro de los equipos."
  ],
  4.1: [
    "Mantiene estándares altos de calidad. Le gusta interactuar siguiendo los patrones, normas y reglas establecidos.",
    "Es consistente y consciente.",
    "Es una persona que se siente presionada en los momentos en que se ve obligada a tomar decisiones rápidas.",
    "Prefiere analizar bien los hechos y analizar toda la información, antes de decidir.",
    "Prefiere probar, clarificar y conseguir toda la información posible antes de proceder.",
    "Es realista y prefiere ponerse metas y objetivos alcanzables. Es una persona objetiva.",
    "Antes de enfrentar un problema y buscar una solución, quiere comprender muy bien la situación.",
    "Busca conocer y repasar la mayor cantidad de información posible respecto al problema.",
    "Se torna muy pesimista frente a las opciones.",
    "Es una persona muy crítica y difícil de complacer.",
    "Tiene una actitud meticulosa y detallista, a veces a niveles obsesivos. Quiere que todo salga bien la primera vez.",
    "Le gusta desmenuzar todos los aspectos de una situación, su visión es más de detalles que global.",
    "Aunque es una persona muy crítica, su principal miedo es justamente que le critiquen.",
    "Su actitud es defensiva frente a las críticas.",
    "Es una persona introvertida.",
    "Es perfeccionista y evita cometer errores. Esto puede ser un obstáculo a la hora de avanzar y tomar decisiones.",
    "Querrá tener todos los posibles riesgos bajo control, para evitar a toda costa equivocarse.",
    "Piensa que la mejor manera de persuadir a los demás es a través de los hechos, de la lógica, de la objetividad.",
    "Se esmera en los análisis y prefiere controlar sus emociones a la hora de convencer a alguien.",
    "Es una persona analítica, precisa, exacta y a la hora de resolver problemas se dedica, concienzudamente al análisis.",
    "Tiene una actitud conservadora a la hora de enfrentar los problemas.",
    "Es una persona centrada principalmente en el seguimiento de los procesos, los protocolos y en las normas.",
    "Su principal preocupación es hacer las cosas bien.",
    "Le estresa equivocarse, ya que esto puede estropear los procedimientos y afectar la calidad.",
    "En momentos de concentración, frente a la ejecución de una tarea, no le gustan las interrupciones.",
    "Cumple con los compromisos y los plazos.",
    "Le gusta prepararse y generar la confianza de estar tratando con una persona experta, que sabe de la materia.",
    "Por su actitud objetiva, corre el riesgo de parecer una persona distante y fría.",
    "Toma en cuenta todas las implicaciones que tiene cualquier decisión que pueda tomar.",
    "Puede demorarse en la toma de decisiones.",
    "Cuando las decisiones son de rutina, tiene la habilidad de ser una persona sistemática y rápida.",
    "Cuando las decisiones son importantes y especiales, es una persona muy cautelosa.",
    "No decide hasta tener la certeza de que la decisión es la correcta.",
    "Más que la teoría, le interesa la práctica, los métodos, los patrones y sobre todo la calidad.",
    "Es tradicional y convencional. Es una persona percibida como reservada y formal, modesta y conservadora.",
    "Es una persona tranquila emocionalmente.",
    "Es paciente.",
    "Es una persona disciplinada, diplomática, aunque de convicciones firmes. De buenos modales.",
    "Le gusta cumplir con altos niveles de calidad y con las exigencias desde la primera vez. No quiere equivocarse.",
    "Si bien es una persona autocontrolada y le cuesta molestarse, cuando lo hace, puede explotar.",
    "Cuando se molesta lo dejará ver claramente.",
    "No le gustan las confrontaciones."
  ],
  4.2: [
    "Suele mantener altos niveles de calidad.",
    "Le gusta, la mayoría de las veces, participar siguiendo los patrones, normas y reglas establecidos.",
    "Tiende a ser consistente y consciente.",
    "Es una persona que suele sentirse presionada en los momentos en que se ve obligada a tomar decisiones rápidas.",
    "Prefiere analizar los hechos y revisar información, antes de decidir.",
    "Tiende a preferir probar, clarificar y conseguir mucha información antes de proceder.",
    "Suele ser realista y prefiere ponerse metas y objetivos alcanzables. Tiende a ser una persona objetiva.",
    "Antes de enfrentar un problema y buscar una solución, tiende a preferir comprender el problema.",
    "Prefiere conocer, revisar información y hechos sobre el tema, antes de buscar una solución.",
    "Tiende a tornarse pesimista frente a las opciones.",
    "Es una persona que puede ser crítica y tiende a ser difícil de complacer.",
    "Puede tener una actitud meticulosa y detallista. Tiende a querer que la tarea salga bien la primera vez.",
    "Tiende a gustarle desmenuzar todos los aspectos de una situación, su visión suele ser más de detalles que global.",
    "Aunque es una persona que puede ser crítica, su principal miedo es a que le critiquen.",
    "Su actitud puede ser defensiva frente a las críticas.",
    "Es una persona con tendencia a ser introvertida.",
    "Puede ser perfeccionista y trata de evitar cometer errores. Esto puede ser un obstáculo a la hora de avanzar.",
    "Querrá tener los posibles riesgos bajo control para tomar decisiones y así evitar equivocarse.",
    "Tiende a pensar que la mejor manera de persuadir a los demás es a través de los hechos, de la lógica, de la objetividad.",
    "Tiende a esmerarse en los análisis y en el control de sus emociones, a la hora de convencer a alguien.",
    "Es una persona con tendencia a ser analítica, precisa, exacta.",
    "A la hora de resolver problemas tiende a preferir dedicarse a realizar análisis minuciosos del mismo.",
    "Suele tener una actitud conservadora a la hora de enfrentar los problemas.",
    "Es una persona que tiende a estar centrada en los procesos.",
    "Su principal preocupación suele ser hacer las cosas bien.",
    "Le puede estresar equivocarse, ya que esto puede estropear los procedimientos y la calidad.",
    "En momentos de concentración, frente a la ejecución de una tarea, tiende a no gustarle las interrupciones.",
    "Suele cumplir con los compromisos y los plazos.",
    "Le tiende gustar prepararse y generar la confianza de estar tratando con una persona experta, que sabe de la materia.",
    "Por su actitud, que tiende a ser objetiva, puede ser percibida como una persona distante y fría.",
    "Suele tomar en cuenta las implicaciones que tienen las decisiones que toma.",
    "Puede demorarse en decidir.",
    "Cuando las decisiones son de rutina, puede tener la habilidad de ser una persona sistemática y rápida.",
    "Cuando las decisiones son importantes y especiales, tiende a ser una persona cautelosa.",
    "Tiende a no decidir hasta tener la certeza de que la decisión es la correcta.",
    "Más que la teoría, le suelen interesar los métodos, la práctica, los patrones y sobre todo la calidad.",
    "Suele ser tradicional y convencional.",
    "Es una persona que puede ser percibida como reservada y formal, modesta y conservadora, tranquila emocionalmente.",
    "Suele ser alguien paciente.",
    "Tiende a ser una persona disciplinada y diplomática, aunque de convicciones firmes. Suele tener buenos modales.",
    "Tiende a cumplir con los niveles de calidad y con las exigencias desde la primera vez. Prefiere no equivocarse.",
    "Si bien tiende a ser una persona autocontrolada y que no se molesta fácilmente, cuando lo hace, puede explotar.",
    "Cuando se molesta, suele demostrarlo.",
    "Tiende a no gustarle las confrontaciones."
  ],
  5: [
    "Es de respuestas rápidas a las crisis y a los cambios, con altas expectativas de obtener resultados de inmediato.",
    "Tiene la habilidad de liderar a las personas del equipo, apoyándose en su encanto e influencia.",
    "Se apoya en su habilidad de liderar y, además, en su determinación y firmeza a la hora de delegar.",
    "Es capaz de actualizar constantemente su comportamiento, según la situación.",
    "Su capacidad de cambiar es muy alta. El cambio forma parte de su día a día.",
    "Mientras la situación es relajada y normal, es capaz de influenciar a la gente y motivarla a través de la persuasión.",
    "Bajo presión y estrés, puede ser firme, determinante e incluso una persona agresiva.",
    "Tiene una gran fuerza que proyecta frente a los otros. Su liderazgo es reconocido por los demás.",
    "Maneja el tiempo con sentido de urgencia y le gusta que la gente le siga el ritmo.",
    "Frente a los grupos numerosos o específicos para algún objetivo, puede mostrarse como una persona muy segura de sí misma.",
    "También se muestra como una persona confiada y articulada.",
    "Su actitud es la de una persona enfocada en los resultados a través de la gente.",
    "Es una persona muy activa y conseguirá que las cosas se hagan.",
    "De ser necesario, hará las cosas con sus propias manos, sólo por el hecho de lograr el objetivo o de alcanzar la meta.",
    "De actitud extrovertida, independiente y con iniciativa.",
    "Es una persona que le gusta competir y ganar.",
    "Tiene la habilidad de ver el panorama global y a la gente dentro de ese panorama.",
    "Le gusta trabajar sin supervisión estrecha ni controles y libre de detalles.",
    "Puede ser flexible con las normas y reglas.",
    "Tiene la habilidad de poder gestionar muchas tareas al mismo tiempo.",
    "Afronta los problemas y retos de forma creativa y directa, manteniendo la motivación de los demás.",
    "Tiene la habilidad de motivar a la gente para que participe.",
    "Tiende a aburrirse con facilidad. No le gustan las tareas rutinarias.",
    "Tiene un buen dominio del lenguaje persuasivo cuando lo necesita.",
    "Puede ser impaciente y demandante cuando está bajo presión.",
    "Le gusta conseguir una nueva forma de hacer las cosas, más rápida y mejor.",
    "Le atrae enfrentar retos que no tengan precedentes.",
    "Resolverá los problemas con la gente, con firmeza y con encanto personal.",
    "De tendencia creativa y gusto por la innovación.",
    "Es una persona segura de sí misma."
  ],
  6: [
    "Es una persona decidida, ambiciosa y optimista.",
    "Le gusta resolver los problemas de forma innovadora. En especial es hábil resolviendo los conflictos entre las personas.",
    "Busca superar todos los obstáculos.",
    "Es una persona dinámica y segura de sí misma.",
    "Tiene una actitud positiva y entusiasta al momento de relacionarse con los demás.",
    "Busca influir sobre las personas a través de su encanto y de su firmeza.",
    "Confía en los demás y quiere que los demás confíen en usted.",
    "Busca variedad en su entorno, ya que se aburre con facilidad.",
    "Tiene un gran sentido de urgencia. Es impaciente. Le gusta terminar todo rápido.",
    "Le agradan los cambios y un ambiente con mucha actividad.",
    "Se esfuerza en ser independiente.",
    "Prefiere la informalidad.",
    "Prefiere seguir sus propias directrices a la hora de emprender una tarea o de resolver un problema.",
    "En general le cuesta seguir las normas y reglas establecidas.",
    "No le agradan los controles, ni la estrecha supervisión.",
    "No es detallista.",
    "No se detiene mucho tiempo a analizar, ni a reflexionar, incluso puede ser más bien una persona impulsiva.",
    "Puede ser una persona terca con sus ideas.",
    "Le gusta ser parte activa en la toma de decisiones.",
    "Es una persona arriesgada.",
    "Tiene la habilidad de obtener resultados a través de la gente.",
    "Tiene un buen manejo de las personas y de los equipos.",
    "Es convincente, persistente y de actitud competitiva.",
    "Tiene altos niveles de exigencia para usted y para los demás.",
    "Es una persona expresiva y dinámica.",
    "Es una persona amistosa, positiva y extrovertida.",
    "Mientras la situación es relajada y normal, es capaz de influenciar en su gente y motivarla a través de la persuasión.",
    "Bajo presión y estrés, puede ser firme, determinante e incluso puede ser una persona agresiva.",
    "Tiene la habilidad para aproximarse de diferentes maneras a las personas.",
    "Su visión está orientada hacia el futuro de forma creativa.",
    "Es una persona veloz en su ritmo y multitasking.",
    "Se adapta al entorno, siendo capaz de regular su comportamiento, según la ocasión.",
    "Es una persona decidida con una buena habilidad para influenciar en los demás.",
    "Tiene la facilidad para proyectar una imagen amigable y receptiva mientras tiene todo bajo control.",
    "Es capaz de tomar distancia cuando así lo desee.",
    "De tendencia creativa y gusto por la innovación.",
    "Es una persona segura de sí misma."
  ],
  7: [
    "Es una persona que tiende a ser positiva, optimista, entusiasta y con buen sentido del humor.",
    "Es una persona que suele ser buena haciendo comentarios agradables, graciosos y encantadores.",
    "Busca aliviar momentos de tensión. Es muy hábil a la hora de influenciar a otros a través de su encanto y amabilidad.",
    "Cuando las emociones, los valores y la creatividad están involucrados, se implica totalmente.",
    "Le gusta ser parte de un equipo, ser una persona aceptada por los demás, integrada y reconocida públicamente.",
    "Le gusta destacar, ser popular. En general, su orientación es hacia las personas y los resultados.",
    "Cuando el ambiente se pone tenso, se torna exigente y demandante, firme en su actitud y en la toma de decisiones.",
    "Cuando el ambiente es favorable, es una persona muy compresiva, de estilo amistoso.",
    "Procede con las puertas abiertas y se hace accesible a todo el mundo.",
    "Juega bien dentro de un equipo, aunque prefiere el rol de líder que de seguidor.",
    "Tiene una gran capacidad para motivar y entusiasmar.",
    "Proyecta una imagen de persona adaptable, pero en el fondo tiene muy claro cómo quiere que se hagan las cosas.",
    "Tiene claro hasta dónde va a ceder.",
    "La autoridad y el poder le llaman la atención.",
    "Es una persona espontánea y auténtica.",
    "La supervisión estricta le incomoda, al igual que la rutina, prefiere que le dejen a sus anchas.",
    "Puede ser una persona impulsiva a la hora de tomar decisiones.",
    "Prefiere que la gente tome las decisiones de forma rápida.",
    "Le desespera un poco tener que invertir tiempo en análisis exhaustivos.",
    "Es hábil solucionando problemas en donde están involucradas las personas.",
    "Tiene la capacidad de influenciar a las personas para que vean las cosas de la manera que usted las ve.",
    "La comunicación puede fluctuar fácilmente entre un estilo amable y amistoso a un estilo acalorado y argumentativo.",
    "Tiene la facultad de sentirse bien con las personas, incluso con los extraños.",
    "Frente al estrés y las presiones, será casual y de actitud espontánea, sin dejar de darle la cara a los retos.",
    "Dejará en claro su posición, con firmeza.",
    "Será una persona demostrativa cuando sus emociones se vean afectadas.",
    "Los demás se darán cuenta fácilmente de su estado de ánimo.",
    "Es una persona extrovertida y entusiasta.",
    "Tiene una visión global a la hora de enfocar y entender los problemas.",
    "Es consciente del impacto de sus actitudes y decisiones en los demás.",
    "Tiene habilidades para impulsar e iniciar actividades, pero tiende a aburrirse con la rutina.",
    "Ante el planteamiento de una tarea o resolución de un problema, será quien aporte las ideas más creativas e innovadoras.",
    "De tendencia creativa y gusto por la innovación.",
    "Es una persona segura de sí misma."
  ],
  8: [
    "Es una persona de retos, enfocada en la tarea y en los resultados, pero con un buen sentido de equipo.",
    "Es capaz de adaptarse al entorno si lo considera necesario para el logro de los objetivos.",
    "Su ritmo rápido y enérgico, con sentido de urgencia, puede verse matizado por la duda y la reflexión.",
    "Puede tender a ser impaciente y si es necesario buscará colaborar con los demás para avanzar en los resultados.",
    "Tiene un interesante equilibrio en su estilo de comunicarse, puede ser firme y de hablar directo y a su vez considerado con el otro.",
    "Buscará obtener resultados a través de un ambiente estable.",
    "Puede ser una persona generosa y bondadosa con los demás de una manera activa.",
    "Tiene la habilidad para presionar a los demás, de una forma gentil, para la obtención de resultados.",
    "Es una persona que está atenta en darle seguimiento a sus promesas y compromisos mientras esto no perjudique la obtención de resultados.",
    "Cuando el ambiente está en calma, será una persona amigable y considerada.",
    "Cuando el ambiente se torna tenso, su actitud puede ser impulsiva y firme.",
    "Puede estresarse fácilmente, aunque recurrirá a la reflexión como primer recurso para calmarse.",
    "Si no funciona, los demás podrán notar fácilmente, que no está de buen ánimo.",
    "Ante situaciones difíciles tenderá a ser una persona ética y leal y espera lo mismo de los demás.",
    "Pudiera ser de las personas que prefieren hacer la tarea por usted misma, en vez de delegar.",
    "Siente en el fondo que nadie realiza la tarea como usted lo hace.",
    "Tenderá a ser independiente y autosuficiente cuando siente que los demás no responden a su ritmo.",
    "Cuando siente el respaldo de su equipo, su actitud será la de una persona integrada.",
    "Tiende a ser una persona un poco más extrovertida que introvertida.",
    "Bajo presión, elegirá preferiblemente entre los resultados de una tarea en vez de atender las necesidades de las personas.",
    "Atenderá a las personas una vez que haya resuelto la obtención de resultados.",
    "Cuando está bajo una fuerte presión, perderá el tacto y la diplomacia.",
    "Cuando considera que hay desviaciones injustificadas, tenderá a dar advertencias.",
    "Tiende a autodirigirse y a ser responsable con sus tareas y metas.",
    "Pudiera tener dificultades a la hora de priorizar.",
    "Es una persona activa y puede estar al frente de varios retos a la vez.",
    "Es una persona curiosa, innovadora, creativa y reflexiva.",
    "Pudiera tener la tendencia de no querer invertir mucho tiempo en estar analizando la información y los hechos disponibles.",
    "Aunque tiende a ser independiente, también puede adaptarse a los demás.",
    "Siempre estará disponible para la acción.",
    "Tiende a reflexionar antes de asumir compromisos.",
    "Es una persona que cuenta con recursos para enfrentar los retos y problemas.",
    "Es una persona creativa a la hora de enfrentar retos complejos."
  ],
  9: [
    "Es una persona de retos, enfocada en obtener resultados, a través de la participación del equipo.",
    "Es capaz de adaptarse al ritmo del entorno para alcanzar los resultados y de acelerar el paso, si la situación lo amerita.",
    "Puede ser paciente en condiciones normales.",
    "Cuando hay tensión le costará mantener la calma y la quietud.",
    "Buscará colaborar con los demás para avanzar en los resultados.",
    "Cuando el ambiente está en calma puede ser una persona considerada, ecuánime y relajada.",
    "Puede ser una persona generosa con los demás de una manera activa.",
    "Bajo presión, puede volverse egoísta o indiferente.",
    "Tiene la habilidad de presionar a los demás, de una forma gentil, para la obtención de sus intereses.",
    "Es una persona atenta al seguimiento de las promesas y compromisos, manteniendo el foco en el logro de los resultados.",
    "Será amigable y gentil en favor de la obtención de los objetivos.",
    "Cuando el ambiente se torna demandante, pudiera tener una actitud impulsiva y firme que buscará matizar con amabilidad.",
    "Puede estresarse fácilmente, aunque recurrirá a la reflexión como primer recurso para calmarse.",
    "Si el reflexionar no funciona, los demás podrán notar fácilmente, que no está de buen ánimo.",
    "Pudiera ser de las personas que prefieren hacer la tarea por usted misma, en vez de delegarla.",
    "En el fondo siente, que nadie hará la tarea como usted la hace.",
    "Es una persona que puede ser muy detallista y metódica en la solución de problemas rutinarios.",
    "Es una persona que puede ser innovadora y creativa con nuevos retos y desafíos.",
    "Tenderá a ser independiente y autosuficiente cuando siente que los demás no responden a su ritmo.",
    "Cuando siente el respaldo de un buen equipo, se comportará como una persona integrada.",
    "Ambos aspectos, los resultados de una actividad y las necesidades de las personas, son igualmente importantes para usted.",
    "En momentos de presión, podrá elegir entre el resultado o las personas, dependiendo de la situación.",
    "Cuando está bajo una fuerte presión, perderá el tacto y la diplomacia.",
    "Cuando considera que hay desviaciones injustificadas, tenderá a dar advertencias.",
    "Tiende a autodirigirse y a ser responsable con sus tareas y metas.",
    "Pudiera tener dificultades a la hora de priorizar.",
    "A la hora de abordar una tarea o un problema, será capaz de ver lo global y los detalles.",
    "Es una persona activa y puede estar al frente de varios retos a la vez.",
    "Es una persona curiosa, innovadora, reflexiva, metódica y creativa.",
    "También sabe lidiar con la rutina y las normas establecidas.",
    "Pudiera tener la tendencia de no querer invertir mucho tiempo en estar analizando la información y los hechos disponibles.",
    "Su estilo de influencia estará basado en ser activamente una persona estabilizadora en los momentos de tensión.",
    "Es una persona enérgica y amistosa a la hora de conectar con los demás para la obtención de los resultados.",
    "Se adaptará naturalmente al entorno para el logro de los objetivos.",
    "En condiciones especiales su ritmo será rápido y enérgico, con sentido de urgencia.",
    "Aunque tiende a ser independiente, también puede adaptarse a los demás.",
    "Siempre tendrá disposición para emprender nuevas tareas, sabiendo cómo mantener la calma en la rutina.",
    "Es una persona creativa a la hora de enfrentar retos complejos.",
    "Ante los cambios prefiere tener información por anticipado, sin embargo, se mostrará con buena disposición para asumir los retos.",
    "En la rutina tiende a seguir patrones, pero a la hora de enfrentar retos complejos apelará a la creatividad.",
    "Será consciente al asumir riesgos."
  ],
  10: [
    "Es una persona con un buen sentido de equipo, que se entusiasma frente a los retos y los desafíos.",
    "Le entusiasma el reto de la solución de problemas en equipo.",
    "Su atención tiende a estar enfocada en la integración de las personas para la obtención de resultados.",
    "Naturalmente se adaptará al entorno para el logro de los objetivos.",
    "En condiciones especiales su ritmo será rápido y enérgico, con sentido de urgencia.",
    "En condiciones normales tiende a ser una persona calmada.",
    "En situaciones tensas puede ser impaciente y buscará colaborar con los demás para avanzar en los resultados.",
    "Tiene un interesante equilibrio en su forma de comunicarse, ya que tiende a tener un estilo considerado y conciliador, pero también puede ser firme.",
    "Puede ser una persona generosa y bondadosa con los demás de una manera activa.",
    "Es gentil a la hora de presionar a los demás para la obtención de resultados.",
    "Pudiera ser muy detallista y apalancarse en modelos probados en la solución de problemas.",
    "Busca equilibrar el ambiente de forma amable y comprensiva y al mismo tiempo mantiene el foco en el logro de resultados.",
    "Cuando el ambiente está en calma, será de actitud dinámica, amigable y firme.",
    "Cuando el ambiente se torna tenso, será una persona reflexiva e imparcial.",
    "Ante el estrés, recurrirá a la reflexión como primer recurso para calmarse.",
    "Bajo mucha presión los demás podrán notar que no está de buen ánimo.",
    "Normalmente tenderá a ser una persona ética y leal y espera lo mismo de los demás.",
    "En situaciones extremas puede ser un poco más flexible.",
    "En situaciones normales su actitud es la de una persona integrada al equipo.",
    "Cuando la situación se vuelve demandante, pudiera preferir tomar la rienda en vez de delegar.",
    "Consciente al asumir riesgos.",
    "Es una persona reservada, pero sintiéndose en confianza, será una persona comunicativa y expresiva.",
    "Aunque su actitud es la de una persona integrada al equipo, si las condiciones lo ameritan, puede ser individualista.",
    "Al elegir en situaciones de presión, escogerá las necesidades de las personas por encima de los resultados.",
    "Cuando está bajo una fuerte presión, perderá el tacto y la diplomacia.",
    "Tenderá a ser conciliador en los conflictos y asumirá una posición firme, solo si eso colabora en la solución del problema.",
    "Pudiera requerir de métodos para orientarse en la realización de sus tareas.",
    "En ocasiones puede tomar la iniciativa y tener una actitud creativa.",
    "Pudiera tener dificultades a la hora de priorizar.",
    "Es una persona activa y puede estar al frente de varios retos a la vez.",
    "Es una persona diplomática, buena escuchando y estabilizadora de forma activa y enérgica.",
    "Pudiera tener la tendencia de no querer invertir mucho tiempo en estar analizando la información y los hechos disponibles.",
    "Puede adaptarse al ritmo de los demás, aunque siempre estará disponible para la acción.",
    "Realizará las tareas diarias de forma rutinaria y metódica.",
    "Será una persona creativa a la hora de enfrentar retos complejos.",
    "Su estilo de influencia estará basado en ser una persona activamente compresiva y conciliadora.",
    "Tiende a estimular al grupo amablemente para la obtención de los resultados."
  ],
  11: [
    "Es una persona con tendencia a orientarse a la tarea y a la obtención de resultados de forma creativa.",
    "Cuida los pasos y la calidad, activamente.",
    "Su principal impulso es la obtención de resultados rápidamente y le sigue el deseo de mantener la calidad.",
    "Para asegurar los modelos de calidad, estará en disposición de invertir un poco más de tiempo, si cuenta con él.",
    "Tenderá a ser una persona creativa y analítica.",
    "Es una persona realizadora, con tendencia a parecer fría y seca.",
    "Su visión está dirigida al futuro, pero de forma objetiva.",
    "De ideas creativas para prever y solucionar problemas.",
    "Aunque su estilo puede ser directo y agresivo, esa tendencia se puede matizar ligeramente por su diplomacia y sensibilidad.",
    "A la hora de tomar decisiones, tiene la habilidad de responder con rapidez y a la vez analizar la mayor cantidad de información posible.",
    "Tiene la habilidad de analizar rápidamente los pros y los contras de una solución.",
    "Tiene la tendencia a asumir riesgos y es capaz de correr con las consecuencias de estos.",
    "Le gusta la acción y es una persona que prefiere pensar más que sentir, por eso su mundo emocional puede verse limitado.",
    "Su ritmo marca la pauta a la hora de solucionar problemas.",
    "Su principal patrón de influencia es su interés por alcanzar las metas con excelencia e innovación.",
    "Se esmera en que las tareas se hagan rápido y con un alto control de calidad.",
    "Le gusta el cambio, la acción, los retos y puede calcular los costos cuando el tiempo se lo permite.",
    "Su visión de las tareas y retos es tanto general como particular.",
    "Es capaz de mirar el todo y las partes, aunque su mayor tendencia es a mirar lo global.",
    "Tiene un estilo desafiante y brusco a la hora de relacionarse con los demás y probablemente no sea consciente de ello.",
    "En la rutina puede ser perfeccionista, pero bajo presión, tratará de lograr los resultados rápidamente.",
    "Tenderá a actuar con sentido de urgencia y eventualmente se detendrá a reflexionar o a analizar la información.",
    "Capaz de aplicar métodos eficientes para lograr los objetivos, de forma rápida y respetando los controles de calidad.",
    "Usted prefiere tener el poder y la autoridad para decidir y empujar cambios y acciones.",
    "Prefiere relacionarse con personas que le den libertad de movimiento.",
    "Le gusta tener toda la información necesaria a la mano.",
    "Es una persona más extrovertida que introvertida y tiende a ser impaciente.",
    "Frente al conflicto usted puede ser egoísta e individualista.",
    "Es una persona enfocada en los resultados, en las normas y en los procedimientos."
  ],
  12: [
    "Es una persona principalmente orientada a la tarea.",
    "Sus principales y opuestos impulsos pueden ser un obstáculo. Por un lado, desea obtener resultados rápidamente y por el otro quiere mantener el control en la calidad.",
    "En ocasiones tendrá que deponer el impulso de rapidez por calidad o viceversa.",
    "Tenderá a ser una persona creativa y analítica.",
    "Es una persona realizadora, con tendencia a parecer fría y seca.",
    "Su visión está dirigida al futuro, pero con los pies en la tierra.",
    "De ideas creativas para prever y solucionar problemas.",
    "Aunque puede ser de estilo directo y agresivo, esa tendencia se puede matizar ligeramente, por su diplomacia y sensibilidad.",
    "A la hora de tomar decisiones, tiene la habilidad de responder con rapidez.",
    "Es capaz de analizar mucha información para respaldar sus decisiones, aunque esta misma competencia podría hacerle vacilar en decisiones específicas.",
    "Su inclinación es más a pensar que a sentir, por eso su mundo emocional puede verse limitado.",
    "Su ritmo marca la pauta a la hora de solucionar problemas.",
    "Su principal modelo de influencia es su interés por alcanzar resultados con excelencia.",
    "Se esmera en que las tareas se hagan rápido y con un alto nivel de comprobación.",
    "Le gusta el cambio calculando los riesgos.",
    "Su visión de las tareas y retos es tanto general como particular, es capaz de mirar el todo y las partes.",
    "Mira lo global y los detalles.",
    "Tiene un estilo desafiante y brusco a la hora de relacionarse con los demás, y probablemente no sea consciente de ello.",
    "Es perfeccionista.",
    "Su ritmo es variante, puede actuar con sentido de urgencia y eventualmente detenerse a reflexionar o a analizar en exceso.",
    "Su tendencia a analizar y reflexionar puede producir demoras.",
    "Presenta dificultad para establecer prioridades, ya que siente que todo es prioritario.",
    "Frente a tareas equilibradas, posee una excelente capacidad de automotivación para lograr los objetivos.",
    "Es capaz de aplicar modelos eficientes para lograr los objetivos de forma rápida y respetando las normas y los procesos.",
    "Prefiere tener el poder y la autoridad para decidir y empujar cambios y tareas."
  ],
  13: [
    "Es una persona que le gusta impulsar el cambio calculando los riesgos.",
    "Se enfoca principalmente en la acción y en la tarea. Es una persona realizadora.",
    "Su enfoque principal es en los planes, en la calidad y en la obtención rápida de resultados.",
    "Posee creatividad, imaginación, capacidad de análisis y cálculo de riesgos para resolver problemas, afrontar retos o diseñar procesos.",
    "Es una persona apegada a las normas y a los patrones para obtener resultados.",
    "Es capaz de proponer nuevas formas e inusuales maneras de afrontar los problemas o las tareas.",
    "Tiene el perfil de una persona que es experta en lo que hace.",
    "Mantiene el respeto por los patrones establecidos y los estándares de calidad.",
    "Frente a las relaciones interpersonales, puede ser más bien una persona fría y distante.",
    "Prefiere estar en la acción que estar relacionándose.",
    "Tiende a ser una persona perfeccionista y detallista para lograr las metas.",
    "Revisa y analiza la información disponible y los hechos antes de decidir.",
    "Esta preferencia analítica puede chocar con su inclinación a ser impaciente y querer tener resultados rápidos.",
    "Frente a los problemas nuevos, tomará en cuenta las teorías disponibles.",
    "Toma en cuenta los hechos relacionados y la información que vaya surgiendo, manteniendo un cierto sentido de urgencia.",
    "Su estilo de relacionarse y comunicarse puede ser brusco y amenazante, sin estar consciente de ello.",
    "Le puede costar establecer las prioridades.",
    "Puede tener períodos de acciones rápidas, intercalados por otros de ritmo pausado dedicados a la reflexión.",
    "Puede ser capaz de hacer análisis rápido de las situaciones y tomar decisiones muy enfocadas en soluciones eficientes.",
    "Tiene la habilidad de poder cambiar rápidamente de actividades.",
    "Su centro de interés es que los resultados sean provechosos, fructíferos y de calidad.",
    "Prefiere que el contacto con la gente sea limitado.",
    "Tiende a prestar mucha atención al orden y a tener disciplina.",
    "Procura que su ambiente esté ordenado y limpio.",
    "Puede ser de ideas muy creativas y de proponer nuevos procesos y modelos para ser más eficientes.",
    "Busca métodos más productivos o con mayor calidad.",
    "Igualmente, le agrada la forma tradicional de obtener resultados y de cuidar la calidad.",
    "Es particularmente una persona cuidadosa de las reglas y las normas, aunque en algunos casos puede ser flexible.",
    "Su mirada está en el futuro y en la forma en cómo establecer normas y modelos más eficientes.",
    "Trabaja con enfoque y esfuerzo en la obtención rápida de resultados de calidad.",
    "Necesita entender mejor la forma de conectarse con la gente de manera más amable.",
    "Su forma de tomar decisiones es manteniendo un enfoque cuidadoso, analítico y preciso,",
    "Puede tener la habilidad de dominar muchas situaciones al mismo tiempo.",
    "Ante los asuntos rutinarios toma decisiones con mucha rapidez.",
    "Ante los temas nuevos o importantes, se detiene un poco más a analizar y revisar opciones."
  ],
  14: [
    "Es una persona encantadora enfocada en las relaciones, se lleva bien con los demás y es amigable.",
    "De actitud positiva, optimista, entusiasta y con buen sentido del humor.",
    "Se enfoca en motivar a otros a alcanzar sus objetivos. Da mucha importancia a las necesidades de los demás.",
    "Su actitud invita a la apertura, es una persona receptiva con los demás.",
    "Aporta al grupo alegría, optimismo y estabilidad. Juega muy bien en equipo.",
    "Puede ser una persona muy buscada para un consejo personal o como coach.",
    "Bajo presión, tiene la inclinación de auto promoverse.",
    "Tiende a cuidar su imagen, le agrada ser una persona querida por los demás y aceptada por el grupo.",
    "Confía en los demás, en sus habilidades para aportar al grupo y en la responsabilidad de asumir los compromisos adquiridos.",
    "También es una persona que confía mucho en sí misma y en sus habilidades.",
    "Siente orgullo de su actitud y deseo de ayudar a los demás y le gusta cuando los demás se dan cuenta y se lo reconocen.",
    "No le gusta estar bajo supervisión estricta, aunque le parece apropiado tener claro lo que esperan de usted.",
    "Disfruta y se motiva cuando le reconocen sus logros públicamente.",
    "Es de fácil trato y los demás pudieran buscarle para que los apoye de forma personal.",
    "Puede ser el alma de la fiesta, a la gente le agrada estar cerca de usted.",
    "Aun cuando es una persona que motiva y estimula a los demás, puede tener dificultades a la hora de dirigir.",
    "Siente gran necesidad de ser amigable. Le cuesta dirigir, corregir y aplicar disciplina cuando es necesario.",
    "Tiene habilidad para hacer comentarios agradables, graciosos y encantadores para aliviar momentos de tensión.",
    "Tiene un nivel alto de tolerancia con las personas, que pudiera perjudicar el rendimiento.",
    "Tiene una habilidad natural para construir relaciones positivas.",
    "Tiende a ser sociable, complaciente, gentil y amigable.",
    "Le gusta participar en grupos, es socialmente adaptable, amable. Prefiere trabajar en equipo que individualmente.",
    "Es una persona colaboradora.",
    "Su estilo de persuasión está basado principalmente en su entusiasmo, calidez humana, positivismo y compresión.",
    "Su peor temor es a que le rechacen socialmente.",
    "Las necesidades de los demás pueden distraerle de la productividad.",
    "Puede ser una persona imprecisa en el manejo del tiempo y tener dificultades a la hora de cumplir con los plazos.",
    "Quizá tienda a prometer más de lo que puede cumplir.",
    "Su énfasis en la consideración por los demás y en evitar molestar a la gente pudiera retrasar la toma de decisiones.",
    "Puede mostrar dificultad ante la administración de disciplina.",
    "Bajo presión se vuelve una persona distraída, poco detallista y con tendencia a dar explicaciones minuciosas.",
    "Su inclinación principal es a tener nuevas relaciones sociales, tratar con personas, en un ambiente positivo y agradable.",
    "Puede ser una persona metódica cuando le presionan.",
    "Por lo general, le costará estar pendiente de los indicadores y del análisis de los hechos.",
    "Pudiera resultarle desagradable tener que revisar muchos hechos antes de tomar una decisión.",
    "La intuición es un aspecto que puede tener muy desarrollado, sobre todo cuando se trata de conocer a la gente.",
    "En ocasiones, puede ser poco realista al evaluar a las personas por su tendencia a confiar en la gente sin discriminar.",
    "Es muy hábil a la hora de solucionar los problemas de la gente. Le gusta estar disponible para los demás.",
    "Es competente negociando conflictos entre las personas. También tendrá la tendencia a ser imparcial cuando sea necesario.",
    "Tiende a cambiar estados de ánimo, de serios o tensos, a suaves y positivos, de forma ligera, sutil y suave.",
    "Le da importancia al aspecto emocional y a los sentimientos."
  ],
  15: [
    "Su enfoque principal es en las relaciones, tiende a integrarse adecuadamente con los demás y a llevarse bien con la gente.",
    "Da importancia a las necesidades de los demás.",
    "Es hábil negociando los conflictos entre las personas. Tiende a buscar resultados ganar-ganar.",
    "Su estilo de persuasión está basado principalmente en su calidez humana, sinceridad y compresión.",
    "Aporta al grupo alegría, optimismo y estabilidad. Le gusta jugar en equipo, se integra con facilidad.",
    "Es de fácil trato y los demás pudieran requerir su apoyo de forma personal.",
    "Aunque es de actitud abierta y receptiva, pudiera preferir relacionarse con su pequeño grupo de personas cercanas.",
    "Puede compartir el protagonismo con otros que requieran de más atención o control.",
    "Tiende a cuidar su imagen, le agrada que le quieran y le acepten.",
    "Es paciente, por lo general.",
    "Tiende a ser sociable, complaciente, gentil y amigable.",
    "Es una persona sincera que sabe expresar confianza a los demás.",
    "Suele tener una actitud colaborativa, empática y generosa.",
    "Puede tener buena disposición con los demás, si es necesario será obediente para no generar conflictos.",
    "Apoya al grupo en el esfuerzo de alcanzar las metas.",
    "Aporta estímulo y entusiasmo al ambiente en donde se desenvuelve.",
    "Puede tender a manejar el tiempo de forma imprecisa y tener dificultades a la hora de cumplir los plazos.",
    "Puede distraerse de la productividad con los requerimientos personales de los demás.",
    "Su énfasis en la consideración por los demás y en evitar molestarles, pudiera retrasarle en la toma de decisiones.",
    "Puede dificultársele la administración de disciplina.",
    "Bajo presión, puede mostrar mayor sentido de urgencia.",
    "Tiene un nivel alto de tolerancia con las personas que pudieran perjudicar el rendimiento.",
    "Tiene una habilidad natural para construir relaciones positivas.",
    "Puede ser una persona muy buscada para un consejo personal o como coach."
  ],
  16: [
    "Tiende a integrarse adecuadamente con los demás y a llevarse bien con la gente.",
    "Da importancia a las necesidades de los demás.",
    "Su estilo de persuasión está basado principalmente en su calidez humana, sinceridad y comprensión.",
    "Tiende a no ser egoísta y le gusta colaborar y ayudar a los demás.",
    "Puede ser un excelente coach o alguien que enseña, asesora y entrena, dentro del equipo.",
    "Es excelente jugando en equipo.",
    "Prefiere que le expliquen todos los detalles antes de asumir los cambios.",
    "Puede ser de tendencia tradicionalista y preferir la estabilidad al cambio.",
    "Puede ser optimista, confiado y poco razonable con respecto a la capacidad de los otros miembros del equipo.",
    "Quizá tenga la tendencia de prometer más de lo que puede cumplir.",
    "Puede perder algo del tiempo productivo en conversar con los demás de temas generales o personales.",
    "Es capaz de darle espacio a las personas que requieren más atención y control de la situación.",
    "Su ritmo tiende a ser pausado.",
    "Su interés principal está en las personas y las relaciones.",
    "Un elemento que pudiera beneficiar su productividad es intentar acelerar su ritmo y enfocarse un poco más en la tarea.",
    "Tiende a ser responsable con las normas y reglas para la convivencia.",
    "Tiende a ser paciente",
    "Inyecta estabilidad y optimismo en su entorno.",
    "Es un buen soporte para sus pares y equipo.",
    "Tiende a confiar en las personas.",
    "Tiene buenas habilidades de comunicación.",
    "Es amable, sociable y amigable.",
    "Tiende a ser una persona compasiva y perceptiva de los sentimientos de los demás.",
    "Es alegre y detallista."
  ],
  17: [
    "Su enfoque principal es hacia las personas, aunque también se centra en la calidad y en los procesos.",
    "Usted es una persona entusiasta, optimista, alegre, pero con los pies puestos en la tierra.",
    "Tiende a confiar en las personas y es hábil a la hora de generar entusiasmo.",
    "Le agrada el reconocimiento social.",
    "Tiene la habilidad de generar cercanía con la gente, manteniendo un cierto nivel de cautela.",
    "Atiende las tareas, los procesos y la calidad, manteniendo un buen clima.",
    "Le gusta mantener la motivación en el equipo sin descuidar las normas ni los protocolos.",
    "Es una persona que se exige a sí misma altos niveles de calidad y estimulará al equipo a seguirlos también.",
    "Es muy sensible a las críticas.",
    "Por lo general tiene buen carácter, aunque en ocasiones puede ser una persona terca con sus puntos de vista.",
    "Quiere que le vean como un iniciador experto de tareas complejas.",
    "En circunstancias difíciles puede manejar al equipo, sin perder de vista los estándares de calidad.",
    "Inspira confianza y experticia.",
    "Toma las decisiones con una buena carga de optimismo, acompañada de análisis de información y de hechos.",
    "Le gusta que su buena participación no pase desapercibida.",
    "Inyecta optimismo y deseo de triunfo en tareas complejas.",
    "Tiene habilidad para las relaciones públicas y diplomáticas.",
    "Pudiera tener dificultades para manejar el tiempo adecuadamente.",
    "Lo mueve el deseo de participar con las personas en tareas específicas con cierto grado de complejidad.",
    "Asumirá con responsabilidad las demoras que cause, por comprometerse en realizar muchas tareas.",
    "Cuida de que sus relaciones sociales sean de calidad.",
    "Influye en la gente para que se integren en el equipo.",
    "Alimenta activamente sus relaciones, con sus amigos, familiares y conocidos.",
    "Muy a menudo, la gente le considerará una persona cercana, que motiva a cuidar de la calidad.",
    "Le gusta participar con un líder democrático que tome decisiones rápidas, pero bien sustentadas.",
    "Le gusta rodearse de gente experta y capaz.",
    "Le agrada ser parte del equipo, colaborar y que le reconozcan su buena disposición a asumir tareas complejas.",
    "En pro de mantener buenas relaciones, puede tener una actitud diplomática a la hora de administrar disciplina.",
    "Es una persona considerada con los demás manteniendo el foco en los detalles de la tarea.",
    "Tiene la habilidad de atender las necesidades de las personas y cuidar las relaciones manteniendo la atención en el cumplimiento de la calidad.",
    "En momentos de presión es capaz de crear un clima amigable, mientras estimula la realización de la tarea.",
    "Se hace notar en su medio. Es una persona expresiva, sobre todo en momentos de preocupación.",
    "Afronta los problemas desde una perspectiva global, es una persona perceptiva y analítica.",
    "Se muestra con una actitud abierta con la gente y logra ganarse la confianza de los demás.",
    "Es una persona receptiva. Tiende a estar disponible a la hora de ayudar.",
    "Mantiene el enfoque en la realización de tareas complejas, estimulando el entusiasmo del grupo.",
    "Tiende a ser competente resolviendo conflictos entre las personas.",
    "Es amigable a la hora de decir lo que piensa de los demás.",
    "No tomará una decisión sin antes considerar a los demás, analizar los hechos y revisar la información disponible.",
    "Tiene la habilidad de sacar lo bueno de los conflictos. Aprende y se adapta.",
    "Es hábil delegando de una forma amistosa y haciendo revisión y seguimiento."
  ],
  18: [
    "Actitud minuciosa en el manejo de las tareas manteniendo el cuidado de las relaciones.",
    "Tiene un interesante equilibrio en el manejo de las relaciones personales y los protocolos y procedimientos establecidos.",
    "Puede ser una persona con tendencia a asumir más de lo que puede cumplir, aunque se auto exigirá el respeto de los plazos.",
    "Su acercamiento a las personas tiene, al mismo tiempo, un matiz diplomático y una actitud positiva.",
    "Es entusiasta y optimista de forma sincera.",
    "Aporta al equipo motivación en el cumplimiento de las tareas.",
    "En los proyectos aporta la energía suficiente para llevarlos hasta el final con éxito.",
    "Tiende a ser una persona analítica y reflexiva, y a la vez sociable.",
    "Influye en los demás con su encanto personal y su buen nivel de conocimiento.",
    "Puede ser perfeccionista y detallista en sus realizaciones.",
    "Puede, a veces, actuar con sentido de urgencia a la hora de tomar decisiones.",
    "Otras veces puede tornarse con un ritmo pausado, en la necesidad de analizar toda la información y evidencias disponibles.",
    "Puede ser una persona atenta y cautelosa al asumir riesgos, pero con una mentalidad abierta a los cambios.",
    "En un ambiente favorable usted tiende a ser una persona confiada de los demás y de sus propias habilidades.",
    "En ambientes hostiles usted es una persona más bien cautelosa.",
    "Pudiera ser una persona que se siente amenazada ante las críticas y tener la inclinación de tomárselas personal.",
    "Tiene tacto para dirigir a los demás en sus tareas y en el cumplimiento de las reglas.",
    "Puede asumir tareas específicas y complejas y a la vez ser clave en la integración y motivación del equipo.",
    "Le cuesta asumir las derrotas y errores en las tareas.",
    "Le gusta que le ofrezcan toda la información disponible para asumir los retos, solucionar problemas y tomar decisiones a través de las personas.",
    "Le gusta contar con información y hechos para analizar a la hora de decidir, pero sin volverse una persona obsesiva.",
    "Le motiva recibir atención de los demás y obtener reconocimiento por la calidad de sus aportes."
  ],
  19: [
    "Ofrece al equipo un enfoque hacia el logro de los estándares de calidad, manteniendo un buen ambiente.",
    "Tiene un buen nivel de optimismo realista.",
    "Toma las decisiones después de haber analizado toda la información y hechos disponibles.",
    "Al tomar decisiones, toma en consideración las necesidades de todas las personas involucradas.",
    "Le gusta seguir las normas, reglas y patrones establecidos.",
    "Es de actitud diplomática y amistosa.",
    "A la hora de motivar a los demás tiene un estilo amistoso y con conocimiento de los hechos.",
    "Es una persona cautelosa, atenta y detallista.",
    "La lógica, la investigación y la capacidad para comunicar sus argumentos con entusiasmo, son sus mejores recursos.",
    "Alienta al equipo al logro de resultados de calidad y al cumplimiento de los procedimientos.",
    "Aporta experiencia y jovialidad al ambiente.",
    "Si bien es una persona enfocada hacia los detalles de una tarea y el análisis, también sabe manejar las relaciones.",
    "Tiende a tomar las decisiones considerando los hechos y las necesidades de las personas.",
    "Proyecta confianza por el manejo profesional de su área de especialidad.",
    "Muestra consideración por los demás.",
    "Aporta un natural sentido de entusiasmo al equipo.",
    "Si bien es una persona cautelosa y respetuosa a la hora de seguir normas, también es perceptiva e intuitiva.",
    "Puede tender a prometer más de lo que puede cumplir, pero será consciente de los plazos.",
    "Puede que se enrede o paralice por estar demasiado pendiente de los detalles.",
    "Es una persona meticulosa y metódica a la hora de afrontar una tarea.",
    "Puede tener una agresividad latente, que puede emerger en forma de pasividad.",
    "Puede tener la habilidad de visualizar con detalles el plan de acción.",
    "Negocia los conflictos entre las personas con interés en conseguir que todos ganen."
  ],
  20: [
    "Su enfoque principal es en los detalles y en la calidad.",
    "Le gusta que la tarea sea bien hecha desde la primera vez.",
    "Le gusta que le reconozcan, con sinceridad, el trabajo bien hecho.",
    "Tiende a ser una persona ordenada y limpia en su ambiente.",
    "Es una persona colaboradora que le gusta participar en grupos.",
    "Si le toca dirigir, tenderá a observar el apego a las normas y procesos y el cuidado en los detalles.",
    "Tiende a ser un especialista en su área, con comportamientos meticulosos y metódicos.",
    "Tiende a ser una persona receptiva, leal y discreta.",
    "Tiende a ser estable, le gusta un ambiente predecible.",
    "No tiende a expresar mucho entusiasmo.",
    "Ante situaciones de tensión o cambios bruscos, se le puede dificultar la toma de decisiones.",
    "Puede ser una persona terca con sus posiciones.",
    "Tiende a tomarse su tiempo para decidir, ya que prefiere primero, analizar la información y los hechos disponibles, al detalle.",
    "Tiende a inclinarse por relaciones con personas cercanas, que le sean familiares.",
    "Puede ser una persona introvertida, prefiere la privacidad y la discreción.",
    "Puede ser de actitud reservada con su vida personal.",
    "Pueden verle como una persona muy metódica, quizá un poco rígida e inflexible, cuando se trata de normas y reglas.",
    "Cuando se saltan los patrones puede asumirlo con malhumor.",
    "Tiene la actitud necesaria para inspirar calma, control y tranquilidad en un ambiente de tensión.",
    "Es principalmente una persona conciliadora, no le gustan los conflictos entre las personas.",
    "Tiende a preferir el cambio planificado y no giros abruptos en la rutina.",
    "Tiende a ser tradicionalista.",
    "Ante innovaciones, puede mostrar resistencia.",
    "Tiene la tendencia de calcular muy bien los riesgos.",
    "Tiende a evitar las oposiciones que puedan ser hostiles, para no enfrentar conflictos.",
    "Los detalles, el análisis y su tendencia a ser precavido le pueden obstaculizar la toma de decisiones y el ponerse en acción.",
    "Tiende a percibir muy bien a los demás.",
    "Es amable, paciente y tolerante.",
    "Puede ser una persona poco expresiva.",
    "Tiende a cumplir con sus responsabilidades de manera consciente y cuidadosa.",
    "Es una persona moderada y autocontrolada.",
    "Por su tendencia a ser una persona meticulosa, descubre problemas que se le pasan por alto a los demás.",
    "Sabe escuchar a las personas y analiza con lógica lo que le dicen.",
    "Tiende a no pelear el protagonismo, cede el espacio si es necesario.",
    "Tiende a aportar pensamiento crítico y el análisis necesario en la toma de decisiones y en la solución de problemas."
  ],
  21: [
    "Toma riesgos de forma calculada y habiendo analizado mucha información con antelación.",
    "Aunque su ritmo puede ser un poco pausado a la hora de tomar decisiones, es muy consciente del tiempo y de los plazos.",
    "Prefiere una persona que le reconozca sinceramente su esfuerzo. Aprecia las palabras honestas de estímulo.",
    "Tiende a analizar mucha información, hechos y material disponible, antes de actuar.",
    "Puede tender a ser detallista y tener la sensación de que, aun cuando haya revisado mucha información, todavía falta mucha más por analizar.",
    "Puede que dude, sienta inseguridad y se detenga antes de tomar una decisión.",
    "Se esmera en el control de la calidad y en cumplir con las normas, manteniéndose integrado al equipo.",
    "Le gusta participar en equipo.",
    "Dedicará tiempo a la revisión de la calidad, normas y procedimientos, antes de poner algo a andar.",
    "Las variables involucradas en una tarea serán revisadas a cabalidad, si usted está a cargo.",
    "La gente pudiera pensar que dedica más tiempo del necesario analizando información.",
    "Tiene habilidad para el pensamiento crítico y la reflexión.",
    "Los altos niveles de calidad que exige a los demás, se los exige a usted también.",
    "Estimula a los demás a que presten bastante atención a los detalles y a los datos disponibles.",
    "Estimula a que repasen toda la información que puedan, usando la lógica, la razón y dejando de lado las emociones.",
    "Puede ser una persona indecisa acerca del cambio, tiende a ser de actitud conservadora y tradicional.",
    "Es una persona diplomática y bien educada.",
    "No le gustan las confrontaciones.",
    "Es más bien una persona conciliadora y objetiva.",
    "Le puede costar ser de actitud abierta a nuevas personas. Prefiere relacionarse con pocas personas en un ambiente familiar.",
    "Puede ser una persona rígida, estricta, de ideas firmes e inflexible respecto a las normas, las reglas y los patrones.",
    "Tiende a ser una persona que sabe lo que hace, por su alto nivel de exigencia en las tareas.",
    "Pudiera ser una persona más abierta a la innovación y al cambio.",
    "Es de tendencia cautelosa, metódica, controlada y previsiva.",
    "Es una persona más bien introvertida, discreta y reservada.",
    "Puede tender a ser dependiente del grupo íntimo con el que participa o comparte.",
    "Tiende a no gustarle que los demás le perciban como una persona poco experta en su área, por lo que dedica tiempo a prepararse.",
    "La incertidumbre y los imprevistos le tienden a incomodar.",
    "Aun cuando puede ser una persona crítica, le genera ansiedad que le critiquen.",
    "Es paciente.",
    "Es una persona intuitiva.",
    "Le gusta que le permitan el tiempo necesario para adaptarse al cambio.",
    "Prefiere sentirse que le orientan y le dirigen con claridad, a través de reglas y ejemplos claros.",
    "Los demás suelen solicitar su opinión o recomendación.",
    "Suele orientarse a seguir las metodologías y tiende a adaptarse al ritmo del entorno.",
    "Le tiende a gustar desempeñarse bien desde el primer momento."
  ],
  22: [
    "Se esmera por mantener altos estándares de calidad.",
    "Le gusta participar siguiendo los procedimientos y normas, siendo parte de un equipo pequeño de personas cercanas.",
    "Es consistente y consciente.",
    "Aunque puede ser de ritmo un poco pausado a la hora de tomar decisiones, es muy consciente del tiempo y los plazos.",
    "Tiene la habilidad de ser paciente.",
    "Le gusta participar bajo la orden de alguien que reconozca sinceramente su esfuerzo.",
    "Aprecia que le guíen y le aclaren lo que esperan de usted y le den pautas de lo que es una tarea bien hecha.",
    "Puede ser muy detallista, incluso esta característica puede ser un obstáculo a la hora de avanzar.",
    "Puede tener siempre la sensación de que aún es necesario tener mucha más información antes de decidir.",
    "Es una persona que tiende a ser cautelosa, metódica, controlada y previsiva.",
    "Es una persona más bien introvertida, discreta y reservada.",
    "Es excelente coordinando las actividades, en el seguimiento y en el control de la calidad.",
    "En el inicio de un emprendimiento o de una innovación, tiende a ser de actitud cautelosa.",
    "Prefiere los ambientes estables, predecibles y controlados.",
    "Es de tendencia analítica, precisa, exacta.",
    "A la hora de resolver problemas se dedica, concienzudamente, a realizar análisis detallados del mismo.",
    "Es principalmente una persona conservadora a la hora de enfrentar los problemas.",
    "Es una persona directa y honrada a la hora de presentar los hechos.",
    "Le gusta prepararse y generar la confianza de estar tratando con un experto que sabe del tema.",
    "Por su actitud objetiva, corre el riesgo de parecer una persona distante y fría.",
    "Tiende a ser una persona estabilizadora dentro del equipo, le gusta asumir una actitud conciliadora e imparcial.",
    "Es una persona analítica y crítica.",
    "Puede ser una persona hábil en lo que hace, por su nivel de exigencia en los estándares de calidad y el respeto a los procedimientos.",
    "Pudiera tener una actitud un poco más abierta a la innovación y al cambio.",
    "Puede ser dependiente del grupo íntimo con el que comparte regularmente.",
    "Tiende a no gustarle que los demás le perciban como alguien poco capaz, por lo que dedica tiempo a prepararse.",
    "Experimenta presión cuando se siente en la obligación de tomar decisiones rápidas.",
    "Prefiere analizar bien los hechos y conocer toda la información, antes de decidir.",
    "Tiene la tendencia de tornarse pesimista.",
    "Toma riesgos de forma calculada y habiendo revisado mucha información con antelación.",
    "La gente pudiera pensar que dedica más tiempo del necesario recopilando información.",
    "El análisis de mucha información puede consumir su tiempo para decidir o ponerse en marcha.",
    "Los estándares de calidad que exige a los demás, se los exige a usted también.",
    "Tiende a estimular a los demás a que presten atención a los detalles y los datos disponibles.",
    "Estimula a los demás a que revisen y analicen toda la información que puedan.",
    "Usa la lógica y la razón, pudiendo dejar de lado las emociones.",
    "Se centra en la calidad, los métodos y en las normas.",
    "Piensa que la mejor manera de persuadir a los demás es a través de los hechos, de la lógica, de la objetividad.",
    "Se esmera en los análisis y prefiere controlar sus emociones a la hora de convencer a alguien.",
    "La incertidumbre y los imprevistos le generan inseguridad.",
    "Aun cuando es una persona crítica, le genera ansiedad que le critiquen.",
    "Es difícil de complacer.",
    "Su principal preocupación es hacer las cosas bien, en un ambiente estable y predecible.",
    "Le estresa equivocarse, ya que esto puede estropear los procedimientos y afectar la calidad.",
    "De actitud diplomática y de buenos modales, con tendencia a la disciplina.",
    "Es una persona reservada y formal, modesta y conservadora. Con tendencia a ser tradicional y convencional.",
    "Tiende a ser de actitud tranquila emocionalmente. No le gustan las confrontaciones."
  ],
  23: [
    "Tiende a enfocarse en los resultados, manteniendo la vista puesta en las necesidades de las personas.",
    "Proyecta la imagen de alguien con capacidad de influencia y persuasión.",
    "Cuenta con buenos recursos personales a la hora de solucionar problemas.",
    "Es capaz de orientar al equipo al logro de los resultados con éxito.",
    "Le gusta mantener un clima de entusiasmo y optimismo.",
    "Tenderá a usar las normas como una referencia y no como un mandato.",
    "Responderá según la situación lo demande, con firmeza y aplomo o con amabilidad y gentileza.",
    "Tiende a ser optimista y es capaz de contagiarlo a los demás.",
    "Es capaz de atraer a las personas a participar y lograr que se enfoquen en la solución de los problemas.",
    "Se enfoca en el logro rápido de los resultados.",
    "Puede ingresar a un proyecto en cualquiera de sus etapas y tomará las riendas para que éste se logre con éxito.",
    "Tenderá a ayudar a las personas a resolver sus problemas, manteniendo el foco en los resultados.",
    "Tenderá a asumir los resultados obtenidos sean cuales sean.",
    "Sabe delegar, ser firme y manejar a las personas, con actitud amigable y optimista.",
    "Su ritmo es rápido a la hora de lograr resultados.",
    "Aunque es realista con sus expectativas, tenderá a presionar con su natural sentido de urgencia para lograr los objetivos.",
    "Tiende a ser impaciente y puede que asuma de forma personal las tareas.",
    "Se impacienta cuando otros no entienden el sentido de urgencia a la hora de obtener resultados.",
    "Esta actitud de impaciencia pudiera llevarle a sobrecargarse de responsabilidades.",
    "A veces tiende a exagerar con el entusiasmo y el optimismo.",
    "Otras veces puede tornarse de actitud negativa y enfatizar en la crítica.",
    "Pudiera tornarse individualista a la hora de considerar que se están tomando mucho tiempo para avanzar.",
    "Se enfoca en tareas realistas y que le brinden la oportunidad de lograr resultados tangibles.",
    "Pudiera ser un poco más paciente a la hora de dejar a los otros hacer su tarea.",
    "Siendo más paciente, podría tener más apoyo de las personas.",
    "Le gusta obtener reconocimiento público de sus logros.",
    "Su tendencia es a ser firme, pero si la situación lo demanda, usted no tendrá problemas en ser amistoso y comprensivo.",
    "Le gustan las posiciones de responsabilidad, que le otorguen autoridad y poder para desarrollar su potencial.",
    "Su imagen es de alguien que moviliza el cambio y logra los objetivos."
  ],
  24: [
    "Tiende a tener ambiciones y metas claras que desea alcanzar.",
    "Es una persona que tenderá a influir en la gente, utilizando sus habilidades personales y de liderazgo.",
    "Su actitud tiende a ser firme, determinada y en ocasiones agresiva.",
    "Es una persona de retos y desafíos.",
    "Su tendencia es a usar las normas como una referencia flexible.",
    "Es una persona que confía en sí misma y en su habilidad para resolver problemas y alcanzar resultados.",
    "Logra mantener al grupo cohesionado.",
    "Es de actitud inquieta y siempre estará buscando desafíos y retos nuevos.",
    "Le da importancia a las relaciones y a las necesidades de la gente.",
    "Tiene el perfil de quien tomará la iniciativa en las tareas y asumirá un rol de líder.",
    "Posee buenas habilidades comunicacionales y las usará principalmente para informar la visión global de la situación.",
    "Es una persona que disfruta de los riesgos, de los retos y también de las relaciones.",
    "Tiende a mantener sus posicionamientos, aunque también tiene la habilidad para suavizar la argumentación, si es clave para lograr los resultados.",
    "Es un líder que coopera con el equipo, para el logro de resultados. Sabe dirigirlo y motivarlo.",
    "Tiende a gustarle competir e ir conquistando espacios de mayores niveles de responsabilidad y autoridad.",
    "Su tendencia es a ser firme, pero si la situación lo demanda, no tendrá problemas es ser amigable y de actitud comprensiva.",
    "Puede que tome en cuenta las reglas y los procesos, si esto lo ayuda a alcanzar los resultados rápidamente.",
    "Estimula a la gente a que logre sus objetivos y supere las brechas de desempeño.",
    "Sus focos principales son la obtención de resultados y la solución de conflictos entre las personas.",
    "Es alguien que estimula a los demás a alcanzar el éxito, a través de su optimismo y motivación.",
    "Tiene principalmente una visión global de los proyectos y problemas.",
    "Puede que pierda de vista los detalles.",
    "Su ritmo y velocidad suelen ser de alguien con sentido de urgencia.",
    "Puede resultar de actitud tosca para quienes no comparten su manera de afrontar las situaciones.",
    "Puede ser una persona impaciente y cuando está bajo estrés puede ser de tendencia agresiva.",
    "Puede perder de vista, en ocasiones, su nivel real de autoridad y sobrepasarlo.",
    "Su optimismo, entusiasmo y pasión por los retos, lo llevan a ponerse metas altas e inalcanzables.",
    "Tiende también a ponerle metas altas e inalcanzables a los demás.",
    "Puede comportarse, bajo presión, de forma individualista, tomando decisiones sin tomar en cuenta a los demás.",
    "En condiciones normales, en el día a día, mantendrá el cumplimiento de las reglas y las normas, mientras estos favorezcan el logro de sus objetivos.",
    "En situaciones de estrés puede ser de actitud impulsiva y firme, aunque lo matizará con sus buenos modales.",
    "Siente que su individualismo garantizará que las cosas se hagan rápido.",
    "Puede manejarse muy bien en ambientes dinámicos, cambiantes, rápidos.",
    "Logrará alinear al equipo en el progreso y el logro de los resultados.",
    "En medio de su rapidez y de su motivación al logro, no pierde de vista a las personas y sus necesidades."
  ],
  25: [
    "Es una persona de retos, enfocada en obtener resultados a través del equipo.",
    "Es capaz de adaptarse al ritmo del entorno para el logro de los objetivos.",
    "Tiene capacidad de acelerar el ritmo si la tarea lo amerita.",
    "Puede ser paciente en condiciones normales.",
    "Cuando la situación se torna tensa, le costará mantener la calma y la quietud.",
    "Bajo tensión, buscará colaborar con los demás para avanzar en los resultados.",
    "En condiciones normales tiende a integrarse adecuadamente con los demás y a llevarse bien con la gente.",
    "En situaciones de tensión, será firme en sus posiciones.",
    "Da importancia a las necesidades de los demás para mantener un buen clima en la obtención de resultados.",
    "Puede tender a usar las normas como una referencia y no como un mandato.",
    "Puede ser una persona considerada, ecuánime, enérgica y puede transmitir optimismo.",
    "Puede ser una persona generosa de una manera activa, aunque bajo presión, puede volverse egoísta o indiferente.",
    "Tiene la habilidad de presionar a los demás, de una forma amigable, para la obtención de resultados.",
    "Es una persona atenta a la hora de darle seguimiento a sus promesas y compromisos.",
    "En la rutina, puede ser una persona amigable y considerada.",
    "Si el ambiente es tenso, pudiera tener una actitud impulsiva y firme.",
    "Puede estresarse fácilmente, aunque recurrirá a la reflexión como primer recurso para calmarse.",
    "Bajo estrés y si el reflexionar no le calma, los demás podrán notar fácilmente, que no está de buen ánimo.",
    "Pudiera ser de las personas que prefieren hacer las tareas por sí misma en vez de delegarla.",
    "En el fondo, siente que nadie hará las cosas como usted las hace.",
    "Puede ser una persona detallista y ordenada en la solución de problemas rutinarios, pero innovadora y creativa frente a nuevos retos.",
    "Tenderá a ser independiente y autosuficiente cuando siente que los demás no responden a su ritmo.",
    "Cuando siente el respaldo de un buen equipo, se comportará como una persona integrada.",
    "En momentos de presión, deberá elegir entre los resultados o las necesidades de las personas.",
    "Decidirá dependiendo de la situación, ya que tanto el proyecto como las personas son importantes para usted.",
    "Cuando está bajo una fuerte presión, perderá el tacto y la diplomacia.",
    "Cuando considera que hay desviaciones injustificadas, tiende a dar advertencias.",
    "Tiende a autodirigirse y a ser responsable con sus tareas y metas.",
    "Pudiera tener dificultades a la hora de priorizar.",
    "A la hora de abordar un plan o un problema, será capaz de ver lo global y los detalles.",
    "Es una persona aplicada y puede estar al frente de varios retos a la vez.",
    "Es una persona curiosa, innovadora y creativa y al mismo tiempo sabe lidiar bien con la rutina y los métodos establecidos.",
    "Pudiera tener la tendencia de no querer invertir mucho tiempo en estar analizando la información y los hechos disponibles.",
    "Su estilo de influencia estará basado en ser activamente una persona estabilizadora en los momentos de tensión.",
    "Será una persona enérgica y amistosa a la hora de conectar con el grupo para la obtención de los resultados.",
    "Se adaptará naturalmente al entorno para el logro de los objetivos.",
    "En condiciones especiales su ritmo será rápido y enérgico, con sentido de urgencia.",
    "Aunque tiende a ser independiente en sus actos, también puede adaptarse a los demás.",
    "Siempre tendrá disposición para emprender nuevas tareas, pero también sabe cómo mantener la calma en la rutina.",
    "Es una persona creativa a la hora de enfrentar retos complejos.",
    "Tenderá a ser una persona activa en la solución de los conflictos de la gente.",
    "Ante los cambios querrá tener información por anticipado y se mostrará con buena disposición para asumir los retos.",
    "En la rutina cuenta con métodos probados, pero a la hora de enfrentar retos complejos apelará a la creatividad.",
    "Será consciente al asumir riesgos."
  ],
  26: [
    "Tiene el foco puesto en la solución de los problemas a través de metodologías exitosas.",
    "Tenderá a ser una persona creativa en la aplicación de los métodos probados.",
    "Le agrada enfrentar desafíos y retos en un ambiente amigable y participativo.",
    "En condiciones desfavorables, pudiera volverse impaciente e individualista.",
    "Es una persona que influenciará a la gente, utilizando sus habilidades personales y de liderazgo.",
    "Puede ser una persona generosa y bondadosa con los demás de una manera activa.",
    "Tiene ambiciones y metas claras que desea alcanzar, a través de las personas, en un buen clima.",
    "Su tendencia a ser una persona firme, determinada y en ocasiones impulsiva, estará matizada por su interés en el bienestar de los demás.",
    "Tenderá a resolver los conflictos entre las personas de manera activa.",
    "Confía en sí misma, en su habilidad para resolver problemas y alcanzar resultados, a través de la gente.",
    "Es capaz de adaptarse al entorno si lo considera necesario, para el logro de los objetivos.",
    "De no lograr adaptarse al entorno, su ritmo será rápido y enérgico, con un alto sentido de urgencia.",
    "Individualista e independiente cuando siente que los demás no responden a su ritmo.",
    "Es una persona considerada y ecuánime cuando está en equipos de alto desempeño.",
    "Es hábil presionando a los demás, de una forma gentil, para la obtención de resultados.",
    "Puede estresarse fácilmente, aunque recurrirá a la reflexión como primer recurso para calmarse.",
    "Cuando no logra mantener la calma, los demás se darán cuenta de su humor.",
    "Ante situaciones difíciles, tenderá a ser una persona ética y leal y espera lo mismo de los demás.",
    "Decide sin tomar en cuenta a los demás, cuando esto favorece los resultados.",
    "Cuando siente el respaldo del equipo, se integra.",
    "Cuando está bajo una fuerte presión, perderá el tacto y la diplomacia.",
    "Da importancia a las relaciones y a las necesidades de la gente, cuando esto no interfiere en la obtención de los resultados.",
    "Pudiera tener dificultades a la hora de priorizar.",
    "Disfruta de los riesgos, de los retos y también de las relaciones.",
    "Es de naturaleza curiosa, innovadora y creativa, pero también valora las rutinas exitosas.",
    "Su tendencia es a ser firme, pero si la situación lo demanda, no tendrá problemas es ser amigable y de actitud comprensiva.",
    "En su actuación le gusta tomar en cuenta las reglas y los modelos, si esto beneficia el logro de los objetivos.",
    "Es un líder que coopera con los demás para el logro de resultados.",
    "Tiende a mantener sus posicionamientos aún en situaciones de tensión, aunque tiene una buena habilidad para suavizar la presión, si en ese momento eso es clave para lograr sus objetivos.",
    "Da soporte a la gente para que logre sus objetivos y supere sus brechas de desempeño.",
    "Pudiera tener la tendencia de no querer invertir mucho tiempo en analizar la información y los hechos disponibles.",
    "Aunque tiende a ser independiente en su desempeño, también puede adaptarse a interactuar con los demás.",
    "Cuando el ambiente es favorable y cuenta con el tiempo necesario, atenderá los detalles de la situación.",
    "Se apegará a los patrones que le garanticen, lo más rápido posible, los resultados o la solución del problema.",
    "Su sentido de urgencia no le hace perder de vista el cumplimiento adecuado de los métodos.",
    "Cuando se siente optimista, tenderá a ser una persona atrevida y arriesgada.",
    "Su tendencia es a usar las normas como una referencia flexible.",
    "Tomará decisiones rápidas cuando esto favorezca los resultados.",
    "En medio de su rapidez y de su motivación al logro, no pierde de vista a las personas y sus necesidades."
  ],
  27: [
    "Es una persona rápida a la hora de obtener resultados y tomar decisiones.",
    "Es una persona que influenciará a la gente utilizando sus habilidades personales y de liderazgo.",
    "Es de tendencia competitiva, le gusta la diversidad, el dinamismo y tomar la iniciativa.",
    "Tendrá su foco puesto en la innovación y el liderazgo, pero sin descuidar los estándares de calidad.",
    "En un ambiente amigable, será una persona encantadora y flexible, siendo capaz de influir y motivar.",
    "En un ambiente tenso u hostil, será firme e incluso de actitudes bruscas.",
    "Puede ser una persona ambiciosa al plantearse metas y tendrá la energía para estimular al equipo a esforzarse duro para lograrlas.",
    "Es capaz de atender varias tareas a la vez, con mucha eficiencia a la hora de conseguir resultados.",
    "Logra mantener un buen nivel de entusiasmo y logra conocer el estatus del progreso de cada proyecto.",
    "Es hábil empujando las circunstancias para conseguir resultados.",
    "Es una persona pionera e influenciadora con foco en la calidad.",
    "Genera confianza, creando redes, estrechando lazos e inspirando credibilidad.",
    "Su ímpetu y energía pueden llegar a ser intimidantes.",
    "Tiene la habilidad de impulsar al equipo hacia el logro de resultados, tratando de mantenerse dentro de las reglas.",
    "Se interesa en dirigir a la gente para lograr los objetivos con excelencia.",
    "Puede faltarle un poco de sensibilidad a la hora de tomar en cuenta las necesidades de los demás.",
    "En general, es una persona más extrovertida que introvertida.",
    "Proyecta fuerza y entusiasmo cuando participa en equipo.",
    "Puede volverse impaciente con que no comparten su sentido de urgencia.",
    "Bajo presión perderá la calma y tenderá a volverse una persona malhumorada y quizá iracunda.",
    "En situaciones de estrés, tenderá a saltarse los detalles y el cumplimiento de las normas.",
    "Puede perder el interés y tender a aburrirse cuando la tarea se torna rutinaria.",
    "Es capaz de ajustar su comportamiento ante las diversas situaciones, con el fin de lograr los objetivos.",
    "Es hábil motivando y persuadiendo al equipo para el logro de los resultados y el cuidado de la calidad.",
    "Es una persona pionera, que, en situaciones ideales, estará atenta durante toda la actividad.",
    "Tendrá el foco, desde el inicio del plan, en el seguimiento, cuidando los estándares de calidad y logrando los objetivos."
  ],
  28: [
    "Tendrá su foco puesto en ser una persona pionera y líder, pero manteniendo cuidado de los estándares de calidad.",
    "En general, es una persona más extrovertida que introvertida.",
    "Tenderá a ser una persona realizadora a través de motivar a la gente a mantenerse enfocada en los resultados.",
    "Es de ritmo rápido en la toma de decisiones.",
    "Tendrá en cuenta, velozmente, el análisis de información relevante a la hora de ponerse en movimiento.",
    "Tiende a ser hábil empujando las circunstancias para conseguir resultados.",
    "Puede cargarse de responsabilidades, ya que, por su sentido de urgencia, asume las tareas de otros para avanzar más rápido.",
    "Individualista al tomar decisiones, cuando los demás no avanzan a su mismo ritmo.",
    "Aunque su tendencia es a ser optimista, en ocasiones puede ser una persona pesimista.",
    "Es una persona crítica con los aspectos débiles del grupo o de las personas.",
    "Tiende a ser una persona experta a la hora de solucionar problemas e innovar.",
    "Bajo estrés puede tender a ser una persona agresiva y malhumorada.",
    "Puede perder de vista, en ocasiones, su nivel real de autoridad y sobrepasarlo.",
    "Puede tender a ser individualista a la hora de tomar decisiones.",
    "Puede tornarse egoísta con la información.",
    "Puede ser muy determinante y firme y su energía puede parecerle brusca a algunas personas que manejan otros ritmos.",
    "Tiene la habilidad de estimular al grupo y de ayudarlo a mantener el norte.",
    "Se interesa en dirigir a la gente para lograr los objetivos con excelencia.",
    "Le falta un poco de sensibilidad a la hora de tomar en cuenta las necesidades de los demás.",
    "Puede perder de vista los detalles por estar tan enfocado en la visión global de la situación.",
    "Valora a las personas por su capacidad de, rápidamente, asumir los cambios.",
    "Valora a aquellos que obtienen resultados y mantienen el cuidado de los estándares de calidad.",
    "Es una persona creativa a la hora de innovar protocolos, métodos y normas para mejorar el rendimiento y la calidad.",
    "Tienden a percibirle como una persona líder, que toma la iniciativa y cuida que las cosas se hagan bien.",
    "Mantiene los estándares de calidad y analiza las opciones disponibles.",
    "Para usted es importante que los otros reconozcan sus logros.",
    "En su desempeño le gusta tomar en cuenta las normas y pautas sociales.",
    "Le gusta ir conquistando roles con mayores responsabilidades.",
    "Le gusta alcanzar más poder, autoridad y construir redes de contactos claves.",
    "Le pueden percibir como una persona dominante, directa y agresiva a la hora de obtener resultados o soluciones.",
    "Es una persona recursiva al resolver problemas y con buen nivel en el manejo de las relaciones, cuando son estratégicas.",
    "Los demás son capaces de percibir su capacidad para solucionar y manejar situaciones.",
    "Mueve a las personas con su optimismo, para que las tareas se logren con éxito.",
    "Logra objetivos dentro de los plazos y con los recursos disponibles.",
    "Cuando se siente optimista, tenderá a ser una persona atrevida y arriesgada, pero manteniendo cuidado por la calidad.",
    "En condiciones normales, cumplirá con los modelos de calidad, mientras le favorezca el logro de sus objetivos.",
    "En condiciones normales, estimulará el ánimo del equipo.",
    "En condiciones especiales tenderá a probar nuevas maneras, más dinámicas y rápidas, buscando mantener la calidad.",
    "Puede asumir una gran diversidad de tareas, manteniendo el foco en lo importante.",
    "Cuando está de ánimo relajado, puede ser amigable y optimista.",
    "Cuando el clima se pone tenso, se torna determinante, firme, y de actitud agresiva.",
    "Le gusta un ambiente con un cierto nivel de dinamismo y cambios.",
    "Buscará motivar a la gente a que se alineen con su ritmo para el logro de resultados, con los niveles de calidad deseados.",
    "Es hábil delegando las responsabilidades con un ánimo entusiasta y optimista.",
    "Tiende a ser una persona competitiva y le gusta ganar.",
    "No evade su responsabilidad.",
    "Buscará que la tarea se realice manteniendo la calidad y negociando los conflictos entre las personas, si es necesario.",
    "Es una persona segura de sí misma y confía en sus habilidades comunicacionales.",
    "Se apoya en sus habilidades de persuasión e influencia, a la hora de dirigirse a grupos de personas."
  ],
  29: [
    "Es una persona principalmente orientada a la tarea.",
    "Posee un nivel moderado de atención en su capacidad de influenciar a las personas a través del entusiasmo y dinamismo.",
    "Sus principales y opuestos impulsos pueden ser un obstáculo, por un lado desea obtener resultados rápidamente y por el otro, quiere mantener un arduo control de calidad.",
    "En algún momento tendrá que deponer un impulso a favor del otro.",
    "Tenderá a ser una persona creativa y analítica, con un interés de fondo de motivar a la gente a la obtención de resultados.",
    "Es una persona realizadora, con tendencia a parecer fría y seca, aunque matizado un poco por su moderado optimismo y entusiasmo.",
    "Su visión está dirigida al futuro, pero con los pies en la tierra.",
    "De ideas creativas para prever y solucionar problemas y para mejorar maneras y normas.",
    "Aunque puede ser de estilo directo y agresivo, esa tendencia se puede matizar ligeramente por su diplomacia y sensibilidad.",
    "A la hora de tomar decisiones, tiene la habilidad de responder con rapidez en el análisis.",
    "Tiende a mirar la mayor cantidad de información posible para respaldar sus decisiones, pero sin obstaculizar la acción.",
    "En ocasiones pudiera vacilar en la toma de decisiones.",
    "Su ritmo marca la pauta a la hora de solucionar problemas.",
    "Su principal forma de influencia es su interés por alcanzar las metas con excelencia, a través de las personas.",
    "Se esmera en que las tareas se hagan rápido y con un alto nivel de calidad.",
    "Tiene en parte un aspecto emotivo y entusiasta.",
    "La mayoría de las veces se enfoca tanto en los medios, como en los resultados.",
    "Tenderá a ser distante y poco atento a las emociones.",
    "Le gusta el cambio calculando los riesgos.",
    "Su visión de las acciones y retos es tanto general como particular.",
    "Es capaz de mirar el todo y las partes. Mira lo global y los detalles.",
    "Su interés tiende a estar puesto en aumentar constantemente el rendimiento y la calidad.",
    "Trata de mantener un buen clima, sin afectar la calidad ni el logro de los objetivos.",
    "Cuando el ambiente es favorable dará entusiasmo al equipo con su energía y dinamismo.",
    "Cuando el ambiente se torna tenso puede asumir una actitud firme, rígida y expresar malhumor.",
    "Puede ser de actitud reservada a la hora de compartir sus ideas.",
    "Su ritmo es variante, puede actuar con sentido de urgencia y eventualmente detenerse a reflexionar o a analizar.",
    "Dificultad para establecer prioridades, ya que siente que todo es prioritario.",
    "Frente a asignaciones equilibradas, cuenta con una excelente capacidad de automotivación para lograr los objetivos.",
    "Capaz de aplicar maneras eficientes para lograr los objetivos de forma rápida y respetando los patrones de calidad.",
    "Prefiere tener el poder y la autoridad para decidir y empujar cambios y planes."
  ],
  30: [
    "Es principalmente una persona realizadora.",
    "Se enfoca en lograr resultados y en descubrir nuevos y mejores maneras para alcanzarlos.",
    "Tiene un enfoque en los cambios, en la innovación y en la calidad.",
    "Logra mantener un entusiasmo adecuado en usted y en el equipo.",
    "Le gustan las personas rápidas en el logro de resultados, que sean capaces de asumir y promover cambios de forma reflexiva.",
    "Le gustan las personas que mantengan los niveles de calidad.",
    "Su interés tiende a estar puesto en aumentar constantemente el rendimiento y la calidad.",
    "Trata de mantener un buen clima en el entorno.",
    "Puede tener dificultades a la hora de priorizar.",
    "Tiende a ser detallista y una persona innovadora en la solución de problemas o en la actualización de tareas.",
    "A veces sentirá que ser perfeccionista y de ritmo rápido al mismo tiempo, son dos expectativas que pueden chocar.",
    "Es capaz de abarcar muchos frentes al mismo tiempo y rápidamente analizar información clave para actuar.",
    "Tiene en parte un aspecto emotivo y entusiasta.",
    "Mayormente, su enfoque estará en las tareas y en los resultados.",
    "Tenderá a ser distante y poner poca atención a las emociones.",
    "Cuando la situación se vuelve tensa o negativa, puede parecer una persona fría y desafiante para los demás.",
    "Su tendencia es a tener una actitud agresiva y malhumorada.",
    "Tiende a ser de actitud firme y determinada, pero manteniendo una cierta inclinación por el bienestar de la gente.",
    "En general es de ritmo rápido tomando decisiones, pero en ocasiones puede tender a demorar.",
    "Tiende a querer revisar y analizar toda la información y hechos disponibles, sin obstaculizar la obtención de resultados.",
    "Suele tener dos ritmos de acción, a veces se mueve con sentido de urgencia y otras veces de forma pausada y reflexiva.",
    "Tiene buenas habilidades para persuadir a los demás.",
    "Da buenos ejemplos de capacidad y ritmo rápido a la hora de hacer frente a los problemas.",
    "Busca equilibrar sus impulsos internos contrapuestos.",
    "Oscila entre la rapidez para lograr resultados y mantener los estándares de calidad.",
    "Es una persona con tendencia a ser creativa a la hora de buscar solucionar problemas y aportar ideas.",
    "Le gusta que le perciban como una persona experta en su área de especialidad y frente a los problemas.",
    "Tiene la tendencia de querer investigar muchas opciones antes de actuar.",
    "Cuando pierde la paciencia puede ser firme, de actitud terca, agresiva o impositiva.",
    "Su tendencia a ser una persona directa y firme se matizará ligeramente por su diplomacia y sensibilidad.",
    "Estimulará al equipo a mantener el ritmo en la actividad y a cuidar las normas y las reglas.",
    "Su visión está dirigida al futuro, pero de forma objetiva y con un adecuado nivel de optimismo.",
    "Tiene la habilidad de analizar rápidamente los pros y los contras de una solución.",
    "Tiene la tendencia a asumir riesgos calculados y es capaz de correr con las consecuencias de estos.",
    "Su tendencia es a la acción y a pensar más que a sentir.",
    "Le gusta el cambio, la acción, los retos y puede calcular los costos y motivar a los demás, si el tiempo se lo permite.",
    "Su visión de los planes y retos es tanto general como particular, es capaz de mirar el todo y las partes.",
    "Su mayor tendencia es a mirar lo global.",
    "En general su estilo es el de una persona analizadora creativa y promotora de innovaciones, cuidadosa de la calidad."
  ],
  31: [
    "Sus focos principales son la obtención de resultado, la estabilidad y la calidad.",
    "Es una persona de retos, enfocada en la tarea y en los resultados, pero con un buen sentido de grupo.",
    "Es capaz de adaptarse al entorno si lo considera necesario para el logro de los objetivos.",
    "Su ritmo por lo general es rápido y enérgico, sin perder de vista las pautas.",
    "En un ambiente relajado tenderá a ser paciente y detallista.",
    "En un ambiente tenso o bajo presión, su tendencia será a ser impaciente y a costarle mantener la calma y la quietud.",
    "La impaciencia le llevará a colaborar con los demás para avanzar en los resultados y controlar la calidad.",
    "Puede ser una persona generosa y bondadosa con los demás de una manera activa.",
    "Es hábil presionando a los demás, de una forma gentil, para la obtención de resultados.",
    "Tenderá a darle seguimiento a sus promesas y compromisos y en coordinar la realización de las tareas.",
    "Cuando el ambiente está en calma, será una persona amigable y considerada.",
    "Cuando se torna tenso, será de actitud impulsiva, expresiva y firme.",
    "Es de tendencia innovadora en cuanto a los métodos establecidos.",
    "Transforma la teoría en soluciones prácticas.",
    "Se maneja entre dos ritmos contrastantes de acuerdo a su entorno.",
    "En condiciones normales mantendrá un ritmo rápido y con sentido de urgencia.",
    "En situaciones adversas pudiera dudar y ser de ritmo más pausado a la hora de tomar acción.",
    "Puede estresarse fácilmente, aunque recurrirá a la reflexión como primer recurso para calmarse.",
    "Si este recurso no le funciona, los demás podrán notar fácilmente que no está de buen ánimo.",
    "Ante situaciones difíciles tenderá a ser de actitud ética y leal y espera lo mismo de los demás.",
    "Pudiera ser de las personas que prefieren hacer las cosas por sí mismas, en vez de delegarlas.",
    "En el fondo, siente que nadie hará las cosas como usted lo hace.",
    "Analiza y reflexiona sus opiniones para luego defenderlas con firmeza.",
    "La creatividad y el análisis son dos de sus competencias.",
    "Tenderá a ser independiente y autosuficiente cuando siente que los demás no responden a su ritmo.",
    "Cuando siente el respaldo de los demás, se integra al equipo.",
    "Si le toca elegir en situaciones de presión, su tendencia será a primero elegir el logro de los resultados y luego las necesidades de los demás.",
    "Cuando la situación lo demanda, le gusta analizar y reflexionar sobre la información y los hechos disponibles.",
    "Cuando está bajo una fuerte presión, tenderá a perder el tacto y la diplomacia.",
    "Cuando considera que hay desviaciones injustificadas, tenderá a dar advertencias.",
    "Suele autodirigirse y a ser responsable con sus tareas y aspiraciones.",
    "Pudiera tener dificultades a la hora de priorizar.",
    "Es una persona colaboradora y puede estar al frente de varios retos a la vez.",
    "Tiene una visión global y de detalle sobre las situaciones.",
    "Es capaz de proyectar con visión de futuro, pero tomando en cuenta las referencias y modelos probados.",
    "Tiende a ser una persona curiosa, innovadora y creativa que al mismo tiempo aprecia el orden y la estabilidad.",
    "Aunque tiende a ser independiente en sus acciones, también puede adaptarse a participar con los demás.",
    "Suele ser una persona lista para la acción, pero calculando los riesgos objetivamente.",
    "Es de actitud creativa y analítica a la hora de enfrentar retos complejos."
  ],
  32: [
    "Es una persona de retos, enfocada en la tarea y en los resultados, pero con un buen sentido de grupo.",
    "Es una persona realizadora pendiente de crear un clima de logro, mientras coordina que se mantenga la calidad.",
    "Tiene una energía que puede ser impulsiva en ocasiones, pero su sensibilidad interna equilibrará esta tendencia.",
    "Es capaz de adaptarse al entorno, si es oportuno para el logro de los resultados.",
    "De no lograr adaptarse al entorno, su ritmo será rápido y enérgico, con un alto sentido de urgencia.",
    "Puede tender a ser impaciente, aunque en ocasiones logrará aquietarse.",
    "La impaciencia lo llevará a colaborar con los demás para avanzar en el logro de los resultados y controlar la calidad.",
    "Tiende a evaluar adecuadamente a las personas que estarán involucradas en el rendimiento del negocio.",
    "Puede ser una persona generosa y bondadosa con los demás, de una manera activa.",
    "Es hábil presionando a los demás, de una forma gentil, para la obtención de resultados de calidad.",
    "Sus focos principales son la obtención de resultado, la estabilidad y la calidad.",
    "Su atención tenderá a estar en darle seguimiento a sus promesas y compromisos y en coordinar la ejecución de las tareas.",
    "Tiende a ser muy consciente de los plazos de entrega.",
    "Cuando el ambiente está en calma, será amigable y de actitud considerada.",
    "Cuando el ambiente se torna tenso, tenderá a ser una persona impulsiva y firme.",
    "Transforma la teoría en soluciones prácticas y enseña conceptos técnicos de forma aplicable.",
    "Tiene un pensamiento creativo e innovador y a la vez crítico, analítico y metódico.",
    "Tiende a ser una persona innovadora en cuanto a los métodos establecidos.",
    "Su finalidad es la de aumentar el rendimiento y alcanzar los estándares de calidad.",
    "Se maneja entre dos ritmos contrastantes a la hora de tomar acción.",
    "Cotidianamente usted mantendrá un ritmo rápido y con sentido de urgencia y en situaciones adversas pudiera dudar y ser de ritmo más pausado.",
    "Ante situaciones difíciles, tenderá a ser una persona ética y leal y espera lo mismo de los demás.",
    "Pudiera ser de las personas que prefieren hacer las tareas con sus propias manos, en vez de delegarla.",
    "En el fondo siente que nadie hará las cosas como usted las hace.",
    "Tenderá a ser independiente y autosuficiente cuando siente que los demás no responden a su ritmo.",
    "Se integra al equipo cuando es de alto desempeño.",
    "En situaciones de presión, decidirá por los resultados y la calidad y luego atenderá las necesidades de los demás.",
    "Cuando está bajo una fuerte presión, perderá el tacto y la diplomacia y dudará en la toma de decisiones.",
    "Cuando considera que hay desviaciones injustificadas, tenderá a dar advertencias.",
    "Tiende a autodirigirse y a ser responsable con sus tareas y metas.",
    "Pudiera tener dificultades a la hora de priorizar.",
    "En ocasiones puede tornarse muy detallista, perfeccionista y de actitud analizadora y rígida con las normas y procesos.",
    "Cuando está bajo estrés se puede tornar ambivalente y pesimista.",
    "Es una persona activa y puede estar al frente de varios retos a la vez.",
    "Tiene una visión global y de detalle sobre los proyectos.",
    "Es capaz de proyectar con visión de futuro, pero tomando en cuenta las referencias y patrones probados.",
    "Puede hacer un análisis rápido de la información y hechos disponibles antes de tomar una decisión.",
    "En general tiende a centrarse en lo importante a la hora de decidir.",
    "Tiende a ser una persona curiosa, innovadora y creativa que al mismo tiempo aprecia el orden y la estabilidad.",
    "En condiciones favorables, agrega valor a la hora de solucionar problemas con sentido de urgencia de forma innovadora.",
    "Cumple con los estándares de calidad, sin perjudicar el clima y el sentido de equipo.",
    "Valora a los demás por la capacidad de comprender los retos y por la habilidad de análisis y solución de problemas.",
    "Aunque tiende a ser independiente, se adapta al equipo en pro de los resultados.",
    "Es una persona con tendencia a estar lista para la acción, pero calculando los riesgos objetivamente.",
    "Tiende a tener una actitud creativa y analítica a la hora de enfrentar retos complejos.",
    "Si bien es una persona que le da importancia al cambio, también valora cierto grado de estabilidad."
  ],
  33: [
    "Es una persona de retos, enfocada en obtener resultados de calidad a través del equipo.",
    "Es capaz de adaptarse al ritmo del entorno para el logro de los objetivos y de la calidad.",
    "Tendrá capacidad de acelerar el ritmo si la tarea lo amerita.",
    "Puede ser paciente en condiciones normales.",
    "Cuando la situación se torna tensa, le costará mantener la calma y la quietud.",
    "Bajo tensión buscará colaborar con los demás para avanzar en los resultados.",
    "Puede ser una persona generosa con los demás de una manera activa.",
    "Bajo presión, puede volverse egoísta o indiferente.",
    "Es una persona realizadora pendiente de crear un clima de logro, mientras coordina que se mantenga la calidad.",
    "Tiende a tener una energía que puede ser impulsiva en ocasiones, pero su sensibilidad interna equilibrará esta tendencia.",
    "Tiende a tener la habilidad de presionar a los demás, de una forma gentil, para la obtención de resultados de calidad.",
    "Es una persona que tiende a estar atenta a la hora de darle seguimiento a sus promesas y compromisos.",
    "Cuando el ambiente está en calma, será amigable y una persona considerada.",
    "Cuando el ambiente se torna tenso, pudiera tener una actitud impulsiva y firme.",
    "Puede estresarse fácilmente, aunque recurrirá a la reflexión como primer recurso para calmarse.",
    "Si la reflexión no funciona, los demás podrán notar fácilmente que no está de buen ánimo.",
    "Pudiera ser de las personas que prefieren hacer las cosas por sí mismas en vez de delegarlas.",
    "En el fondo, siente que nadie hará la tarea como usted la hace.",
    "Es una persona que puede ser muy detallista, analizadora y metódica en la solución de problemas rutinarios.",
    "Es una persona innovadora y creativa con nuevos retos y desafíos.",
    "Tenderá a ser independiente y autosuficiente cuando siente que los demás no responden a su ritmo.",
    "Se integra al equipo cuando es de alto desempeño.",
    "En momentos de presión sentirá la obligación de decidir de acuerdo con las demandas de la situación.",
    "En unas ocasiones dará prioridad a los resultados y en otras ocasiones a las necesidades de las personas.",
    "Tanto la gente como los resultados son de igual importancia para usted.",
    "Cuando está bajo una fuerte presión, perderá el tacto y la diplomacia y puede tornarse de actitud pesimista.",
    "Cuando considera que hay desviaciones injustificadas, tenderá a dar advertencias.",
    "Tiende a autodirigirse y a ser responsable con sus tareas y metas.",
    "Pudiera tener dificultades a la hora de priorizar.",
    "A la hora de abordar una tarea o un problema, será capaz de ver lo global y los detalles.",
    "Es una persona activa y puede estar al frente de varios retos a la vez.",
    "Es una persona con tendencia a ser curiosa, innovadora y creativa que al mismo tiempo lidia bien con la rutina.",
    "Maneja adecuadamente los métodos y normas establecidas.",
    "Pudiera tener la tendencia de no querer estar en contacto con mucha gente extraña.",
    "Pudiera preferir ambientes privados con personas conocidas.",
    "Su estilo de influencia estará basado en ser activamente una persona estabilizadora en los momentos de tensión.",
    "Será una persona enérgica y amistosa al conectar con el equipo para la obtención de los resultados y el cuidado del bienestar.",
    "Naturalmente se adaptará al entorno para el logro de los objetivos.",
    "En condiciones especiales su ritmo será rápido y enérgico, con sentido de urgencia.",
    "Aunque tiende a ser independiente, también puede adaptarse a trabajar con los demás.",
    "Tendrá disposición para emprender nuevas tareas, pero también sabrá cómo mantener la calma en la rutina.",
    "Es una persona con tendencia a ser creativa y planificada a la hora de enfrentar retos complejos.",
    "Ante los cambios prefiere tener información por anticipado y tiempo para analizar los hechos.",
    "Se mostrará con buena disposición para asumir los retos.",
    "En la rutina tenderá a contar con modelos probados, pero a la hora de enfrentar retos complejos apelará a la creatividad.",
    "Será consciente al asumir riesgos."
  ],
  34: [
    "Tiene dos ritmos a la hora de enfrentar problemas.",
    "Su ritmo es rápido con sentido de urgencia cuando la prioridad es lograr resultados pronto y a tiempo.",
    "Posee un ritmo más pausado para analizar información y hechos, cuando la calidad es la prioridad.",
    "En la rutina hará las tareas muy rápido, pero en situaciones especiales, se detendrá a reflexionar y analizar.",
    "Es una persona de retos, enfocada en las tareas, en los resultados y en la calidad, principalmente.",
    "Es una persona con tendencia a alcanzar los objetivos propuestos, se preocupa por coordinar que se mantenga la calidad.",
    "Tendrá sentido de equipo cuando es estratégico en el logro de los resultados.",
    "Mostrará sensibilidad a las necesidades de las personas de ser necesario, para alcanzar los objetivos y la calidad.",
    "Puede ser firme y de actitud agresiva frente a las oposiciones.",
    "Es capaz de adaptarse al entorno si es oportuno para el logro de los resultados; de no ser así, su ritmo será rápido y enérgico, con un alto sentido de urgencia.",
    "Puede ser impaciente y costarle mantener la calma y la quietud.",
    "Debido a su impaciencia, buscará colaborar con los demás para avanzar en los resultados y controlar la calidad.",
    "Tiende a ser una persona experta en diversas áreas.",
    "Afronta las situaciones complejas con tendencia a analizar con urgencia la información disponible y los hechos.",
    "Busca actuar de manera rápida, sin perjudicar los detalles.",
    "Muestra un especial interés en la solución de problemas, en la innovación y en las ideas creativas.",
    "Muestra su capacidad innovadora al implementar nuevos métodos para mejorar el rendimiento y los estándares de calidad.",
    "Probará varias opciones frente a nuevos problemas y retos.",
    "Puede ser detallista y perfeccionista en ocasiones.",
    "Suele darle seguimiento a sus promesas y compromisos.",
    "Tenderá a coordinar la realización de las tareas, para controlar los tiempos y la calidad.",
    "Se autodirige y es responsable con sus tareas y metas.",
    "Tiende a ser consciente de los plazos de entrega.",
    "Pudiera tener dificultades a la hora de priorizar.",
    "Cuando el ambiente está en calma, será amigable y de actitud considerada.",
    "Cuando el ambiente se torna tenso, será una persona de tendencia impulsiva, firme y malhumorada.",
    "Tiene un pensamiento creativo e innovador y a la vez crítico, analítico y metódico.",
    "Pudiera ser de las personas que prefieren hacer las cosas por sí misma en vez de delegar.",
    "En el fondo, siente que nadie hará las tareas como usted las hace.",
    "Tenderá a ser independiente y autosuficiente cuando siente que los demás no responden a su ritmo.",
    "Cuando siente el respaldo de un buen equipo, tenderá a participar de forma integrada.",
    "Cuando considera que hay desviaciones injustificadas, tenderá a dar advertencias.",
    "Tiende a ser una persona activa y puede estar al frente de varios retos a la vez.",
    "Tiene una visión global y de detalle sobre las tareas.",
    "Es capaz de proyectar con visión de futuro, pero tomando en cuenta los patrones y los métodos exitosos.",
    "Puede hacer un análisis rápido de la información y hechos disponibles antes de tomar una decisión.",
    "En general, tiende a centrarse en lo importante a la hora de decidir.",
    "Es una persona curiosa, innovadora y creativa, pero con sentido crítico y objetivo.",
    "En condiciones favorables, agrega valor a la hora de solucionar problemas.",
    "Mantiene sentido de urgencia, de forma innovadora, conservando los estándares de calidad.",
    "Puede ser una persona con tendencia a estar lista para la acción, pero calculando los riesgos objetivamente.",
    "Es una persona con tendencia a ser creativa y analítica a la hora de enfrentar retos complejos."
  ],
  35: [
    "Tiende a ser optimista y a tener buen sentido del humor.",
    "Tiene la tendencia a enfrentar los obstáculos y buscar superarlos a través de las personas.",
    "Le puede gustar resolver los problemas de forma innovadora. Es hábil resolviendo los conflictos entre las personas.",
    "Tiende a buscar influir sobre las personas a través de su encanto y de su firmeza.",
    "Suele confiar en los demás y quiere que los demás confíen en usted.",
    "Tiende a buscar variedad en su entorno, aunque puede tolerar una cuota moderada de rutina antes de aburrirse.",
    "Su ritmo tiende a ser con sentido de urgencia. Tiende a ser impaciente y a gustarle terminar todo rápido.",
    "Tiende a buscar la independencia, aunque puede integrarse y trabajar en equipo.",
    "Tiende a preferir la informalidad.",
    "Es una persona con una buena habilidad para relacionarse, conocer gente nueva y mantener relaciones cercanas.",
    "Le gusta el dinamismo social y formar parte de grupos.",
    "En general, es más de tendencia extrovertida que introvertida.",
    "Puede ser una persona intuitiva.",
    "Su poder de influencia está principalmente en su generosidad y amabilidad y en su empuje por la obtención de resultados.",
    "Suelen percibirle como alguien adaptable y de fácil trato cuando el ambiente es favorable.",
    "Le perciben como una persona firme cuando el ambiente se torna exigente.",
    "Su orientación principal es hacia las personas, las relaciones y la obtención de resultados a través de los demás.",
    "Le gusta involucrarse en actividades y lograr resultados en donde el foco principal sean las personas.",
    "Es una persona que juega bien en equipo y podría asumir, de forma natural, roles de liderazgo.",
    "La gente extraña son personas que pueden llegar a ser sus amigos rápidamente.",
    "Le gustan las posiciones de responsabilidad y autoridad.",
    "Es popular y disfruta mucho del reconocimiento público.",
    "Podrá ser una persona directa y firme si el ambiente se vuelve desfavorable.",
    "Tiende a ser una persona auténtica, espontánea y demostrativa.",
    "Buscará que le involucren y le consideren dentro de las actividades, en roles claves.",
    "Tiende a ser una persona impulsiva en sus decisiones, suele tomar acciones sin haber analizado suficiente información.",
    "Tiene la habilidad de comunicarse de una forma precisa, amable, justa y generosa.",
    "Su visión de las tareas o problemas tiende a ser global, más que a ver los detalles.",
    "Tenderá a ser una persona expresiva cuando está bajo estrés o tiene problemas.",
    "Tiende a motivar de forma natural a los demás y tiende a buscar influenciar a la gente a que piense como usted.",
    "Tiende a ser una persona demostrativa en su disposición a ayudar a los demás, y la gente suele abrirse y confiar en usted.",
    "Tiende a ser más emocional que racional.",
    "Es una persona que suele demostrar cuando está entusiasmada por algo en particular.",
    "Tiende a confiar en los demás y en sus habilidades, esto puede traerle problemas y frustraciones.",
    "Tiende a ser de estilo enérgico y firme a la hora de dar apoyo o de oponerse.",
    "Tiende a ser consciente del impacto de sus decisiones sobre los demás.",
    "Es hábil resaltando los puntos en común en lugar de las diferencias.",
    "Tiende a disfrutar de la compañía de las personas y tiene la habilidad de relajarse.",
    "Tiende a ser informal y logra que se sientan a gusto con usted.",
    "Puede llenarse de muchos compromisos y retos a la vez y ser muy optimista en relación con la obtención de los resultados.",
    "Tiende a ser de actitud subjetiva en relación con la gente, en especial con las personas queridas.",
    "Tiende a ser de ritmo rápido en la toma de decisiones.",
    "Al analizar se centra en los resultados, más que en la calidad, en los detalles o en las reglas.",
    "Tiende a delegar mucho y a hacer poco seguimiento.",
    "Hace seguimiento basado en los sentimientos y no en los procesos o normas.",
    "Puede tener la habilidad de planificar, si es posible a gran escala, con ideas novedosas.",
    "Puede tender a ser una persona pionera a la hora de solucionar problemas."
  ],
  36: [
    "Es una persona con una buena habilidad para relacionarse.",
    "Es hábil para conocer gente nueva y mantener relaciones cercanas.",
    "Cuando el ambiente no es tan cercano, tenderá a tener paciencia y buen humor.",
    "Cuando el ambiente es de confianza, se permitirá dejar salir sus molestias.",
    "Tiende a ser de actitud considerada con las personas.",
    "Su actitud considerada pudiera tornar su ritmo a uno pausado a la hora de decidir.",
    "Tenderá a querer que todas las personas participen y opinen.",
    "No suele ser una persona cautelosa en el análisis de la información al decidir.",
    "Tiende a delegar mucho y a hacer poco seguimiento.",
    "Tiende al seguimiento basado en los sentimientos y no en los patrones.",
    "Es de las personas que suelen ser buscadas para ofrecer un consejo personal.",
    "Su principal foco tiende a ser en las personas.",
    "Pudiera ser una persona argumentadora ante las críticas.",
    "Tiende a tomar las críticas a modo personal.",
    "Tiene tendencia a colaborar, por lo que las personas se sentirán apoyadas.",
    "Puede tener dificultad para manejar adecuadamente el tiempo y los plazos de entrega.",
    "Puede tener cierta dificultad a la hora de impartir disciplina.",
    "Puede tornarse muy tolerante de aquellos que no dan la talla en el rendimiento.",
    "Su ritmo tiende a ser equilibrado cuando el ambiente es favorable.",
    "Cuando está bajo presión tendrá sentido de urgencia.",
    "Suele ser una persona respetuosa de los demás.",
    "Tiende a mantener buen humor cuando se relaciona con la gente.",
    "Si se lo propone puede superar momentos de aburrimiento en la rutina.",
    "Puede lograr ser persistente en los proyectos profesionales.",
    "Es una persona positiva a la hora de negociar los conflictos entre las personas.",
    "Puede lograr las metas a través de la gente y del apoyo del equipo.",
    "Se maneja bien tanto en grupos pequeños como en grupos grandes.",
    "Se maneja bien tanto con conocidos como con extraños.",
    "En ocasiones puede ser firme y de actitud directa.",
    "Su capacidad le convierte en alguien con habilidad para orientar a los demás.",
    "Tiende a entusiasmar a la gente en el logro de los objetivos.",
    "Motiva a los demás a través de su optimismo y energía.",
    "Tiende a ser una persona sincera con los demás de una forma respetuosa.",
    "Inspira confianza y eso le ayuda a relacionarse y a conocer gente constantemente.",
    "Posee capacidades importantes a la hora de influenciar a los demás.",
    "Es capaz de comprender a los demás, con calidez, sinceridad, receptividad y apertura."
  ],
  37: [
    "Tiene la tendencia a enfrentar los obstáculos y buscar superarlos.",
    "Tiende a enfrentar los obstáculos a través del apoyo de las personas.",
    "Puede ser una persona de recursos frente a los retos.",
    "Le puede gustar resolver los problemas de forma innovadora.",
    "En especial es hábil resolviendo los conflictos entre las personas.",
    "Es una persona dinámica y segura de sí misma la mayor parte del tiempo.",
    "Si bien tiende a ser agente de cambio, le gusta un cierto grado de estabilidad.",
    "Tiende a delegar y a hacer poco acompañamiento.",
    "Tiende a hacer seguimiento basado en los sentimientos y no en los procedimientos y normas.",
    "Suele ser una persona decidida, ambiciosa y optimista.",
    "En general, es más de tendencia extrovertida que introvertida.",
    "Puede ser una persona intuitiva.",
    "Su actitud tiende a ser amable y entusiasta al momento de relacionarse con los demás.",
    "Tiende a buscar influir sobre las personas a través de su encanto y de su firmeza.",
    "Confía en los demás y quiere que los demás confíen en usted.",
    "Busca variedad en su entorno, ya que puede tender a aburrirse.",
    "Tiene sentido de urgencia.",
    "Tiende a ser impaciente y a ser una persona que le gusta obtener resultados rápidos.",
    "Le tienden a agradar los cambios y un ambiente dinámico.",
    "Suele esforzarse en ser independiente. Prefiere la informalidad.",
    "Prefiere seguir sus propias reglas al emprender una tarea y resolver un problema.",
    "En general le cuesta seguir los métodos establecidos.",
    "No le agradan los controles, ni la estrecha supervisión.",
    "Cuando la obtención de los resultados depende de seguir un patrón, lo seguirá, pero en general no se apegará a las normas.",
    "No se detiene mucho tiempo a analizar la información disponible.",
    "Puede ser más bien una persona impulsiva.",
    "Le tiende a gustar participar en la toma de decisiones.",
    "Tiene la habilidad de obtener resultados a través de la gente.",
    "Hace un buen manejo de los conocidos y del grupo.",
    "Puede tender a tener altos niveles de exigencia para usted y para los demás.",
    "Tenderá a matizar su nivel de exigencia con su natural sensibilidad hacia las personas.",
    "Es una persona que tiende a ser activa, dinámica y colaboradora.",
    "Mientras la situación es relajada, es capaz de influenciar a la gente y motivarla. Influirá en el grupo a través de la persuasión y la amabilidad.",
    "En situaciones de presión, puede ser una persona firme, determinante y agresiva.",
    "Tiene la habilidad para aproximarse de diferentes maneras a las personas.",
    "Su visión tiende a estar orientada hacia el futuro de forma creativa.",
    "Tiende a ser capaz de cuidar de algunas tradiciones cuando favorecen la obtención de los resultados.",
    "Es alguien que puede ser multitasking.",
    "Tiende a adaptarse al entorno, regulando su comportamiento, según la ocasión.",
    "Tiene la facilidad para proyectar una imagen amigable, receptiva y dinámica."
  ],
  38: [
    "Es una persona con habilidad para relacionarse y conocer gente nueva.",
    "Prefiere mantener relaciones cercanas.",
    "Tiene energía y puede tender a ser una persona arriesgada y competitiva tanto en lo social como en lo profesional.",
    "Matiza su energía con su sensibilidad personal.",
    "Tenderá a enfocarse en generar un buen clima al delegar responsabilidades.",
    "Tenderá a ser amable a la hora de ejercer la autoridad.",
    "Es sociable y amable y en ocasiones, puede ser de actitud firme y directa.",
    "Es un persona motivadora, alegre, optimista e influenciadora.",
    "En un ambiente favorable es una persona encantadora y jovial.",
    "En ambientes de tensión, puede ser una persona muy franca y firme.",
    "A la hora de influenciar, tenderá a ser una persona encantadora y amable.",
    "Tiende a ser leal.",
    "Suele ser una persona sensible y perceptiva.",
    "Tiende a no prestar mucha atención a la información y a los hechos.",
    "No se detiene mucho tiempo en el análisis.",
    "Puede ser una persona que atiende varias tareas a la vez.",
    "A la hora de priorizar, preferirá atender las necesidades de la gente que le rodea.",
    "Suele ser de ritmo equilibrado, pero puede distraerse en la toma de decisiones, especialmente por prestar atención a las relaciones interpersonales.",
    "Sabe tomar la iniciativa, tanto en lo social como en lo profesional.",
    "Tiende a cuidar su imagen, le agrada que le acepten y le quieran.",
    "Puede ser alguien servicial.",
    "Suele ser una persona sincera y tiende a expresar confianza a los demás.",
    "Tiende a ser una persona colaboradora cuando el ambiente es favorable.",
    "Cuando el clima se vuelve negativo puede que asuma actitudes competitivas.",
    "Tiende a tener buena disposición con los demás.",
    "Suele apoyar al grupo en el esfuerzo de alcanzar las metas y obtener resultados.",
    "Construir una red de contactos claves es importante para usted."
  ],
  39: [
    "Tiende a enfocarse principalmente en la obtención de resultados de calidad.",
    "Tiende a obtener resultados a través de las personas.",
    "Le gusta dar la talla en sus responsabilidades.",
    "Tiende a ser una persona segura de sí misma cuando ejerce el liderazgo.",
    "Las demás personas tienden a reconocer su autoridad.",
    "Cuando está bajo estrés, puede tender a ser menos firme.",
    "Bajo tensión puede dejar influenciar sus puntos de vista por los de los otros.",
    "Tiende a manejarse bien en muchos escenarios y con diversos niveles jerárquicos.",
    "Suele integrarse en los ambientes en los que se desenvuelve.",
    "Tiende a participar en el equipo de forma integrada.",
    "Logra mantener los niveles de rendimiento con altos estándares.",
    "Suele ser hábil formando y orientando a la gente hacia el cuidado de la calidad y la obtención de resultados.",
    "Es alguien que motiva a los demás a realizar la tarea y lograr los objetivos.",
    "Tiende a preocuparse por su formación y por alcanzar un buen nivel intelectual.",
    "Suele estar con la atención puesta en los resultados y la calidad.",
    "Busca descubrir mejores prácticas y estimular a la gente a seguirlas.",
    "Suele tener conocimiento de la psicología de las personas y del equipo.",
    "Tenderá a ejercer su liderazgo para estimular e influenciar.",
    "Puede ser impaciente y poco detallista.",
    "Tiende a inspirar confianza.",
    "A veces sobrestima las habilidades de los demás.",
    "Puede que sobrevalore su capacidad de ejercer influencia.",
    "A veces puede ser una persona impulsiva emocionalmente.",
    "Puede tender a ser optimista y entusiasta.",
    "Eventualmente, habrá gente que se confunda y abuse de su confianza.",
    "Transmite calidad y energía.",
    "Puede establecer límites ante grupos grandes, ya que es más impersonal.",
    "Puede tender a ser una persona desordenada en el trabajo.",
    "Logrará proyectar una imagen de tener todo bajo control.",
    "Muestra capacidad de convocatoria y de empuje con el grupo.",
    "Su capacidad de influenciar tiende a ser amplia.",
    "Es posible que logre el soporte de una diversidad de personas.",
    "Cuando está en calma, puede ser exigente y firme.",
    "Cuando está bajo presión, puede que sea condescendiente con los demás.",
    "Puede ser una persona expresiva y espontánea.",
    "Tenderá a generar un buen ambiente para delegar y ejercer la autoridad.",
    "Tiende a ser una persona que consigue que los demás rindan de forma efectiva.",
    "Puede lograr alto rendimiento de principio a fin en las tareas.",
    "Su ritmo suele ser rápido y con tendencia a conseguir resultados inmediatos.",
    "Suele adaptarse fácilmente a los cambios.",
    "Tiende a lograr que la gente colabore y coopere entre sí.",
    "Es una persona realizadora a través de su liderazgo con los demás.",
    "Tiende a ser una persona creativa.",
    "Le gusta buscar mejores formas para abordar los problemas.",
    "Logra generar climas de entusiasmo.",
    "Es capaz de afrontar diversas actividades y tareas.",
    "Puede tener la capacidad de influir en los demás para que piensen como usted y suele lograr que se alineen con sus objetivos.",
    "Suele ser flexible y le gusta hacer equipo con otros que se parezcan a usted en este sentido.",
    "Para usted es importante dedicarse a roles en los que se rodee de personas con conocimientos.",
    "Cuida de su imagen y de su preparación, para proyectar autoridad y conocimiento."
  ],
  40: [
    "Tiende a ser hábil impulsando actividades complejas y planes de envergadura.",
    "Suele ser una persona segura de sí misma cuando ejerce el liderazgo.",
    "Las otras personas tienden a reconocer su autoridad.",
    "Tiende a enfocarse en la obtención de resultados de calidad a través de las personas.",
    "Le gusta dar la talla en sus responsabilidades.",
    "Equilibra de forma adecuada la dedicación a las personas al igual que a las tareas.",
    "Su ritmo de participación suele ser rápido pero sin atropellar.",
    "Es capaz de tomar decisiones con sentido de urgencia pero sin descuidar el análisis.",
    "Hace un buen manejo del humor, siendo gentil, con actitud diplomática y divertida.",
    "Es capaz de relajar ambientes tensos.",
    "En condiciones favorables o en la rutina, tenderá a decidir rápidamente.",
    "Bajo tensión, puede atascarse en los detalles y en la perfección.",
    "En un ambiente tenso, puede tender a ser menos firme en sus puntos de vista.",
    "Producto del estrés, puede dejarse influenciar con otras maneras de ver la situación.",
    "Tenderá a mantener su atención en los modelos de calidad y en las actividades del grupo.",
    "Generará motivación por el logro de resultados excelentes.",
    "Puede tender a ser una persona muy confiada en las habilidades de los demás y este nivel de confianza puede traerle problemas.",
    "Puede tender a sentirse vulnerable frente a las críticas.",
    "Cuando está en calma puede tender a ser exigente y firme.",
    "Cuando está bajo presión puede que sea condescendiente con los demás.",
    "Puede tener la necesidad, en ocasiones, de manejar mucha información.",
    "Tiende a analizar hechos antes de tomar una decisión.",
    "Suele ser entusiasta a la hora de seguir las normas.",
    "Tiende a motivar a los demás en la importancia de tomar en cuenta los procedimientos.",
    "Suele seguir los estándares de calidad para lograr los resultados que desea.",
    "Se le dificulta la confrontación y el conflicto con las personas que le rodean.",
    "Tenderá a ser una persona evitativa de los conflictos, aunque en ocasiones podrá perder el tacto y la diplomacia.",
    "Tenderá a motivar y a impulsar el logro de las metas, cuidando la calidad.",
    "Suele orientar con éxito a la gente en las tareas claves de los proyectos.",
    "Tiende a prepararse y estar a la altura de los retos y desafíos.",
    "Suele saber atender las necesidades de las personas y negociar los conflictos.",
    "Tenderá a ser sensible ante el ambiente, la calidad y el rendimiento.",
    "Suele procurar un ambiente estimulante, competente, de integración y de logros.",
    "Tiende a darle importancia a sus sentimientos y a los de los demás.",
    "Tiende a motivarse con la competencia y el deseo de ganar.",
    "Su competitividad puede ser un estímulo para el equipo, al enfrentar los obstáculos.",
    "Puede ser una persona meticulosa y consciente en la supervisión de las tareas.",
    "Tiende a ser una persona creativa a la hora de proponer estándares de calidad.",
    "Es optimista en la obtención de resultados de calidad a través de la gente.",
    "Conserva cierta cautela ante los riesgos, aunque tendrá el impulso de seguir adelante.",
    "Cuando hay presión, será una parte clave del equipo.",
    "Tenderá a lograr el mejor rendimiento del equipo manteniendo un buen ambiente.",
    "Puede ser detallista y organizado en la planificación."
  ],
  41: [
    "Se desenvuelve bien en su área y maneja con habilidad a las personas.",
    "Tenderá a dejar claro, de forma estimulante, lo importante que es el control de la calidad.",
    "Suele generar confianza, haciendo sentir a los demás como compañeros indispensables.",
    "Tiende a mantener al equipo enfocado en el cuidado de los detalles y de la calidad para el logro de resultados.",
    "Maneja un buen sentido de optimismo y diplomacia, enfocado al alcance de las metas.",
    "Tendencia a la impulsividad.",
    "Puede tener riesgo de sobrepasar sus límites de autoridad.",
    "Es bueno manejando los conflictos entre las personas y negociando de forma positiva.",
    "Tiende a ser hábil, impulsando actividades complejas y planes de envergadura.",
    "Tiende a ser una persona segura de sí misma cuando ejerce el liderazgo.",
    "Los demás tienden a reconocer su autoridad como líder.",
    "Tiende a enfocarse principalmente en la obtención de resultados a través de las personas.",
    "Le gusta dar la talla en sus responsabilidades.",
    "Es una persona decidida, ambiciosa y optimista.",
    "Le gusta resolver los problemas de forma innovadora.",
    "Busca superar los obstáculos y mantener los estándares de calidad.",
    "Busca influir sobre las personas a través de su encanto, de su firmeza y profesionalismo.",
    "Confía en los demás y quiere que los demás confíen en usted.",
    "Busca variedad en su entorno ya que se aburre con facilidad.",
    "En condiciones normales tiende a analizar antes de decidir.",
    "Bajo presión actuará con mayor sentido de urgencia.",
    "Puede tender a ser impaciente.",
    "Le gusta obtener resultados de calidad rápidamente.",
    "Le agradan los cambios y un ambiente con mucha actividad.",
    "Tiende a buscar ser independiente.",
    "Le gusta ser parte activa en la toma de decisiones.",
    "Es una persona que tiende a asumir riesgos calculados.",
    "Suele tener un buen manejo de los colaboradores y del equipo.",
    "Es persistente.",
    "Es una persona competitiva.",
    "Tiene altos niveles de exigencia para usted y para los demás.",
    "Tiene la habilidad para aproximarse de diferentes maneras a las personas.",
    "Su visión está orientada hacia el futuro, de forma creativa.",
    "Mantiene un interés especial por la calidad.",
    "Pudiera tener interés en la innovación de los normas y procesos formales.",
    "Se adapta al entorno, siendo capaz de regular su comportamiento según la ocasión.",
    "Tiene la facilidad para proyectar una imagen amigable mientras mantiene el control.",
    "Puede proyectar una imagen receptiva y es capaz de tomar distancia cuando así lo desee."
  ],
  42: [
    "Tiende a analizar hechos antes de tomar una decisión.",
    "Sabe cómo hacer que una idea se convierta en algo práctico.",
    "Tiende a delegar y a hacer seguimiento para asegurarse de los compromisos.",
    "Tiende a hacer seguimiento de los plazos y del cuidado de los detalles que delega.",
    "Es entusiasta a la hora de seguir los procesos o motivando a los demás a que lo hagan.",
    "Puede ser hábil en la planificación.",
    "Su enfoque principal es hacia las relaciones.",
    "Mantiene cuidado especial en la obtención de resultados con calidad.",
    "Es una persona con tendencia a impulsar causas complejas.",
    "Puede ser capaz de impulsar tareas de envergadura.",
    "Le gusta dar la talla en sus responsabilidades.",
    "Equilibra adecuadamente la dedicación a las personas, las tareas y los detalles.",
    "Su ritmo para desenvolverse suele ser rápido pero sin atropellar.",
    "Es capaz de tomar decisiones con sentido de urgencia.",
    "Mantiene un buen nivel de análisis de la información disponible.",
    "Hace un buen manejo del humor, siendo gentil; de actitud diplomática y divertida.",
    "Es capaz de relajar ambientes tensos.",
    "En condiciones favorables o en la rutina, tenderá a decidir rápidamente.",
    "En situaciones tensas, puede atascarse en los detalles y en la perfección.",
    "En situaciones de estrés, puede tender a ser menos firme en sus puntos de vista.",
    "Bajo tensión, puede dejarse influenciar con otras maneras de ver la situación.",
    "Tiende a mantener la atención en los niveles de calidad.",
    "Tiende a generar motivación en los demás por el logro de resultados excelentes.",
    "Suele ser una persona que puede orientar a otros adecuadamente.",
    "Puede alinear al grupo en las tareas claves de los proyectos y en el control de la calidad.",
    "Bajo presión será una parte clave del grupo.",
    "En ambientes tensos, logrará buen rendimiento del equipo sin que se afecte el ambiente.",
    "Puede ser detallista en la planificación.",
    "Le interesa prepararse técnicamente y estar a la altura de los retos y desafíos.",
    "Sabe cómo atender las necesidades de las personas.",
    "Suele ser sensible ante el clima del equipo, la calidad y el rendimiento",
    "Procurará crear un ambiente estimulante, competente, de integración y de logros.",
    "Suele dar importancia a sus sentimientos y a los de los demás.",
    "Puede ser capaz de expresar sus sentimientos positivamente cuando es necesario.",
    "Tenderá a expresar sus sentimientos en aras del logro de resultados e integración del equipo.",
    "Se entusiasma con las competencias y su deseo de ganar.",
    "Su competitividad será un estímulo para el equipo a la hora de enfrentar los obstáculos.",
    "Puede tender a ser, en ocasiones, una persona meticulosa y consciente en la supervisión de las tareas.",
    "Puede tomarse de forma personal las críticas.",
    "Puede que confíe demasiado en las habilidades de la gente y esto puede perjudicar el éxito de la tarea.",
    "Puede tornarse muy detallista y perfeccionista.",
    "Demostrará su optimismo y confianza en el respeto de los normas y procedimientos.",
    "Puede que sea una persona espontánea y expresiva con sus emociones.",
    "Alineará sus emociones para mantener el enfoque en el rendimiento y en la calidad.",
    "Puede que sea un poco entusiasta a la hora de comprometerse.",
    "Tiende a prometer más de lo que puede asumir. La tendencia a comprometerse puede convertirse en una fuente de presión.",
    "Puede ser hábil negociando los conflictos entre las personas."
  ],
  43: [
    "Su foco principal, sincero y estable, es en las relaciones y en las personas. Puede llevarse bien con diferentes estilos de personas.",
    "Mantiene buenas redes de contactos y cuidado especial de la calidad.",
    "Inyecta entusiasmo y motivación a los demás. Suele ser una persona amistosa, amable y fácil de tratar.",
    "Tiende a prepararse profesionalmente y puede que estimule a los demás a hacerlo también.",
    "Tiende a ser una persona comunicativa, modesta y asertiva cuando es necesario.",
    "Puede excederse, en ocasiones, en las expectativas y habilidades suyas y de los demás.",
    "Tenderá a entrenar a los demás en el logro de los estándares de calidad.",
    "Puede influir en las personas a través de su actitud profesional y responsable.",
    "Suele generar entusiasmo con su optimismo.",
    "Puede tener, en ocasiones, reacciones impulsivas.",
    "Tiende a afrontar sus responsabilidades de forma metódica, sistemática y persistente.",
    "Promueve la creación de normas para el control eficiente de la calidad.",
    "Puede dar la impresión de estar muy disponible.",
    "A veces no sabrá cómo mantenerse al margen del compromiso.",
    "Es una persona persuasiva, hábil en la comunicación, amigable e interesada en la calidad.",
    "Puede tender a tomar las críticas de forma personal.",
    "Pudiera ser de tendencia impulsiva al responder a las críticas.",
    "Cuando la situación está en calma, pudiera ser detallista y perfeccionista.",
    "Bajo tensión, pudiera ser una persona descuidada y con tendencia a hablar mucho.",
    "Tenderá a estimular a los demás de forma positiva en el abordaje de las tareas.",
    "Motivará positivamente el seguimiento de los patrones, normas y procedimientos.",
    "En situaciones tensas e inestables, se le puede dificultar tomar decisiones.",
    "Puede tender a insistir en la aplicación de los sistemas establecidos.",
    "Ante los cambios, tenderá a demandar explicaciones detalladas.",
    "Hará todo lo necesario por cumplir con sus promesas.",
    "En ambientes favorables estimulará a los demás a innovar en la planificación y en tareas.",
    "Puede tener la habilidad para entrenar y guiar con paciencia a las personas.",
    "Tenderá a ser alguien que cuida, de forma positiva, de los estándares de calidad.",
    "Le agrada que le reconozcan públicamente por sus logros.",
    "Puede tener habilidades para negociar y motivar a los demás.",
    "Logra con sus habilidades comunicacionales y de análisis que el equipo colabore.",
    "Ante los conflictos entre las personas, será de actitud conciliadora en lugar de confrontadora. Su enfoque será el de ganar-ganar.",
    "Tiende a ser de actitud generosa y amable.",
    "Se toma en serio la preparación frente a sus responsabilidades y le gusta rodearse de personas que valoren formarse."
  ],
  44: [
    "Su enfoque principal es en las relaciones. Suele ser una persona amistosa, amable y fácil de tratar.",
    "Mantiene buenas redes de contactos y cuidado especial de la calidad.",
    "Le gusta dar la talla en sus responsabilidades.",
    "Tiende a cumplir con los plazos y la calidad.",
    "Busca prepararse profesionalmente y promueve que los demás también lo hagan.",
    "Ante problemas, tareas y responsabilidades, tenderá a tener una actitud seria.",
    "Expresa buen sentido del humor cuando el ambiente es favorable y está de ánimo.",
    "Inyecta entusiasmo y motivación al equipo.",
    "Puede excederse en las expectativas de las aptitudes de los demás.",
    "Tiende a confiar.",
    "Suele llevarse bien con diferentes estilos de personas.",
    "Puede tener dificultad para tomar decisiones en situaciones inestables o complejas.",
    "Tiende a esmerarse en conseguir los mejores resultados cumpliendo con las normas.",
    "Puede dar la impresión de estar muy disponible.",
    "A veces no sabrá mantenerse al margen del compromiso asumiendo una actitud evitativa.",
    "Tiene una buena habilidad de persuasión. Se apalanca principalmente en su habilidad comunicacional.",
    "Tiende a tomar las críticas de forma personal.",
    "Pudiera ser de actitud impulsiva al responder a las críticas.",
    "Puede ser detallista y perfeccionista. Esto puede ser un obstáculo en climas de tensión.",
    "Estimulará a otros en el cumplimiento de las tareas, normas y procedimientos.",
    "Inspira y motiva a los demás a cuidar de los detalles y de la calidad.",
    "Le gusta innovar en el desarrollo de una solución a la hora de abordar un problema.",
    "Puede ser un buen coach o mentor en su área y tenderá a guiar con paciencia.",
    "Le agrada que le reconozcan públicamente por sus logros.",
    "Tiene habilidades para negociar y motivar a las personas.",
    "Consigue con sus habilidades comunicacionales y de análisis que el equipo colabore.",
    "Ante conflictos entre las personas, será de actitud conciliadora y no confrontadora. Su enfoque será el de ganar-ganar.",
    "Puede ser una persona percibida como alguien central y clave."
  ],
  45: [
    "Suele inyectar motivación y estabilidad en el equipo.",
    "Su enfoque principal es en las relaciones.",
    "Tiende a integrarse adecuadamente con los demás y a llevarse bien con la gente.",
    "Suele dar importancia a las necesidades de los demás y al cuidado de la calidad.",
    "Le gusta jugar en equipo.",
    "Con frecuencia es de actitud abierta y receptiva con las personas.",
    "A veces, pudiera preferir relacionarse con su pequeño grupo de personas cercanas.",
    "Puede compartir el protagonismo con otros que requieran de más atención o control.",
    "Es paciente y tiene buena disposición con los demás, por lo general.",
    "Suele ser una persona que sabe expresar confianza a los demás.",
    "Si es necesario, será obediente para no generar conflictos.",
    "Tiende a apoyar al grupo en el esfuerzo por alcanzar las metas.",
    "Puede que maneje el tiempo de forma imprecisa, de ser así tendrá dificultades para cumplir los plazos.",
    "Puede tender a distraerse de la tarea ante los requerimientos personales de los demás.",
    "Hace énfasis en la consideración por los demás y en evitar molestar a la gente, esto puede retrasar la toma de decisiones.",
    "Se le dificulta administrar disciplina por su tendencia a evitar molestar a los demás.",
    "Bajo presión, puede mostrar mayor sentido de urgencia.",
    "Le puede gustar formarse y motivar a los demás para que lo hagan.",
    "Puede excederse en las expectativas de sus habilidades y en las de los demás.",
    "Entrena a otros para alcanzar altos estándares de calidad en su área de especialidad.",
    "Puede ser de reacciones impulsivas frente a las críticas.",
    "Tiende a afrontar sus responsabilidades de forma metódica, sistemática y persistente.",
    "Promueve la creación de normas para el control de los estándares de calidad.",
    "Puede dar la impresión de estar muy disponible.",
    "A veces, no sabrá cómo mantenerse al margen de los compromisos adquiridos y pudiera asumir una actitud evitativa.",
    "Cuando la situación está en calma puede ser detallista y perfeccionista.",
    "Bajo estrés puede ser una persona descuidada y con tendencia a hablar mucho.",
    "Estimula a los demás de forma positiva en el abordaje de las tareas.",
    "Motiva a los demás para que sigan las reglas, normas y procedimientos.",
    "En situaciones tensas e inestables, se le puede dificultar tomar decisiones.",
    "Puede tender a insistir en la aplicación de los métodos establecidos.",
    "Ante los cambios, tenderá a demandar explicaciones detalladas.",
    "Hará todo lo necesario por cumplir con sus promesas.",
    "Si el clima es favorable, estimulará a los demás a innovar en las planificaciones y proyectos.",
    "Puede tener la habilidad para entrenar como un experto en lo que hace.",
    "Le agrada que le reconozcan por sus logros.",
    "Puede tener habilidades para negociar y motivar a los demás.",
    "Ante los conflictos entre las personas tendrá una actitud conciliadora en lugar de confrontadora. Su enfoque será el de ganar-ganar.",
    "Tiende a ser de actitud generosa y amable."
  ],
  46: [
    "Suele tener buena capacidad de comunicación al delegar sus responsabilidades.",
    "Tiende a ser entusiasta en la ejecución de sus responsabilidades.",
    "Es capaz de motivar y contagiar a los demás.",
    "Buscará tener experiencia en su área de conocimiento.",
    "Puede que tenga buenas habilidades para relacionarse.",
    "Sabe expresar buen sentido del humor y será una persona oportuna al hacerlo.",
    "Tenderá a asumir con seriedad y detalle la calidad y las normas. Motivará a los demás para que lo hagan también.",
    "Puede ser una persona capaz de ayudar a otros a calmarse en momentos de tensión.",
    "Puede lograr buenos niveles de conocimiento en su área de especialidad.",
    "Puede ser de actitud amplia y receptiva a la hora de relacionarse con los demás.",
    "La paciencia con las personas y su capacidad para motivar son parte de sus fortalezas.",
    "Tiende a ser una persona sincera y estable en sus vínculos interpersonales.",
    "Tiende a participar bien en equipo.",
    "En un ambiente favorable es una persona amistosa, de fácil trato y agradable.",
    "En un ambiente tenso su carácter puede ser malhumorado y difícil de complacer.",
    "Es una persona activa al entusiasmar a la gente a llevar adelante las tareas.",
    "Tiende a cumplir con las normas y alcanzar los niveles de exigencia.",
    "Puede ser resistente a los cambios.",
    "Tiende a ser de ritmo equilibrado para lograr resultados.",
    "Tenderá a dedicar mucho tiempo a analizar información y hechos antes de actuar.",
    "Tiende a ser consciente del factor tiempo y de los plazos.",
    "Puede tender a ser una persona crítica, aunque no se toma bien las críticas que puedan hacerle los demás.",
    "Es hábil impulsando en su equipo la misión de la empresa.",
    "Sabe administrarse dentro de sus responsabilidades y tareas.",
    "Puede establecer muy altas expectativas sobre las habilidades de los demás.",
    "Es una fuente de optimismo y motivación dentro del equipo.",
    "Puede tener la habilidad para entrenar a los demás en su área de especialidad.",
    "Puede ser autodidacta en la adquisición de habilidades y conocimiento."
  ],
  47: [
    "Su enfoque principal es hacia la gente.",
    "Tiende a ser una persona empática con los demás.",
    "Mantiene un buen nivel de independencia personal.",
    "Tenderá a ser persistente en las tareas.",
    "Mantendrá una postura firme y determinada en sus planteamientos y propuestas.",
    "Es capaz de alinear, con una actitud positiva e integradora, a los oposicionistas.",
    "Tiende a generar un ambiente de cooperación y compañerismo.",
    "Puede tener la habilidad para negociar los conflictos entre las personas y comprender los diferentes puntos de vista.",
    "Puede ser de actitud descuidada en el análisis de la información disponible para la toma de decisiones.",
    "Agrega valor al equipo con su capacidad para solucionar los problemas creativamente e incluir a la gente para crear la solución.",
    "Tiende a apoyar al equipo y aportar innovación al proyecto.",
    "Puede ser una persona que juega bien en equipo.",
    "Tiene la sensibilidad para atender las necesidades de los demás.",
    "Atiende al equipo sin perder de vista los resultados de la tarea.",
    "Puede tener dificultad a la hora de priorizar sus tareas cotidianas.",
    "En ocasiones puede ser una persona un poco indecisa y vacilante.",
    "Tiende a ser consistente en el logro de resultados.",
    "En condiciones favorables y en la rutina tendrá sentido de urgencia en las tareas.",
    "En clima amigable tomará las decisiones para la solución de problemas comunes.",
    "En climas tensos o de cambios importantes, tenderá a tener un ritmo más pausado.",
    "Tenderá a evadir las situaciones tensas encargándose de asuntos rutinarios o muy conocidos.",
    "Tomará muy en cuenta el nivel de lealtad que demuestran los demás.",
    "Siempre estará en disposición de ayudar y orientar, más si es líder en el equipo.",
    "Es una persona que tiende a ser dedicada y a estar disponible.",
    "Se estresa un poco frente a los cambios, sobre todo si son repentinos e importantes.",
    "Cuando siente motivación por algo demostrará una energía inusual.",
    "Se notará entusiasta cuando siente motivación.",
    "Puede ser paciente cuando interactúa con la gente.",
    "Tenderá a respetar los métodos establecidos, aunque puede promover algunos cambios con los que se siente seguro.",
    "En los asuntos triviales pudiera ser de actitud más relajada frente a las normas.",
    "Muestra capacidad para desarrollar complejos métodos y hacerles seguimiento.",
    "Quiere ser parte del equipo y que le acepten.",
    "Tiene energía para trabajar duro por un líder o por una causa.",
    "Se enfoca en la solución de problemas, cuidando a las personas involucradas.",
    "Tiene la capacidad de calmar a las personas que están fuera de control emocional.",
    "Es una persona que integra activamente a los miembros del equipo.",
    "Puede tender a crear un ambiente estable, sincero, estimulante, creativo y positivo para participar.",
    "Su enfoque está en el mantenimiento del ritmo del equipo, cuidando la obtención de los resultados.",
    "Se adaptará naturalmente al entorno para el logro de los objetivos.",
    "En condiciones especiales su ritmo será rápido y enérgico con sentido de urgencia.",
    "En condiciones normales tiende a ser de actitud calmada.",
    "Ante situaciones de tensión puede ser impaciente.",
    "Bajo climas tensos buscará colaborar con los demás para avanzar en los resultados.",
    "Puede tender a ser una persona generosa y bondadosa de una manera activa.",
    "Suele ser gentil a la hora de presionar a los demás para la obtención de resultados.",
    "Puede que asuma riesgos que no afecten su estabilidad.",
    "Tiende a asumir el reto de progresar en los sistemas y modelos establecidos.",
    "Se entusiasma frente a los retos y los desafíos en la solución de problemas."
  ],
  48: [
    "De actitud sostenedora del esfuerzo del equipo, con sentido de innovación.",
    "Su orientación tiende a ser hacia las personas, al mantenimiento del ritmo y la creatividad.",
    "Es hábil planificando y creando sistemas para el logro de los objetivos y la solución de problemas.",
    "Mantiene la vista puesta en el resultado sosteniendo un buen ambiente.",
    "Es gentil a la hora de presionar a los demás para la obtención de resultados.",
    "Tiende a asumir riesgos que no afecten su estabilidad.",
    "Puede asumir el reto de progresar en los sistemas y en los modelos establecidos.",
    "Es una persona con un buen sentido de equipo.",
    "Se entusiasma frente a los retos y los desafíos en la solución de problemas.",
    "Su actitud tiende a ser la de servir a la gente. En roles de liderazgo sería un líder con orientación al servicio.",
    "Le agrada orientar a las personas en el logro de los objetivos.",
    "Puede tender a no analizar suficiente información antes de tomar una decisión.",
    "Busca lograr resultados únicos, orientado en la gente.",
    "Tiene buenas habilidades para relacionarse e integrar al grupo. Suele llevarse bien con la gente.",
    "Mantiene una actitud equilibrada ante su entorno. Tiende a ser una persona estable y dinámica.",
    "Tenderá a crear un ambiente estable, sincero, estimulante, creativo y positivo.",
    "Es una persona que tiende a ser colaboradora y buen integrante del equipo.",
    "Será leal y trabajará arduamente para lograr los objetivos.",
    "Tiende a ser una persona activamente conciliadora. Buscará intermediar y ayudar a conciliar las partes.",
    "Tendrá la visión global y de detalles en los conflictos.",
    "Tiende a ser paciente.",
    "Sabe cómo estimular a los demás para que mantengan el ritmo y estén enfocados.",
    "En la rutina puede descuidar un poco los detalles.",
    "Tiende a respetar las normas y las reglas del equipo.",
    "Tenderá a evitar los conflictos.",
    "Cuando el ambiente es favorable, asumirá un liderazgo dinámico, activo y pionero.",
    "En ambientes tensos tenderá a ser de actitud conciliadora y evitará tomar posiciones.",
    "Puede tener la tendencia de querer agradar a la gente la mayor parte del tiempo.",
    "Tiene energía para trabajar duro por un líder y por una causa.",
    "Su colaboración puede volverse clave dentro del equipo.",
    "Se enfoca en la solución de problemas cuidando a las personas involucradas.",
    "Tiende a tener la capacidad de calmar a aquellos que están fuera de control emocional.",
    "Tiende a ser una persona generosa y bondadosa de una manera activa."
  ],
  49: [
    "Es hábil negociando los conflictos entre las personas. Tenderá a buscar un resultado de ganar-ganar.",
    "Los demás pudieran requerir su apoyo, tanto de forma personal, como en lo profesional.",
    "Busca comprender a los demás y motivar para obtener resultados.",
    "Aporta al equipo creatividad, optimismo y estabilidad.",
    "Le gusta jugar en equipo, se integra con facilidad.",
    "Su enfoque principal es en las relaciones y en construir redes con personas claves.",
    "Tiende a llevarse bien con la gente y ser de fácil trato.",
    "Da importancia a las necesidades de los demás.",
    "Tiende a cuidar su imagen, le agrada que le quieran y le acepten.",
    "Por lo general tiende a ser paciente.",
    "Es una persona sincera que sabe expresar confianza a los demás.",
    "Tiende a tener una actitud colaboradora y generosa.",
    "Tiende a tener buena disposición con los demás.",
    "Será obediente para no generar conflictos, si lo considera necesario.",
    "Apoya al equipo en el esfuerzo por alcanzar las metas.",
    "Tiende a ser gentil a la hora de presionar a los demás para la obtención de resultados.",
    "Le gusta enfocarse en lograr resultados cuando coopera con los demás en un fin común.",
    "Asume riesgos que no afecten su estabilidad.",
    "Puede asumir el reto de progresar en los sistemas y métodos establecidos.",
    "Aporta estímulo y entusiasmo al ambiente.",
    "Puede tender a ser una persona imprecisa en el manejo del tiempo y costarle cumplir con los plazos.",
    "Pudiera distraerse de la productividad y en la toma de decisiones con los requerimientos personales de los demás.",
    "Tiende a hacer énfasis en la consideración por los demás y en evitar molestar a la gente.",
    "Su nivel alto de tolerancia con las personas pudiera perjudicar el rendimiento.",
    "Al liderar tiende a ser una persona orientada al servicio de la gente con miras a lograr resultados.",
    "En ambientes favorables su actitud será dinámica y asumirá un liderazgo activo y pionero.",
    "En ambientes tensos tenderá a ser de actitud conciliadora y evitará tomar posiciones.",
    "Bajo presión puede mostrar mayor sentido de urgencia.",
    "Tiene una habilidad natural para construir relaciones positivas de forma activa.",
    "Puede ser una persona muy buscada para un consejo personal o como coach."
  ],
  50: [
    "Suele estar disponible para ayudar o colaborar con los demás.",
    "Su enfoque principal es hacia las relaciones.",
    "Tiende a integrarse adecuadamente en el equipo y a llevarse bien con la gente.",
    "Es gentil a la hora de presionar a los demás para la obtención de resultados.",
    "Le gusta enfocarse en lograr resultados cuando coopera con los demás en un fin común.",
    "Tiende a asumir riesgos que no afecten su estabilidad.",
    "Puede asumir el reto de progresar en los sistemas y métodos establecidos.",
    "Da importancia a las necesidades de los demás.",
    "Es hábil como coach o mentor dentro del equipo.",
    "Su ritmo para actuar o decidir suele ser pausado.",
    "Tiene interés en que todos se sientan atendidos e involucrados.",
    "Tiende a mantener un buen clima y a evitar los conflictos.",
    "Puede que necesite aprender a administrar los tiempos de entrega en los proyectos.",
    "Puede atascarse en los procesos complejos y con la gente.",
    "Puede ser un líder con orientación al servicio con miras a lograr resultados únicos.",
    "En ambientes favorables su actitud será dinámica y asumirá un liderazgo activo y pionero.",
    "En ambientes tensos tenderá a ser de actitud conciliadora y evitará tomar posiciones.",
    "Puede tener la tendencia de querer agradar a la gente la mayor parte del tiempo.",
    "Es de actitud voluntariosa y colaboradora con el progreso del equipo.",
    "Puede tener dificultades a la hora de impartir disciplina.",
    "Tiende a ser una persona considerada con los demás y no querrá sentir que les molesta.",
    "Es paciente, de fácil trato y de buen humor en general.",
    "Cuando está con su grupo íntimo y más familiar puede mostrar sus molestias.",
    "Pudiera tomar conciencia de su ritmo pausado a la hora de decidir.",
    "Asumirá un poco más de sentido de urgencia cuando la situación lo amerite.",
    "Su actitud puede ser de descuido al analizar información ante la toma de decisiones.",
    "Puede que tome las críticas de forma personal.",
    "Es una persona que tiende a ser positiva en la negociación de los conflictos humanos.",
    "Con su optimismo consigue motivar al equipo para lograr los resultados.",
    "Su énfasis es en los vínculos y en las relaciones.",
    "Muestra menos interés por los resultados y la calidad.",
    "Tiene la habilidad de construir vínculos positivos con las personas activamente.",
    "Su actitud propicia que otros le busquen para que les aconseje y oriente.",
    "Se maneja bien tanto en grupos grandes como en grupos pequeños.",
    "Puede desplazar su rol protagónico a otra persona que requiera de más atención.",
    "Mantiene una actitud equilibrada, tiende a ser estable y con energía.",
    "Suele ser una persona generosa y amigable con los demás.",
    "Permitirá a los otros que piensen a su manera y prueben sus propias ideas.",
    "Tenderá a estimular la sinceridad dentro del grupo.",
    "Suele ser persistente y querrá llevar el proyecto adelante de principio a fin.",
    "Estimulará las ideas creativas dentro del desarrollo del proyecto.",
    "Cuidará el respeto a los sistemas probados.",
    "Su colaboración puede volverse clave dentro del entorno."
  ],
  51: [
    "Su enfoque principal es hacia las relaciones y hacia los sistemas probados.",
    "Tiende a integrarse adecuadamente con los demás y a llevarse bien con la gente.",
    "Suele tener buena disposición con los demás y a dar importancia a las necesidades de los otros.",
    "Su estilo de persuasión está basado, principalmente, en su calidez humana.",
    "Comprende la importancia de los procesos y de la calidad.",
    "Prefiere que le expliquen los detalles antes de asumir los cambios.",
    "Puede ser de tendencia tradicionalista y preferir la estabilidad, al cambio.",
    "Puede ser optimista, con tendencia a confiar.",
    "Suele ser muy confiado con respecto a la capacidad de los demás.",
    "Tiende a estimular al equipo a lograr los estándares de calidad.",
    "Es una persona que suele ser paciente y conciliadora.",
    "Tiende a aportar al ambiente alegría, optimismo y estabilidad.",
    "Suele tener capacidad para trabajar en equipo.",
    "Puede que los demás busquen su apoyo para un consejo o como coach.",
    "Prefiere ser detallista y reflexivo en situaciones complejas.",
    "Tiende a guiarse por los patrones establecidos.",
    "Es una persona que suele ser consistente, consciente y considerada.",
    "Le gusta trabajar con un líder que le aclare muy bien lo que espera de usted.",
    "Puede que prefiera relacionarse con su pequeño grupo de personas cercanas.",
    "En general, tiende a ser de actitud abierta y receptiva.",
    "Puede compartir el protagonismo con otros que requieran de más atención o control.",
    "Es de actitud sincera y expresa confianza a los demás.",
    "Puede ser detallista, de pensamiento analítico y actitud metódica.",
    "Al resolver problemas se dedica, concienzudamente, a enfocarlo desde diversos ángulos.",
    "De tendencia conservadora a la hora de enfrentar los problemas.",
    "Se autocontrola y no se molesta fácilmente, pero cuando lo hace, no cabrán dudas.",
    "No le gusta las confrontaciones.",
    "Es servicial, amigable y alegre.",
    "Cuando se estresa, puede volverse de carácter terco y de actitud cerrada.",
    "Tiende a cuidar su imagen, le agrada que los demás le acepten y le quieran.",
    "Tiende a ser de comportamiento colaborador y generoso.",
    "Cuando lo considera necesario, será obediente para no generar conflictos.",
    "Cuando las decisiones son de rutina es de tendencia sistemática y de ritmo rápido.",
    "Cuando las decisiones son importantes, se comporta de forma cautelosa.",
    "Puede sentir presión ante circunstancias que obliguen tomar decisiones rápidas.",
    "Apoya y colabora con el grupo en el esfuerzo de alcanzar las metas.",
    "Puede ser una persona imprecisa en el manejo del tiempo.",
    "Cuando se siente bajo presión hará todo lo necesario para cumplir los plazos.",
    "Pudiera distraerse del logro de los resultados con los requerimientos personales de los demás.",
    "Hace énfasis en la consideración por los demás y en evitar molestar a la gente.",
    "Su consideración a los demás pudiera retrasarle en la toma de decisiones.",
    "Su énfasis en las personas pudiera dificultarle impartir disciplina.",
    "Puede ser muy tolerante y esto quizá perjudique el logro de los resultados.",
    "Tiene una habilidad natural para construir relaciones positivas.",
    "No decide hasta sentir la seguridad de que la decisión es la correcta."
  ],
  52: [
    "Es una persona que le gusta realizar actividades en equipo, es paciente, colaboradora y de fácil trato.",
    "Tiende a enfocarse en sus tareas y en el cuidado de la calidad.",
    "En condiciones normales, será una persona optimista y amistosa.",
    "En situación de tensión, tiende a ponerse de mal humor.",
    "Tiende a ser detallista.",
    "Prefiere participar para un líder y una causa. Sabe seguir lineamientos.",
    "Puede conseguir dificultades a la hora de establecer prioridades.",
    "Suele ser entusiasta a la hora de afrontar la tarea con organización.",
    "Prefiere evitar los conflictos, los problemas con las personas le incomodan mucho.",
    "Principalmente, es una persona conciliadora.",
    "A la hora de afrontar retos o dificultades lo hace de forma seria y responsable.",
    "Sigue las normas y los patrones, manteniendo una actitud positiva.",
    "Le desagrada la confrontación y la crítica.",
    "Puede tender a ser sensible a las críticas y tomarlas de forma personal.",
    "Prefiere un ambiente estable.",
    "Prefiere que se le dé suficiente tiempo para afrontar el cambio de forma planificada y organizada.",
    "Los cambios repentinos no le gustan.",
    "Tiende a analizar los hechos y la información antes de tomar decisiones.",
    "Establece sus rutinas y va a su ritmo.",
    "Tiende a seguir los métodos de forma consistente.",
    "Tiene la habilidad para conseguir, de buena manera, que los demás cooperen.",
    "Combina ágilmente la paciencia, el razonamiento, la tolerancia y el entusiasmo.",
    "Influencia a los demás de forma entusiasta.",
    "Puede tender a resistirse al cambio.",
    "Su ritmo es más bien pausado, aunque mantiene energía y entusiasmo.",
    "Da una gran importancia a analizar, evaluar y reflexionar.",
    "Bajo presión puede tornarse de actitud reservada e indecisa y atascarse en los detalles.",
    "Es amigable, servicial y aporta jovialidad al ambiente.",
    "Es una persona cautelosa, atenta y detallista.",
    "La lógica, la investigación y la capacidad para comunicarse son sus mejores recursos.",
    "Puede tener buen sentido del humor.",
    "Le gusta seguir las instrucciones y cumplir las normas.",
    "Tiene buena habilidad para manejarse con la gente sin perder de vista la tarea.",
    "Inspira confianza a quienes le rodean por el manejo responsable de su área.",
    "Puede orientar a otros para que aprendan habilidades y destrezas.",
    "Le gusta prepararse para poder hacer bien su tarea.",
    "Enfrenta sus responsabilidades y retos de forma ordenada y planificada."
  ],
  53: [
    "Suele ser una persona cautelosa, atenta y detallista.",
    "Tiende a ser amigable y servicial y en ocasiones aporta jovialidad al ambiente.",
    "Tenderá a respetar los estándares de calidad en sus resultados.",
    "Suele conocer y adaptarse a la cultura de su entorno.",
    "Tenderá a asumir las responsabilidades en las tareas en las que se compromete.",
    "Prefiere no prometer más de lo que puede hacer.",
    "Se centra, usualmente, en mantener la estabilidad, las tradiciones y los métodos establecidos.",
    "Le gusta asumir un proyecto de principio a fin, cuidando de que salga bien desde el inicio.",
    "Tiende a mantener el seguimiento de los patrones y el foco en las personas.",
    "Su ritmo tiende a ser pausado, con poco sentido de urgencia.",
    "Tiende a apegarse a las formas tradicionales de hacer las cosas.",
    "Necesita tiempo para asumir los cambios. Los cambios repentinos le suelen abrumar.",
    "Puede ser detallista y esto le puede complicar el sentido de prioridad.",
    "Puede orientar al grupo en los sistemas, normas y patrones establecidos.",
    "Cuando el clima es positivo, mantiene buen humor, pudiendo ser flexible e informal.",
    "En climas adversos, puede tornarse una persona rígida y malhumorada.",
    "Puede tender a ser una persona defensiva frente a las críticas.",
    "Tenderá a ser una persona cautelosa ante los desconocidos.",
    "Suele tener una actitud conciliadora, generosa, diplomática, amable y servicial.",
    "Suele ser amigable, sobre todo dentro de su grupo de confianza.",
    "Puede orientar a los demás en el uso de los sistemas y los estándares de calidad.",
    "Puede ser un coach en el equipo.",
    "Tiene un buen nivel de pensamiento crítico y de capacidad de análisis.",
    "Es capaz de relajar el ambiente con una cuota proporcionada de buen humor.",
    "Tenderá a ser una persona reflexiva y analítica antes de tomar acción y decidir.",
    "Suele actuar respetando los plazos de entrega.",
    "Le gusta prepararse para poder hacer bien su tarea.",
    "Procurará ser especialista en su área profesional.",
    "Tiende a ser paciente con las personas.",
    "Le gusta progresar en su nivel de conocimiento.",
    "Le gusta aprender constantemente.",
    "Puede tender a ser exigente con el control de la calidad.",
    "Puede tender a enfrentar sus responsabilidades y retos de forma organizada.",
    "Hará seguimiento organizado y persistente de los procesos y normas establecidas.",
    "Le gusta demostrar con sus resultados su nivel de experiencia.",
    "Es consistente con sus habilidades y talentos."
  ],
  54: [
    "Tenderá a buscar un buen nivel de calidad en sus resultados.",
    "Suele conocer y adaptarse a la cultura del entorno.",
    "Tiende a enfocarse en sus tareas y responsabilidades.",
    "Tenderá a asumir sus compromisos.",
    "Buscará no prometer más de lo que puede hacer.",
    "Tiende a analizar, evaluar y reflexionar antes de tomar una decisión.",
    "Suele ser amigable y servicial y aporta jovialidad al ambiente.",
    "Puede comportarse de forma cautelosa, atenta y detallista.",
    "Asume la solución de los problemas, siguiendo los patrones y el foco en las personas.",
    "Su ritmo tiende a ser pausado con poco sentido de urgencia.",
    "Puede tender a ser una persona apegada a las formas tradicionales de hacer las cosas.",
    "Necesita tiempo para asumir los cambios repentinos los cuales pueden abrumarle.",
    "Tiende a ser detallista y esto le puede complicar el sentido de prioridad.",
    "Suele orientar al equipo en las normas y patrones establecidos.",
    "En clima positivo mantiene buen humor, pudiendo ser flexible e informal.",
    "En clima adverso puede tornarse en una persona rígida y malhumorada.",
    "Puede tender a ser una persona defensiva frente a las críticas.",
    "Suele confiar en las habilidades de los demás.",
    "Tenderá a ser una persona cautelosa ante los desconocidos.",
    "Puede tener buenas habilidades para relacionarse con los demás y motivarlos.",
    "Tiende a relacionarse bien sobre todo dentro de su grupo de confianza.",
    "Puede relajar el ambiente con una cuota proporcionada de buen humor.",
    "Suele actuar con un buen nivel de conciencia en los plazos de entrega.",
    "Procura ser especialista en su área.",
    "Tiende a ser paciente con las personas.",
    "Puede ser una persona generosa, amable, diplomática y amigable.",
    "Tiene un buen nivel de pensamiento crítico y de capacidad de análisis.",
    "Puede enseñar a otros y ser un coach dentro del equipo.",
    "Le gusta prepararse para poder hacer bien su tarea.",
    "Puede tender a enfrentar sus responsabilidades y retos de forma planificada.",
    "Su comportamiento puede ser organizado y persistente en el seguimiento de los patrones.",
    "Le gusta demostrar con sus resultados su nivel de conocimiento.",
    "Suele ser consistente en sus habilidades y talentos.",
    "Le gusta progresar en su nivel de conocimiento y experticia."
  ],
  55: [
    "Tiende a enfrentar sus responsabilidades y retos de forma organizada y planificada.",
    "Es persistente en el seguimiento de los patrones establecidos.",
    "Su habilidad para influenciar a los demás se apalanca en su sentido de responsabilidad.",
    "Tiende a ser una persona curiosa a la hora de buscar soluciones a los problemas.",
    "Trata de encontrar la mejor solución.",
    "Tiende a proyectar la imagen de que está disponible para colaborar con los otros.",
    "Tendencia a la reflexión, el análisis y la solución de problemas.",
    "Es consistente en sus habilidades y talentos.",
    "Su fortaleza está en su capacidad y su forma de asumir las tareas.",
    "Elige asumir las tareas en lugar de dejárselas a los demás. Puede ser un líder de acción.",
    "Entrena a sus compañeros para que sean capaces y competentes.",
    "No necesita que le digan lo que debe hacer, ejerce su autonomía y competencia.",
    "Suele comprometerse en los asuntos que pueda cumplir y darles seguimiento.",
    "Verá las oportunidades detrás de las asignaciones retadoras.",
    "Buscará tener un excelente rendimiento cuidando de la calidad.",
    "Es una persona recursiva frente a los problemas.",
    "Tiene un buen nivel de pensamiento crítico y analítico.",
    "Es capaz de ver los detalles y lo global de un problema.",
    "Sabrá darse cuenta de las fallas y las desviaciones rápidamente.",
    "Da valor a los patrones y métodos manteniendo un nivel adecuado de flexibilidad.",
    "Es una persona confiable a la hora de dar resultados y contar con su colaboración.",
    "Pudiera tornarse una persona defensiva frente a las críticas.",
    "Pudiera defenderse al momento de asumir su responsabilidad en las equivocaciones.",
    "Es un estímulo para el grupo cuando enfoca las situaciones desde una visión justa.",
    "Se enfoca en los beneficios para todos.",
    "Logra alinear las metas personales con las de los demás.",
    "Puede acaparar responsabilidades de otros o mucha carga, sin darse cuenta.",
    "En momentos desfavorables, tenderá a refugiarse en lo conocido.",
    "En un ambiente tenso pudiera volverse de actitud evitativa frente al conflicto.",
    "Tiende a cooperar con los demás y asumir tareas que no son suyas.",
    "Suele requerir garantías de que la tarea se va a hacer bien hecha.",
    "Es una persona ética en su actuar y le gusta que los demás también lo sean."
  ],
  56: [
    "Tiende a enfrentar sus responsabilidades y retos de forma organizada y planificada.",
    "Suele ser persistente en el seguimiento de los métodos establecidos.",
    "Mantiene un buen ritmo en la obtención de resultados.",
    "Su enfoque está en el ritmo estable y la obtención de resultados de calidad.",
    "Busca aplicar nuevas ideas cuidando siempre el control de calidad.",
    "Es consistente en sus habilidades y talentos.",
    "Le gusta que le reconozcan, de forma ocasional y sincera, su tarea bien hecha.",
    "Es realista en cuanto a su enfoque en las normas y modelos.",
    "Utilizará su capacidad creativa en la solución de problemas y en los resultados.",
    "Suele tener un sentido equilibrado de la competencia.",
    "Es detallista y se enfoca en que no existan aspectos descuidados en una tarea.",
    "Es paciente con los demás e invita a la reflexión de forma activa.",
    "El cuidado de los niveles de calidad en los resultados le son muy importantes.",
    "Es sensible al ritmo del entorno y capaz de adaptarse enfocándose en los resultados.",
    "Le gusta que le permitan privacidad para pensar en las tareas, en ideas y soluciones.",
    "Tiende a enfrentar retos y desafíos con objetividad e innovación.",
    "Suele ser una persona ordenada, limpia y organizada para trabajar.",
    "Tiende a ser estable y le gusta que le den el tiempo necesario para asumir los cambios.",
    "Se llenará de valor para enfrentar lo inevitable.",
    "Pudiera tener un poco más de autoconfianza.",
    "Pudiera acelerar un poco más el ritmo en la toma de decisiones.",
    "Su comportamiento tiende a ser meticuloso y le gusta tener toda la información para decidir.",
    "Tiende a manejar los detalles necesarios en cuanto a su nivel de responsabilidad.",
    "Pudiera manejarse con más libertad creativa.",
    "Es una persona que tiende a ser equilibrada entre su naturaleza humanista y el logro de resultados.",
    "Juega bien en equipo, es capaz de apoyar y colaborar.",
    "Tiende a ser una persona que cuida de que los métodos y normar se cumplan.",
    "En general, tiende a ser de actitud generosa.",
    "Prefiere participar con un líder que esté disponible cuando presente dudas para decidir.",
    "Mira con buen ojo la seguridad en las actividades.",
    "Es prioridad, en el logro de los objetivos, cuidar la calidad y la seguridad en la ejecución.",
    "Tiende a invertir tiempo en analizar información y evaluar los hechos.",
    "La evaluación quizá retrase la toma de decisiones, pero en pro de garantizar la calidad.",
    "Quiere ser parte de quienes motivan a las personas a cumplir con las tareas.",
    "Cuando el clima es favorable, tiende a ser de actitud introvertida.",
    "Cuando el clima es tenso, puede tender a la extroversión y demostrar su mal humor.",
    "Es consciente de los tiempos de entrega y buscará cumplir con los plazos.",
    "Pudiera ser más entusiasta y de actitud receptiva, con personas diferentes a usted.",
    "Su comportamiento pudiera ser un poco inflexible y agresivo cuando está bajo estrés.",
    "Puede tender a ser una persona indecisa.",
    "Es exigente con usted y con los demás al cumplir con normas e instrucciones."
  ],
  57: [
    "Tiende a enfrentar sus responsabilidades y retos de forma organizada y planificada.",
    "Es persistente en el seguimiento de los patrones para la obtención de los resultados.",
    "Suele ser consistente en sus habilidades y talentos.",
    "Le gusta que le reconozcan, de forma ocasional y sincera, su tarea bien hecha.",
    "Es realista en cuanto a su enfoque en las normas, métodos y procedimientos.",
    "Tiende a ser detallista.",
    "Suele mantener su enfoque en que no existan aspectos descuidados en un proyecto.",
    "Tiende a cuidar el cumplimiento de los estándares de calidad.",
    "Es una persona sensible al ritmo del entorno.",
    "Es capaz de adaptarse al ritmo del entorno para enfocarse en los resultados.",
    "Prefiere participar con su pequeño grupo de personas cercanas.",
    "Bajo estrés o tensión, su actitud pudiera tornarse un poco rígida, inflexible y agresiva.",
    "Puede tender a ser una persona indecisa.",
    "Es exigente con usted y con los demás en el cumplimiento de las normas, la calidad y los procesos.",
    "Tiende a motivar a los demás a que presten atención a los indicadores y a los detalles.",
    "Promueve que los demás investiguen y analicen toda la información.",
    "Tiende a estimular el uso de la lógica y la razón, dejando de lado las emociones.",
    "En climas favorables, es una persona que tiende a ser diplomática y bien educada.",
    "Tiende a no gustarle las confrontaciones.",
    "Quiere ser parte de quienes motivan a las personas a cumplir con las tareas.",
    "Cuando el clima es favorable, tiende a ser más de actitud introvertida.",
    "En climas tensos, puede tender a la extroversión y demostrar su mal humor.",
    "Tiende a ser firme al cuidar los niveles de calidad, en el logro de los resultados.",
    "Prefiere participar en un ambiente que entienda la importancia de hacer la tarea bien desde el primer intento.",
    "Es una persona que tiende a ser meticulosa y le gusta tener la información y los detalles.",
    "Tiende a informarse de lo necesario en cuanto a su nivel de responsabilidad.",
    "Pudiera manejarse con más de libertad creativa.",
    "Tiende al equilibrio entre su naturaleza humanista y su motivación al logro de resultados.",
    "Participa bien en equipo, es capaz de apoyar y colaborar.",
    "Pudiera ser una persona un poco más expresiva y abierta con personas diferentes a usted.",
    "Tiende a ser estable y prefiere que le den el tiempo para asumir los cambios.",
    "Se llenará de valor para asumir cambios y enfrentar lo inevitable.",
    "Pudiera tener un poco más de confianza en usted y en sus habilidades.",
    "Pudiera acelerar un poco más el ritmo en la toma de decisiones.",
    "Tiende a ser una persona experta en lo que hace.",
    "Enfrenta retos y desafíos con objetividad e innovación.",
    "Es una persona ordenada, limpia y organizada."
  ],
  58: [
    "Enfrenta sus responsabilidades y retos de forma organizada y planificada.",
    "Suele ser persistente en el seguimiento de los patrones establecidos.",
    "Tiende a ser consistente en sus habilidades y talentos.",
    "Le gusta que le reconozcan, de forma ocasional y sincera, su tarea bien hecha.",
    "Tiende a ser realista en cuanto a su enfoque en los normas y procedimientos.",
    "Se enfoca en los sistemas, las normas, la calidad y los resultados.",
    "Suele ser detallista y se centra en que no existan aspectos descuidados en un proyecto.",
    "Cuida de que se logren los estándares de calidad.",
    "Tiende a ser una persona sensible al ritmo del entorno.",
    "Es capaz de adaptarse al ritmo del entorno para enfocarse en los resultados.",
    "Tiende a ser estable y le gusta que le den el tiempo para asumir los cambios.",
    "Pudiera tener un poco más de confianza en usted y en sus habilidades.",
    "Pudiera acelerar un poco más el ritmo en la toma de decisiones.",
    "Tiende a ser una persona meticulosa y le gusta analizar mucha información y datos para tomar decisiones.",
    "Analizar puede retrasar la toma de decisiones, pero será en pro de garantizar la calidad.",
    "Le gusta manejar los detalles necesarios en cuanto a su nivel de responsabilidad.",
    "Tiende al equilibrio entre su naturaleza humanista y su motivación al logro de resultados.",
    "Juega bien en equipo, es capaz de apoyar y colaborar.",
    "Puede ser una persona percibida como experta por los demás.",
    "En general, tiende a ser una persona generosa.",
    "Mira con buen ojo contar con seguridad en las actividades.",
    "En el logro de los objetivos prioriza el cuidado de la calidad y la seguridad en la ejecución.",
    "Prefiere realizar sus tareas y analizar la información en privado, sin interrupciones.",
    "Prefiere participar en su pequeño grupo de personas cercanas.",
    "Tiende a ser de actitud introvertida y una persona poco expresiva.",
    "Será firme al observar los niveles de calidad en el logro de los resultados.",
    "Suele ser consciente de los tiempos de entrega.",
    "Puede ser de ritmo lento en la toma de decisiones y en asumir los cambios.",
    "Pudiera ser una persona más expresiva y abierta con quienes son diferentes a usted.",
    "Tiene la tendencia a tornarse de actitud rígida e inflexible.",
    "Puede tender a ser una persona indecisa.",
    "Es exigente con usted y con los demás al cumplir con las normas y los procesos.",
    "Es una persona que tiende a ser ordenada, limpia y organizada."
  ],
  59: [
    "Tiende a ser una persona realizadora con ideas creativas.",
    "Le gustan las personas que están motivadas al cambio y a la excelencia.",
    "Le agrada el logro de resultados de calidad, con sentido de urgencia.",
    "Tiende a analizar información y hechos para decidir en situaciones complejas.",
    "Su tendencia al análisis podría retrasar el ponerse en acción.",
    "Suele no prestarle mucha atención al aspecto emocional.",
    "Bajo presión puede parecer una persona fría y mostrarse de actitud tosca y retadora.",
    "En situaciones favorables buscará resultados y soluciones de calidad.",
    "Trata de mantener un clima de entusiasmo en situaciones favorables.",
    "Puede ser, en ocasiones, perfeccionista y difícil de complacer.",
    "Puede tener dificultades a la hora de priorizar.",
    "Puede ser que tenga sentido de urgencia en la toma de decisiones y en la acción, en la cotidianidad.",
    "En situaciones extraordinarias se toma más tiempo y cautela para decidir.",
    "Pudiera hacer algún chiste inesperado que relajara el ambiente.",
    "Integra, rápidamente, teorías e información diversa, para solucionar problemas.",
    "Es una persona que puede ser muy recursiva.",
    "Tiene un buen nivel de pensamiento analítico, crítico y visión de futuro.",
    "Puede dudar entre tener un ritmo rápido o ser perfeccionista.",
    "Puede tender a tomar las críticas de manera personal.",
    "Tiene un lado creativo e innovador que le gusta explorar.",
    "Tenderá a ser eficiente asumiendo una tarea.",
    "Podrá aportar ideas novedosas comportándose de forma meticulosa.",
    "Tenderá a mantener altos niveles de calidad.",
    "Se puede implicar en muchos asuntos a la vez y cuidará de cumplir con los plazos.",
    "Pudiera costarle acoplar sus tendencias de actuar con rapidez y controlar la calidad.",
    "Tenderá a ofrecer estímulo a la gente para participar en el equipo en pro de la meta.",
    "Le gustan los cambios y los retos que le permitan demostrar sus capacidades.",
    "Le gusta ser parte de actividades específicas y formar grupo con gente experta.",
    "No se desvía de lo importante a la hora de analizar sus decisiones.",
    "Participa de forma consistente y se compromete con el logro de resultados de calidad.",
    "Tiende a calcular los riesgos de forma objetiva.",
    "Puede usar su capacidad creativa para encontrar ideas pioneras.",
    "Puede hacer aportes que favorezcan los métodos y los modelos establecidos.",
    "Tenderá a revisar mucha información, a buen ritmo, para solucionar los problemas.",
    "Es una persona motivada al cambio que impulsa el éxito del equipo.",
    "Su ritmo es su principal factor de influencia en el grupo a la hora de ponerse en acción.",
    "Le gusta asumir tareas que se realicen meticulosamente.",
    "Le gusta que las tareas salgan bien al primer intento.",
    "Prefiere que le aclaren lo que esperan de su desempeño."
  ],
  60: [
    "Tiende a tener interés en la obtención de resultados primero y en la gente después.",
    "Tiene tacto y diplomacia a la hora de dirigir a la gente.",
    "Puede tener un enfoque claro y sustentado, orientado a las soluciones.",
    "Tiende a ser una persona diplomática a la hora de manejar el humor con los demás.",
    "Suele ser eficiente asumiendo una tarea de principio a fin.",
    "Podrá ser una persona meticulosa manteniendo los niveles de calidad.",
    "Se pudiera implicar en muchos asuntos a la vez y tenderá a cumplir con los plazos.",
    "Bajo presión puede tender a concentrarse en los detalles y ser muy perfeccionista.",
    "Bajo estrés puede perder el foco de lo importante.",
    "En situaciones de tensión puede paralizarse a la hora de tomar decisiones.",
    "Frente a los conflictos tenderá a analizar los hechos.",
    "Tenderá a comprender el conflicto y negociar entre las partes, buscando que todos ganen.",
    "Tiende a colocar el foco en el logro de resultados de calidad.",
    "Puede que sobre exija a los demás más de lo adecuado.",
    "Tenderá a ofrecer estímulo para participar en el equipo en pro de la meta común.",
    "Le animan los proyectos especializados y la compañía de gente experta.",
    "En situaciones normales tendrá sentido de urgencia.",
    "En situaciones complejas tenderá a darse el tiempo para evaluar y analizar.",
    "Puede tender a ser una persona apegada a la agenda y depender de la planificación.",
    "En ocasiones, tendrá energía al impulsar cambios y novedades.",
    "En general respetará las normas, los métodos y los estándares.",
    "Puede tener la tendencia a ser una persona defensiva ante las críticas.",
    "Puede tener la capacidad para asumir tareas y metas complejas.",
    "Puede crear, para el equipo, un ambiente de retos y de entusiasmo.",
    "Al decidir pondrá los hechos y las necesidades de la gente en la balanza.",
    "Tendrá el carácter para asumir riesgos.",
    "Busca cumplir con la obtención de resultados de calidad.",
    "Suele ser una persona analítica y creativa.",
    "Tiende a tomar riesgos calculados."
  ],
  61: [
    "Tiende a equilibrar su atención, entre la gente, las tareas y la obtención de resultados.",
    "Suele demostrar un buen nivel en su área de conocimiento.",
    "Es capaz de conectarse con la gente.",
    "Puede tener la capacidad de ejercer influencia en los demás.",
    "Puede ser una persona considerada y al mismo tiempo exigente, pero sin incomodar.",
    "Negocia conflictos entre las personas buscando resultados de ganar-ganar.",
    "Tiene un interesante equilibrio en el manejo de las relaciones personales.",
    "Establece balance entre las normas, los procedimientos y las relaciones personales.",
    "Puede tender a asumir más tareas de las que puede cumplir.",
    "Se auto exige el respeto por el cumplimiento de los plazos.",
    "Su acercamiento a las personas tiene un matiz diplomático y directo y una actitud positiva.",
    "Aporta motivación al cumplimiento de las tareas y obtención de resultados.",
    "Tiende a persistir para encontrar la mejor solución a los problemas con entusiasmo.",
    "En las actividades suele aportar la energía suficiente para llevarlas hasta el final con éxito.",
    "Puede ser una persona extrovertida y sociable y al mismo tiempo analítica y reflexiva.",
    "Puede lograr que los demás se proyecten en el logro de resultados exitosos.",
    "Los demás tienden a valorar su criterio por su nivel de conocimiento y trato amigable.",
    "Normalmente, será una persona extrovertida, enérgica, amigable y sociable.",
    "Bajo tensión puede ser firme y de mal carácter.",
    "Su foco en la calidad, en los resultados y en la gente, logra crear un clima retador.",
    "Logra crear un ambiente eficiente y amigable para el equipo.",
    "Puede ejercer un seguimiento cercano de las tareas delegadas a los otros.",
    "Tomará en cuenta a los demás, los hechos y la información, para tomar decisiones.",
    "Tiende a sentir motivación frente a los retos y los desafíos.",
    "Perder una propuesta le genera temor.",
    "Le gusta tomar la iniciativa al afrontar tareas complejas y seguir los niveles de calidad.",
    "Su actitud puede ser muy positiva tanto con personas conocidas como con extraños.",
    "Estimula a los demás para que sigan las normas y los modelos establecidos.",
    "Bajo tensión motivará al grupo a resolver el conflicto.",
    "En ambientes tensos motivará el mantenimiento del nivel de desempeño en alto.",
    "Puede ser una persona precisa y exacta que asume riesgos calculados.",
    "Sabe cómo lograr resultados a través de la gente.",
    "Puede mostrar un optimismo realista a la hora de asumir riesgos o enfrentar retos.",
    "Tiene un sentido de urgencia moderado.",
    "Frente a decisiones complejas tiende a analizar en exceso.",
    "Puede ser de actitud activa en la actualización de los métodos y normas.",
    "Su diplomacia y su optimismo se combinan con su energía para empujar al equipo.",
    "Estimula a la gente a la acción y al cumplimiento de las tareas.",
    "Puede demostrar un buen equilibrio entre la lógica, la razón, la emoción y la intuición.",
    "Pudiera ser algunas veces una persona impulsiva y optimista para decidir.",
    "En ocasiones, se puede detener mucho en el análisis de información.",
    "Tenderá a cuidar, de forma activa y enérgica, el cumplimiento de los niveles de calidad.",
    "Habitualmente, maneja sus sentimientos positivamente y orientados al resultado."
  ],
  62: [
    "Tenderá a dar seguimiento a las tareas y cuidará que se controle la calidad y los detalles.",
    "Suele tener tacto y diplomacia a la hora de dirigir a la gente.",
    "Sabe poner el foco en los detalles, en los patrones y en la calidad.",
    "Tiende a mantener el entusiasmo en el equipo para el cuidado de la calidad.",
    "Puede tener un enfoque claro y sustentado orientado a las soluciones.",
    "Pudiera ser un referente dentro del entorno por su nivel de conocimiento y su trato jovial.",
    "En ocasiones puede que tenga una actitud evasiva frente a los conflictos con las personas.",
    "Pudiera tender a la evitación en vez de ir al grano.",
    "Cuida de la calidad y de las necesidades de las personas.",
    "Tiende a ser una persona analítica, enfocada en producir ideas funcionales y pioneras.",
    "Bajo presión tenderá a ser una persona un poco rígida con la planificación.",
    "Sus principales fortalezas como líder son su intelecto y su positivismo.",
    "Puede tender a ser muy optimista con las capacidades de las personas en el grupo.",
    "En ocasiones desfavorables puede tornarse difícil de complacer y perfeccionista.",
    "Pudiera tomarse las críticas de forma personal.",
    "Tiende a transmitir optimismo al equipo creando un ambiente de apertura.",
    "Tiende a analizar información para decidir, tomando en cuenta, activamente, a las personas.",
    "Busca que todos tengan claro las metas y los objetivos.",
    "Tenderá a participar en equipo para impulsar los resultados con calidad.",
    "Suele ser una persona que promueve el uso adecuado de los procesos y protocolos.",
    "Es hábil negociando los conflictos de la gente con justicia.",
    "En ocasiones será de actitud enérgica al impulsar cambios y novedades.",
    "En general, respetará las normas, los modelos y los patrones.",
    "Puede que tenga un ritmo moderado a la hora de tomar acción y decisiones.",
    "Tiende a favorecer el pensamiento analítico con el foco en producir ideas funcionales e innovadoras.",
    "Sabe lidiar con muchos compromisos al mismo tiempo.",
    "Tenderá a no perder de vista la administración adecuada del tiempo.",
    "Tiende a tomar riesgos calculados."
  ],
  63: [
    "Es de ritmo equilibrado para lograr resultados y tomar decisiones.",
    "Su ritmo natural se equilibra entre ni mucha urgencia ni mucha pausa en lo que hace.",
    "Suele ser una persona que proyecta conocimiento en su área de especialidad.",
    "Tiende a ser una persona buscada por los demás para que les asesore.",
    "Es más bien de actitud modesta.",
    "Tiende a manejar un fino sentido del humor resaltando la jovialidad, diplomacia y modales.",
    "Puede relajar ambientes tensos con su humor.",
    "En ocasiones puede excederse en los compromisos que asume.",
    "Puede ser muy detallista y perfeccionista.",
    "Tiende a buscar que los demás sean muy meticulosos.",
    "Prefiere crear un clima amigable y no generar controversias.",
    "Tiende a ser optimista al juzgar a los demás.",
    "Tiende a tener una buena actitud frente al trabajo de equipo.",
    "Se orienta a la calidad y a las personas y es capaz de llevarse bien con la mayoría.",
    "En condiciones normales maneja un buen sentido del humor.",
    "Cotidianamente puede atender situaciones difíciles con diplomacia.",
    "Ante el estrés o presión puede responder con malhumor y terquedad.",
    "Tiende a ser una persona analítica y reflexiva.",
    "Suele mantener la diplomacia y generar entusiasmo en los demás.",
    "Tenderá a rodearse de personas con un perfil parecido al suyo.",
    "Puede volverse de actitud rígida con lo planificado.",
    "Tiende a defenderse demasiado ante las críticas.",
    "Aporta confianza al grupo con su optimismo y entusiasmo.",
    "Mantiene la vista puesta en los plazos.",
    "Tiende a responder a tiempo con todos sus compromisos.",
    "Le gusta asumir retos complejos.",
    "Tiende a motivar al grupo a integrarse en pro de la meta común.",
    "Prefiere alcanzar el éxito junto al equipo en las actividades que emprende.",
    "Tiende a ser una persona analítica, lógica y de pensamiento crítico y es capaz de equilibrar esto con el aspecto emocional."
  ],
  64: [
    "Suele mantener equilibrio entre el cuidado de la calidad como de las personas.",
    "Cuida de los procesos, se relaciona y toma decisiones a un ritmo armonioso.",
    "Su ritmo se equilibra, sin mucha urgencia ni mucha pausa.",
    "Es más bien de actitud modesta.",
    "Tiene la habilidad de llevarse bien con una diversidad de gente.",
    "Tiende a ser una persona activa y a entusiasmar a la gente a cumplir con las normas.",
    "Tiende a entusiasmar para llevar adelante los modelos y los estándares de calidad.",
    "Puede beneficiarse de la diversidad si se mantiene alerta a su tendencia a rodearse de personas muy parecidas a usted.",
    "Aporta optimismo al equipo y crea conciencia sobre los riesgos y los controles necesarios.",
    "En condiciones normales maneja un buen sentido del humor.",
    "Puede atender situaciones difíciles con diplomacia.",
    "Ante el estrés o presión puede tornarse difícil y de mal humor.",
    "Tiende a ser una persona analítica y reflexiva.",
    "En ocasiones, puede excederse en los compromisos que asume.",
    "Prefiere crear un clima amigable y no generar controversias.",
    "Es una persona buena relacionándose, sabe mantener la diplomacia.",
    "Genera entusiasmo en los demás y juega bien en equipo.",
    "Es de actitud motivadora y logra que se enfoquen en lo importante de una tarea.",
    "Es capaz de hacerle ver a los demás las tareas claves para el éxito.",
    "Le gusta ser una persona experta y proyectar esa imagen en el equipo.",
    "Puede tener habilidades como coach, guía o para asesorar en tareas específicas.",
    "Puede ser una persona crítica de los modelos y la calidad.",
    "Puede que experimente con ansiedad la posibilidad de perder una oportunidad.",
    "Eventualmente pudiera obviar que incomoda a otros más reservados con su optimismo.",
    "Le puede gustar vincularse con las personas a través de sus quehaceres.",
    "Tiende a ser una persona detallista y perfeccionista.",
    "Puede que se sienta vulnerable frente a los cambios abruptos.",
    "Tiende a persuadir con su optimismo, diplomacia e inclinación a la calidad.",
    "Guía al grupo al logro del éxito con excelencia.",
    "Puede tomar una acción y lograr, con su seguimiento, que se desarrolle exitosamente.",
    "Puede ser optimista al evaluar a las personas.",
    "Puede debatirse frente a dos tendencias opuestas al evaluar situaciones.",
    "Puede ser una persona pesimista y crítica y en ocasiones optimista y de actitud confiada.",
    "Suele dirigir con tacto comprendiendo las necesidades de los demás y del grupo.",
    "Tiene la habilidad de mantener un equilibrio entre la lógica, la razón y las emociones.",
    "Puede ser de pensamiento analítico y objetivo cuando es necesario.",
    "No pierde de vista las implicaciones emocionales."
  ],
  65: [
    "Puede tener la tendencia a asumir más de lo que puede cumplir.",
    "Se auto exigirá el respeto de los plazos.",
    "Su acercamiento a las personas tiene un matiz diplomático y una actitud positiva.",
    "Tiende a ser una persona equilibrada en su ritmo de productividad.",
    "Logra resultados, asume cambios y toma decisiones a un ritmo equilibrado.",
    "Su ritmo se equilibra, ni mucha urgencia ni mucha pausa.",
    "Tiene una interesante armonía en el manejo de las relaciones personales.",
    "Establece balance entre las normas, patrones y las relaciones personales.",
    "Aporta motivación en el cumplimiento de los procesos y el cuidado de los detalles.",
    "Es capaz de negociar los conflictos entre las personas con una visión de ganar-ganar.",
    "En los proyectos aporta la energía suficiente para llevarlos hasta el final con éxito.",
    "Puede ser una persona extrovertida y sociable, así como analítica y reflexiva.",
    "Influye en los demás con su encanto personal y su buen nivel de conocimiento.",
    "Es entusiasta y optimista de forma sincera.",
    "Puede ser perfeccionista y detallista en lo que hace.",
    "A veces puede actuar con sentido de urgencia a la hora de tomar decisiones.",
    "Puede tornarse a un ritmo pausado para evaluar toda la información disponible.",
    "Puede ser una persona terca, pudiendo valerse de su simpatía para no incomodar.",
    "Dirige con tacto comprendiendo las necesidades de los demás.",
    "Puede ser una persona atenta y cautelosa al asumir riesgos.",
    "Asume los cambios con mentalidad abierta si le dan el tiempo para adaptarse.",
    "En un ambiente favorable, tiende a confiar en los demás y en sus propias habilidades.",
    "En ambientes hostiles, es una persona más bien cautelosa.",
    "Pudiera ser una persona que se siente amenazada ante las críticas.",
    "Pudiera tener la inclinación de tomarse las críticas de forma personal.",
    "Tiene tacto para dirigir a los demás en sus tareas y en el cumplimiento de las normas.",
    "Puede asumir tareas especializadas siendo clave en la motivación del equipo.",
    "Le cuesta asumir las derrotas y errores en las tareas.",
    "Prefiere que le ofrezcan toda la información disponible antes de tomar decisiones.",
    "Le gusta considerar toda la información disponible sin volverse una persona obsesiva.",
    "Puede moverle el reconocimiento de los demás por la calidad de lo que hace.",
    "Puede generar influencia en su área de especialidad."
  ],
  66: [
    "Puede tener su foco tanto en la calidad como en las personas.",
    "Tiene la habilidad de llevarse bien con una diversidad de gente, sobre todo con las personas cercanas.",
    "Tiende a ser paciente con las personas.",
    "Es especialista en su área y muy consciente del nivel de calidad.",
    "Cotidianamente tenderá a enfocarse en sus tareas y responsabilidades rutinarias.",
    "Es más bien de actitud modesta.",
    "Puede tender a sentir los cambios repentinos como amenazantes.",
    "Suele ser una persona crítica de las reglas y de la calidad.",
    "Tiende a sentir ansiedad cuando considera que puede perder una oportunidad.",
    "Prefiere ser especialista en su área de conocimiento.",
    "En ambiente tenso o desfavorable su carácter puede ser malhumorado y terco.",
    "Puede dar la imagen de estar demasiado disponible para los demás.",
    "En ocasiones, tenderá a evitar a las personas para poder realizar las tareas en privado.",
    "Tiende a ser agente de armonía en la rutina.",
    "Puede tener la habilidad para asumir varias tareas complejas al mismo tiempo.",
    "Puede mantener un ambiente estable e integrado.",
    "Suele asumir compromisos que puede cumplir.",
    "Tiende a ser hábil entrenando a las personas de forma positiva y empática.",
    "Dirige con tacto comprendiendo las necesidades de los demás y del entorno.",
    "Puede tornarse de actitud confiada en las habilidades de los demás.",
    "Tiende a mantenerse progresando en su área de especialización.",
    "Es una persona que suele analizar hechos e información antes de decidir.",
    "Tomará en cuenta su intuición y a los demás como elementos claves para decidir.",
    "Tiende a ser una persona precisa y exacta, manteniendo su sensibilidad hacia los demás.",
    "En ocasionas pudiera ser muy entusiasta con sus propias ideas.",
    "Puede volverse de actitud defensiva frente a las críticas.",
    "Es muy consciente del factor tiempo, más puede atascarse por tender al perfeccionismo.",
    "Puede ser tradicionalista y tender a apegarse al pasado.",
    "Puede verse en dificultades a la hora de priorizar, por su tendencia a ser detallista.",
    "Tiende a tener altas expectativas en cuanto a la calidad.",
    "Motivará a los demás a que se alineen con sus altas expectativas de calidad.",
    "Requiere de tiempo y planificación para asumir los cambios.",
    "Su ritmo puede ser considerado un poco pausado.",
    "Tenderá a enfocarse en obtener resultados de calidad.",
    "Le gusta ayudar al progreso de los otros, orientándolos a desarrollarse.",
    "Tiende a lograr buenos niveles de pericia dentro del equipo."
  ],
  67: [
    "Tiende a gustarle impulsar el cambio luego de haber calculado los riesgos.",
    "Mantiene el cuidado por los sistemas que funcionan adecuadamente.",
    "Busca perfeccionar aquellos procesos que pueden mejorar.",
    "Se enfoca principalmente en la acción y en la tarea.",
    "Es una persona realizadora que cuida el buen clima del entorno.",
    "Su enfoque principal es en los procesos y la calidad.",
    "Se enfoca en la calidad y la obtención rápida de resultados de forma ordenada y reflexiva.",
    "Puede usar su creatividad, análisis y cálculo de riesgos para resolver los problemas o retos.",
    "Tiende a apegarse a las normas y patrones.",
    "Es capaz de proponer novedosas maneras de afrontar los problemas o las tareas.",
    "Tiene el perfil de una persona que es experta en lo que hace.",
    "Trabaja duro para conseguir los resultados rápidamente.",
    "Mantiene el respeto por los patrones establecidos y los niveles de calidad.",
    "Frente a las relaciones interpersonales puede ser más bien una persona fría y distante.",
    "Tiene actitudes amables. Busca ser una persona diplomática y objetiva.",
    "Tiende a ser una persona perfeccionista y detallista.",
    "Suele analizar toda la información disponible y los hechos antes de decidir.",
    "Analizar puede chocar con su inclinación a ser impaciente y querer resultados rápidos.",
    "Tenderá a analizar las soluciones de problemas nuevos manteniendo sentido de urgencia.",
    "Quizá le cueste establecer prioridades.",
    "Puede tener períodos de acción rápida y otros de ritmo pausado dedicados a la reflexión.",
    "Puede ser capaz de análisis rápidos tomando decisiones muy enfocadas y eficientes.",
    "Especial interés por la productividad, manteniendo altos estándares.",
    "Mantiene los niveles de calidad, tratando de cuidar el buen clima en el ambiente.",
    "Le gusta que el contacto con la gente sea limitado.",
    "Prefiere relacionarse con personas de confianza y cercana.",
    "Tiende a prestar mucha atención al orden y a la disciplina.",
    "Procura que sus ambientes estén ordenados y limpios.",
    "Puede ser de ideas creativas a la hora de proponer nuevos modelos y patrones.",
    "Le gusta innovar para hacer modelos más eficientes, provechosos o con mayor calidad, aunque también le agrada los métodos tradicionales que funcionan.",
    "Es particularmente una persona cuidadosa de las reglas y las normas.",
    "Aun siendo cuidadoso de las normas, en algunos casos puede ser flexible.",
    "Su mirada está en el futuro y en la forma en cómo establecer normas y patrones adecuados.",
    "Le gusta crear soluciones novedosas y eficientes a los problemas y a la producción.",
    "Su forma de tomar decisiones es manteniendo un enfoque cuidadoso, analítico y preciso.",
    "Ante los asuntos rutinarios toma decisiones con mucha rapidez.",
    "Ante temas nuevos o importantes tomará más tiempo para analizar y revisar opciones."
  ],
  68: [
    "Tiende a ser una persona práctica, metódica, incisiva y objetiva.",
    "Prefiere aplicar protocolos probados para solucionar problemas nuevos, aunque en ocasiones puede apelar por soluciones creativas.",
    "Tiende al cuidado de la calidad y de los resultados manteniendo un buen clima en el entorno.",
    "Es capaz de proponer nuevas formas de afrontar los problemas o las tareas.",
    "Tiene el perfil de una persona que conoce lo que hace.",
    "Tiende a esforzarse para conseguir los resultados rápidamente.",
    "Mantiene el respeto por las normas establecidas y la calidad en un ambiente amable.",
    "Le gusta hacer bien su tarea, y de ser posible que salga bien desde la primera vez.",
    "Tiende a pensar que el tiempo es dinero y que es un recurso que debe saberse usar.",
    "Tiende a ser de actitud reflexiva y capaz de aceptar sus errores.",
    "Cuando su enfoque está puesto en una tarea no le gusta que le interrumpan.",
    "Tomará el tiempo necesario para el cuidado de la calidad sin descuidar los resultados.",
    "Trabaja bien en equipo.",
    "Se integrará fácilmente con personas que comprendan la importancia de la excelencia.",
    "Su actitud tiende a ser respetuosa de los modelos, las normas y las reglas.",
    "Puede flexibilizarse si el ambiente se lo exige.",
    "Tiende a ser una persona disciplinada, detallista y perfeccionista.",
    "Puede ser firme bajo presión, tratando de mantener la diplomacia y los buenos modales.",
    "Tiende a ser una persona honesta y modesta.",
    "En ocasiones, puede tornarse en una persona firme e impositiva con sus posiciones.",
    "Frente a la búsqueda de soluciones será de tendencia práctica y metódica.",
    "Cuando el patrón establecido no funciona se abrirá a probar nuevas ideas.",
    "Al tomar decisiones tenderá a calcular los posibles riesgos.",
    "En ocasiones puede volverse vacilante antes de tomar una decisión.",
    "No se detiene frente a un reto, por lo general los enfrenta con responsabilidad.",
    "Mantiene el foco en los métodos probados.",
    "Se auto exige y exige a los demás el mantenimiento de los niveles de calidad.",
    "Busca que los modelos establecidos no sean un obstáculo para alcanzar los resultados.",
    "En situaciones de estrés puede que asuma posiciones firmes e impositivas, aunque también puede ser vacilante para decidir.",
    "Tiende a asumir acciones autónomas cuando eso mejora los resultados de calidad.",
    "No le gusta que interfieran en su planificación.",
    "Tiende a ser vulnerable frente a la crítica y los altos riesgos.",
    "Suele ser una persona reservada y de actitud desconfiada con las personas que no conoce.",
    "Evalúa muy bien a las personas antes de confiar en ellas.",
    "Será una persona muy decidida en situaciones rutinarias.",
    "Frente a nuevos retos puede que se distraiga analizando antes de tomar una decisión.",
    "En su entorno proyecta la imagen de una persona distante, formal y fría.",
    "De actitud lógica y racional y al mismo tiempo intuitiva y perceptiva.",
    "Tiene habilidad para el pensamiento crítico y analítico.",
    "En general, tiene una actitud resolutiva frente a los problemas."
  ],
  69: [
    "Prefiere poder aplicar modelos y patrones en la solución de problemas nuevos.",
    "Se enfoca principalmente en la acción y en la tarea.",
    "Básicamente es una persona realizadora.",
    "Su enfoque está en los procesos, en la calidad y en la obtención rápida de resultados.",
    "Tiende a mantener un buen clima en su entorno.",
    "Le gusta asumir nuevos retos analizando la información y hechos disponibles.",
    "Prefiere participar en donde se relacione con poca gente.",
    "Pone una buena cuota de creatividad y de análisis para afrontar los retos.",
    "Suele calcular los riesgos para resolver los problemas o diseñar modelos.",
    "Tiende a influir en su entorno.",
    "Suele cumplir con las normas y los patrones para beneficiar el logro rápido de resultados.",
    "Es capaz de proponer novedosas maneras de afrontar los problemas o las tareas.",
    "Tiene el perfil de una persona experta en lo que hace.",
    "Tiende a esforzarse para conseguir los resultados rápidamente.",
    "Suele mantener el respeto por los modelos establecidos y los niveles de calidad.",
    "Tiende a ser una persona consciente y consistente.",
    "En condiciones normales será una persona conciliadora.",
    "Tenderá a resolver los conflictos de forma imparcial y objetiva.",
    "Bajo presión puede volverse firme y ser una persona terca y brusca.",
    "Puede tener una visión de futuro al momento de plantear controles y procesos.",
    "Se suele enfocar en la solución de problemas y en los niveles de calidad.",
    "Tiende a tener una visión tanto global como de detalle al enfocarse en un reto.",
    "En condiciones normales puede tomar decisiones con sentido de urgencia.",
    "En la rutina y en el día a día será de ritmo rápido para decidir y solucionar.",
    "Bajo presión su ritmo será pausado, prefiriendo tener el tiempo para el análisis.",
    "En cualquier caso es muy consciente del tiempo y de los plazos.",
    "Suele ser paciente, aunque bajo tensión puede volverse una persona malhumorada.",
    "Le gusta participar a la orden de una persona que reconozca sinceramente su esfuerzo.",
    "Aprecia que le guíen y le aclaren lo que esperan de usted.",
    "Valora que le den pautas de lo que es una tarea bien hecha.",
    "Habiendo entendido sus responsabilidades le gusta la libertad de movimiento.",
    "Puede incomodarle la supervisión estrecha.",
    "Puede ser una persona discreta y reflexiva.",
    "Puede volverse perfeccionista y difícil de complacer.",
    "Se toma su tiempo para pensar y analizar nuevas formas de hacer las cosas.",
    "Por su actitud objetiva corre el riesgo de parecer una persona distante y fría.",
    "Suele ser una persona experta probando diferentes formas para resolver un problema.",
    "Tiende a probar diversas soluciones y esto puede retrasarle al momento de decidir.",
    "Buscará mayor eficiencia a la hora de alcanzar mejores niveles de calidad.",
    "Puede que tenga dificultad a la hora de establecer prioridades.",
    "Puede vacilar en ocasiones a la hora de decidir.",
    "Tiende a enfocarse en lo realmente importante de una acción, problema o tarea.",
    "Puede ser una persona creativa en las soluciones pero calculando bien los riesgos.",
    "Le gusta prepararse y generar la confianza de estar tratando con un especialista.",
    "Tiende a saber de su área y demostrará, con el logro de resultados, su nivel de experticia."
  ],
  70: [
    "Tiende a ser una persona práctica, incisiva y objetiva.",
    "Suele tener cuidado de la calidad.",
    "Se esmera en revisar toda la información disponible antes de tomar una decisión.",
    "Le gusta hacer bien su tarea, e idealmente, que salga bien desde la primera vez.",
    "Tiende a pensar que el tiempo es dinero y que es un recurso que debe saberse usar.",
    "Es de tendencia introvertida, de actitud reflexiva y capaz de aceptar sus errores.",
    "Cuando su enfoque está puesto en una tarea no le gusta que le interrumpan.",
    "Se tomará el tiempo para el cuidado de la calidad sin descuidar los resultados.",
    "Suele integrarse bien al equipo, sobre todo con personas que comprenden la importancia de la excelencia.",
    "Su actitud tiende a ser respetuosa de los procesos, las normas y los protocolos.",
    "Tiende a ser una persona disciplinada, precisa, exacta, detallista y perfeccionista.",
    "Puede ser firme bajo presión.",
    "Por lo general será de tendencia diplomática y de buenos modales.",
    "Tiende a ser una persona honesta y modesta.",
    "Prefiere mantener el orden, la limpieza y la organización en su ambiente.",
    "Frente a la búsqueda de soluciones será de tendencia práctica y organizada.",
    "Si los modelos establecidos no funcionan, probará nuevas ideas.",
    "Antes de tomar una decisión tenderá a calcular los riesgos posibles.",
    "No se detiene frente a un reto, por lo general los enfrenta con responsabilidad.",
    "Tiende a ser exigente con mantener los estándares de calidad.",
    "Tiende a controlar sus impulsos y a pensar antes de actuar.",
    "No le gusta que interfieran en su planificación.",
    "Suele ser de actitud desconfiada con las personas que no conoce.",
    "Evalúa muy bien a las personas antes de confiar en ellas.",
    "Puede distraerse mucho tiempo analizando antes de tomar una decisión.",
    "Tiende a proyectar la imagen de una persona distante, formal y fría.",
    "Es de actitud lógica y racional pero también intuitiva y perceptiva.",
    "Tiende a usar el pensamiento crítico y analítico en la resolución de los problemas.",
    "En general tiene una actitud consciente y consistente de forma activa."
  ],
  71: [
    "Puede ser una persona pionera en su área.",
    "Es de actitud abierta a conocer nuevas personas y su estilo genera confianza.",
    "Le gustaría que los demás le llevaran el ritmo.",
    "Tiende a tener iniciativa y al mismo tiempo es capaz de integrar las iniciativas de los demás.",
    "Tiende a ver el cuadro completo y es capaz de comunicarlo a los otros de forma apropiada.",
    "Los demás pueden percibirle como alguien optimista a la hora de afrontar retos y riesgos.",
    "Equilibra su forma de afrontar los retos con su enfoque en las necesidades de los demás.",
    "Tiende a ser sociable, amable, entusiasta, a la vez que exigente.",
    "Tiende a confiar en usted y en los demás.",
    "Puede tomar el liderazgo de una tarea o reto si fuera necesario.",
    "Tiende a insistir en el logro de los resultados a través de la gente sin volverse una persona incómoda.",
    "Le gusta llegar al final de los retos sin pasar por alto las necesidades de las personas.",
    "Pudiera perder de vistas detalles importantes.",
    "Puede tender a ser impaciente.",
    "Prefiere enfrentar nuevos retos y oportunidades.",
    "Puede adaptarse a una rutina si la situación lo demanda.",
    "Tiende a decidir tomando en cuenta a los demás.",
    "Prefiere establecer algunos niveles de control y estabilidad.",
    "Le suele gustar pertenecer a un equipo.",
    "Puede serle fácil asumir posiciones de liderazgo.",
    "Tiene la tendencia a ser una persona retadora y optimista.",
    "Puede ser una persona reflexiva a la hora de plantearse metas.",
    "Tiende a tomar decisiones con sentido de control de riesgo.",
    "Aunque le gusta innovar, le es fácil respetar las formas tradicionales de hacer las cosas.",
    "Se exige el mismo nivel de rendimiento que le exige a los demás.",
    "Le tiende a atraer más estar en acción y relacionándose, que analizar información.",
    "Tiende a la creatividad y al mismo tiempo aprecia seguir un método.",
    "Pudiera ser optimista a la hora de hacer promesas.",
    "Le gusta invertir tiempo en asesorar y ayudar a otros.",
    "Tiende a apreciar el orden y la estructura.",
    "Puede ser de actitud motivadora.",
    "Puede ser de influencia calmante con quienes se relaciona.",
    "Puede tener la habilidad de autocontrolarse.",
    "Cuando los protocolos establecidos no le convencen puede que actúe de forma independiente.",
    "Tiende a ser de actitud competitiva y persistente en el logro de sus metas.",
    "Tiende a cooperar con el grupo y con la comunidad.",
    "Según lo requiera la situación, puede ser firme o amistoso."
  ],
  72: [
    "Tiende a equilibrar su atención en la gente, en la obtención de resultados y en el cumplimiento de los procesos.",
    "Tiende a mostrar un buen nivel de conocimiento en lo que hace a través de la obtención de resultados.",
    "Ejerce influencia en los demás siendo una persona considerada y no demandante.",
    "Logra que los demás se proyecten a través de los resultados exitosos.",
    "Los demás tienden a valorar su criterio por su nivel de experiencia.",
    "Tiende a ser una persona valorada por su forma amigable de conectarse con los demás.",
    "En condiciones normales será amable, amigable y sociable.",
    "Bajo tensión puede ser firme y una persona terca.",
    "Su foco es la calidad, los resultados y la gente, logra crear equipos eficientes y amigables.",
    "Tenderá a hacer un seguimiento cercano de las tareas delegadas a los otros.",
    "Tomará en cuenta a los demás, a los hechos y la información, para tomar decisiones.",
    "Tiende a ser entusiasta y con motivación para competir y ganar en la obtención de resultados de calidad.",
    "Tiende a tener una actitud competitiva y analítica para afrontar los retos.",
    "Perder una oportunidad le genera temor.",
    "Le gusta tomar la iniciativa para afrontar las tareas y alcanzar los estándares de calidad.",
    "Tenderá a estimular a los demás para que sigan las normas y los modelos establecidos.",
    "En momentos de tensión tenderá a estimular a los demás a resolver el conflicto.",
    "Intenta mantener el nivel de desempeño del equipo en alto.",
    "Puede ser una persona precisa, exacta y calculadora.",
    "De ser necesario puede asumir riesgos calculando las consecuencias.",
    "Bajo presión, puede tornarse una persona malhumorada y agresiva.",
    "Sabe cómo lograr resultados a través de la gente.",
    "Hace seguimiento al cumplimiento de los niveles de calidad.",
    "Puede mostrar un optimismo realista a la hora de asumir riesgos o enfrentar retos.",
    "Tiene un moderado sentido de urgencia.",
    "Ante decisiones complejas pudiera tornarse en una persona analítica.",
    "Es un agente activo para el cambio.",
    "De tendencia extrovertida más que introvertida.",
    "En ocasiones puede ser de actitud reservada.",
    "Su diplomacia y su optimismo se combinan con su energía para estimular al grupo.",
    "Es clave para la acción y el cumplimiento de las tareas.",
    "Puede demostrar un buen equilibrio entre la lógica, la razón, la emoción y la intuición.",
    "Pudiera ser algunas veces de tendencia impulsiva y optimista para decidir.",
    "En ocasiones, se puede detener mucho en el análisis de información.",
    "Cuidará, de forma activa y enérgica, el logro de los resultados y la calidad.",
    "Cotidianamente manejará sus sentimientos de forma positiva y orientada al resultado.",
    "Por lo general tiende a llevarse bien con la gente.",
    "Prefiere asumir nuevos retos y problemas analizando toda la información disponible.",
    "Prefiere abordar soluciones a partir de innovar en modelos probados.",
    "Pone una buena cuota de creatividad y de imaginación, para resolver problemas.",
    "Analiza, usa la lógica y el cálculo de riesgos para afrontar los retos o diseñar procesos.",
    "En las situaciones rutinarias se maneja con sentido de urgencia.",
    "Ante los nuevos problemas o situaciones complejas tenderá al análisis.",
    "Reflexionará y probará diversas teorías y modelos antes de decidir y actuar.",
    "Le gusta cumplir las normas y modelos, siendo capaz de proponer innovaciones.",
    "Le gusta explorar inusuales maneras de afrontar los problemas o las tareas.",
    "Tiene el perfil de una persona que conoce lo que hace.",
    "Se esfuerza para conseguir los resultados rápidamente.",
    "Suele respetar los patrones establecidos y los niveles de calidad.",
    "Tiende a ser una persona consciente y consistente de forma activa.",
    "En condiciones normales será de actitud conciliadora.",
    "Buscará resolver los conflictos de forma imparcial y objetiva.",
    "Bajo presión, se puede volver una persona firme, terca y brusca.",
    "Puede tener una visión de futuro al momento de plantear controles y normas.",
    "Podrá anticiparse en la solución de problemas y al implementar niveles de calidad."
  ],
  73: [
    "Tiene una visión tanto global como de detalle al enfocarse en un reto o problema.",
    "Cuando el clima es desfavorable, tenderá a ser perfeccionista y a pausar el ritmo.",
    "Tiende a ser consciente del tiempo y de los plazos.",
    "Suele ser paciente en un clima de normalidad.",
    "En momentos de tensión puede volverse una persona malhumorada.",
    "Le gusta trabajar a la orden de una persona que reconozca sinceramente su esfuerzo.",
    "Aprecia que le guíen y le aclaren las expectativas de su desempeño.",
    "Valora recibir pautas de lo que es una tarea bien hecha.",
    "Habiendo entendido sus responsabilidades, le gusta que le den libertad de movimiento.",
    "Prefiere que no haya una supervisión estrecha.",
    "Es de tendencia introvertida y discreta.",
    "Puede volverse perfeccionista y difícil de complacer.",
    "Se toma su tiempo para pensar y analizar nuevas formas de hacer las cosas.",
    "Por su actitud objetiva corre el riesgo de parecer una persona distante y fría.",
    "Tiende a ser una persona experta probando múltiples formas para resolver un problema.",
    "Al analizar múltiples soluciones puede paralizarse al momento de decidir por la mejor.",
    "En clima favorable, tiende a ser de actitud considerada y a mantener la armonía.",
    "Enfrenta sus responsabilidades y retos de forma organizada y sistemática.",
    "Es persistente en el seguimiento de los patrones establecidos.",
    "Tiende a ser una persona sólida con sus habilidades y talentos.",
    "Es realista con los sistemas, patrones, procedimientos y normas.",
    "Es detallista y tiende a enfocarse en cuidar todos los aspectos de un proyecto.",
    "Tiende a darle importancia al cuidado de la calidad.",
    "Suele ser sensible al ritmo del entorno y se adapta a éste para enfocarse en los resultados.",
    "Tiende a ser una persona ordenada, limpia y organizada para actuar.",
    "En ocasiones, puede tolerar pasar por alto ciertas normas.",
    "Tiende a ser estable y le gusta tener un tiempo prudencial para asumir los cambios.",
    "Estará pendiente de probar nuevos protocolos más eficientes.",
    "Le gustará innovar para alcanzar mejores niveles de calidad y un mejor rendimiento.",
    "Puede tener dificultad a la hora de establecer prioridades.",
    "Puede vacilar en ocasiones a la hora de decidir.",
    "Es una persona enfocada en lo importante de una tarea, problema o proyecto.",
    "Tenderá a la creatividad en la solución de problemas, pero calculando bien los riesgos.",
    "Le gusta prepararse y generar la confianza de estar tratando con una persona con experiencia.",
    "Le gusta demostrar, con el logro de resultados, su nivel de experiencia."
  ],
  74: [
    "Tiende a ser de actitud seria cuando se requiere afrontar tareas y acciones.",
    "Suele relajar el ambiente tenso con buen humor y alegría, de ser necesario.",
    "Cuando el clima es desfavorable tenderá a tornarse en una persona malhumorada.",
    "Tiende a demostrar un buen nivel de experiencia en su área de conocimiento.",
    "Al seguir normas y modelos puede ser una persona meticulosa y detallista.",
    "Tiende a ser optimista y de actitud crítica cuando es necesario.",
    "Tiende a ser una persona amistosa que sabe integrarse al grupo.",
    "Puede ser de actitud desenvuelta cuando comparte con los demás.",
    "Tiende a ser de fácil trato.",
    "Expresará su decepción ante el incumplimiento de los estándares de calidad.",
    "Tiende a organizar sus tareas de forma autónoma.",
    "Suele a ser de actitud modesta.",
    "Es hábil y paciente con las personas y es capaz de ser eficaz en su área de conocimiento.",
    "Le gusta revisar información específica y hechos antes de tomar decisiones.",
    "Puede confiar en demasía en las habilidades de los demás.",
    "Bajo estrés o tensión, puede volverse una persona malhumorada.",
    "Puede estresarse cuando la carga es mucha.",
    "Tenderá a enfocarse en las tareas rutinarias o que estén a la mano.",
    "Su ritmo tiende a ser pausado para analizar los hechos.",
    "Puede demorar en la toma de decisiones mientras analiza toda la información.",
    "Puede ser un reto cumplir con las fechas límites.",
    "Tiende a la perfección y al detalle.",
    "Tenderá a tornarse en una persona indecisa ante los cambios de rutinas y las tensiones.",
    "Puede tender a ser perfeccionista.",
    "Tenderá a enfocarse en resultados con altos niveles de calidad en su área.",
    "Tiene buen equilibrio en la forma de abordar la calidad, los patrones y las personas."
  ]
};
