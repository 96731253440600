<template>
  <div>
    <table>
      <thead>
        <tr>
          <th colspan="2">Nivel de desarrollo del Autoliderazgo</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(section, index) in sections" :key="index">
          <td style="width: 50%">{{ section.label }}</td>
          <td>{{ getFeedback(section.key, section.score) }}</td>
        </tr>
        <tr>
          <td style="width: 50%">Resultados Integrales</td>
          <td>{{ getFeedback("integral", integralScore) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  emotionFeedback,
  mindFeedback,
  behaviorFeedback,
  integralFeedback
} from "@/model/self-leadership/feedbacks.js";

export default {
  name: "LeadershipDevelopmentTable",
  props: {
    applicantCount: {
      type: Object,
      required: true
    }
  },
  computed: {
    sections() {
      return [
        {
          key: "emotion",
          label: "Gestión del Sistema Emocional",
          score: this.applicantCount.emotionsum
        },
        {
          key: "mind",
          label: "Gestión del Sistema Mental",
          score: this.applicantCount.mindsum
        },
        {
          key: "behavior",
          label: "Gestión del Sistema Corporal",
          score: this.applicantCount.behaviorsum
        }
      ];
    },
    integralScore() {
      return (
        this.applicantCount.emotionsum +
        this.applicantCount.mindsum +
        this.applicantCount.behaviorsum
      );
    }
  },
  methods: {
    getFeedback(sectionKey, score) {
      let feedback = "";

      switch (sectionKey) {
        case "emotion":
          feedback = this.getSectionFeedback(score, emotionFeedback);
          break;
        case "mind":
          feedback = this.getSectionFeedback(score, mindFeedback);
          break;
        case "behavior":
          feedback = this.getSectionFeedback(score, behaviorFeedback);
          break;
        case "integral":
          feedback = this.getSectionFeedback(score, integralFeedback);
          break;
      }

      return feedback;
    },
    getSectionFeedback(score, feedbacks) {
      let selectedFeedback = "";

      for (const feedback of feedbacks) {
        if (score >= feedback.range.min && score <= feedback.range.max) {
          selectedFeedback = feedback.label;
          break;
        }
      }

      return selectedFeedback;
    }
  }
};
</script>

<style scoped>
table {
  width: 90%;
  margin: auto;
  border-collapse: collapse;
}

h1 {
  width: 90%;
  margin: auto;
  padding: 10px;
  border: 1px solid #dddddd;
  text-align: center;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  text-align: center;
  background-color: #f2f2f2;
}

.table-container {
  margin-bottom: 20px;
}

div {
  list-style-type: none;
  text-align: justify;
}
</style>
