<template>
  <div>
    <personal-disc-report v-if="isDISC" :data="reportData" />
    <personal-convergence-report v-if="isPGC" :data="reportData" />
    <personal-onetoone-report v-if="isiOneToOne" :data="reportData" />
    <personal-foneteam-report v-if="isFOneTeam" :data="reportData" />
    <personal-selflead-report v-if="isSelfLead" :data="reportData" />
    <roledisc-report v-if="isRoleDISC" :data="reportData" />
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

import tableOfContents from "@/model/disc/toc";
import PersonalDISCReport from "./disc/PersonalDISCReport.vue";
import PersonalConvergenceReport from "./pgc/PersonalConvergenceReport.vue";
import OTOReport from "./one-to-one/OTOReport.vue";
import FOneReport from "./fone-team/FOneReport.vue";
import SelfLeadReport from "./self-leadership/SLReport.vue";
import RoleDISCReport from "./roledisc/RoleDISCReport.vue";


export default defineComponent({
  name: "ReportController",
  components: {
    "personal-disc-report": PersonalDISCReport,
    "personal-convergence-report": PersonalConvergenceReport,
    "personal-onetoone-report": OTOReport,
    "personal-foneteam-report": FOneReport,
    "personal-selflead-report": SelfLeadReport,
    "roledisc-report": RoleDISCReport
  },
  data() {
    return {
      reportData: null
    };
  },
  computed: {
    isDISC() {
      return this.reportData && this.reportData.modelType === "DISC";
    },
    isPGC() {
      return this.reportData && this.reportData.modelType === "PGC";
    },
    isiOneToOne() {
      return this.reportData && this.reportData.modelType === "ONE_TO_ONE";
    },
    isFOneTeam() {
      return this.reportData && this.reportData.modelType === "F1_TEAM";
    },
    isSelfLead() {
      return this.reportData && this.reportData.modelType === "SELF_LEADERSHIP";
    },
    isRoleDISC(){
      return this.reportData && this.reportData.modelType === "ROLE_DISC"
    }
  },
  methods: {
    initData() {
      this.reportId = this.$route.params.reportId;
    },
    getReportData() {
      this.$store
        .dispatch("getReportData", this.reportId)
        .then(response => {
          this.reportData = response;
          this.initTableOfContents();
        })
        .catch(error => console.log(error));
    },
    initTableOfContents() {
      let tocItems = null;

      if (this.reportData.modelType === "DISC") {
        tocItems = tableOfContents.filter(item =>
          !item.reportType ||
          (item.reportType === this.reportData.reportType)
        );
      }
      this.$emit("set-table-of-contents", tocItems);
    }
  },
  mounted() {
    this.initData();
    this.$nextTick(this.getReportData);
  }
});
</script>
