export default [
  {
    text: "Grave",
    threshold: 49,
    color: "#b60000",
    fontColor: "#ffffff"
  },
  {
    text: "Problemático",
    threshold: 79,
    color: "#ffa600",
    fontColor: "#000000"
  },
  {
    text: "Alerta",
    threshold: 99,
    color: "#ffea00",
    fontColor: "#000000"
  },
  {
    text: "Óptimo",
    threshold: 100,
    color: "#417600",
    fontColor: "#ffffff"
  }
];
