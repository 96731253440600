import firebase from "@/plugins/firebase";
import axios from "@/utils/axios";

export default {
  async products(type) {
    try {
      const params = { type };
      let userToken = "";

      if (type === "pack") {
        const currentUser = firebase.auth().currentUser;
        if (!currentUser) {
          history.back();
        } else {
          userToken = await currentUser.getIdToken();
        }
      }

      const response = await axios.get("/shop/products", {
        params,
        headers: {
          token: userToken
        }
      });
      const data = processResponse(response);
      return data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw new Error(error.response.data.error);
      } else {
        throw error;
      }
    }
  },
  async manageMembership() {
    try {
      const params = {};
      const currentUser = firebase.auth().currentUser;

      if (!currentUser) {
        history.back();
      } else {
        const userToken = await currentUser.getIdToken();

        const response = await axios.post(
          "/shop/manage-subscription",
          undefined,
          {
            params,
            headers: {
              token: userToken
            }
          }
        );

        if (response.status !== 200) {
          throw new Error(response.data.error);
        }

        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw new Error(error.response.data.error);
      } else {
        throw error;
      }
    }
  }
};

function processResponse(response) {
  if (response.status === 200) {
    if (response.data && response.data) {
      return response.data;
    } else {
      return null;
    }
  } else {
    throw new Error(
      `Error ${response.status} on response for ${response.config.url}`
    );
  }
}
