<template>
  <div class="pb-0 no-breakable" v-if="!!content">
    <v-row no-gutters v-if="!!title">
      <v-col cols="0" md="1" />
      <v-col cols="12" md="10" class="pt-8">
        <report-section-subtitle :text="title" :image="icon" />
      </v-col>
      <v-col cols="0" md="1" />
    </v-row>

    <v-row no-gutters class="generic-text">
      <v-col cols="0" sm="0" md="1" />
      <v-col class="px-5 px-sm-5 px-md-0" cols="12" sm="12" md="10">
        <p
          class="generic-text-description"
          v-html="description"
          v-if="!!description"
        />
        <ul class="bullets-list no-breakable">
          <li class="bullet no-breakable" v-for="items in content" :key="items">
            {{ items }}
          </li>
        </ul>
      </v-col>
      <v-col cols="0" sm="0" md="1" />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SectionGenericBulletContent",
  props: {
    title: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    content: {
      type: Array,
      required: false
    },
    description: String
  }
};
</script>

<style scoped>
.bullet {
  text-align: justify;
  font-size: 1em;
  margin-bottom: 0.3em;
}
.generic-text {
  margin-top: 0.25em;
}
.generic-text-description {
  text-align: justify;
  margin-top: 0;
  margin-bottom: 1.5em;
}

@media print {
  li {
    break-inside: avoid;
    page-break-inside: avoid;
  }
  .generic-text {
    margin-top: 0.25em;
  }
  .generic-text-description {
    padding-left: 50px;
    padding-right: 50px;
  }
  .bullets-list {
    padding-left: 50px;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .no-breakable {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
</style>
