import Vue from "vue";
import Vuetify from "vuetify/lib";
import es from "vuetify/es5/locale/es";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa"
  },
  lang: {
    locales: { es },
    current: "es"
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        background: "#FFFFFF",
        primary: "#1C2B39",
        secondary: "#9EDBDD",
        accent: "#717698",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  }
});
