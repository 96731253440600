export const formatDate = dateInt => {
  if (dateInt) {
    const date = new Date(dateInt);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  } else {
    return "-";
  }
};
