<template>
  <v-text-field
    background-color="#ffffff"
    dense
    outlined
    :clearable="clearable"
    :disabled="disabled"
    :hide-details="hideDetails"
    :label="label"
    :required="required"
    :rules="computedRules"
    :type="type"
    :value="value"
    @change="onChange"
    @input="onInput"
    @keyup.native="onKeydown"
  ></v-text-field>
</template>

<script>
import { defineComponent } from "vue";

import validations from "@/plugins/validations";

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: "text"
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    value: String
  },
  computed: {
    computedRules() {
      const rules = [];
      if (this.required) {
        rules.push(validations.form.required_field);
      }
      if (this.rules) {
        rules.push(...this.rules);
      }
      return rules;
    }
  },
  methods: {
    onChange(event) {
      this.$emit("change", event);
    },
    onInput(event) {
      this.$emit("input", event);
    },
    onKeydown(event) {
      this.$emit("keydown", event);
    }
  }
});
</script>
