<template>
  <v-container fluid class="white py-0 px-0" id="printPage">
    <report-header id="ready_to_know">
      <template v-slot:data>
        <applicant-data :data="applicantData" />
      </template>
      <template v-slot:controls>
        <v-row justify="end" align="center">
          <v-col cols="4" class="d-flex justify-end pr-sm-10 pr-4">
            <v-btn elevation="2" @click="onPrint">Imprimir</v-btn>
          </v-col>
        </v-row>
      </template>
    </report-header>

    <section-intro />

    <fone-team :applicantCount="applicantCount" />

    <team-level :applicantCount="applicantCount" />

    <diagnostics-level :applicantCount="applicantCount" />

    <strategies-level :applicantCount="applicantCount" />

    <level-array
      :applicantAnswers="applicantAnswers"
      :assessedCountByFactor="assessedCountByFactor"
    />
    <team-level-comparition
      v-if="!hasAssessed"
      :applicantCount="applicantCount"
      :assessedByLevel="assessedByLevel"
    />
  </v-container>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { formatDate } from "@/utils/date";
import ApplicantData from "../common/ApplicantData.vue";
import ReportHeader from "../common/ReportHeader.vue";
import SectionIntro from "./components/SectionIntro.vue";
import FOneTeam from "./components/FOneTeam.vue";
import TeamLevel from "./components/TeamLevel.vue";
import Diagnostics from "./components/Diagnostics.vue";
import StrategiesLevel from "./components/StrategiesLevel.vue";
import LevelArray from "./components/LevelArray.vue";
import TeamLevelComparition from "./components/TeamLevelComparition.vue";

export default defineComponent({
  name: "FOneReport",
  components: {
    "applicant-data": ApplicantData,
    "report-header": ReportHeader,
    "section-intro": SectionIntro,
    "fone-team": FOneTeam,
    "team-level": TeamLevel,
    "diagnostics-level": Diagnostics,
    "strategies-level": StrategiesLevel,
    "level-array": LevelArray,
    "team-level-comparition": TeamLevelComparition
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      assessed: []
    };
  },
  computed: {
    hasAssessed() {
      return this.data.assessed.length === 0;
    },
    applicantAnswers() {
      return this.data && this.data.results
        ? this.data.results.applicantAnswers
        : null;
    },
    assessedCountByFactor() {
      return this.data && this.data.results
        ? this.data.results.assessedCountByFactor
        : null;
    },
    applicantCount() {
      return this.data && this.data.results
        ? this.data.results.applicantCount
        : null;
    },
    assessedByLevel() {
      return this.data && this.data.results
        ? this.data.results.assessedByLevel
        : null;
    },
    applicantData() {
      return [
        { label: "Aplicante", value: this.applicantName },
        { label: "Correo Electrónico", value: this.applicantEmail },
        { label: "Fecha de Aplicación", value: formatDate(this.submittedAt) }
      ];
    },
    applicantName() {
      return this.data ? this.data.applicantName : "-";
    },
    applicantEmail() {
      return this.data ? this.data.applicantEmail : "-";
    },
    submittedAt() {
      return this.data ? this.data.submittedAt : null;
    },
  },
  methods: {
    onPrint() {
      window.print();
    }
  }
});
</script>
