<template>
  <div class="background py-6">
    <v-row no-gutters>
      <v-col cols="0" sm="0" md="1" />
      <v-col cols="12" sm="12" md="10" class="px-2">
        <v-row class="ma-0">
          <v-col cols="12" sm="6" class="print-only-layout pa-0 pa-sm-3">
            <report-section-subtitle
              text="Estilo Natural"
              image="ICONOS-01.png"
              :subtext="naturalName"
            />
            <v-row no-gutters>
              <v-col>
                <p class="content mt-2">
                  El estilo natural es la forma básica o primaria de
                  comportarse. Es el estilo que se utiliza sin pensar, no
                  requiere de esfuerzo. Es el comportamiento que surge de forma
                  espontánea y auténtica. En el gráfico corresponde a las barras
                  de colores.
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="6" class="print-only-layout pa-0 pa-sm-3">
            <report-section-subtitle
              text="Respuesta Adaptativa"
              image="ICONOS-02.png"
              :subtext="adaptiveName"
            />
            <v-row no-gutters>
              <v-col>
                <p class="content mt-2">
                  La respuesta adaptativa es un ajuste del estilo natural a las
                  exigencias del medio ambiente. Es el resultado de lo que la
                  persona hace para tener éxito ante las demandas del contexto.
                  Es uno de los dos estilos más importantes. Si hay mucha
                  adaptación la persona deberá esforzarse para sostener este
                  perfil y esto puede generarle estrés. En el gráfico
                  corresponde a las barras grises.
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="1" sm="2" />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SectionStyleAndAnswer",
  props: {
    naturalName: {
      type: String
    },
    adaptiveName: {
      type: String
    }
  }
};
</script>

<style scoped>
.content {
  text-align: justify;
}
.background {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(233, 234, 237, 1) 100%
  );
}
@media print {
  .print-only-layout {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 16px !important;
    margin-top: 8px;
  }
  .content {
    line-height: normal;
  }
}
</style>
