<template>
  <v-container fluid>
    <v-row class="text-center justify-center">
      <v-col :cols="side" class="hidden-sm-and-down"></v-col>
      <v-col cols="12" :md="center">
        <slot />
      </v-col>
      <v-col :cols="side" class="hidden-sm-and-down"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    mode: {
      type: String,
      default: "small"
    }
  },
  data() {
    return {
      side: 4,
      center: 4
    };
  },
  created() {
    if (this.mode === "small") {
      this.side = 4;
      this.center = 4;
    } else if (this.mode === "mid") {
      this.side = 3;
      this.center = 6;
    } else if (this.mode === "large") {
      this.side = 1;
      this.center = 10;
    } else if (this.mode === "wide") {
      this.side = 0;
      this.center = 12;
    }
  }
});
</script>

<style></style>
