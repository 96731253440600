import userService from "@/services/userService";

export default {
  state: () => ({
    userInvite: null
  }),
  mutations: {
    setUserInvite(state, data) {
      state.userInvite = data;
    }
  },
  actions: {
    listUsers() {
      return userService.listUsers();
    },
    async sendInvitation({ commit }, { email, displayName }) {
      commit("setProcessingRequest", true);

      try {
        await userService.inviteUser({ email, displayName });
      } catch (error) {
        console.error("Error sending user invite:", error);
        throw error;
      } finally {
        commit("setProcessingRequest", false);
      }
    },
    fetchUserInvite({ commit }, inviteId) {
      return new Promise((resolve, reject) => {
        userInviteService
          .getUserInvite(inviteId)
          .then(response => {
            commit("setUserInvite", response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.error("Error fetching user invite:", error);
            reject(error);
          });
      });
    }
  },
  getters: {
    userInvite(state) {
      return state.userInvite;
    }
  }
};
