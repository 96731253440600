import store from "@/store/index.js";
import messages from "@/plugins/messages";

export default {
  async beforeRouteEnter(to, from, next) {
    try {
      const accountInfo = await store.dispatch("getAccountInfo");
      if (accountInfo) {
        if (accountInfo.billingInfo) {
          if (accountInfo.purchaseRequired) {
            if (!!store.getters.redirectRoute) {
              const pathToGo = store.getters.redirectRoute.path;
              store.dispatch("clearRedirectRoute");
              next(pathToGo);
            } else {
              next("/shop/membership");
            }
          } else {
            next(true);
          }
        } else {
          next("/billing-info/onboard");
        }
      } else {
        next(`/error/${encodeURI(messages.errors.invalidAccountInfo)}`);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
};
