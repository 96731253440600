<template>
  <assessment-back-next-card-layout
    :title="title"
    :step="step"
    :steps="steps"
    @next="next"
    @back="back"
  >
    <v-form lazy-validation>
      <v-row justify="center">
        <v-col cols="12">
          <h2 class="mb-2">{{ currentSection.label }}</h2>

          <v-row v-if="currentSection.key === 'aspects'">
            <v-col
              class="py-0"
              cols="12"
              v-for="{ id, text } in currentSection.items"
              :key="id"
            >
              <v-checkbox
                hide-details
                v-model="answers[currentSection.key]"
                :disabled="disabledAspect(id)"
                :label="text"
                :value="id"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col
              cols="12"
              v-for="(question, questionIndex) in currentSection.items"
              :key="`question-${questionIndex}`"
              subtitle-1
              class="text-center pb-0"
            >
              <p class="mb-0">{{ question }}</p>
              <v-row class="justify-center align-center">
                <v-col cols="2" class="pt-0 d-none d-sm-flex justify-end">
                  Nunca
                </v-col>

                <v-col cols="auto" class="pt-0">
                  <v-radio-group
                    v-model="answers[currentSection.key][questionIndex]"
                    row
                  >
                    <v-radio
                      v-for="value in 5"
                      :key="`answer-${questionIndex}-${value}`"
                      :label="`${value}`"
                      :value="value"
                      class="mr-2"
                    >
                      <template v-slot:label>
                        <span>{{ value }}</span>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="2" class="pt-0 d-none d-sm-flex">Siempre</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" class="text-center py-0">
        <v-col cols="12" v-if="error" class="red--text text-center py-0">
          {{ error }}
        </v-col>
      </v-row>
    </v-form>
  </assessment-back-next-card-layout>
</template>

<script lang="js">
import { defineComponent } from "vue";
import assessmentMixin from "@/mixins/assessmentMixin";
import { assessed, applicant } from "@/model/one-to-one/questions.js";

export default defineComponent({
  mixins: [assessmentMixin],
  data() {
    return {
      answers: null,
      error: false,
      sections: null,
    };
  },
  created() {
    if (this.getAndValidateAssessment()) {
      this.sections = !!this.applicantId ? applicant : assessed;

      if (this.$store.getters.answers) {
        this.answers = this.$store.getters.answers[this.applicantId || "assessed"];
      } else {
        this.answers = this.sections.reduce(
          (answers, { key, items }) => ({
            ...answers,
            [key]: key === "aspects"
              ? []
              : new Array(items.length).fill(undefined)
          }),
          {}
        );
      }
    }
  },
  computed: {
    step() {
      return this.$store.getters.currentStep;
    },
    steps() {
      return this.sections.length;
    },
    title() {
      return !!this.applicantId
        ? `Selecciona la opción que mejor describa el comportamiento de ${this.assessment.assessedName}`
        : "Selecciona la opción que mejor te describa";
    },
    currentSection() {
      return this.sections[this.step];
    },
  },
  methods: {
    back() {
      this.error = false;

      if (this.step == 0) {
        this.setAnswers();

        this.$router.push(
          !!this.applicantId
            ? `${this.assessmentStartURL}/${this.applicantId}`
            : this.assessmentFormURL
        );
      } else {
        this.$store.dispatch("back");
      }
    },
    disabledAspect(aspectId) {
      const aspects = this.answers["aspects"];
      return aspects && aspects.length === 3 && !aspects.includes(aspectId);
    },
    isValidSelection() {
      if (this.currentSection.key === "aspects") {
        return this.answers[this.currentSection.key].length === 3;
      } else {
        return !!this.answers[this.currentSection.key]
          && this.answers[this.currentSection.key].every(item => !!item);
      }
    },
    next() {
      if (this.isValidSelection()) {
        if (this.step === this.steps - 1) {
          this.setAnswers();
          this.$router.push(this.assessmentSubmitURL);
        } else {
          this.$store.dispatch("next");
        }
      } else if (this.currentSection.key === "aspects") {
        this.error = "Debe seleccionar tres opciones para continuar";
      } else {
        this.error = "Debe seleccionar todas las opciones para continuar";
      }
    },
    setAnswers() {
      this.$store.dispatch("setAnswers", {
        [this.applicantId || "assessed"]: this.answers
      });
    }
  },
});
</script>
