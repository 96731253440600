const percentages = {};

percentages["D"] = {
  0: { percentage: 10, intensity: 1 },
  1: { percentage: 10, intensity: 1 },
  2: { percentage: 14, intensity: 1 },
  3: { percentage: 21, intensity: 2 },
  4: { percentage: 29, intensity: 2 },
  5: { percentage: 35, intensity: 3 },
  6: { percentage: 43, intensity: 3 },
  7: { percentage: 51, intensity: 4 },
  8: { percentage: 58, intensity: 4 },
  9: { percentage: 64, intensity: 5 },
  10: { percentage: 71, intensity: 5 },
  11: { percentage: 78, intensity: 6 },
  12: { percentage: 85, intensity: 6 },
  13: { percentage: 92, intensity: 7 },
  14: { percentage: 99, intensity: 7 },
  15: { percentage: 99, intensity: 7 },
  16: { percentage: 99, intensity: 7 },
  17: { percentage: 99, intensity: 7 },
  18: { percentage: 99, intensity: 7 },
  19: { percentage: 99, intensity: 7 },
  20: { percentage: 99, intensity: 7 },
  21: { percentage: 99, intensity: 7 },
  22: { percentage: 100, intensity: 7 },
  23: { percentage: 100, intensity: 7 },
  24: { percentage: 100, intensity: 7 },
  25: { percentage: 100, intensity: 7 },
  26: { percentage: 100, intensity: 7 },
  27: { percentage: 100, intensity: 7 },
  28: { percentage: 100, intensity: 7 }
};

percentages["I"] = {
  0: { percentage: 10, intensity: 1 },
  1: { percentage: 10, intensity: 1 },
  2: { percentage: 14, intensity: 1 },
  3: { percentage: 21, intensity: 2 },
  4: { percentage: 29, intensity: 2 },
  5: { percentage: 35, intensity: 3 },
  6: { percentage: 43, intensity: 3 },
  7: { percentage: 51, intensity: 4 },
  8: { percentage: 58, intensity: 4 },
  9: { percentage: 64, intensity: 5 },
  10: { percentage: 71, intensity: 5 },
  11: { percentage: 78, intensity: 6 },
  12: { percentage: 85, intensity: 6 },
  13: { percentage: 92, intensity: 7 },
  14: { percentage: 99, intensity: 7 },
  15: { percentage: 99, intensity: 7 },
  16: { percentage: 99, intensity: 7 },
  17: { percentage: 99, intensity: 7 },
  18: { percentage: 99, intensity: 7 },
  19: { percentage: 99, intensity: 7 },
  20: { percentage: 99, intensity: 7 },
  21: { percentage: 99, intensity: 7 },
  22: { percentage: 100, intensity: 7 },
  23: { percentage: 100, intensity: 7 },
  24: { percentage: 100, intensity: 7 },
  25: { percentage: 100, intensity: 7 },
  26: { percentage: 100, intensity: 7 },
  27: { percentage: 100, intensity: 7 },
  28: { percentage: 100, intensity: 7 }
};

percentages["S"] = {
  0: { percentage: 10, intensity: 1 },
  1: { percentage: 10, intensity: 1 },
  2: { percentage: 14, intensity: 1 },
  3: { percentage: 21, intensity: 2 },
  4: { percentage: 29, intensity: 2 },
  5: { percentage: 35, intensity: 3 },
  6: { percentage: 43, intensity: 3 },
  7: { percentage: 51, intensity: 4 },
  8: { percentage: 58, intensity: 4 },
  9: { percentage: 64, intensity: 5 },
  10: { percentage: 71, intensity: 5 },
  11: { percentage: 78, intensity: 6 },
  12: { percentage: 85, intensity: 6 },
  13: { percentage: 92, intensity: 7 },
  14: { percentage: 99, intensity: 7 },
  15: { percentage: 99, intensity: 7 },
  16: { percentage: 99, intensity: 7 },
  17: { percentage: 99, intensity: 7 },
  18: { percentage: 99, intensity: 7 },
  19: { percentage: 99, intensity: 7 },
  20: { percentage: 99, intensity: 7 },
  21: { percentage: 99, intensity: 7 },
  22: { percentage: 100, intensity: 7 },
  23: { percentage: 100, intensity: 7 },
  24: { percentage: 100, intensity: 7 },
  25: { percentage: 100, intensity: 7 },
  26: { percentage: 100, intensity: 7 },
  27: { percentage: 100, intensity: 7 },
  28: { percentage: 100, intensity: 7 }
};

percentages["C"] = {
  0: { percentage: 10, intensity: 1 },
  1: { percentage: 10, intensity: 1 },
  2: { percentage: 14, intensity: 1 },
  3: { percentage: 21, intensity: 2 },
  4: { percentage: 29, intensity: 2 },
  5: { percentage: 35, intensity: 3 },
  6: { percentage: 43, intensity: 3 },
  7: { percentage: 51, intensity: 4 },
  8: { percentage: 58, intensity: 4 },
  9: { percentage: 64, intensity: 5 },
  10: { percentage: 71, intensity: 5 },
  11: { percentage: 78, intensity: 6 },
  12: { percentage: 85, intensity: 6 },
  13: { percentage: 92, intensity: 7 },
  14: { percentage: 99, intensity: 7 },
  15: { percentage: 99, intensity: 7 },
  16: { percentage: 99, intensity: 7 },
  17: { percentage: 99, intensity: 7 },
  18: { percentage: 99, intensity: 7 },
  19: { percentage: 99, intensity: 7 },
  20: { percentage: 99, intensity: 7 },
  21: { percentage: 99, intensity: 7 },
  22: { percentage: 100, intensity: 7 },
  23: { percentage: 100, intensity: 7 },
  24: { percentage: 100, intensity: 7 },
  25: { percentage: 100, intensity: 7 },
  26: { percentage: 100, intensity: 7 },
  27: { percentage: 100, intensity: 7 },
  28: { percentage: 100, intensity: 7 }
};
export default {
  get(data) {
    const response = { ...data };
    const profiles = response.results;

    profiles.values["D"] = percentages["D"][profiles.values["D"]];
    profiles.values["I"] = percentages["I"][profiles.values["I"]];
    profiles.values["S"] = percentages["S"][profiles.values["S"]];
    profiles.values["C"] = percentages["C"][profiles.values["C"]];

    return response;
  }
};
