<template>
  <div>
    <p><strong>Instrucciones:</strong></p>
    <p>
      Cada cargo tiene un estilo de comportamiento predominante que lo
      caracteriza. Entender esta naturaleza ayuda a tomar decisiones
      estratégicas. Esta herramienta te permite pensar, recoger información y
      organizarla para comprender el perfil de comportamiento del cargo. Es
      clave que, al llenarla, pienses en ese cargo y en sus funciones
      cotidianas.
    </p>
    <p>
      Esta herramienta busca responder la pregunta:
      <strong>¿Cómo debe comportarse este cargo para ser exitoso?</strong>
    </p>
    <p>Para lograr una respuesta ajustada a la realidad, te recomendamos:</p>
    <ol>
      <li>Conocer muy bien el cargo que vas a evaluar.</li>
      <li>No sobreanalizar tus respuestas.</li>
      <li>
        Apoyarte en las evidencias y comportamientos observables cotidianos, más
        que en suposiciones.
      </li>
    </ol>
    <br />
    <p>
      A continuación, encontrarás 28 ítems. En cada uno, deberás escoger la
      opción que mejor describa el cargo que estás evaluando. Debes responder
      cada ítem para avanzar al siguiente. Recuerda pensar en las
      características predominantes del cargo al responder este instrumento.
    </p>
    <p><strong>¿Preparado(a) para comenzar?</strong></p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "StartTextRoleDISC"
});
</script>
