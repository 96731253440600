<template>
  <div class="container">
    <report-section-title>
      Estrategias para llevar al equipo a un mayor nivel de madurez y desempeño
    </report-section-title>
    <div v-for="(table, index) in tables" :key="index">
      <h3>{{ table.label }}</h3>
      <ul>
        <li v-for="(item, i) in table.items" :key="i">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  beginner,
  distrustful,
  misaligned,
  unsure,
  capable,
  bounded,
  innovative
} from "@/model/fone-team/strategies.js";

export default {
  props: {
    applicantCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      tables: []
    };
  },
  created() {
    this.setStrategies();
  },
  methods: {
    setStrategies() {
      switch (true) {
        case this.applicantCount >= 7 && this.applicantCount <= 9:
          this.tables = beginner;
          break;
        case this.applicantCount >= 10 && this.applicantCount <= 12:
          this.tables = distrustful;
          break;
        case this.applicantCount >= 13 && this.applicantCount <= 15:
          this.tables = misaligned;
          break;
        case this.applicantCount >= 16 && this.applicantCount <= 19:
          this.tables = unsure;
          break;
        case this.applicantCount >= 20 && this.applicantCount <= 22:
          this.tables = capable;
          break;
        case this.applicantCount >= 23 && this.applicantCount <= 25:
          this.tables = bounded;
          break;
        case this.applicantCount >= 26 && this.applicantCount <= 28:
          this.tables = innovative;
          break;
        default:
          this.tables = [];
      }
    }
  }
};
</script>

<style scoped>
.container {
  width: 70%;
}
h3 {
  width: 90%;
  margin: auto;
  padding: 10px;
  text-align: center;
}
li {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--v-primary-base);
  text-align: justify;
  margin-bottom: 1em;
}
</style>
