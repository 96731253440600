export const emotionFeedback = [
  {
    key: "initial",
    label: "Inicial", //entre 7 y 21 puntos
    range: { min: 7, max: 21 },
    items: [
      "Tu autogestión emocional tiene un gran potencial para ser fortalecida.",
      "Dominar tu sistema de gestión emocional te permitirá potenciar significativamente tu autoliderazgo.",
      "Desarrollar tu consciencia emocional y aprender a gestionarlas de manera efectiva te permitirá avanzar en tu desarrollo personal.",
      "Descubrir tus pasiones te brindará la motivación necesaria para alcanzar tus metas.",
      "Desarrollar estrategias para recuperar tu energía y entusiasmo te permitirá enfrentar los desafíos con mayor vitalidad.",
      "Entender la naturaleza de tus emociones, sin juzgarlas como buenas o malas, te permitirá gestionarlas de manera más efectiva y reducir tu sufrimiento.",
      "Fortalecer tu bienestar interno te permitirá ser más resiliente ante las circunstancias externas y reducir tu vulnerabilidad emocional."
    ]
  },
  {
    key: "mid-developed",
    label: "Intermedio", //entre 22 y 33 puntos
    range: { min: 22, max: 33 },
    items: [
      "Tu capacidad de gestionar tus emociones es buena y tiene un gran potencial para seguir creciendo.",
      "Posees una buena consciencia emocional y la habilidad para manejar tus emociones en la mayoría de las situaciones. Sin embargo, hay momentos en los que puedes sentirte abrumado por ellas.",
      "Tienes una clara idea de lo que te apasiona, pero en ocasiones puedes experimentar una disminución en tu motivación y entusiasmo.",
      "En algunos momentos puedes mostrar impulsividad, reactividad o impaciencia.",
      "Generalmente mantienes una actitud resiliente ante las circunstancias, pero en ocasiones puedes experimentar dificultades para recuperar tu fuerza interna y seguir adelante.",
      "Si bien la incertidumbre puede afectarte negativamente en algunas situaciones, posees la capacidad para lidiar con ella en general.",
      "Aceptas tus emociones, reconociendo que algunas son agradables y otras no tanto.",
      "Prefieres experimentar ciertas emociones sobre otras.",
      "Tu bienestar interno puede verse afectado por las circunstancias externas en cierta medida. Continuar desarrollando tus habilidades de gestión emocional te permitirá fortalecer aún más tu autoliderazgo."
    ]
  },
  {
    key: "advanced",
    label: "Avanzado", //entre 34 y 35
    range: { min: 34, max: 35 },
    items: [
      "Posees un dominio excepcional de tus emociones, gestionándolas de manera óptima en todo momento.",
      "Mantienes una profunda consciencia de tus emociones, permitiéndote manejarlas con maestría y evitando que te afecten negativamente.",
      "Tienes una clara visión de tus pasiones y la capacidad de reavivar tu energía personal cuando esta decae.",
      "Demuestras un control excepcional sobre tus impulsos, reacciones y automatismos, gestionando tu energía emocional con gran habilidad.",
      "Afrontas las circunstancias con una actitud resiliente, buscando en tu fuerza interna la capacidad para recuperarte y seguir adelante.",
      "Aceptas la incertidumbre como parte inherente de la vida, entendiendo que el cambio es constante y que nada es seguro.",
      "Aceptas todas tus emociones sin juzgarlas como positivas o negativas, reconociendo su valor en tu experiencia personal.",
      "Mantienes una conexión profunda con tu bienestar interno, incluso en situaciones desafiantes.",
      "Si bien siempre hay espacio para el crecimiento personal, tu manejo de las emociones es ejemplar, demostrando una madurez emocional excepcional."
    ]
  }
];

export const mindFeedback = [
  {
    key: "initial",
    label: "Inicial", //entre 7 y 21 puntos
    range: { min: 7, max: 21 },
    items: [
      "Tu capacidad de autogestión mental posee un gran potencial para ser fortalecida.",
      "Existe una gran oportunidad para desarrollar tu consciencia sobre tus pensamientos y evitar que te afecten negativamente.",
      "Aumentar tu capacidad para distinguir entre la experiencia real y tus pensamientos te permitirá mejorar tu bienestar. Identificar y transformar los diálogos internos negativos que obstaculizan tu progreso personal te permitirá alcanzar tus metas con mayor facilidad.",
      "Desarrollar nuevos hábitos mentales más positivos te permitirá mejorar tu autogestión mental y alcanzar tu máximo potencial.",
      "Aprender técnicas para calmar tu mente te permitirá reducir el ruido mental y alcanzar mayor claridad.",
      "Desarrollar tu capacidad para meditar te ayudará a aquietar tu mente y mejorar tu bienestar.",
      "Tu capacidad para expresar tus opiniones de manera asertiva te ayudará a mejorar tus relaciones interpersonales.",
      "Asumir la responsabilidad de tus errores te permitirá aprender de ellos y crecer como persona.",
      "Desarrollar una mentalidad de aprendizaje continuo ampliará tus conocimientos y habilidades, impulsándote hacia el éxito."
    ]
  },
  {
    key: "mid-developed",
    label: "Intermedio", //entre 22 y 33 puntos
    range: { min: 22, max: 33 },
    items: [
      "Posees la habilidad para gestionar tu sistema mental de manera efectiva, con un gran potencial para alcanzar niveles aún más altos de desarrollo.",
      "En algunos momentos, puedes experimentar una falta de consciencia sobre tus pensamientos, lo que te lleva a involucrarte con ellos y dejarte afectar por tus diálogos internos. Sin embargo, tienes la capacidad para desarrollar una mayor consciencia y control en este aspecto.",
      "A veces puedes confundir la experiencia real con tus pensamientos (comentarios mentales o diálogos internos). Sin embargo, tienes la capacidad para desarrollar una mayor claridad y distinción entre ambos.",
      "Tus pensamientos pueden ser tanto prácticos y funcionales como improductivos o negativos, lo que puede afectar tu bienestar interno y tu progreso personal. Sin embargo, tienes la capacidad para identificar y transformar estos pensamientos negativos, potenciando tu desarrollo personal.",
      "Tus hábitos mentales actuales pueden ser aún más efectivos si los mejoras continuamente.",
      "Si bien tu mente puede ser ruidosa e inquieta en ocasiones, tienes la capacidad para calmarla y alcanzar mayor claridad mental.",
      "Valoras la preparación y la comprensión de la realidad antes de tomar una posición personal, lo que demuestra tu capacidad para tomar decisiones informadas y reflexivas.",
      "Demuestras una capacidad de aprendizaje a partir de tus errores, evitando culpar a otros y asumiendo la responsabilidad de tu propio crecimiento.",
      "Posees una actitud de aprendizaje continuo, buscando adquirir el conocimiento necesario para alcanzar tus metas."
    ]
  },
  {
    key: "advanced",
    label: "Avanzado", //entre 34 y 35
    range: { min: 34, max: 35 },
    items: [
      "Posees un dominio excepcional de tu mente, gestionándola de manera óptima en todo momento.",
      "Mantienes una profunda consciencia de tus pensamientos, observándolos sin involucrarte.",
      "Tienes una gran capacidad para discernir entre el pensamiento práctico y funcional y aquel que no aporta valor o incluso puede ser perjudicial para tu bienestar interno y obstaculizar tu progreso personal.",
      "Tus excelentes hábitos mentales son un pilar fundamental de tu bienestar y desarrollo personal.",
      "Es bueno continuar cultivándolos y fortaleciéndolos.",
      "Posees la habilidad de trascender el ruido mental y conectar con la profunda calma y quietud de tu ser interior.",
      "Antes de expresar una opinión, te tomas el tiempo para investigar y comprender a fondo la realidad, basando tus posiciones en hechos y conocimientos sólidos.",
      "Abordas tus errores con una actitud de aprendizaje, asumiendo la responsabilidad de tu crecimiento personal sin culpar a otros.",
      "Posees una insaciable curiosidad por el conocimiento, impulsándote a explorar y aprender constantemente.",
      "Disfrutas del proceso de aprendizaje y te dedicas con pasión a convertirte en un experto en tu área de especialización.",
      "Tu manejo de la mente es ejemplar, demostrando una madurez y control excepcionales. Sin embargo, siempre hay espacio para el crecimiento personal, así que continúa explorando nuevas herramientas y técnicas para alcanzar tu máximo potencial."
    ]
  }
];

export const behaviorFeedback = [
  {
    key: "initial",
    label: "Inicial", //entre 7 y 21 puntos
    range: { min: 7, max: 21 },
    items: [
      "Tu capacidad de autogestionar tus acciones posee un gran potencial para ser fortalecida.",
      "Existe una gran oportunidad para desarrollar tu consciencia corporal y ser más consciente de tus comportamientos y sus consecuencias.",
      "Adoptar hábitos saludables te permitirá mejorar tu bienestar físico y prevenir problemas de salud en el futuro.",
      "Valoras la estabilidad y la familiaridad en tu entorno, lo que te brinda seguridad y comodidad.",
      "Prefieres un proceso de cambio gradual y adaptado a tu ritmo, lo que te permite asimilarlo mejor y reducir el estrés.",
      "Desarrollar estrategias para afrontar los retos cotidianos te permitirá reducir las tensiones y el estrés, aumentando tu bienestar emocional.",
      "Fortalecer tu capacidad de adaptación te permitirá enfrentar las circunstancias difíciles o complejas con mayor flexibilidad y resiliencia.",
      "Mejorar tus habilidades de organización y planificación te ayudará a alcanzar tus objetivos de manera más efectiva.",
      "Explorar tu lado vulnerable y expresarlo de manera saludable puede fortalecer tus relaciones interpersonales y tu bienestar."
    ]
  },
  {
    key: "mid-developed",
    label: "Intermedio", //entre 22 y 33 puntos
    range: { min: 22, max: 33 },
    items: [
      "Posees una buena capacidad de gestionar tus comportamientos, con un gran potencial para alcanzar un nivel aún más alto de desarrollo.",
      "Mantienes una buena consciencia de tus comportamientos, que generalmente se alinean con tu propósito principal.",
      "Demuestras una inclinación hacia los hábitos saludables, aunque no siempre los mantienes de manera constante.",
      "Afrontas el cambio con una actitud proactiva, demostrando potencial para la creatividad y la innovación.",
      "Posees habilidades de resolución de problemas y gestión del estrés, lo que te permite afrontar las tensiones y retos cotidianos de manera efectiva.",
      "Si bien puedes experimentar algunas dificultades para adaptarte a circunstancias particularmente desafiantes, tu capacidad de aprendizaje te permite mejorar tu adaptabilidad con el tiempo.",
      "Demuestras tenacidad y perseverancia en la consecución de tus objetivos, aunque en situaciones muy complejas puedes reevaluar tus estrategias para optimizar tu enfoque.",
      "Si bien puedes ser reservado en cuanto a tu vulnerabilidad, en un entorno de confianza revelas tu autenticidad y conectas con los demás de manera genuina."
    ]
  },
  {
    key: "advanced",
    label: "Avanzado", //entre 34 y 35
    range: { min: 34, max: 35 },
    items: [
      "Posees un dominio excepcional de tus comportamientos, gestionándolos de manera óptima en todo momento.",
      "Mantienes una profunda consciencia de tus comportamientos, manejándolos siempre de manera positiva y constructiva.",
      "Has adoptado hábitos saludables que te aportan bienestar físico y mental a largo plazo.",
      "Abordas el cambio con una actitud proactiva y creativa, demostrando un gran potencial para la innovación.",
      "Posees habilidades excepcionales para la resolución de problemas y el manejo del estrés, lo que te permite afrontar los desafíos cotidianos con serenidad y resiliencia.",
      "Tu capacidad de aprendizaje y adaptación te permite superar cualquier obstáculo, incluso en situaciones difíciles y complejas.",
      "Demuestras una gran organización y planificación en la consecución de tus objetivos, lo que te acerca a alcanzarlos de manera efectiva.",
      "Elaboras planes estratégicos y perseveras con determinación hasta alcanzar tus metas más ambiciosas.",
      "Asumes tu vulnerabilidad con autenticidad y coraje, inspirando a otros a ser ellos mismos y conectar de manera genuina.",
      "Tu manejo excepcional de los comportamientos te permite vivir una vida plena y significativa, en constante aprendizaje y crecimiento personal."
    ]
  }
];

export const integralFeedback = [
  {
    key: "initial",
    label: "Inicial", //entre 21 y 65 puntos
    range: { min: 21, max: 65 },
    items: [
      "Tu sistema de inteligencia personal posee un gran potencial para ser fortalecido y optimizado.",
      "Existe una oportunidad para alinear tus emociones, mente y acciones para alcanzar tus objetivos de manera más efectiva.",
      "Desarrollar hábitos más saludables y productivos te permitirá potenciar tu progreso personal.",
      "Fortalecer tu capacidad de adaptación te permitirá afrontar las nuevas circunstancias, incluso las más desafiantes, con mayor flexibilidad y resiliencia.",
      "Desarrollar una actitud abierta al cambio te permitirá aprovechar las nuevas oportunidades y experiencias que se te presenten.",
      "Practicar la escucha activa y la empatía te ayudará a comprender mejor los puntos de vista de los demás y enriquecer tus propias perspectivas.",
      "Mejorar tu autoconciencia te servirá para comprender mejor tus emociones, pensamientos y comportamientos, permitiéndote tomar decisiones más conscientes y efectivas.",
      "Desarrollar estrategias para gestionar el estrés y las emociones te dará un mayor bienestar emocional y equilibrio mental.",
      "Explorar y comprender el funcionamiento de tu sistema de creación personal te permitirá aprovechar al máximo su potencial para alcanzar tus metas y objetivos."
    ]
  },
  {
    key: "mid-developed",
    label: "Intermedio", //entre 66 y 99 puntos
    range: { min: 66, max: 99 },
    items: [
      "Posees un sistema de inteligencia personal en desarrollo con un gran potencial para seguir creciendo.",
      "Experimentas momentos de alineación entre tus emociones, mente y acciones, lo que te permite avanzar hacia tus objetivos de manera efectiva.",
      "Has incorporado algunos hábitos positivos que contribuyen a tu progreso personal.",
      "Demuestras capacidad de aprendizaje y adaptación en ciertas circunstancias.",
      "En algunas ocasiones, defiendes tu punto de vista antes de comprender completamente el contexto.",
      "Esta es una oportunidad para desarrollar la escucha activa y la empatía.",
      "Cultivas la autoconciencia, siendo consciente de tus emociones, pensamientos y acciones en algunos momentos.",
      "Tienes una comprensión inicial del funcionamiento de tu sistema de inteligencia personal y colaboras para obtener beneficios en algunas ocasiones. Profundizar en este conocimiento te permitirá aprovechar al máximo su potencial."
    ]
  },
  {
    key: "advanced",
    label: "Avanzado", //entre 100 y 105
    range: { min: 100, max: 105 },
    items: [
      "Posees un sistema de inteligencia personal altamente desarrollado que opera de manera excepcionalmente positiva.",
      "Mantienes una alineación perfecta entre tus emociones, mente y acciones, impulsándote hacia el logro de tus objetivos con gran efectividad.",
      "Has cultivado buenos hábitos que aportan un valor inestimable a tu progreso personal.",
      "Demuestras una capacidad extraordinaria para aprender y adaptarte a cualquier circunstancia, incluso las más desafiantes.",
      "Priorizas la comprensión profunda del contexto antes de expresar opiniones o defender posturas personales, lo que te permite tener una visión más completa y objetiva.",
      "Tu propósito no es obtener la razón, sino aportar valor a la comprensión de la realidad, promoviendo un diálogo constructivo y enriquecedor.",
      "Posees una alta consciencia de tus emociones, pensamientos y acciones, lo que te permite tomar decisiones acertadas y vivir con plena autenticidad.",
      "Mantienes una conexión profunda con tu experiencia directa la mayor parte del tiempo, lo que te permite cultivar un bienestar interno profundo y una madurez emocional excepcional.",
      "Posees una comprensión profunda del funcionamiento de tu sistema de inteligencia personal, colaborando activamente para obtener el máximo beneficio de este potencial."
    ]
  }
];
