<template>
  <div>
    <div class="white pt-8 pb-8">
      <v-row no-gutters>
        <v-col cols="0" md="1" />
        <v-col cols="12" md="10" align="center">
          <report-section-title>
            {{ title }}
          </report-section-title>

          <xs-rotation-message />
        </v-col>
        <v-col cols="0" md="1" />
      </v-row>

      <div class="d-none d-sm-block d-print-block">
        <v-row
          no-gutters
          v-for="(_, factor) in factors"
          :key="factor"
          class="ma-0 pb-4 no-breakable margin-top"
          :class="{ 'pt-6': factor === 'serenity' }"
        >
          <v-col cols="0" md="1" />
          <v-col cols="12" md="10">
            <factor-power-table :factor="factor" :data="data" />
          </v-col>
          <v-col cols="0" md="1" />
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import factors from "@/model/disc/factors";

import FactorPowerTable from "@/views/reports/common/disc/FactorPowerTable.vue";

export default {
  name: "SectionFactorsPower",
  components: {
    "factor-power-table": FactorPowerTable
  },
  props: {
    reportType: String,
    data: {
      type: Object,
      required: false
    }
  },
  data() {
    return { factors };
  },
  computed: {
    isExtendedReport() {
      return (
        this.data &&
        (this.data.reportType === "EXTENDED_DISC" || !this.data.reportType)
      );
    },
    title() {
      return this.isExtendedReport
        ? "Poder de los Factores"
        : "El Poder de los Colores en tu Liderazgo";
    }
  }
};
</script>

<style scoped>
@media print {
  .no-breakable {
    break-inside: avoid;
    page-break-inside: avoid;
  }
  .margin-top {
    margin-top: 8em;
  }
}
</style>
