export default {
  dominance: {
    name: "Dominancia",
    adjective: "Dominante",
    letter: "D",
    description:
      "Enfocado en la obtención rápida de resultados y solución de problemas.",
    color: "var(--v-error-base)",
    colorName: "rojo",
    rgb: "255, 82, 82",
    backgroundStyle: "error",
    textStyle: "white--text",
    question: "¿Cómo respondes a los nuevos problemas?",
    factorPower: {
      keyConcept: "Los resultados",
      leftText:
        "Actitud amable, servicial, discreta y amigable. Comportamiento cauteloso, moderado y precavido. Sigue rutinas. Respuesta premeditada. Agradable. Actitud reflexiva, humilde y pacífica. Cumple con métodos de forma sistemática. Trabaja en equipo.",
      rightText:
        "Ágil resolviendo problemas nuevos. Responde de forma muy rápida, contundente y determinada. Obtiene resultados rápidos. De actitud activa, directa y arriesgada. Comportamiento creativo e innovador. Actitud proactiva."
    }
  },
  influence: {
    name: "Influencia",
    adjective: "Influyente",
    letter: "I",
    description:
      "Enfocado en las relaciones interpersonales y en la expresión de emociones.",
    color: "var(--v-warning-base)",
    colorName: "amarillo",
    rgb: "255, 193, 7",
    backgroundStyle: "warning",
    textStyle: "warning--text text--darken-4",
    question: "¿Cómo te relacionas con los demás?",
    factorPower: {
      keyConcept: "Las relaciones",
      leftText:
        "Relaciones profundas y estables con personas del entorno conocido. Poca expresividad emocional. Actitud reflexiva, pensamiento crítico y analítica. Racional. Pensamiento lógico. Introspección. De actitud contemplativa. Ritmo pausado y meticuloso.",
      rightText:
        "De actitud extrovertida a la hora de hacer nuevas amistades que se refleja en apertura y confianza hacia los demás. Facilidad para conversar de forma sociable y amena. Actitud dinámica. Personalidad carismática, optimista, persuasiva y convincente. Emocional. Negocia conflictos entre las personas. Impulsa ideas con entusiasmo."
    }
  },
  serenity: {
    name: "Serenidad",
    adjective: "Sereno",
    letter: "S",
    description:
      "Enfocado en el mantenimiento del ritmo constante, la persistencia y la estabilidad.",
    color: "var(--v-success-base)",
    colorName: "verde",
    rgb: "76, 175, 80",
    backgroundStyle: "success",
    textStyle: "white--text",
    question: "¿Cómo te adaptas al ritmo del medio ambiente?",
    factorPower: {
      keyConcept: "La estabilidad",
      leftText:
        "Agente de cambio e innovación. Expresa ideas libremente. Busca la novedad. Actitud dinámica, flexible y audaz. Ambiente libre de controles. De carácter intenso, arriesgado y animado.",
      rightText:
        "Paciente. De ritmo pausado. Estable. Trabaja en equipo. De actitud disciplinada y leal. Habilidad para escuchar y armonizar. Sigue métodos y sistemas. Consistente. De carácter calmado y reflexivo. Ecuánime. Predecible. Actitud serena."
    }
  },
  caution: {
    name: "Cautela",
    adjective: "Cauteloso",
    letter: "C",
    description:
      "Enfocado en el cumplimiento de los protocolos, las normas y estándares de calidad.",
    color: "var(--v-info-base)",
    colorName: "azul",
    rgb: "33, 150, 243",
    backgroundStyle: "info",
    textStyle: "white--text",
    question: "¿Cómo cumples con las normas y procedimientos?",
    factorPower: {
      keyConcept: "La cautela",
      leftText:
        "Original y libre. Autosuficiente. Independiente y poco convencional. Autónomo. Confidente. Con ingenio. Flexible. De actitud desinhibida y dinámica.",
      rightText:
        "Actitud analítica, cautelosa, diplomática y apegada a los protocolos. Respeto por la autoridad y las reglas. Hace las cosas correctamente desde el inicio. Usa el control y el seguimiento para lograr altos estándares de calidad. Aprecia el orden y la limpieza."
    }
  }
};
