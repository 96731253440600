<template>
  <o-card-form-layout
    title="Crear Cuenta"
    :actions="actions"
    :loading="loading"
  >
    <v-container class="pb-0">
      <v-row>
        <v-col cols="12" class="pt-0 pb-2 px-1">
          <v-alert
            color="primary"
            type="info"
            v-if="userInvite && userInvite.accountOwnerName"
          >
            Ha sido invitado por <b>{{ userInvite.accountOwnerName }}</b> a la
            plataforma de <b>LiderLab</b>.
          </v-alert>
        </v-col>

        <v-col cols="12" class="py-0 px-1">
          <o-email-field v-model="user.email" />
        </v-col>

        <v-col cols="12" class="py-0 px-1">
          <o-text-field
            v-model="user.displayName"
            label="Nombre y Apellido"
            required
          />
        </v-col>

        <v-col cols="12" class="py-0 px-1">
          <o-password-field v-model="user.password" />
        </v-col>
        <!-- <v-col cols="12" class="py-0 px-1">
          <v-checkbox
            class="mt-0"
            required
            v-model="acceptTermsAndConditions"
            :rules="rules.acceptanceRule"
          >
            <template v-slot:label>
              Acepto los&nbsp;
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="https://ovaldisc.com/terminos-y-condiciones-de-uso/"
                    @click.stop
                    v-on="on"
                  >
                    Términos y Condiciones de Uso
                  </a>
                </template>
                Ver en nueva pestaña
              </v-tooltip>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" class="py-0 px-1">
          <v-checkbox
            class="mt-0"
            required
            v-model="acceptPrivacyPolicy"
            :rules="rules.acceptanceRule"
          >
            <template v-slot:label>
              Acepto las&nbsp;
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="https://ovaldisc.com/politicas-de-privacidad/"
                    @click.stop
                    v-on="on"
                  >
                    Políticas de Privacidad
                  </a>
                </template>
                Ver en nueva pestaña
              </v-tooltip>
            </template>
          </v-checkbox>
        </v-col> -->
        <v-col cols="12" class="py-1 px-1">
          <o-captcha
            sitekey="6Ldg9ZgeAAAAAJ-IlmoFp6gVUtjktWa13blJ_wMb"
            @verify="markRecaptchaAsVerified"
          >
          </o-captcha>
        </v-col>
      </v-row>
    </v-container>
  </o-card-form-layout>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";
import authMixin from "@/mixins/authMixin";
import messageDialogMixin from "@/mixins/messageDialogMixin";
import messages from "@/plugins/messages";

export default defineComponent({
  name: "SignUp",
  mixins: [authMixin, messageDialogMixin],
  data() {
    return {
      user: {
        email: "",
        displayName: "",
        password: "",
        inviteId: undefined
      },
      actions: [
        { type: "submit", label: "Continuar", handler: this.signUp },
        {
          type: "secondary-right",
          label: "Ir al Inicio de Sesión",
          to: "/signin"
        }
      ],
      rules: {
        acceptanceRule: [v => v || "Debe aceptar para continuar"]
      },
      acceptTermsAndConditions: true,
      acceptPrivacyPolicy: true,
      loading: false,
      recaptchaVerified: false
    };
  },
  computed: {
    ...mapState({
      userInvite: state => state.userInvite.userInvite
    })
  },
  async created() {
    const inviteId = this.$route.params.inviteId;
    if (inviteId) {
      try {
        const userInvite = await this.$store.dispatch(
          "fetchUserInvite",
          inviteId
        );
        if (userInvite) {
          this.user.email = userInvite.email;
          this.user.displayName = userInvite.displayName;
          this.user.inviteId = userInvite.inviteId;
        }
      } catch (error) {
        this.errorDialog("Error al obtener los datos de la invitación");
      }
    }
  },
  methods: {
    async signUp(form) {
      if (this.recaptchaVerified) {
        if (form.validate()) {
          this.loading = true;
          try {
            await this.$store.dispatch("signUserUp", this.user);

            this.$store.dispatch("initDashboard");
          } catch (error) {
            this.errorDialog(error.message);
          } finally {
            this.loading = false;
          }
        }
      } else {
        this.errorDialog(messages.validation.tickRecaptcha);
      }
    },
    markRecaptchaAsVerified() {
      this.recaptchaVerified = true;
    },
    errorDialog(message) {
      console.error(message);
    }
  }
});
</script>

<style scoped></style>
