export const assessed = [
  {
    key: "job",
    label: "Tarea",
    items: [
      "Conozco las competencias de cada uno de mis colaboradores y sé cómo facultarlos para ser autónomos e innovadores.",
      "Establezco metas y objetivos claros para cada colaborador, definiendo en detalle las tareas y los criterios de éxito.",
      "Dejo claro a cada colaborador cómo se supervisará su desempeño durante la ejecución de las tareas.",
      "Enseño los protocolos y procedimientos, aclarando normas y límites para garantizar estándares de calidad en el desempeño de cada colaborador.",
      "Acompaño a cada uno de mis colaboradores en el logro de sus objetivos y les proveo de los recursos necesarios.",
      "Transformo los errores en oportunidades para la reflexión y el aprendizaje positivo.",
      "Evalúo y retroalimento frecuentemente el desempeño de cada colaborador."
    ]
  },
  {
    key: "motivation",
    label: "Motivación",
    items: [
      "Descubro las pasiones de cada colaborador y las utilizo para incrementar su motivación.",
      "Fomento la autonomía en la solución de problemas y la toma de decisiones con cada colaborador, solicitando sus opiniones e incorporando sus sugerencias.",
      "Asigno tareas desafiantes y estimulantes según el nivel de desarrollo y compromiso de cada colaborador.",
      "Promuevo la adaptabilidad, flexibilidad y el aprendizaje de cada colaborador, incluso en situaciones difíciles.",
      "Expreso a cada colaborador aliento, confianza y elogio por el trabajo bien hecho.",
      "Estimulo el estudio de las mejores prácticas y fomento la innovación y la creatividad con cada uno de mis colaboradores.",
      "Celebro junto a cada uno de mis colaboradores el logro de sus objetivos y sus buenos resultados."
    ]
  },
  {
    key: "relationships",
    label: "Relaciones",
    items: [
      "Descubro proactivamente cuáles son las necesidades personales de cada uno de mis colaboradores y les doy apoyo oportuno.",
      "Fomento la cercanía, la confianza y el respeto con cada uno de mis colaboradores.",
      "Reconozco las ocasiones especiales y los logros personales de cada uno de mis colaboradores para felicitarles y celebrar.",
      "En situaciones de conflicto con algún colaborador, abordo los desafíos con paciencia, respeto y tolerancia, siempre buscando que estos momentos se transformen en oportunidades para fortalecer nuestra relación y avanzar conjuntamente.",
      "Mantengo una comunicación constante con cada uno de mis colaboradores, asegurándome de que estén informados y escuchándolos atentamente cada vez que es necesario.",
      "Estimulo el trabajo colaborativo, la solidaridad y el compañerismo entre mis colaboradores.",
      "Valoro y fomento la autenticidad en cada uno de mis colaboradores, incluso cuando experimentan vulnerabilidad."
    ]
  }
];

export const applicant = [
  {
    key: "job",
    label: "Tarea",
    items: [
      "Conoce mis competencias y sabe en qué debe facultarme para que sea autónomo e innovador.",
      "Me fija metas y objetivos claros. Me define en detalle las tareas y los criterios de éxito.",
      "Me aclara cómo se supervisará mi desempeño durante la ejecución de la tarea.",
      "Me enseña los protocolos y procedimientos, aclarando normas y límites para garantizar estándares de calidad.",
      "Me acompaña en el logro de los objetivos y me provee los recursos necesarios.",
      "Transforma mis errores en oportunidades para la reflexión y el aprendizaje positivo.",
      "Me evalúa y da retroalimentación frecuentemente sobre mi desempeño."
    ]
  },
  {
    key: "motivation",
    label: "Motivación",
    items: [
      "Me pregunta qué es lo que me entusiasma y usa esta información para estimular mi motivación.",
      "Fomenta mi autonomía en la solución de los problemas. Permite mi participación en la toma de decisiones solicitando mi opinión e incorporando mis sugerencias.",
      "Me asigna tareas desafiantes y estimulantes según mi nivel de desarrollo y compromiso.",
      "Estimula mi aprendizaje y adaptación a los cambios, aunque las circunstancias sean difíciles.",
      "Me expresa aliento, confianza y elogio por el trabajo bien hecho.",
      "Me invita a estudiar las mejores prácticas y realiza actividades que fomentan la innovación y creatividad.",
      "Celebra el logro de mis objetivos y mis buenos resultados."
    ]
  },
  {
    key: "relationships",
    label: "Relaciones",
    items: [
      "Descubre proactivamente cuáles son mis necesidades personales y me da apoyo oportuno.",
      "Es cercano conmigo, fomentando una relación de confianza y de respeto.",
      "Celebra y me felicita por mis ocasiones especiales y mis logros personales.",
      "En situaciones de conflicto, aborda los desafíos con paciencia, respeto y tolerancia, siempre buscando que estos momentos se transformen en oportunidades para fortalecer nuestra relación y avanzar conjuntamente.",
      "Mantiene una comunicación constante conmigo, asegurándose de que esté informado y escuchándome atentamente cada vez que es necesario.",
      "Estimula el trabajo colaborativo, la solidaridad y el compañerismo entre nosotros.",
      "Valora y fomenta mi autenticidad, incluso cuando me siento vulnerable."
    ]
  },
  {
    key: "aspects",
    label:
      "De la siguiente lista, escoge 3 acciones que tu líder puede llevar a cabo para contribuir a impulsar tu desempeño y motivación.",
    items: [
      { id: 0, text: "Darme más retroalimentación sobre mi desempeño" },
      { id: 1, text: "Manejar mejor el estrés en momentos de tensión" },
      { id: 2, text: "Ser más planificado(a), organizado(a) y estratégico(a)" },
      {
        id: 3,
        text: "Confiar más en mi capacidad para tomar decisiones y resolver problemas"
      },
      { id: 4, text: "Ser más paciente frente a los desacuerdos" },
      {
        id: 5,
        text: "Unificar criterios con el resto de los gerentes o directores"
      },
      { id: 6, text: "Ser más flexible con sus puntos de vista y decisiones" },
      {
        id: 7,
        text: "Hablarme con respeto, principalmente cuando está molesto(a)"
      },
      {
        id: 8,
        text: "Apoyarme en la obtención de recursos para optimizar mis resultados"
      },
      { id: 9, text: "Que acepte sus errores cuando se equivoca" },
      { id: 10, text: "Reconocer y apreciar el buen trabajo que hago" },
      { id: 11, text: "Escucharme más" },
      {
        id: 12,
        text: "Que analice las situaciones antes de reaccionar de forma impulsiva"
      },
      { id: 13, text: "Ser más empático(a) conmigo y mis necesidades" },
      {
        id: 14,
        text: "Ayudar al equipo a integrarse y a superar los conflictos"
      },
      {
        id: 15,
        text: "Ser imparcial. Que no prefiera a unos compañeros más que a otros"
      },
      { id: 16, text: "Estimular una comunicación sincera y auténtica" },
      {
        id: 17,
        text: "Ofrecerme más información, de forma oportuna, en los procesos de cambio"
      },
      { id: 18, text: "Mejorar las relaciones interpersonales con el equipo" },
      {
        id: 19,
        text: "Demostrar más armonía interna y fortaleza de carácter ante situaciones adversas"
      },
      { id: 20, text: "Corregir mis errores de buena manera" },
      {
        id: 21,
        text: "Que se interese más en mi trabajo y en mi progreso dentro de la empresa"
      },
      {
        id: 22,
        text: "Que cuide de forma activa que haya un buen clima laboral"
      },
      { id: 23, text: "Que cumpla con lo que promete" }
    ]
  }
];
