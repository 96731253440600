export default {
  1.1: [
    "Firmeza y determinación. Rapidez y sentido de urgencia.",
    "Orientación a resultados.",
    "Gusto por expresar ideas y opiniones sin restricciones.",
    "Actitud agresiva e individualista para abordar los problemas, sin tomar en cuenta los inconvenientes de la opción escogida.",
    "Persona lógica e incisiva frente a los problemas.",
    "Responsable en el cumplimiento de los plazos. Autoexigente.",
    "Pasión por encontrar soluciones satisfactorias y rápidas a problemas complejos.",
    "Decisiones arriesgadas e impulsivas.",
    "Actitud espontánea, atrevida, valiente, aventurera, extrovertida, creativa y dinámica.",
    "Aspira tener autoridad total para asumir las responsabilidades.",
    "Carácter obstinado y terco. Pierde el control fácilmente. Impaciente.",
    "Bajo presión puede comportarse de forma egoísta.",
    "Exige un ritmo más rápido de lo normal a los demás para la obtención de resultados.",
    "Demasiado flexible con el uso de las normas y protocolos.",
    "Beneficia la obtención de resultados más que la armonía en las relaciones.",
    "Persona espontánea, extrovertida, creativa, innovadora, dinámica e independiente.",
    "Confía más en sus propias habilidades que en la de los demás."
  ],
  1.2: [
    "Tiende a ser firme y de actitud determinada.",
    "Suele actuar con rapidez y sentido de urgencia.",
    "Orientación a resultados.",
    "Tiende a expresar ideas y opiniones sin restricciones.",
    "Suele ser una persona lógica e incisiva frente a los problemas.",
    "Tiende a ser responsable en el cumplimiento de los plazos y autoexigente.",
    "Se apasiona por encontrar soluciones satisfactorias y rápidas a problemas complejos.",
    "Suele tomar decisiones arriesgadas e impulsivas.",
    "Tiende a desear tener autoridad total para asumir las responsabilidades.",
    "Suele ser de carácter obstinado y terco.",
    "Puede que pierda el control fácilmente. Impaciente.",
    "Bajo presión puede comportarse de forma egoísta.",
    "Suele exigir un ritmo rápido a los demás para la obtención de resultados.",
    "Puede ser flexible con el uso de las normas y protocolos.",
    "Tiende a beneficiar la obtención de resultados más que la armonía en las relaciones.",
    "Suele tener una actitud, valiente, atrevida, espontánea, extrovertida, creativa, innovadora, dinámica e independiente.",
    "Tiende a confiar más en sus propias habilidades que en la de los demás."
  ],
  2.1: [
    "Actitud extrovertida, segura de sí misma, espontánea, optimista y positiva.",
    "Convincente y con capacidad para persuadir.",
    "Sabe aliviar momentos difíciles.",
    "Promueve ideas para el logro de los resultados.",
    "No sabe lidiar con la soledad.",
    "Poco realista al evaluar a las personas por la tendencia a confiar sin discriminar.",
    "Bajo presión puede ser en exceso emocional, optimista y poco realista.",
    "Poco detallista.",
    "Actitud servicial y colaboradora.",
    "Conquistar y mantiene la atención de los demás a través del encanto personal.",
    "En momentos de presión, es casual e informal, sin dejar de darle la cara a los retos.",
    "Promueve nuevas amistades y trata con personas de todo tipo.",
    "Valora los sentimientos y las emociones.",
    "Asume más compromisos de los que puede cumplir.",
    "Bajo presión habla de más y se autopromueve.",
    "Busca el consenso en la toma de decisiones.",
    "Le cuesta corregir y aplicar disciplina porque no quiere afectar su aceptación social.",
    "Está disponible para los demás.",
    "Competente para negociar conflictos entre las personas de forma positiva.",
    "Logra la confianza y la apertura de las personas.",
    "Impulsa a los demás a lograr sus objetivos."
  ],
  2.2: [
    "Tiende a ser de actitud extrovertida, espontánea, optimista y positiva.",
    "Habitualmente es convincente y con capacidad para persuadir.",
    "Tiende a aliviar momentos difíciles.",
    "Promueve ideas para el logro de los resultados.",
    "No le gusta mucho la soledad.",
    "Tiende a ser poco realista al evaluar a las personas por confiar sin discriminar.",
    "Cuando está bajo presión tiende a ser en exceso optimista y poco realista.",
    "Suele ser poco detallista.",
    "En general es de actitud servicial y colaboradora.",
    "Conquista y mantiene la atención de los demás a través del encanto personal.",
    "En momentos de presión suele ser casual e informal, sin dejar de darle la cara a los retos.",
    "Tiende a crear nuevas amistades y tratar con personas de todo tipo.",
    "Suele valorar los sentimientos y las emociones.",
    "Con frecuencia asume más compromisos de los que puede cumplir.",
    "Bajo presión tiende a hablar de más, ser emocional y autopromoverse.",
    "Le suele costar corregir y aplicar disciplina porque no quiere afectar su aceptación social.",
    "Tiende a estar disponible para los demás.",
    "Con frecuencia es hábil para negociar conflictos entre las personas de forma positiva.",
    "Suele lograr la confianza y la apertura de las personas.",
    "Con frecuencia impulsa a los demás a lograr sus objetivos."
  ],
  3.1: [
    "Actitud positiva como miembro de un equipo.",
    "Actitud amistosa, amable y gentil.",
    "Sigue lineamientos.",
    "Actitud introvertida, reflexiva, serena, estable, calmada, pacífica y paciente.",
    "Implementa el plan para el logro de la meta.",
    "Sigue rutinas y repite el patrón de forma consistente.",
    "Ritmo pausado y tranquilo. Sin sentido de urgencia.",
    "Actitud disciplinada y metódica. Aprecia el orden y la limpieza.",
    "Persona tradicionalista, generosa, servicial y leal.",
    "Asume el cambio de forma positiva, cuando puede planificar y seguir una ruta clara.",
    "Se paraliza cuando enfrenta situaciones sin precedentes o cambios bruscos.",
    "Dificultad para establecer prioridades.",
    "Evita los conflictos. Los problemas con las personas le incomodan mucho.",
    "Planifica su crecimiento a largo plazo.",
    "De actitud reservada, indecisa, inflexible y despreocupada en situaciones de estrés.",
    "Enfrenta los desafíos y problemas de forma seria e inalterable emocionalmente.",
    "Socializa en grupos pequeños, con familiares o amigos cercanos.",
    "Se esmera en mantener relaciones profundas y duraderas."
  ],
  3.2: [
    "Suele tener una actitud positiva como miembro de un equipo.",
    "Con frecuencia es una persona amistosa, amable y gentil.",
    "Tiende a seguir lineamientos.",
    "Suele ser de actitud introvertida, serena, pacífica, generosa y paciente.",
    "Con frecuencia sigue rutinas y repite el patrón de forma consistente.",
    "Suele ser estable, de ritmo pausado y tranquilo, sin sentido de urgencia.",
    "Tiende a ser de carácter reflexivo.",
    "Puede que tenga una actitud disciplinada y metódica. Aprecia el orden y la limpieza.",
    "Con tendencia a ser tradicionalista, servicial y leal.",
    "Tiende a asumir el cambio de forma positiva, cuando puede planificar una ruta clara.",
    "Implementa el plan para el logro de la meta.",
    "Probablemente se paraliza cuando enfrenta situaciones sin precedentes o cambios bruscos.",
    "Le cuesta, muchas veces, establecer prioridades.",
    "Suele evitar los conflictos. Los problemas con las personas le incomodan.",
    "Tiende a planificar su crecimiento a largo plazo.",
    "Actitud reservada, indecisa, inflexible y despreocupada en situaciones de estrés.",
    "Procura enfrentar los desafíos y problemas de forma seria e inalterable emocionalmente.",
    "Habitualmente socializa en grupos pequeños, con familiares o amigos cercanos.",
    "Procura mantener relaciones profundas y duraderas."
  ],
  4.1: [
    "Sigue los protocolos en pro de alcanzar altos estándares de calidad.",
    "Analiza e investiga los hechos antes de tomar una decisión.",
    "Objetividad y precisión en el manejo de la información.",
    "Respeta los patrones, normas y reglas establecidas.",
    "Actitud introvertida, modesta, convencional.",
    "Plantea metas realistas y alcanzables.",
    "Visión de detalles, más que global.",
    "Comportamiento meticuloso con el propósito de que todo salga bien la primera vez.",
    "Se estresa y paraliza cuando hay que tomar decisiones rápidas, sin mucho análisis.",
    "Conecta el plan para lograr la meta.",
    "Puede ser pesimista, con tendencia a la crítica y difícil de complacer.",
    "Defensividad frente a las críticas.",
    "Desmenuza todas las opciones al punto de volverse ineficiente.",
    "Evita cometer errores.",
    "Busca tener todos los posibles riesgos bajo control.",
    "Actitud disciplinada, diplomática, de convicciones firmes y buenos modales.",
    "Cumple con los plazos y compromisos.",
    "Comportamiento distante y frío.",
    "Se autocontrola emocionalmente, pero cuando se molesta lo demuestra explotando.",
    "Prefiere participar en un grupo pequeño y establecer relaciones estrechas.",
    "En situaciones rutinarias decide de forma rápida y sistemática.",
    "Aplica la cautela en decisiones especiales e importantes."
  ],
  4.2: [
    "Tiende a seguir los protocolos en pro de alcanzar los estándares de calidad.",
    "Suele analizar e investigar los hechos antes de tomar una decisión.",
    "Conecta el plan para lograr la meta.",
    "Tiende a tener una actitud objetividad y precisa en el manejo de la información.",
    "Con frecuencia respeta los patrones, normas y reglas establecidas.",
    "Por lo general es de actitud introvertida, modesta, convencional.",
    "Habitualmente plantea metas realistas y alcanzables.",
    "Visión principalmente de detalles, más que global.",
    "Comportamiento meticuloso con el propósito de que todo salga bien la primera vez.",
    "Quizá experimente presión en los momentos en que hay que tomar decisiones rápidas.",
    "Tiende a paralizarse al sentir que no ha analizado suficiente información para tomar acción.",
    "Suele ser pesimista, con tendencia a la crítica.",
    "Quizá sea difícil de complacer.",
    "Suele comportarse a la defensiva frente a las críticas.",
    "Tiende a desmenuzar todas las opciones al punto de volverse ineficiente.",
    "Evita cometer errores, en la medida de lo posible.",
    "Tiende a desear tener los posibles riesgos bajo control.",
    "Tiende a presentar los hechos de forma directa, clara y honrada.",
    "Actitud principalmente disciplinada, diplomática, de convicciones firmes y buenos modales. Suele cumplir con los plazos y compromisos.",
    "Probablemente se comporte de forma autocontrolada, distante y fría.",
    "Cuando se molesta lo demuestra explotando.",
    "Prefiere participar en grupos pequeños y establecer relaciones estrechas.",
    "En situaciones rutinarias tiende a decidir de forma rápida y sistemática.",
    "Aplica la cautela en decisiones especiales e importantes."
  ],
  5: [
    "Gestiona las crisis y los cambios con sentido de urgencia.",
    "Resuelve problemas logrando la colaboración de forma persuasiva.",
    "Obtiene resultados a través de las personas.",
    "Impulsa a las personas a seguirle el ritmo.",
    "Bajo presión tiende a ser contundente e incluso de actitud agresiva.",
    "Puede tornarse una persona incisiva y argumentativa.",
    "La supervisión estrecha, los detalles y los controles, le generan tensión.",
    "Impulsa el cambio, de forma constante.",
    "Enfrenta los desafíos directamente, con creatividad, manteniendo la motivación del equipo.",
    "Visión global que incluye a la gente.",
    "La rutina le aburre.",
    "Actitud descuidada con las normas y los procedimientos.",
    "Actitud directa e influyente a la hora de resolver los problemas de la gente.",
    "Bajo presión tiende a ser impaciente y demandante.",
    "Actualiza su comportamiento, según la situación. Flexible.",
    "Actitud extrovertida, independiente y con iniciativa.",
    "Delega con determinación, firmeza y encanto.",
    "Actitud activa y dinámica que motiva a los demás a lograr los resultados.",
    "Si es necesario asume personalmente las tareas, sólo por el hecho de lograr la meta.",
    "De actitud competitiva, auto confiada y segura.",
    "Deseos de triunfo.",
    "Puede gestionar muchas tareas a la vez."
  ],
  6: [
    "Comportamiento creativo, dinámico, optimista, original e innovador.",
    "Resuelve problemas logrando la colaboración de forma persuasiva.",
    "Influye y motiva a las personas a través de su encanto y firmeza.",
    "Decide con sentido de urgencia.",
    "No le agradan los controles, ni la estrecha supervisión.",
    "Tendencia a la impulsividad.",
    "Resuelve problemas de forma creativa en especial los conflictos entre las personas.",
    "Refleja una imagen amigable y receptiva, mientras tiene todo bajo control.",
    "De actitud decidida, expresiva y extrovertida.",
    "Agente de cambio.",
    "Impaciente.",
    "Descuida los detalles.",
    "Se aburre con facilidad.",
    "Dificultad para seguir las normas y procedimientos.",
    "Tiende a ser de actitud terca y argumentativa.",
    "Altos niveles de exigencia personal y hacia los demás que puede generar estrés.",
    "Visión global en donde la gente forma parte.",
    "Visión innovadora de futuro.",
    "Busca ser independiente.",
    "Tendencia a asumir riesgos y correr con las consecuencias.",
    "Enfoque en la obtención de resultados rápidos, por medio de las personas."
  ],
  7: [
    "De actitud positiva y optimista, con buen sentido del humor.",
    "Logra resultados a través de las personas creando un ambiente amable, creativo de innovación y entusiasmo.",
    "Consciente de la fuerza que ofrece al equipo para el logro del éxito.",
    "Convence y promueve amigable y comunicativamente para lograr los resultados.",
    "La supervisión estricta le incomoda, prefiere que le dejen a sus anchas.",
    "Dificultad para seguir las normas y procedimientos.",
    "Tendencia a ser poco reflexivo y analítico.",
    "Entusiasta en el emprendimiento.",
    "Se siente bien tanto con extraños como con personas conocidas.",
    "Aprecia ser parte de un grupo,",
    "Alivia momentos de tensión con su manera amigable y extrovertida.",
    "No le gusta la rutina.",
    "Impulsividad en la toma de decisiones.",
    "De actitud abierta y accesible a todos.",
    "Refleja una actitud adaptable, pero en el fondo tiene muy claro lo que quiere.",
    "Bajo presión se puede tornar exigente y demandante.",
    "Descuida los detalles.",
    "Impaciente.",
    "Enfrenta la tensión y los obstáculos de forma casual.",
    "Visión global en donde las personas forman parte.",
    "Rápido en la toma de decisiones.",
    "Habilidad para resolver problemas entre las personas.",
    "Sabe inspirar a los demás con su visión personal."
  ],
  8: [
    "Con iniciativa.",
    "Amplio alcance de funciones.",
    "Consciente de los plazos.",
    "Tiende a ser impaciente.",
    "Dificultad para alinear sus priorizar con la de los demás.",
    "Busca activamente soluciones a los problemas.",
    "Impulsa a los demás a lograr las metas, de una manera gentil.",
    "Ritmo rápido y enérgico, equilibrado por la duda y la reflexión.",
    "Se adapta al entorno, si es necesario, para el logro de los objetivos.",
    "Cumple con lo que promete, mientras no interfiera en los resultados.",
    "Colabora con los demás, para avanzar en el alcance de la meta.",
    "Actitud innovadora, creativa, curiosa y reflexiva.",
    "Enfoque en las tareas y retos, con buen sentido de equipo.",
    "Actitud amigable y considerada, cuando todo está en calma.",
    "Bajo presión, elegirá enfocarse en los resultados en vez de atender a las personas.",
    "Cuando los demás no le siguen el ritmo asume una actitud individualista.",
    "En situaciones tensas pierde el tacto y la diplomacia.",
    "Tiende a no analizar mucho la información y los hechos disponibles.",
    "Cuida de la estabilidad del ambiente, para favorecer el logro de los objetivos.",
    "Actitud generosa y bondadosa con los demás, de una manera activa.",
    "Se integra al equipo sobre todo cuando siente su respaldo.",
    "Enfrenta de forma creativa las situaciones complejas",
    "Reflexiona antes de comprometerse.",
    "Cuenta con recursos para enfrentar los obstáculos."
  ],
  9: [
    "Enfrenta de forma creativa y metódica, las situaciones complejas.",
    "Ritmo rápido y enérgico, equilibrado por la duda y la reflexión.",
    "Actitud generosa y bondadosa con los demás, de una manera activa.",
    "Paciente, en situaciones rutinarias.",
    "En situaciones tensas pierde el tacto y la diplomacia.",
    "Acelerar el paso o lo ralentiza, dependiendo de la situación.",
    "Actitud impulsiva, indiferente y egoísta, bajo presión.",
    "Tiende a no analizar mucho la información y los hechos disponibles.",
    "Impulsa a los demás a lograr las metas, de una manera gentil.",
    "Consciente al asumir riesgos.",
    "Trabaja en equipo para avanzar en el alcance de la meta.",
    "Demuestra el estrés abiertamente, aunque trata de reflexionar.",
    "Es situaciones tensas le cuesta mantener la calma y la serenidad.",
    "Dificultad para priorizar.",
    "Actitud ecuánime, juiciosa, considerada y relajada, cuando todo está en calma.",
    "Actitud detallista y metódica en la solución de problemas rutinarios.",
    "Frente a los problemas nuevos tiende a la innovación y creatividad.",
    "Considera que los resultados y las necesidades de las personas, son igualmente importantes.",
    "En la adversidad el enfoque dependerá de lo que más requiera atención.",
    "En situaciones complejas asume una actitud firme, que matiza con amabilidad.",
    "Puede enfocar tanto lo global como el detalle, en las diferentes situaciones.",
    "Destreza y disposición para enfrentar tanto la rutina como la novedad.",
    "Concilia en momentos de tensión, de forma activa.",
    "Buena disposición frente al cambio, sobre todo si cuenta con información previa.",
    "Cumple con lo que promete, manteniendo el foco en los resultados.",
    "Reflexiona antes de comprometerse."
  ],
  10: [
    "Trabaja en equipo para resolver problemas complejos.",
    "Se integra a las personas alrededor de una meta compartida.",
    "Adaptación al ritmo del entorno para el logro de los resultados.",
    "En la rutina prevalece una actitud de calma, ecuanimidad y consideración matizada con cierto nivel de firmeza.",
    "Actitud generosa y bondadosa con los demás, de una manera activa.",
    "Presiona a las personas con amabilidad para lograr los objetivos.",
    "Actitud detallista y metódica para la solución de problemas.",
    "Equilibra el ambiente de forma comprensiva, mientras mantiene el foco en los resultados.",
    "Actitud reflexiva e imparcial en situaciones complejas.",
    "Consciente frente al riesgo.",
    "En la adversidad atiende las necesidades de las personas por encima de los resultados.",
    "Actitud indiferente, despreocupada o resignada, bajo presión extrema.",
    "Concilia en los conflictos, pero si es necesario, puede asumir una posición firme.",
    "Tiende a apoyarse en sistemas y métodos para orientarse en la ejecución de las tareas.",
    "En ocasiones puede tomar la iniciativa con una actitud creativa.",
    "Prefiere enfrentar el cambio contando información previa.",
    "Dificultad para priorizar.",
    "Tiende a no analizar mucho la información y los hechos disponibles.",
    "Tolera las dificultades y confía en que la situación va a mejorar.",
    "Enfrenta obstáculos complejos con creatividad.",
    "Colabora con los demás para avanzar en el alcance de la meta.",
    "Reflexiona antes de comprometerse.",
    "Cuida de la estabilidad del ambiente, para favorecer el logro de los objetivos."
  ],
  11: [
    "Se enfoca en resultados cuidando la calidad.",
    "Toma decisiones en un clima de incertidumbre.",
    "Foco en los resultados cuidando de los procesos de forma creativa.",
    "Prefiere pensar más que sentir. Mundo emocional muy limitado.",
    "Innova tomando en cuenta los hechos y los indicadores.",
    "Visión de futuro de forma objetiva.",
    "Apertura al cambio, calculando los costos, cuando la situación lo permite.",
    "Puede dudar en actuar bajo una fuerte presión.",
    "Vacilante, temperamental, ambivalente.",
    "En la calma es impaciente para lograr resultados, y es vacilante bajo presión.",
    "Visión global matizada ligeramente por una conciencia del detalle.",
    "Actitud realizadora e implementadora de soluciones creativas.",
    "Puede tornarse de actitud agresiva, terca y difícil de complacer, bajo presión.",
    "Estilo desafiante y brusco para relacionarse.",
    "Asume riesgos calculando las consecuencias.",
    "Impulsa cambios y acciones con autoridad.",
    "Cuida los estándares de calidad de manera dinámica y creativa.",
    "Comportamiento contundente matizado ligeramente con diplomacia.",
    "Rapidez en la toma de decisiones respaldada con pensamiento crítico y análisis.",
    "Su ritmo marca la pauta en el equipo.",
    "Disponible para la acción con una actitud racional."
  ],
  12: [
    "Se enfoca en la tarea de una forma creativa y analítica.",
    "Toma decisiones en un clima de incertidumbre.",
    "Visión de futuro de forma objetiva.",
    "Innova tomando en cuenta los hechos y los indicadores.",
    "Visión global y de detalle.",
    "Capaz de anticipar y resolver problemas de forma creativa.",
    "Asume riesgos calculando las consecuencias.",
    "Aplica métodos probados para lograr resultados rápidamente.",
    "Actitud directa y contundente matizada con diplomacia y sensibilidad.",
    "Analiza rápidamente todos los aspectos de una solución.",
    "Puede tornarse de actitud agresiva, terca y difícil de complacer, bajo presión.",
    "Impulso por obtener resultados rápidamente contrapuesto con el deseo de controlar.",
    "Prefiere pensar más que sentir. Mundo emocional muy limitado.",
    "Temperamental.",
    "Puede dudar en actuar, bajo una fuerte presión.",
    "Estilo desafiante y brusco para relacionarse.",
    "Ritmo ambivalente para actuar y decidir.",
    "Sensación de que todo es prioritario por lo que puede vacilar a la hora de actuar.",
    "Apertura al cambio, calculando los costos.",
    "Interés por alcanzar las metas con excelencia e innovación.",
    "Cuando las demandas son equilibradas se automotiva para lograr los objetivos.",
    "Aplica sistemas eficientes para lograr los objetivos, de forma rápida y respetando las normas y los procedimientos.",
    "Proyecta una imagen seria y profesional, de alguien que sabe lo que hace.",
    "Valida la calidad de los resultados con sentido de urgencia.",
    "Impulsa el cambio y la calidad con autoridad y diplomacia.",
    "Innova en procedimientos para alcanzar mayores niveles de calidad."
  ],
  13: [
    "Innova en los procedimientos.",
    "Integra las teorías y los sistemas probados para diseñar mejores soluciones.",
    "Concentra la atención en encontrar las respuestas correctas.",
    "Confiable y adaptable.",
    "Proyecta una imagen seria y profesional, de alguien que sabe lo que hace.",
    "Diseña nuevas formas para afrontar los problemas luego de analizar información.",
    "Impulsa el cambio, consciente de los riesgos y calculando los costos.",
    "Posee un enfoque objetivo, dinámico y analítico, para afrontar las tareas.",
    "Evalúa la información disponible decidir con un cierto sentido de urgencia.",
    "Sensación de que todo es prioritario por lo que puede vacilar a la hora de actuar.",
    "Ritmo ambivalente, de repente actúa con sentido de urgencia y en otras ocasiones se detiene a analizar y reflexionar en exceso, causando demoras.",
    "Comienza los proyectos pensando bien el plan y lo ejecuta de forma dinámica.",
    "Controla la calidad para la obtención de resultados.",
    "Respeto por las normas y los procedimientos en pro del logro de la meta.",
    "En ocasiones puede ser flexible.",
    "Actitud enfocada en el orden y la disciplina.",
    "Tendencia a ser una persona fría y distante.",
    "Prefiere analizar y solucionar problemas, más que estar relacionándose.",
    "Puede tornarse de actitud terca y difícil de complacer, bajo presión.",
    "Toma decisiones rápidas en la rutina.",
    "En situaciones importantes, nuevas o complejas, analiza con detalle antes de actuar.",
    "Visión de detalle y tendencia a la exactitud y precisión, de forma activa."
  ],
  14: [
    "Influencia a los demás, de forma amable, para lograr los objetivos compartidos.",
    "Aporta alegría, comprensión, colaboración, estabilidad y positivismo al equipo.",
    "Actitud amigable y abierta con las personas, sobre todo con amigos cercanos y socios.",
    "Cohesiona al equipo y se lleva bien con las personas.",
    "Muestra resistencia a los controles estrictos.",
    "Alto nivel de tolerancia con personas que perjudican la productividad.",
    "Capacita o aconseja, por su disposición para escuchar y motivar.",
    "Sabe aliviar ambientes tensos a través de comentarios agradables y graciosos.",
    "Se auto promueve, bajo presión.",
    "Dificultad para dirigir, corregir y administrar disciplina, cuando es necesario.",
    "Miedo al rechazo social y a perder la confianza del grupo cercano.",
    "Las necesidades de los demás pueden distraerle de la productividad.",
    "Administra mal el tiempo y no cumple con los plazos.",
    "Debido a la tendencia de involucrar a todas las personas, las decisiones pueden demorarse.",
    "Descuida los detalles cuando está bajo presión.",
    "No dedica mucho tiempo al análisis de hechos e indicadores.",
    "Adaptable socialmente.",
    "Construye relaciones positivas y de confianza a través de la calidez humana y la amabilidad.",
    "Con frecuencia confía en sus habilidades y en la de los demás, para agregar valor y asumir los compromisos.",
    "Necesita que le aclaren las expectativas sobre sus funciones.",
    "Aprecia la libertad de movimiento.",
    "Valora las emociones y los sentimientos.",
    "Cuando está en calma puede cumplir con los sistemas y métodos de forma lógica.",
    "Habilidad para resolver los problemas de la gente.",
    "Negocia los conflictos entre las personas, de forma imparcial, cuando es necesario."
  ],
  15: [
    "Integra al equipo y se lleva bien con las personas.",
    "Negocia los conflictos entre las personas buscando que todos ganen.",
    "Actitud amigable, colaborativa y abierta con las personas, en diversas situaciones, pero principalmente con el grupo de personas cercanas.",
    "Descuida los detalles cuando está bajo presión.",
    "No dedica mucho tiempo al análisis de hechos e indicadores.",
    "Prioriza las necesidades de las personas antes que la productividad.",
    "Apoya al equipo en el logro de la meta compartida.",
    "Confía en las personas, sin discriminar.",
    "Informalidad.",
    "De fácil trato. Se integra al equipo sin dificultad.",
    "Construye relaciones positivas y de confianza a través de la calidez humana.",
    "Administra mal el tiempo y no cumple con los plazos.",
    "Le cuesta dirigir, corregir y administrar disciplina, cuando es necesario.",
    "Las necesidades de los demás pueden distraerle de la productividad.",
    "Miedo al rechazo social y a perder la confianza de su grupo cercano.",
    "Retraso en la toma de decisiones por la tendencia a considerar a las personas de forma excesiva y no querer molestarlas.",
    "Alto nivel de tolerancia con personas que perjudican la productividad.",
    "Capacita o aconseja, por su disposición para escuchar y motivar.",
    "Puede ser obediente para no generar conflictos.",
    "Sabe aliviar ambientes tensos a través de comentarios agradables y graciosos.",
    "Sentido de urgencia bajo presión.",
    "Necesita que le aclaren las expectativas sobre sus funciones.",
    "Cuando está en calma puede cumplir con los sistemas y métodos de forma lógica.",
    "Habilidad para resolver los problemas de la gente."
  ],
  16: [
    "Se integra al equipo y se lleva bien con las personas.",
    "Actitud deliberada, reflexiva, alegre, optimista y amistosa.",
    "Le cuesta dirigir, corregir y administrar disciplina, cuando es necesario.",
    "Alto nivel de tolerancia con personas que perjudican la productividad.",
    "Actitud despreocupada e indecisa, cuando está bajo presión.",
    "Confía en las personas de forma optimista y poco racional.",
    "Retraso en la toma de decisiones por la tendencia de no querer molestar a las personas.",
    "Actitud paciente, detallista y reflexiva.",
    "Actitud colaborativa y generosa, enfocada en las relaciones.",
    "Prioriza las necesidades de las personas.",
    "Disposición a entrenar, aconsejar o asesorar a los demás.",
    "Las necesidades de los demás pueden distraerle de la productividad.",
    "Administra mal el tiempo y no cumple con los plazos.",
    "Apoya al equipo en el logro de la meta compartida.",
    "Actitud tradicionalista y estable.",
    "Aprecia que le expliquen los detalles, antes de enfrentar el cambio.",
    "De ritmo pausado.",
    "Cumple las normas para la convivencia.",
    "Implementa el plan para obtener resultados, con entusiasmo.",
    "Puede ser obediente para no generar conflictos.",
    "Necesita que le aclaren las expectativas sobre sus funciones.",
    "Actitud compasiva y habilidad perceptiva de los sentimientos de los demás."
  ],
  17: [
    "Optimista y entusiasta, de forma objetiva y sincera.",
    "Calidad y diplomacia en las relaciones sociales.",
    "Motiva al equipo a cumplir los protocolos.",
    "Colabora y se integra al equipo con buena disposición.",
    "Interactúa con personas de una manera segura y serena.",
    "Demuestra confianza y cercanía a las personas.",
    "Actitud extrovertida y expresiva, sobre todo en momentos de preocupación.",
    "Motiva a las personas a atender los detalles y la calidad.",
    "Capacidad perceptiva y analítica cuando afronta los problemas.",
    "Muy sensible a las críticas.",
    "Necesidad de reconocimiento social.",
    "Dificultad en el manejo del tiempo.",
    "Rigidez en momentos de tensión y hostilidad.",
    "Capaz de resolver los conflictos entre las personas.",
    "Motiva y acompaña al equipo a lograr altos estándares de calidad.",
    "Habilidad para sacar lo bueno de los malos momentos. Aprende y se adapta.",
    "Capaz de delegar, supervisar y hacer seguimiento, de una forma positiva.",
    "Toma las decisiones con optimismo, considerando lo que piensan los demás.",
    "Respalda las decisiones con el análisis de los hechos.",
    "Considera y atiende las necesidades de las personas.",
    "Le gusta rodearse de gente experta en lo que hace.",
    "Actitud diplomática a la hora de administrar disciplina.",
    "Asume la responsabilidad cuando no cumple los plazos."
  ],
  18: [
    "Entusiasmo y optimismo sincero.",
    "Habilidad para motivar el cumplimiento de los procedimientos.",
    "Calidad, positivismo y diplomacia en las relaciones sociales.",
    "Autoexigencia en el respeto de los plazos.",
    "Equilibrada importancia tanto en las relaciones, como en las normas y los procesos.",
    "Negocia los conflictos entre las personas con una visión de ganancia compartida.",
    "Interactúa con las personas de una manera segura y serena.",
    "Ritmo ambivalente para decidir. En momentos puede tener sentido de urgencia y a ratos se detiene a analizar muchos hechos e información.",
    "Actitud cautelosa ante el riesgo, que se equilibra con una mentalidad abierta al cambio.",
    "Asume tareas complejas, cuidando de la integración y motivación del equipo.",
    "Muy sensible a las críticas.",
    "Necesidad de reconocimiento social.",
    "Tendencia al perfeccionismo.",
    "Aporta la energía suficiente para llevar los proyectos con éxito, de principio a fin.",
    "Influye en los demás con encanto personal y profesionalismo.",
    "Dificultad en el manejo del tiempo.",
    "Dificultad para asumir los errores y los fracasos.",
    "Rigidez y cautela en momentos de tensión.",
    "Motiva a las personas a atender los detalles y la calidad.",
    "Motiva y acompaña al equipo a lograr altos estándares de calidad.",
    "Capaz de delegar, supervisar y hacer seguimiento, de una forma amistosa.",
    "Aprecia cuando le reconocen el trabajo bien hecho, de forma pública.",
    "Le gusta rodearse de gente experta en lo que hace.",
    "Colabora y se integra al equipo, con buena disposición.",
    "Actitud diplomática a la hora de administrar disciplina.",
    "Capacidad perceptiva y analítica cuando afronta los problemas."
  ],
  19: [
    "Cumplimiento de los estándares de calidad manteniendo la motivación del equipo.",
    "Decide después de analizar y pensar con criterio, tomando en consideración a los demás.",
    "Cumple las normas, los procedimientos y protocolos establecidos, con entusiasmo.",
    "Capacidad de organización, incluso en las relaciones sociales.",
    "Actitud diplomática, educada y cooperativa, con el equipo.",
    "Aprecia relacionarse con personas con quien comparte similares ideas.",
    "Se integra fácilmente con personas organizadas y enfocadas en la calidad.",
    "Visualiza con detalles el plan de acción.",
    "Enfoque en la precisión, exactitud y cuidado de los detalles.",
    "Actitud rigurosa con la calidad, los sistemas y el orden.",
    "Dificultad en el manejo del tiempo.",
    "Rigidez y cautela en momentos de tensión.",
    "Proyecta confianza por el manejo profesional de su área de especialidad.",
    "De actitud considerada con las necesidades de los demás.",
    "Decide con cautela, análisis e intuición.",
    "Actitud receptiva, en el ámbito profesional, para conocer nuevas personas.",
    "Prefiere relacionarse de forma cercana con un grupo pequeño de asociados.",
    "Agresividad latente, que puede manifestarse como pasividad.",
    "Dificultad para asumir los errores y los fracasos.",
    "Muy sensible a las críticas.",
    "Optimismo realista y objetivo.",
    "Asume los proyectos de principio a fin y cuida que sean hechos bien desde el principio.",
    "Autoexigencia en el respeto de los plazos.",
    "Actitud meticulosa y metódica para afrontar un proyecto.",
    "Negocia los conflictos entre las personas con una visión de ganancia compartida.",
    "Actitud diplomática a la hora de administrar disciplina.",
    "Delega, supervisar y hacer seguimiento, de una forma amistosa."
  ],
  20: [
    "Cuidado por los detalles.",
    "Estabilidad.",
    "Cuidado del orden y de la limpieza.",
    "Cumple con las normas, los procedimientos y los protocolos establecidos.",
    "Actitud conciliadora, no le gustan los conflictos entre las personas.",
    "Capacidad para organizar y coordinar.",
    "Persistente. Refina e implementa el plan para la obtención de resultados.",
    "Actitud moderada, disciplinada y autocontrolada.",
    "Colabora y trabaja en equipo.",
    "Actitud introvertida, meticulosa, metódica, receptiva, leal y discreta.",
    "Intensidad emocional no exteriorizada, sobre todo cuando no se logran los estándares.",
    "Parálisis para accionar por exceso de cuidado por los detalles y consideración a los demás.",
    "Prefiere la privacidad.",
    "Se adapta al ritmo del entorno.",
    "Actitud rígida cuando se trata de seguir un sistema o método.",
    "Resistencia al cambio.",
    "Evita las posturas que puedan ser hostiles, para no enfrentar conflictos.",
    "Cuidado por las personas, la información y los proceso, para el beneficio del producto.",
    "Asume las decisiones de forma tradicionalista y conservadora.",
    "Inspira calma, control y tranquilidad en un ambiente de tensión.",
    "Prefiere el cambio planificado paso a paso y no giros bruscos en la rutina.",
    "Paciente, amable, gentil y tolerante.",
    "Revisa la información y las expectativas de los demás, antes de tomar acciones.",
    "Prefiere relaciones con personas cercanas, de confianza y que le sean familiares."
  ],
  21: [
    "Cuidado del orden y la limpieza.",
    "Capacidad para trabajar en equipos especializados.",
    "Coordina que el plan se lleve a término.",
    "Cumple con sus responsabilidades consciente y cuidadosamente.",
    "Cuida que el trabajo sea hecho bien desde el principio.",
    "Aporta al equipo intuición, pensamiento crítico y analítico.",
    "Precaución en el cambio. Mide los riesgos.",
    "Dificultad para decidir cuando la situación es compleja o frente a cambios bruscos.",
    "Parálisis para accionar por exceso de análisis, cautela y cuidado por los detalles.",
    "Intensidad emocional no exteriorizada, sobre todo cuando no se logran los estándares.",
    "Actitud rígida, estricta y rigurosa, con las normas y procedimientos.",
    "Decisiones sustentadas en análisis de hechos y datos, tomando en cuenta las necesidades de los demás.",
    "Persistente. Refina e implementa el plan para la obtención de resultados.",
    "Actitud moderada, gentil, reservada, disciplinada y autocontrolada.",
    "Consciente del tiempo y de los plazos. Se adapta al ritmo del entorno.",
    "Dificultad para abrirse a nuevas personas. Tiende a ser dependiente del grupo íntimo.",
    "Le tiende a incomodar la incertidumbre y los imprevistos.",
    "Cuidado por las personas, la información, los procesos y la calidad del producto.",
    "Actitud diplomática, conciliadora, objetiva, paciente, educada y cooperativa.",
    "Prefiere la privacidad y relacionarse con personas de confianza, que le sean familiares.",
    "Actitud introvertida, meticulosa, metódica, receptiva, leal y discreta.",
    "Asume las decisiones de forma tradicionalista y conservadora.",
    "Prefiere el cambio planificado paso a paso y no giros bruscos en la rutina.",
    "Conoce los productos y los procesos al detalle."
  ],
  22: [
    "Se adapta a los métodos y procesos exitosos.",
    "Cautela para evaluar las posibles consecuencias de las decisiones y cambios, sobre todo si afectan las relaciones estables o los protocolos establecidos.",
    "Actitud profesional en su área de especialidad.",
    "Sensible y alerta frente a los problemas, controles y posibles errores.",
    "Coordina que el plan se lleve a término, manteniendo la calidad.",
    "Consciente del tiempo y de los plazos.",
    "Ritmo pausado para tomar decisiones.",
    "Precaución en el cambio. Mide los riesgos.",
    "Estimula al equipo a que analice los hechos e información y dejen de lado las emociones. Invita a tomar decisiones precisas y exactas.",
    "Conoce los productos y los procesos, al detalle.",
    "Actitud conciliadora e imparcial, que estabiliza ambientes de tensión.",
    "Parálisis para accionar, por exceso de análisis y cautela.",
    "Intensidad emocional no exteriorizada, sobre todo cuando no se logran los estándares.",
    "Actitud pesimista, distante y fría. Difícil de complacer.",
    "Actitud introvertida, meticulosa, metódica y discreta.",
    "Dificultad para abrirse a nuevas personas. Tiende a ser dependiente del grupo íntimo.",
    "Se torna de malhumor cuando no se logran los estándares de calidad.",
    "Incomodidad por la incertidumbre y los imprevistos.",
    "Actitud crítica y defensividad frente a las críticas.",
    "Las equivocaciones le generan tensión, por el miedo a estropear la calidad.",
    "Decisiones basadas en análisis de hechos y datos, tomando en cuenta a los demás.",
    "Actitud moderada, gentil, reservada, disciplinada y autocontrolada.",
    "Cuidado por las personas, la información y los procesos, para el beneficio del producto.",
    "Actitud diplomática, formal, tranquila, objetiva, paciente y educada.",
    "Prefiere la privacidad y relacionarse con personas de confianza, que le sean familiares."
  ],
  23: [
    "Logra resultados manteniendo al equipo motivado.",
    "Resuelve problemas a través de las personas, en un clima de entusiasmo.",
    "Se integra a un proyecto, con éxito, en cualquiera de sus fases.",
    "Asume las tareas de otros, cuando no responden a buen ritmo.",
    "Presiona a los demás, más de lo necesario, para el logro de los resultados.",
    "Poco apego al cumplimiento de las normas.",
    "En ocasiones puede exagerar con el optimismo y el entusiasmo y otras veces puede ser de actitud negativa, crítica y exigente.",
    "Individualista en la toma de decisiones, cuando considera que se demoran para avanzar.",
    "Asume el liderazgo cuando se integra al equipo, en pro del éxito del proyecto.",
    "Contagia a los demás de energía y optimismo realista, para alcanzar los objetivos.",
    "Usa las normas como una referencia flexible.",
    "Realista con las expectativas.",
    "Asume la responsabilidad por los resultados obtenidos.",
    "Ayuda a los demás a resolver sus problemas, para agilizar el logro de los resultados.",
    "Responde con firmeza o con amabilidad, según lo demande la situación.",
    "Enfoque en los resultados y en la solución de los problemas, sin descuidar las necesidades de las personas.",
    "Persuade a las personas para participar en los proyectos.",
    "Enfoque en proyectos realistas, con oportunidad de lograr resultados tangibles.",
    "Se motiva cuando le reconocen sus logros públicamente.",
    "Moviliza el cambio y motiva el logro de los objetivos."
  ],
  24: [
    "Influye en las personas para el logro de resultados.",
    "Habilidad para mantener al equipo cohesionado.",
    "Actitud inquieta y curiosa con tendencia a buscar nuevos proyectos y retos.",
    "Usa las normas como una referencia flexible.",
    "Enfoque en la obtención de resultados y en la solución de conflictos entre las personas.",
    "Actitud atrevida y arriesgada cuando se siente optimista.",
    "Actitud firma y determinada pero también considerada con las personas.",
    "Atiende las relaciones y las necesidades de las personas, con el foco puesto en el éxito.",
    "Actitud impaciente y agresiva cuando los demás no tienen sentido de urgencia.",
    "Posiciones firmes, aún en situaciones de tensión.",
    "Individualista en la toma de decisiones, cuando considera que se demoran para avanzar.",
    "Pierde de vista los detalles.",
    "Visión global y general de los proyectos y problemas.",
    "Toma la iniciativa en los proyectos asumiendo el rol de liderazgo.",
    "Suaviza la presión, si es estratégico para lograr los objetivos.",
    "Responde con firmeza o con amabilidad y comprensión, según lo demande la situación.",
    "Toma en cuenta los métodos y los sistemas, si es estratégico en el logro de los resultados.",
    "Puede ser de actitud tosca cuando está enfrentando problemas.",
    "Poco apego al cumplimiento de las normas.",
    "En ocasiones puede exagerar con el optimismo y el entusiasmo y otras veces puede ser de actitud negativa, crítica y exigente.",
    "Coopera con el equipo, para lograr los objetivos.",
    "Moviliza el cambio y motiva el logro de resultados.",
    "Ritmo rápido para lograr resultados, sin perder de vista las necesidades de las personas.",
    "Lidera con actitud firme, amigable, gentil y entusiasta."
  ],
  25: [
    "Habilidad para adaptarse al ritmo del entorno para lograr resultados.",
    "Asume retos junto al equipo, con el foco en el logro de los objetivos.",
    "Paciente, amable y gentil en la rutina y cuando el ambiente está en calma.",
    "Colabora con los demás para avanzar en la obtención de los resultados.",
    "Actitud impaciente e individualista cuando los demás no responden a su ritmo.",
    "Poco apego a las normas y reglas.",
    "Se integra y se lleva bien con las personas en situaciones normales.",
    "Atiende las necesidades de las personas para mantener un buen clima y favorecer los resultados.",
    "Pierde la calma y la quietud en momentos de tensión.",
    "Dificultad para priorizar.",
    "Egoísta e indiferente bajo presión.",
    "No invierte mucho tiempo en analizar información y datos.",
    "Consciente al asumir riesgos.",
    "Actitud activa en la solución de los conflictos entre las personas.",
    "Actitud innovadora y creativa frente a nuevos y complejos desafíos.",
    "Actitud detallista y metódica en la solución de problemas rutinarios.",
    "Reflexiona frente al estrés, como primer recurso para calmarse.",
    "Presiona de forma amable la obtención de resultados, cuando el tiempo está a favor.",
    "Tiende a cumplir con sus promesas y compromisos.",
    "Tanto los resultados como las necesidades de las personas le parecen importantes.",
    "Actitud curiosa y creativa matizada por el respeto a las metodologías establecidas.",
    "Visión global y de detalle frente a los problemas y proyectos.",
    "Actitud trabajadora y multitasking.",
    "Enfrenta los cambios de forma activa y positiva, si cuenta con información detallada."
  ],
  26: [
    "Habilidad y creatividad para solucionar problemas utilizando sistemas exitosos.",
    "Alcanza resultados trabajando en equipo.",
    "Actitud firme y determinada que se equilibra con un interés activo por el bienestar de los demás.",
    "Mantiene un ambiente de armonía y entusiasmo, en pro de lograr resultados rápido.",
    "Brinda soporte a las personas, para que superen sus brechas de desempeño y logren los objetivos.",
    "Actitud innovadora, curiosa y creativa.",
    "Valora las rutinas exitosas.",
    "Disfruta enfrentando desafíos y relacionándose con las personas.",
    "Individualista e independiente, cuando siente que los demás no responden a su ritmo.",
    "Egoísta, impaciente, impulsivo e indiferente, bajo presión.",
    "Actitud impaciente e impulsiva, cuando los demás no tienen sentido de urgencia.",
    "Dificultad para priorizar.",
    "Decide sin tomar en cuenta a los demás, cuando esto favorece los resultados.",
    "Poco apego a las normas y reglas.",
    "No invierte mucho tiempo en analizar información y datos.",
    "Resuelve problemas complejos cooperando con las personas.",
    "Cumple con los procesos, mientras favorezca el logro de la meta, a buen ritmo.",
    "Usa las normas como una referencia flexible.",
    "Actitud atrevida, valiente y arriesgada, cuando se siente en calma.",
    "Detallista, cuando cuenta con tiempo suficiente y el clima es favorable.",
    "Influye a través de su seguridad personal y gentileza.",
    "Reflexiona frente al estrés, como primer recurso para calmarse.",
    "Cuida de las necesidades de las personas, en pro del logro de los objetivos comunes.",
    "Visión global y de futuro, en donde las personas están incluidas."
  ],
  27: [
    "Alcanza resultados y toma decisiones con sentido de urgencia.",
    "Toma la iniciativa.",
    "Actitud extrovertida y competitiva.",
    "Negocia los conflictos entre las personas, cuando favorece el logro de los resultados.",
    "Pierde interés y se aburre con la rutina.",
    "Firme y de actitud brusca en situaciones tensas.",
    "Pierde la calma y se torna de mal humor, cuando se siente bajo presión.",
    "En ocasiones, no toma en cuenta las necesidades de las personas, por mantener el foco en los resultados.",
    "Flexibilidad, encanto personal y actitud motivadora, en circunstancias favorables.",
    "Inspira credibilidad y confianza.",
    "No controla los detalles y pasa por alto las normas, en situaciones de tensión.",
    "Crea redes sociales y estrecha lazos con personas claves.",
    "Consciencia del progreso de cada proyecto.",
    "Plantea metas ambiciosas y estimula a los demás a trabajar duro para alcanzarlas.",
    "Impaciente con quienes no comparten su sentido de urgencia.",
    "Aborda varios proyectos a la vez, manteniendo la eficiencia en el logro de resultados.",
    "Maneja las circunstancias para favorecer el logro de los objetivos, en un ambiente amigable y cuidando de la calidad.",
    "Actitud creativa, pionera, influenciadora y preocupada por la calidad.",
    "Ajusta su comportamiento, ante cada situación, en función del logro de los objetivos.",
    "Proyecta energía, fuerza y entusiasmo a los demás.",
    "Hace seguimiento desde el inicio, inspirando entusiasmo, para garantizar el logro rápido de resultados de calidad.",
    "Estimula a los demás a cumplir con los protocolos, si eso beneficia los resultados.",
    "Analiza rápidamente las opciones frente a los problemas."
  ],
  28: [
    "Innova manteniendo los estándares de calidad y el entusiasmo del equipo.",
    "Analiza rápidamente las opciones frente a un problema.",
    "Dirige a la gente para lograr los objetivos con excelencia.",
    "Negocia los conflictos entre las personas, cuando favorece el logro de los resultados.",
    "Favorece los resultados, cuidando de los procesos, de forma dinámica.",
    "Aporta optimismo al equipo, en el logro del éxito.",
    "Analiza información relevante, con sentido de urgencia, antes de decidir.",
    "Alcanza los resultados cumpliendo los plazos acordados.",
    "Administra adecuadamente los recursos disponibles.",
    "Actitud recursiva frente a los problemas y con las relaciones interpersonales.",
    "Pierde de vista los detalles por concentrarse en la visión global del proyecto.",
    "Actúa tomando en consideración las normas y la calidad, para beneficiar los resultados.",
    "Aspira alcanzar cada vez más autoridad y liderazgo.",
    "Actitud crítica frente a los aspectos débiles de las personas y del equipo.",
    "Se impaciente con quien no lleva su ritmo.",
    "Cuida de las relaciones claves.",
    "Actitud agresiva, malhumorada y dominante cuando la situación es tensa.",
    "En ocasiones se torna pesimista.",
    "Asume las tareas de otros para avanzar más rápido.",
    "Tiene creatividad para innovar sistemas y procedimientos, para mejorar el rendimiento y la calidad.",
    "Amigable y optimista en circunstancias favorables.",
    "Asume una gran diversidad de tareas, manteniendo el foco en lo importante.",
    "Prueba procedimientos más dinámicos y rápidos, para obtener resultados de calidad.",
    "Soluciona problemas e innova con actitud profesional."
  ],
  29: [
    "Alcanza resultados y analiza muchas opciones frente a un problema.",
    "Usa sistemas exitosos para lograr resultados de calidad, de forma rápida.",
    "Prefiere estar en roles con poder y autoridad para decidir e impulsar cambios.",
    "Visión global y de detalles.",
    "Cuida de que el clima sea positivo para el logro de los objetivos.",
    "Actitud dinámica que persuade al equipo a lograr resultados, cuidando los protocolos.",
    "Sentido de urgencia contrapuesto a la necesidad de analizar minuciosamente.",
    "Actitud directa y contundente matizada con diplomacia y sensibilidad por las personas.",
    "Actitud realizadora e implementadora de soluciones creativas.",
    "Impaciente con quien no mantiene el ritmo.",
    "Impulsa al equipo a seguir el ritmo en el logro de los objetivos, con conciencia de calidad.",
    "Anticipa y resuelve problemas de forma creativa.",
    "Responde con rapidez y objetividad en la toma de decisiones.",
    "En ocasiones se torna pesimista.",
    "Gerencia el cambio y los riesgos, calculando los costos.",
    "Actitud agresiva, malhumorada y dominante cuando la situación es tensa.",
    "Actitud crítica frente a los aspectos débiles de las personas y del equipo.",
    "Dificultad para establecer prioridades Siente que todo es importante.",
    "Negocia los conflictos entre las personas, si esto favorece los resultados.",
    "Analiza información relevante, a buen ritmo, antes de decidir.",
    "Alcanza los resultados cumpliendo los plazos acordados.",
    "Administra adecuadamente los recursos disponibles.",
    "Favorece el logro de resultados, cuidando de los procesos, de forma dinámica.",
    "Innova tomando en cuenta los hechos y los indicadores."
  ],
  30: [
    "Promueve e implementa cambios innovadores y de calidad en un clima dinámico y reflexivo.",
    "Enfoca los esfuerzos en aumentar el rendimiento y la calidad de forma sostenida y amable.",
    "Usa sistemas exitosos para lograr resultados, de forma rápida.",
    "Prefiere estar en roles con poder y autoridad para decidir y promover cambios.",
    "Actitud agresiva, malhumorada y dominante cuando la situación es desfavorable.",
    "Actitud firma, terca, agresiva o impositiva, cuando pierde la paciencia.",
    "Visión global con conciencia de calidad.",
    "Visión objetiva de futuro, con un adecuado nivel de optimismo.",
    "Actitud directa y contundente equilibrada ligeramente con diplomacia y sensibilidad.",
    "Enfoque en tareas y resultados con una cuota de entusiasmo y sensibilidad.",
    "Actitud distante, fría y desafiante, con poca atención a las emociones, bajo estrés.",
    "Dificultad para establecer prioridades.",
    "Ritmos ambivalentes, rápido y reflexivo al mismo tiempo.",
    "Actitud firme, con cierto interés por las personas, en situaciones favorables.",
    "Mundo emocional limitado por la tendencia a actuar y pensar, más que sentir.",
    "Cuida de mantener un clima de entusiasmo adecuado, para el logro de los objetivos.",
    "Actitud enérgica y dinámica que inspira a lograr resultados, cuidando los protocolos.",
    "Negocia los conflictos entre las personas, si esto favorece los resultados.",
    "Alcanza los resultados cumpliendo con los plazos acordados",
    "Administra adecuadamente los recursos disponibles.",
    "Creatividad para innovar sistemas y procedimientos, para mejorar el rendimiento y la calidad.",
    "Analiza información relevante, a buen ritmo, antes de decidir.",
    "Abarca muchos proyectos a la vez.",
    "Ritmo rápido al tomar decisiones que puede ralentizar, en ocasiones, para analizar.",
    "Asume riesgos y cambios, calculando las consecuencias."
  ],
  31: [
    "Asume retos, manteniendo el foco en el logro de resultados, con sentido de equipo.",
    "Se adapta al entorno y al equipo, en beneficio de los objetivos.",
    "Actitud generosa, de forma activa, para beneficiar el logro de los resultados.",
    "Presiona a los demás, con gentileza, para alcanzar la meta.",
    "En situaciones relajadas muestra una actitud amable y considerada.",
    "Actitud impulsiva, expresiva y firme en situaciones de tensión.",
    "En situaciones tensas prioriza los resultados, sobre las necesidades de las personas.",
    "Dificultad para priorizar.",
    "Colabora con lo demás para avanzar más rápido y controlar la calidad.",
    "Cuando no llevan su ritmo, prefiere hacer el trabajo, en vez de delegarlo.",
    "Tiende a dudar en situaciones adversas.",
    "Dirige y organiza la ejecución de las tareas, para garantizar los resultados y la calidad.",
    "Disponible para la acción y el riesgo, pero calculando los costos objetivamente.",
    "Innova en pro de aumentar el desempeño y los estándares de calidad.",
    "En la calma puede ser paciente y detallista.",
    "En situaciones tensas o complejas actúa con sentido de urgencia.",
    "Reflexiona frente al estrés, como primer recurso para calmarse.",
    "Actitud ética y leal en situaciones complejas, de forma activa.",
    "Responsable con sus tareas, plazos y metas.",
    "Advierte, de forma activa, cuando hay desviaciones injustificadas.",
    "Analiza la información y hechos para favorecer el logro rápido de los resultados.",
    "Atiende varios proyectos a la vez, trabajando duro para lograr los resultados.",
    "Visión global y de detalles.",
    "Visión de futuro tomando en cuenta las referentes y sistemas probados.",
    "Actitud creativa y lógica, para enfrentar retos complejos.",
    "Actitud curiosa e innovadora, matizada por cierto nivel de estabilidad."
  ],
  32: [
    "Transforma la teoría en soluciones prácticas.",
    "Enseña conceptos técnicos, de forma aplicable.",
    "Actitud generosa y bondadosa, de forma activa, para beneficiar el logro de los objetivos.",
    "Presiona a los demás, con gentileza, para obtener resultados.",
    "Dificultad para priorizar.",
    "Se adapta al entorno para beneficiar el logro de resultados, con consciencia de calidad.",
    "Actitud impulsiva, impaciente, individualista y firme en ciertas circunstancias.",
    "Coordina y colabora para avanzar más rápido y controlar la calidad.",
    "Cuando no llevan su ritmo, prefiere hacer el trabajo, en vez de delegarlo.",
    "Prioriza los resultados y la calidad sobre las necesidades de las personas en la adversidad.",
    "Tiende a dudar en situaciones adversas.",
    "Puede tornarse muy detallista, perfeccionista y de actitud rígida, con los protocolos.",
    "Puede ser ambivalente y pesimista en situaciones de estrés.",
    "Enfrenta retos y se enfoca en los resultados, con un buen sentido de equipo.",
    "Pensamiento creativo e innovador y a la vez crítico, analítico y metódico.",
    "Coordina la ejecución de tareas, impulsando la calidad y el cumplimiento de los plazos.",
    "En situaciones relajadas muestra una actitud amable y considerada.",
    "Disponible para la acción y el riesgo, calculando los costos objetivamente.",
    "Atiende varios proyectos a la vez, trabajando duro para lograr los resultados.",
    "Responsable con la ejecución de sus tareas y el logro de sus metas.",
    "Visión global y de detalles.",
    "Visión de futuro tomando en cuenta los referentes y sistemas probados.",
    "Actitud curiosa e innovadora, matizada por cierto nivel de estabilidad.",
    "Analiza rápidamente la información y hechos disponibles para tomar decisiones.",
    "Actitud creativa y lógica, para enfrentar retos complejos.",
    "Aprecia el cambio dentro de un ambiente confiable y estable."
  ],
  33: [
    "Sensibilidad humana con sentido práctico.",
    "Trabaja duro en múltiples asignaciones.",
    "Crea un clima de logro, mientras coordina que se mantenga la calidad.",
    "Se adapta al ritmo del entorno. Si es necesario, acelera el ritmo en pro de los resultados.",
    "Conecta con el equipo de forma enérgica y amistosa, para facilitar el alcance de la meta.",
    "Actitud generosa, gentil, paciente y bondadosa de forma activa, en condiciones favorables.",
    "Estabiliza activamente los momentos de tensión.",
    "Aprecia anticiparse a los cambios de forma dinámica, dentro de un ambiente confiable.",
    "Siente que los resultados y las personas son importantes. Prioriza según la situación.",
    "Actitud detallista, analizadora y metódica frente a problemas rutinarios.",
    "Actitud innovadora frente a nuevos desafíos o situaciones complejas.",
    "Visión global y de detalles.",
    "Visión de futuro tomando en cuenta los referentes y sistemas probados.",
    "Actitud curiosa e innovadora, pero al mismo tiempo aprecia los métodos, la estabilidad y lo conocido.",
    "Bajo presión es egoísta e indiferente y pierde la calma, el tacto y la diplomacia.",
    "Colabora con los demás para avanzar rápido, cuando hay tensión.",
    "Autosuficiente e individualista cuando los demás no se alinean al sentido de urgencia.",
    "Dificultad para priorizar.",
    "Poca apertura a conocer gente nueva.",
    "Tiende a dudar en situaciones adversas y volverse pesimista.",
    "En la calma puede ser paciente y detallista.",
    "En situaciones tensas o complejas actúa con sentido de urgencia.",
    "Pensamiento creativo e innovador y a la vez crítico, analítico y metódico.",
    "Consciente de sus compromisos y promesas.",
    "Disponible para la acción y el riesgo, pero considerando los referentes."
  ],
  34: [
    "Alcanza resultados rápidos, coordinando el cuidado de la calidad.",
    "Innova en los sistemas y en los métodos para lograr mejores resultados.",
    "Disciplina en la implementación de las estrategias.",
    "Trabaja en equipo si eso favorece el logro rápido de resultados de calidad.",
    "Sentido de urgencia en la rutina, en lo operativo y para el logro de los resultados.",
    "En situaciones complejas y en pro de los resultados, analiza las opciones con disciplina.",
    "Tiende a ser impaciente, perder la calma, el tacto y la diplomacia, bajo presión.",
    "Interés en la solución de problemas con innovación y disciplina.",
    "Se adapta al ritmo del entorno. Si es necesario, acelera en pro de los resultados.",
    "Natural impulsividad matizada con reflexión.",
    "Bajo presión es egoísta y con tendencia a preocuparse.",
    "Firme y de actitud agresiva frente a las oposiciones.",
    "Colabora con los demás para avanzar rápido y controlar la calidad, cuando hay tensión.",
    "Dificultad para priorizar.",
    "Actitud impulsiva y malhumorada en ciertas circunstancias.",
    "Tiende a dudar en situaciones adversas y volverse pesimista y exigente.",
    "Actitud curiosa, innovadora y creativa, pero con sentido crítico y objetivo.",
    "Dirige la implementación del plan para obtener resultados rápidos.",
    "Poca apertura a conocer gente nueva.",
    "Visión global y de detalles.",
    "Visión de futuro tomando en cuenta los referentes y sistemas probados.",
    "Autosuficiente e individualista cuando los demás no se alinean al sentido de urgencia.",
    "Responsable con el logro de metas y el cumplimiento de promesas y plazos.",
    "Coordina al equipo para el cumplimiento de los plazos y de la calidad.",
    "En la calma puede ser paciente y amable.",
    "En situaciones tensas o complejas actúa con sentido de urgencia y firmeza.",
    "Disponible para la acción, pero considerando los riesgos objetivamente."
  ],
  35: [
    "Se relaciona con muchas personas de una forma dinámica y amable.",
    "Motiva y persuade al equipo, para que logre los resultados.",
    "Aporta ideas innovadoras y convence con entusiasmo.",
    "Resuelve conflictos entre las personas.",
    "Se integra y trabaja en equipo.",
    "Ritmo rápido frente al cambio.",
    "Independiente cuando se requiere.",
    "Optimista y con buen sentido del humor.",
    "Actitud imaginativa, extrovertida, innovadora, auténtica, amable y generosa.",
    "Actitud adaptable y de fácil trato en circunstancias favorables.",
    "Firme en situaciones complejas.",
    "Tolera una cuota limitada de rutina, antes de aburrirse.",
    "Actitud testaruda bajo presión.",
    "Exceso de ambición.",
    "Impaciente, quiere terminar todo rápido. Descuida los detalles.",
    "Poca organización.",
    "Miedo a que no le acepten dentro del equipo.",
    "Toma acciones de forma impulsiva. Analiza poco antes de decidir.",
    "Actitud superficial en situaciones de estrés.",
    "Asume muchos compromisos a la vez y puede ser muy optimista con el logro de resultados.",
    "Delega mucho con poco seguimiento.",
    "Asume con gusto y naturalidad las posiciones de responsabilidad y liderazgo.",
    "Resuelve conflictos y problemas probando soluciones novedosas.",
    "Tiende a buscar variedad y dinamismo.",
    "Visión global en donde las personas están incluidas.",
    "Planifica, si es posible, a gran escala, con ideas novedosas."
  ],
  36: [
    "Sabe hacer nuevas amistades y mantener relaciones cercanas.",
    "Actitud colaborativa, considerada, firme y de apoyo con los demás.",
    "Aporta ideas innovadoras y convence con entusiasmo.",
    "Se integra y trabaja en equipo.",
    "Miedo a que no le acepten dentro del equipo.",
    "Considera a todas las personas en la toma de decisiones.",
    "Descuida los detalles.",
    "Actitud superficial en situaciones de estrés.",
    "Delega mucho con poco seguimiento. Poca organización.",
    "Expresa la molestia abiertamente en ambientes de confianza.",
    "Tolerante de quienes no dan la talla en el rendimiento.",
    "Toma las críticas a modo personal.",
    "Presta más atención a los sentimientos y necesidades de las personas, que a la calidad.",
    "Paciente y de buen humor cuando se relaciona.",
    "Persistente en el abordaje de las tareas y obtención de resultados.",
    "Logra los objetivos a través de la gente y del apoyo del equipo.",
    "Dificultad para impartir disciplina.",
    "Manejo inadecuado del tiempo y los plazos de entrega.",
    "Actitud positiva para negociar conflictos entre las personas.",
    "Habilidades para orientar, aconsejar o entrenar, activamente.",
    "Ritmo equilibrado en situaciones favorables. Sentido de urgencia bajo presión.",
    "Actitud poco reflexiva y analítica para tomar decisiones.",
    "Actitud respetuosa, cálida, sincera y receptiva con los demás, de forma activa.",
    "Inspira confianza.",
    "Motiva a los demás, a través de su optimismo y energía, para el logro de resultados."
  ],
  37: [
    "Autoconfianza, amabilidad y optimismo.",
    "Aporta ideas innovadoras y convence con entusiasmo.",
    "Enfrenta obstáculos con determinación y recursos.",
    "Motiva y persuade con entusiasmo y energía al equipo, para el logro de los objetivos.",
    "Ritmo rápido frente al cambio y la toma de decisiones.",
    "Delega mucho con poco seguimiento.",
    "Independiente e informal. Asume con naturalidad roles de liderazgo.",
    "Actitud adaptable y de fácil trato en circunstancias favorables.",
    "Actitud firme y agresiva en situaciones complejas.",
    "Poco cuidado con el cumplimiento de los estándares de calidad.",
    "Resuelve conflictos y problemas probando soluciones innovadoras.",
    "Aborda varias tareas a la vez. Tiende a buscar variedad y dinamismo.",
    "Visión global en donde las personas están incluidas.",
    "Planifica, si es posible, a gran escala, con ideas novedosas.",
    "Tolera una cuota limitada de rutina, antes de aburrirse.",
    "Actitud testaruda bajo presión.",
    "Impaciente, quiere terminar todo rápido. Descuida los detalles.",
    "Poca organización. Poco análisis e impulsividad, para tomar decisiones.",
    "No le agradan los controles, ni la estrecha supervisión.",
    "Regula el comportamiento según lo demanda la situación.",
    "Logra el apoyo de las personas para solucionar los problemas.",
    "Hábil resolviendo los conflictos de las personas.",
    "Le gusta pertenecer, integrarse y obtener la atención de los otros.",
    "Actitud dinámica, activa, decidida, ambiciosa, con cierto grado de reflexión.",
    "Altos niveles de exigencia personal y con los demás, que matiza con una natural sensibilidad hacia las necesidades de las personas.",
    "Busca activamente participar en la toma de decisiones."
  ],
  38: [
    "Actitud amable, optimista, gentil, colaborativa y entusiasta.",
    "Considera a todas las personas en la toma de decisiones.",
    "Motiva, integra e influye. Cohesiona al equipo de forma ágil.",
    "Paciente y de buen humor cuando se relaciona.",
    "Persistente en el abordaje de las tareas y obtención de resultados.",
    "Actitud positiva para negociar conflictos entre las personas.",
    "Presta más atención a los sentimientos y resultados, que a los estándares de calidad.",
    "Puede distraerse cuando toma decisiones, por prestar mucha atención a las personas.",
    "Habilidades para orientar, aconsejar o entrenar.",
    "Actitud respetuosa, cálida, sincera y receptiva con los demás, de forma activa.",
    "Se desenvuelve bien con cualquier persona, pero prefiere compartir con su grupo de confianza.",
    "Actitud informal, superficial, relajada e indiferente en situaciones de tensión.",
    "Le cuesta lidiar con la soledad.",
    "Impulsa el rendimiento a través del entusiasmo, energía y gentileza.",
    "Sensibilidad hacia las necesidades de los demás. Servicial. Ayuda a los demás activamente.",
    "Ejerce el liderazgo con amabilidad y encanto personal.",
    "Actitud poco analítica, para tomar decisiones.",
    "Sociable, leal y amable, con matices de firmeza.",
    "Se integra con facilidad y trabaja en equipo.",
    "Ritmo equilibrado al actuar y decidir.",
    "Toma la iniciativa y asume riesgos, tanto en lo laboral como en lo personal.",
    "Actitud sincera. Confía en los demás y aprecia que le demuestren confianza.",
    "Apoya al equipo para alcanzar las metas y obtener resultados.",
    "Construye redes de contactos claves.",
    "Usa metodologías probadas para alcanzar los resultados."
  ],
  39: [
    "Seguridad personal al ejercer el liderazgo. Inspira autoridad y confianza.",
    "Se integra al equipo de forma activa y logra la colaboración de la gente.",
    "Se enfoca en el logro de resultados cuidando de la calidad.",
    "Alinea al equipo con sus objetivos.",
    "Obtiene resultados de calidad a través de las personas.",
    "Se preocupa por formarse. Busca proyectar una imagen profesional.",
    "Tiende a ser menos firme y más condescendiente, cuando está bajo estrés.",
    "Actitud impulsiva en ciertas ocasiones.",
    "Su actitud puede confundir a las personas y llevarlos a abusar de su confianza.",
    "Establece límites ante grupos grandes, ya que es más impersonal",
    "Puede sobrestimar las habilidades de los demás y las suyas.",
    "Impaciente y poco detallista bajo presión. Cierto nivel de desorden.",
    "Busca mejores procedimientos para solucionar los problemas.",
    "Promociona la implementación de buenas prácticas.",
    "Flexible y prefiere trabajar con personas similares en ese sentido.",
    "Ritmo rápido con foco en resultados inmediatos.",
    "Impulsa el alto rendimiento en el logro de la meta, de principio a fin.",
    "Crea un buen clima a su alrededor.",
    "Se adapta a los cambios con facilidad.",
    "Puede ser exigente y firme en circunstancias normales.",
    "Forma y orienta a la gente hacia la calidad y los resultados.",
    "Se enfoca en construir una red de personas amplias y claves.",
    "Facilidad para relacionarse con diversos niveles jerárquicos.",
    "Actitud creativa, optimista, expresiva, espontánea y entusiasta.",
    "Le gusta tener autoridad para tomar decisiones.",
    "Capacidad de convocatoria y de empuje con el equipo."
  ],
  40: [
    "Impulsa proyectos de envergadura y maneja procesos complejos.",
    "Inspira autoridad, liderazgo y confianza.",
    "Equilibra la atención de las necesidades de las personas con el logro de resultados de calidad y el análisis de indicadores.",
    "Motiva a los demás a tomar en cuenta los procesos en el logro de resultados.",
    "En la rutina tiene un ritmo rápido para decidir, pero sin atropellar.",
    "Bajo tensión se traba con los detalles, análisis exhaustivos y deseos de perfección.",
    "Tiende a ser menos firme y más condescendiente, cuando está bajo estrés.",
    "Dificultad para encarar la confrontación y el conflicto con las personas.",
    "Puede perder el tacto y la diplomacia en situaciones de tensión.",
    "Vulnerable frente a las críticas.",
    "Relaja ambientes tensos con optimismo, diplomacia y sentido del humor.",
    "Se preocupa por prepararse y estar a la altura de los retos y desafíos.",
    "Motiva al equipo, con energía y optimismo, a lograr las metas cuidando de la calidad.",
    "Negocia los conflictos entre las personas.",
    "Crea un ambiente estimulante, competente, de integración y de logros.",
    "Da importancia a sus sentimientos y a los de los demás.",
    "Estimula al equipo a enfrentar y superar los obstáculos, de forma organizada.",
    "Descubre buenas prácticas y motiva a la gente a seguirlas.",
    "Impulsa el alto rendimiento en el logro de la meta, de principio a fin.",
    "Puede ser exigente y firme cuando es necesario.",
    "En ocasiones es de actitud detallista y sistematizada en la planificación.",
    "Asume roles claves en el equipo, en situaciones de presión.",
    "Construye redes amplias de personas claves.",
    "Cierto nivel de cautela ante los riesgos, que equilibra su impulsividad por seguir adelante.",
    "Abierto a los cambios y retos, a la hora de resolver problemas."
  ],
  41: [
    "Impulsa proyectos de envergadura y maneja procesos complejos.",
    "Optimismo y diplomacia enfocado en el logro de la meta.",
    "Inspira al equipo a cuidar de los detalles y de la calidad para lograr resultados ágilmente.",
    "Genera confianza, haciendo sentir a la gente como compañeros indispensables.",
    "Negocia los conflictos entre las personas de forma positiva.",
    "Se adapta al entorno autorregalando su comportamiento, según la situación.",
    "Autoconfianza en el manejo del liderazgo. Le gusta tener autoridad para tomar decisiones.",
    "Proyecta una imagen amigable, mientras mantiene el control.",
    "Si es necesario toma distancia para analizar, proyectando una imagen receptiva.",
    "Busca, creativamente, mejorar los procedimientos y protocolos formales.",
    "Consigue resultados a través de las personas.",
    "Impulsa el cambio y el dinamismo.",
    "Puede ser exigente y firme cuando es necesario.",
    "Actitud competitiva, persistente y convincente. Inspira autoridad y confianza.",
    "Sensación de autoimportancia.",
    "Tendencia a la impulsividad y a la impaciencia. Se aburre con facilidad.",
    "Corre el riesgo de sobrepasar sus límites de autoridad.",
    "Sentido de urgencia e intransigencia, en situaciones de estrés.",
    "Asume riesgos analizando las consecuencias.",
    "Actitud decidida, independiente, ambiciosa y optimista.",
    "Se interesa en ganar la confianza de los demás, activamente.",
    "Analiza para innovar en la solución de los problemas.",
    "Se preocupa por prepararse y estar a la altura de los retos y desafíos.",
    "Se interesa en superar los obstáculos cumpliendo con los protocolos.",
    "Visión de futuro de forma creativa, manteniendo un interés especial en la calidad."
  ],
  42: [
    "Lleva las ideas a la práctica e impulsa proyectos de envergadura.",
    "Maneja procesos complejos.",
    "Motiva a las personas a seguir los protocolos y cumplir los estándares de calidad.",
    "Inspira al equipo a cuidar de los detalles y de la calidad, para lograr resultados.",
    "Delega y hace seguimiento de forma positiva, para asegurarse del cumplimiento.",
    "Cuida de que se cumplan los plazos y la calidad, generando un buen clima.",
    "Gestiona con tacto las situaciones difíciles. Influye con diplomacia.",
    "Aplomo y autoconfianza.",
    "Actitud meticulosa y consciente, en la supervisión de los procesos.",
    "Planifica y sigue el cronograma, con entusiasmo.",
    "Actitud espontánea y expresiva matizada con diplomacia y gentileza.",
    "Bajo tensión se traba con los detalles, análisis exhaustivos y deseos de perfección.",
    "Ofrece mucha información cuando promueve ideas o productos.",
    "Requiere de muchas explicaciones y detalles, cuando le asignan nuevas tareas.",
    "Asume las críticas de forma personal. Actitud irónica en situaciones de estrés.",
    "Tiende a ser menos firme y más condescendiente, cuando está bajo tensión.",
    "Promete más de lo que puede asumir, generándole presión.",
    "Relaja ambientes tensos con humor y diplomacia.",
    "Analiza la información disponible.",
    "En la rutina tiene un ritmo rápido, pero sin atropellar.",
    "Equilibra activamente la dedicación a las personas, con la ejecución de las tareas y el cuidado de los detalles.",
    "Miedo a perder la independencia e individualidad.",
    "Se preocupa por prepararse y estar a la altura de las responsabilidades.",
    "Negocia los conflictos entre las personas de forma positiva.",
    "Sensible a las necesidades de los demás.",
    "Asume un rol clave dentro del equipo cuando hay presión.",
    "Busca activamente la atención de la gente y el reconocimiento de sus logros.",
    "Impulsa a los demás a superar los obstáculos."
  ],
  43: [
    "Mantiene redes de contactos y relaciones de forma metódica.",
    "Actitud profesional que influye positivamente en los demás.",
    "Enfoque sincero y estable, en las relaciones.",
    "Inspira y motiva a los demás a cuidar de los detalles y de la calidad.",
    "Actitud amistosa, generosa, amable y fácil de tratar.",
    "Delega y hace seguimiento, a lo largo de todo el proyecto.",
    "Orienta y forma con paciencia, entusiasmo y metodología, a las personas.",
    "Dificultad para tomar decisiones en situaciones inestables o complejas.",
    "Se esmera en cumplir con las promesas.",
    "Motiva a seguir los protocolos, normas y procedimientos, de forma positiva.",
    "En ocasiones, se excede en las expectativas de las habilidades propias y la de los demás.",
    "Se descuida, en situaciones de tensión.",
    "Persiste en aplicar los protocolos conocidos.",
    "Detallista y perfeccionista en la rutina.",
    "Toma las críticas de forma personal.",
    "Demanda explicaciones detalladas en procesos de cambio.",
    "Se preocupa por estar a la altura de sus responsabilidades.",
    "Actitud conciliadora en lugar de confrontadora, frente a los conflictos.",
    "Se enfoca en ganar-ganar, cuando negocia.",
    "Afronta sus responsabilidades de forma metódica, sistemática y persistente.",
    "Motiva la colaboración de los demás.",
    "Busca la aceptación de los demás y el reconocimiento de sus logros.",
    "Genera entusiasmo con su optimismo."
  ],
  44: [
    "Mantiene redes de contactos y relaciones de forma metódica.",
    "Entrena y orienta a los demás con paciencia.",
    "Actitud profesional que influye positivamente en los demás.",
    "Se esfuerza en conseguir resultados, a través de las personas, cumpliendo con los protocolos.",
    "Conforma equipo y logra que funcione de forma organizada.",
    "Enfoque sincero y estable, en las relaciones.",
    "Se esfuerza por cumplir con las promesas y los estándares de calidad.",
    "Inspira y motiva a los demás a cuidar de los detalles y de la calidad.",
    "Buen sentido del humor cuando el ambiente es relajado.",
    "Actitud seria frente a los problemas, tareas y responsabilidades.",
    "Promueve la colaboración entre las personas.",
    "Actitud amistosa, generosa, gentil y amable.",
    "Puede ser detallista y perfeccionista, en ocasiones.",
    "Dificultad para tomar decisiones en situaciones inestables o complejas.",
    "Delega y hace seguimiento a lo largo de todo el proyecto.",
    "Demanda explicaciones detalladas en procesos de cambio.",
    "Motiva a seguir los protocolos, normas y procedimientos, de forma positiva.",
    "Puede excederse en las expectativas de las habilidades propias y de la de los demás.",
    "Toma las críticas de forma personal.",
    "Busca la aceptación de los demás y el reconocimiento de sus logros.",
    "Actitud conciliadora, frente a los conflictos. Se enfoca en ganar-ganar, cuando negocia.",
    "Capacidad de análisis y de pensamiento crítico.",
    "Tiende a afrontar las responsabilidades de forma sistemática y positiva."
  ],
  45: [
    "Entrena y orienta, con paciencia.",
    "Mantiene redes de contactos. Inspira confianza, receptividad y apertura.",
    "Se integra y trabaja en equipo, dando soporte en el esfuerzo por alcanzar la meta.",
    "Prefiere relacionarse con un pequeño grupo de personas cercanas.",
    "Afronta sus responsabilidades de forma metódica, sistemática y persistente.",
    "Motiva y ofrece estabilidad a los demás. Promueve la colaboración.",
    "Da importancia a las necesidades de los demás y al cuidado de la calidad.",
    "Estimula, de forma positiva, el seguimiento de los protocolos y de las normas.",
    "Considera a los demás y evita molestar a la gente. Actitud generosa y amable.",
    "Maneja el tiempo con imprecisión. Dificultad a la hora de cumplir con los plazos.",
    "Se distrae de la tarea, ante los requerimientos personales de los demás.",
    "Dificultad para tomar decisiones en situaciones tensas e inestables.",
    "Actitud detallista y perfeccionista, en la rutina.",
    "Actitud descuidada y con tendencia a hablar mucho, en situaciones de tensión.",
    "Toma las críticas de forma personal.",
    "Actitud impulsiva, en ciertas ocasiones.",
    "Demanda explicaciones detalladas en procesos de cambio.",
    "Retrasa la toma de decisiones por considerar a todos los involucrados.",
    "Dificultad para aplicar disciplina por no querer generar conflictos.",
    "Persiste en aplicar protocolos conocidos.",
    "Actitud conciliadora frente a los conflictos. Negocia buscando que todos ganen.",
    "Enfoque sincero y estable, en las relaciones.",
    "Motiva a los demás a cuidar de los detalles.",
    "Logra la colaboración de los demás con sus habilidades comunicacionales y de análisis.",
    "Busca la aceptación de los demás y el reconocimiento de sus logros.",
    "Delega y hace seguimiento, a lo largo de todo el proyecto."
  ],
  46: [
    "Se adapta a diversas situaciones.",
    "Obtiene resultados a través de la gente de forma metódica.",
    "Influye en los demás con diplomacia.",
    "Trabaja en equipo con actitud sociable, cooperativa y optimista.",
    "Conforma equipo y logra que funcione de forma organizada.",
    "Enfoque sincero y estable en las relaciones. Tiende a confiar e inspirar confianza.",
    "Detallista en el cuidado del control de la calidad y el respeto a los procedimientos.",
    "Motiva a las personas con entusiasmo y gentileza.",
    "Aplomo personal. Actitud amplia y receptiva, a la hora de relacionarse con los demás.",
    "Logra buenos niveles técnicos y profesionales, si se lo propone.",
    "Ayuda a otros a calmarse en momentos de tensión.",
    "Buen sentido del humor, de forma adecuada.",
    "Entusiasma a la gente a llevar adelante los procesos.",
    "Falta de asertividad en algunas ocasiones.",
    "Dedica mucho tiempo a analizar información y hechos, antes de actuar.",
    "Actitud posesiva y muy sensible en situaciones de estrés.",
    "Gestiona mal el tiempo.",
    "Le falta firmeza en la toma de decisiones.",
    "Tiende a acomodarse mucho a los demás y ser condescendiente.",
    "Paciencia para entrenar y orientar a los demás.",
    "Administra adecuadamente sus responsabilidades y tareas.",
    "Asume las críticas de los demás de forma constructiva.",
    "Ritmo equilibrado, para lograr resultados.",
    "Actitud amistosa, de fácil trato y agradable, en situaciones favorables.",
    "Cumple con las normas y alcanza los estándares de calidad, manteniendo un buen clima."
  ],
  47: [
    "Asume responsabilidades con determinación y persistencia.",
    "Impulsa procesos de negociación, escuchando los diferentes puntos de vista.",
    "Logra resultados a través de la consistencia.",
    "Se apasiona cuando siente motivación por algo.",
    "Soluciona problemas, cuidando a las personas involucradas.",
    "Disposición para ayudar y orientar a las personas.",
    "Se relaja con las normas en los asuntos triviales.",
    "En situaciones importantes respeta los sistemas establecidos.",
    "Actitud intensa, cuando está bajo una fuerte presión y serena, en condiciones normales.",
    "Resistente a los cambios repentinos e importantes.",
    "Evade el cambio, encargándose de asuntos rutinarios o conocidos.",
    "Descuida el análisis de la información en la toma de decisiones.",
    "En la rutina tiene sentido de urgencia.",
    "Ritmo pausado para solucionar los problemas, en situaciones tensas o inestables.",
    "Actitud generosa, paciente y bondadosa, de una manera dinámica.",
    "Dificultad para priorizar sus tareas cotidianas. Actitud indecisa y vacilante, en ocasiones.",
    "Presiona con gentileza, para lograr los objetivos.",
    "Se adapta al entorno para lograr los objetivos.",
    "Trabaja duro y con energía, por una causa o por una meta.",
    "Asume el reto de progresar, en los sistemas y métodos establecidos.",
    "Colabora con los demás, para avanzar en los resultados, cuando el clima es tenso.",
    "Asume riesgos que no afectan su estabilidad.",
    "Crea un ambiente de cooperación y compañerismo.",
    "Incluye a la gente para crear soluciones a los problemas.",
    "Apoya al equipo y aporta innovación a los proyectos."
  ],
  48: [
    "Impulsa procesos de negociación, escuchando los diferentes puntos de vista.",
    "Facilidad para sostener los esfuerzos del equipo, con sentido de innovación.",
    "Se apasiona frente a los retos que implican la solución de problemas.",
    "Se orienta a servir a las personas de forma activa. Buen sentido de equipo.",
    "Ayuda a las personas a lograr sus metas. Crea un ambiente de compañerismo.",
    "Asume riesgos que no afectan su estabilidad.",
    "Presiona con gentileza, para obtener los resultados.",
    "Crea un buen ambiente, mientras sostiene el foco en los resultados.",
    "Planifica y desarrolla sistemas, para los proyectos o solución de problemas.",
    "Se enfoca en la gente, en el mantenimiento del ritmo y en la creatividad.",
    "Descuida el análisis de la información en la toma de decisiones.",
    "Actitud evitativa frente a los conflictos y las confrontaciones.",
    "Descuida los detalles en la rutina.",
    "Actitud intensa e impaciente cuando está bajo presión.",
    "Ritmo pausado para solucionar los problemas, en situaciones complejas o inestables.",
    "Dificultad para priorizar sus tareas cotidianas. Actitud indecisa y vacilante, en ocasiones.",
    "Resistente a los cambios repentinos e importantes.",
    "Se relaja con las normas en los asuntos triviales.",
    "En situaciones importantes respeta los sistemas establecidos.",
    "Trabaja duro, con energía y lealtad, por una causa o por una meta.",
    "Motiva a los demás para que mantengan el ritmo y estén enfocados.",
    "Visión global y de detalles en los conflictos.",
    "Asume un liderazgo dinámico, activo y pionero, en situaciones favorables.",
    "Actitud conciliadora, evitando tomar posiciones, en situaciones tensas.",
    "Actitud generosa, estable, paciente, colaborativa y bondadosa, de una manera dinámica."
  ],
  49: [
    "Negocia los conflictos entre las personas buscando resultados ganar - ganar.",
    "Motiva y sostiene los esfuerzos del equipo. Aporta estímulo y entusiasmo al ambiente.",
    "Sensibilidad para atender las necesidades de los demás.",
    "Coopera con los demás con el foco puesto en lograr resultados.",
    "Obedece para no generar conflictos, si lo considera necesario.",
    "Asume riesgos que no afecten su estabilidad.",
    "Se integra con facilidad y trabaja en equipo. Expresa confianza a los demás, de forma sincera.",
    "Actitud dinámica y liderazgo activo y pionero, en condiciones favorables.",
    "Actitud conciliadora y evita tomar posiciones, en situaciones tensas.",
    "Gentil presionando a los demás, para la obtención de resultados.",
    "Busca la aprobación y aceptación social.",
    "Descuida el análisis de la información en la toma de decisiones.",
    "Mal manejo del tiempo. Dificultades para cumplir con los plazos.",
    "Descuida la productividad y la toma de decisiones, por atender a las personas.",
    "Descuida los detalles en la rutina.",
    "Actitud intensa, impaciente y sentido de urgencia, cuando está bajo presión.",
    "Ritmo pausado para solucionar los problemas, en situaciones complejas o inestables.",
    "Dificultad para priorizar sus tareas. Actitud indecisa y vacilante, en ocasiones.",
    "Resistencia a los cambios repentinos e importantes.",
    "Aporta creatividad, optimismo y estabilidad al entorno.",
    "Actitud colaborativa, paciente, generosa y gentil.",
    "Se enfoca en las relaciones y en construir redes con personas claves.",
    "Influye con su actitud comprensiva y motivación, en la obtención de resultados.",
    "Cuida los protocolos, para que los resultados sean buenos desde el primer intento.",
    "Asume el reto de mejorar los sistemas establecidos."
  ],
  50: [
    "Negocia los conflictos entre las personas buscando resultados ganar - ganar.",
    "Actitud estable, cálida, generosa, equilibrada y dinámica.",
    "Motiva y sostiene los esfuerzos del equipo. Disponible para ayudar a los demás.",
    "Se enfoca en las relaciones y en lo social. Se integra y se lleva bien con la gente.",
    "Respeta los sistemas y métodos probados, aunque asume el reto de mejorarlos.",
    "Actitud dinámica, liderazgo activo y pionero, en condiciones favorables.",
    "Actitud conciliadora y evita tomar posiciones, en situaciones tensas.",
    "Gentil presionando a los demás, para la obtención de resultados.",
    "Se atasca en los procesos complejos. Le cuesta administrar el tiempo.",
    "Toma las críticas de forma personal.",
    "Descuida el análisis de la información en la toma de decisiones.",
    "Dificultades para administrar disciplina por el deseo de agradar a los demás.",
    "Descuida la productividad y la toma de decisiones, por atender a las personas.",
    "Permite a los demás que piensen a su manera y prueben sus propias ideas.",
    "Motiva la sinceridad dentro del equipo.",
    "Dificultad para priorizar las tareas cotidianas. Actitud indecisa y vacilante, en ocasiones.",
    "Resistencia a los cambios repentinos e importantes.",
    "Persistente, lleva el proyecto adelante de principio a fin.",
    "Asume riesgos que no afecten su estabilidad.",
    "Demuestra la molestia con su grupo íntimo y más familiar.",
    "Actitud de servicio y apertura, con miras a lograr resultados.",
    "Obedece para no generar conflictos, si lo considera necesario.",
    "Ritmo pausado para actuar o decidir. Le gusta ir a su ritmo y sentirse libre de estrés.",
    "Asume con sentido de urgencia las situaciones que lo ameriten.",
    "Los demás buscan su apoyo para un consejo o como coach."
  ],
  51: [
    "Se integra con los demás y se lleva bien con la gente. Trabaja bien en equipo.",
    "Concilia en los conflictos entre las personas. Buena disposición con los demás.",
    "Sensible con las necesidades de los demás. Construye relaciones positivas.",
    "Considera importante la calidad y los procesos y se esmera en comprenderlos.",
    "Actitud generosa, amigable, paciente, servicial, colaborativa y receptiva, en general.",
    "Tradicionalista, prefiere la estabilidad más que el cambio.",
    "Necesita explicaciones detalladas y tiempo antes de asumir los cambios.",
    "Optimista, con tendencia a confiar.",
    "Considera a los demás y evita molestar a la gente. Busca comprenderlos.",
    "Motiva, de forma positiva, el cuidado de los detalles.",
    "Siente presión cuando hay que tomar decisiones rápidas.",
    "No decide, hasta sentir la seguridad de que la decisión es la correcta.",
    "Actitud evitativa frente a los conflictos y las confrontaciones.",
    "Carácter terco y actitud cerrada cuando se estresa.",
    "Maneja el tiempo de forma imprecisa.",
    "Se esfuerza por cumplir los plazos, cuando está bajo presión.",
    "Dificultad para administrar disciplina por su deseo de agradar a los demás.",
    "Descuida la productividad y la toma de decisiones, por atender a las personas.",
    "Actitud sistemática y de ritmo rápido, con decisiones rutinarias.",
    "Reflexiona con decisiones complejas.",
    "Obedece para no generar conflictos, si lo considera necesario.",
    "Detallista. Le interesan los métodos, procesos, patrones y estándares más que la teoría.",
    "Se autocontrola emocionalmente. Busca la aprobación y el cariño de la gente.",
    "Prefiera relacionarse con un pequeño grupo de personas cercanas.",
    "Apoya y colabora con el equipo en el esfuerzo por alcanzar las metas."
  ],
  52: [
    "Coherente y paciente. Actitud conciliadora, optimista, amable, jovial y servicial.",
    "Actitud cautelosa, atenta y detallista.",
    "Trabaja bien en equipo. Colabora.",
    "De fácil trato y buen sentido del humor, en condiciones normales.",
    "Evita los conflictos con las personas, porque le incomodan mucho.",
    "Se concentra en la ejecución de la tarea y el cuidado de la calidad.",
    "Sabe seguir lineamientos y normas.",
    "Entusiasta para afrontar la tarea metódicamente.",
    "Analiza, evalúa y reflexiona para tomar decisiones con consciencia.",
    "Ritmo pausado, pero manteniendo energía y entusiasmo.",
    "Sigue los protocolos y los procedimientos, manteniendo una actitud positiva.",
    "Consigue de buena manera, que los demás cooperen.",
    "Consistente siguiendo los protocolos. Establece rutinas de trabajo y va a su ritmo.",
    "Asume el cambio de forma planificada y organizada.",
    "Prefiere la estabilidad. Se resiste al cambio, sobre todo a los cambios abruptos.",
    "Habilidad para enseñar a otros.",
    "Malhumor cuando está bajo presión.",
    "Dificultad para establecer prioridades.",
    "Actitud reservada, indecisa y se traba con los detalles, bajo presión",
    "Sensible a las críticas. Toma las críticas de forma personal.",
    "Le gusta tomar un proyecto de principio a fin, cuidando de que salga bien desde el inicio.",
    "Prefiere evitar los problemas de una tarea mal hecha.",
    "Prefiere a las personas cercanas, aunque es de actitud abierta a conocer gente nueva.",
    "Se relaciona sin perder de vista el cuidado de la calidad y de los detalles.",
    "La lógica, la investigación y la capacidad para comunicarse, son sus mejores recursos."
  ],
  53: [
    "Utiliza un juicio equilibrado aportando estabilidad al ambiente.",
    "Brinda apoyo en cualquier situación, siendo amigable, paciente y optimista.",
    "Actitud evitativa frente a los conflictos y las confrontaciones.",
    "Sensible y de actitud defensiva frente a las críticas. Teme hacer el ridículo.",
    "Tiende a ser de actitud rencorosa en situaciones de estrés.",
    "Se centra en mantener la estabilidad, las tradiciones y los métodos establecidos.",
    "Actitud conciliadora, modesta, generosa, diplomática, amable y servicial.",
    "Le gusta tomar un proyecto de principio a fin, cuidando de que salga bien desde el inicio.",
    "Sociable y capaz de identificar la individualidad de cada persona.",
    "Inspira confianza por su actitud profesional. Coherente.",
    "Actitud rígida y malhumorada cuando está bajo presión.",
    "Pensamiento crítico y capacidad de análisis. Evalúa y reflexiona para tomar decisiones.",
    "Exigente y rígido con el control de la calidad y de los detalles.",
    "Orienta a los demás en el uso de los sistemas y los estándares de calidad.",
    "Prefiere evitar los problemas de una tarea mal hecha.",
    "Necesita tiempo para adaptarse al cambio. Asume el cambio de forma planificada.",
    "Buen humor, flexible e informal en climas positivos.",
    "Actitud reservada, indecisa y se traba con los detalles, bajo presión.",
    "Se relaciona sin perder de vista el cuidado de la calidad y el seguimiento de los procesos.",
    "Se aturde cuando hay demanda de diversos resultados al mismo tiempo.",
    "Ritmo pausado, con poco sentido de urgencia.",
    "Actitud sistemática, analítica y metódica que ralentiza el logro de los resultados.",
    "Asume la solución de los problemas, siguiendo los métodos probados.",
    "Asume las responsabilidades con compromiso. No promete más de lo que puede hacer.",
    "Prefiere a las personas cercanas y de confianza. Actitud cautelosa ante los desconocidos."
  ],
  54: [
    "Brinda apoyo en cualquier situación, siendo amigable y optimista.",
    "Hace bien las cosas, es complaciente y paciente con los demás.",
    "Coherente. Utiliza un juicio equilibrado aportando estabilidad al ambiente.",
    "Asume la responsabilidad en sus compromisos. No promete más de lo que puede hacer.",
    "Aporta jovialidad al ambiente.",
    "Actitud conciliadora, generosa, diplomática, complaciente, amable y servicial.",
    "Evalúa, analiza y reflexiona para tomar decisiones y actuar.",
    "Cuida de lograr los estándares de calidad en sus resultados. Respeta los plazos.",
    "Actitud cautelosa, modesta, tradicional, atenta y detallista. Temor a hacer el ridículo",
    "Asume los retos siguiendo los protocolos, sin perder el foco en las personas.",
    "Le gusta tomar un proyecto de principio a fin, cuidando de que salga bien desde el inicio.",
    "Tiende a ser de actitud rencorosa en situaciones de estrés.",
    "Atiende un rol y tarea por vez. Se aturde con demandas de diversos resultados a la vez.",
    "Dificultad para priorizar por exceso de atención al detalle.",
    "Actitud rígida, reservada, indecisa y malhumorada cuando está bajo presión.",
    "Sensible y de actitud defensiva frente a las críticas.",
    "Actitud evitativa frente a los conflictos y las confrontaciones.",
    "Necesita tiempo para adaptarse al cambio. Se planifica y organiza frente al cambio.",
    "Establece rutinas de trabajo y va a su ritmo. Se abruma frente a los cambios repentinos.",
    "Actitud sistemática, analítica y detallista que ralentiza el logro de los resultados.",
    "Motiva y orienta a los demás a cumplir las normas y alcanzar los estándares de calidad.",
    "Buen humor, flexible e informal, en climas positivos.",
    "Aprecia las demostraciones de amistad.",
    "Prefiere evitar los problemas de una tarea mal hecha.",
    "Prefiere a las personas cercanas y de confianza. Actitud cautelosa ante los desconocidos."
  ],
  55: [
    "Se autogestiona, enfrenta problemas y trabaja hasta solucionarlos.",
    "Asume sus responsabilidades y retos de forma metódica y sistemática.",
    "Hace seguimiento de sus compromisos. Se compromete en las tareas que puede cumplir.",
    "Se integra y trabaja en equipo para acelerar el ritmo.",
    "Influencia a los demás con su sentido de responsabilidad y sinceridad.",
    "Se interesa por encontrar la mejor solución.",
    "Elige asumir las tareas en lugar de dejárselas a los demás.",
    "Innova en los métodos o en la solución de los problemas.",
    "Ve oportunidades en los retos y desafíos. Actitud recursiva para enfrentar los problemas.",
    "Se enfoca en alcanzar un excelente rendimiento, cuidando de la calidad.",
    "Pensamiento crítico, lógico y analítico. Ve los detalles y lo global de un problema.",
    "En momentos desfavorables, se refugia en lo conocido.",
    "Se apoya en los datos objetivos y no en el aspecto emocional, para tomar decisiones.",
    "Demuestra buen juicio y ética y espera lo mismo de los demás.",
    "Actitud defensiva frente a las críticas.",
    "Actitud evitativa frente a los conflictos en situaciones tensas.",
    "Se defiende cuando le toca asumir la responsabilidad en las equivocaciones.",
    "Poca receptividad con las ideas de los demás.",
    "Acapara responsabilidades de otros o se recarga de deberes, sin darse cuenta.",
    "Teme no lograr los resultados o no poder cumplir con los compromisos.",
    "Se da cuenta de las fallas y las desviaciones rápidamente.",
    "Da valor a los métodos y sistemas, manteniendo un nivel adecuado de flexibilidad.",
    "Se enfoca en lograr que todos ganen. Actitud colaborativa.",
    "Se esfuerza en hacer la tarea bien desde el primer intento, siguiendo los procedimientos.",
    "Es ejemplo para los demás cuando enfoca las situaciones desde una visión justa."
  ],
  56: [
    "Enfrenta sus responsabilidades de forma metódica, detallista y sistemática.",
    "Enfrenta retos y desafíos, con objetividad, lógica e innovación.",
    "Se autogestiona. Asume los problemas y trabaja en ellos hasta solucionarlos.",
    "Tiene un sentido equilibrado de la competencia.",
    "Aplica nuevas ideas, cuidando siempre de la calidad.",
    "Influencia en los demás con su sentido de responsabilidad.",
    "Le falta un poco de autoconfianza. Tendencia a la indecisión.",
    "La evaluación y el análisis ralentiza la toma de decisiones.",
    "Exceso de meticulosidad y cuidado, que bloquea la creatividad.",
    "Actitud introvertida, cuando el clima es favorable.",
    "Actitud poco entusiasta y receptiva con personas diferentes a sus referentes familiares.",
    "Actitud inflexible y agresiva cuando está bajo estrés.",
    "Teme no lograr los resultados o no poder cumplir con los compromisos.",
    "Paciente con los demás. Invita a la reflexión de forma activa.",
    "Sensible al ritmo del entorno y se adapta, enfocándose en los resultados.",
    "Le gusta pensar en ideas y soluciones, en un lugar privado y sin interrupciones.",
    "Actitud ordenada, limpia y organizada.",
    "Estable y aprecia tener tiempo suficiente para asumir los cambios.",
    "Se llena de valor para enfrentar lo inevitable.",
    "Actitud amable, reflexiva, gentil, trabajadora y diplomática.",
    "Trabaja en equipo. Apoya y colabora.",
    "Profundiza las relaciones con su pequeño grupo de personas cercanas.",
    "Cuida de la calidad y de la seguridad en el logro de los objetivos.",
    "Motiva a las personas a lograr los resultados y a cumplir con los procesos.",
    "Consciente del tiempo. Cumple con los plazos."
  ],
  57: [
    "Cumple con los estándares de calidad en la obtención de resultados.",
    "Enfrenta responsabilidades y retos de forma metódica, objetiva y sistemática.",
    "Promueve la investigación en la toma de decisiones.",
    "Persistente en el seguimiento de los protocolos, las normas y los métodos.",
    "Actitud inflexible, rígida y agresiva cuando está bajo estrés.",
    "Excesivamente exigente con las normas y los procedimientos.",
    "Actitud poco entusiasta y receptiva con personas diferentes a sus referentes familiares.",
    "Le falta un poco de autoconfianza en sus habilidades.",
    "Ritmo pausado en la toma de decisiones complejas por exceso de análisis.",
    "Poca receptividad con las ideas de los demás.",
    "Exceso de meticulosidad y cuidado, que bloquea la creatividad.",
    "Teme no lograr los resultados o no poder cumplir con los compromisos.",
    "Sensible al ritmo del entorno y se adapta manteniendo el foco en la calidad.",
    "Prefiere realizar sus tareas y analizar la información en privado, sin interrupciones.",
    "Hace equipo con un pequeño grupo de personas cercanas.",
    "Estimula a los demás a ser detallistas, analizar los indicadores y a cumplir con los plazos.",
    "Motiva a usar la lógica y la razón, poniendo a un lado las emociones.",
    "Actitud ordenada, limpia y organizada.",
    "Se llenará de valor para asumir cambios y enfrentar lo inevitable.",
    "Actitud diplomática y educada en situaciones favorables. Evita las confrontaciones.",
    "Trabaja bien en equipo. Colabora, coordina y apoya.",
    "Cuida de que los resultados sean buenos desde el primer intento.",
    "Aprecia tener cierto grado de libertad para modificar y mejorar los sistemas.",
    "Equilibrio entre su naturaleza humanista y su motivación al logro.",
    "Estable y aprecia que le den el tiempo necesario para asumir los cambios."
  ],
  58: [
    "Asume responsabilidades y retos de forma metódica, objetiva y sistemática.",
    "Persistente, realista y exigente en el seguimiento de los protocolos establecidos.",
    "Se enfoca en los sistemas, las normas, la calidad y los resultados.",
    "Actitud cuidadosa, detallista y meticulosa en la ejecución de la tarea y toma de decisiones.",
    "Sensible al ritmo del entorno y se adapta enfocándose en los sistemas.",
    "Actitud ordenada, limpia, introvertida, estable y organizada.",
    "Prefiere realizar sus tareas y analizar la información en privado, sin interrupciones.",
    "Quiere analizar toda la información y datos para tomar decisiones.",
    "Trabaja bien en equipo. Colabora, coordina y apoya.",
    "Hace equipo con un pequeño grupo de personas cercanas.",
    "Equilibrio entre su naturaleza humanista y su motivación al logro.",
    "Consciente con los tiempos de entrega.",
    "Actitud generosa y gentil, en general.",
    "Aprecia que se cuide de la seguridad y de la calidad en lo que se hace.",
    "Poca autoconfianza en sus habilidades. Indecisión en situaciones complejas.",
    "Actitud poco entusiasta y receptiva con personas diferentes a sus referentes familiares.",
    "Actitud inflexible, rígida y agresiva cuando está bajo estrés.",
    "Exceso de meticulosidad y cuidado, que bloquea la creatividad.",
    "Usa la lógica y la razón, poniendo a un lado las emociones. Evita las confrontaciones.",
    "Motiva a las personas a cumplir con sus responsabilidades.",
    "Sensible a las necesidades de los demás.",
    "Cuida de que los resultados sean buenos desde el primer intento.",
    "Aprecia tener cierto grado de libertad para mejorar los sistemas.",
    "Ritmo pausado en la toma de decisiones complejas y para adaptarse al cambio.",
    "Toma decisiones rutinarias con buen ritmo."
  ],
  59: [
    "Alcanza resultados de calidad e innova formas para elevar los estándares.",
    "Persiste para encontrar la mejor solución a los problemas, con dinamismo.",
    "Decide y acciona con sentido de urgencia, en el día a día.",
    "Analiza demasiado, en situaciones complejas, lo que ocasiona retrasos.",
    "Analiza para tomar decisiones complejas, manteniendo un optimismo realista.",
    "Relaja el ambiente con algún comentario divertido inesperado.",
    "Se enfoca en aprovechar el tiempo de forma productiva. Busca rendir al máximo.",
    "Actitud recursiva en la solución de los problemas.",
    "Pensamiento analítico y crítico con visión de futuro.",
    "Tiene un lado creativo e innovador, que le gusta explorar.",
    "Asume muchos compromisos a la vez, midiendo los riesgos. Cuida de cumplir los plazos.",
    "Integra, rápidamente, teorías e información diversa, para solucionar problemas.",
    "Afronta cambios y retos que le permiten demostrar sus capacidades.",
    "Le gusta ser parte de un equipo de expertos y afrontar retos especializados.",
    "Se concentra en lo importante para decidir. Calcula los riesgos de forma objetiva.",
    "No presta mucha atención al aspecto emocional. Actitud fría y retadora, bajo presión.",
    "Perfeccionista y difícil de complacer. Le falta empatía con las necesidades de las personas.",
    "Pierde la calma con facilidad en situaciones tensas.",
    "Duda, en ocasiones, entre tener un ritmo rápido o ser perfeccionista.",
    "Toma las críticas de manera personal.",
    "Piensa creativamente y analiza, para potenciar la calidad.",
    "Hace aportes que favorecen los procesos y los protocolos.",
    "Impulsa el cambio que favorece el éxito.",
    "Su ritmo marca la pauta y es su principal factor de influencia, en la rutina.",
    "Asume tareas meticulosamente para que los resultados salgan bien al primer intento."
  ],
  60: [
    "Obtiene resultados de calidad a través de la gente.",
    "Asume proyectos y metas complejas.",
    "Actitud equilibrada y sensata. Tacto, diplomacia y dinamismo en roles de liderazgo.",
    "Enfoque claro y sustentado, orientado a las soluciones.",
    "Interés tanto en obtener resultados de calidad como en las personas.",
    "En situaciones de conflictos busca analizar los hechos para comprender.",
    "Actitud meticulosa en el mantenimiento de los estándares de calidad.",
    "Asume varios compromisos a la vez y se esfuerza en cumplir con los plazos.",
    "Se enfoca en lograr las metas con criterio de calidad.",
    "Estimula el trabajo de equipo en pro de los objetivos compartidos.",
    "Le animan los proyectos especializados y la compañía de gente experta.",
    "Pensamiento analítico y habilidad creativa.",
    "Ritmo rápido en la rutina. Actitud inquieta.",
    "Detallista y perfeccionista bajo presión, lo que paraliza la toma de decisiones.",
    "Pierde el foco de lo importante, en situaciones de estrés.",
    "Firmeza y juicio frente a los riesgos. Se apega a la agenda y depende de la planificación.",
    "Es sobre exigente con las personas.",
    "Toma las críticas de forma personal.",
    "Prioriza la administración y conservación de los recursos.",
    "Se toma tiempo para analizar y evaluar en situaciones complejas.",
    "Impulsa cambios y novedades con energía, en ocasiones especiales.",
    "Respeta las normas y los estándares de calidad, con cierto nivel de flexibilidad.",
    "Crea, para el equipo, un ambiente de retos y de entusiasmo.",
    "Se adapta y se integra al equipo si eso favorece los resultados de calidad.",
    "Es consciente tanto de los hechos como de las necesidades de la gente, para decidir."
  ],
  61: [
    "Se conecta e influye en la gente para obtener resultados.",
    "Persistente para encontrar la mejor solución a los problemas, con dinamismo.",
    "Realiza tareas con altos estándares de calidad y moderado sentido de urgencia.",
    "Actitud equilibrada y sensata. Optimismo realista.",
    "Actitud considerada y exigente al mismo tiempo, pero sin incomodar.",
    "Se adapta y se integra al equipo si eso favorece los resultados de calidad.",
    "Negocia conflictos entre las personas buscando resultados ganar-ganar.",
    "Consciente de los plazos y de sus compromisos.",
    "Crea un clima retador, amigable y de alto desempeño.",
    "Hace seguimiento de las labores delegadas.",
    "Toma en cuenta a la gente y a los hechos, para decidir.",
    "Se motiva con los retos exigentes. Actitud inquieta. Difícil de complacer.",
    "Asume más tareas de las que puede cumplir.",
    "Teme que no le reconozcan sus logros y que le digan que no a sus propuestas.",
    "Irrealista al evaluar a los demás.",
    "Analiza demasiado, en situaciones complejas, lo que ocasiona retrasos.",
    "No valora abiertamente los aportes de las personas.",
    "En ocasiones toma decisiones impulsivas y optimistas.",
    "Motiva a los demás a dar lo mejor cuando la situación es compleja,",
    "Toma la iniciativa al afrontar tareas difíciles. Asume riesgos con optimismo realista.",
    "Motiva a las personas a la acción y a seguir las normas y los procesos.",
    "Estimula a las personas a resolver los conflictos, en ambientes tensos.",
    "Aporta la energía suficiente para llevar los planes hasta el final.",
    "Aporta mejoras, activamente, en los protocolos y en los sistemas establecidos.",
    "Actitud diplomática, directa y positiva cuando se acerca a las personas."
  ],
  62: [
    "Realiza tareas con altos estándares de calidad.",
    "Obtiene resultados a través de la gente.",
    "Actitud equilibrada y sensata. Optimismo realista. Toma riesgos calculados.",
    "Combina habilidades sociales con motivación al logro.",
    "Se adapta y se integra al equipo, manteniendo el foco en la calidad.",
    "Le gusta rodearse de gente experta. Dirige a los demás con tacto y diplomacia.",
    "Transmite optimismo creando un ambiente de apertura y logro.",
    "Si la calidad está en juego, protesta y reclama.",
    "Irrealista al evaluar a los demás.",
    "Teme que no le reconozcan sus logros y que le digan que no a sus propuestas.",
    "No es consciente de los esfuerzos de los demás.",
    "Perfeccionista y de actitud inquieta. Difícil de complacer en la adversidad.",
    "Se excede en análisis y detalles en situaciones complejas.",
    "Actitud evitativa frente a los conflictos con los demás, en ambientes desfavorables.",
    "Flexibiliza la planificación cuando hay presión.",
    "Se toma las críticas de forma personal.",
    "Hace seguimiento de la calidad manteniendo el entusiasmo de la gente.",
    "Enfoque claro y sustentado, orientado a las soluciones.",
    "Se esfuerza para que los resultados sean buenos desde el primer intento.",
    "Cuida de la calidad y de las necesidades de las personas.",
    "Pensamiento analítico, con el foco en producir ideas funcionales e innovadoras.",
    "Negocia los conflictos de la gente, con justicia.",
    "En ocasiones especiales es de actitud enérgica al impulsar cambios y novedades.",
    "Ritmo moderado en la rutina, para actuar y decidir.",
    "Asume muchos compromisos y se esfuerza en cumplir los plazos."
  ],
  63: [
    "Promueve sistemas de calidad de forma amable.",
    "Realiza tareas con altos estándares de calidad a un ritmo moderado.",
    "Actitud equilibrada, modesta y sensata, con un toque de optimismo realista.",
    "Ritmo equilibrado, para lograr resultados y tomar decisiones.",
    "Fino sentido del humor, resaltando la jovialidad, diplomacia y modales.",
    "Crea un clima amigable y evita las controversias.",
    "Actitud rígida con la planificación. Se esfuerza por cumplir con los plazos.",
    "En ocasiones asume más compromisos de los que puede cumplir.",
    "Le preocupa tener que renunciar a la calidad en pro del buen ambiente.",
    "Detallista y perfeccionista. Le exige meticulosidad a los demás.",
    "Optimista al juzgar a los demás.",
    "Pierde firmeza en sus convicciones en situaciones de estrés.",
    "Toma las críticas personales y asume una actitud a la defensiva.",
    "Le cuesta abrirse a ideas diferentes. Sensible a los cambios.",
    "Asume con buena actitud el trabajo en equipo.",
    "Se enfoca en la calidad y en las personas y es capaz de llevarse bien con la mayoría.",
    "Atiende situaciones difíciles con diplomacia.",
    "Actitud analítica y reflexiva. Da soporte y guía sobre estándares de calidad.",
    "Le gusta rodearse de personas con quienes comparte los mismos intereses.",
    "Aporta confianza al equipo con su optimismo y entusiasmo.",
    "Se motiva con los retos complejos y genera entusiasmo con diplomacia.",
    "Motiva a la gente a integrarse en pro de la meta común.",
    "Actitud organizada y metódica incluso socialmente.",
    "Pensamiento lógico, crítico y analítico con un buen nivel de positivismo.",
    "Equilibra la tendencia a ser racional con el aspecto emocional."
  ],
  64: [
    "Promueve sistemas de calidad de forma amable.",
    "Sociable y estable. Actitud diplomática, equilibrada y optimista de forma realista.",
    "Su ritmo es moderado, sin mucha urgencia ni mucha pausa.",
    "Combina habilidades sociales con cuidado de la calidad.",
    "Actitud modesta y activa. Se lleva bien con gente diversa.",
    "Entusiasma a la gente a cumplir con las normas y los estándares de calidad.",
    "Le gusta rodearse de personas con quienes comparte los mismos intereses.",
    "Aporta optimismo y crea conciencia sobre los riesgos y los controles necesarios.",
    "Normalmente maneja buen sentido del humor.",
    "En situaciones complejas actúa con diplomacia.",
    "Piensa de forma lógica, crítica y analítica con un buen nivel de positivismo.",
    "Trabaja en equipo creando un buen clima. Evita las controversias.",
    "Toma en cuenta las necesidades de las personas, sin descuidar la calidad.",
    "Logra que los demás se enfoquen, con entusiasmo, en lo importante de una tarea.",
    "Asume más compromisos de los que puede cumplir.",
    "Le cuesta abrirse a ideas diferentes.",
    "Actitud crítica de los procesos y de la calidad.",
    "Se siente vulnerable frente a los cambios abruptos.",
    "Actitud ambivalente, a veces pesimista y crítica y otras optimista y confiada.",
    "Perfeccionista en ocasiones, lo que puede ralentizar la toma de decisiones.",
    "Pierde firmeza en sus convicciones en situaciones de estrés.",
    "Toma las críticas personales y asume una actitud defensiva.",
    "Equilibra la tendencia a ser racional con el aspecto emocional.",
    "Actitud detallista y meticulosa.",
    "Logra, con su seguimiento, que un plan se desarrolle exitosamente."
  ],
  65: [
    "Actitud social, diplomática y optimista de forma realista.",
    "Motiva el cumplimiento de los procedimientos y el cuidado de los detalles.",
    "Promueve sistemas de calidad de forma amable.",
    "Combina habilidades sociales con cuidado de la calidad en sus responsabilidades.",
    "Aprecia el equilibrio y la estabilidad, en un ambiente dinámico.",
    "Autoexigente con el respeto de los plazos.",
    "Se relaciona con diplomacia, entusiasmo y actitud positiva, de forma sincera.",
    "Aporta energía y colaboración para llevar el plan hasta el final.",
    "Ritmo equilibrado para decidir y tomar acción.",
    "Asume más compromisos de los que puede cumplir.",
    "Ritmo pausado debido al análisis de mucha información, en situaciones complejas.",
    "Actitud ambivalente, a veces pesimista y crítica y otras optimista y confiada.",
    "Toma las críticas de forma personal y asume una actitud defensiva.",
    "Le cuesta asumir las derrotas y los errores.",
    "Actitud cautelosa en situaciones hostiles.",
    "Dirige con tacto, comprendiendo las necesidades de los demás.",
    "Asume cambios con mentalidad abierta, cuando cuenta con tiempo para adaptarse.",
    "Negocia los conflictos entre las personas, con una visión ganar-ganar.",
    "Asume tareas especializadas, siendo clave en la motivación del equipo.",
    "Influye con su encanto personal y su actitud profesional.",
    "Actitud terca, matizada con simpatía, para no incomodar.",
    "Actitud cuidadosa frente a los riesgos.",
    "Gestiona con tacto las situaciones difíciles.",
    "Confía en los demás y en sus propias habilidades, en situaciones favorables.",
    "Aprecia contar con datos e información para decidir, sin caer en la obsesión."
  ],
  66: [
    "Sensible a las necesidades de las personas.",
    "Actitud diplomática, gentil, analítica, reflexiva, paciente y modesta.",
    "Cuida de los detalles y de la calidad, buscando la precisión y exactitud.",
    "Se enfoca tanto en la calidad como en las personas.",
    "Se lleva bien con una diversidad de gente.",
    "Se esfuerza por cumplir con sus tareas y responsabilidades rutinarias.",
    "Actitud crítica de los procesos y de la calidad.",
    "Experimenta ansiedad frente al rechazo de sus aportes.",
    "Carácter malhumorado y actitud terca, en situaciones de estrés.",
    "En ocasiones, evita a las personas para poder trabajar en privado.",
    "Muy entusiasta con sus propias ideas y proyectos.",
    "Toma las críticas de forma personal y asume una actitud defensiva.",
    "Puede ser muy tradicionalista y apegado al pasado.",
    "Dificultades para priorizar. Requiere de tiempo y planificación para asumir los cambios.",
    "Ritmo pausado para actuar. Analiza hechos e información antes de decidir.",
    "Actitud cerrada a las ideas diferentes.",
    "Actitud cautelosa en situaciones hostiles.",
    "Aporta estabilidad y equilibrio al entorno.",
    "Consciente del tiempo, al asumir compromisos.",
    "Transmite la importancia de respetar las normas y cuidar de la calidad.",
    "Dirige y enseña con tacto y empatía, comprendiendo las necesidades de las personas.",
    "Se esfuerza por progresar y hacer carrera y coopera con el progreso de los demás.",
    "Se integra y estimula el sentido de pertenencia",
    "Aprecia que le aclaren sus responsabilidades con detalle.",
    "Toma en cuenta su intuición y a los demás como elementos claves para decidir."
  ],
  67: [
    "Impulsa el cambio, luego de haber calculado los riesgos.",
    "Obtiene resultados de calidad, de forma ágil, metódica y reflexiva.",
    "Cumple con los procesos que funcionan adecuadamente.",
    "Innova en protocolos más eficientes, productivos o que ofrezcan más calidad.",
    "Centra esfuerzos en lograr los objetivos, cuidando de mantener un clima amable.",
    "Afronta los retos analizando y perseverando hasta encontrar una solución válida.",
    "Toma decisiones difíciles reflexionando sobre los hechos y dejando de lado las emociones.",
    "Reta a las personas a conseguir cada vez mejores resultados.",
    "Actitud meticulosa y detallista con las soluciones.",
    "Trabaja duro para conseguir los mejores resultados, lo más rápido posible.",
    "Prefiere relacionarse con gente de confianza y cercana.",
    "Actitud fría y diplomática en las relaciones interpersonales.",
    "Presta poca atención a los sentimientos de los demás. Pierde la calma con facilidad.",
    "Analiza toda la información disponible y los hechos antes de decidir.",
    "Analizar puede chocar con su inclinación a ser impaciente y querer resultados rápidos.",
    "Dificultad para priorizar. Teme a la desorganización.",
    "Puede tener períodos de acción rápida y otros de ritmo pausado, dedicados a la reflexión.",
    "Presta atención al orden y a la disciplina.",
    "Cumple las reglas y actualiza las normas para mejorar los resultados.",
    "Visión de futuro y de detalle, para innovar en las normas y procesos.",
    "En la rutina toma decisiones con rapidez.",
    "Ante temas nuevos o complejos, se detiene para analizar y revisar opciones.",
    "Análisis rápidos, tomando decisiones muy enfocadas y eficientes, en la rutina.",
    "Aun siendo cuidadoso de las normas, en algunos casos puede ser flexible.",
    "Toma decisiones manteniendo un enfoque cuidadoso, analítico y preciso."
  ],
  68: [
    "Actitud práctica, metódica, incisiva y objetiva.",
    "Cuida de la calidad y de los resultados, manteniendo un clima amable.",
    "Impulsa el cambio de forma metódica, luego de haber calculado los riesgos.",
    "Aplica protocolos, en la solución de problemas nuevos.",
    "Prueba nuevas ideas cuando los métodos establecidos no funcionan.",
    "Actitud reflexiva y capaz de aceptar sus errores. Cumple con los protocolos.",
    "Se esfuerza para que los resultados sean buenos desde la primera vez.",
    "Dedica tiempo al cuidado de la calidad, sin descuidar los resultados.",
    "Se integra, fácilmente, con personas que comprenden la importancia de la excelencia.",
    "Respeta los métodos, las normar y los procedimientos probados.",
    "Firme bajo presión, tratando de mantener la diplomacia y los buenos modales.",
    "Actitud honesta y modesta. Enfrenta los retos con responsabilidad.",
    "Actitud muy decidida en las situaciones rutinarias.",
    "En ocasiones complejas es vacilante, antes de tomar una decisión.",
    "No le gusta que interfieran en su planificación.",
    "Vulnerable frente a la crítica y los altos riesgos.",
    "Frente a los nuevos retos se distrae analizando mucho, antes de tomar una decisión.",
    "Prefiere la intimidad, la privacidad y el contacto con gente de confianza.",
    "Intenta controlar sus impulsos, pensando antes de actuar.",
    "Asume acciones autónomas cuando eso mejora los resultados.",
    "Evalúa muy bien a las personas, antes de confiar en ellas.",
    "Actitud lógica, racional, intuitiva y perceptiva. Pensamiento crítico y analítico.",
    "Actitud fría, formal y distante en las relaciones interpersonales.",
    "Se flexibiliza, si el ambiente se lo exige.",
    "Actitud disciplinada, detallista y perfeccionista."
  ],
  69: [
    "Innova en protocolos más eficientes, productivos o que ofrezcan más calidad.",
    "Impulsa el cambio, luego de haber calculado los riesgos.",
    "Enfoque en los resultados y en la calidad, manteniendo un clima amable.",
    "Actitud realizadora y recursiva frente a los retos y desafíos.",
    "Asume nuevos retos, analizando la información y hechos disponibles.",
    "Prefiere relacionarse con un grupo pequeño de personas expertas.",
    "Buen ritmo de acción, manteniendo cuidado por la calidad.",
    "Actitud firme, terca y brusca, bajo presión.",
    "Ritmo ambivalente en la toma de decisiones.",
    "Actitud fría y distante en las relaciones interpersonales.",
    "Presta poca atención a los sentimientos de los demás.",
    "Perfeccionista y difícil de complacer en situaciones complejas.",
    "Prueba diversas soluciones, lo que puede retrasar la toma de decisiones.",
    "Dificultad para priorizar. Vacila en ocasiones, al momento de decidir.",
    "Actitud consciente, objetiva, dinámica y consistente.",
    "Resuelve los conflictos de forma imparcial y objetiva.",
    "Visión de futuro para plantear controles y protocolos.",
    "Visión global y de detalle, al enfrentar desafíos.",
    "Análisis rápido tomando decisiones muy enfocadas y eficientes, en la rutina.",
    "Aun siendo cuidadoso de las normas, puede ser flexible en ocasiones.",
    "Muy consciente del tiempo y de los plazos.",
    "Aprecia que le ofrezcan guías precisas sobre sus responsabilidades.",
    "Una vez que entiende sus responsabilidades prefiere tener libertad de movimiento.",
    "Actitud discreta, paciente y reflexiva en circunstancias favorables.",
    "Se enfoca en lo realmente importante de un proyecto, plan o problema."
  ],
  70: [
    "Promueve la investigación en la toma de decisiones.",
    "Afronta situaciones complejas a través del análisis y de la intuición.",
    "Recaba información relevante haciendo las preguntas adecuadas.",
    "Se enfoca en resolver problemas cuidando de los estándares.",
    "Se esfuerza en aplicar métodos que garanticen la calidad.",
    "Evalúa con objetividad a los demás, evitando el favoritismo.",
    "Se inclina por realizar tareas que impliquen un elevado análisis.",
    "Actitud honesta, reflexiva y modesta.",
    "Investiga toda la información disponible, antes de tomar una decisión.",
    "Se esmera porque los resultados salgan bien al primer intento.",
    "Actitud práctica, incisiva y objetiva.",
    "Consciente del tiempo, cumple con los plazos.",
    "Acepta sus errores.",
    "Exigente con las normas y los procedimientos. Perfeccionista.",
    "Malhumor y rigidez cuando la situación es tensa.",
    "Actitud reservada y desconfiada con los desconocidos.",
    "Siente vulnerabilidad frente a riesgos altos.",
    "Proyecta una imagen fría y distante por su tendencia a la introversión.",
    "Ritmo pausado en la toma de decisiones complejas.",
    "Exceso de meticulosidad y cuidado, que bloquea la creatividad.",
    "Se integra al equipo si está conformado por personas que aprecian la excelencia.",
    "Actitud diplomática y de buenos modales, por lo general.",
    "Busca soluciones prácticas y que se puedan replicar.",
    "No le gusta que interfieran en su planificación.",
    "Prefiere encargarse de los proyectos de principio a fin."
  ],
  71: [
    "Llega al final de los objetivos tomando en cuenta las necesidades de las personas.",
    "Trabaja en equipo de forma activa. Inspira confianza y suele confiar en los demás.",
    "Actitud directa, optimista, servicial, gentil y motivadora.",
    "Aspira que los demás le lleven el ritmo en las tareas y en el día a día.",
    "Tiene iniciativa y persistencia, principalmente en lo social.",
    "Visión global en donde las personas están incluidas.",
    "Actitud optimista y reflexiva, para asumir riesgos.",
    "Asume el liderazgo, si es necesario.",
    "Insiste a los demás para alcanzar las metas, cuidando de no incomodar.",
    "Le atrae más estar en acción y relacionándose, que analizar información.",
    "Puede ser optimista a la hora de hacer promesas.",
    "Cuando los protocolos establecidos no le convencen actúa de forma independiente.",
    "Toma decisiones a buen ritmo, considerando a los demás.",
    "Asume algunos sistemas de controles que garanticen el logro de los resultados.",
    "Actitud competitiva en ocasiones.",
    "Flexible frente a las normas.",
    "Puede asumir ciertas situaciones de forma superficial o con indiferencia.",
    "Pierde de vista detalles importante. Impaciente y exigente, en ocasiones.",
    "Aprecia la innovación, sin perder de vista los métodos tradicionales exitosos.",
    "Le gusta asesorar y ayudar a los demás, de forma activa y estructurada.",
    "Influencia a los demás para que se calmen, en momentos de tensión.",
    "Aprecia las actividades que involucren construir y mantener redes de personas.",
    "Explora cambios, manteniendo las tradiciones que funcionan.",
    "Actitud cooperativa, para acelerar la solución de los problemas.",
    "Puede adaptar su actitud, según favorezca al ambiente y a los objetivos."
  ],
  72: [
    "Obtiene resultados de calidad a través de la gente.",
    "Impulsa a los demás para que sigan las normas y los procesos establecidos.",
    "Actúa de forma reflexiva para llegar a soluciones viables.",
    "Equilibrio entre la inclinación social, obtención de resultados y cuidado de los procesos.",
    "Cumple con las normas de forma flexible.",
    "Visión de futuro de forma realista.",
    "Visión global y de detalle en el abordaje de los retos.",
    "Se conecta con los demás de forma amigable, respetuosa y firme.",
    "Puede ser de actitud organizada en lo social.",
    "Entusiasta para competir y ganar, manteniendo una postura sustentada.",
    "Estimula el trabajo de equipo asumiendo el liderazgo.",
    "Toma la iniciativa para afrontar tareas y seguir los estándares de calidad.",
    "Estimula a las personas a resolver los conflictos, en los momentos de tensión.",
    "Asume riesgos calculados, aunque a veces decide de forma impulsiva y optimista.",
    "Actitud propositiva.",
    "En condiciones normales se lleva bien con la gente.",
    "Ritmo ambivalente, rápido y analítico al mismo tiempo.",
    "En situaciones complejas analiza mucha información.",
    "Maneja bien sus sentimientos y se orienta a los resultados, en situaciones favorables.",
    "Hace seguimiento del cumplimiento de los estándares de calidad, de forma activa.",
    "Su diplomacia y optimismo se combinan con su energía, para influenciar en el entorno.",
    "Malhumor y agresividad cuando hay estrés.",
    "Disponible para la acción y el cambio, manteniendo el respeto por los procesos.",
    "Propone mejoras creativas para los procesos, que benefician los resultados de calidad.",
    "Puede ser de actitud precisa y exacta de forma activa."
  ],
  73: [
    "Mezcla la creatividad y la lógica para encontrar soluciones.",
    "Asume nuevos retos, analizando información y diseñando opciones.",
    "Trabaja duro para conseguir resultados de calidad, rápidamente.",
    "Resuelve los problemas mejorando las metodologías probadas.",
    "En lo operativo y en la rutina actúa con sentido de urgencia.",
    "Consciente del tiempo y de los plazos.",
    "Se adapta al ritmo del entorno, si favorece los resultados y la calidad.",
    "Cuando no llevan su ritmo, prefiere hacer el trabajo, en vez de delegarlo.",
    "Cumple las reglas con cierto nivel de flexibilidad.",
    "Propone innovaciones planificadas y con riesgos calculados.",
    "En situaciones complejas se detiene a pensar y diseñar, tomando en cuenta los detalles.",
    "Asume las tareas con consciencia y consistencia, de forma activa.",
    "Resuelve los conflictos de forma imparcial y objetiva.",
    "Se anticipa a la solución de problemas al implementar estándares de calidad.",
    "En situaciones complejas puede ser difícil de complacer.",
    "No toma muy en cuenta el aspecto emocional. Actitud distante y fría.",
    "Dificultad para priorizar. Puede vacilar en ocasiones, a la hora de decidir.",
    "Actitud firme, terca, brusca, ambivalente y pesimista, bajo presión.",
    "Autosuficiente e individualista cuando los demás no se alinean al sentido de urgencia.",
    "Persistente en el seguimiento de los protocolos establecidos, en pro de los resultados.",
    "Valora recibir pautas de cómo se hace una tarea y que luego le den libertad para actuar.",
    "Actitud discreta, paciente, realista, lógica, racional y objetiva, en circunstancias normales.",
    "En situaciones favorables es de actitud considerada, buscando mantener la armonía.",
    "Aprecia el orden, la organización y la limpieza.",
    "Asume el cambio con tiempo prudencial, para adaptarse."
  ],
  74: [
    "Sigue normas y procedimientos con meticulosidad, manteniendo un clima amable.",
    "Busca hacer bien las cosas, además de complacer y ser paciente con los demás.",
    "Actitud seria, cuando se requiere afrontar tareas y proyectos.",
    "Juicio equilibrado, aportando estabilidad al ambiente.",
    "Brinda apoyo en cualquier situación, siendo amigable y optimista.",
    "Relaja ambientes tensos, con buen humor y alegría, de ser necesario.",
    "Unas veces es optimista y otras veces es de actitud crítica.",
    "Analiza mucha información, por lo que su ritmo tiende a ser pausado, para decidir.",
    "Se le dificulta cumplir con las fechas límites.",
    "Sabe integrarse al equipo.",
    "De actitud educada, diplomática y desenvuelta, cuando socializa.",
    "Actitud modesta, paciente, amable y gentil.",
    "Detallista y perfeccionista, aunque en ocasiones puede ser de actitud descuidada.",
    "Actitud indecisa, ante los cambios de rutinas y las tensiones.",
    "Incomodidad por la incertidumbre y los imprevistos.",
    "Aporta equilibrio, lógica y armonía en los conflictos.",
    "Negocia los problemas entre las personas, con cautela y empatía.",
    "En momentos de tensión, es de actitud malhumorada.",
    "Expresa su desacuerdo cuando incumplen los estándares de calidad o los plazos.",
    "Tiende a confiar mucho en las habilidades de los demás.",
    "Capaz en su área de especialización.",
    "Le gusta progresar en su carrera de forma estable.",
    "Se enfoca en las tareas rutinarias o que estén a la mano.",
    "Cuida de la calidad, sin perder de vista las necesidades de la gente.",
    "Equilibra la atención de los sistemas, la calidad y las relaciones."
  ]
};
