<template lang="html">
  <assessment-back-next-card-layout
    :title="`Seleccione la conducta que mejor describa a ${assessment.assessedName}`"
    :step="step"
    :steps="steps"
    @next="next"
    @back="back"
  >
    <v-form lazy-validation>
      <v-row class="text-center">
        <v-col cols="12">
          <v-radio-group v-model="answers[step]">
            <v-radio
              v-for="(item, index) in questions[step]"
              :key="index"
              :label="item"
              :value="index + 1"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="text-center py-0">
        <v-col cols="12" v-if="error" class="red--text text-center py-0">
          {{ error }}
        </v-col>
      </v-row>
    </v-form>
  </assessment-back-next-card-layout>
</template>

<script lang="js">
import { defineComponent } from "vue";

import assessmentMixin from "@/mixins/assessmentMixin";
import questions from "@/model/pgc/questions";

export default defineComponent({
  mixins: [assessmentMixin],
  data() {
    return {
      answers: null,
      error: false,
      questions: questions
    };
  },
  created() {
    if (this.getAndValidateAssessment()) {
      this.answers = this.$store.getters.answers;
      if (!this.answers) {
        this.answers = new Array(this.steps);
      }
    }
  },
  computed: {
    step() {
      return this.$store.getters.currentStep;
    },
    steps() {
      return this.questions.length;
    }
  },
  methods: {
    back() {
      this.error = false;
      if (this.step == 0) {
        this.$store.dispatch("setAnswers", this.answers);
        this.$router.push(this.assessmentFormURL);
      } else {
        this.$store.dispatch("back");
      }
    },
    isValidSelection() {
      return !!this.answers[this.step];
    },
    next() {
      if (this.isValidSelection()) {
        if (this.step === this.steps - 1) {
          this.$store.dispatch("setAnswers", this.answers);
          this.$router.push(this.assessmentSubmitURL);
        } else {
          this.$store.dispatch("next");
        }
      } else {
        this.error = "Debe seleccionar una opción para continuar";
      }
    },
  }
});
</script>
