export default [
  [
    "Desalineado de los objetivos comunes e influye activamente para desalinear a los demás.",
    "No cumple las órdenes. Procrastina. Improductivo.",
    "Hace el trabajo a su manera. Individualista.",
    "Cumple órdenes selectivamente. Hace sus labores diarias por salir del paso. No muestra interés.",
    "Obedece sin cuestionar. Cumple con sus tareas rutinarias eficientemente.",
    "Cumple con sus tareas eficientemente. Toma la iniciativa de forma insegura enfrentando sus miedos.",
    "Es eficiente.  Propone ideas. Aporta. Quiere destacar por méritos.",
    "Autónomo. Consigue los objetivos de forma óptima y coopera con los demás. Prefiere destacar junto con el equipo.",
    "Innovador, reta el status quo y estimula a otros a ser creativos. Ayuda a los demás a lograr mayores niveles de excelencia."
  ],
  [
    "En franca oposición con la autoridad. Estimula la insubordinación. Descalifica activamente a la autoridad.",
    "Pone excusas y/o miente para cubrir sus fallas recurrentes. Irrespeta a la autoridad de forma pasiva.",
    "Está orientado hacia la tarea y no hacia las relaciones de cooperación. En conflicto con la autoridad. Actitud arrogante o altanera.",
    "Hay que estarlo mandando. No cumple con los plazos. Responde a su ritmo.",
    "Dependiente del líder. Hace lo que el líder diga. Cumple con sus funciones.",
    "Cumple con sus obligaciones. Inseguro frente a la autoridad, pero se llena de valor para ser asertivo.",
    "Respeta la autoridad. Es asertivo. Se integra al equipo manteniendo su aspiración por crecer y destacarse.",
    "Entiende y cumple las normas e inspira a otros a seguirlas. Enseña con su ejemplo. Se integra al equipo y ayuda a los demás a integrarse.",
    "Cuida la calidad y la mejora continua. Inspira a otros a alcanzar altos estándares. Busca que el equipo destaque como un todo."
  ],
  [
    "Influencia negativamente en la productividad de los demás con el fin de presionar a la autoridad legítima y conseguir beneficios personales.",
    "No cumple con sus funciones, pero quiere que la empresa si cumpla con su compromiso.",
    "Eficiente en las funciones operativas, pero rebelde con la autoridad.",
    "Bajo rendimiento laboral producto de su desmotivación. No toma la iniciativa.",
    "Cumple con sus obligaciones. Requiere de instrucciones para resolver nuevos retos.",
    "Ofrece soluciones a los problemas comunes. Requiere de guía para sentirse seguro en la toma de decisiones frente a los nuevos retos.",
    "Toma decisiones. Orientado a la acción y a los resultados. Busca destacar dando lo mejor de sí.",
    "Muestra alto rendimiento y altos niveles de excelencia. Resultados óptimos. Busca superar los retos junto al equipo.",
    "Alta productividad y creatividad que impulsan el negocio. Innova junto al equipo en la solución de los problemas."
  ],
  [
    "Bloquea la productividad como mecanismo de control. Prefiere tener el control que lograr resultados.",
    "No logra los objetivos. Presenta reposos con frecuencia. Se victimiza frente a su falta de productividad.",
    "Logra los objetivos, pero genera un mal clima laboral con su actitud altanera o arrogante.",
    "Logra los objetivos parcialmente.",
    "Buen desempeño en tareas rutinarias. Logra los objetivos, pero no va más allá.",
    "Productivo en las tareas rutinarias y en la resolución de los problemas comunes. Quiere mejorar.",
    "Alta productividad. Asume rol de liderazgo. Ambicioso y con deseos de superación.",
    "Integrador del equipo. Va más allá de sus funciones y ayuda a los demás a lograr sus objetivos y a destacarse.",
    "Aporta en la expansión del negocio y del éxito compartido. Agrega valor constantemente dentro y fuera de su entorno sin reclamar méritos."
  ],
  [
    "Nunca está de acuerdo con nada, sabotea los cambios activamente y logra que otros también lo hagan.",
    "Se queja por tener que asumir los cambios. Se opone de forma pasiva. Sabotea con quejas las posibles soluciones.",
    "Frente al cambio genera oposición y resistencia activa para demostrar su descontento.",
    "Asume el cambio a su ritmo. Su descontento interfiere en su adaptación al cambio.",
    "Prefiere lo estable y tradicional, pero si le dan el acompañamiento adecuado asume el cambio de forma positiva.",
    "Se llena de valor para asumir el cambio. Busca ayuda para adaptarse adecuadamente.",
    "Se adapta y promueve los cambios que le favorecen y negocia, asertivamente, los que no se alinean con sus expectativas.",
    "Aprende, se adapta rápidamente y promueve el cambio que favorece a todos.",
    "Aprende, se adapta ágilmente y crea el ambiente adecuado para facilitar la transformación de otros. Agente continuo de cambio e innovación."
  ],
  [
    "Hace alianzas con quienes están dispuestos a seguir sus órdenes en contra de la autoridad legítima. Rivaliza con quienes le cuestionan.",
    "No está disponible para aportar o ayudar. Muchas veces genera el rechazo de sus compañeros.",
    "Individualista. Controla. Le cuesta adaptarse a la autoridad cuando está molesto. Actitud arrogante.",
    "Cercano con gente de su confianza. Poco colaborativo. Desmotivado.",
    "Cercano con quien considera sus iguales o de su confianza. Obediente con la autoridad.",
    "Tiene disposición a integrarse al equipo, pero de forma cautelosa al principio. Buena relación con la autoridad cuando el jefe es alguien amable, se intimida frente a jefes dominantes.",
    "Cercano con la autoridad. Se integra al equipo asumiendo rol de liderazgo. Disponible para ayudar.",
    "Se integra. Auténtico. Le importan los demás. Cercano y colaborador.",
    "Los demás le importan de forma genuina y activa. Los demás son sus aliados. Escucha a todos. Construye la armonía en las relaciones de forma consciente."
  ],
  [
    "Retador. Se rodea de gente manipulable. Fomenta la rebelión indiscriminada y la discordia dentro del equipo.",
    "Constantemente está hablando de sus problemas y sufrimiento. Pesimista. Se compara con los demás y se siente en desventaja.",
    "Compite y argumenta para imponerse. Dominante. Genera conflictos interpersonales que no sabe solucionar.",
    "Se guarda las cosas que no le gustan y actúa con resentimiento. Habla de su descontento con sus compañeros a manera de queja.",
    "Cuida sus vínculos cércanos. Se siente mejor en un ambiente familiar y con personas de confianza. Requiere tiempo para aceptar a las nuevas personas del equipo.",
    "Receptivo a nuevos compañeros, aunque de forma cautelosa al inicio.",
    "Cuida y propicia vínculos con personas claves que le ayuden a lograr sus aspiraciones. Abierto a las nuevas relaciones.",
    "Busca mejorar en pro de la armonía de la relación. Reflexivo. Auténtico. Cercano.",
    "Se interesa en conocer las necesidades de los demás y los ayuda solidariamente y de forma desinteresada."
  ],
  [
    "Alto nivel de influencia social. Líder negativo. Organiza activamente su liderazgo en contra de la organización.",
    "Carece de carisma y de influencia social. Genera rechazo.",
    "Estilo de liderazgo impositivo. Quiere dar órdenes y ser obedecido.",
    "Su descontento le afecta su liderazgo. Bajo nivel de influencia.",
    "Bajo nivel de influencia social. Le falta autoridad. Tiende a ser más operativo que táctico o estratégico.",
    "En roles de liderazgo le falta consolidar la autoridad.",
    "Buen nivel de influencia social. Acapara la atención. Le cuesta compartir el liderazgo. Aunque permite que aporten ideas, al final él toma las decisiones.",
    "Alto nivel de influencia social. Cercano. Genera sentido de equipo. Estimula la integración y la pertenencia.",
    "Muy alto nivel de influencia social. Comparte el liderazgo con los demás. Es uno más del equipo. Estratégico. Maduro. Humilde. Le deja los méritos al equipo y asume las equivocaciones."
  ],
  [
    "Capitaliza el descontento de las personas a su favor. Se vale de las necesidades de las personas para obtener seguidores a su causa egoísta y en contra de la empresa.",
    "Sus intereses personales y baja energía interfieren de forma negativa en su liderazgo.",
    "Impulsivo. Poco reflexivo. Humilla o maltrata con su actitud.",
    "Su desmotivación interfiere en su rendimiento y liderazgo.",
    "Es más seguidor que líder.",
    "Se siente inseguro con el manejo de la autoridad. En roles de liderazgo defenderá su posición, pero le faltará fuerza frente a personas agresivas.",
    "Dirige y guía. Le cuesta empoderar. Transmite su visión personal con entusiasmo y pasión y logra motivar al equipo.",
    "Estimula la autonomía. Reconoce cuando se equivoca. Permite la participación en la toma de decisiones.",
    "Crea un ambiente psicológicamente seguro para innovar. Integra las diferencias. Construye una visión compartida que sirve de guía."
  ],
  [
    "Se crece en el conflicto porque ha logrado capitalizar el apoyo de las personas a su favor y en contra de la autoridad legítima.",
    "Abandona sus responsabilidades amparándose es causas legales. Consciente de sus faltas, pero no hace nada para remediarlas.",
    "En los conflictos busca imponer su punto de vista. Incapaz de solucionar conflictos interpersonales. Tiende a no escuchar. Reactivo con tendencia a la impaciencia y/o agresividad.",
    "Frente al conflicto es proclive a la crítica y no propone nada en concreto. Mantiene su posición de forma pasiva sin asumir la responsabilidad.",
    "Frente al conflicto se dirige al líder en busca de ayuda. Sabe cómo resolver problemas comunes, pero le cuesta los nuevos retos. Es leal a lo que diga el líder.",
    "Defiende con timidez su punto de vista, cuando se siente desfavorecido o tratado injustamente.",
    "En el conflicto defiende su posición con argumentos buscando ganar y destacar. Aprecia la justicia en el manejo de las consecuencias.",
    "Acepta sus errores, aprende de ellos y es amable con los errores de los demás. Es honesto. Busca acuerdos ganar-ganar y resolver los conflictos de forma positiva.",
    "Paciente. Analiza, asume y aprende de sus errores y de los errores de los demás. No ve conflictos sino situaciones para seguir creciendo e innovando. Integra las diferencias."
  ]
];
