export const beginner = [
  {
    key: "f",
    label: "Facultados para la Autonomía e Innovación",
    items: [
      "Son optimistas con respecto al futuro, aunque no están preparados en cuanto a las competencias y los conocimientos que requieren para realizar sus tareas de forma adecuada.",
      "Los protocolos, normas, procedimientos, políticas y estándares esperados aún no los tienen claros.",
      "No están preparados para ser autónomos.",
      "No están claros en cuáles son sus tareas y responsabilidades.",
      "Cooperan por cortesía."
    ]
  },
  {
    key: "o",
    label: "Organizados con Base en una Visión Compartida",
    items: [
      "Su foco está en conocer el nuevo contexto, a sus compañeros y en adaptarse.",
      "Aún no saben si comparten o no la misma forma de ver el propósito del equipo.",
      "Los valores aún no están claros."
    ]
  },
  {
    key: "r",
    label: "Relacionados con Buena Voluntad",
    items: [
      "Se muestran amables pero cautelosos y vacilantes.",
      "No se atreven a expresarse libremente, dejan el protagonismo y la dirección exclusivamente al líder.",
      "No se sienten en confianza. No hay familiaridad entre los miembros del equipo.",
      "Aún no hay sensación de equipo ni compañerismo."
    ]
  },
  {
    key: "m",
    label: "Motivados",
    items: [
      "Se sienten motivados hasta cierto punto.",
      "Los integrantes están contentos de haber sido elegidos para conformar el equipo, pero tienen dudas de si les gustará en el futuro ser parte de este.",
      "Tienen expectativas. Aún no saben si van a poder conformar un verdadero equipo que logre los resultados."
    ]
  },
  {
    key: "u",
    label: "Usan sus Recursos y Competencias Eficientemente",
    items: [
      "El líder marca la pauta. Dirige. Aprueba. Domina.",
      "El equipo es dependiente de las órdenes del líder.",
      "El equipo obedece.",
      "Los integrantes del equipo están atentos y son discretos en cuanto a sus acciones.",
      "Miembros formales y cautos."
    ]
  },
  {
    key: "l",
    label: "Logran la Excelencia",
    items: [
      "Poca capacidad para solucionar problemas y tomar decisiones.",
      "Tienen miedo de equivocarse, se sienten vulnerables de mostrar sus fallas frente a los demás."
    ]
  },
  {
    key: "a",
    label: "Amplían la Conciencia Compartida",
    items: [
      "El líder tiene un papel protagónico, su reconocimiento es muy valioso para cada uno de forma individual.",
      "Los miembros buscan activamente su aprobación.",
      "La opinión de los compañeros está en un segundo plano, lo más importante es lo que el líder diga.",
      "Se respetan por cortesía."
    ]
  }
];

export const distrustful = [
  {
    key: "f",
    label: "Facultados para la Autonomía e Innovación",
    items: [
      "No están preparados para ser autónomos.",
      "No están conformes con las políticas de funcionamiento.",
      "Están comenzando a saber cuáles son sus tareas y responsabilidades, pero tienen dudas e insatisfacción.",
      "Comienzan a competir entre ellos."
    ]
  },
  {
    key: "o",
    label: "Organizados con Base en una Visión Compartida",
    items: [
      "Comienza a emerger un sentimiento de confusión y desacuerdo.",
      "Los miembros del equipo comienzan a enfrentar conflictos y no logran integrarse a una meta común.",
      "Los valores pueden entrar en conflictos.",
      "No tienen claro lo que esperan de ellos como equipo."
    ]
  },
  {
    key: "r",
    label: "Relacionados con Buena Voluntad",
    items: [
      "Comienzan a desconfiar entre ellos.",
      "Se comienzan a sentir mal dentro del equipo.",
      "La comunicación entre los integrantes del equipo comienza a fallar.",
      "Empieza un sentimiento de rechazo entre los miembros del equipo que se creen diferentes.",
      "Comienzan a retraerse y a no participar.",
      "Comienzan a formarse parcelas."
    ]
  },
  {
    key: "m",
    label: "Motivados",
    items: [
      "Ya no sienten tanto entusiasmo como en el inicio y se comienzan a sentir frustrados.",
      "Se comienzan a dar cuenta de que quizá sus expectativas no se cumplan como esperaban.",
      "El sentido de entusiasmo inicial se comienza a convertir en desconfianza e insatisfacción.",
      "Se detecta una sensación de descontento."
    ]
  },
  {
    key: "u",
    label: "Usan sus Recursos y Competencias Eficientemente",
    items: [
      "Dependientes del líder para la realización de las tareas.",
      "Comienzan a criticarse unos a otros.",
      "Son cautelosos a la hora de opinar y de actuar.",
      " Comienzan a funcionar de forma individualizada intentando destacar.",
      "Comienzan a resaltar los errores de sus compañeros."
    ]
  },
  {
    key: "l",
    label: "Logran la Excelencia",
    items: [
      "No logran tener buenos resultados con sus tareas.",
      "Dificultades en la solución de problemas y en la toma de decisiones.",
      "Dominio bajo de las competencias y habilidades.",
      "Les cuesta asumir la responsabilidad de sus errores."
    ]
  },
  {
    key: "a",
    label: "Amplían la Conciencia Compartida",
    items: [
      "Tendencia a la incomodidad dentro del equipo.",
      "Comienza a levantarse resistencia entre los miembros e incluso con el líder.",
      "Los conflictos que están comenzando crean un ambiente de estrés y tensión."
    ]
  }
];

export const misaligned = [
  {
    key: "f",
    label: "Facultados para la Autonomía e Innovación",
    items: [
      "Las políticas de funcionamiento le generan incomodidad.",
      "No han demostrado buena fe en el uso de la autonomía.",
      "No se atreven a innovar y si lo hacen es de forma individual.",
      "Hay conflictos entre las expectativas iniciales de los integrantes del equipo y lo que está sucediendo en la práctica con relación a los resultados esperados."
    ]
  },
  {
    key: "o",
    label: "Organizados con Base en una Visión Compartida",
    items: [
      "El equipo no logra integrarse a una meta común.",
      "Hay confusión y desacuerdo sobre el propósito del equipo y las funciones de sus miembros.",
      "Conflicto de valores entre los miembros del equipo y/o con el líder."
    ]
  },
  {
    key: "r",
    label: "Relacionados con Buena Voluntad",
    items: [
      "No hay confianza entre los miembros del equipo ni con el líder.",
      "Mala comunicación. No se escuchan, se interrumpen. Hablan negativamente entre ellos y con el líder.",
      "No se aceptan. No participan abiertamente, ni expresan sus sentimientos con los demás.",
      "Puede haber parcelas y miembros excluidos.",
      "No hay buena voluntad entre ellos.",
      "Actitud de competencia entre los miembros del equipo.",
      "Las emociones interfieren negativamente."
    ]
  },
  {
    key: "m",
    label: "Motivados",
    items: [
      "Se sienten frustrados.",
      "Insatisfacción general. Desmotivación y descontento.",
      "No hay integración ni sentido de pertenencia como equipo."
    ]
  },
  {
    key: "u",
    label: "Usan sus Recursos y Competencias Eficientemente",
    items: [
      "Sienten que sus compañeros en vez de ser aliados son una amenaza.",
      "Frustración y ambivalencia de parte de los integrantes del equipo. Pueden ser agresivos/pasivos u oposicionistas/dependientes.",
      "Hay mucho estrés y tensión lo cual debilita la flexibilidad en el uso de los recursos y las competencias.",
      "Buscan resaltar de forma individualista.",
      "Resaltan los errores de sus compañeros."
    ]
  },
  {
    key: "l",
    label: "Logran la Excelencia",
    items: [
      "Muchas dificultades en la solución de problemas.",
      "No confían, por lo tanto, no pueden obtener resultados de alta calidad.",
      "Están aprendiendo el criterio para tomar decisiones acertadas.",
      "Apenas comienzan a aprender a realizar sus funciones. Las competencias están en su desarrollo inicial o medio.",
      "Requieren de ayuda del líder o de un experto para alcanzar los objetivos, aún no pueden hacerlo de forma independiente."
    ]
  },
  {
    key: "a",
    label: "Amplían la Conciencia Compartida",
    items: [
      "Se sienten mal dentro del equipo.",
      "Se tratan exclusivamente con base en los aspectos negativos.",
      "No tienen buena actitud entre ellos.",
      "No se reconocen ni se aprecian."
    ]
  }
];

export const unsure = [
  {
    key: "f",
    label: "Facultados para la Autonomía e Innovación",
    items: [
      "Enfrentan conflictos con cierto nivel de optimismo con relación a la capacidad del equipo de lograr los objetivos, resolver los problemas y alcanzar las metas comunes.",
      "Comportamientos competitivos aunque a veces colaboran entre sí.",
      "Comienzan a incorporar las normas y los protocolos.",
      "Con la adquisición de las competencias inicia un sentido de autonomía general.",
      "Se sienten inseguros."
    ]
  },
  {
    key: "o",
    label: "Organizados con Base en una Visión Compartida",
    items: [
      "Se están aclarando poco a poco las tareas y funciones de cada miembro del equipo.",
      "Van encontrando sus propias maneras de alcanzar las metas.",
      "Débil integración, aún predominan las diferencias y la falta de alineación hacia una meta común."
    ]
  },
  {
    key: "r",
    label: "Relacionados con Buena Voluntad",
    items: [
      "La comunicación aún no es buena, pero hacen intentos por mejorar.",
      "Todavía hay muchos sentimientos negativos que se comienzan a cuestionar.",
      "Comienzan a surgir tímidamente buenas relaciones.",
      "Intentan confiar entre ellos.",
      "El equipo se comporta con un cierto grado de cautela. Las parcelas comienzan a disolverse y hay intentos sinceros de integrar a los miembros anteriormente excluidos."
    ]
  },
  {
    key: "m",
    label: "Motivados",
    items: [
      "Están un poco sensibles por los conflictos.",
      "Comienzan a aprender a crear un ambiente de armonía entre ellos.",
      "Débil sensación de pertenencia al equipo."
    ]
  },
  {
    key: "u",
    label: "Usan sus Recursos y Competencias Eficientemente",
    items: [
      "Se comienzan a compartir las responsabilidades. Los talentos personales comienzan a sumar en la construcción del logro común, pero aún tienen dificultad en la solución de los problemas.",
      "Comienzan a tener un compromiso positivo en cuanto a la realización de la tarea.",
      "Todavía no confían entre ellos totalmente, lo que dificulta el logro de resultados de calidad."
    ]
  },
  {
    key: "l",
    label: "Logran la Excelencia",
    items: [
      "La competencia en la ejecución de sus funciones está en un nivel medio.",
      "Los resultados se ven afectados por la falta de integración.",
      "Comienzan a ser más eficientes en la toma de decisiones y en la solución de problemas, pero todavía enfrentan dificultades.",
      "No asumen totalmente sus errores como equipo."
    ]
  },
  {
    key: "a",
    label: "Amplían la Conciencia Compartida",
    items: [
      "Quieren superar la sensación de inseguridad y amenaza.",
      "La sensación de ser un equipo aún es débil.",
      "Los integrantes del equipo apenas se comienzan a interesar unos en los otros de forma sincera."
    ]
  }
];

export const capable = [
  {
    key: "f",
    label: "Facultados para la Autonomía e Innovación",
    items: [
      "Se comienza a manifestar optimismo con relación a la capacidad del equipo de lograr los objetivos, resolver los problemas y alcanzar las metas comunes.",
      "La sensación de equipo comienza a evidenciarse a través de comportamientos colaborativos.",
      "Comienzan a incorporar las normas y los protocolos con mayor comodidad.",
      "Cada vez se sienten más seguros de realizar la tarea de forma autónoma.",
      "Continúan aprendiendo y alcanzando mayor nivel de seguridad."
    ]
  },
  {
    key: "o",
    label: "Organizados con Base en una Visión Compartida",
    items: [
      "El equipo se va integrando a un propósito común.",
      "Cada vez tienen una visión más compartida.",
      "Las responsabilidades y tareas de cada miembro están cada vez más claras para el equipo.",
      "Están aprendiendo a lograr las metas y están creando sus propias formas de alcanzar los objetivos.",
      "Comienzan a compartir los valores positivos."
    ]
  },
  {
    key: "r",
    label: "Relacionados con Buena Voluntad",
    items: [
      "Comienzan a confiar, pero aún con un poco de cautela entre ellos.",
      "Van superando los conflictos, pero están un poco sensibles por lo que evitan hablar de lo negativo.",
      "Ya están colaborando un poco más entre ellos mismos y comportándose como compañeros de equipos.",
      "La comunicación comienza a fluir un poco mejor. Se escuchan más y hablan más entre ellos.",
      "Van teniendo buena voluntad como miembros del equipo, de forma sincera.",
      "Poco a poco se van sintiendo mejor entre ellos, los sentimientos negativos van siendo reemplazados por los positivos.",
      "Van confiando unos en los otros.",
      "Se comienzan a integrar."
    ]
  },
  {
    key: "m",
    label: "Motivados",
    items: [
      "Comienzan a sentir nuevamente entusiasmo por pertenecer al equipo porque ahora es satisfactorio trabajar en conjunto.",
      "El esfuerzo compartido trae buenos resultados."
    ]
  },
  {
    key: "u",
    label: "Usan sus Recursos y Competencias Eficientemente",
    items: [
      "Comienzan a surgir las buenas relaciones y el buen funcionamiento del equipo a través de la conciencia del uso adecuado de los recursos y las competencias.",
      "Se comparten las responsabilidades. Los talentos personales suman en la construcción del éxito compartido.",
      "Comienzan a favorecer la armonía en las relaciones como un capital indispensable que afecta directamente la productividad y éxito del negocio."
    ]
  },
  {
    key: "l",
    label: "Logran la Excelencia",
    items: [
      "Comienzan a tener un compromiso positivo en cuanto a la realización de la tarea.",
      "La competencia en la ejecución de sus funciones está en un nivel de medio a alto.",
      "Son más eficientes en la toma de decisiones y en la solución de problemas.",
      "Aún tienen que aprender a estar más seguros de sí mismos en la obtención de los objetivos que se plantean."
    ]
  },
  {
    key: "a",
    label: "Amplían la Conciencia Compartida",
    items: [
      "La sensación de ser un equipo aún es débil.",
      "Los integrantes del equipo apenas se comienzan a interesar unos en los otros de forma positiva.",
      "Comienzan a demostrarse apreciación y reconocimiento.",
      "Se inicia una relación de confianza y armonía entre los integrantes del equipo."
    ]
  }
];

export const bounded = [
  {
    key: "f",
    label: "Facultados para la Autonomía e Innovación",
    items: [
      "El equipo cada vez se comporta con mayor seguridad y mejor desempeño en el logro de los resultados y la ejecución de la tarea.",
      "Están convirtiéndose en expertos en lo que hacen.",
      "Se sienten más seguros a la hora de ensayar y probar formas diferentes de hacer las cosas.",
      "Entienden bien la importancia de las normas y los procedimientos y las asumen en la cotidianidad.",
      "Colaboran entre ellos, se respetan cada día más.",
      "Cada vez necesitan menos supervisión para realizar el trabajo.",
      "Demuestran buena fe en el uso de la autonomía."
    ]
  },
  {
    key: "o",
    label: "Organizados con Base en una Visión Compartida",
    items: [
      "El equipo está cada vez más integrado alrededor de un propósito común.",
      "Tiene una visión compartida cada vez más clara. El equipo piensa con mejor criterio y de forma estratégica.",
      "Hay un buen sentido de equipo.",
      "Las funciones y tareas individuales están siendo claras y aceptadas.",
      "El equipo comparte los valores positivos de la organización."
    ]
  },
  {
    key: "r",
    label: "Relacionados con Buena Voluntad",
    items: [
      "Se relacionan con buena voluntad entre ellos.",
      "Tienden a ser sinceros, honestos y a comunicarse con respeto.",
      "Están superando el miedo al rechazo. Cada vez se aceptan más y hablan abiertamente de sus sentimientos.",
      "Están aprendiendo a aceptar e integrar las diferencias.",
      "Se comienzan a expresar afecto.",
      "Resuelven los conflictos entre ellos casi sin necesitar la intervención del líder."
    ]
  },
  {
    key: "m",
    label: "Motivados",
    items: [
      "El sentido de pertenencia es bueno y compartido.",
      "Hay sensación de orgullo e identidad de equipo.",
      "Confían entre ellos y se sienten motivados y satisfechos con los resultados."
    ]
  },
  {
    key: "u",
    label: "Usan sus Recursos y Competencias Eficientemente",
    items: [
      "Se comienza a compartir el liderazgo dependiendo de lo experto que sea cada uno.",
      "En ocasiones se intercambian las funciones dependiendo de las competencias requeridas.",
      "Se adaptan a las demandas del momento.",
      "Dan a conocer su perspectiva y opiniones."
    ]
  },
  {
    key: "l",
    label: "Logran la Excelencia",
    items: [
      "Alcanzan un buen desempeño en el logro de los objetivos que se plantean.",
      "Cada vez son más ágiles y eficientes.",
      "Toman las decisiones correctas en la solución de los problemas y son capaces de asumir sus errores.",
      "Son competentes."
    ]
  },
  {
    key: "a",
    label: "Amplían la Conciencia Compartida",
    items: [
      "Se aprecian, respetan y se consideran cada vez más.",
      "Se ofrecen reconocimiento entre ellos.",
      "Sienten que cada día crecen y aprenden juntos."
    ]
  }
];

export const innovative = [
  {
    key: "f",
    label: "Facultados para la Autonomía e Innovación",
    items: [
      "El equipo se comporta con seguridad y alto desempeño en el logro de los resultados y la ejecución de la tarea.",
      "Ensayan y prueban con nuevos métodos, retando el estatus quo.",
      "Entienden muy bien la importancia de las normas y los procedimientos y las asumen con buena actitud.",
      "Demuestran buena fe en el uso de la autonomía.",
      "Colaboran entre ellos, se respetan y comparten la información con confianza.",
      "No necesitan supervisión para realizar el trabajo. Son expertos en lo que hacen. Se desarrollan y preparan constantemente."
    ]
  },
  {
    key: "o",
    label: "Organizados con Base en una Visión Compartida",
    items: [
      "El equipo está integrado y comprometido alrededor de un propósito común y comparten los valores positivos de la organización.",
      "Tienen una visión compartida que es una guía clara para todos los miembros del equipo.",
      "Hay sentido de equipo y criterio de excelencia común. Tienen claro lo que se espera de ellos como equipo y entienden la importancia de su misión.",
      "Han desarrollado en equipo las estrategias para lograr la visión compartida.",
      "Tienen claras sus funciones con relación a las estrategias definidas.",
      "Creen en el poder del equipo para alcanzar las metas comunes."
    ]
  },
  {
    key: "r",
    label: "Relacionados con Buena Voluntad",
    items: [
      "Se relacionan con buena voluntad entre ellos.",
      "Son sinceros, honestos, se comunican con respeto. Tienen buena comunicación.",
      "Han superado el miedo al rechazo.",
      "Se aceptan. Se expresan afecto.",
      "Hablan abiertamente de sus sentimientos y aceptan e integran sus diferencias. Saben resolver los conflictos entre ellos, por ellos mismos."
    ]
  },
  {
    key: "m",
    label: "Motivados",
    items: [
      "El sentido de pertenencia es sólido y compartido.",
      "Hay orgullo e identidad de equipo y confían entre ellos.",
      "Se sienten motivados y satisfechos con los resultados, el nivel de excelencia y el éxito que alcanzan en conjunto."
    ]
  },
  {
    key: "u",
    label: "Usan sus Recursos y Competencias Eficientemente",
    items: [
      "Se comparte el liderazgo dependiendo de lo experto que sea cada uno.",
      "Integran las diferentes opiniones de los miembros expertos.",
      "Se intercambian las funciones dependiendo de las competencias de cada uno.",
      "Se adaptan a las demandas del momento, buscando sacar el mayor provecho del profesionalismo de cada uno.",
      "Dan a conocer su perspectiva y opiniones cada vez que éstas agregan verdadero valor al logro de los objetivos."
    ]
  },
  {
    key: "l",
    label: "Logran la Excelencia",
    items: [
      "Son un equipo de alto desempeño en el logro de los objetivos que se plantean. Alcanzan altos estándares de calidad y excelencia.",
      "Pueden trabajar eficientemente con personas externas al equipo, integrándose sin dificultad.",
      "Son ágiles y eficientes. Son conscientes de sus comportamientos y del impacto de estos en sus resultados.",
      "Logran los más altos niveles de productividad. Saben responder a los desafíos de forma resolutiva.",
      "Aprenden de sus errores y asumen la responsabilidad.",
      "Son creativos e innovadores en la solución de problemas y superando los obstáculos."
    ]
  },
  {
    key: "a",
    label: "Amplían la Conciencia Compartida",
    items: [
      "Se aprecian, respetan y se consideran unos a otros. Se sienten seguros y cómodos dentro del equipo.",
      "Se ofrecen reconocimiento entre ellos y crean un ambiente de armonía y expansión.",
      "Sienten que cada día crecen, aprenden y evolucionan juntos."
    ]
  }
];
