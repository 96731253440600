<template>
  <div>
    <v-row no-gutters>
      <v-col cols="1.5" />
      <v-col align="center" cols="9" class="bar-container py-2">
        <div
          class="bar-height-adjustment"
          v-for="words in upperWords"
          :key="words"
        >
          {{ words }}
        </div>
        <div class="divider">
          <div
            class="bar"
            :style="{
              height: graphData.bar + 'px',
              top: graphData.direction + 'px',
              background: color,
              borderRadius: graphData.radius
            }"
          />
        </div>
        <div
          class="bar-height-adjustment"
          v-for="words in lowerWords"
          :key="words"
        >
          {{ words }}
        </div>
      </v-col>
      <v-col cols="1.5" />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DescriptorsBar",
  props: {
    upperWords: Array,
    lowerWords: Array,
    color: String,
    value: Number
  },
  computed: {
    graphData() {
      const number = this.value;
      const up = number >= 50;
      let height = up ? number - 50 : 50 - number;
      // 278 is the highest height value for each of the bar charts.
      // (2 / 100) * 278 = 5.56
      height *= 5.56;
      height = height > 25 ? height : 25;

      const radius = up ? "10px 10px 0 0" : "0 0 10px 10px";

      return {
        direction: up ? -height : 0,
        bar: height,
        radius
      };
    }
  }
};
</script>

<style scoped>
.divider {
  border-top: 4px solid var(--v-background-darken2);
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  position: relative;
}
.bar-container {
  background-color: #f4f4f4;
  font-size: 0.9em !important;
}
.bar {
  width: 100%;
  opacity: 0.2;
  margin: auto;
  display: flex;
  align-items: flex-start;
  position: absolute;
}
.bar-height-adjustment {
  line-height: 120%;
}
</style>
