<template>
  <div class="global">
    <report-section-title>Madurez de equipos F1</report-section-title>
    <div class="container">
      <div class="sidebar">
        <p class="diagnostic">Diagnóstico</p>
        <div class="level">
          <p>Nivel:</p>
          <p>{{ level.label }}</p>
          <p>{{ level.level }} / {{ diagnosticItems.length }}</p>
        </div>
      </div>
      <div class="content">
        <table>
          <tbody>
            <tr v-for="item in diagnosticItems" :key="item.key">
              <td class="label">{{ item.label }}</td>
              <td>
                <ol>
                  <li v-for="(subItem, index) in item.items" :key="index">
                    {{ subItem }}
                  </li>
                </ol>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import levels from "@/model/fone-team/levels.js";
import * as diagnostics from "@/model/fone-team/diagnostics.js";

export default {
  props: {
    applicantCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      diagnosticItems: [],
      level: {}
    };
  },
  created() {
    const level = this.getLevel(this.applicantCount);
    const diagnosticArray = this.selectDiagnosticArray(level);
    this.diagnosticItems = diagnosticArray;
    this.level = level;
  },
  methods: {
    getLevel(applicantCount) {
      return levels.find(level => {
        return (
          applicantCount >= level.range.min && applicantCount <= level.range.max
        );
      });
    },
    selectDiagnosticArray(level) {
      switch (level.key) {
        case "beginner":
          return diagnostics.beginner;
        case "distrustful":
          return diagnostics.distrustful;
        case "misaligned":
          return diagnostics.misaligned;
        case "unsure":
          return diagnostics.unsure;
        case "capable":
          return diagnostics.capable;
        case "bounded":
          return diagnostics.bounded;
        case "innovative":
          return diagnostics.innovative;
        default:
          return [];
      }
    }
  }
};
</script>

<style scoped>
.global {
  margin-top: 30px;
}
.container {
  display: flex;
}

.sidebar {
  flex: 1;
  background-color: #1c2b39;
  padding: 20px;
}

.diagnostic {
  color: #9edbdd;
  font-size: 2rem;
  font-weight: bold;
}
.level {
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  color: #f2f2f2;
  margin-top: 15rem;
}
.content {
  flex: 4;
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.label {
  font-weight: bold;
}
.label::first-letter {
  font-weight: 600;
  color: var(--v-primary-base);
  font-size: 2rem;
}
td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

ol {
  margin: 0;
  padding: 0;
  padding-left: 20px;
}

ol li {
  list-style-type: decimal;
}
</style>
