<template>
  <div id="" class="section-background">
    <div class="acrostic">
      <p>
        <span class="capital">F</span>&nbsp;&nbsp;&nbsp;&nbsp;acultados para la
        autonomía y la innovación
      </p>
      <p>
        <span class="capital">O</span>&nbsp;&nbsp;rganizados en función de una
        visión compartida
      </p>
      <p>
        <span class="capital">R</span>&nbsp;&nbsp;&nbsp;&nbsp;elacionados con
        buena voluntad
      </p>
      <p><span class="capital">M</span>&nbsp;&nbsp;&nbsp;&nbsp;otivados</p>
      <p>
        <span class="capital">U</span>&nbsp;&nbsp;&nbsp;&nbsp;san sus recursos y
        competencias eficientemente
      </p>
      <p>
        <span class="capital">L</span>&nbsp;&nbsp;&nbsp;&nbsp;ogran la
        excelencia
      </p>
      <p>
        <span class="capital">A</span>&nbsp;&nbsp;&nbsp;&nbsp;mplían la
        conciencia compartida
      </p>
    </div>
    <div>
      <p>EL LÍDER ES <span class="capital">1</span> MÁS DEL EQUIPO</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FOneTeam"
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.section-background {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: var(--v-primary-base);
}
.acrostic {
  justify-content: center;
  font-weight: 400;
  color: var(--v-primary-base);
  text-align: left;
  font-size: 1rem;
  margin: 0 auto;
}
.capital {
  font-weight: 600;
  color: var(--v-primary-base);
  font-size: 2rem;
}
</style>
