export const maturityLevels = [
  {
    key: "high",
    label: "Alto",
    value: 4
  },
  {
    key: "mid-high",
    label: "Medio Alto",
    value: 3
  },
  {
    key: "mid-low",
    label: "Medio Bajo",
    value: 2
  },
  {
    key: "beginner",
    label: "Inicial",
    value: 1
  }
];

export const factors = [
  {
    key: "f",
    label: "Facultados para la Autonomía e Innovación"
  },
  {
    key: "o",
    label: "Organizados con Base en una Visión Compartida"
  },
  {
    key: "r",
    label: "Relacionados con Buena Voluntad"
  },
  {
    key: "m",
    label: "Motivados"
  },
  {
    key: "u",
    label: "Usan sus Recursos y Competencias Eficientemente"
  },
  {
    key: "l",
    label: "Logran la Excelencia"
  },
  {
    key: "a",
    label: "Amplían la Conciencia Compartida"
  }
];
