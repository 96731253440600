<template>
  <div class="pr-6 header-card">
    <v-row no-gutters v-for="item in data" :key="item.label">
      <v-col class="header-content">
        <strong>{{ item.label }}:</strong> {{ item.value }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ApplicantData",
  props: {
    data: {
      type: Array, // { label: string; value: string }
      required: true
    }
  }
});
</script>

<style scoped>
.header-card {
  background: linear-gradient(
    150deg,
    var(--v-primary-base) 0%,
    var(--v-primary-lighten3) 100%
  );
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  text-align: left;
  padding: 1em 1em 1em 1em;
}
.header-content {
  font-size: 1em;
}
@media screen and (min-width: 960px) {
  .header-card {
    padding-left: 7em !important;
  }
}
@media print {
  .header-card {
    max-width: 500px;
  }
}
</style>
