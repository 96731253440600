<template>
  <div class="container">
    <report-section-title>Sugerencias finales</report-section-title>
    <ol style="text-align: left">
      <li>Reúnete con tu equipo para agradecerles por su colaboración.</li>
      <li>
        Menciona que recibiste información valiosa que te permitirá mejorar en
        tu rol de líder.
      </li>
      <li>
        Invítalos a que se sientan en libertad de ampliar el feedback, si lo
        desean.
      </li>
      <li>
        Observa los comportamientos donde tienes la puntuación más baja y
        trabaja en ellos.
      </li>
      <li>
        Mantén aquellos comportamientos que fueron puntuados de manera positiva.
      </li>
      <li>
        En caso de no estar satisfecho con algún aspecto de los resultados de
        esta evaluación, no tomes ningún tipo de represalias, no personalices
        comentarios, ni asumas actitudes que afecten negativamente el clima
        laboral, ya que esto te perjudicará como líder.
      </li>
      <li>
        Recuerda que cualquier resultado, sea positivo o desafiante, representa
        una gran oportunidad para fortalecer tu liderazgo. Asume este reto con
        madurez y compromiso.
      </li>
      <li>
        Si tienes dudas contacta con tu tutor(a), mentor(a), coach o
        consultor(a) para que responda tus inquietudes.
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "FinalSuggestions"
};
</script>

<style scoped>
.container {
  width: 90%;
  margin: auto;
}

h1 {
  width: 70%;
  margin: auto;
  padding: 10px;
  border: 1px solid #dddddd;
  text-align: center;
}

ol {
  list-style-type: decimal;
  margin: 20px;
  padding: 0;
}

li {
  margin: 10px 0;
  padding: 0;
}
</style>
