import questions from "./questions";

const assessment = {
  ageRanges: [
    "18 o menor",
    "19 a 29",
    "30 a 39",
    "40 a 49",
    "50 a 59",
    "60 a 69",
    "70 o más"
  ],
  form: {
    ageRangeField: "ageRange",
    companyField: "company",
    emailField: "email",
    genderField: "gender",
    nameField: "name",
    positionField: "position"
  },
  gender: ["Masculino", "Femenino"],
  questions: questions
};

export default assessment;
