import { calculateKey, attachSections } from "@/model/common/disc/utils";
import profiles from "@/model/common/disc/profiles";
import adaptiveResponse from "@/model/disc/content/adaptiveResponse";
import profileDescriptions from "./content/profileDescriptions";
import highlightedTalents from "./content/highlightedTalents";
import prevailingTrends from "./content/prevailingTrends";
import possibleBarriers from "./content/possibleBarriers";
import communicationStyle from "./content/communicationStyle";
import idealContext from "./content/idealContext";

function processProfile(profileData, sections) {
  profileData.key = calculateKey(profileData.values);

  const selectedProfile = profiles.find(
    profile => profile.key == profileData.key
  );

  profileData.id = selectedProfile.id;
  profileData.code = selectedProfile.code;
  profileData.name = selectedProfile.name;

  attachSections(selectedProfile, profileData, sections);
}

export default {
  populate(data) {
    processProfile(data.profiles.primary, {
      naturalStyle: profileDescriptions,
      highlightedTalents,
      prevailingTrends,
      possibleBarriers,
      communicationStyle,
      idealContext
    });
    processProfile(data.profiles.conscious, {
      adaptiveResponse:
        data.reportType && data.reportType === "SIMPLIFIED_DISC"
          ? profileDescriptions
          : adaptiveResponse
    });
  }
};
