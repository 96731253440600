import axios from "axios";
import firebaseConfig from "@/config/firebaseConfig";
import stripePromise from "@/plugins/payment.js";
// const API_URL = "https://api.stripe.com";

const instance = axios.create({
  baseURL: firebaseConfig.apiBaseURL
});

// TODO: Add domain at Client-Only Integration Stripe config

export default {
  async processPayment(params) {
    const { priceId, customerId, type, quantity } = params;
    const session = await instance.post("/shop/start-checkout", {
      priceId,
      customerId,
      type,
      quantity
    });
    stripePromise.then(async stripe => {
      stripe
        .redirectToCheckout({
          sessionId: session.data.id
        })
        .then(response => {
          console.log("Checkout Success");
          console.log(response);
        })
        .catch(error => {
          console.log("Checkout Error");
          console.log(error);
        });
    });
  }
};
