function isObject(value) {
  return typeof value === "object" && !Array.isArray(value) && value !== null;
}

export default {
  camelToSnakeCase(str) {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  },
  snakeToCamelCase(str) {
    return str
      .toLowerCase()
      .replace(/(_[a-z])/g, group => group.toUpperCase().replace("_", ""));
  },
  objectCamelToSnakeCase(obj, deep) {
    const newObj = {};
    for (let attr in obj) {
      const newAttr = this.camelToSnakeCase(attr);
      if (isObject(obj[attr]) && deep) {
        newObj[newAttr] = this.objectCamelToSnakeCase(obj[attr]);
      } else {
        newObj[newAttr] = obj[attr];
      }
    }
    return newObj;
  },
  objectSnakeToCamelCase(obj, deep) {
    const newObj = {};
    for (let attr in obj) {
      const newAttr = this.snakeToCamelCase(attr);
      if (isObject(obj[attr]) && deep) {
        newObj[newAttr] = this.objectSnakeToCamelCase(obj[attr]);
      } else {
        newObj[newAttr] = obj[attr];
      }
    }
    return newObj;
  }
};
