<template>
  <v-dialog width="200" :value="true">
    <v-card>
      <v-card-title class="text-h6"> Procesando... </v-card-title>
      <v-card-text class="text-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import messageDialogMixin from "@/mixins/messageDialogMixin";
import messages from "@/plugins/messages";
import paymentService from "@/services/paymentService";

export default defineComponent({
  mixins: [messageDialogMixin],
  created() {
    try {
      const priceId = this.$route.params.priceId;

      if (priceId) {
        this.$store
          .dispatch("goToProcessPayment", {
            priceId,
            type: "membership",
            quantity: 1
          })
          .catch(error => this.errorDialog(error.message));
      } else {
        this.errorDialog("Error: Id del producto inválido");
      }
    } catch (error) {
      this.errorDialog("Ha ocurrido un error inesperado");
      throw error;
    }
  }
});
</script>
