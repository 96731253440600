import validations from "@/plugins/validations";

export default {
  computed: {
    computedRules() {
      const rules = [];
      if (this.required) {
        rules.push(validations.form.required_field);
      }
      if (this.rules) {
        rules.push(...this.rules);
      }
      return rules;
    }
  },
  methods: {
    onChange(event) {
      this.$emit("change", event);
    },
    onInput(event) {
      this.$emit("input", event);
    }
  }
};
