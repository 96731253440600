var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-container"},[_c('report-section-title',[_vm._v(" Matriz del nivel de madurez del equipo por variable ")]),_c('div',{staticClass:"table-container"},[_c('table',[_c('thead',[_c('th',{staticStyle:{"text-align":"center"}},[_vm._v("Nivel de madurez")]),_vm._l((_vm.factors),function(factor){return _c('th',{key:`footer-${factor.key}`,staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(factor.label)+" ")])})],2),_c('tbody',_vm._l((_vm.maturityLevels),function(level){return _c('tr',{key:level.key},[_c('td',{staticStyle:{"text-align":"center"}},[_c('strong',[_vm._v(_vm._s(level.label))])]),_vm._l((_vm.factors),function(factor){return _c('td',{key:factor.key,staticStyle:{"text-align":"center"}},[(
                _vm.applicantAnswers[factor.key] === level.value &&
                _vm.assessedCountByFactor[factor.key][level.value] !==
                  undefined &&
                _vm.assessedCountByFactor[factor.key][level.value] !== 0
              )?_c('span',[_vm._v(" (L) "+_vm._s(_vm.assessedCountByFactor[factor.key][level.value])+" ")]):(_vm.applicantAnswers[factor.key] === level.value)?_c('span',[_vm._v("(L)")]):(
                _vm.assessedCountByFactor[factor.key] !== undefined &&
                _vm.assessedCountByFactor[factor.key][level.value] !==
                  undefined &&
                _vm.assessedCountByFactor[factor.key][level.value] !== 0
              )?_c('span',[_vm._v(" "+_vm._s(_vm.assessedCountByFactor[factor.key][level.value])+" ")]):_vm._e()])})],2)}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }