<template>
  <o-page-layout mode="wide" v-if="isAuthorized">
    <o-card-layout page-mode="wide">
      <v-container fluid>
        <v-row>
          <v-col
            class="text-left pt-0 pt-md-3 d-flex align-center"
            md="6"
            cols="10"
          >
            <o-back-button path="/" />
            <h2 class="ml-2">Gestión de Usuarios</h2>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right pt-0 pt-md-3" md="6" cols="12">
            <v-btn color="primary" @click="inviteUser">
              <v-icon left>fas fa-user-plus</v-icon>
              Invitar Usuario
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              id="users-table"
              dense
              fixed-header
              item-key="id"
              :headers="headers"
              :height="tableHeightStr"
              :loading="loading"
              :items="this.users"
              hide-default-footer
            >
              <template v-slot:item.actions="{ item }">
                <o-dropdown-menu
                  icon="fas fa-ellipsis-v"
                  :actions="getActions(item)"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </o-card-layout>
  </o-page-layout>
</template>

<script>
import { defineComponent, ref } from "vue";

import userService from "@/services/userService";
import messageDialogMixin from "@/mixins/messageDialogMixin";
import navigationGuardMixin from "@/mixins/navigationGuardMixin";
import store from "@/store";
import { PAGE_SIZE } from "./config";

export default defineComponent({
  mixins: [messageDialogMixin, navigationGuardMixin],
  data() {
    return {
      headers: [
        { text: "Nombre", value: "displayName" },
        { text: "Correo Electrónico", value: "email" },
        { text: "Estado", value: "status" }
        //{ text: "", value: "actions", sortable: false, align: "right" }
      ],
      users: [],
      loading: ref(true),
      tableOptions: {
        loading: false,
        page: 1,
        last: null,
        loadMore: true
      },
      pageSize: PAGE_SIZE
    };
  },
  computed: {
    isAuthorized() {
      const accountInfo = store.getters.accountInfo;
      return (
        accountInfo && accountInfo.features && accountInfo.features.multiuser
      );
    },
    tableHeightStr() {
      return "300px";
      //return `${getTableHeight(this.pageSize, this.users.length)}px`;
    }
  },
  methods: {
    inviteUser() {
      this.$router.push("/invite-user");
    },
    getActions(user) {
      return [
        {
          label: "Editar Usuario",
          icon: "fas fa-edit",
          handler: () => {
            this.$router.push({ path: `/edit-user/${user.id}` });
          }
        },
        {
          label: "Eliminar Usuario",
          icon: "fas fa-trash",
          handler: () => {
            this.$confirmDialog({
              title: "Confirmar Eliminación",
              message: `¿Estás seguro de que deseas eliminar al usuario ${user.displayName}?`,
              confirmText: "Eliminar",
              cancelText: "Cancelar",
              onConfirm: async () => {
                try {
                  await userService.deleteUser(user.id);
                  this.users = this.users.filter(u => u.id !== user.id);
                  this.successDialog("Usuario eliminado exitosamente.");
                } catch (error) {
                  this.errorDialog("Error al eliminar el usuario.");
                }
              }
            });
          }
        }
      ];
    },
    async fetchData() {
      try {
        const response = await this.$store.dispatch("listUsers");
        this.users = response.data;
      } catch (error) {
        this.errorDialog("Error al obtener la lista de usuarios.");
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    (async () => {
      await this.fetchData();
    })();
  }
});
</script>
<style scoped>
.v-data-table {
  overflow-x: auto;
}

@media (max-width: 600px) {
  .v-toolbar-title {
    font-size: 18px;
  }
}
</style>
