<template lang="html">
  <v-container>
    <v-row wrap>
      <v-col cols="3" class="hidden-sm-and-down" />
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="primary-title text-h6 secondary primary--text">
            Para finalizar
          </v-card-title>
          <v-card-text class="text-justify">
            <v-form lazy-validation>
              <v-row>
                <v-col cols="12" class="pl-3">
                  <p class="pt-4">Está a un paso de culminar.</p>
                  <p>
                    Para enviar la información suministrada previamente, por
                    favor haga click en
                    <span class="font-weight-bold">Finalizar</span>.
                  </p>
                  <p>Gracias por tomarse unos minutos de su valioso tiempo.</p>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3" class="hidden-sm-and-down" />
      <v-col cols="3" class="hidden-sm-and-down" />
      <v-col cols="6" md="3" class="align-content-center pr-1">
        <v-btn
          block
          class="primary--text"
          color="secondary"
          large
          :to="assessmentQuestionsURL"
        >
          Atrás
        </v-btn>
      </v-col>
      <v-col cols="6" md="3" class="align-content-center pl-1">
        <v-btn
          block
          class="secondary--text"
          color="primary"
          large
          @click="finish()"
          :disabled="processingRequest"
        >
          Finalizar
        </v-btn>
      </v-col>
      <v-col cols="3" class="hidden-sm-and-down" />
    </v-row>
    <v-row>
      <v-col>
        <v-dialog
          v-model="showSuccessDialog"
          persistent
          transition="dialog-transition"
          max-width="300"
        >
          <v-card>
            <v-card-title class="text-h5">
              <v-icon large>fas fa-award</v-icon>
            </v-card-title>
            <v-card-text>
              <p>
                La información se ha enviado con éxito. A continuación, cierre
                la pestaña para finalizar
              </p>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import assessmentMixin from "@/mixins/assessmentMixin";

export default defineComponent({
  mixins: [assessmentMixin],
  data() {
    return {
      showSuccessDialog: false
    };
  },
  created() {
    this.getAndValidateAssessment();
  },
  computed: {
    processingRequest() {
      return this.$store.getters.processingRequest;
    }
  },
  methods: {
    finish() {
      if (!this.processingRequest) {
        this.$store
          .dispatch("submitAssessment")
          .then(() => {
            this.showSuccessDialog = true;
          })
          .catch(error => {
            console.error(error.message);
          });
      }
    }
  }
});
</script>

<style lang="css" scoped>
.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 0.87);
}
</style>
