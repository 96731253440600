<template>
  <div>
    <div class="pt-4">
      <v-row no-gutters>
        <v-col cols="0" md="1" />
        <v-col cols="12" md="10" align="center" class="px-5 px-md-0">
          <report-section-title>
            Consejos para una buena comunicación
          </report-section-title>

          <xs-rotation-message />

          <div class="d-none d-sm-block d-print-block">
            <p class="mt-3 primary--text text-justify mb-4">
              Para lograr una buena comunicación es indispensable entender el
              estilo de comportamiento del interlocutor. Mantener ciertas pautas
              en la interacción con el otro al momento de comunicarse fortalece
              los vínculos interpersonales.
            </p>
            <div>
              <v-row class="mb-3">
                <v-col cols="3" class="table-title">Interlocutor</v-col>
                <v-col cols="4.5" class="table-title">Hacer</v-col>
                <v-col cols="4.5" class="table-title">Evitar</v-col>
              </v-row>
              <v-row
                class="mb-3 mt-3"
                v-for="(data, factor) in factors"
                :key="factor"
              >
                <v-col
                  cols="3"
                  class="card-cell white--text"
                  :class="`${data.letter.toLowerCase()}-cell`"
                >
                  <div
                    class="font-weight-bold text-left font-weight-medium uppercase"
                  >
                    {{ data.adjective }}
                  </div>
                  <p class="text-left">
                    {{ data.communicationTips.qualities }}
                  </p>
                </v-col>
                <v-col cols="4.5" class="content-cell-dark">
                  <ul>
                    <li>
                      {{ data.communicationTips.toDo }}
                    </li>
                  </ul>
                </v-col>
                <v-col cols="4.5" class="content-cell-dark">
                  <li>
                    {{ data.communicationTips.toAvoid }}
                  </li>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
        <v-col cols="0" md="1" />
      </v-row>
    </div>
  </div>
</template>

<script>
import factors from "@/model/disc/factors";

export default {
  name: "communicationTipsView",
  data() {
    return { factors };
  }
};
</script>

<style scoped>
.table-title {
  background: linear-gradient(
    150deg,
    var(--v-primary-base) 0%,
    var(--v-primary-lighten3) 100%
  );
  color: #fff;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  font-weight: bold;
  margin-left: 0.4em;
  margin-right: 0.4em;
  font-size: 1em;
  text-transform: uppercase;
}
.card-cell {
  background-size: cover;
  background-position: 50% 15%;
  margin-left: 0.4em;
  margin-right: 0.4em;
  padding: 2em;
  padding-top: 3em;
  border-bottom-left-radius: 50px;
}

.d-cell {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 82, 82, 0.7),
      rgba(128, 0, 0, 0.7)
    ),
    url("../../../../assets/card-dominance-no-text.png");
}
.s-cell {
  background-image: linear-gradient(
      to bottom,
      rgba(105, 203, 105, 0.7),
      rgba(0, 121, 30, 0.8)
    ),
    url("../../../../assets/card-serenity-no-text.png");
}
.c-cell {
  background-image: linear-gradient(
      180deg,
      rgba(33, 150, 243, 0.7),
      rgba(0, 77, 159, 0.7)
    ),
    url("../../../../assets/card-caution-no-text.png");
}
.i-cell {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 249, 86, 1),
      rgba(255, 193, 7, 0.3)
    ),
    url("../../../../assets/card-influence-no-text.png");
}
.content-cell-dark {
  background-color: rgba(233, 234, 237, 0.7);
  margin-left: 0.4em;
  margin-right: 0.4em;
  padding: 2em;
  text-align: left;
}
.uppercase {
  text-transform: uppercase;
}
@media print {
  .content-cell-dark {
    line-height: 1.2;
    font-size: 0.8em;
  }
  .card-cell {
    margin-left: 0.2em;
    margin-right: 0.2em;
    padding: 1em;
    padding-top: 1em;
    line-height: 1.3;
    font-size: 0.9em;
  }
  .d-cell {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 82, 82, 0.7),
        rgba(128, 0, 0, 0.7)
      ),
      url("../../../../assets/card-dominance-no-text.png");
  }
  .s-cell {
    background-image: linear-gradient(
        to bottom,
        rgba(105, 203, 105, 0.7),
        rgba(0, 121, 30, 0.8)
      ),
      url("../../../../assets/card-serenity-no-text.png");
  }
  .c-cell {
    background-image: linear-gradient(
        180deg,
        rgba(33, 150, 243, 0.7),
        rgba(0, 77, 159, 0.7)
      ),
      url("../../../../assets/card-caution-no-text.png");
  }
  .i-cell {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 249, 86, 1),
        rgba(255, 193, 7, 0.3)
      ),
      url("../../../../assets/card-influence-no-text.png");
  }
  .table-title {
    font-size: 0.8em;
  }
}
</style>
