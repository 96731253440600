<template>
  <v-container fluid class="white py-0 px-0" id="printPage">
    <report-header id="ready_to_know" class="topMarginNegative80">
      <template v-slot:data>
        <applicant-data :data="applicantData" />
      </template>

      <template v-slot:controls>
        <v-row justify="end" align="center">
          <v-col cols="8" class="pl-sm-10 pl-4">
            <v-select
              label="Proyecto de Fortalecimiento"
              :items="strengthPlanOptions"
              v-model="strengthPlan"
            />
          </v-col>

          <v-col cols="4" class="d-flex justify-end pr-sm-10 pr-4">
            <v-btn elevation="2" @click="onPrint">Imprimir</v-btn>
          </v-col>
        </v-row>
      </template>
    </report-header>

    <section-ready-to-know />

    <section-behavior-graph :profiles="profiles" />

    <section-style-and-answer
      :naturalName="primaryProfileName"
      :adaptiveName="consciousProfileName"
    />

    <section-profile-graph
      id="natural_style"
      title="Estilo Natural"
      icon="ICONOS-01.png"
      :profile="primaryProfile"
      :description="primaryStyleDescription"
    />

    <section-generic-bullet-content
      title="Estilo Natural"
      icon="ICONOS-01.png"
      :content="getNatural"
      v-if="isValidPrimaryProfile && isSimplifiedReport"
    />

    <section-generic-bullet-content
      id="highlighted_talents"
      title="Talentos Destacados"
      icon="ICONOS-01.png"
      :content="getTalents"
      v-if="isValidPrimaryProfile && isExtendedReport"
    />

    <section-generic-bullet-content
      id="prevailing_trends"
      title="Tendencias Predominantes"
      icon="ICONOS-04.png"
      :content="getTrends"
      v-if="isValidPrimaryProfile && isExtendedReport"
    />

    <section-generic-bullet-content
      id="possible_barriers"
      title="Oportunidades de Desarrollo"
      icon="ICONOS-05.png"
      :content="getBarriers"
      description="Algunas de estas afirmaciones son posibles retos de tu estilo
      natural, identifica aquellas que tengan más sentido para ti."
      v-if="isValidPrimaryProfile && isExtendedReport"
    />

    <section-generic-bullet-content
      id="communication_style"
      title="Estilo de Comunicación"
      icon="ICONOS-06.png"
      :content="communicationBullets"
      v-if="isValidPrimaryProfile"
    />

    <section-generic-bullet-content
      id="favorable_context"
      title="Contexto Favorable"
      icon="ICONOS-07.png"
      description="El contexto favorable son afirmaciones que describen el
      ambiente ideal para sentirse a gusto según el ESTILO NATURAL. Es
      importante destacar que, se puede ampliar la sensación de disfrute a
      diversos ambientes siendo flexible, sin embargo, si se pudiera crear un
      ambiente favorable para este estilo natural de comportamiento, el mismo
      debería tener las siguientes características:"
      :content="getContext"
      v-if="isValidPrimaryProfile"
    />

    <section-profile-graph
      id="adaptive_style"
      class="pagebreakBefore"
      title="Respuesta Adaptativa"
      icon="ICONOS-03.png"
      :profile="consciousProfile"
      :description="consciousStyleDescription"
    />

    <section-generic-bullet-content
      id="adaptive_style"
      title="Respuesta Adaptativa"
      icon="ICONOS-03.png"
      v-if="isValidConsciousProfile"
      :content="adaptiveBullets"
      :description="adaptiveDescription"
    />

    <section-communication-tips
      id="advices_for_good_communication"
      class="pagebreakBefore"
    />

    <section-natural-style-descriptors
      class="pagebreakBefore"
      id="naural_style_descriptors"
      :profile="primaryProfile"
    />

    <section-factors-power
      class="pagebreakBefore"
      id="factors_power"
      :data="data"
    />

    <section-personal-graphic
      id="personal_graphic"
      class="pagebreakBefore"
      :primary="primaryProfile"
      :conscious="consciousProfile"
    />

    <section-personal-plan
      class="pagebreakBefore"
      id="strengthening_project"
      v-if="strengthPlan === 'Personal'"
    />

    <section-working-plan
      class="pagebreakBefore"
      id="strengthening_project"
      v-if="strengthPlan === 'Laboral'"
    />

    <section-branding-plan
      class="pagebreakBefore"
      id="strengthening_project"
      v-if="strengthPlan === 'Marca Personal'"
    />

    <section-leadership-plan
      class="pagebreakBefore"
      id="strengthening_project"
      v-if="strengthPlan === 'Liderazgo'"
    />
  </v-container>
</template>

<script lang="js">
import { defineComponent } from "vue";

import { formatDate } from "@/utils/date";

import ApplicantData from "../common/ApplicantData.vue";
import ReportHeader from "../common/ReportHeader.vue";
import SectionReadyToKnow from "./components/SectionReadyToKnow.vue";
import SectionBehaviorGraph from "./components/SectionBehaviorGraph.vue";
import SectionStyleAndAnswer from "./components/SectionStyleAndAnswer.vue";
import SectionProfileGraph from "./components/SectionProfileGraph.vue";
import SectionGenericBulletContent from "@/views/reports/common/disc/SectionGenericBulletContent.vue";
import SectionCommunicationTips from "./components/SectionCommunicationTips.vue";
import SectionNaturalStyleDescriptors from "./components/SectionNaturalStyleDescriptors.vue";
import SectionFactorsPower from "./components/SectionFactorsPower.vue";
import SectionPersonalGraphic from "./components/SectionPersonalGraphic";
import SectionPersonalPlan from "./components/SectionPersonalPlan.vue";
import SectionWorkingPlan from "./components/SectionWorkingPlan.vue";
import SectionBrandingPlan from "./components/SectionBrandingPlan.vue";
import SectionLeadershipPlan from "./components/SectionLeadershipPlan.vue";

import assessmentMixin from "@/mixins/assessmentMixin";

export default defineComponent({
  name: "PersonalDISCReport",
  mixins: [assessmentMixin],
  components: {
    "applicant-data": ApplicantData,
    "report-header": ReportHeader,
    "section-ready-to-know": SectionReadyToKnow,
    "section-behavior-graph": SectionBehaviorGraph,
    "section-style-and-answer": SectionStyleAndAnswer,
    "section-profile-graph": SectionProfileGraph,
    "section-generic-bullet-content": SectionGenericBulletContent,
    "section-communication-tips": SectionCommunicationTips,
    "section-natural-style-descriptors": SectionNaturalStyleDescriptors,
    "section-factors-power": SectionFactorsPower,
    "section-personal-graphic": SectionPersonalGraphic,
    "section-personal-plan": SectionPersonalPlan,
    "section-working-plan": SectionWorkingPlan,
    "section-branding-plan": SectionBrandingPlan,
    "section-leadership-plan": SectionLeadershipPlan
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // data: null,
      strengthPlan: ""
    };
  },
  methods: {
    onPrint() {
      window.print();
    }
  },
  computed: {
    applicantData() {
      return [
        { label: "Aplicante", value: this.applicantName },
        { label: "Correo Electrónico", value: this.applicantEmail },
        { label: "Fecha de Aplicación", value: formatDate(this.submittedAt) }
      ];
    },
    applicantName() {
      return this.data ? this.data.applicantName : "-";
    },
    applicantEmail() {
      return this.data ? this.data.applicantEmail : "-";
    },
    submittedAt() {
      return this.data ? this.data.submittedAt : null;
    },
    profiles() {
      return this.data ? this.data.profiles : null;
    },
    strengthPlanOptions() {
      const options = ["Personal", "Laboral", "Marca Personal"];
      if (this.isSimplifiedReport) {
        options.push("Liderazgo");
      }
      return options;
    },
    consciousProfile() {
      return this.data && this.data.profiles && this.data.profiles.conscious
        ? this.data.profiles.conscious
        : null;
    },
    consciousProfileId() {
      return this.consciousProfile ? this.consciousProfile.id : 0;
    },
    consciousProfileName() {
      return this.consciousProfile ? this.consciousProfile.name : "-";
    },
    primaryProfile() {
      return this.data && this.data.profiles && this.data.profiles.primary
        ? this.data.profiles.primary
        : null;
    },
    primaryProfileId() {
      return this.primaryProfile ? this.primaryProfile.id : 0;
    },
    primaryProfileName() {
      return this.primaryProfile ? this.primaryProfile.name : "-";
    },
    getTalents() {
      return this.primaryProfile
        ? this.primaryProfile.highlightedTalents
        : null;
    },
    getTrends() {
      return this.primaryProfile ? this.primaryProfile.prevailingTrends : null;
    },
    getBarriers() {
      return this.primaryProfile ? this.primaryProfile.possibleBarriers : null;
    },
    communicationBullets() {
      return this.primaryProfile
        ? this.primaryProfile.communicationStyle
        : null;
    },
    getContext() {
      return this.primaryProfile ? this.primaryProfile.idealContext : null;
    },
    getNatural() {
      return this.primaryProfile ? this.primaryProfile.naturalStyle : null;
    },
    adaptiveBullets() {
      return this.consciousProfile &&
        (this.isExtendedReport || !this.isSameProfile)
        ? this.consciousProfile.adaptiveResponse
        : [];
    },
    adaptiveDescription() {
      return this.isExtendedReport || !this.isSameProfile
        ? null
        : "La <b>respuesta adaptativa</b> coincide con el <b>estilo natural</b>, esto puede suceder por alguna de estas razones:<br>" +
            "<br>" +
            "<ol>" +
            "<li>Siente satisfacción con los resultados actuales por lo que no considera necesario modificar el estilo natural.</li>" +
            "<li>Puede haber resistencia para modificar el comportamiento natural por lo que es importante comprender las causas de esta actitud.</li>" +
            "</ol>";
    },
    reportType() {
      return this.data
        ? this.data.reportType || "EXTENDED_DISC"
        : "EXTENDED_DISC";
    },
    isExtendedReport() {
      return (
        this.data &&
        (this.data.reportType === "EXTENDED_DISC" || !this.data.reportType)
      );
    },
    isSimplifiedReport() {
      return this.data && this.data.reportType === "SIMPLIFIED_DISC";
    },
    isValidPrimaryProfile() {
      return ![75, 76].includes(this.primaryProfileId);
    },
    isValidConsciousProfile() {
      return ![75, 76].includes(this.consciousProfileId);
    },
    isSameProfile() {
      const primaryProfile = this.primaryProfile;
      const consciousProfile = this.consciousProfile;

      return (
        primaryProfile &&
        consciousProfile &&
        primaryProfile.id === consciousProfile.id
      );
    },
    primaryStyleDescription() {
      return this.isValidPrimaryProfile
        ? undefined
        : "Los resultados vinculados al <b>ESTILO NATURAL</b> no concuerdan con ningún perfil válido, esto puede suceder por diversas razones. Por favor ponte en contacto con la persona que te envió la prueba para que coordinen una nueva aplicación del test.";
    },
    consciousStyleDescription() {
      return this.isValidConsciousProfile
        ? undefined
        : "Los resultados vinculados a la <b>RESPUESTA ADAPTATIVA</b> no concuerdan con ningún perfil válido, esto puede suceder por diversas razones. Por favor ponte en contacto con la persona que te envió la prueba para que coordinen una nueva aplicación del test.";
    }
  }
});
</script>

<style scoped>
@media print {
  .topMarginNegative80 {
    margin-top: -80px;
  }
  .pagebreakBefore {
    break-before: always !important;
    page-break-before: always !important;
  }
  html {
    overflow-y: auto;
  }
}
</style>
