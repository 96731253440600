<template>
  <v-app id="ovaldisc">
    <v-navigation-drawer app clipped v-if="showMenu" v-model="openMenu">
      <v-list dense>
        <v-list-item
          v-for="(item, index) in tableOfContents"
          :key="index"
          @click="goTo(item)"
        >
          <v-list-item-title class="o-menu-item" :href="item.href">
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="primary" dark height="80">
      <v-btn icon color="secondary" v-if="showMenu" @click="toggleReportMenu()">
        <v-icon>fas fa-list</v-icon>
      </v-btn>

      <v-toolbar-title>
        <router-link to="/">
          <img
            alt="LiderLab"
            class="header-logo"
            src="./assets/logo-color.png"
          />
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="font-weight-bold pr-4" v-if="!isXS && isUserAuthenticated">
        {{ user.displayName }}<br />
        {{ user.email }}
      </div>

      <v-menu left bottom v-if="isUserAuthenticated" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="secondary" v-bind="attrs" v-on="on">
            <v-icon>fas fa-bars</v-icon>
          </v-btn>
        </template>

        <v-list class="o-opacity">
          <v-list-item
            v-for="(menu, i) in filteredMenuItems"
            :key="i"
            @click="menu.handler"
          >
            <v-list-item-icon v-if="menu.icon">
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menu.label }}</v-list-item-title>
          </v-list-item>

          <v-divider v-if="isXS"></v-divider>

          <div class="text-center font-weight-bold pt-2" v-if="isXS">
            {{ user.displayName }}<br />
            {{ user.email }}
          </div>
        </v-list>
      </v-menu>
    </v-app-bar>

    <table class="print-layout-table">
      <tbody>
        <tr>
          <td>
            <v-main class="d-flex align-content-center flex-wrap main-height">
              <v-container fluid class="ma-0 pa-0">
                <v-row class="ma-0">
                  <v-col class="pa-0 text-center" cols="12">
                    <router-view
                      @open-dialog="openDialog"
                      @set-table-of-contents="setTableOfContents"
                      @open-dialog-team="openDialogTeam"
                      @set-floating-action="setFloatingAction"
                    ></router-view>
                  </v-col>
                </v-row>
              </v-container>
            </v-main>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div class="footer-space">&nbsp;</div>
          </td>
        </tr>
      </tfoot>
    </table>

    <!-- TODO: Componentize -->
    <v-dialog width="200" v-model="processingRequest" persistent>
      <v-card>
        <v-card-title class="text-h6"> Procesando... </v-card-title>

        <v-card-text class="text-center">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <o-message-dialog v-bind="messageDialogOptions" @close="closeDialog" />

    <o-message-dialog-team ref="messageDialogTeam" />

    <!-- TODO: Encapusale footer into component -->
    <v-footer app class="o-footer" color="secondary">
      <v-btn
        dark
        absolute
        top
        right
        fab
        v-if="showFloatingAction"
        :color="floatingAction.color"
        @click="floatingAction.handler"
      >
        <v-icon>{{ floatingAction.icon }}</v-icon>
      </v-btn>

      <div class="caption font-weight-bold" :title="version">
        LiderLab LLC &copy; {{ year }} - Todos los derechos reservados.
      </div>
    </v-footer>
  </v-app>
</template>

<script lang="js">
import packageJson from "../package.json";

export default {
  computed: {
    // TODO: Refactor to a mixin
    isUserAuthenticated() {
      return this.$store.getters.isUserAuthenticated;
    },
    isXS() {
      return this.$vuetify.breakpoint.name == "xs";
    },
    processingRequest() {
      return this.$store.getters.processingRequest;
    },
    showFloatingAction() {
      return this.isXS && this.floatingAction;
    },
    showMenu() {
      return this.$route.name === "ReportController" && !!this.tableOfContents;
    },
    user() {
      return this.$store.getters.user;
    },
    multiuserEnabled() {
      return this.$store.getters.accountInfo?.features?.multiuser;
    },
  appMenuItems() {
    return [
    {
        label: "Cambiar Contraseña",
        icon: "fas fa-key",
        handler: () => {
          this.$router.push("/change-password");
         }
       },
       {
        label: "Gestionar Membresía",
        icon: "fas fa-credit-card",
        handler: async () => {
          // TODO: In case we get the Membership Management from Stripe
          // const route = await this.$store.dispatch("manageMembership");
          // window.location.href = route;
         window.open(
           process.env.VUE_APP_MEMBERSHIP_MANAGEMENT_FORM_URL,
          "_blank"
         );
        }
       },
      {
        label: "Gestionar usuarios",
        icon: "fas fa-users",
        handler: () => {
          this.$router.push("/users");
        },
        visible: this.multiuserEnabled
      },
      {
        label: "Cerrar Sesión",
        icon: "fas fa-sign-out-alt",
        handler: this.signOut
        }
    ];
  },
  filteredMenuItems() {
    return this.appMenuItems.filter(menu => menu.visible !== false);
  }

},
  created() {
    this.openMenu = this.$vuetify.breakpoint.smAndUp;
    this.version = "v" + packageJson.version;
    this.year = new Date().getFullYear();
  },
  data() {
    return {
      openMenu: true,
      version: null,
      year: null,
      floatingAction: null,
      tableOfContents: null,
      messageDialogOptions: { show: false }
    };
  },
  methods: {
    goTo(item) {
      const el = document.getElementById(item.id);

      if (el) {
        window.scrollTo({
          top: el.offsetTop,
          behavior: "auto"
        });

        if (this.isXS) {
          this.openMenu = false;
        }
      } else {
        throw new Error(`HTML Element ${item.id} not found`);
      }
    },
    async signOut() {
      try {
        await this.$store.dispatch("signUserOut");
        this.$store.dispatch("initDashboard");
      } catch (error) {
        this.openDialog({
          type: "error",
          message: error.message
        });
      }
    },
    openDialog(opts) {
      this.messageDialogOptions = { ...opts, show: true };
    },
    closeDialog() {
      this.messageDialogOptions = { show: false };
    },
    setTableOfContents(tableOfContents) {
      this.tableOfContents = tableOfContents;
    },
    openDialogTeam(opts) {
      this.$refs.messageDialogTeam.open(opts);
    },
    toggleReportMenu() {
      this.openMenu = !this.openMenu;
    },
    setFloatingAction(action) {
      this.floatingAction = action;
    },
  },
  watch: {
    isUserAuthenticated(value) {
      if (!value) {
        this.$router.push("/signin");
      }
    }
  }
};
</script>

<style lang="css">
.black-background {
  background-color: black;
}
.white-background {
  background-color: #ffffff !important;
}
.uppercase {
  text-transform: uppercase;
}
.main-height {
  min-height: 100vh;
}
.o-footer > div {
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
}
.o-opacity {
  opacity: 0.9;
}
.o-menu-item {
  white-space: normal;
}
.v-card__title {
  word-break: normal !important;
}
.print-layout-table {
  border-spacing: 0;
}
.footer-space {
  height: 32px;
}
@media screen {
  .no-screen {
    display: none !important;
  }
  .theme--light.v-application {
    background-image: url("/img/background.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
  }
  .od-mt-16 {
    margin-top: 16px !important;
  }
}
@media screen and (max-width: 600px) {
  .view-container {
    height: calc(100vh - 112px);
  }
}
@media print {
  .no-print,
  nav,
  header {
    display: none !important;
  }
  main {
    padding-left: 0px !important;
  }
  @page {
    size: 8.5in 11in portrait;
    margin: 10mm 10mm 30mm 10mm;
  }
  .page-break-before {
    break-before: always !important;
    page-break-before: always !important;
  }
}
.v-messages__message {
  font-weight: 600;
}
.header-logo {
  cursor: pointer;
  width: 140px;
  height: 80px;
  object-fit: cover;
  object-position: 50% 40%;
}

/* Octagon Styles */
.cls-1 {
  fill: #fff;
}
.cls-2 {
  fill: url(#linear-gradient);
}
.cls-3 {
  fill: #cc0e9f;
}
.cls-4 {
  fill: #de65c1;
}
.cls-5 {
  fill: #d63eb2;
}
.cls-6 {
  fill: #e584cd;
}
.cls-7 {
  fill: #ea9dd7;
}
.cls-8 {
  fill: #eeb1df;
}
.cls-9 {
  fill: #f1c1e5;
}
.cls-10 {
  font-size: 57.56px;
}
.cls-10,
.cls-18,
.cls-26 {
  fill: #1a1a1a;
  font-family: Calibri-Light, Calibri;
  font-weight: 300;
}
.cls-11 {
  fill: #0071bc;
}
.cls-12 {
  fill: #338dc9;
}
.cls-13 {
  fill: #acd1e9;
}
.cls-14 {
  fill: #bddaed;
}
.cls-15 {
  fill: #7db6dd;
}
.cls-16 {
  fill: #5ca4d4;
}
.cls-17 {
  fill: #97c5e4;
}
.cls-18 {
  font-size: 57.56px;
}
.cls-19 {
  fill: #29abe2;
}
.cls-20 {
  fill: #c7e9f8;
}
.cls-21 {
  fill: #54bce8;
}
.cls-22 {
  fill: #76c9ed;
}
.cls-23 {
  fill: #91d4f1;
}
.cls-24 {
  fill: #a7ddf4;
}
.cls-25 {
  fill: #b9e4f6;
}
.cls-26 {
  font-size: 57.56px;
}
.cls-27 {
  fill: #39b54a;
}
.cls-28 {
  fill: #61c46e;
}
.cls-29 {
  fill: #bee7c4;
}
.cls-30 {
  fill: #cbecd0;
}
.cls-31 {
  fill: #9ad9a2;
}
.cls-32 {
  fill: #81d08b;
}
.cls-33 {
  fill: #aee1b5;
}
.cls-34 {
  fill: #8cc63f;
}
.cls-35 {
  fill: #e1f0cd;
}
.cls-36 {
  fill: #b5da84;
}
.cls-37 {
  fill: #a3d165;
}
.cls-38 {
  fill: #c4e19d;
}
.cls-39 {
  fill: #d0e7b1;
}
.cls-40 {
  fill: #d9ecc1;
}
.cls-41 {
  fill: #fcee21;
}
.cls-42 {
  fill: #fdf14d;
}
.cls-43 {
  fill: #fdf9b6;
}
.cls-44 {
  fill: #fefbd3;
}
.cls-45 {
  fill: #fdf68d;
}
.cls-46 {
  fill: #fdf471;
}
.cls-47 {
  fill: #fdf8a4;
}
.cls-48 {
  fill: #f27b06;
}
.cls-49 {
  fill: #fcddbd;
}
.cls-50 {
  fill: #f59538;
}
.cls-51 {
  fill: #f7aa60;
}
.cls-52 {
  fill: #f9bb80;
}
.cls-53 {
  fill: #fac999;
}
.cls-54 {
  fill: #fbd4ad;
}
.cls-55 {
  fill: red;
}
.cls-56 {
  fill: #f33;
}
.cls-57 {
  fill: #ffcaca;
}
.cls-58 {
  fill: #ffbdbd;
}
.cls-59 {
  fill: #ff7d7d;
}
.cls-60 {
  fill: #ff5c5c;
}
.cls-61 {
  fill: #ffacac;
}
.cls-62 {
  fill: #ed1c24;
}
.cls-63 {
  fill: #a0a0a0;
  stroke: #000;
  stroke-width: 5px;
}
/* End of Octagon */
</style>
