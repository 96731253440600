<template>
  <assessment-back-next-card-layout
    title="Selecciona la opción que mejor describa a tu equipo"
    :step="step"
    :steps="steps"
    @next="next"
    @back="back"
  >
    <v-form lazy-validation>
      <v-row>
        <v-col cols="12">
          <h2 class="mb-2">{{ currentSection.label }}</h2>
          <br />
          <v-row
            v-for="(question, questionIndex) in currentSection.items"
            :key="`question-${questionIndex}`"
            class="answer-row"
          >
            <v-col cols="12">
              <label class="answer-label">
                <input
                  type="radio"
                  class="answer-radio"
                  :value="question.value"
                  v-model="answers[currentSection.key]"
                />
                {{ question.text }}
              </label>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="text-left py-0">
        <v-col cols="12" v-if="error" class="red--text text-center py-0">
          {{ error }}
        </v-col>
      </v-row>
    </v-form>
  </assessment-back-next-card-layout>
</template>

<script lang="js">
import { defineComponent } from "vue";
import assessmentMixin from "@/mixins/assessmentMixin";
import questions from "@/model/fone-team/questions.js";

export default defineComponent({
  mixins: [assessmentMixin],
  data() {
    return {
      answers: {},
      error: false,
      sections: this.shuffleSections(questions) // Shuffle items within sections
    };
  },
  created() {
    if (this.getAndValidateAssessment()) {
      if (this.$store.getters.answers) {
        this.answers = this.$store.getters.answers[this.assessedId || "applicant"];
      } else {
        this.answers = this.sections.reduce(
          (answers, { key, items }) => ({
            ...answers,
            [key]: new Array(items.length).fill(undefined)
          }),
          {}
        );
      }
    }
  },
  computed: {
    step() {
      return this.$store.getters.currentStep;
    },
    steps() {
      return this.sections.length;
    },
    currentSection() {
      return this.sections[this.step];
    }
  },
  methods: {
    back() {
      this.error = false;

      if (this.step == 0) {
        this.setAnswers();

        this.$router.push(
          !!this.assessedId
            ? `${this.assessmentStartURL}/${this.assessedId}`
            : this.assessmentFormURL
        );
      } else {
        this.$store.dispatch("back");
      }
    },
    isValidSelection() {
      const currentAnswers = this.answers[this.currentSection.key];
      return Object.values(currentAnswers).every(item => !!item);
    },
    next() {
      if (this.isValidSelection()) {
        this.error = ""; // Clear error message if selection is valid

        if (this.step === this.steps - 1) {
          this.setAnswers();
          this.$router.push(this.assessmentSubmitURL);
        } else {
          this.$store.dispatch("next");
        }
      } else {
        this.error = "Debe seleccionar todas las opciones para continuar";
      }
    },
    setAnswers() {
      this.$store.dispatch("setAnswers", {
        [this.assessedId || "applicant"]: this.answers
      });
    },
    shuffleSections(sections) {
      return sections.map(section => ({
        ...section,
        items: this.shuffleArray(section.items)
      }));
    },
    shuffleArray(array) {
      let shuffledArray = array.slice();
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }
      return shuffledArray;
    }
  }
});
</script>

<style scoped>
.answer-row {
  margin-bottom: 10px;
}
.answer-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
  margin-right: 30px;
  text-align: left;
  font-size: medium;
}
.answer-radio {
  margin-right: 30px;
}
</style>
