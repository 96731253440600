<template>
  <div>
    <p>Por favor lea con cuidado las instrucciones antes de comenzar.</p>
    <p>
      Completar este instrumento te tomará aproximadamente 15 minutos. En este
      lapso, asegúrate de estar en un ambiente libre de interrupciones y de
      distracciones.
    </p>
    <p>
      Con este cuestionario identificarás el nivel de convergencia de tu
      colaborador con los objetivos laborales y podrás diseñar estrategias para
      mejorar el desempeño.
    </p>
    <p>Indicaciones:</p>
    <p>
      A continuación encontrarás un cuestionario con 10 items. En cada ítem se
      te presentarán 9 opciones, escoge aquella que mejor describa el
      comportamiento o actitud actual de tu colaborador.
    </p>
    <p>
      En la medida en que tu respuesta se ajuste a la realidad podrás obtener
      mayor provecho de los resultados y ajustar las estrategias para promover
      la alineación con los objetivos de la organización y tomar decisiones que
      beneficien el ambiente laboral y el alto desempeño.
    </p>
    <p>No analices mucho tus respuestas.</p>
    <p>¿Estás listo/a? ¡Empecemos!</p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "StartTextPGC"
});
</script>
