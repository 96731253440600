<template>
  <div class="octagon-container" ref="container">
    <div
      class="octagon-tooltip"
      v-if="!!tooltipData"
      :style="{ ...tooltipData.style }"
    >
      <template v-if="tooltipData.type === 'node'">
        <div>{{ tooltipData.content.id }} - {{ tooltipData.content.name }}</div>

        <ul>
          <li
            v-for="(applicantName, index) in tooltipData.content
              .applicantsNames"
            :key="`node-name-${tooltipData.content.id}-${index}`"
          >
            {{ applicantName }}
          </li>
        </ul>
      </template>

      <template v-else-if="tooltipData.type === 'text'">
        <div>{{ tooltipData.content }}</div>
      </template>
    </div>

    <svg viewBox="0 0 2051.28 1670.28">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="1038.27"
          y1="943.99"
          x2="1038.27"
          y2="708.07"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset="0.18" stop-color="#e5e5e5" />
          <stop offset="0.36" stop-color="#d3d3d3" />
          <stop offset="0.49" stop-color="#ccc" />
          <stop offset="0.63" stop-color="#d5d5d5" />
          <stop offset="0.86" stop-color="#ededed" />
          <stop offset="1" stop-color="#fff" />
        </linearGradient>
      </defs>

      <g id="GRÁFICO">
        <path
          id="_18"
          data-name="18"
          class="cls-1"
          d="M1253.52,877.46v235.93h-55.09a25.54,25.54,0,0,1-15.48-5.26l-73.27-56.2a25.57,25.57,0,0,1-10-20.19V959.12a25.57,25.57,0,0,1,10-20.19L1183,882.72a25.71,25.71,0,0,1,15.48-5.26Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_9"
          data-name="9"
          class="cls-1"
          d="M1407.27,959.12v72.62a25.57,25.57,0,0,1-10,20.19l-73.27,56.2a25.54,25.54,0,0,1-15.49,5.26h-55V877.46h55a25.71,25.71,0,0,1,15.49,5.26l73.27,56.21a25.5,25.5,0,0,1,10,20.19Z"
          transform="translate(-215.23 -169.4)"
        />
        <rect
          id="LINEA_DIVISORA"
          data-name="LINEA DIVISORA"
          class="cls-2"
          x="1037.27"
          y="708.07"
          width="2"
          height="235.93"
        />
        <path
          id="_12"
          data-name="12"
          class="cls-3"
          d="M584.53,772.06l380.89-292.2a25.42,25.42,0,0,1,7.55-4l-43.51-80.58a25.4,25.4,0,0,0-7.54,4L479.5,738.68a25.63,25.63,0,0,0-7.12,8.54l105,33.38A25.43,25.43,0,0,1,584.53,772.06Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_11"
          data-name="11"
          class="cls-4"
          d="M1008.93,560.43a25.6,25.6,0,0,1,7.54-4L973,475.89a25.42,25.42,0,0,0-7.55,4l-190.7,146.3,73.42,57.62Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_13"
          data-name="13"
          class="cls-5"
          d="M689.56,805.43,848.14,683.78l-73.42-57.62L584.53,772.06a25.43,25.43,0,0,0-7.12,8.54l105,33.37A25.63,25.63,0,0,1,689.56,805.43Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_28"
          data-name="28"
          class="cls-6"
          d="M848.14,683.78l73.43,57.62L1052.43,641a25.45,25.45,0,0,1,7.55-4l-43.51-80.57a25.6,25.6,0,0,0-7.54,4Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_29"
          data-name="29"
          class="cls-4"
          d="M794.59,838.8l127-97.4-73.43-57.62L689.56,805.43a25.63,25.63,0,0,0-7.12,8.54l105,33.37A25.63,25.63,0,0,1,794.59,838.8Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_30"
          data-name="30"
          class="cls-7"
          d="M921.57,741.4,995,799l101-77.44a25.4,25.4,0,0,1,7.54-4L1060,637a25.45,25.45,0,0,0-7.55,4Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_62"
          data-name="62"
          class="cls-6"
          d="M899.62,872.18,995,799,921.57,741.4l-127,97.4a25.63,25.63,0,0,0-7.12,8.54l105,33.38A25.43,25.43,0,0,1,899.62,872.18Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_59"
          data-name="59"
          class="cls-8"
          d="M1141.25,800.89a25.57,25.57,0,0,1,5.74-2.72h0l-43.51-80.57a25.4,25.4,0,0,0-7.54,4L995,799l73.42,57.62,71-54.49C1140,801.7,1140.63,801.28,1141.25,800.89Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_67"
          data-name="67"
          class="cls-7"
          d="M899.62,872.18a25.43,25.43,0,0,0-7.12,8.54l105,33.37a25.63,25.63,0,0,1,7.12-8.54l63.76-48.91L995,799Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_74"
          data-name="74"
          class="cls-9"
          d="M1147,798.18a25.31,25.31,0,0,0-5.74,2.71q-.93.58-1.8,1.26l-71,54.49-63.77,48.92a25.32,25.32,0,0,0-7.12,8.54l105,33.37a25.32,25.32,0,0,1,7.12-8.54l32.16-24.67L1183,882.72a25.48,25.48,0,0,1,7.54-4Z"
          transform="translate(-215.23 -169.4)"
        />

        <text
          class="cls-18"
          transform="matrix(0.8, -0.61, 0.61, 0.8, 342.06, 449.61)"
          @mouseenter="
            onMouseEnterText(
              $event,
              'Orientado a los resultados de calidad. Eficiente. Objetivo. Carácter fuerte. Práctico.'
            )
          "
          @mouseleave="onMouseLeaveText($event)"
        >
          EJECUCIÓN
        </text>

        <path
          class="cls-8"
          d="M1141.25,800.89a25.31,25.31,0,0,1,5.74-2.71h0A25.57,25.57,0,0,0,1141.25,800.89Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_4"
          data-name="4"
          class="cls-11"
          d="M574.57,1198.61V792.25a25.44,25.44,0,0,1,2.84-11.65l-105-33.38a25.47,25.47,0,0,0-2.84,11.65V1232a25.47,25.47,0,0,0,2.84,11.65l105-33.37A25.47,25.47,0,0,1,574.57,1198.61Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_19"
          data-name="19"
          class="cls-12"
          d="M679.61,1165.23V825.62A25.47,25.47,0,0,1,682.44,814l-105-33.37a25.44,25.44,0,0,0-2.84,11.65v406.36a25.47,25.47,0,0,0,2.84,11.65l105-33.38A25.43,25.43,0,0,1,679.61,1165.23Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_63"
          data-name="63"
          class="cls-13"
          d="M889.67,1098.49a25.5,25.5,0,0,0,2.83,11.65l105-33.38a25.47,25.47,0,0,1-2.83-11.65V925.74a25.47,25.47,0,0,1,2.83-11.65l-105-33.37a25.47,25.47,0,0,0-2.83,11.65Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_61"
          data-name="61"
          class="cls-14"
          d="M1099.72,1031.74a25.47,25.47,0,0,0,2.84,11.65l-105,33.37a25.47,25.47,0,0,1-2.84-11.65V925.74a25.4,25.4,0,0,1,2.84-11.64l105,33.37a25.47,25.47,0,0,0-2.84,11.65Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_60"
          data-name="60"
          class="cls-15"
          d="M784.64,859a25.5,25.5,0,0,1,2.83-11.65L682.44,814a25.47,25.47,0,0,0-2.83,11.65v172h105Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_68"
          data-name="68"
          class="cls-16"
          d="M784.64,997.62h-105v167.61a25.43,25.43,0,0,0,2.83,11.65l105-33.37a25.47,25.47,0,0,1-2.83-11.65Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_70"
          data-name="70"
          class="cls-17"
          d="M784.64,997.62h105V892.37a25.47,25.47,0,0,1,2.83-11.65l-105-33.38A25.5,25.5,0,0,0,784.64,859Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_64"
          data-name="64"
          class="cls-15"
          d="M889.67,997.62h-105v134.24a25.47,25.47,0,0,0,2.83,11.65l105-33.37a25.5,25.5,0,0,1-2.83-11.65Z"
          transform="translate(-215.23 -169.4)"
        />
        <text
          class="cls-18"
          transform="translate(202.71 944.91) rotate(-90)"
          @mouseenter="
            onMouseEnterText(
              $event,
              'Exacto. Crítico. Busca hechos. Orientado a la calidad. Atención al detalle. Organizado y normativo. Cumple los procedimientos.'
            )
          "
          @mouseleave="onMouseLeaveText($event)"
        >
          CAUTELA
        </text>
        <g id="ICONO_C" data-name="ICONO C">
          <circle class="cls-11" cx="55.88" cy="826.11" r="55.88" />
          <path
            class="cls-1"
            d="M289,1011.15l7.31,4.84a30.79,30.79,0,0,1-24.49,11.45c-18.23,0-31.36-13.92-31.36-32s13.13-31.89,31.36-31.89c10.22,0,18.85,4.31,24.49,11.45L289,979.79a21.57,21.57,0,0,0-17.18-8c-14.36,0-22.37,11.72-22.37,23.7s8,23.69,22.37,23.69A21.59,21.59,0,0,0,289,1011.15Z"
            transform="translate(-215.23 -169.4)"
          />
        </g>
        <path
          id="_21"
          data-name="21"
          class="cls-19"
          d="M965.42,1511,584.53,1218.8a25.63,25.63,0,0,1-7.12-8.54l-105,33.37a25.43,25.43,0,0,0,7.12,8.54l442.42,339.4a25.6,25.6,0,0,0,7.54,4L973,1515A25.45,25.45,0,0,1,965.42,1511Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_73"
          data-name="73"
          class="cls-20"
          d="M1190.49,1112.11l-43.5,80.57a25.16,25.16,0,0,1-7.54-4l-134.8-103.4a25.32,25.32,0,0,1-7.12-8.54l105-33.37a25.41,25.41,0,0,0,7.12,8.54l73.27,56.2A25.51,25.51,0,0,0,1190.49,1112.11Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_20"
          data-name="20"
          class="cls-21"
          d="M847.69,1306.73l-73.33,57.69L965.42,1511a25.45,25.45,0,0,0,7.55,4l43.5-80.57a25.4,25.4,0,0,1-7.54-4Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_22"
          data-name="22"
          class="cls-22"
          d="M689.56,1185.42a25.43,25.43,0,0,1-7.12-8.54l-105,33.38a25.63,25.63,0,0,0,7.12,8.54l189.83,145.62,73.33-57.69Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_53"
          data-name="53"
          class="cls-22"
          d="M921,1249l-73.32,57.7,161.24,123.69a25.4,25.4,0,0,0,7.54,4l43.51-80.58a25.42,25.42,0,0,1-7.55-4Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_57"
          data-name="57"
          class="cls-23"
          d="M689.56,1185.42l158.13,121.31L921,1249l-126.42-97a25.63,25.63,0,0,1-7.12-8.54l-105,33.37A25.43,25.43,0,0,0,689.56,1185.42Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_54"
          data-name="54"
          class="cls-24"
          d="M794.59,1152.05,921,1249l73.32-57.7-94.71-72.65a25.63,25.63,0,0,1-7.12-8.54l-105,33.37A25.63,25.63,0,0,0,794.59,1152.05Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_51"
          data-name="51"
          class="cls-23"
          d="M994.33,1191.33,921,1249l131.42,100.82a25.42,25.42,0,0,0,7.55,4l43.5-80.57a25.6,25.6,0,0,1-7.54-4Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_58"
          data-name="58"
          class="cls-24"
          d="M1067.66,1133.63l-73.33,57.7,101.61,78a25.6,25.6,0,0,0,7.54,4l43.51-80.57a25.45,25.45,0,0,1-7.55-4Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_66"
          data-name="66"
          class="cls-25"
          d="M899.62,1118.68l94.71,72.65,73.33-57.7-63-48.33a25.43,25.43,0,0,1-7.12-8.54l-105,33.38A25.63,25.63,0,0,0,899.62,1118.68Z"
          transform="translate(-215.23 -169.4)"
        />
        <text
          class="cls-26"
          transform="matrix(0.78, 0.62, -0.62, 0.78, 272.96, 1206.58)"
          @mouseenter="
            onMouseEnterText(
              $event,
              'Orientado a la implementación del plan. De ritmo pausado frente al cambio. Disciplinado. Equilibrio entre el análisis y la diplomacia. No le gusta la ambigüedad.'
            )
          "
          @mouseleave="onMouseLeaveText($event)"
        >
          COORDINACIÓN
        </text>
        <path
          id="_3"
          data-name="3"
          class="cls-27"
          d="M1526.08,1516.25H980.91A25.34,25.34,0,0,1,973,1515l-43.51,80.57a25.4,25.4,0,0,0,7.95,1.28h632.17a25.4,25.4,0,0,0,8-1.28L1534,1515A25.38,25.38,0,0,1,1526.08,1516.25Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_10"
          data-name="10"
          class="cls-28"
          d="M1482.57,1435.68H1024.42a25.69,25.69,0,0,1-8-1.28L973,1515a25.34,25.34,0,0,0,7.94,1.28h545.17A25.38,25.38,0,0,0,1534,1515l-43.5-80.57A25.69,25.69,0,0,1,1482.57,1435.68Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_47"
          data-name="47"
          class="cls-29"
          d="M1352.06,1194H1154.93a25.34,25.34,0,0,1-7.94-1.28l-43.51,80.57a25.4,25.4,0,0,0,8,1.28h284.13a25.4,25.4,0,0,0,8-1.28L1360,1192.68A25.38,25.38,0,0,1,1352.06,1194Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_25"
          data-name="25"
          class="cls-30"
          d="M1360,1192.68a25.38,25.38,0,0,1-7.94,1.28H1154.93a25.38,25.38,0,0,1-7.94-1.28l43.5-80.57a25.68,25.68,0,0,0,7.94,1.28h110.12a25.74,25.74,0,0,0,8-1.28Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_52"
          data-name="52"
          class="cls-31"
          d="M1067.92,1355.11a25.35,25.35,0,0,1-7.94-1.29l-43.51,80.58a25.69,25.69,0,0,0,8,1.28h229.09v-80.57Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_45"
          data-name="45"
          class="cls-32"
          d="M1490.52,1434.4,1447,1353.82a25.39,25.39,0,0,1-7.94,1.29H1253.51v80.57h229.06A25.69,25.69,0,0,0,1490.52,1434.4Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_55"
          data-name="55"
          class="cls-31"
          d="M1439.07,1355.11a25.39,25.39,0,0,0,7.94-1.29l-43.5-80.57a25.4,25.4,0,0,1-8,1.28h-142v80.58Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_56"
          data-name="56"
          class="cls-33"
          d="M1253.51,1355.11v-80.58H1111.43a25.4,25.4,0,0,1-8-1.28l-43.5,80.57a25.35,25.35,0,0,0,7.94,1.29Z"
          transform="translate(-215.23 -169.4)"
        />
        <text
          class="cls-18"
          transform="translate(898.9 1520.96)"
          @mouseenter="
            onMouseEnterText(
              $event,
              'Buen escucha. No confrontante. Acoplado. Estable. Controla sus emociones. Complaciente. Paciente. Reflexivo. Metódico.'
            )
          "
          @mouseleave="onMouseLeaveText($event)"
        >
          SERENIDAD
        </text>

        <circle class="cls-27" cx="1038.29" cy="1614.4" r="55.88" />
        <path
          class="cls-1"
          d="M1272.41,1797.49c0,8.63-5.9,18.23-21.75,18.23-9.43,0-19.21-4.22-22.2-13.91l8.72-2.74c.88,4.06,5.46,8.37,13.65,8.37,6.7,0,12.6-2.9,12.6-9.25,0-8.36-7.49-8.72-17.27-11.1-12.42-3-15.24-9.51-15.24-17.53,0-10.39,8.9-17.7,20.35-17.7,8.81,0,16.12,4.76,18.68,13.3l-8.64,2.11c-1.23-3.69-4.4-7.22-10.92-7.22-5.9,0-10.66,3.61-10.66,9.51,0,7.85,7.49,9.08,15.33,10.84C1267,1783.22,1272.41,1787.54,1272.41,1797.49Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_15"
          data-name="15"
          class="cls-34"
          d="M1922.46,1218.8,1541.56,1511a25.28,25.28,0,0,1-7.54,4l43.51,80.57a25.6,25.6,0,0,0,7.54-4l442.42-339.4a25.43,25.43,0,0,0,7.12-8.54l-105-33.37A25.63,25.63,0,0,1,1922.46,1218.8Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_72"
          data-name="72"
          class="cls-35"
          d="M1397.3,1051.93l-73.26,56.2a25.4,25.4,0,0,1-7.54,4l43.5,80.57a25.28,25.28,0,0,0,7.54-4l134.79-103.4a25.35,25.35,0,0,0,7.13-8.54l-105-33.37A25.54,25.54,0,0,1,1397.3,1051.93Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_16"
          data-name="16"
          class="cls-36"
          d="M1498.06,1430.42a25.4,25.4,0,0,1-7.54,4L1534,1515a25.28,25.28,0,0,0,7.54-4l189.62-145.45-73.28-57.73Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_14"
          data-name="14"
          class="cls-37"
          d="M1824.55,1176.88a25.43,25.43,0,0,1-7.12,8.54L1657.9,1307.81l73.28,57.73,191.28-146.74a25.63,25.63,0,0,0,7.12-8.54Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_44"
          data-name="44"
          class="cls-36"
          d="M1712.4,1152.05l-127.78,98,73.28,57.74,159.53-122.39a25.43,25.43,0,0,0,7.12-8.54l-105-33.37A25.63,25.63,0,0,1,1712.4,1152.05Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_49"
          data-name="49"
          class="cls-38"
          d="M1657.9,1307.81l-73.28-57.74-130.07,99.78a25.25,25.25,0,0,1-7.54,4l43.51,80.58a25.4,25.4,0,0,0,7.54-4Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_50"
          data-name="50"
          class="cls-39"
          d="M1584.62,1250.07l-73.28-57.73-100.29,76.94a25.6,25.6,0,0,1-7.54,4l43.5,80.57a25.25,25.25,0,0,0,7.54-4Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_43"
          data-name="43"
          class="cls-38"
          d="M1607.37,1118.68l-96,73.66,73.28,57.73,127.78-98a25.63,25.63,0,0,0,7.12-8.54l-105-33.37A25.63,25.63,0,0,1,1607.37,1118.68Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_46"
          data-name="46"
          class="cls-39"
          d="M1607.37,1118.68a25.63,25.63,0,0,0,7.12-8.54l-105-33.38a25.43,25.43,0,0,1-7.12,8.54l-64.28,49.31,73.28,57.73Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_48"
          data-name="48"
          class="cls-40"
          d="M1511.34,1192.34l-73.28-57.73-70.52,54.09a25.28,25.28,0,0,1-7.54,4l43.51,80.57a25.6,25.6,0,0,0,7.54-4Z"
          transform="translate(-215.23 -169.4)"
        />

        <text
          class="cls-10"
          transform="matrix(0.77, -0.64, 0.64, 0.77, 1551.45, 1401.06)"
          @mouseenter="
            onMouseEnterText(
              $event,
              'Orientado a las relaciones. Colaborador. Cercano. Solidario. Servicial. Buen miembro de equipo. Inspira buena voluntad.'
            )
          "
          @mouseleave="onMouseLeaveText($event)"
        >
          RELACIÓN
        </text>

        <path
          id="_2"
          data-name="2"
          class="cls-41"
          d="M2037.44,758.87a25.39,25.39,0,0,0-2.74-11.48l-105,33.37a25.43,25.43,0,0,1,2.74,11.49v406.36a25.47,25.47,0,0,1-2.83,11.65l105,33.37a25.47,25.47,0,0,0,2.83-11.65Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_17"
          data-name="17"
          class="cls-42"
          d="M1827.38,825.62v339.61a25.43,25.43,0,0,1-2.83,11.65l105,33.38a25.47,25.47,0,0,0,2.83-11.65V792.25a25.43,25.43,0,0,0-2.74-11.49l-105,33.38A25.39,25.39,0,0,1,1827.38,825.62Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_42"
          data-name="42"
          class="cls-43"
          d="M1512.29,925.74v139.37a25.47,25.47,0,0,1-2.83,11.65l105,33.38a25.5,25.5,0,0,0,2.83-11.65V892.37a25.35,25.35,0,0,0-2.74-11.48l-105,33.37A25.42,25.42,0,0,1,1512.29,925.74Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_65"
          data-name="65"
          class="cls-44"
          d="M1407.26,959.12v72.62a25.47,25.47,0,0,1-2.83,11.65l105,33.37a25.47,25.47,0,0,0,2.83-11.65V925.74a25.42,25.42,0,0,0-2.74-11.48l-105,33.37A25.43,25.43,0,0,1,1407.26,959.12Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_36"
          data-name="36"
          class="cls-45"
          d="M1722.35,995.51v136.35a25.47,25.47,0,0,1-2.83,11.65l105,33.37a25.43,25.43,0,0,0,2.83-11.65V995.51Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_40"
          data-name="40"
          class="cls-46"
          d="M1722.35,995.51h105V825.62a25.39,25.39,0,0,0-2.74-11.48l-105,33.37a25.42,25.42,0,0,1,2.74,11.48Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_35"
          data-name="35"
          class="cls-45"
          d="M1722.35,859a25.42,25.42,0,0,0-2.74-11.48l-105,33.38a25.35,25.35,0,0,1,2.74,11.48V995.51h105Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_38"
          data-name="38"
          class="cls-47"
          d="M1722.35,995.51h-105v103a25.5,25.5,0,0,1-2.83,11.65l105,33.37a25.47,25.47,0,0,0,2.83-11.65Z"
          transform="translate(-215.23 -169.4)"
        />

        <text
          class="cls-18"
          transform="translate(1866.64 681.71) rotate(90)"
          @mouseenter="
            onMouseEnterText(
              $event,
              'Confiado. Accesible. Persuasivo. Comunicativo. Sociable. Optimista. Busca la variedad.'
            )
          "
          @mouseleave="onMouseLeaveText($event)"
        >
          INFLUENCIA
        </text>

        <g id="ICONO_I" data-name="ICONO I">
          <circle class="cls-41" cx="1995.4" cy="826.11" r="55.88" />
          <path
            class="cls-1"
            d="M2214.5,964.64v61.66h-8.81V964.64Z"
            transform="translate(-215.23 -169.4)"
          />
        </g>
        <path
          id="_6"
          data-name="6"
          class="cls-48"
          d="M1541.56,479.86l380.9,292.2a25.47,25.47,0,0,1,7.21,8.7l105-33.37a25.49,25.49,0,0,0-7.21-8.71L1585.07,399.29a25.4,25.4,0,0,0-7.54-4L1534,475.89A25.25,25.25,0,0,1,1541.56,479.86Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_71"
          data-name="71"
          class="cls-49"
          d="M1324,882.72l73.26,56.21a25.39,25.39,0,0,1,7.22,8.7l105-33.37a25.52,25.52,0,0,0-7.22-8.71l-134.79-103.4a25.51,25.51,0,0,0-7.54-4l-43.5,80.58A25.6,25.6,0,0,1,1324,882.72Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_5"
          data-name="5"
          class="cls-50"
          d="M1657.89,683.05l74-57.2-190.31-146a25.25,25.25,0,0,0-7.54-4l-43.5,80.57a25.6,25.6,0,0,1,7.54,4Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_7"
          data-name="7"
          class="cls-51"
          d="M1922.46,772.06,1731.87,625.85l-74,57.2,159.54,122.38a25.41,25.41,0,0,1,7.21,8.71l105-33.38A25.47,25.47,0,0,0,1922.46,772.06Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_24"
          data-name="24"
          class="cls-51"
          d="M1583.91,740.24l74-57.19L1498.06,560.43a25.6,25.6,0,0,0-7.54-4L1447,637a25.28,25.28,0,0,1,7.54,4Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_37"
          data-name="37"
          class="cls-52"
          d="M1817.43,805.43,1657.89,683.05l-74,57.19L1712.4,838.8a25.6,25.6,0,0,1,7.21,8.71l105-33.37A25.41,25.41,0,0,0,1817.43,805.43Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_26"
          data-name="26"
          class="cls-53"
          d="M1712.4,838.8l-128.49-98.56-74,57.2,97.44,74.74a25.41,25.41,0,0,1,7.21,8.71l105-33.38A25.6,25.6,0,0,0,1712.4,838.8Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_23"
          data-name="23"
          class="cls-52"
          d="M1509.93,797.44l74-57.2L1454.55,641a25.28,25.28,0,0,0-7.54-4l-43.5,80.57a25.4,25.4,0,0,1,7.54,4Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_34"
          data-name="34"
          class="cls-53"
          d="M1411.05,721.58a25.4,25.4,0,0,0-7.54-4L1360,798.17a25.51,25.51,0,0,1,7.54,4L1436,854.63l74-57.19Z"
          transform="translate(-215.23 -169.4)"
        />
        <path
          id="_39"
          data-name="39"
          class="cls-54"
          d="M1607.37,872.18l-97.44-74.74-74,57.19,66.38,50.92a25.6,25.6,0,0,1,7.21,8.71l105-33.37A25.41,25.41,0,0,0,1607.37,872.18Z"
          transform="translate(-215.23 -169.4)"
        />

        <text
          class="cls-18"
          transform="matrix(0.79, 0.61, -0.61, 0.79, 1468.35, 251.36)"
          @mouseenter="
            onMouseEnterText(
              $event,
              'Orientado a la innovación. Rápido al cambio. Independiente. Enérgico. Impulsivo.'
            )
          "
          @mouseleave="onMouseLeaveText($event)"
        >
          CREATIVIDAD
        </text>

        <g id="_1" data-name="1">
          <path
            class="cls-55"
            d="M980.91,474.6h545.17a25.39,25.39,0,0,1,7.94,1.29l43.51-80.58a25.69,25.69,0,0,0-8-1.28H937.41a25.69,25.69,0,0,0-7.95,1.28L973,475.89A25.35,25.35,0,0,1,980.91,474.6Z"
            transform="translate(-215.23 -169.4)"
          />
        </g>
        <g id="_8" data-name="8">
          <path
            class="cls-56"
            d="M1024.42,555.18h458.15a25.4,25.4,0,0,1,8,1.28l43.5-80.57a25.39,25.39,0,0,0-7.94-1.29H980.91a25.35,25.35,0,0,0-7.94,1.29l43.5,80.57A25.4,25.4,0,0,1,1024.42,555.18Z"
            transform="translate(-215.23 -169.4)"
          />
        </g>
        <g id="_33" data-name="33">
          <path
            class="cls-57"
            d="M1360,798.17l-43.5,80.58a25.46,25.46,0,0,0-8-1.29H1198.43a25.39,25.39,0,0,0-7.94,1.29L1147,798.17a25.38,25.38,0,0,1,7.94-1.28h197.13A25.38,25.38,0,0,1,1360,798.17Z"
            transform="translate(-215.23 -169.4)"
          />
        </g>
        <g id="_31" data-name="31">
          <path
            class="cls-58"
            d="M1395.56,716.32H1111.43a25.4,25.4,0,0,0-8,1.28L1147,798.17a25.63,25.63,0,0,1,7.94-1.28h197.13a25.68,25.68,0,0,1,7.94,1.28l43.51-80.57A25.69,25.69,0,0,0,1395.56,716.32Z"
            transform="translate(-215.23 -169.4)"
          />
        </g>
        <g id="_41" data-name="41">
          <path
            class="cls-59"
            d="M1490.52,556.46a25.4,25.4,0,0,0-8-1.28H1252.38v80.57h186.69a25.29,25.29,0,0,1,6,.73c.65.16,1.29.34,1.93.55Z"
            transform="translate(-215.23 -169.4)"
          />
        </g>
        <g id="_32" data-name="32">
          <path
            class="cls-60"
            d="M1252.38,635.75V555.18h-228a25.4,25.4,0,0,0-8,1.28L1060,637a25.34,25.34,0,0,1,7.94-1.28Z"
            transform="translate(-215.23 -169.4)"
          />
        </g>
        <g id="_27" data-name="27">
          <path
            class="cls-61"
            d="M1253.51,635.75h-1.13v80.57h143.18a25.69,25.69,0,0,1,8,1.28L1447,637c-.64-.21-1.28-.39-1.93-.55a25.29,25.29,0,0,0-6-.73Z"
            transform="translate(-215.23 -169.4)"
          />
        </g>
        <g id="_69" data-name="69">
          <path
            class="cls-59"
            d="M1067.92,635.75A25.34,25.34,0,0,0,1060,637l43.5,80.57a25.4,25.4,0,0,1,8-1.28h141V635.75Z"
            transform="translate(-215.23 -169.4)"
          />
        </g>

        <text
          class="cls-18"
          transform="translate(869.33 189.89)"
          @mouseenter="
            onMouseEnterText(
              $event,
              'Competitivo. Confrontante. Directo. Orientado a resultados. Rápido. Agente de cambio. Asume riesgos.'
            )
          "
          @mouseleave="onMouseLeaveText($event)"
        >
          DOMINANCIA
        </text>

        <g id="ICONO_D" data-name="ICONO D">
          <circle class="cls-62" cx="1037.15" cy="55.88" r="55.88" />
          <path
            class="cls-1"
            d="M1280.44,225.23c0,17.44-11.28,30.83-32.16,30.83h-17.44V194.4h17.44C1269.16,194.4,1280.44,207.88,1280.44,225.23Zm-8.9,0c0-13.83-8.63-22.81-23.26-22.81h-8.63v45.63h8.63C1262.91,248.05,1271.54,239.06,1271.54,225.23Z"
            transform="translate(-215.23 -169.4)"
          />
        </g>
      </g>

      <g id="OVALOS">
        <template v-for="(n, index) in nodes">
          <o-octagon-node
            :data="n"
            :key="index"
            :highlight="highlight(n)"
            v-if="!!n"
            @mouseenter="onMouseEnterNode"
            @mouseleave="onMouseLeaveNode"
          />
        </template>
      </g>
    </svg>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

import OctagonNode from "./OctagonNode";
import { getNode } from "./octagonProfiles";

const formatNodeTooltipText = (data) => {
  const { id, name, applicantsNames } = data;
  const label = `${id} - ${name}`;

  if (applicantsNames && applicantsNames.length > 0) {
    return `${label}: ${applicantsNames.join(", ")}`;
  }

  return label;
};

export default defineComponent({
  name: "Octagon",
  components: {
    "o-octagon-node": OctagonNode
  },
  props: {
    data: {
      title: "",
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedNode: null,
      tooltipData: null
    };
  },
  methods: {
    onMouseEnterNode(e, node) {
      this.showTooltip(e, "node", node);
      this.selectedNode = node;
    },
    onMouseLeaveNode(_) {
      this.hideTooltip();
      this.selectedNode = null;
    },
    onMouseEnterText(e, text) {
      this.showTooltip(e, "text", text);
    },
    onMouseLeaveText(_) {
      this.hideTooltip();
    },
    highlight(node) {
      if (!this.selectedNode) {
        return false;
      }

      return node.reportId === this.selectedNode.reportId &&
        node.profileType !== this.selectedNode.profileType;
    },
    showTooltip(e, type, content) {
      const { container } = this.$refs;
      const containerRect = container.getBoundingClientRect();

      const tooltipX = e.clientX - containerRect.left + container.scrollLeft + 10;
      const tooltipY = e.clientY - containerRect.top + container.scrollTop + 10;

      this.tooltipData = {
        type,
        content,
        style: {
          left: `${tooltipX}px`,
          top: `${tooltipY}px`
        }
      };
    },
    hideTooltip() {
      this.tooltipData = null;
    }
  },
  computed: {
    nodes() {
      const results = [];

      if (this.data && this.data.length > 0) {
        this.data.forEach(d => {
          const nodeCode = `${d.profileType}${d.id}`;
          let node = results.find(n => n.code === nodeCode);

          if (!node) {
            node = getNode(nodeCode);

            if (node) {
              node.id = d.id;
              node.name = d.name;
              node.profileType = d.profileType;
              node.reportId = d.reportId;
              node.applicantsNames = [];
              results.push(node);
            } else {
              throw new Error(`Profile not found for code: "${nodeCode}"`);
            }
          }

          if (d.applicantName) {
            node.applicantsNames.push(d.applicantName);
          }
        });
      }

      return results.map(node => ({
        ...node,
        label: formatNodeTooltipText(node)
      }));
    }
  }
});
</script>

<style scoped lang="css">
@media screen {
  svg {
    max-height: 75vh;
    width: auto;
  }
}
@media print {
  svg {
    height: auto;
    width: 300;
  }
}
.octagon-container {
  position: relative;
}

.octagon-tooltip {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  -webkit-justify-content: center;
  background: #444;
  border-radius: 8px;
  color: #fff;
  content: attr(title);
  font-size: 16px;
  padding: 12px;
}
.octagon-tooltip ul {
  padding-left: 0;
  list-style-position: inside;
}
.octagon-tooltip ul li {
  padding-left: 0;
}
</style>
