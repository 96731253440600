<template>
  <v-dialog
    v-model="visible"
    persistent
    transition="dialog-transition"
    :max-width="width"
    @keydown.esc="close()"
  >
    <v-card>
      <v-card-title class="text-h5">
        <v-icon large>{{ badge }}</v-icon>
      </v-card-title>

      <v-card-text class="text-center pt-4">
        {{ message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          class="secondary--text"
          color="primary"
          v-if="!actions"
          @click="close()"
        >
          Cerrar
        </v-btn>

        <template v-if="!!actions">
          <v-btn
            class="secondary--text"
            color="primary"
            v-for="(action, index) in actions"
            :key="index"
            @click="exec(action.handler)"
          >
            {{ action.label }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MessageDialog",
  props: {
    type: {
      type: String,
      default: "success"
    },
    message: {
      type: String,
      default: ""
    },
    /**
     * Attributes
     *  - label
     *  - handler
     */
    actions: {
      type: Array,
      default: null
    },
    closeHandler: {
      type: Function,
      default: null
    },
    width: {
      type: String,
      default: "450"
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    badge() {
      let badge = null;
      if (this.type === "success") {
        badge = "fas fa-award";
      } else if (this.type === "error") {
        badge = "fas fa-ban";
      } else if (this.type === "decision") {
        badge = "fas fa-question";
      }
      return badge;
    }
  },
  methods: {
    close() {
      this.visible = false;

      if (this.closeHandler && this.closeHandler instanceof Function) {
        this.closeHandler();
      }

      this.$emit("close");
    },

    exec(handler) {
      if (handler) {
        handler();
      }
      this.close();
    }
  },
  watch: {
    show(value) {
      this.visible = value;
    }
  }
});

function checkType(type) {
  if (!["success", "error", "decision"].includes(type)) {
    throw new Error("Unsupported type");
  }
}
</script>
