<template>
  <div>
    <p>Por favor lea con cuidado las instrucciones antes de comenzar.</p>
    <p>
      El cuestionario que va a completar a continuación le tomará
      aproximadamente 20 minutos de su tiempo. En este lapso, asegúrese de poder
      encontrarse en un ambiente libre de interrupciones y de distracciones.
    </p>
    <p>
      Este test no evalúa respuestas correctas o incorrectas, o buenas o malas.
      Todos los estilos de comportamiento son únicos y valiosos.
    </p>
    <p>
      Se recomienda no sobre analizar las respuestas, trate de contestar con lo
      primero que se le venga a la mente.
    </p>
    <p>
      En cada ítem, debe seleccionar la opción que más lo describa y la opción
      que menos lo describa. Si no se identifica con las opciones propuestas,
      igualmente debe escoger una que se aproxime a lo que más lo describe y una
      a lo que menos lo describe. Cuando tenga dudas en su respuesta, ubíquese
      en su ambiente laboral para hacer su selección.
    </p>
    <p>
      Deberá responder a cada ítem para poder avanzar en la prueba a la
      siguiente respuesta.
    </p>
    <p>
      ¿Considera que en los próximos 20 minutos se encontrará libre de
      interrupciones y de distractores?, de ser así, puede comenzar.
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "StartTextDISC"
});
</script>
