function evaluateThreshold(value) {
  return value >= 86 ? 1 : 2;
}

export const calculateKey = function (data) {
  const key = {};
  let factors = [];
  // Profiles 1 to 4
  for (let factor in data) {
    const value = data[factor].percentage;
    if (value < 50) {
      key[factor] = 0;
    } else {
      key[factor] = 3;
      factors.push({ factor: factor, value: value });
    }
  }
  // Profiles 5 to 74
  if (factors.length == 2) {
    // Profiles 5 to 22
    const rest = factors[0].value - factors[1].value;
    if (rest >= 10) {
      key[factors[1].factor]--;
    } else if (rest <= -10) {
      key[factors[0].factor]--;
    }
  } else if (factors.length == 3) {
    // Profiles 23 to 74
    factors = factors.sort((a, b) => b.value - a.value);
    let firstTwoAreEqual = true;
    if (factors[0].value - factors[1].value > 0) {
      key[factors[1].factor]--;
      firstTwoAreEqual = false;
    }
    if (factors[0].value - factors[2].value > 0) {
      key[factors[2].factor]--;
    }
    if (!firstTwoAreEqual && factors[1].value - factors[2].value > 0) {
      key[factors[2].factor]--;
    }
  }
  return (
    key.D.toString() + key.I.toString() + key.S.toString() + key.C.toString()
  );
};

const attachSection = function (profile, profileData, sectionId, sectionData) {
  let { id } = profile;

  if (id < 5) {
    const { code } = profile;
    const { percentage } = profileData.values[code];
    const idComplement = evaluateThreshold(percentage);
    id = `${id}.${idComplement}`;
  }

  profileData[sectionId] = sectionData[id];
};

export const attachSections = function (profile, profileData, sections) {
  const sectionIds = Object.keys(sections);
  sectionIds.forEach(sectionId =>
    attachSection(profile, profileData, sectionId, sections[sectionId])
  );
};
