export const beginner = [
  {
    key: "job",
    label: "Estrategias enfocadas en optimizar la ejecución de las tareas",
    items: [
      "Definir metas y convenir las normas del equipo.",
      "Definir cómo se medirán los logros.",
      "Entrenar habilidades y dar formación técnica.",
      "Definir procedimientos.",
      "Comunicar las políticas.",
      "Aclarar el propósito del equipo y dar a conocer los valores del equipo.",
      "Convenir las funciones de cada integrante del equipo.",
      "Definir tareas y responsabilidades.",
      "Aclarar planes de acción.",
      "Proporcionar oportunidades para poner en práctica nuevas habilidades.",
      "Proporcionar referencias y estándares para evaluar el logro de las metas.",
      "Proporcionar los recursos que permitan a las personas realizar su trabajo fácilmente.",
      "Ayudarlos a superar los obstáculos en el logro de los resultados.",
      "Tomar activamente las decisiones, explicando a cada uno su rol y funciones."
    ]
  },
  {
    key: "motivation",
    label: "Estrategias enfocadas en potenciar la motivación",
    items: [
      "Dar retroalimentación frecuente.",
      "Permitir los errores como parte del aprendizaje.",
      "Corregir los errores, amablemente, aportando aprendizaje y confianza.",
      "Crear un ambiente psicológicamente seguro para aprender.",
      "Estar atento cuando las personas hacen su trabajo bien hecho y proporcionar retroalimentación positiva, en ese momento, al equipo como un todo y no de forma individual."
    ]
  },
  {
    key: "relationships",
    label: "Estrategias enfocadas en el fortalecimiento de las relaciones",
    items: [
      "Crear oportunidades para que los miembros del equipo se conozcan y se integren.",
      "Realizar encuentros para integración grupal (Team Building).",
      "Implementar buenos hábitos de comunicación.",
      "Implementar el uso de herramientas colaborativas."
    ]
  }
];

export const distrustful = [
  {
    key: "job",
    label: "Estrategias enfocadas en optimizar la ejecución de las tareas",
    items: [
      "Recordar las metas y las normas.",
      "Reforzar aprendizajes técnicos y habilidades.",
      "Aclarar protocolos, normas, políticas y procedimientos.",
      "Recordar el propósito y los valores del equipo.",
      "Reforzar las funciones, tareas y responsabilidades del equipo.",
      "Revisar los planes de acción.",
      "Proporcionar oportunidades para poner en práctica los aprendizajes.",
      "Proporcionar retroalimentación al equipo sobre los resultados.",
      "Proporcionar información, estándares y referencias para evaluar los resultados.",
      "Proporcionar los recursos que permitan a las personas realizar sus trabajos fácilmente.",
      "Ayudarlos a superar los obstáculos en el logro de los resultados.",
      "Ser activo en la toma de decisiones, explicando al equipo el criterio aplicado."
    ]
  },
  {
    key: "motivation",
    label: "Estrategias enfocadas en potenciar la motivación",
    items: [
      "Crear un ambiente psicológicamente seguro para aprender.",
      "Dar retroalimentación frecuente.",
      "Permitir los errores como parte del aprendizaje.",
      "Corregir los errores, amablemente, aportando aprendizaje y confianza.",
      "Estar atento cuando las personas hacen su trabajo bien hecho y proporcionar retroalimentación positiva, en ese momento, al equipo como un todo y no de forma individual.",
      "Celebrar los éxitos."
    ]
  },
  {
    key: "relationships",
    label: "Estrategias enfocadas en el fortalecimiento de las relaciones",
    items: [
      "Estimular la escucha y la comunicación abierta.",
      "Crear oportunidades para que los miembros del equipo se integren.",
      "Escuchar activamente al equipo.",
      "Crear relaciones de apoyo.",
      "Demostrar los comportamientos adecuados para solucionar confrontaciones, desafíos y conflictos.",
      "Ayudarlos a superar obstáculos y a resolver los conflictos y tensiones dentro del equipo.",
      "Realizar encuentros para integración grupal (Team Building)."
    ]
  }
];

export const misaligned = [
  {
    key: "job",
    label: "Estrategias enfocadas en optimizar la ejecución de las tareas",
    items: [
      "Proporcionar apoyo y dar confianza para mejorar el desempeño en la ejecución de las tareas.",
      "Recordar el propósito del equipo.",
      "Afianzar los valores del equipo.",
      "Recordar las normas y las funciones.",
      "Aclarar dudas sobre las tareas y planes de acción.",
      "Enseñar a pensar correctamente para la toma de decisiones, desarrollar criterio.",
      "Permitirles practicar lo aprendido.",
      "Recordar las políticas.",
      "Aclarar los protocolos y los procedimientos.",
      "Dar retroalimentación sobre los resultados.",
      "Recordar cómo se evaluarán los resultados.",
      "Proporcionar al equipo los recursos que permitan realizar su trabajo fácilmente.",
      "Ayudar al equipo a superar los obstáculos en el logro de los resultados.",
      "Participar activamente en la toma de decisiones, permitiendo que el equipo opine y aporte."
    ]
  },
  {
    key: "motivation",
    label: "Estrategias enfocadas en potenciar la motivación",
    items: [
      "Alentar la participación en el liderazgo para la solución de problemas y la toma de decisiones, manteniendo la supervisión.",
      "Permitir que se expresen y se debatan las diferentes opiniones y puntos de vistas.",
      "Utilizar los errores amablemente como fuente de aprendizaje, creando un espacio psicológicamente seguro, que le permita al equipo enfrentar su vulnerabilidad.",
      "Estar atento cuando las personas hacen su trabajo bien hecho para proporcionar retroalimentación positiva, en ese momento, al equipo como un todo y no de forma individual.",
      "Celebrar los éxitos.",
      "Aprovechar cada oportunidad para afianzar los valores del equipo."
    ]
  },
  {
    key: "relationships",
    label: "Estrategias enfocadas en el fortalecimiento de las relaciones",
    items: [
      "Escuchar con atención y de forma activa al equipo.",
      "Crear relaciones de apoyo.",
      "Demostrar los comportamientos adecuados para solucionar confrontaciones, desafíos y conflictos.",
      "Compartir momentos de entretenimiento juntos.",
      "Crear espacios para la integración.",
      "Velar por el respeto mutuo entre los miembros del equipo.",
      "Incentivar la integración de los miembros marginados.",
      "Velar porque no se establezcan parcelas.",
      "Organizar reuniones en donde todos los miembros del equipo tengan preponderancia y no unos más que otros.",
      "No demostrar preferencia por ningún miembro del equipo.",
      "Crear espacios para que los miembros manifiesten las causas de su descontento y frustración.",
      "Conocer las necesidades del equipo y ayudar a superarlas.",
      "Dar formación en técnicas de comunicación.",
      "Dar formación en estrategias de solución de conflictos y problemas.",
      "Fortalecer el uso de herramientas colaborativas."
    ]
  }
];

export const unsure = [
  {
    key: "job",
    label: "Estrategias enfocadas en optimizar la ejecución de las tareas",
    items: [
      "Colaborar con el equipo en la solución de los problemas y en la toma de decisiones.",
      "Brindar dirección, según el requerimiento del equipo.",
      "Estar disponible para cualquier asesoría que el equipo requiera.",
      "Permitir la participación en la toma de decisiones.",
      "Escucharlos activamente, permitiendo que ellos tomen las decisiones.",
      "Asesorarlos, cuando sea necesario, sobre la aplicación del criterio adecuado.",
      "Ofrecer sugerencias si es necesario, luego de haberlos escuchado y hacerlos participar.",
      "Asegurarse de que el equipo cuente con los recursos necesarios para el logro de los objetivos.",
      "Preguntar ¿Cómo puedo ayudarlos?",
      "Dar formación en resolución de conflictos, toma de decisiones y pensamiento crítico."
    ]
  },
  {
    key: "motivation",
    label: "Estrategias enfocadas en potenciar la motivación",
    items: [
      "Permitir que se expresen y se debatan las diferentes opiniones y puntos de vistas.",
      "Utilizar los errores amablemente como fuente de aprendizaje, creando un espacio psicológicamente seguro, que le permita al equipo enfrentar su vulnerabilidad.",
      "Reconocer y elogiar al equipo como un todo.",
      "Celebrar los éxitos.",
      "Compartir el liderazgo.",
      "Estimular la participación."
    ]
  },
  {
    key: "relationships",
    label: "Estrategias enfocadas en el fortalecimiento de las relaciones",
    items: [
      "Estimular la escucha y la comunicación abierta.",
      "Crear relaciones cercanas.",
      "Ayudarlos a integrar sus diferencias.",
      "Fortalecer la integración del equipo, cuidando de que no se formen parcelas o haya miembros marginados.",
      "Realizar actividades para compartir y disfrutar.",
      "Darles formación en equipos de alto desempeño."
    ]
  }
];

export const capable = [
  {
    key: "job",
    label: "Estrategias enfocadas en optimizar la ejecución de las tareas",
    items: [
      "Colaborar con el equipo en la solución de los problemas y en la toma de decisiones.",
      "Brindar dirección, según el requerimiento del equipo.",
      "Estar disponible para cualquier asesoría que el equipo requiera.",
      "Permitir la participación en la toma de decisiones.",
      "Escucharlos activamente, permitiendo que ellos tomen las decisiones.",
      "Asesorarlos, cuando sea necesario, sobre la aplicación del criterio adecuado.",
      "Ofrecer sugerencias si es necesario, luego de haberlos escuchado y hacerlos participar.",
      "Asegurarse de que el equipo cuente con los recursos necesarios para el logro de los objetivos.",
      "Preguntar ¿Cómo puedo ayudarlos?",
      "Dar formación en resolución de conflictos, toma de decisiones y pensamiento crítico."
    ]
  },
  {
    key: "motivation",
    label: "Estrategias enfocadas en potenciar la motivación",
    items: [
      "Dejar al equipo debatir las ideas y ponerse de acuerdo. Participar únicamente si es requerido por el equipo.",
      "Reconocer y elogiar al equipo como un todo, resaltando también, los comportamientos ejemplares y aportes de cada miembro.",
      "Estimular que los miembros del equipo se reconozcan unos a otros positivamente.",
      "Permitir que el mismo equipo comparta el liderazgo.",
      "Celebrar los éxitos."
    ]
  },
  {
    key: "relationships",
    label: "Estrategias enfocadas en el fortalecimiento de las relaciones",
    items: [
      "Estimular la escucha y la comunicación abierta.",
      "Crear relaciones cercanas.",
      "Dejar que sean ellos quienes integren sus diferencias.",
      "Realizar actividades para compartir y disfrutar.",
      "Dar formación en equipos de alto desempeño."
    ]
  }
];

export const bounded = [
  {
    key: "job",
    label: "Estrategias enfocadas en optimizar la ejecución de las tareas",
    items: [
      "Permitir que sea el equipo quien tome las decisiones, dando apoyo y reconocimiento.",
      "Estar disponible para cualquier asesoría que el equipo requiera.",
      "Dar asesoría tipo coach.",
      "Asegurarse de que el equipo cuente con los recursos necesarios para el logro de los objetivos.",
      "Preguntar ¿Cómo puedo ayudarlos?",
      "Estimular la autonomía en la solución de los problemas y la toma de decisiones.",
      "Estimular un ambiente de innovación y creatividad."
    ]
  },
  {
    key: "motivation",
    label: "Estrategias enfocadas en potenciar la motivación",
    items: [
      "Utilizar la experiencia del equipo para que entrenen a otras personas u otros equipos.",
      "Reconocer y elogiar los aportes de cada miembro del equipo.",
      "Celebrar junto a ellos los éxitos."
    ]
  },
  {
    key: "relationships",
    label: "Estrategias enfocadas en el fortalecimiento de las relaciones",
    items: [
      "Dejar que sean ellos quienes integren sus diferencias.",
      "Fortalecer la comunicación auténtica, sincera y abierta.",
      "Dejar que ellos mismos compartan el liderazgo.",
      "Ser un colega más que un jefe.",
      "Realizar actividades para compartir y disfrutar."
    ]
  }
];

export const innovative = [
  {
    key: "job",
    label: "Estrategias enfocadas en optimizar la ejecución de las tareas",
    items: [
      "Ser un colega más que un jefe.",
      "Permitir la autonomía en la toma de decisiones y la solución de problemas.",
      "Estimular un ambiente de innovación y creatividad.",
      "Asegurarse de que el equipo cuente con los recursos necesarios para el logro de los objetivos.",
      "Validar."
    ]
  },
  {
    key: "motivation",
    label: "Estrategias enfocadas en potenciar la motivación",
    items: [
      "Celebrar junto a ellos los éxitos.",
      "Ser uno más del equipo.",
      "Ofrecerles nuevos retos y desafíos.",
      "Utilizar la experiencia del equipo para que entrenen a otras personas u otros equipos.",
      "Ofrecer entrenamiento especializado o formación en otras áreas diferentes, que pueda llevar al equipo a un desempeño más alto del ya logrado."
    ]
  },
  {
    key: "relationships",
    label: "Estrategias enfocadas en el fortalecimiento de las relaciones",
    items: [
      "Compartir momentos de entretenimiento juntos.",
      "Fortalecer la comunicación auténtica, sincera y abierta."
    ]
  }
];
