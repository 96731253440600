export default {
  1.1: [
    "Carácter fuerte, obstinado y terco. Se irrita fácilmente.",
    "Fuerza el compromiso de los demás.",
    "Exige un ritmo excesivamente rápido a los demás lo que puede ser fuente de tensión.",
    "Demasiado flexible con el uso de las normas y protocolos.",
    "Beneficia la obtención de resultados más que la armonía en las relaciones.",
    "Actitud impulsiva a la hora de comenzar algo.",
    "Individualista.",
    "Actitud controladora y dominante.",
    "Exceso de ambición y competitividad.",
    "Inspira miedo.",
    "Impaciente y poco humilde.",
    "Poca sensibilidad hacia las necesidades de los demás.",
    "Se aburre fácilmente con la rutina.",
    "Actitud agresiva e individualista para abordar los problemas, sin tomar en cuenta los inconvenientes de la opción escogida.",
    "Carácter agresivo, autoritario y egoísta. Presiona a las personas más de lo normal.",
    "Mucha delegación con pocas instrucciones.",
    "No confía en los demás."
  ],
  1.2: [
    "Suele tener carácter fuerte, obstinado y terco. Tiende a irritarse fácilmente.",
    "Tiende a forzar el compromiso de los demás.",
    "Suele exigir un ritmo rápido, lo que puede ser fuente de tensión.",
    "Tiende a ser flexible con el uso de las normas y protocolos.",
    "favorece la obtención de resultados más que la armonía en las relaciones.",
    "Actitud con tendencia a la impulsividad a la hora de comenzar algo.",
    "Actitud controladora y dominante, con frecuencia.",
    "Suele ser de carácter agresivo, autoritario y egoísta. En general, Inspira miedo.",
    "Impaciente y poco humilde, con frecuencia.",
    "Tiende a tener poca sensibilidad hacia las necesidades de los demás.",
    "Actitud ambiciosa y competitiva.",
    "Se aburre con la rutina.",
    "Aborda los problemas sin tomar en cuenta los inconvenientes de la opción escogida.",
    "Delega con pocas instrucciones.",
    "Tiende a no confiar en los demás."
  ],
  2.1: [
    "Manejo impreciso del tiempo.",
    "Poco foco en los objetivos.",
    "Invierte mucho tiempo productivo en relacionarse con los demás.",
    "Mucha preocupación por los sentimientos de los demás.",
    "No le gusta la soledad, dependiente de la compañía de los otros.",
    "Actitud desorganizada y soñadora.",
    "Persuade a las personas por lo que pueden aportarle.",
    "Actitud relajada en situaciones sociales, que pueden ser arriesgadas e inciertas.",
    "Informal, incluso en situaciones serias.",
    "Poco realista al evaluar a las personas, por la tendencia a confiar sin discriminar.",
    "Asume más compromisos de los que puede cumplir.",
    "Excesivo deseo de complacer a todos.",
    "Tendencia a la superficialidad.",
    "Bajo presión habla de más, confía en exceso, es muy emocional y se autopromueve.",
    "Tiene dificultades para dirigir.",
    "Le cuesta corregir y aplicar disciplina, porque no quiere afectar su aceptación social.",
    "Demuestra el estrés de forma abierta y muy emotiva.",
    "Muy optimista y poco realista bajo presión.",
    "Mucho miedo al rechazo social.",
    "Poco detallista."
  ],
  2.2: [
    "Suele ser una persona imprecisa con los tiempos.",
    "Quizá invierta tiempo productivo en relacionarse con los demás, sin darse cuenta.",
    "Tiende a no gustarle la soledad.",
    "Suele ser informal incluso en situaciones serias.",
    "Poco realista al evaluar a las personas por la tendencia a confiar sin discriminar.",
    "Tiende a asumir más compromisos de los que puede cumplir.",
    "Tiende a querer complacer a todos.",
    "Bajo presión tiende a hablar de más, ser muy optimista, emocional y se autopromueve.",
    "Dificultad para administrar disciplina, porque no quiere afectar su aceptación social.",
    "Suele demostrar el estrés de forma abierta y emotiva.",
    "Proclive al desorden y a la desorganización.",
    "Cuando está bajo presión puede que sea en exceso optimista y poco realista.",
    "Tiende a ser poco detallista.",
    "Suele sentir miedo al rechazo social."
  ],
  3.1: [
    "Dificultad para establecer prioridades.",
    "Sin sentido de urgencia.",
    "Se paraliza cuando enfrenta situaciones sin precedentes.",
    "Inseguridades personales.",
    "Actitud despreocupada por los demás, cuando la situación es compleja.",
    "Mantiene la compostura en exceso, le falta espontaneidad.",
    "No demuestra las emociones, en situaciones de estrés.",
    "Evita los conflictos. Los problemas con las personas le incomodan mucho.",
    "Sede por miedo a la confrontación.",
    "Actitud reservada, indecisa e inflexible, en situaciones de estrés.",
    "Miedo al cambio y a lo desconocido.",
    "Teme no ser aceptado y apreciado por los demás.",
    "Le falta iniciativa.",
    "Actitud dogmática.",
    "Dificultad para lidiar con situaciones diversas en el día a día.",
    "Se centra mucho en la rutina."
  ],
  3.2: [
    "Tiende a no saber establecer prioridades.",
    "Suele no tener sentido de urgencia.",
    "Probablemente tenga dificultad para enfrentar situaciones sin precedentes.",
    "Suele experimentar inseguridad interna.",
    "Tiende a no demostrar las emociones.",
    "Suele evitar los conflictos. Los problemas con las personas le incomodan.",
    "En ocasiones sede por miedo a la confrontación.",
    "Actitud reservada, indecisa, inflexible y despreocupada en situaciones de estrés.",
    "Suele ser resistente frente a los cambios bruscos.",
    "La diversidad en el día a día puede generarle tensión."
  ],
  4.1: [
    "Experimenta presión cuando hay que tomar decisiones rápidas.",
    "Se paraliza al sentir que no ha analizado suficiente información.",
    "Pesimista. Difícil de complacer.",
    "Apego a los manuales. Se excede en el uso de las normas.",
    "Actitud muy crítica y exigente en lo personal y con los demás.",
    "Poco entusiasta y muy detallista.",
    "Sensible a los errores cuando se requiere exactitud y precisión.",
    "Actitud defensiva frente a las críticas.",
    "Desmenuza todas las opciones en exceso al punto de volverse ineficiente.",
    "Actitud obsesiva, meticulosa y perfeccionista.",
    "Actitud distante y fría.",
    "Se autocontrola emocionalmente, pero cuando se molesta demuestra el malhumor.",
    "Prefiere adaptarse antes que arriesgarse."
  ],
  4.2: [
    "Puede experimentar presión cuando hay que tomar decisiones rápidas.",
    "Tiende a paralizarse al sentir que no ha analizado suficiente información.",
    "Suele ser pesimista y difícil de complacer.",
    "No le gustan las confrontaciones.",
    "Tiende a apegarse a los manuales y a las normas.",
    "Actitud crítica y exigente en lo personal y con los demás.",
    "Tiende a mostrar poco entusiasmo.",
    "Sensible a los errores cuando se requiere exactitud y precisión.",
    "Actitud defensiva frente a las críticas.",
    "Desmenuza las opciones al punto de volverse ineficiente.",
    "Actitud obsesiva, meticulosa y perfeccionista.",
    "Actitud distante y fría.",
    "Se autocontrola emocionalmente, pero cuando se molesta demuestra el malhumor.",
    "Prefiere adaptarse antes que arriesgarse."
  ],
  5: [
    "Bajo presión tiende a ser contundente e incluso una persona agresiva.",
    "Se irrita cuando no se cumplen los plazos.",
    "Asume muchas responsabilidades a la vez.",
    "Miedo a equivocarse y a perder.",
    "Puede tornarse una persona incisiva y argumentativa.",
    "La supervisión estrecha, los detalles y los controles, le generan tensión.",
    "La rutina le aburre.",
    "Actitud descuidada con las normas y los procedimientos.",
    "Bajo presión tiende a ser impaciente, insensible y demandante.",
    "Hace poco seguimiento.",
    "Expectativas elevadas sobre la rapidez de los resultados."
  ],
  6: [
    "Impaciente.",
    "Descuida los detalles.",
    "Se aburre con facilidad.",
    "No le gusta la rutina.",
    "Difícil de dirigir porque tiende a querer estar al mando y seguir sus propias directrices.",
    "Dificultad para seguir normas y procedimientos.",
    "No le agradan los controles ni la estrecha supervisión.",
    "Tendencia a la impulsividad.",
    "Actitud poco analítica y reflexiva.",
    "Actitud terca y argumentativa.",
    "Tendencia a ser competitivo.",
    "Altos niveles de exigencia personal y hacia los demás que puede generar estrés.",
    "Tendencia a ser una persona ambiciosa."
  ],
  7: [
    "Impaciente.",
    "Bajo presión se puede tornar exigente y demandante.",
    "Descuida los detalles.",
    "Demasiado entusiasta.",
    "No le gusta la rutina.",
    "Teme al fracaso y a cometer errores.",
    "Impulsividad y emocionalidad en la toma de decisiones.",
    "El control le incomoda, prefiere estar a sus anchas.",
    "Dificultad para seguir normas y procedimientos.",
    "Actitud poco reflexiva y analítica.",
    "Ofrece plazos irrealistas. Mala gestión del tiempo. Incumplimiento de plazos."
  ],
  8: [
    "Autosuficiente.",
    "Se enfoca en un único aspecto y no detecta otras oportunidades.",
    "Hace énfasis solo en sus estándares personales.",
    "Cuando los demás no le siguen el ritmo asume una actitud individualista.",
    "Bajo presión, se enfoca en los resultados y no en las necesidades de las personas.",
    "Pierde la diplomacia y es de actitud testaruda, callada e inexpresiva, bajo tensión.",
    "Dificultad para alinear sus priorizar con la de los demás.",
    "Demuestra el estrés abiertamente, aunque trata de reflexionar.",
    "Tiende a no analizar mucho la información y los hechos disponibles.",
    "Teme tener que lidiar con demasiadas personas.",
    "Dificultad para delegar, prefiriendo encargarse personalmente de la tarea."
  ],
  9: [
    "Demuestra el estrés abiertamente, aunque trata de reflexionar.",
    "Dificultad para delegar, prefiriendo encargarse personalmente de la tarea.",
    "Es situaciones tensas le cuesta mantener la calma y la serenidad.",
    "Cuando los demás no le siguen el ritmo asume una actitud independiente e individualista.",
    "Dificultad para priorizar.",
    "Actitud impulsiva, indiferente y egoísta, bajo presión.",
    "En situaciones tensas pierde el tacto y la diplomacia.",
    "Tiende a no analizar mucho la información y los hechos disponibles."
  ],
  10: [
    "Dificultad para priorizar.",
    "Actitud poco activa incluso cuando está en peligro.",
    "Poca creatividad e innovación en la solución de problemas.",
    "Dificultad para delegar en situaciones demandantes.",
    "Tiende a no analizar mucho la información y los hechos disponibles.",
    "Actitud indiferente, despreocupada o resignada.",
    "Se estresa cuando no consigue los resultados deseados.",
    "Se excede con la franqueza.",
    "Actitud obstinada, incesante e inflexible cuando está bajo estrés.",
    "Se resiste a salir de la zona de confort."
  ],
  11: [
    "Puede dudar en actuar, bajo una fuerte presión.",
    "Vacilante, temperamental, ambivalente.",
    "Prefiere pensar más que sentir. Mundo emocional muy limitado.",
    "Actitud fría y distante.",
    "Poco equilibrio entre la cantidad y la calidad en las decisiones.",
    "Actitud muy crítica con las personas que no cumplen sus estándares.",
    "No reconoce ni aprecia, el valor de las personas.",
    "Actitud autoritaria y exigente, bajo presión.",
    "Miedo a no ejercer liderazgo e influencia en el entorno.",
    "Estilo desafiante y brusco para relacionarse.",
    "Impaciente para lograr resultados."
  ],
  12: [
    "Impulso por obtener resultados rápidamente contrapuesto con el deseo de controlar.",
    "Tendencia a parecer emocionalmente una persona fría y seca.",
    "Prefiere pensar más que sentir. Mundo emocional muy limitado.",
    "Temperamental.",
    "Puede dudar en actuar, bajo una fuerte presión.",
    "Estilo desafiante y brusco para relacionarse.",
    "Actitud agresiva, terca y difícil de complacer, bajo presión.",
    "En situaciones de calma es impaciente para lograr resultados y vacilante bajo presión.",
    "Ritmo ambivalente para actuar y decidir.",
    "Sentido de urgencia y también se detiene a analizar mucho, causando demoras.",
    "Sensación de que todo es prioritario por lo que puede vacilar a la hora de actuar."
  ],
  13: [
    "Tendencia a ser una persona fría y distante.",
    "Pierde la calma fácilmente.",
    "Prefiere pensar más que sentir. Mundo emocional muy limitado.",
    "Poco sensible a los sentimientos de los demás.",
    "Puede dudar en actuar por la tendencia al perfeccionismo.",
    "Prefiere analizar y solucionar problemas, más que estar relacionándose.",
    "Actitud terca y difícil de complacer, bajo presión.",
    "Teme a la desorganización por lo que se excede en usar hechos, números y datos.",
    "Sensación de que todo es prioritario por lo que puede vacilar a la hora de actuar.",
    "Ritmo ambivalente para actuar y decidir.",
    "Sentido de urgencia y también se detiene a analizar mucho, causando demoras."
  ],
  14: [
    "Muestra resistencia a los controles estrictos.",
    "Dificultad para dirigir, corregir y administrar disciplina, cuando es necesario.",
    "Alto nivel de tolerancia con personas que perjudican la productividad.",
    "Miedo al rechazo social y a perder la confianza de su grupo cercano.",
    "Las necesidades de los demás pueden distraerle de la productividad.",
    "Administra mal el tiempo y no cumple con los plazos.",
    "Asume más compromisos de los que puede cumplir.",
    "Alta consideración a todos los involucrados, lo que retrasa la toma de decisiones.",
    "Actitud rencorosa e inquieta, bajo presión.",
    "Descuida los detalles en situaciones complejas.",
    "No dedica mucho tiempo al análisis de hechos e indicadores.",
    "Confía en las personas, sin discriminar.",
    "Informalidad.",
    "Le falta iniciativa para asumir la tarea.",
    "Falta de asertividad y decisión en ciertas ocasiones.",
    "Evita la confrontación y los conflictos."
  ],
  15: [
    "Administra mal el tiempo y no cumple con los plazos.",
    "Le cuesta dirigir, corregir y administrar disciplina, cuando es necesario.",
    "Las necesidades de los demás pueden distraerle de la productividad.",
    "Alto nivel de tolerancia con personas que perjudican la productividad.",
    "Miedo al rechazo social y a perder la confianza de su grupo cercano.",
    "Retraso en la toma de decisiones por la tendencia de considerar a todas las personas.",
    "No le gusta molestar a nadie.",
    "Descuida los detalles, cuando está bajo presión.",
    "No dedica mucho tiempo al análisis de hechos e indicadores.",
    "Confía en las personas, sin discriminar.",
    "Informalidad."
  ],
  16: [
    "Dependiente del apoyo y de la aceptación de las personas.",
    "Le falta asertividad y decisión.",
    "Huye cuando siente estrés.",
    "Evita el conflicto y los desacuerdos.",
    "Apegado a lo establecido y a las tradiciones.",
    "Poca firmeza y autoconfianza.",
    "Confía en las personas de forma optimista y poco racional.",
    "Retraso en la toma de decisiones por la tendencia de no querer molestar a las personas.",
    "Puede invertir tiempo productivo en conversaciones superficiales.",
    "Administra mal el tiempo y no cumple con los plazos.",
    "Le cuesta dirigir, corregir y administrar disciplina, cuando es necesario.",
    "Las necesidades de los demás pueden distraerle de la productividad.",
    "Alto nivel de tolerancia con personas que perjudican la productividad.",
    "Actitud despreocupada e indecisa, cuando está bajo presión.",
    "No dedica mucho tiempo al análisis de hechos e indicadores.",
    "Falta de concentración en la tarea."
  ],
  17: [
    "Muy sensible a las críticas.",
    "Necesidad de reconocimiento social.",
    "Quizá asume más compromisos de los que puede cumplir.",
    "Actitud poco asertiva, en ocasiones.",
    "A veces analiza mucho antes de decidir.",
    "Actitud irónica en momentos de tensión.",
    "Miedo a perder la individualidad.",
    "Dificultad en el manejo del tiempo.",
    "Rigidez en momentos de tensión y hostilidad."
  ],
  18: [
    "Asume más compromisos de los que puede cumplir.",
    "Dificultad en el manejo del tiempo.",
    "Dificultad para asumir los errores y los fracasos.",
    "Rigidez y cautela en momentos de tensión.",
    "Muy sensible a las críticas.",
    "Necesidad de reconocimiento social.",
    "Tendencia al perfeccionismo.",
    "Ritmo ambivalente para decidir.",
    "En momentos puede tener sentido de urgencia y a ratos se detiene a analizar mucho."
  ],
  19: [
    "Tendencia al perfeccionismo.",
    "Los comentarios de los demás le afectan mucho.",
    "Muy sensible a las críticas.",
    "Actitud aduladora en situaciones de estrés.",
    "Actitud de mucho tacto y diplomacia.",
    "Puede ser muy optimista al establecer los objetivos.",
    "Teme al hecho de tener que sacrificar la calidad por cuidar de las buenas relaciones.",
    "Demasiado pendiente de los detalles.",
    "Resistencia a las ideas diferentes.",
    "Sensible al cambio, tanto en lo laboral como en lo social.",
    "Agresividad latente, que puede manifestarse como pasividad.",
    "Dificultad en el manejo del tiempo.",
    "Dificultad para asumir los errores y los fracasos."
  ],
  20: [
    "Intensidad emocional no exteriorizada, sobre todo cuando no se logran los estándares.",
    "Le falta iniciativa.",
    "Poca asertividad y firmeza en el abordaje de las situaciones.",
    "Expresa poco entusiasmo.",
    "Dificultad para decidir cuando la situación es compleja o frente a cambios bruscos.",
    "Parálisis para accionar por exceso de cuidado por los detalles.",
    "Inflexible con sus posiciones en situaciones de estrés.",
    "Teme no cumplir con las expectativas o no dar respuesta a las necesidades.",
    "Actitud rígida cuando se trata de normas y procedimientos.",
    "Se torna de malhumor cuando se saltan los protocolos.",
    "Resistencia al cambio."
  ],
  21: [
    "Dificultad para decidir cuando la situación es compleja o frente a cambios bruscos.",
    "Parálisis para accionar por exceso de análisis, cautela y cuidado por los detalles.",
    "Intensidad emocional no exteriorizada, sobre todo cuando no se logran los estándares.",
    "No sabe expresar entusiasmo.",
    "Inflexible con sus posiciones.",
    "Actitud rígida, estricta y rigurosa, con las normas y procedimientos.",
    "Dificultad para abrirse a nuevas personas. Tiende a ser dependiente del grupo íntimo.",
    "Se torna de malhumor cuando se saltan los protocolos.",
    "Resistente a los cambios.",
    "Incomodidad con la incertidumbre y los imprevistos."
  ],
  22: [
    "Parálisis para accionar, por exceso de análisis y cautela.",
    "Intensidad emocional no exteriorizada, sobre todo cuando no se logran los estándares.",
    "Confía poco, actitud controladora.",
    "Actitud pesimista, distante y fría. Difícil de complacer.",
    "Actitud rígida, estricta y rigurosa, con las normas y procedimientos.",
    "Dificultad para abrirse a nuevas personas. Tiende a ser dependiente del grupo íntimo.",
    "Se torna de malhumor cuando no se logran los estándares de calidad.",
    "Incomodidad por la incertidumbre y los imprevistos.",
    "Actitud crítica.",
    "Teme a las confrontaciones y al antagonismo.",
    "Sensible a los problemas y errores.",
    "Las equivocaciones le generan tensión, por el miedo a estropear la calidad.",
    "Resistente al cambio.",
    "Defensividad frente a las críticas.",
    "Actitud obstinada e introvertida en momentos de estrés."
  ],
  23: [
    "Actitud impaciente cuando los demás no tienen sentido de urgencia, con los resultados.",
    "Asume las tareas de otros, cuando no responden a buen ritmo.",
    "Se sobrecarga de responsabilidades, por la falta de paciencia.",
    "Presiona a los demás, más de lo necesario, para el logro de los resultados.",
    "Poco apego al cumplimiento de las normas y a analizar opciones detalladamente.",
    "En ocasiones puede ser muy amable y otras veces puede ser muy firme y exigente.",
    "Individualista en la toma de decisiones, cuando considera que se demoran para avanzar."
  ],
  24: [
    "Actitud impaciente cuando los demás no son rápidos, en la obtención de resultados.",
    "Actitud agresiva, en ocasiones.",
    "Posiciones firmes, aún en situaciones de tensión.",
    "Individualista en la toma de decisiones, cuando se demoran para avanzar.",
    "Asume las tareas de otros, cuando no responden a buen ritmo.",
    "Pierde de vista los detalles en situaciones complejas.",
    "Puede plantear metas inalcanzables, debido a su optimismo y pasión por los retos.",
    "Actitud tosca cuando está enfrentando problemas.",
    "Puede perder de vista su verdadero nivel de autoridad y sobrepasarlo.",
    "Poco apego al cumplimiento de las normas.",
    "A veces es muy optimista y entusiasta y otras veces es de actitud negativa y exigente."
  ],
  25: [
    "Pierde la calma y la quietud en momentos de tensión.",
    "Puede estresarse fácilmente.",
    "Actitud impaciente cuando los demás no tienen prisa, en la obtención de resultados.",
    "Poco apego a las normas y reglas.",
    "Individualista cuando siente que los demás no responden a su ritmo.",
    "Asume las tareas de otros, para avanzar.",
    "Dificultad para priorizar.",
    "Egoísta e indiferente bajo presión.",
    "Pierde el tacto y la diplomacia bajo una fuerte presión.",
    "No invierte mucho tiempo en analizar información y datos."
  ],
  26: [
    "Pierde la calma en momentos de tensión.",
    "Individualista e independiente, cuando siente que los demás no responden a su ritmo.",
    "Egoísta e indiferente bajo presión.",
    "Puede estresarse fácilmente.",
    "Actitud impaciente e impulsiva, cuando los demás no tienen sentido de urgencia.",
    "Dificultad para priorizar.",
    "Pierde el tacto y la diplomacia bajo una fuerte presión.",
    "Decide sin tomar en cuenta a los demás, cuando esto favorece los resultados.",
    "Poco apego a las normas y reglas.",
    "No invierte mucho tiempo en analizar información y datos.",
    "Asume las tareas de otros, para avanzar."
  ],
  27: [
    "Actitud competitiva.",
    "Pierde interés y se aburre con la rutina.",
    "Actitud firme y brusca, en situaciones tensas.",
    "Teme no alcanzar lo que se propone.",
    "Muy independiente aun sin necesidad.",
    "Actitud impaciente, desestructurada y autoritaria, en situaciones de estrés.",
    "Delega y planifica poco.",
    "Se excede en ocasiones usando los métodos probados.",
    "Toma decisiones de forma individual.",
    "No controla los detalles.",
    "Puede llegar a ser intimidante debido al dinamismo, energía e ímpetu.",
    "Impaciente con quienes no comparten su sentido de urgencia.",
    "Pierde la calma y se torna de mal humor, cuando se siente bajo presión.",
    "No toma en cuenta las necesidades de las personas, por el foco en los resultados."
  ],
  28: [
    "Actitud crítica frente a los aspectos débiles de las personas y del equipo.",
    "En ocasiones se torna pesimista.",
    "Impaciente con quien no lleva su ritmo.",
    "Asume las tareas de otros para avanzar más rápido.",
    "Actitud agresiva, malhumorada y dominante cuando la situación es tensa.",
    "Pierde de vista los detalles por concentrarse en la visión global del proyecto.",
    "En ocasiones no es sensible a las necesidades de los demás.",
    "Individualista al tomar decisiones, cuando los demás no avanzan a su mismo ritmo.",
    "En ocasiones sobrepasa su nivel real de autoridad."
  ],
  29: [
    "Actitud agresiva, malhumorada y dominante cuando la situación es tensa.",
    "Impaciente con quien no mantiene el ritmo.",
    "Actitud crítica frente a los aspectos débiles de las personas y del equipo.",
    "En ocasiones se torna pesimista.",
    "Dificultad para establecer prioridades, siente que todo es importante.",
    "Ritmo variante al actuar.",
    "Sentido de urgencia contrapuesto a la necesidad de analizar minuciosamente."
  ],
  30: [
    "Dificultad para establecer prioridades.",
    "Ritmo ambivalente, rápido y analítico al mismo tiempo.",
    "Actitud distante, fría y desafiante, con poca atención a las emociones, bajo estrés.",
    "Actitud agresiva, malhumorada y dominante en situaciones complejas.",
    "Mundo emocional limitado por la tendencia a actuar y pensar, más que sentir.",
    "Actitud terca o impositiva, cuando pierde la paciencia."
  ],
  31: [
    "Dificultad para priorizar.",
    "Actitud impulsiva, expresiva y firme en situaciones de tensión.",
    "Cuando no llevan su ritmo, prefiere hacer el trabajo, en vez de delegarlo.",
    "Autosuficiente e individualista cuando los demás no se alinean al sentido de urgencia.",
    "Se estresa fácilmente.",
    "Actitud ambivalente y pesimista cuando está bajo estrés.",
    "Pierde el tacto y la diplomacia bajo una fuerte presión.",
    "Teme que el tiempo no le alcance para hacer un trabajo de calidad.",
    "En situaciones tensas prioriza los resultados sobre las necesidades de las personas.",
    "Tiende a dudar en situaciones adversas.",
    "Poca capacidad de relación interpersonal."
  ],
  32: [
    "Autosuficiente e individualista cuando los demás no se alinean al sentido de urgencia.",
    "Dificultad para priorizar.",
    "Si la situación lo demanda actuará con sentido de urgencia de manera independiente.",
    "Actitud impulsiva y firme en ciertas circunstancias.",
    "Colabora con lo demás para avanzar más rápido y controlar la calidad.",
    "Impaciente bajo estrés.",
    "Si no llevan su ritmo o no cumplen con la calidad, prefiere no delegar el trabajo.",
    "Se estresa fácilmente.",
    "Prioriza los resultados y la calidad sobre las necesidades de las personas en la adversidad.",
    "Tiende a dudar en situaciones adversas.",
    "Puede ser muy detallista, perfeccionista y de actitud rígida con los protocolos.",
    "Ambivalente y pesimista en situaciones de estrés."
  ],
  33: [
    "Bajo presión es egoísta e indiferente.",
    "Pierde la calma, el tacto y la diplomacia, bajo estrés.",
    "Colabora con los demás para avanzar rápido, cuando hay tensión.",
    "Autosuficiente e individualista cuando los demás no se alinean al sentido de urgencia.",
    "Cuando no llevan su ritmo o no cumplen con las expectativas, prefiere no delegar.",
    "Dificultad para priorizar.",
    "Poca apertura a conocer gente nueva.",
    "Tiende a dudar en situaciones adversas y volverse pesimista.",
    "Actitud impulsiva y firme en ciertas circunstancias.",
    "Si la situación lo demanda actuará con sentido de urgencia de manera independiente."
  ],
  34: [
    "Bajo presión es egoísta y con tendencia a preocuparse.",
    "Actitud firme y agresiva, frente a las oposiciones.",
    "Tiende a ser impaciente, perder la calma y la diplomacia, bajo presión.",
    "Colabora con los demás para avanzar rápido y controlar la calidad, cuando hay tensión.",
    "Dificultad para priorizar.",
    "Cuando no llevan su ritmo o no cumplen con las expectativas, prefiere no delegar.",
    "Autosuficiente e individualista cuando los demás no se alinean al sentido de urgencia.",
    "Tiende a dudar en situaciones adversas y volverse pesimista y exigente.",
    "Poca apertura a conocer gente nueva."
  ],
  35: [
    "Tolera una cuota limitada de rutina, antes de aburrirse.",
    "Actitud testaruda bajo presión.",
    "Exceso de ambición.",
    "Impaciente, quiere terminar todo rápido.",
    "Descuida los detalles.",
    "Poca organización.",
    "Miedo a que no le acepten dentro del equipo.",
    "Toma acciones de forma impulsiva.",
    "Actitud poco analítica para tomar decisiones.",
    "Actitud superficial en situaciones de estrés.",
    "Asume muchos compromisos a la vez.",
    "Muy optimista en relación con la obtención de los resultados.",
    "Delega mucho con poco seguimiento."
  ],
  36: [
    "Descuida los detalles.",
    "Poca organización.",
    "Actitud superficial en situaciones de estrés.",
    "Miedo a que no le acepten dentro del equipo.",
    "Delega mucho con poco seguimiento.",
    "Actitud poco reflexiva y analítica para tomar decisiones.",
    "Estimula la participación de todos y esto puede ralentizar el ritmo.",
    "Expresa la molestia abiertamente en ambientes de confianza.",
    "Manejo inadecuado del tiempo y los plazos de entrega.",
    "Dificultad para impartir disciplina.",
    "Tolerante de quienes no dan la talla en el rendimiento.",
    "Toma las críticas a modo personal.",
    "Presta más atención a los sentimientos que al cumplimiento de los estándares de calidad.",
    "Prioriza las necesidades de las personas sobre los procesos."
  ],
  37: [
    "Tolera una cuota limitada de rutina, antes de aburrirse.",
    "Actitud testaruda bajo presión.",
    "Exceso de ambición.",
    "Impaciente, quiere terminar todo rápido.",
    "Descuida los detalles.",
    "Poca organización.",
    "Toma acciones de forma impulsiva.",
    "Actitud poco analítica para tomar decisiones.",
    "Asume muchos compromisos a la vez.",
    "Delega mucho con poco seguimiento.",
    "Poco cuidado con el cumplimiento de los estándares de calidad.",
    "No le agradan los controles ni la estrecha supervisión.",
    "Actitud firme, determinante y agresiva, en situaciones de tensión.",
    "Sigue sus propias directrices, al emprender una tarea."
  ],
  38: [
    "Actitud informal, superficial, relajada e indiferente, en situaciones de tensión.",
    "Presta más atención a los sentimientos y resultados, que a los estándares de calidad.",
    "Actitud poco analítica, para tomar decisiones.",
    "Puede distraerse cuando toma decisiones.",
    "Presta mucha atención a las relaciones interpersonales.",
    "Le cuesta lidiar con la soledad.",
    "Estimula la participación de todos y esto puede ralentizar el ritmo."
  ],
  39: [
    "Tiende a ser menos firme y más condescendiente, cuando está bajo estrés.",
    "Sobrevalora su capacidad de influencia.",
    "Actitud impulsiva en ciertas ocasiones.",
    "Su actitud puede confundir a las personas y llevarlos a abusar de su confianza.",
    "Cierto nivel de desorden.",
    "Puede sobrestimar las habilidades de los demás.",
    "Impaciente y poco detallista bajo presión."
  ],
  40: [
    "Bajo tensión se traba con los detalles, análisis exhaustivos y deseos de perfección.",
    "Tiende a ser menos firme y más condescendiente, cuando está bajo estrés.",
    "Dificultad para encarar la confrontación y el conflicto con las personas.",
    "En situaciones de tensión puede perder el tacto y la diplomacia.",
    "Vulnerable frente a las críticas.",
    "Puede sobrestimar las habilidades de los demás."
  ],
  41: [
    "Sensación de autoimportancia.",
    "Tendencia a la impulsividad y a la impaciencia.",
    "Corre el riesgo de sobrepasar sus límites de autoridad.",
    "Sentido de urgencia e intransigencia, en situaciones de estrés.",
    "Se aburre con facilidad."
  ],
  42: [
    "Ofrece mucha información cuando promueve ideas o productos.",
    "Busca activamente la aprobación y aceptación de los demás.",
    "Actitud irónica en situaciones de estrés.",
    "Miedo a perder la independencia e individualidad.",
    "Tiende a ser menos firme y más condescendiente, cuando está bajo estrés.",
    "Promete más de lo que puede asumir, generándole tensión.",
    "Asume las críticas de forma personal.",
    "En ocasiones confía demasiado en las habilidades de las personas."
  ],
  43: [
    "Puede excederse en las expectativas de las habilidades propias y de la de los demás.",
    "Demanda explicaciones detalladas en procesos de cambio.",
    "Busca la aceptación de los demás y el reconocimiento de sus logros.",
    "Persiste en aplicar protocolos conocidos.",
    "Dificultad para tomar decisiones en situaciones inestables o complejas.",
    "Se descuida, en situaciones de tensión.",
    "Detallista y perfeccionista en la rutina.",
    "Toma las críticas de forma personal.",
    "Actitud impulsiva, en ciertas ocasiones.",
    "Proyecta la imagen de estar muy disponible.",
    "Actitud evitativa, para mantenerse al margen del compromiso."
  ],
  44: [
    "Proyecta la imagen de estar muy disponible.",
    "Busca la aceptación de los demás y el reconocimiento de sus logros.",
    "Actitud evitativa, para mantenerse al margen del compromiso.",
    "Puede excederse en las expectativas de las habilidades propias y de la de los demás.",
    "Toma las críticas de forma personal.",
    "Actitud impulsiva, en ciertas ocasiones.",
    "Actitud detallista y perfeccionista, en ocasiones de forma excesiva.",
    "Demanda explicaciones detalladas en procesos de cambio.",
    "Persiste en aplicar protocolos conocidos.",
    "Dificultad para tomar decisiones en situaciones inestables o complejas."
  ],
  45: [
    "Maneja el tiempo con imprecisión. Dificultad a la hora de cumplir con los plazos.",
    "De ritmo pausado, pero cuando siente presión actúa con sentido de urgencia.",
    "Busca la aceptación de los demás y el reconocimiento de sus logros.",
    "Proyecta la imagen de estar muy disponible.",
    "Actitud evitativa, para mantenerse al margen del compromiso.",
    "Obedece para no generar conflictos.",
    "Se distrae de la tarea, ante los requerimientos personales de los demás.",
    "Dificultad para tomar decisiones en situaciones tensas e inestables.",
    "Actitud detallista y perfeccionista, en la rutina.",
    "Actitud descuidada y con tendencia a hablar mucho, en situaciones de tensión.",
    "Puede excederse en las expectativas de las habilidades propias y de la de los demás.",
    "Toma las críticas de forma personal.",
    "Actitud impulsiva, en ciertas ocasiones.",
    "Demanda explicaciones detalladas en procesos de cambio.",
    "Retrasa la toma de decisiones por considerar la participación de todas las personas.",
    "Dificultad para aplicar disciplina por no querer generar conflictos.",
    "Persiste en aplicar protocolos conocidos."
  ],
  46: [
    "Falta de asertividad en algunas ocasiones.",
    "Se excede en el uso de sus estándares y posiciones personales.",
    "Dedica mucho tiempo a analizar información y hechos, antes de actuar.",
    "Actitud posesiva y muy sensible en situaciones de estrés.",
    "Gestiona mal el tiempo.",
    "Le falta firmeza en la toma de decisiones.",
    "Preocupación por no formar parte del equipo.",
    "Tiende a acomodarse mucho a los demás y ser condescendiente."
  ],
  47: [
    "Actitud intensa e impaciente cuando está bajo presión.",
    "Resistente a los cambios repentinos e importantes.",
    "Miedo a perder o a no lograr el apoyo de las personas.",
    "Descuida el análisis de la información en la toma de decisiones.",
    "Ritmo pausado para solucionar los problemas, en situaciones complejas o inestables.",
    "Evade el cambio, encargándose de asuntos rutinarios o conocidos.",
    "No se detiene a pensar en los pros y en los contras para tomar decisiones.",
    "Dificultad para priorizar sus tareas cotidianas.",
    "Juzga a las personas por el nivel de lealtad que muestran.",
    "Actitud indecisa y vacilante, en ocasiones."
  ],
  48: [
    "Descuida el análisis de la información en la toma de decisiones.",
    "Actitud evitativa frente a los conflictos y las confrontaciones.",
    "Deseos de agradar a los demás.",
    "Descuida los detalles en la rutina.",
    "Miedo a perder o a no lograr el apoyo de las personas.",
    "Actitud intensa e impaciente cuando está bajo presión.",
    "Ritmo pausado para solucionar los problemas, en situaciones complejas o inestables.",
    "No se detiene a pensar en los pros y en los contras para tomar decisiones.",
    "Dificultad para priorizar sus tareas cotidianas.",
    "Actitud indecisa y vacilante, en ocasiones.",
    "Resistente a los cambios repentinos e importantes."
  ],
  49: [
    "Descuida el análisis de la información en la toma de decisiones.",
    "Mal manejo del tiempo. Dificultades para cumplir los plazos.",
    "Actitud evitativa frente a los conflictos y las confrontaciones.",
    "Deseos de agradar a los demás.",
    "Miedo a perder o a no lograr el apoyo de las personas.",
    "Descuida la productividad y las decisiones, por atender las necesidades de las personas.",
    "Mucha tolerancia con las personas.",
    "Descuida los detalles en la rutina.",
    "Actitud intensa e impaciente cuando está bajo presión.",
    "Ritmo pausado para solucionar los problemas, en situaciones complejas o inestables.",
    "No se detiene a pensar en los pros y en los contras para tomar decisiones.",
    "Dificultad para priorizar sus tareas cotidianas.",
    "Actitud indecisa y vacilante, en ocasiones.",
    "Resistente a los cambios repentinos e importantes."
  ],
  50: [
    "Se atasca en los procesos complejos y con la gente.",
    "No sabe administrar los plazos.",
    "Toma las críticas de forma personal.",
    "Descuida el análisis de la información en la toma de decisiones.",
    "Dificultades para administrar disciplina.",
    "Deseos de agradar a los demás.",
    "Descuida la productividad y las decisiones, por atender las necesidades de las personas.",
    "Le gusta ir a su ritmo y sentirse libre de estrés.",
    "Demuestra la molestia con su grupo íntimo y más familiar.",
    "Obedece para no generar conflictos, si lo considera necesario.",
    "Considera a los demás y evita molestar a la gente.",
    "Dificultad para priorizar sus tareas cotidianas.",
    "Actitud indecisa y vacilante, en ocasiones.",
    "Resistente a los cambios repentinos e importantes.",
    "Ritmo pausado para solucionar los problemas, en situaciones complejas o inestables.",
    "No se detiene a pensar en los pros y en los contras para tomar decisiones.",
    "Actitud evitativa frente a los conflictos y las confrontaciones."
  ],
  51: [
    "Necesita explicaciones detalladas antes de asumir los cambios.",
    "Se resiste al cambio.",
    "Considera a los demás y evita molestar a la gente.",
    "Obedece para no generar conflictos, si lo considera necesario.",
    "Muy confiado con respecto a la capacidad de las demás personas.",
    "Siente presión cuando hay que tomar decisiones rápidas.",
    "No decide, hasta sentir la seguridad de que la decisión es la correcta.",
    "Actitud conservadora a la hora de enfrentar los problemas.",
    "Actitud evitativa frente a los conflictos y las confrontaciones.",
    "Carácter terco y actitud cerrada, cuando se estresa.",
    "Maneja el tiempo de forma imprecisa.",
    "Dificultad para administrar disciplina por su deseo de agradar a los demás.",
    "Descuida la productividad y las decisiones, por atender las necesidades de las personas.",
    "Tiende a ser muy tolerante y esto puede perjudicar la productividad."
  ],
  52: [
    "Malhumor cuando está bajo presión.",
    "Actitud evitativa frente a los conflictos y las confrontaciones.",
    "Actitud reservada, indecisa y se traba con los detalles, bajo presión",
    "Dificultad para establecer prioridades.",
    "Se resiste al cambio, sobre todo a los cambios abruptos.",
    "Toma las críticas de forma personal.",
    "Sensible a las críticas."
  ],
  53: [
    "Atiende un rol y tarea por vez.",
    "Se aturde cuando hay demanda de diversos resultados al mismo tiempo.",
    "Ritmo pausado, con poco sentido de urgencia.",
    "Actitud sistemática, analítica y metódica que ralentiza el logro de los resultados.",
    "Actitud evitativa frente a los conflictos y las confrontaciones.",
    "Teme hacer el ridículo.",
    "Tiende a ser de actitud rencorosa en situaciones de estrés.",
    "Sensible a las críticas.",
    "Asume una actitud defensiva cuando le critican.",
    "Actitud en exceso modesta y conservadora.",
    "Actitud rígida y malhumorada cuando está bajo presión.",
    "Dificultad para priorizar por exceso de atención al detalle.",
    "Se abruma frente a los cambios repentinos.",
    "Exigente y rígido con el control de la calidad.",
    "Actitud reservada, indecisa y se traba con los detalles, bajo presión."
  ],
  54: [
    "Ritmo pausado, con poco sentido de urgencia.",
    "Se abruma frente a los cambios repentinos.",
    "Actitud sistemática, analítica y metódica que ralentiza el logro de los resultados.",
    "Tiende a ser de actitud rencorosa en situaciones de estrés.",
    "Teme hacer el ridículo.",
    "Actitud en exceso modesta y conservadora.",
    "Atiende un rol y tarea por vez.",
    "Se aturde cuando hay demanda de diversos resultados al mismo tiempo.",
    "Dificultad para priorizar por exceso de atención al detalle.",
    "Actitud rígida y malhumorada cuando está bajo presión.",
    "Sensible y de actitud defensiva frente a las críticas.",
    "Actitud evitativa frente a los conflictos y las confrontaciones.",
    "Actitud reservada, indecisa y se traba con los detalles, bajo presión."
  ],
  55: [
    "Actitud defensiva frente a las críticas.",
    "Actitud evitativa frente a los conflictos en situaciones tensas.",
    "Se defiende cuando le toca asumir la responsabilidad en las equivocaciones.",
    "Poca receptividad con las ideas de los demás.",
    "Acapara responsabilidades de otros o se recarga de deberes, sin darse cuenta.",
    "Teme no lograr los resultados o no poder cumplir con los compromisos.",
    "En momentos desfavorables, se refugia en lo conocido.",
    "Dificultad para priorizar.",
    "Exceso de franqueza.",
    "Actitud obstinada, incesante e inflexible, cuando está bajo estrés.",
    "Se resiste a salir de la zona de confort."
  ],
  56: [
    "Le falta un poco de autoconfianza.",
    "Ritmo pausado en la toma de decisiones.",
    "Exceso de meticulosidad y cuidado, que bloquea la creatividad.",
    "La evaluación y el análisis ralentiza la toma de decisiones.",
    "Malhumor cuando la situación es tensa.",
    "Actitud poco entusiasta y receptiva con personas diferentes a sus referentes familiares.",
    "Actitud inflexible y agresiva cuando está bajo estrés.",
    "Tendencia a la indecisión, en el día a día.",
    "Poca receptividad con las ideas de los demás.",
    "Teme no lograr los resultados o no poder cumplir con los compromisos."
  ],
  57: [
    "Actitud inflexible, rígida y agresiva cuando está bajo estrés.",
    "Excesivamente exigente con las normas y los procedimientos.",
    "Malhumor cuando la situación es tensa.",
    "Actitud poco entusiasta y receptiva con personas diferentes a sus referentes familiares.",
    "Le falta un poco de autoconfianza en sus habilidades.",
    "Ritmo pausado en la toma de decisiones complejas.",
    "Exceso de meticulosidad y cuidado, que bloquea la creatividad.",
    "La evaluación y el análisis ralentiza la toma de decisiones.",
    "Poca receptividad con las ideas de los demás.",
    "Teme no lograr los resultados o no poder cumplir con los compromisos."
  ],
  58: [
    "Poca autoconfianza en sus habilidades.",
    "Ritmo pausado en la toma de decisiones complejas.",
    "Requiere de tiempo para adaptarse al cambio.",
    "La evaluación y el análisis ralentiza la toma de decisiones.",
    "Exigente con las normas y los procedimientos.",
    "Indecisión en situaciones complejas.",
    "Actitud poco entusiasta y receptiva con personas diferentes a sus referentes familiares.",
    "Actitud inflexible, rígida y agresiva cuando está bajo estrés.",
    "Exceso de meticulosidad y cuidado, que bloquea la creatividad."
  ],
  59: [
    "Analiza demasiado, en situaciones complejas, lo que ocasiona retrasos.",
    "No presta mucha atención al aspecto emocional.",
    "Actitud fría, tosca y retadora, bajo presión.",
    "Perfeccionista y difícil de complacer.",
    "Le falta empatía con las necesidades de las personas.",
    "Pierde la calma con facilidad en situaciones tensas.",
    "Duda, en ocasiones, entre tener un ritmo rápido o ser perfeccionista.",
    "Toma las críticas de manera personal."
  ],
  60: [
    "Se concentra en los detalles y es muy perfeccionista bajo presión.",
    "Se paraliza para tomar decisiones en situaciones complejas.",
    "Pierde el foco de lo importante, en situaciones de estrés.",
    "Es sobre exigente con las personas.",
    "Se apega a la agenda y depende de la planificación.",
    "Asume más tareas de las que puede cumplir.",
    "Toma las críticas de forma personal.",
    "Actitud inquieta."
  ],
  61: [
    "Asume más tareas de las que puede cumplir.",
    "Exigente con los estándares elevados.",
    "Difícil de complacer.",
    "Actitud inquieta.",
    "Teme que no le reconozcan sus logros y que le digan que no a sus propuestas.",
    "Irrealista al evaluar a los demás.",
    "Se paraliza en la toma de decisiones cuando le falta información.",
    "Analiza demasiado, en situaciones complejas, lo que ocasiona retrasos.",
    "No es consciente de los esfuerzos de los demás.",
    "No valora abiertamente los aportes de las personas.",
    "En ocasiones toma decisiones impulsivas y optimistas."
  ],
  62: [
    "Si la calidad está en juego, protesta y reclama.",
    "Irrealista al evaluar a los demás.",
    "Se paraliza en la toma de decisiones cuando le falta información.",
    "Teme que no le reconozcan sus logros y que le digan que no a sus propuestas.",
    "No es consciente de los esfuerzos de los demás.",
    "No valora abiertamente los aportes de las personas.",
    "Actitud inquieta.",
    "Difícil de complacer y perfeccionista, en la adversidad.",
    "Se excede en análisis y detalles en situaciones complejas, lo que ocasiona retrasos.",
    "Exigente con los estándares elevados.",
    "Actitud evitativa frente a los conflictos con los demás, en ambientes desfavorables.",
    "Flexibiliza la planificación cuando hay presión.",
    "Se toma las críticas de forma personal."
  ],
  63: [
    "Asume más compromisos de los que puede cumplir.",
    "Le preocupa tener que renunciar a la calidad en pro del buen ambiente.",
    "Detallista y perfeccionista.",
    "Le exige meticulosidad a los demás.",
    "Actitud aduladora ante el estrés.",
    "Optimista al juzgar a los demás.",
    "Pierde firmeza en sus convicciones en situaciones de estrés.",
    "Actitud rígida con la planificación.",
    "Toma las críticas a modo personal y asume una actitud defensiva.",
    "Le cuesta abrirse a ideas diferentes.",
    "Sensible a los cambios."
  ],
  64: [
    "Le cuesta abrirse a ideas diferentes.",
    "En ciertas ocasiones reacciona con malhumor.",
    "Asume más compromisos de los que puede cumplir.",
    "Actitud crítica de los procesos y de la calidad.",
    "Teme que le digan que no a sus propuestas.",
    "Se siente vulnerable frente a los cambios abruptos.",
    "Optimista al evaluar a las personas.",
    "Actitud ambivalente, a veces pesimista y crítica y otras optimista y confiada.",
    "Perfeccionista en ocasiones, lo que puede ralentizar la toma de decisiones.",
    "Le exige meticulosidad a los demás.",
    "Pierde firmeza en sus convicciones en situaciones de estrés.",
    "Toma las críticas a modo personal y asume una actitud defensiva."
  ],
  65: [
    "En ocasiones establece objetivos irrealistas.",
    "Optimista al evaluar a las personas.",
    "Asume más compromisos de los que puede cumplir.",
    "Perfeccionista y detallista, en ocasiones.",
    "Ritmo pausado debido al análisis de mucha información, en situaciones complejas.",
    "Actitud ambivalente, a veces pesimista y crítica y otras optimista y confiada.",
    "Toma las críticas de forma personal y asume una actitud defensiva.",
    "Le cuesta asumir las derrotas y los errores.",
    "Actitud cautelosa en situaciones hostiles.",
    "Actitud terca, matizada con simpatía, para no incomodar."
  ],
  66: [
    "Siente los cambios repentinos como amenazantes.",
    "Actitud crítica de los procesos y de la calidad.",
    "Experimenta ansiedad frente al rechazo de sus aportes.",
    "Carácter malhumorado y actitud terca, en situaciones tensas.",
    "Proyecta una imagen de estar muy disponible para los demás.",
    "En ocasiones, evita a las personas para poder trabajar en privado.",
    "Perfeccionista y detallista, en ocasiones, lo que ralentiza la toma de decisiones.",
    "Analiza mucha información, en situaciones complejas.",
    "Muy entusiasta con sus propias ideas y proyectos.",
    "Toma las críticas de forma personal y asume una actitud defensiva.",
    "Puede ser muy tradicionalista y apegado al pasado.",
    "Dificultades para priorizar.",
    "Requiere de tiempo y planificación para asumir los cambios.",
    "Actitud cerrada a las ideas diferentes.",
    "Actitud cautelosa en situaciones hostiles."
  ],
  67: [
    "Actitud fría y distante en las relaciones interpersonales.",
    "Presta poca atención a los sentimientos de los demás.",
    "Prefiere que el contacto con la gente sea limitado.",
    "Analiza toda la información disponible y los hechos antes de decidir.",
    "Pierde la calma con facilidad.",
    "Analizar puede chocar con su inclinación a ser impaciente y querer resultados rápidos.",
    "Dificultad para priorizar.",
    "Teme a la desorganización.",
    "Actitud evaluativa de los procesos y de la calidad.",
    "Tiene períodos de acción rápida y otros de ritmo pausado, dedicados a la reflexión."
  ],
  68: [
    "Posiciones firmes e impositivas, en situaciones de estrés.",
    "En ocasiones es vacilante, antes de tomar una decisión.",
    "Exigente con los estándares de calidad. En exceso perfeccionista.",
    "No le gusta que interfieran en su planificación.",
    "Vulnerable frente a la crítica y los altos riesgos.",
    "Actitud reservada y desconfiada con los desconocidos.",
    "Frente a los nuevos retos se distrae analizando mucho, antes de tomar una decisión.",
    "Actitud fría, formal y distante, en las relaciones interpersonales.",
    "Prefiere el contacto con la gente cercana y de confianza."
  ],
  69: [
    "Actitud firme, terca y brusca, bajo presión.",
    "Ritmo ambivalente en la toma de decisiones.",
    "Puede tener períodos de acción rápida y otros de ritmo pausado, para reflexionar.",
    "Actitud fría y distante, en las relaciones interpersonales.",
    "Presta poca atención a los sentimientos de los demás.",
    "Prefiere que el contacto con la gente sea limitado.",
    "Malhumor en situaciones tensas.",
    "Le incomoda el control estrecho.",
    "Perfeccionista y difícil de complacer, en situaciones complejas.",
    "Prueba diversas soluciones, lo que puede retrasar la toma de decisiones.",
    "Dificultad para priorizar.",
    "Vacila, en ocasiones, al momento de decidir.",
    "Actitud evaluativa de los procesos y de la calidad."
  ],
  70: [
    "Exigente con las normas y los procedimientos.",
    "Malhumor cuando la situación es tensa.",
    "Perfeccionista.",
    "Actitud reservada y desconfiada con los desconocidos.",
    "Actitud muy directa, en situaciones de estrés.",
    "Siente vulnerabilidad frente a riesgos altos.",
    "No le gusta perder su privacidad e intimidad.",
    "Demuestra poco entusiasmo.",
    "Se excede al analizar y corregir los errores y desviaciones.",
    "Oculta sus emociones.",
    "No incentiva la colaboración.",
    "Invierte mucho tiempo analizando antes de tomar algunas decisiones.",
    "Proyecta una imagen fría y distante por su tendencia a la introversión.",
    "Actitud inflexible y rígida cuando está bajo estrés.",
    "Ritmo pausado en la toma de decisiones complejas.",
    "Exceso de meticulosidad y cuidado, que bloquea la creatividad.",
    "La evaluación y el análisis ralentiza la toma de decisiones."
  ],
  71: [
    "Pierde de vista detalles importante.",
    "Impaciente y exigente, en ocasiones.",
    "Le atrae más estar en acción y relacionándose, que analizar información.",
    "Puede ser optimista a la hora de hacer promesas.",
    "Cuando los protocolos establecidos no le convencen actúa de forma independiente.",
    "Actitud competitiva en ocasiones.",
    "Flexible frente a las normas.",
    "Puede asumir ciertas situaciones de forma superficial o con indiferencia.",
    "Necesidad de reconocimiento social y de sus logros."
  ],
  72: [
    "Actitud firme y terca, bajo tensión.",
    "Perder una propuesta le genera temor.",
    "Malhumor y agresividad cuando hay estrés.",
    "Ritmo ambivalente, rápido y analítico al mismo tiempo.",
    "En situaciones complejas analiza mucha información.",
    "Algunas veces decide de forma impulsiva y optimista."
  ],
  73: [
    "Actitud firme, terca y brusca, bajo presión.",
    "Actitud malhumorada, en momentos de tensión.",
    "Cuando no llevan su ritmo, prefiere hacer el trabajo, en vez de delegarlo.",
    "Autosuficiente e individualista cuando los demás no se alinean al sentido de urgencia.",
    "Actitud ambivalente y pesimista cuando está bajo estrés.",
    "Tiende a dudar en situaciones adversas.",
    "No toma muy en cuenta el aspecto emocional.",
    "Cuando el clima es desfavorable, tiende a ser perfeccionista y a pausar el ritmo.",
    "En situaciones complejas puede ser difícil de complacer.",
    "Actitud distante y fría.",
    "Analiza múltiples soluciones, lo que ralentiza la toma de decisiones.",
    "Dificultad para priorizar.",
    "Puede vacilar en ocasiones, a la hora de decidir."
  ],
  74: [
    "Actitud malhumorada, en momentos de tensión.",
    "Expresa su desacuerdo cuando incumplen los estándares de calidad o los plazos.",
    "Tiende a confiar mucho en las habilidades de los demás.",
    "Se estresa cuando la carga laboral es mucha.",
    "Analiza mucha información, por lo que su ritmo tiende a ser pausado, para decidir.",
    "Se le dificulta cumplir con las fechas límites.",
    "Detallista y perfeccionista, aunque en ocasiones puede ser de actitud descuidada.",
    "Actitud indecisa, ante los cambios de rutinas y las tensiones.",
    "Incomodidad por la incertidumbre y los imprevistos."
  ]
};
