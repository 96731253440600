<template>
  <v-container fluid class="white py-0 px-0" id="printPage">
    <report-header id="ready_to_know">
      <template v-slot:data>
        <applicant-data :data="applicantData" />
      </template>
      <template v-slot:controls>
        <v-row justify="end" align="center">
          <v-col cols="4" class="d-flex justify-end pr-sm-10 pr-4">
            <v-btn elevation="2" @click="onPrint">Imprimir</v-btn>
          </v-col>
        </v-row>
      </template>
    </report-header>

    <section-intro />

    <div class="container">
      <v-row class="flex-container">
        <v-col cols="6" class="flex-item">
          <leadership-development-table :assessedCounts="assessedCounts" />
        </v-col>
        <v-col cols="6" class="flex-item">
          <leadership-development-chart :assessedCounts="assessedCounts" />
        </v-col>
      </v-row>
    </div>

    <behavior-tables :assessedCounts="assessedCounts" />

    <shared-vision
      v-if="hasApplicants"
      :applicants="applicants"
      :assessedAnswers="assessedAnswers"
      :answerCounts="answerCounts"
    />

    <aspect-table
      v-if="hasApplicants"
      :answerCounts="answerCounts"
      :applicantsCount="applicantsCount"
    />

    <final-suggestions v-if="hasApplicants" />
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import { formatDate } from "@/utils/date";
import ApplicantData from "../common/ApplicantData.vue";
import ReportHeader from "../common/ReportHeader.vue";
import SectionIntro from "./components/SectionIntro.vue";
import LeadershipDevelopmentChart from "./components/LeadershipDevelopmentChart.vue";
import LeadershipDevelopmentTable from "./components/LeadershipDevelopmentTable.vue";
import LeadershipBehaviorTables from "./components/LeadershipBehaviorTables.vue";
import SharedVision from "./components/SharedVision.vue";
import AspectTable from "./components/AspectTable.vue";
import FinalSuggestions from "./components/FinalSuggestions.vue";

export default defineComponent({
  name: "OTOReport",
  components: {
    "applicant-data": ApplicantData,
    "report-header": ReportHeader,
    "section-intro": SectionIntro,
    "leadership-development-chart": LeadershipDevelopmentChart,
    "leadership-development-table": LeadershipDevelopmentTable,
    "behavior-tables": LeadershipBehaviorTables,
    "shared-vision": SharedVision,
    "aspect-table": AspectTable,
    "final-suggestions": FinalSuggestions
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      applicants: []
    };
  },
  computed: {
    assessedAnswers() {
      return this.data && this.data.results
        ? this.data.results.assessedAnswers
        : null;
    },
    assessedCounts() {
      return this.data && this.data.results
        ? this.data.results.assessedCounts
        : null;
    },
    answerCounts() {
      return this.data && this.data.results
        ? this.data.results.answerCounts
        : null;
    },
    applicantsCount() {
      return this.data.applicants ? this.data.applicants.length : 0;
    },
    applicantData() {
      return [
        { label: "Evaluado", value: this.assessedName },
        { label: "Correo Electrónico", value: this.assessedEmail },
        { label: "Fecha de Aplicación", value: formatDate(this.submittedAt) }
      ];
    },
    assessedName() {
      return this.data ? this.data.assessedName : "-";
    },
    assessedEmail() {
      return this.data ? this.data.assessedEmail : "-";
    },
    submittedAt() {
      return this.data ? this.data.submittedAt : null;
    },
    hasApplicants() {
      return this.data.applicants && this.data.applicants.length > 0;
    }
  },
  methods: {
    onPrint() {
      window.print();
    }
  }
});
</script>
