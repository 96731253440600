<template>
  <v-container fluid class="white py-0 px-0" id="printPage">
    <report-header id="ready_to_know">
      <template v-slot:data>
        <applicant-data :data="applicantData" />
      </template>

      <template v-slot:controls>
        <v-row justify="end" align="center">
          <v-col cols="4" class="d-flex justify-end pr-sm-10 pr-4">
            <v-btn elevation="2" @click="onPrint">Imprimir</v-btn>
          </v-col>
        </v-row>
      </template>
    </report-header>

    <section-intro />

    <diagnostic-personal-goals :data="values" :answers="answers" />
  </v-container>
</template>

<script lang="js">
import { defineComponent } from 'vue';

import levels from "@/model/pgc/levels.js";
import questions from "@/model/pgc/questions.js";
import { formatDate } from "@/utils/date";

import ApplicantData from "../common/ApplicantData.vue";
import ReportHeader from "../common/ReportHeader.vue";
import SectionIntro from "./components/SectionIntro.vue";
import DiagnosticPersonGoals from "./components/DiagnosticPersonGoals.vue";

export default defineComponent({
  name: "PersonalGoalConvergence",
  components: {
    "applicant-data": ApplicantData,
    "report-header": ReportHeader,
    "section-intro": SectionIntro,
    "diagnostic-personal-goals": DiagnosticPersonGoals
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      levels: levels
    };
  },
  computed: {
    applicantData() {
      return [
        { label: "Aplicante", value: this.applicantName },
        { label: "Evaluado", value: this.assessedName },
        { label: "Correo Electrónico", value: this.applicantEmail },
        { label: "Fecha de Aplicación", value: formatDate(this.submittedAt) }
      ];
    },
    applicantName() {
      return this.data ? this.data.applicantName : "-";
    },
    assessedName() {
      return this.data ? this.data.assessedName : "-";
    },
    applicantEmail() {
      return this.data ? this.data.applicantEmail : "-";
    },
    submittedAt() {
      return this.data ? this.data.submittedAt : null;
    },
    values() {
      const { results } = this.data;

      return levels.map((level, index) => ({
        ...level,
        value: results
          ? Math.round(results.filter(a => a === (index + 1)).length * 100 / 10)
          : 0
      }));
    },
    answers() {
      return this.data.results.map((item, index) => questions[index][item - 1]);
    }
  },
  methods: {
    onPrint() {
      window.print();
    }
  }
});
</script>
