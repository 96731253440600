<template>
  <div>
    <template v-if="isApplicant">
      <div>
        <p>¡Hola! Agradecemos tu participación en esta evaluación anónima.</p>
        <p>
          Es importante que compartas tus experiencias de manera sincera y
          honesta para contribuir al proceso de formación de tu líder.
        </p>
        <p>
          Aquí tienes algunas instrucciones clave para completar la encuesta:
        </p>
        <ol>
          <li>
            Responde basándote en tus experiencias cotidianas. No dudes en ser
            franco/a.
          </li>
          <li>No analices mucho tus respuestas.</li>
        </ol>
        <br />
        <p>
          Evaluemos los comportamientos de tu jefe. Utiliza la siguiente escala
          para describir la frecuencia con la que observas cada comportamiento:
        </p>
        <ol>
          <li>Nunca: Tu jefe nunca realiza este comportamiento.</li>
          <li>Raramente: Ocasionalmente observas este comportamiento.</li>
          <li>A veces: A veces observas este comportamiento.</li>
          <li>Frecuentemente: Con frecuencia observas este comportamiento.</li>
          <li>Siempre: Tu jefe siempre realiza este comportamiento.</li>
        </ol>
        <br />
        <p>
          Recuerda, no analices demasiado tu respuesta y ¡contesta con total
          sinceridad!
        </p>
      </div>
    </template>

    <template v-else>
      <div>
        <p>¡Hola! Por favor lee las instrucciones antes de comenzar.</p>
        <p>
          Completar este instrumento te tomará aproximadamente 15 minutos. En
          este lapso, asegúrate de estar en un ambiente libre de interrupciones
          y distracciones.
        </p>
        <p>Indicaciones:</p>
        <p>
          Es importante que respondas con base en tu experiencia cotidiana con
          tus colaboradores, de manera sincera y honesta, para que los
          resultados agreguen verdadero valor en tu proceso de formación como
          líder.
        </p>
        <p>
          Utiliza la siguiente escala para describir la frecuencia con la que
          realizas cada comportamiento:
        </p>
        <ol type="1">
          <li>
            Nunca: Nunca realizas este comportamiento con tus colaboradores.
          </li>
          <li>
            Raramente: Ocasionalmente realizas este comportamiento con tus
            colaboradores.
          </li>
          <li>
            A veces: A veces realizas este comportamiento con tus colaboradores.
          </li>
          <li>
            Frecuentemente: Con frecuencia realizas este comportamiento con tus
            colaboradores.
          </li>
          <li>
            Siempre: Siempre realizas este comportamiento con tus colaboradores.
          </li>
        </ol>
        <br />
        <p>
          No analices demasiado tu respuesta y ¡contesta con total sinceridad!
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import assessmentMixin from "@/mixins/assessmentMixin";

export default defineComponent({
  mixins: [assessmentMixin],
  name: "StartTextOTO",
  computed: {
    isApplicant() {
      return this.applicantId !== null;
    }
  }
});
</script>
