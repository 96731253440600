<template>
  <v-container class="py-0">
    <v-row no-gutters align="center">
      <v-col :cols="colSize" class="d-flex align-center">
        <div class="node black-background d-inline-block" />
        <report-section-subtitle
          text="Estilo Natural"
          image="ICONOS-01.png"
          :subtext="primaryName"
        />
      </v-col>
      <v-col :cols="colSize" class="d-flex align-center">
        <div class="node white-background d-inline-block" />
        <report-section-subtitle
          text="Respuesta Adaptativa"
          image="ICONOS-02.png"
          :subtext="consciousName"
        />
      </v-col>
      <v-col v-if="showRoleNode" cols="4" class="d-flex align-center">
        <div class="roleNode d-inline-block"></div>
        <report-section-subtitle
          text="Perfil del Cargo"
          image="ICONOS-10.png"
          :subtext="position"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "GraphLegend",
  props: {
    primaryName: {
      type: String,
      required: false
    },
    consciousName: {
      type: String,
      required: false
    },
    position: {
      type: String,
      required: false
    },
    modelType: {
      type: String,
      required: false
    }
  },
  computed: {
    showRoleNode() {
      return this.modelType === "ROLE_DISC";
    },
    colSize() {
      return this.showRoleNode ? 4 : 6;
    }
  }
};
</script>

<style scoped>
.node {
  width: 2em;
  height: 2em;
  border-radius: 25px;
  float: left;
  border-color: black;
  border-style: solid;
}
.roleNode {
  background-color: #a0a0a0;
  width: 2em;
  height: 2em;
  border-radius: 25px;
  float: left;
  border-color: black;
  border-style: solid;
}
</style>
