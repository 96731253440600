<template>
  <div class="header-background">
    <v-row no-gutters align="center">
      <v-col sm="6" cols="12">
        <v-row>
          <v-col
            class="d-flex align-center pl-6 justify-center no-print"
            v-if="isUserAuthenticated"
          >
            <o-back-button path="/" />
          </v-col>

          <v-col cols="9">
            <v-img
              contain
              max-height="9em"
              max-width="9em"
              :src="require('@/assets/logo-print.png')"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="data">
            <slot name="data" />
          </v-col>
        </v-row>
      </v-col>

      <v-col sm="6" cols="12" class="no-print">
        <slot name="controls" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ReportHeader",
  computed: {
    isUserAuthenticated() {
      return this.$store.getters.isUserAuthenticated;
    }
  }
};
</script>

<style scoped>
.header-background {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(233, 234, 237, 1) 100%
  );
}
@media print {
  .no-print {
    display: none !important;
  }
}
</style>
