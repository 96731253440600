var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-none d-sm-block d-print-block pt-8 no-breakable"},[_c('v-row',[_c('v-col',{staticClass:"primary white--text ma-1 table-title",attrs:{"align":"center","cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('v-row',_vm._l((_vm.columns),function(column){return _c('v-col',{key:`header-${column.label.split(' ').join('')}`,staticClass:"accent white--text ma-1 table-cell",attrs:{"cols":column.cols}},[(_vm.headerSize === 'small')?_c('div',{class:{
          xs: _vm.$vuetify.breakpoint.xs,
          sm: _vm.$vuetify.breakpoint.sm,
          md: _vm.$vuetify.breakpoint.md,
          lg: _vm.$vuetify.breakpoint.lg,
          xl: _vm.$vuetify.breakpoint.xl
        },domProps:{"innerHTML":_vm._s(column.label)}}):(_vm.headerSize === 'large')?_c('h3',{domProps:{"innerHTML":_vm._s(column.label)}}):_vm._e()])}),1),_vm._l((_vm.rows),function(row,i){return _c('v-row',{key:`row-${i}`},_vm._l((row),function(data,j){return _c('v-col',{key:`data-${i}-${j}`,staticClass:"background darken-1 primary--text ma-1 table-cell",attrs:{"cols":data.cols}},[_vm._v(" "+_vm._s(data.value)+" ")])}),1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }