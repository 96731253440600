export default [
  {
    key: "emotion",
    label: "Liderazgo de tus emociones",
    items: [
      "Soy consciente de mis emociones.",
      "Acepto mis emociones sin juzgarlas.",
      "Manejo mis emociones sin reaccionar impulsivamente.",
      "Me siento bien en la incertidumbre.",
      "Sé lo que me apasiona.",
      "Consigo recuperar la motivación cuando la energía me falla",
      "Experimento bienestar interior."
    ]
  },
  {
    key: "mind",
    label: "Liderazgo de tu mente",
    items: [
      "Soy consciente de mis pensamientos y de cómo me afectan.",
      "Tengo buenos hábitos mentales (medito, consumo contenido de valor).",
      "Soy capaz de observar mis pensamientos sin involucrarme con ellos.",
      "Sé cómo aquietar mi mente.",
      "Investigo y estudio para comprender la realidad y el contexto.",
      "Asumo mis errores sin culpar a nadie y busco aprender de ellos.",
      "Me preparo para saber y poder generar valor en lo que hago."
    ]
  },
  {
    key: "behavior",
    label: "Liderazgo de tus acciones",
    items: [
      "Actúo dándome cuenta de lo que hago y consciente de sus consecuencias.",
      "Tengo buenos hábitos de salud (alimentación, deporte, sueño, relajación).",
      "Asumo activamente el cambio y la innovación.",
      "Manejo los retos cotidianos sin que lleguen a producirme estrés.",
      "Aprendo y me adapto rápidamente, aunque sean circunstancias difíciles.",
      "Planifico mis metas y persisto hasta lograrlas.",
      "Soy auténtico/a en mi forma de ser incluso cuando me siento vulnerable."
    ]
  }
];
