<template>
  <g>
    <circle
      v-if="!!data"
      :class="classes"
      :cx="data.cx"
      :cy="data.cy"
      :r="data.r"
      :transform="data.transform"
      :stroke="data.stroke"
      :stroke-miterlimit="data.strokeMiterLimit"
      @mouseenter="$emit('mouseenter', $event)"
      @mouseleave="$emit('mouseleave', $event)"
    />
  </g>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    },
    highlight: Boolean
  },
  computed: {
    classes() {
      return [this.data.class, this.highlight ? "highlight" : null];
    }
  }
});
</script>

<style lang="css" scoped>
.highlight {
  stroke-width: 8px;
}
</style>
