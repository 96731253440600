<template>
  <div class="pagebreakAfter">
    <div class="white pt-16">
      <v-row no-gutters justify="center">
        <v-col cols="10" align="center">
          <report-section-title>Mapa de Comportamiento</report-section-title>

          <xs-rotation-message />
        </v-col>
      </v-row>

      <div class="d-none d-sm-block d-print-block">
        <v-row no-gutters>
          <!-- <v-col cols="0" md="1" /> -->
          <v-col cols="12" class="px-5 px-sm-5 px-md-0">
            <o-octagon :data="profiles" />

            <graph-legend
              :primaryName="primaryName"
              :consciousName="consciousName"
            />
          </v-col>
          <!-- <v-col cols="0" md="1" /> -->
        </v-row>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

import { Octagon } from "../../common/octagon";
import graphLegend from "../../common/GraphLegend";

export default defineComponent({
  props: {
    primary: {
      type: Object,
      required: false
    },
    conscious: {
      type: Object,
      required: false
    }
  },
  components: {
    "o-octagon": Octagon,
    "graph-legend": graphLegend
  },
  computed: {
    profiles() {
      const result = [];
      if (this.primary) {
        result.push({
          ...this.primary,
          profileType: "P"
        });
      }
      if (this.conscious) {
        result.push({
          ...this.conscious,
          profileType: "C"
        });
      }

      return result;
    },
    primaryName() {
      if (this.primary) {
        const { id, name } = this.primary;
        return !!name && !!id ? `${name} (${id})` : "-";
      } else {
        return "-";
      }
    },
    consciousName() {
      if (this.conscious) {
        const { id, name } = this.conscious;
        return !!name && !!id ? `${name} (${id})` : "-";
      } else {
        return "-";
      }
    }
  }
});
</script>

<style lang="css">
.sectionTitle {
  font-size: 2em;
  font-weight: 600;
}
.nameTitle {
  font-size: 0.8em;
}
.typeTitle {
  font-size: 1em;
  font-weight: 600;
}
.graphLabels {
  float: left;
  margin-left: 0.5em;
}
.userType-natural-style {
  background: linear-gradient(
    150deg,
    var(--v-primary-base) 0%,
    var(--v-primary-lighten3) 100%
  );
  color: #fff;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  text-align: left;
  padding: 0 2em 0 0.5em;
  display: inline-block;
}
.blackNode {
  width: 2em;
  height: 2em;
  border-radius: 25px;
  background-color: black;
  float: left;
}
.whiteNode {
  width: 2em;
  height: 2em;
  border-radius: 25px;
  background-color: white;
  border-color: black;
  border-style: solid;
  float: left;
}

@media print {
  .graphLabels {
    float: left;
    margin-left: 2em;
  }
}
</style>
