import messages from "@/plugins/messages";
import shopService from "@/services/shopService";
import paymentService from "@/services/paymentService";

export default {
  actions: {
    listProducts({ commit }, type) {
      return new Promise((resolve, reject) => {
        commit("setProcessingRequest", true);
        shopService
          .products(type)
          .then(products => {
            commit("setProcessingRequest", false);
            resolve(products.filter(product => !product.isSecondary));
          })
          .catch(error => {
            commit("setProcessingRequest", false);
            reject(error);
          });
      });
    },
    /**
     *
     * @param {VuexStore} store
     * @param {Object} payload
     *  - `priceId`
     *  - `type`
     * @returns
     */
    goToProcessPayment({ commit, dispatch }, payload) {
      return new Promise(async (resolve, reject) => {
        commit("setProcessingRequest", true);
        const { priceId, type, quantity } = payload;
        try {
          const accountInfo = await dispatch("getAccountInfo");

          if (accountInfo) {
            const customerId = accountInfo.customerId;
            paymentService.processPayment({
              priceId,
              quantity,
              type,
              customerId
            });
            commit("setProcessingRequest", false);
            resolve();
          } else {
            commit("setProcessingRequest", false);
            reject(new Error(messages.errors.invalidAccountInfo));
          }
        } catch (error) {
          commit("setProcessingRequest", false);
          reject(error);
        }
      });
    },
    manageMembership({ commit }) {
      return new Promise((resolve, reject) => {
        commit("setProcessingRequest", true);
        shopService
          .manageMembership()
          .then(redirectUrl => {
            commit("setProcessingRequest", false);
            resolve(redirectUrl);
          })
          .catch(error => {
            commit("setProcessingRequest", false);
            reject(error);
          });
      });
    }
  }
};
