export default [
  {
    code: "D",
    id: 1,
    key: "3000",
    name: "Director"
  },
  {
    code: "I",
    id: 2,
    key: "0300",
    name: "Influenciador"
  },
  {
    code: "S",
    id: 3,
    key: "0030",
    name: "Sostenedor"
  },
  {
    code: "C",
    id: 4,
    key: "0003",
    name: "Cumplidor"
  },
  {
    code: "Di",
    id: 5,
    key: "3200",
    name: "Innovador"
  },
  {
    code: "DI",
    id: 6,
    key: "3300",
    name: "Pionero"
  },
  {
    code: "Id",
    id: 7,
    key: "2300",
    name: "Creativo"
  },
  {
    code: "Ds",
    id: 8,
    key: "3020",
    name: "Resolutivo"
  },
  {
    code: "DS",
    id: 9,
    key: "3030",
    name: "Perseverante"
  },
  {
    code: "Sd",
    id: 10,
    key: "2030",
    name: "Lógico"
  },
  {
    code: "Dc",
    id: 11,
    key: "3002",
    name: "Implementador"
  },
  {
    code: "DC",
    id: 12,
    key: "3003",
    name: "Realizador"
  },
  {
    code: "Cd",
    id: 13,
    key: "2003",
    name: "Diseñador"
  },
  {
    code: "Is",
    id: 14,
    key: "0320",
    name: "Cohesionador"
  },
  {
    code: "IS",
    id: 15,
    key: "0330",
    name: "Relacionador"
  },
  {
    code: "Si",
    id: 16,
    key: "0230",
    name: "Reflexivo"
  },
  {
    code: "Ic",
    id: 17,
    key: "0302",
    name: "Impulsador"
  },
  {
    code: "CI",
    id: 18,
    key: "0303",
    name: "Promotor"
  },
  {
    code: "Ci",
    id: 19,
    key: "0203",
    name: "Diplomático"
  },
  {
    code: "Sc",
    id: 20,
    key: "0032",
    name: "Organizador"
  },
  {
    code: "SC",
    id: 21,
    key: "0033",
    name: "Coordinador"
  },
  {
    code: "Cs",
    id: 22,
    key: "0023",
    name: "Finalizador"
  },
  {
    code: "Dis",
    id: 23,
    key: "3210",
    name: "Enérgico"
  },
  {
    code: "Di=s",
    id: 24,
    key: "3220",
    name: "Capaz"
  },
  {
    code: "DSi",
    id: 25,
    key: "3230",
    name: "Enfocado"
  },
  {
    code: "Dsi",
    id: 26,
    key: "3120",
    name: "Responsable"
  },
  {
    code: "Dic",
    id: 27,
    key: "3201",
    name: "Independiente"
  },
  {
    code: "Di=c",
    id: 28,
    key: "3202",
    name: "Solucionador"
  },
  {
    code: "DCi",
    id: 29,
    key: "3203",
    name: "Estratega"
  },
  {
    code: "Dci",
    id: 30,
    key: "3102",
    name: "Visionario"
  },
  {
    code: "Dsc",
    id: 31,
    key: "3021",
    name: "Práctico"
  },
  {
    code: "Ds=c",
    id: 32,
    key: "3022",
    name: "Exigente"
  },
  {
    code: "DSc",
    id: 33,
    key: "3032",
    name: "Adaptable"
  },
  {
    code: "Dcs",
    id: 34,
    key: "3012",
    name: "Dedicado"
  },
  {
    code: "Ids",
    id: 35,
    key: "2310",
    name: "Carismático"
  },
  {
    code: "Id=s",
    id: 36,
    key: "2320",
    name: "Entrenador"
  },
  {
    code: "IDs",
    id: 37,
    key: "3320",
    name: "Convincente"
  },
  {
    code: "Isd",
    id: 38,
    key: "1320",
    name: "Servicial"
  },
  {
    code: "Idc",
    id: 39,
    key: "2301",
    name: "Motivador"
  },
  {
    code: "Id=c",
    id: 40,
    key: "2302",
    name: "Comunicativo"
  },
  {
    code: "IDc",
    id: 41,
    key: "3302",
    name: "Emprendedor"
  },
  {
    code: "Icd",
    id: 42,
    key: "1302",
    name: "Dinámico"
  },
  {
    code: "Isc",
    id: 43,
    key: "0321",
    name: "Formador"
  },
  {
    code: "Is=c",
    id: 44,
    key: "0322",
    name: "Asesor"
  },
  {
    code: "ISc",
    id: 45,
    key: "0332",
    name: "Integrador"
  },
  {
    code: "Ics",
    id: 46,
    key: "0312",
    name: "Cooperativo"
  },
  {
    code: "Sdi",
    id: 47,
    key: "2130",
    name: "Persistente"
  },
  {
    code: "Sd=i",
    id: 48,
    key: "2230",
    name: "Negociador"
  },
  {
    code: "SId",
    id: 49,
    key: "2330",
    name: "Conciliador"
  },
  {
    code: "Sid",
    id: 50,
    key: "1230",
    name: "Empático"
  },
  {
    code: "Sic",
    id: 51,
    key: "0231",
    name: "Comprensivo"
  },
  {
    code: "Si=c",
    id: 52,
    key: "0232",
    name: "Planificado"
  },
  {
    code: "SCi",
    id: 53,
    key: "0233",
    name: "Detallista"
  },
  {
    code: "Sci",
    id: 54,
    key: "0132",
    name: "Consistente"
  },
  {
    code: "Sdc",
    id: 55,
    key: "2031",
    name: "Sincero"
  },
  {
    code: "Sd=c",
    id: 56,
    key: "2032",
    name: "Cuidadoso"
  },
  {
    code: "SCd",
    id: 57,
    key: "2033",
    name: "Metódico"
  },
  {
    code: "Scd",
    id: 58,
    key: "1032",
    name: "Prudente"
  },
  {
    code: "Cdi",
    id: 59,
    key: "2103",
    name: "Competente"
  },
  {
    code: "Cd=i",
    id: 60,
    key: "2203",
    name: "Especialista"
  },
  {
    code: "CId",
    id: 61,
    key: "2303",
    name: "Eficaz"
  },
  {
    code: "Cid",
    id: 62,
    key: "1203",
    name: "Eficiente"
  },
  {
    code: "Cis",
    id: 63,
    key: "0213",
    name: "Comprometido"
  },
  {
    code: "Ci=s",
    id: 64,
    key: "0223",
    name: "Ponderado"
  },
  {
    code: "CIs",
    id: 65,
    key: "0323",
    name: "Colaborativo"
  },
  {
    code: "Csi",
    id: 66,
    key: "0123",
    name: "Disciplinado"
  },
  {
    code: "Cds",
    id: 67,
    key: "2013",
    name: "Ecuanime"
  },
  {
    code: "Cd=s",
    id: 68,
    key: "2023",
    name: "Estructurado"
  },
  {
    code: "CDs",
    id: 69,
    key: "3023",
    name: "Ingenioso"
  },
  {
    code: "Csd",
    id: 70,
    key: "1023",
    name: "Investigador"
  },
  {
    code: "DIS",
    id: 71,
    key: "3330",
    name: "Mentor"
  },
  {
    code: "ICD",
    id: 72,
    key: "3303",
    name: "Propositivo"
  },
  {
    code: "SCD",
    id: 73,
    key: "3033",
    name: "Racional"
  },
  {
    code: "CSI",
    id: 74,
    key: "0333",
    name: "Precavido"
  },
  {
    code: "N/A",
    id: 75,
    key: "3333",
    name: "Inválido"
  },
  {
    code: "N/A",
    id: 76,
    key: "0000",
    name: "Inválido"
  }
];
