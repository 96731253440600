<template>
  <o-octagon-node-circle
    v-if="data.type === 'circle'"
    :data="data"
    :highlight="computedHighlight"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  />

  <o-octagon-node-path
    v-else-if="data.type === 'path'"
    :data="data"
    :highlight="computedHighlight"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  />

  <o-octagon-node-g
    v-else-if="data.type === 'g'"
    :data="data"
    :highlight="computedHighlight"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  />
</template>

<script lang="js">
import { defineComponent } from "vue";

import OctagonNodeCircle from "./OctagonNodeCircle";
import OctagonNodePath from "./OctagonNodePath";
import OctagonNodeG from "./OctagonNodeG";

export default defineComponent({
  components: {
    "o-octagon-node-circle": OctagonNodeCircle,
    "o-octagon-node-path": OctagonNodePath,
    "o-octagon-node-g": OctagonNodeG
  },
  props: {
    data: Object,
    highlight: Boolean
  },
  data() {
    return {
      active: false
    };
  },
  computed: {
    computedHighlight() {
      return this.active || this.highlight;
    }
  },
  methods: {
    onMouseEnter(event) {
      this.active = true;
      this.$emit("mouseenter", event, this.data);
    },
    onMouseLeave(event) {
      this.active = false;
      this.$emit("mouseleave", event);
    },
  }
});
</script>
