export default [
  {
    key: "f",
    label: "Facultados para la Autonomía e Innovación",
    items: [
      {
        value: 1,
        text: "Los integrantes del equipo son optimistas con relación al futuro, aunque no están preparados en cuanto a las competencias y los conocimientos que requieren para realizar sus tareas de forma adecuada. Los protocolos, normas, procedimientos, políticas y estándares esperados aún no los tienen claros."
      },
      {
        value: 2,
        text: "Las políticas de funcionamiento le generan incomodidad. Se sienten frustrados. Predomina el conflicto producto de la actitud de competencia entre los miembros del equipo. No han demostrado buena fe en el uso de la autonomía."
      },
      {
        value: 3,
        text: "Se comienza a manifestar optimismo con relación a la capacidad del equipo de lograr los objetivos y resolver los problemas. La sensación de equipo comienza a evidenciarse a través de comportamientos colaborativos. Comienzan a incorporar las normas y los protocolos con mayor comodidad. Cada vez se sienten más seguros de realizar la tarea de forma autónoma porque han ido fortaleciendo las competencias y conocimientos. Continúan aprendiendo y alcanzando mayor nivel de seguridad. Aún no se sienten seguros para innovar."
      },
      {
        value: 4,
        text: "El equipo se comporta con seguridad y alto desempeño en el logro de los resultados y la ejecución de las tareas. Ensayan y prueban con nuevos métodos, retando la forma tradicional de hacer las cosas. Son expertos en lo que hacen. Entienden muy bien la importancia de las normas y los procedimientos y las asumen con buena actitud. Colaboran entre ellos y se respetan. Demuestran buena fe en el uso de la autonomía. No necesitan supervisión para realizar el trabajo. Comparten la información con confianza. Se preocupan por desarrollarse y prepararse constantemente."
      }
    ]
  },
  {
    key: "o",
    label: "Organizados con Base en una Visión Compartida",
    items: [
      {
        value: 1,
        text: "Los integrantes del equipo apenas están comenzando a saber cuáles son sus funciones y responsabilidades. Aún no han definido las estrategias. Su foco está en conocer el nuevo contexto, a sus compañeros y en adaptarse. Los miembros del equipo aún no saben si comparten o no la misma forma de ver el propósito de la misión común. Los valores de los miembros del equipo aún no están claros."
      },
      {
        value: 2,
        text: "Hay conflictos entre las expectativas iniciales de los integrantes del equipo y lo que está sucediendo en la práctica con relación a los resultados esperados. Hay confusión y desacuerdo sobre el propósito del equipo y las funciones de sus miembros. Conflictos de valores entre los miembros del equipo y/o con el líder. El equipo no logra integrarse a una meta común."
      },
      {
        value: 3,
        text: "El equipo se va integrando a un propósito común. Cada vez tienen una visión más compartida. Cada vez comprenden mejor la misión del proyecto. Están aprendiendo a lograr las metas, llegando a acuerdos. Comienzan a compartir los valores positivos. Están comenzando a definir las estrategias para lograr la visión conjunta. Cada vez tienen más claras sus funciones."
      },
      {
        value: 4,
        text: "El equipo está integrado y comprometido alrededor de un propósito común. Tienen una visión compartida que es una guía clara para todos los miembros del equipo. Comparten los valores positivos de la organización. Hay sentido de equipo y criterio de excelencia común. Tienen claro lo que se espera del equipo. Entienden la importancia de su misión. Han desarrollado en equipo las estrategias para lograr la visión compartida. Tienen claras sus funciones con relación a las estrategias definidas. Creen en el poder del equipo para alcanzar las metas comunes."
      }
    ]
  },
  {
    key: "r",
    label: "Relacionados con Buena Voluntad",
    items: [
      {
        value: 1,
        text: "Los miembros del equipo se muestran amigables pero vacilantes. No se atreven a expresarse libremente, dejan el protagonismo y la dirección exclusivamente al líder. No se sienten en confianza, pero se tratan con respeto y amabilidad. No hay familiaridad entre los miembros del equipo. Aún no hay sensación de equipo."
      },
      {
        value: 2,
        text: "Hay desconfianza entre los miembros del equipo. Hay mala comunicación. No se escuchan, se interrumpen, hablan negativamente entre ellos mismos y con el líder. No se aceptan. Hay conflictos. Puede que se sientan frustrados. Puede haber parcelas y miembros excluidos. No participan abiertamente ni expresan sus sentimientos con los demás. No hay buena voluntad entre ellos."
      },
      {
        value: 3,
        text: "Comienzan a confiar, pero aún con un poco de cautela entre ellos. Van superando los conflictos, pero están un poco sensibles por lo que evitan hablar de lo negativo. Ya están colaborando un poco más entre ellos y comportándose como compañeros de equipos. La comunicación comienza a fluir un poco mejor. Se escuchan más y hablan más entre ellos. Van teniendo buena voluntad como miembros del equipo de forma sincera."
      },
      {
        value: 4,
        text: "Se relacionan con buena voluntad entre ellos. Son sinceros, honestos, se comunican con respeto. Han superado el miedo al rechazo. Se aceptan. Tienen buena comunicación. Hablan abiertamente de sus sentimientos. Aceptan e integran las diferencias. Se expresan afecto. Saben resolver los conflictos entre ellos, por ellos mismos."
      }
    ]
  },
  {
    key: "m",
    label: "Motivados",
    items: [
      {
        value: 1,
        text: "Los integrantes están contentos de haber sido elegidos para conformar el equipo, pero tienen dudas de si les gustará en el futuro ser parte de este. Tienen expectativas. Aún no saben si van a poder conformar un verdadero equipo que logre los resultados."
      },
      {
        value: 2,
        text: "No hay confianza entre los miembros del equipo ni con el líder. Los integrantes se sienten muy mal dentro del equipo. Hay frustración, desmotivación, descontento, conflictos. Compiten entre ellos, están desintegrados como equipos. Sienten que sus compañeros en vez de ser aliados son una amenaza. No confían, por lo tanto, no pueden obtener resultados de alta calidad."
      },
      {
        value: 3,
        text: "El equipo se comporta con un cierto grado de cautela. Poco a poco se van sintiendo mejor entre ellos, los sentimientos negativos van siendo reemplazados por los positivos. Van confiando unos en los otros. Se comienzan a integrar. Comienzan a sentir nuevamente entusiasmo por pertenecer al equipo porque ahora es satisfactorio trabajar en conjunto. El esfuerzo compartido trae buenos resultados."
      },
      {
        value: 4,
        text: "El sentido de pertenencia es sólido y compartido. Hay orgullo e identidad de equipo. Confían entre ellos, se sienten motivados y satisfechos con los resultados, el nivel de excelencia y el éxito que alcanzan en conjunto."
      }
    ]
  },
  {
    key: "u",
    label: "Usan sus Recursos y Competencias Eficientemente",
    items: [
      {
        value: 1,
        text: "El líder marca la pauta. Dirige. Aprueba. Domina. El equipo es dependiente de las órdenes del líder. El equipo obedece. Los integrantes del equipo están atentos y son discretos en cuanto a sus acciones. Miembros formales y cautos dentro del equipo."
      },
      {
        value: 2,
        text: "Frustración y ambivalencia de los integrantes del equipo. Pueden ser agresivos/pasivos u oposicionistas/dependientes. Hay mucho estrés y tensión lo cual debilita la flexibilidad en el uso de los recursos y de las competencias. Insatisfacción general."
      },
      {
        value: 3,
        text: "Comienzan a tener conciencia del uso adecuado de los recursos y de las competencias. Se comparten las responsabilidades. Los talentos personales suman en la construcción del éxito compartido. Comienzan a favorecer la armonía en las relaciones como un capital indispensable que afecta directamente en la productividad."
      },
      {
        value: 4,
        text: "Se comparte el liderazgo dependiendo de lo experto que sea cada uno. Integran las diferentes opiniones de los miembros expertos. Se intercambian las funciones dependiendo de las competencias de cada uno. Se adaptan a las demandas del momento, buscando sacar el mayor provecho del profesionalismo de cada miembro del equipo. Dan a conocer su perspectiva y opiniones cada vez que agregan verdadero valor al logro de los objetivos."
      }
    ]
  },
  {
    key: "l",
    label: "Logran la Excelencia",
    items: [
      {
        value: 1,
        text: "Poca capacidad para solucionar problemas y tomar decisiones. No pueden demostrar habilidad porque apenas comienzan a aprender. Nuevos en la ejecución de las tareas. Pocos resultados. Bajo conocimiento."
      },
      {
        value: 2,
        text: "Muchas dificultades en la solución de problemas. Están comenzando a aprender el criterio para tomar decisiones adecuadamente. Apenas comienzan a aprender a realizar sus funciones. Las competencias tienen un desarrollo inicial o medio. Aún no pueden lograr los objetivos de forma independiente. Requieren de ayuda del líder o de un experto para alcanzar los objetivos."
      },
      {
        value: 3,
        text: "Comienzan a tener un compromiso positivo en cuanto a la realización de las tareas.  El dominio de sus funciones está en un nivel de medio a alto. Son eficientes en la toma de decisiones y en la solución de problemas. Aún tienen que aprender a estar más seguros de sí mismos en la obtención de los objetivos que se plantean y en el logro de la excelencia."
      },
      {
        value: 4,
        text: "Alcanzan los objetivos con excelencia y altos estándares de calidad, de forma ágil. Pueden trabajar eficientemente con personas externas al equipo, integrándose sin dificultad. Logran los más altos niveles de productividad. Toman las decisiones correctas. Son hábiles y competentes. Aprenden de sus errores. Son eficientes e innovadores en la solución de los problemas y para superar los obstáculos."
      }
    ]
  },
  {
    key: "a",
    label: "Amplían la Conciencia Compartida",
    items: [
      {
        value: 1,
        text: "El líder tiene un papel protagónico, su reconocimiento es muy valioso para el equipo, los miembros buscan activamente su aprobación. La opinión de los compañeros está en un segundo plano, lo más importante es lo que el líder diga."
      },
      {
        value: 2,
        text: "Los integrantes del equipo se critican y se quejan uno de los otros. Se tratan exclusivamente con base en los aspectos negativos. No tienen una buena actitud entre ellos. No se reconocen. No se aprecian."
      },
      {
        value: 3,
        text: "Los integrantes del equipo apenas se comienzan a interesar unos en los otros de forma positiva. Comienzan a demostrarse aprecio y reconocimiento. Se inicia una relación de confianza y armonía entre ellos."
      },
      {
        value: 4,
        text: "Se aprecian, respetan y se consideran unos a otros. Se ofrecen reconocimiento entre ellos y crean un ambiente de armonía y expansión. Los integrantes del equipo sienten que cada día crecen, aprenden y evolucionan juntos. Se sienten seguros y cómodos dentro del equipo."
      }
    ]
  }
];
