<template>
  <o-card-form-layout title="Recuperar Contraseña" :actions="actions">
    <v-container class="pb-0">
      <v-row>
        <v-col cols="12" class="py-0 px-1">
          <o-email-field v-model="email" />
        </v-col>
      </v-row>
    </v-container>
  </o-card-form-layout>
</template>

<script>
import messageDialogMixin from "@/mixins/messageDialogMixin";

export default {
  name: "ForgotPassword",
  mixins: [messageDialogMixin],
  data() {
    return {
      email: "",
      actions: [
        { type: "submit", label: "Continuar", handler: this.forgotPassword },
        {
          type: "secondary-left",
          label: "Volver Atrás",
          to: "/signin"
        }
      ]
    };
  },
  methods: {
    close() {
      this.$router.push("/signin");
    },
    forgotPassword(form) {
      if (form.validate()) {
        this.$store
          .dispatch("forgotPassword", this.email)
          .then(() =>
            this.successDialog(
              "Se ha enviado a su correo electrónico para restablecer su contraseña. Si no recibe nada en unos minutos, por favor revise su directorio de correo no deseado",
              this.close
            )
          )
          .catch(error => this.errorDialog(error.message, this.close));
      }
    }
  }
};
</script>

<style scoped></style>
