<template>
  <o-card-form-layout :title="''" :actions="actions" page-mode="mid">
    <v-col class="text-left pt-0 pt-md-3 d-flex align-center" md="6" cols="10">
      <o-back-button path="/users" />
      <h2 class="ml-2">Invitar Usuario</h2>
    </v-col>

    <v-container class="pb-0">
      <v-row>
        <v-col class="py-0 px-1">
          <o-text-field
            v-model="user.email"
            label="Correo Electrónico"
            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="py-0 px-1">
          <o-text-field
            v-model="user.displayName"
            label="Nombre(s) y Apellido(s)"
            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="py-0 px-1">
          <o-checkbox v-model="sendAgain" label="Permanecer aquí al enviar" />
        </v-col>
      </v-row>
    </v-container>
  </o-card-form-layout>
</template>

<script lang="js">
import { defineComponent } from "vue";
import messageDialogMixin from "@/mixins/messageDialogMixin";

export default defineComponent({
  mixins: [messageDialogMixin],
  data() {
    return {
      user: {
        email: "",
        displayName: "",
      },
      sendAgain: false,
    };
  },
  computed: {
    actions() {
      return [
        {
          type: "submit",
          label: !this.sendAgain ? "Enviar y Volver Atrás" : "Enviar",
          handler: this.send
        }
      ];
    },
  },
  methods: {
    async send(form) {
      if (form.validate()) {
        try {
          await this.$store.dispatch("sendInvitation", {
            ...this.user,
          });

          this.successDialog("Invitación enviada con éxito");
          form.reset();

          if (!this.sendAgain) {
            this.$router.push('/users');
          }
        } catch (error) {
          this.errorDialog(
            error.message || "Ha ocurrido un error al enviar la invitación"
          );
        }
      }
    }
  }
});
</script>
<style scoped>
.sendbtn {
  width: 95%;
}
</style>
