export default [
  // 1
  {
    attributes: [
      {
        name: "Ser exacto",
        value: "C"
      },
      {
        name: "Ser persuasivo",
        value: "I"
      },
      {
        name: "Tomar decisiones rápidas",
        value: "D"
      },
      {
        name: "Ser amable",
        value: "S"
      }
    ]
  },
  //2
  {
    attributes: [
      {
        name: "Enfrentar desafíos",
        value: "D"
      },
      {
        name: "Analizar muchos hechos antes de actuar",
        value: "C"
      },
      {
        name: "Orientarse al servicio",
        value: "S"
      },
      {
        name: "Sostener relaciones con mucha gente",
        value: "I"
      }
    ]
  },
  //3
  {
    attributes: [
      {
        name: "Estar libre de conflictos y confrontaciones",
        value: "S"
      },
      {
        name: "Mucha comunicación e influencia",
        value: "I"
      },
      {
        name: "Acciones rápidas y arriesgadas",
        value: "D"
      },
      {
        name: "Seguir normas y procedimientos",
        value: "C"
      }
    ]
  },
  //4
  {
    attributes: [
      {
        name: "Adaptarse a un trabajo rutinario",
        value: "S"
      },
      {
        name: "Promover cambios",
        value: "D"
      },
      {
        name: "Concentrarse en los detalles",
        value: "C"
      },
      {
        name: "Confiar en los demás",
        value: "I"
      }
    ]
  },
  //5
  {
    attributes: [
      {
        name: "Capacidad para planificar a gran escala",
        value: "D"
      },
      {
        name: "Capacidad para mantenerse conectado al plan hasta el final",
        value: "C"
      },
      {
        name: "Capacidad para generar entusiasmo y motivación",
        value: "I"
      },
      {
        name: "Capacidad para mantenerse acoplado",
        value: "S"
      }
    ]
  },
  //6
  {
    attributes: [
      {
        name: "Asignaciones retadoras",
        value: "D"
      },
      {
        name: "Trabajo para hacerlo eficientemente al primer intento",
        value: "C"
      },
      {
        name: "Influenciar a otros para alcanzar una meta común",
        value: "I"
      },
      {
        name: "Implementar el plan",
        value: "S"
      }
    ]
  },
  //7
  {
    attributes: [
      {
        name: "Objetividad y precisión",
        value: "C"
      },
      {
        name: "Resolver los problemas de la gente",
        value: "I"
      },
      {
        name: "Libertad para actuar con poca supervisión",
        value: "D"
      },
      {
        name: "Desempeño consistente",
        value: "S"
      }
    ]
  },
  //8
  {
    attributes: [
      {
        name: "Ningún cambio o cambios muy lentos y planificados",
        value: "S"
      },
      {
        name: "Muchos cambios, rápidos y bruscos",
        value: "D"
      },
      {
        name: "Algunos cambios",
        value: "C"
      },
      {
        name: "Cambios frecuentes",
        value: "I"
      }
    ]
  },
  //9
  {
    attributes: [
      {
        name: "Ser metódico",
        value: "S"
      },
      {
        name: "Ser analítico",
        value: "C"
      },
      {
        name: "Ser Exigente",
        value: "D"
      },
      {
        name: "Ser flexible",
        value: "I"
      }
    ]
  },
  //10
  {
    attributes: [
      {
        name: "Diplomacia y formalidad",
        value: "C"
      },
      {
        name: "Trabajo en equipo y cooperación",
        value: "S"
      },
      {
        name: "Franqueza y firmeza",
        value: "D"
      },
      {
        name: "Comunicación y espontaneidad",
        value: "I"
      }
    ]
  },
  //11
  {
    attributes: [
      {
        name: "Resolver problemas",
        value: "D"
      },
      {
        name: "Persistir",
        value: "S"
      },
      {
        name: "Ser cauteloso",
        value: "C"
      },
      {
        name: "Interactuar con mucha gente",
        value: "I"
      }
    ]
  },
  //12
  {
    attributes: [
      {
        name: "Seguridad en sí mismo",
        value: "D"
      },
      {
        name: "Encanto personal",
        value: "I"
      },
      {
        name: "Paciencia",
        value: "S"
      },
      {
        name: "Meticulosidad",
        value: "C"
      }
    ]
  },
  //13
  {
    attributes: [
      {
        name: "Utilizar experiencia técnica",
        value: "C"
      },
      {
        name: "Ser competitivo",
        value: "D"
      },
      {
        name: "Estimular la integración del equipo",
        value: "I"
      },
      {
        name: "Colaborar con los demás",
        value: "S"
      }
    ]
  },
  //14
  {
    attributes: [
      {
        name: "Verbalizar ideas",
        value: "I"
      },
      {
        name: "Dar órdenes",
        value: "D"
      },
      {
        name: "Seguir reglas y protocolos",
        value: "C"
      },
      {
        name: "Seguir órdenes",
        value: "S"
      }
    ]
  },
  //15
  {
    attributes: [
      {
        name: "Disciplina",
        value: "C"
      },
      {
        name: "Tomar riesgos",
        value: "D"
      },
      {
        name: "Equilibrio",
        value: "S"
      },
      {
        name: "Optimismo",
        value: "I"
      }
    ]
  },
  //16
  {
    attributes: [
      {
        name: "Dar apoyo",
        value: "S"
      },
      {
        name: "Contactar personas",
        value: "I"
      },
      {
        name: "Garantizar la presición",
        value: "C"
      },
      {
        name: "Acciones rápidas",
        value: "D"
      }
    ]
  },
  //17
  {
    attributes: [
      {
        name: "Ser lógico",
        value: "S"
      },
      {
        name: "Obtener resultados inmediatos",
        value: "D"
      },
      {
        name: "Cuestionar las suposiciones",
        value: "C"
      },
      {
        name: "Ser convincente",
        value: "I"
      }
    ]
  },
  //18
  {
    attributes: [
      {
        name: "Ser innovador y creativo",
        value: "D"
      },
      {
        name: "Mantener altos controles de calidad",
        value: "C"
      },
      {
        name: "Motivar al equipo",
        value: "I"
      },
      {
        name: "Saber escuchar a la gente",
        value: "S"
      }
    ]
  },
  //19
  {
    attributes: [
      {
        name: "Investigar",
        value: "C"
      },
      {
        name: "Emprender",
        value: "D"
      },
      {
        name: "Comunicar",
        value: "I"
      },
      {
        name: "Cooperar",
        value: "S"
      }
    ]
  },
  //20
  {
    attributes: [
      {
        name: "Lealtad",
        value: "S"
      },
      {
        name: "Entusiasmo",
        value: "I"
      },
      {
        name: "Ética",
        value: "C"
      },
      {
        name: "Valentía",
        value: "D"
      }
    ]
  },
  //21
  {
    attributes: [
      {
        name: "Estar accesible",
        value: "I"
      },
      {
        name: "Estar integrado",
        value: "S"
      },
      {
        name: "Estar Enérgico",
        value: "D"
      },
      {
        name: "Estar alerta",
        value: "C"
      }
    ]
  },
  //22
  {
    attributes: [
      {
        name: "Ser osado",
        value: "D"
      },
      {
        name: "Ser desenvuelto",
        value: "I"
      },
      {
        name: "Ser obediente",
        value: "S"
      },
      {
        name: "Ser analítico",
        value: "C"
      }
    ]
  },
  //23
  {
    attributes: [
      {
        name: "Centrarse en los resultados y en ser resolutivo",
        value: "D"
      },
      {
        name: "Centrarse en los procedimientos y las normativas",
        value: "C"
      },
      {
        name: "Centrarse en hacer nuevas relaciones claves",
        value: "I"
      },
      {
        name: "Centrarse en mantener las relaciones armoniosas",
        value: "S"
      }
    ]
  },
  //24
  {
    attributes: [
      {
        name: "Ambiente estable y predecible",
        value: "S"
      },
      {
        name: "Ambiente de trabajo privado y especializado",
        value: "C"
      },
      {
        name: "Libertad de movimiento y dinamismo social",
        value: "I"
      },
      {
        name: "Libertad de controles",
        value: "D"
      }
    ]
  },
  //25
  {
    attributes: [
      {
        name: "Visión global y estratégica, sin enfocarse en los detalles operativos",
        value: "D"
      },
      {
        name: "Ser eflexivo y pausado",
        value: "S"
      },
      {
        name: "Ser extrovertido y alegre",
        value: "I"
      },
      {
        name: "Ser introvertido y perfeccionista",
        value: "C"
      }
    ]
  },
  //26
  {
    attributes: [
      {
        name: "Aprovechar los contactos",
        value: "I"
      },
      {
        name: "Analizar mucha información antes de asumir riesgos",
        value: "C"
      },
      {
        name: "Aprovechar oportunidades",
        value: "D"
      },
      {
        name: "Escuchar con empatía",
        value: "S"
      }
    ]
  },
  //27
  {
    attributes: [
      {
        name: "Ceder para conciliar posiciones",
        value: "S"
      },
      {
        name: "Ser firme en sus posiciones",
        value: "D"
      },
      {
        name: "Argumentar con hechos",
        value: "C"
      },
      {
        name: "Negociar para lograr acuerdos",
        value: "I"
      }
    ]
  },
  //27
  {
    attributes: [
      {
        name: "Enfocarse en los procesos y la calidad",
        value: "C"
      },
      {
        name: "Enfocarse en las relaciones interpersonales",
        value: "I"
      },
      {
        name: "Enfocarse en la armonía y estabilidad",
        value: "S"
      },
      {
        name: "Enfocarse en obtener resultados rápidos",
        value: "D"
      }
    ]
  }
];
