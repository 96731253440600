export const PRIMARY = "primary";
export const CONSCIOUS = "conscious";

export const MEMBERSHIP_STATUS = {
  ACTIVE: "Activa",
  INACTIVE: "Inactiva"
};

export const ASSESSMENT_STATUS = {
  SENT: "Enviado",
  SUBMITTED: "Aplicado"
};

export const MODEL_TYPES = {
  DISC: "DISC",
  PGC: "Diagnóstico de Convergencia",
  ONE_TO_ONE: "Liderazgo Uno a Uno",
  F1_TEAM: "Diagnóstico de Madurez de Equipo",
  SELF_LEADERSHIP: "Evaluación de Autoliderazgo",
  ROLE_DISC: "DISC del Cargo"
};

export const REPORT_TYPES = {
  EXTENDED_DISC: { label: "Ejecutivo", modelType: "DISC" },
  SIMPLIFIED_DISC: { label: "Liderazgo", modelType: "DISC" },
  STANDARD_PGC: { label: "Estándar", modelType: "PGC" },
  STANDARD_ONE_TO_ONE: { label: "Estándar", modelType: "ONE_TO_ONE" },
  STANDARD_F1_TEAM: { label: "Estándar", modelType: "F1_TEAM" },
  STANDARD_SELF_LEADERSHIP: { label: "Estándar", modelType: "SELF_LEADERSHIP" },
  STANDARD_ROLE_DISC: { label: "Estándar", modelType: "ROLE_DISC" }
};
