<template>
  <div>
    <div class="white pb-16 margin-top">
      <v-container fluid>
        <report-section-title>
          Proyecto de fortalecimiento de la marca personal
        </report-section-title>
      </v-container>

      <v-container fluid>
        <v-row no-gutters>
          <v-col no-gutters cols="0" md="1" />
          <v-col no-gutters cols="12" md="10" class="px-5 px-sm-5 px-md-0">
            <!-- INTRO -->
            <div>
              <p class="paragraph pb-10 no-breakable">
                Esta sección está diseñada para fortalecer tu marca personal. Si
                estás realizando coaching, mentoría o asesoría de marketing,
                comparte las reflexiones de este apartado, para que logres la
                expansión de tu marca personal de forma más ágil.
              </p>

              <plan-item class="pb-16 mb-16">
                1.- Reflexiona sobre tus resultados
              </plan-item>

              <plan-item :n-items="3">
                2.- Lea el perfil <strong>NATURAL</strong> y escribe aquí las
                tres frases que mejor te describen:
              </plan-item>

              <plan-item :n-items="3">
                3.- Repasa la <strong>RESPUESTA ADAPTATIVA</strong> y escribe
                aquí las tres frases que mejor te describen:
              </plan-item>

              <plan-item :n-items="3">
                4.- Menciona 3 aspectos de tu perfil <strong>NATURAL</strong>
                que te gustaría resaltar en tu marca personal:
              </plan-item>

              <plan-item :n-items="3">
                5.- Revisa las <strong>OPORTUNIDADES DE DESARROLLO</strong> y
                escribe aquí los tres aspectos que pueden estar afectando el
                desempeño de tu marca personal:
              </plan-item>

              <plan-item :n-items="3">
                6.- Revisa tu <strong>ESTILO DE COMUNICACIÓN</strong> y escoge 2
                fortalezas que prefieras utilizar en las estrategias de
                marketing:
              </plan-item>

              <plan-item no-breakable class="padding-top-48 mb-10">
                7.- ¿Cuáles son las características de tu cliente ideal?
                <br />

                <xs-rotation-message />

                <plan-table
                  title="Características de tu cliente ideal"
                  header-size="large"
                  :columns="idealCustomerColumns"
                  :data="idealCustomerData"
                />
              </plan-item>

              <plan-item :n-items="3">
                8.- Escribe al menos 3 referencias de marca personal de
                homólogos que te gusten mucho:
              </plan-item>

              <plan-item :n-items="3" class="padding-top-60">
                9.- Escribe al menos 3 referencias de marca personal de
                homólogos que no te gusten:
              </plan-item>

              <plan-table
                title="Marca con una X"
                :columns="markWithXColumns"
                :data="markWithXData"
              />

              <xs-rotation-message />

              <plan-item class="mt-16 padding-top-60">
                15.- Desarrolla un proyecto de fortalecimiento de tu marca
                personal tomando en cuenta las respuestas a las preguntas
                anteriores.
                <br />

                <xs-rotation-message />

                <plan-table
                  title="Proyecto de fortalecimiento de marca personal"
                  :columns="projectTableColumns"
                  :n-rows="3"
                />
              </plan-item>
            </div>
          </v-col>
          <v-col cols="0" md="1" />
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import PlanItem from "@/views/reports/common/disc/PlanItem";
import PlanTable from "@/views/reports/common/disc/PlanTable";

export default {
  name: "SectionBrandingPlan",
  components: {
    "plan-item": PlanItem,
    "plan-table": PlanTable
  },
  data() {
    return {
      idealCustomerColumns: [
        { label: "Persona", cols: 5.5 },
        { label: "Empresa", cols: 5.5 }
      ],
      // TODO: Improve with computed
      idealCustomerData: [
        [
          { value: "Sexo", cols: 2.5 },
          { cols: 2.5 },
          { value: "Tamaño", cols: 2.5 },
          { cols: 2.5 }
        ],
        [
          { value: "Rango de edad", cols: 2.5 },
          { cols: 2.5 },
          { value: "Ubicación", cols: 2.5 },
          { cols: 2.5 }
        ],
        [
          { value: "Ubicación", cols: 2.5 },
          { cols: 2.5 },
          { value: "Idioma", cols: 2.5 },
          { cols: 2.5 }
        ],
        [
          { value: "Idioma", cols: 2.5 },
          { cols: 2.5 },
          { value: "Departamento", cols: 2.5 },
          { cols: 2.5 }
        ],
        [
          { value: "Profesión", cols: 2.5 },
          { cols: 2.5 },
          { value: "Cargo", cols: 2.5 },
          { cols: 2.5 }
        ],
        [
          { value: "Otras", cols: 2.5 },
          { cols: 2.5 },
          { value: "Otras", cols: 2.5 },
          { cols: 2.5 }
        ],
        [{ cols: 2.5 }, { cols: 2.5 }, { cols: 2.5 }, { cols: 2.5 }],
        [{ cols: 2.5 }, { cols: 2.5 }, { cols: 2.5 }, { cols: 2.5 }],
        [{ cols: 2.5 }, { cols: 2.5 }, { cols: 2.5 }, { cols: 2.5 }]
      ],
      markWithXColumns: [
        { label: "", cols: 1.5 },
        { label: "10<br />RRSS de tus homólogos", cols: 1.5 },
        { label: "11<br />RRSS que prefieres", cols: 1.5 },
        { label: "12<br />RRSS de tus clientes", cols: 1.5 },
        { label: "13<br />Estrategia que te gusta como consumidor", cols: 1.5 },
        {
          label: "14<br />Estrategia que prefieres para ofrecer contenido",
          cols: 1.5
        }
      ],
      // TODO: Improve with computed
      markWithXData: [
        [
          { value: "Facebook", cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 }
        ],
        [
          { value: "Twitter", cols: 2.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 }
        ],
        [
          { value: "Instagram", cols: 2.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 }
        ],
        [
          { value: "TikTok", cols: 2.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 }
        ],
        [
          { value: "LinkedIn", cols: 2.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 }
        ],
        [
          { value: "Clubhouse", cols: 2.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 }
        ],
        [
          { value: "Otras", cols: 2.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 },
          { cols: 1.5 }
        ]
      ],
      projectTableColumns: [
        { label: "Aspecto", cols: 1.5 },
        { label: "Objetivo", cols: 1.5 },
        { label: "Acciones", cols: 1.5 },
        { label: "Evidencia de progreso", cols: 3 },
        { label: "Plazo", cols: 4 }
      ]
    };
  }
};
</script>

<style scoped>
.paragraph {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--v-primary-base);
  text-align: justify;
  margin-bottom: 1em;
}
@media print {
  .no-breakable {
    break-inside: avoid;
    page-break-inside: avoid;
  }
  .margin-top {
    margin-top: 5em;
  }
  .printMarginLarge {
    padding-top: 10em;
    margin-bottom: 0.5em;
  }
  .printMarginSmall {
    padding-top: 4em;
    margin-bottom: 0.5em;
  }
  .pagebreakBefore {
    break-before: always !important;
    page-break-before: always !important;
  }
  .padding-top-48 {
    padding-top: 48px;
  }
  .padding-top-60 {
    padding-top: 60px;
  }
  .padding-bottom {
    padding-bottom: 16px;
  }
}
</style>
