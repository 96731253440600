<template lang="html">
  <v-container>
    <v-row wrap>
      <v-col cols="3" class="hidden-sm-and-down" />
      <v-col cols="12" md="6">
        <v-card class="text-center">
          <v-card-title class="text-subtitle-2 secondary primary--text">
            {{ title }}
          </v-card-title>
          <v-card-text class="pt-4">
            <slot />
          </v-card-text>
          <v-row wrapc class="pt-2 pr-4">
            <v-col cols="12" class="text-right">
              {{ step + 1 }} de {{ steps }}
            </v-col>
          </v-row>
          <v-row wrap class="ma-0">
            <v-col cols="12">
              <v-progress-linear v-model="progress" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="3" class="hidden-sm-and-down" />
    </v-row>

    <v-row wrap>
      <v-col cols="3" class="hidden-sm-and-down pt-0" />
      <v-col cols="6" md="3" class="align-content-center pr-1 pt-0">
        <v-btn
          block
          class="primary--text"
          color="secondary"
          large
          @click="back()"
        >
          Atrás
        </v-btn>
      </v-col>
      <v-col cols="6" md="3" class="align-content-center pl-1 pt-0">
        <v-btn
          block
          class="secondary--text"
          color="primary"
          large
          @click="next()"
        >
          Siguiente
        </v-btn>
      </v-col>
      <v-col cols="3" class="hidden-sm-and-down pt-0" />
    </v-row>
  </v-container>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    step: {
      type: Number,
      required: true,
    },
    steps: {
      type: Number,
      required: true,
    }
  },
  methods: {
    back() {
      this.$emit("back");
    },
    next() {
      this.$emit("next");
    }
  },
  computed: {
    progress() {
      return (this.step / this.steps) * 100;
    }
  }
});
</script>
