<template>
  <div>
    <div class="pt-2">
      <v-row no-gutters>
        <v-col cols="0" md="1" />
        <v-col align="center" cols="12" md="10" class="px-5 px-sm-5 px-md-0">
          <report-section-title>
            Descriptores del Estilo Natural
          </report-section-title>

          <xs-rotation-message />

          <div class="d-none d-sm-block d-print-block">
            <p class="primary--text text-justify description">
              Este gráfico presenta los descriptores de cada factor DISC según
              los resultados del Estilo Natural. Los factores DISC que
              resultaron por encima del 50% en el ESTILO NATURAL están
              resaltados en colores desde la mitad de la barra hacia arriba y
              los que resultaron por debajo del 50% están resaltados en colores
              desde la mitad de la barra hacia abajo.
            </p>
            <v-row no-gutters>
              <v-col cols="1" />
              <v-col cols="5" align="center" class="category-header">
                Extroversión
              </v-col>
              <v-col cols="5" align="center" class="category-header">
                Introversión
              </v-col>
              <v-col cols="1" />
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="3.5"
                align="center"
                class="category-header category-border-bottom"
              >
                Tarea
              </v-col>
              <v-col
                cols="5"
                align="center"
                class="category-header category-border-bottom category-border-x"
              >
                Social
              </v-col>
              <v-col
                cols="3.5"
                align="center"
                class="category-header category-border-bottom"
              >
                Tarea
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="3"
                align="center"
                v-for="(_, factor) in factors"
                :key="factor"
              >
                <factor-badge :factor="factor" fullcolor bold />
              </v-col>
            </v-row>
            <div>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="background darken-1 font-weight-bold uppercase"
                >
                  Alto
                </v-col>
              </v-row>
            </div>

            <v-row no-gutters align="center" class="my-1">
              <v-col cols="3" v-for="(data, factor) in factors" :key="factor">
                <descriptors-bar
                  :upper-words="data.descriptors.up"
                  :lower-words="data.descriptors.down"
                  :value="profile ? profile.values[data.letter].percentage : 0"
                  :color="data.color"
                />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                cols="12"
                class="background darken-1 font-weight-bold uppercase"
              >
                Bajo
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-1">
              <v-col cols="3" v-for="(data, factor) in factors" :key="factor">
                <v-row class="mt-0">
                  <v-col cols="1.5" align-self="stretch" />
                  <v-col
                    cols="8"
                    align-self="stretch"
                    class="bottom-box py-0"
                    :class="`${data.backgroundStyle} ${data.textStyle}`"
                  >
                    <h5>{{ data.question }}</h5>
                  </v-col>
                  <v-col cols="1.5" align-self="stretch" />
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="0" md="1" />
      </v-row>
    </div>
  </div>
</template>

<script>
import factors from "@/model/disc/factors";

import FactorBadge from "@/views/reports/common/disc/FactorBadge.vue";
import DescriptorsBar from "@/views/reports/common/disc/DescriptorsBar.vue";

export default {
  name: "ExtroIntroVersionView",
  components: {
    "factor-badge": FactorBadge,
    "descriptors-bar": DescriptorsBar
  },
  props: {
    profile: {
      type: Object,
      required: false
    }
  },
  data() {
    return { factors };
  },
  created() {}
};
</script>

<style scoped>
.category-header {
  color: var(--v-primary-base);
  font-weight: 700;
  font-size: 1.5em;
  text-transform: uppercase;
}
.category-border-bottom {
  border-bottom-style: solid;
  border-bottom-color: var(--v-primary-base);
}
.category-border-x {
  border-right-style: solid;
  border-right-color: var(--v-primary-base);
  border-left-style: solid;
  border-left-color: var(--v-primary-base);
}
.description {
  padding-top: 1em;
}
.bottom-box {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: 500;
  font-size: 1em;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media print {
  .description {
    padding-top: 0.5em;
    line-height: 0.98;
  }
  .bottom-box {
    height: 4em;
    font-size: 0.8em;
  }
  .category-header {
    font-size: 1em;
  }
}
</style>
