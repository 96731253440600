<template>
  <o-card-form-layout
    title="Inicio de Sesión"
    :actions="actions"
    :loading="loading"
  >
    <v-container class="pb-0">
      <v-row>
        <v-col cols="12" class="py-0 px-1">
          <o-email-field v-model="user.email" />
        </v-col>
        <v-col cols="12" class="py-0 px-1">
          <o-password-field v-model="user.password" />
        </v-col>
        <v-col cols="12" class="py-1 px-1">
          <o-captcha
            sitekey="6Ldg9ZgeAAAAAJ-IlmoFp6gVUtjktWa13blJ_wMb"
            @verify="markRecaptchaAsVerified"
          >
          </o-captcha>
        </v-col>
      </v-row>
    </v-container>
  </o-card-form-layout>
</template>

<script>
import { defineComponent } from "vue";
import authMixin from "@/mixins/authMixin";
import messageDialogMixin from "@/mixins/messageDialogMixin";
import messages from "@/plugins/messages";

export default defineComponent({
  name: "SignIn",
  mixins: [authMixin, messageDialogMixin],
  data() {
    return {
      user: {
        email: "",
        password: ""
      },
      actions: [
        { type: "submit", label: "Continuar", handler: this.signIn },
        {
          type: "secondary-left",
          label: "¿Olvidó su contraseña?",
          to: "/forgot-password"
        },
        {
          type: "secondary-right",
          label: "Crear Cuenta",
          to: "/signup"
        }
      ],
      loading: false,
      recaptchaVerified: false
    };
  },
  methods: {
    async signIn(form) {
      if (this.recaptchaVerified) {
        if (form.validate()) {
          this.loading = true;
          try {
            await this.$store.dispatch("signUserIn", {
              email: this.user.email,
              password: this.user.password
            });

            this.$store.dispatch("initDashboard");
          } catch (error) {
            this.errorDialog(error.message);
          } finally {
            this.loading = false;
          }
        }
      } else {
        this.errorDialog(messages.validation.tickRecaptcha);
      }
    },
    markRecaptchaAsVerified() {
      this.recaptchaVerified = true;
    }
  }
});
</script>

<style scoped></style>
