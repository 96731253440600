<template>
  <v-select
    background-color="#ffffff"
    dense
    outlined
    :id="uid"
    :name="uid"
    :ref="uid"
    :clearable="clearable"
    :disabled="disabled"
    :hide-details="hideDetails"
    :item-text="itemText"
    :item-value="itemValue"
    :items="data"
    :label="label"
    :prepend-icon="icon || ''"
    :required="required"
    :rules="computedRules"
    :value="value"
    @change="onChange"
    @input="onInput"
  ></v-select>
</template>

<script>
import { defineComponent } from "vue";

import formInputField from "@/mixins/formInputField";
import validations from "@/plugins/validations";

export default defineComponent({
  mixins: [formInputField],
  props: {
    uid: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    itemText: {
      type: String,
      required: true
    },
    itemValue: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    value: String
  },
  computed: {
    computedRules() {
      const rules = [];
      if (this.required) {
        rules.push(validations.form.required_field);
      }
      if (this.rules) {
        rules.push(...this.rules);
      }
      return rules;
    }
  },
  methods: {
    onChange(event) {
      this.$emit("change", event);
    }
  }
});
</script>

<style></style>
