<template lang="html">
  <assessment-back-next-card-layout
    title="Seleccione la opción que más lo describa y la que menos lo describa"
    :step="step"
    :steps="steps"
    @next="next"
    @back="back"
  >
    <v-form lazy-validation>
      <v-row class="text-center">
        <v-col cols="6" class="text-h6 text-left pl-4 py-2">Atributos</v-col>
        <v-col cols="3" class="py-2">
          <v-icon>fas fa-plus-circle</v-icon>
        </v-col>
        <v-col cols="3" class="py-2">
          <v-icon>fas fa-minus-circle</v-icon>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col
          cols="12"
          v-for="(item, index) in questions[step].attributes"
          :key="index"
        >
          <v-row class="align-center">
            <v-col cols="6" class="subtitle-1 text-left pl-4 py-2">
              {{ item.name }}
            </v-col>

            <v-col xs3 class="text-center py-2">
              <v-btn
                icon
                small
                class="elevation-0"
                :disabled="answers[step]['dw'].index === index"
                @click="selectAttribute(index, 'up')"
              >
                <v-icon v-if="answers[step]['up'].index === index">
                  fas fa-circle
                </v-icon>
                <v-icon v-else>far fa-circle</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="3" class="text-center py-2">
              <v-btn
                icon
                small
                class="elevation-0"
                :disabled="answers[step]['up'].index === index"
                @click="selectAttribute(index, 'dw')"
              >
                <v-icon v-if="answers[step]['dw'].index === index">
                  fas fa-circle
                </v-icon>
                <v-icon v-else>far fa-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col cols="12" v-if="error" class="red--text text-center pt-3">
          {{ error }}
        </v-col>
      </v-row>
    </v-form>
  </assessment-back-next-card-layout>
</template>

<script>
import { defineComponent } from "vue";

import assessmentMixin from "@/mixins/assessmentMixin";
import assessmentModel from "@/model/assessment/assessment";

export default defineComponent({
  mixins: [assessmentMixin],
  data() {
    return {
      answers: null,
      error: false,
      questions: assessmentModel.questions
    };
  },
  created() {
    if (this.getAndValidateAssessment()) {
      this.answers = this.$store.getters.answers;
      if (!this.answers) {
        this.answers = [];
        for (let i = 0; i < this.steps; i++) {
          this.answers.push({
            up: { index: -1 },
            dw: { index: -1 }
          });
        }
      }
    }
  },
  computed: {
    step() {
      return this.$store.getters.currentStep;
    },
    steps() {
      return assessmentModel.questions.length;
    }
  },
  methods: {
    back() {
      this.error = false;
      if (this.step == 0) {
        this.$store.dispatch("setAnswers", this.answers);
        this.$router.push(this.assessmentFormURL);
      } else {
        this.$store.dispatch("back");
      }
    },
    isAttributeSelected(index, action) {
      return this.answers[this.step][action].index === index;
    },
    isValidSelection() {
      return (
        this.answers[this.step]["up"].index >= 0 &&
        this.answers[this.step]["dw"].index >= 0
      );
    },
    next() {
      if (this.isValidSelection()) {
        if (this.step === this.steps - 1) {
          this.$store.dispatch("setAnswers", this.answers);
          this.$router.push(this.assessmentSubmitURL);
        } else {
          this.$store.dispatch("next");
        }
      } else {
        this.error = "Debe seleccionar las opciones descritas en el enunciado";
      }
    },
    selectAttribute(index, action) {
      this.error = false;
      if (this.isAttributeSelected(index, action)) {
        this.answers[this.step][action].index = -1;
        this.answers[this.step][action].attribute = null;
      } else {
        this.answers[this.step][action].index = index;
        this.answers[this.step][action].attribute =
          this.questions[this.step].attributes[index];
      }
    }
  }
});
</script>

<style lang="css" scoped>
.theme--light.v-sheet {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-icon {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 0.87);
}
</style>
