<template>
  <div>
    <report-section-title>
      Niveles de madurez de un equipo
    </report-section-title>
    <div><h2>MODELO LIDERLAB</h2></div>
    <div class="level-line">
      <div class="line-container"></div>
      <div
        v-for="level in levels"
        :key="level.key"
        class="level-circle-container"
      >
        <div class="circle-label-container">
          <div
            class="level-circle"
            :class="{ active: selectedLevel === level.level }"
          >
            {{ level.level }}
          </div>
          <span class="level-label">{{ level.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import levels from "@/model/fone-team/levels.js";

export default {
  props: {
    applicantCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      levels: [],
      selectedLevel: null
    };
  },
  created() {
    this.levels = levels;
    this.selectedLevel = this.getLevel(this.applicantCount).level;
  },
  methods: {
    getLevel(applicantCount) {
      return levels.find(level => {
        return (
          applicantCount >= level.range.min && applicantCount <= level.range.max
        );
      });
    }
  }
};
</script>

<style scoped>
.level-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.level-circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.level-circle {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 1rem;
  font-weight: bold;
}

.level-circle.active {
  background-color: lightblue;
  width: 100px;
  height: 100px;
}

.level-label {
  font-size: 1rem;
  margin-top: 5px;
  font-weight: bold;
}

.line-container {
  position: absolute;
  top: 40%;
  left: auto;
  right: auto;
  width: 92%;
  height: 4px;
  background-color: #000000;
  z-index: 0;
}

h2 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
