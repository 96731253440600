<template>
  <div>
    <table>
      <thead>
        <tr>
          <th colspan="2">Nivel de desarrollo del Liderazgo 1 A 1</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(section, index) in sections" :key="index">
          <td style="width: 50%">{{ section.label }}</td>
          <td>{{ getFeedback(section.key, section.score) }}</td>
        </tr>
        <tr>
          <td style="width: 50%">Resultados Integrales</td>
          <td>{{ getFeedback("integral", integralScore) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  jobFeedback,
  motivationFeedback,
  relationshipFeedback,
  integralFeedback
} from "@/model/one-to-one/feedbacks.js";

export default {
  name: "LeadershipDevelopmentTable",
  props: {
    assessedCounts: {
      type: Object,
      required: true
    }
  },
  computed: {
    sections() {
      return [
        {
          key: "job",
          label: "En relación con las Tareas",
          score: this.assessedCounts.job
        },
        {
          key: "motivation",
          label: "En relación con la Motivación",
          score: this.assessedCounts.motivation
        },
        {
          key: "relationship",
          label: "En relación con las Relaciones",
          score: this.assessedCounts.relationships
        }
      ];
    },
    integralScore() {
      return (
        this.assessedCounts.job +
        this.assessedCounts.motivation +
        this.assessedCounts.relationships
      );
    }
  },
  methods: {
    getFeedback(sectionKey, score) {
      let feedback = "";

      switch (sectionKey) {
        case "job":
          feedback = this.getSectionFeedback(score, jobFeedback);
          break;
        case "motivation":
          feedback = this.getSectionFeedback(score, motivationFeedback);
          break;
        case "relationship":
          feedback = this.getSectionFeedback(score, relationshipFeedback);
          break;
        case "integral":
          feedback = this.getSectionFeedback(score, integralFeedback);
          break;
      }

      return feedback;
    },
    getSectionFeedback(score, feedbacks) {
      let selectedFeedback = "";

      for (const feedback of feedbacks) {
        if (score >= feedback.range.min && score <= feedback.range.max) {
          selectedFeedback = feedback.label;
          break;
        }
      }

      return selectedFeedback;
    }
  }
};
</script>

<style scoped>
table {
  width: 90%;
  margin: auto;
  border-collapse: collapse;
}

h1 {
  width: 90%;
  margin: auto;
  padding: 10px;
  border: 1px solid #dddddd;
  text-align: center;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  text-align: center;
  background-color: #f2f2f2;
}

.table-container {
  margin-bottom: 20px;
}

div {
  list-style-type: none;
  text-align: justify;
}
</style>
