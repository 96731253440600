export default {
  1.1: [
    "Visión global, capacidad para integrar las partes en un todo.",
    "Orientación al futuro.",
    "Agilidad en la obtención de resultados y soluciones creativas a problemas complejos."
  ],
  1.2: [
    "Visión global, capacidad para integrar las partes en un todo.",
    "Agilidad en la obtención de resultados y soluciones creativas a problemas complejos."
  ],
  2.1: [
    "Facilidad para relacionarse y conectar con personas claves.",
    "Habilidad para promover ideas, Influenciar y motivar a las personas.",
    "Optimismo, entusiasmo y buen sentido del humor."
  ],
  2.2: [
    "Facilidad para relacionarse y conectar con personas claves.",
    "Habilidad para promover ideas, Influenciar y motivar a las personas.",
    "Optimismo, entusiasmo y buen sentido del humor."
  ],
  3.1: [
    "Actitud conciliadora frente a los conflictos.",
    "Habilidad para trabajar en equipo.",
    "Equilibrio interno.",
    "Formalidad y estabilidad."
  ],
  3.2: [
    "Actitud conciliadora frente a los conflictos.",
    "Habilidad para trabajar en equipo.",
    "Equilibrio interno.",
    "Lealtad."
  ],
  4.1: [
    "Análisis e investigación de hechos antes de decidir.",
    "Objetividad y precisión en el manejo de la información.",
    "Cumplimiento de estándares de calidad.",
    "Capacidad para hacer bien las tareas difíciles desde el primer intento."
  ],
  4.2: [
    "Análisis e investigación de hechos antes de decidir.",
    "Objetividad y precisión en el manejo de la información.",
    "Cumplimiento de estándares de calidad.",
    "Capacidad para hacer bien las tareas difíciles desde el primer intento."
  ],
  5: [
    "Gestión de las crisis y los cambios con sentido de urgencia.",
    "Capacidad para resolver problemas logrando la colaboración de forma persuasiva.",
    "Creatividad e innovación.",
    "Autoconfianza."
  ],
  6: [
    "Creatividad e innovación.",
    "Capacidad para resolver problemas logrando la colaboración de forma persuasiva.",
    "Optimismo, autoconfianza y dinamismo.",
    "Facilidad para lograr resultados rápidos."
  ],
  7: [
    "Capacidad para relajar ambientes tensos.",
    "Capacidad para lograr resultados a través de las personas.",
    "Habilidad para crear un ambiente amable y entusiasta.",
    "Creatividad e innovación."
  ],
  8: [
    "Amplio alcance de funciones.",
    "Capacidad para solucionar problemas complejos.",
    "Automotivación, con iniciativa y sentido de equipo.",
    "Enfoque en resultados."
  ],
  9: [
    "Enfoque en obtener resultados, a través de la participación del equipo.",
    "Capacidad para enfrentar de forma creativa y metódica las situaciones complejas.",
    "Innovación, curiosidad y reflexión.",
    "Sentido de responsabilidad."
  ],
  10: [
    "Trabajo en equipo para resolver problemas complejos.",
    "Habilidad para tratar un problema y persistir hasta solucionarlo.",
    "Capacidad para integrar a las personas, alrededor de una meta compartida.",
    "Reflexión y sentido de responsabilidad."
  ],
  11: [
    "Enfoque en resultados cuidando de la calidad.",
    "Sentido de urgencia en el logro de los objetivos.",
    "Destreza para administrar adecuadamente el tiempo.",
    "Capacidad para tomar decisiones en un clima de incertidumbre."
  ],
  12: [
    "Facilidad para enfocarse en la tarea de una forma creativa y analítica.",
    "Capaz de tomar decisiones en un clima de incertidumbre.",
    "Visión de futuro de forma objetiva."
  ],
  13: [
    "Innovación en los procedimientos para alcanzar mayores niveles de calidad.",
    "Integra las teorías y los sistemas probados para diseñar soluciones más efectivas.",
    "Concentra la atención en encontrar las respuestas correctas."
  ],
  14: [
    "Capacidad para llevarse bien con las personas.",
    "Carisma y buen sentido del humor.",
    "Optimismo y entusiasmo.",
    "Integra a las personas y trabaja en equipo."
  ],
  15: [
    "Capacidad para llevarse bien con las personas e integrarse al equipo.",
    "Facilidad para negociar los conflictos entre las personas buscando que todos ganen.",
    "Sinceridad.",
    "Comprensión."
  ],
  16: [
    "Capacidad para concentrarse en los detalles.",
    "Amabilidad, sinceridad y reflexión.",
    "Facilidad para integrarse al equipo y llevarse bien con los demás.",
    "Calidez humana y comprensión."
  ],
  17: [
    "Facilidad para influenciar a los demás para alcanzar resultados de calidad.",
    "Capacidad para motivar al equipo a cumplir los protocolos.",
    "Capacidad para gestionar con tacto las situaciones complejas.",
    "Capacidad para impulsar eficazmente las ideas."
  ],
  18: [
    "Entusiasmo y optimismo sincero.",
    "Habilidad para motivar el cumplimiento de los procedimientos.",
    "Diplomacia y positivismo para relacionarse."
  ],
  19: [
    "Capacidad para promover sistemas de calidad.",
    "Optimismo realista.",
    "Decisiones respaldadas por análisis y pensamiento crítico, manteniendo un buen clima."
  ],
  20: [
    "Cuidado por los detalles.",
    "Capacidad para seguir sistemas y métodos probados.",
    "Lógica, estabilidad y persistencia.",
    "Cuidado del orden y la limpieza."
  ],
  21: [
    "Cumplimiento de los estándares de calidad.",
    "Capacidad para seguir sistemas y métodos probados.",
    "Cuidado del orden y de la limpieza.",
    "Capacidad para trabajar en equipos especializados."
  ],
  22: [
    "Facilidad para adaptarse a los métodos y procesos exitosos.",
    "Cautela para evaluar las posibles consecuencias de las decisiones y cambios, sobre todo si afectan las relaciones estables o las técnicas y protocolos establecidos."
  ],
  23: [
    "Facilidad para lograr resultados manteniendo al equipo motivado.",
    "Resolución de problemas a través de las personas, en un clima de entusiasmo.",
    "Capacidad para integrarse a un proyecto, con éxito, en cualquiera de sus fases."
  ],
  24: [
    "Facilidad para influir en las personas para el logro de resultados.",
    "Autoconfianza.",
    "Habilidad para mantener al equipo cohesionado.",
    "Capacidad resolutiva."
  ],
  25: [
    "Habilidad para adaptarse al ritmo del entorno para lograr resultados.",
    "Capacidad para asumir retos junto al equipo, con el foco en el logro de los objetivos.",
    "Generosidad activa.",
    "Creatividad frente a desafíos complejos."
  ],
  26: [
    "Habilidad y creatividad para solucionar problemas utilizando sistemas exitosos.",
    "Autoconfianza.",
    "Seguridad personal y gentileza."
  ],
  27: [
    "Habilidad para innovar manteniendo los estándares de calidad.",
    "Autoconfianza y dinamismo.",
    "Facilidad para alcanzar resultados y tomar decisiones con sentido de urgencia.",
    "Capacidad para crear un buen clima entre las personas con foco en los resultados."
  ],
  28: [
    "Habilidad para innovar manteniendo los estándares de calidad y el entusiasmo del equipo.",
    "Capacidad para alcanzar resultados y analizar rápidamente opciones creativas frente a un problema.",
    "Capaz de reflexionar con sentido de urgencia antes de actuar."
  ],
  29: [
    "Habilidad para enfocar los esfuerzos en aumentar el rendimiento y la calidad de forma sostenida y amable.",
    "Capacidad para asumir riesgos bien pensados.",
    "Facilidad para analizar muchas opciones frente a un problema y lograr resultados."
  ],
  30: [
    "Facilidad para promover e implementar cambios innovadores y de calidad en un clima dinámico y reflexivo.",
    "Habilidad para enfocar los esfuerzos en aumentar el rendimiento y la calidad de forma sostenida y amable."
  ],
  31: [
    "Capacidad para asumir retos, manteniendo el foco en la obtención de resultados, con buen sentido de equipo.",
    "Adaptación al entorno en pro de los objetivos.",
    "Capacidad para innovar los sistemas establecidos.",
    "Trabajo colaborativo para agilizar los resultados."
  ],
  32: [
    "Facilidad para innovar los sistemas establecidos.",
    "Trabajo colaborativo para agilizar los resultados.",
    "Capacidad para coordinar que se mantenga la calidad y lograr resultados."
  ],
  33: [
    "Capacidad para obtener resultados de calidad trabajando en equipo.",
    "Facilidad para innovar en los sistemas y en los métodos para lograr mejores resultados.",
    "Sensibilidad humana con sentido práctico.",
    "Capacidad para trabajar duro en múltiples asignaciones."
  ],
  34: [
    "Habilidad para alcanzar resultados rápidos, coordinando el cuidado de la calidad.",
    "Capacidad para innovar en los sistemas y en los métodos para lograr mejores resultados.",
    "Disciplina en la implementación de las estrategias."
  ],
  35: [
    "Facilidad para relacionarse con muchas personas de una forma dinámica y amable.",
    "Habilidad para motivar al equipo, para el logro de resultados.",
    "Capacidad para convencer y aportar ideas.",
    "Capacidad para resolver conflictos entre las personas."
  ],
  36: [
    "Facilidad para conocer nuevas personas y mantener relaciones cercanas.",
    "Actitud colaborativa y de apoyo con los demás.",
    "Actitud considerada, firme y amable.",
    "Capacidad para convencer y aportar ideas."
  ],
  37: [
    "Capacidad para resolver problemas y lograr resultados a través de las personas.",
    "Facilidad para superar obstáculos.",
    "Autoconfianza, amabilidad y optimismo.",
    "Capacidad para convencer y aportar ideas."
  ],
  38: [
    "Facilidad para generar un buen clima al delegar tareas.",
    "Amabilidad y optimismo.",
    "Capacidad para motivar, integrar e influenciar.",
    "Habilidad para relacionarse y conocer gente."
  ],
  39: [
    "Capacidad para obtener resultados de calidad a través de las personas.",
    "Seguridad personal al ejercer el liderazgo.",
    "Facilidad para integrarse al equipo de forma activa."
  ],
  40: [
    "Destreza para impulsar proyectos de envergadura y manejar procesos complejos.",
    "Habilidad para tomar decisiones rápidas respaldadas en análisis de hechos e indicadores.",
    "Buen manejo del humor con gentileza y diplomacia."
  ],
  41: [
    "Capacidad para motivar al equipo a lograr resultados rápidos cuidando de la calidad.",
    "Destreza para impulsar proyectos de envergadura y manejar procesos complejos.",
    "Optimismo y diplomacia enfocado en el logro de la meta."
  ],
  42: [
    "Destreza para llevar las ideas a la práctica.",
    "Capacidad para impulsar proyectos de envergadura y manejar procesos complejos.",
    "Habilidad para motivar a las personas a seguir los protocolos y cumplir los estándares de calidad.",
    "Capacidad para promover a las personas."
  ],
  43: [
    "Facilidad para mantener redes de contactos y relaciones de forma metódica.",
    "Capacidad para formar a los demás y motivar el logro de los estándares de calidad.",
    "Actitud profesional que influye positivamente en los demás."
  ],
  44: [
    "Facilidad para mantener redes de contactos y relaciones.",
    "Habilidad para conformar equipo y lograr que funcione de forma muy organizada.",
    "Paciencia para entrenar y asesorar a los demás.",
    "Capacidad para cuidar, de forma positiva, de la calidad."
  ],
  45: [
    "Facilidad de integración con las personas.",
    "Paciencia para entrenar y orientar a los demás.",
    "Destreza para mantener redes de contactos y trabajar en equipo.",
    "Competencia para dar soporte y apoyo al equipo en el esfuerzo por alcanzar la meta."
  ],
  46: [
    "Paciencia para escuchar a los demás.",
    "Destreza para adaptarse a diversas situaciones.",
    "Capacidad para tener resultados a través de la gente de forma metódica.",
    "Habilidad para influenciar a los demás con diplomacia."
  ],
  47: [
    "Habilidad para asumir responsabilidades con determinación y persistencia.",
    "Capacidad para impulsar procesos de negociación, escuchando los diferentes puntos de vista.",
    "Escucha para comprender.",
    "Logra resultados a través de la consistencia."
  ],
  48: [
    "Capacidad para impulsar procesos de negociación, escuchando los diferentes puntos de vista.",
    "Facilidad para sostener los esfuerzos del equipo, con sentido de innovación.",
    "Actitud equilibrada y servicial.",
    "Estabilidad y dinamismo."
  ],
  49: [
    "Capacidad para negociar los conflictos entre las personas buscando resultados ganar - ganar.",
    "Calidez humana.",
    "Facilidad para motivar y sostener los esfuerzos del equipo.",
    "Estabilidad y dinamismo."
  ],
  50: [
    "Calidez humana.",
    "Capacidad para negociar los conflictos entre las personas buscando resultados ganar - ganar.",
    "Estabilidad, equilibrio y dinamismo.",
    "Facilidad para motivar y sostener los esfuerzos del equipo."
  ],
  51: [
    "Facilidad para integrarse con los demás y llevarse bien con la gente.",
    "Calidez Humana.",
    "Capacidad para conciliar en los conflictos entre las personas.",
    "Habilidad natural para construir relaciones positivas."
  ],
  52: [
    "Facilidad para brindar apoyo en cualquier situación, siendo amigable y optimista.",
    "Capacidad para hacer bien las cosas y ser complaciente con los demás.",
    "Coherencia.",
    "Paciencia."
  ],
  53: [
    "Capacidad para hacer bien las cosas, complacer y ser paciente con los demás.",
    "Habilidad para utilizar un juicio equilibrado aportando estabilidad al ambiente.",
    "Facilidad para brindar apoyo en cualquier situación, siendo amigable y optimista.",
    "Coherencia."
  ],
  54: [
    "Facilidad para brindar apoyo en cualquier situación, siendo amigable y optimista.",
    "Coherencia.",
    "Capacidad para hacer bien las cosas, complacer y ser paciente con los demás.",
    "Habilidad para utilizar un juicio equilibrado aportando estabilidad al ambiente."
  ],
  55: [
    "Capacidad de autogestión.",
    "Facilidad para enfrentar problemas y trabajar en él hasta solucionarlos.",
    "Capacidad para enfrentar responsabilidades y retos de forma metódica y sistemática."
  ],
  56: [
    "Capacidad para enfrentar responsabilidades y retos de forma metódica y sistemática.",
    "Facilidad para enfrentar problemas y trabajar en ellos hasta solucionarlos.",
    "Capacidad de autogestión."
  ],
  57: [
    "Facilidad para cumplir los estándares de calidad en la obtención de resultados.",
    "Capacidad para enfrentar responsabilidades y retos de forma metódica y sistemática.",
    "Habilidad para promover la investigación en la toma de decisiones."
  ],
  58: [
    "Capacidad para enfrentar responsabilidades y retos de forma metódica y sistemática.",
    "Persistencia en el seguimiento de los protocolos establecidos.",
    "Meticulosidad.",
    "Análisis en la toma de decisiones."
  ],
  59: [
    "Habilidad para alcanzar resultados de calidad e innovar formas para elevar los estándares.",
    "Persistencia para encontrar la mejor solución a los problemas, con dinamismo.",
    "Capacidad para retar con entusiasmo a los demás, para lograr los objetivos."
  ],
  60: [
    "Habilidad para obtener resultados de calidad a través de la gente.",
    "Capacidad para asumir proyectos y metas complejas.",
    "Equilibrio y sensatez.",
    "Tacto, diplomacia y dinamismo."
  ],
  61: [
    "Habilidad para obtener resultados a través de la gente.",
    "Persistencia para encontrar la mejor solución a los problemas, con entusiasmo.",
    "Equilibrio y sensatez.",
    "Optimismo realista."
  ],
  62: [
    "Habilidad para obtener resultados a través de la gente.",
    "Equilibrio y sensatez.",
    "Optimismo realista.",
    "Combinación de habilidades sociales con motivación al logro."
  ],
  63: [
    "Habilidad para promover sistemas de calidad de forma amable.",
    "Capacidad para realizar tareas con altos estándares de calidad a un ritmo moderado.",
    "Equilibrio y sensatez.",
    "Optimismo realista."
  ],
  64: [
    "Facilidad para promover sistemas de calidad de forma amable.",
    "Capacidad para realizar tareas con altos estándares de calidad a un ritmo moderado.",
    "Equilibrio y estabilidad.",
    "Optimismo realista."
  ],
  65: [
    "Capacidad para motivar el cumplimiento de los procedimientos y el cuidado de los detalles.",
    "Facilidad para promover sistemas de calidad de forma amable.",
    "Optimismo realista.",
    "Colaboración."
  ],
  66: [
    "Precisión y exactitud.",
    "Sensibilidad por las necesidades de las personas.",
    "Análisis, objetividad y reflexión.",
    "Cuidado de los detalles y de la calidad."
  ],
  67: [
    "Capacidad para impulsar el cambio, luego de haber calculado los riesgos.",
    "Habilidad para obtener resultados de calidad, de forma ágil, metódica y reflexiva.",
    "Capacidad para innovar en protocolos más eficientes, productivos o que ofrezcan más calidad."
  ],
  68: [
    "Habilidad para obtener resultados de calidad, de forma ágil y reflexiva.",
    "Capacidad para impulsar el cambio de forma metódica, luego de haber calculado los riesgos.",
    "Facilidad para diseñar nuevas formas de afrontar los problemas o las tareas."
  ],
  69: [
    "Capacidad para innovar en protocolos más eficientes, productivos o que ofrezcan más calidad.",
    "Facilidad para impulsar el cambio, luego de haber calculado los riesgos.",
    "Habilidad para obtener resultados de calidad, de forma ágil, metódica y reflexiva."
  ],
  70: [
    "Habilidad para promover la investigación en la toma de decisiones.",
    "Análisis e intuición para afrontar situaciones complejas.",
    "Facilidad para recabar información relevante haciendo las preguntas adecuadas."
  ],
  71: [
    "Habilidad para llegar al final de los objetivos tomando en cuenta las necesidades de las personas.",
    "Capacidad para trabajar en equipo de forma activa.",
    "Facilidad para conocer nuevas personas."
  ],
  72: [
    "Habilidad para obtener resultados de calidad a través de la gente.",
    "Capacidad para impulsar a los demás para que sigan las normas y los procesos establecidos.",
    "Inclinación para actuar de forma reflexiva para llegar a soluciones viables."
  ],
  73: [
    "Habilidad para mezclar la creatividad y la lógica para encontrar soluciones.",
    "Capacidad para asumir nuevos retos, analizando información y diseñando opciones.",
    "Capacidad para trabajar duro para conseguir resultados de calidad, rápidamente."
  ],
  74: [
    "Habilidad para seguir normas y procedimientos con meticulosidad, manteniendo un clima amable.",
    "Capacidad para hacer bien las cosas, complacer y ser paciente con los demás."
  ]
};
