export const sumArray = arr => {
  return arr.reduce((prev, curr) => prev + curr, 0);
};

export const createEmptyArray = (rows, cols) => {
  const array = [];

  for (let i = 0; i < rows; i++) {
    array[i] = new Array(cols).fill(0);
  }

  return array;
};
