<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-transition"
    :max-width="width"
    @keydown.esc="close()"
  >
    <v-card>
      <v-container>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12">
              <v-autocomplete
                v-model="assessmentsUserSelected"
                :items="assessmentsUser"
                outlined
                dense
                chips
                small-chips
                label="Seleccionar los aplicantes"
                multiple
                item-text="applicantName"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="secondary--text"
          color="primary"
          v-if="!actions"
          @click="close()"
        >
          Cerrar
        </v-btn>
        <template v-if="!!actions">
          <v-btn
            class="secondary--text"
            color="primary"
            v-for="(action, index) in actions"
            :key="index"
            @click="exec(action.handler)"
          >
            {{ action.label }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "messageDialogTeam",
  props: {},
  data() {
    return {
      closeHandler: null,
      show: false,
      type: "success",
      width: "450",
      /**
       * Attributes
       *  - label
       *  - handler
       */
      actions: null,
      assessmentsUser: null,
      assessmentsUserSelected: null
    };
  },

  methods: {
    open(opts) {
      if (opts.width) {
        this.width = opts.width;
      }
      this.closeHandler = opts.closeHandler;
      this.actions = opts.actions;
      this.assessmentsUser = opts.assessmentsUser;
      this.assessmentsUserSelected = opts.assessmentsUserSelected;
      this.show = true;
      this.$emit("open");
    },
    close() {
      this.show = false;
      if (this.closeHandler && this.closeHandler instanceof Function) {
        this.closeHandler();
        this.closeHandler = null;
      }
      this.$emit("close");
    },
    exec(handler) {
      if (handler) {
        handler(this.assessmentsUserSelected);
      }
      this.close();
    }
  }
});
</script>
