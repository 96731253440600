<template>
  <v-card
    class="pb-0"
    :class="`${color} ${classname}`"
    :max-width="maxWidth"
    :min-height="minHeight"
  >
    <div>
      <v-card-title class="subtitle-1 font-weight-black" v-if="!!title">
        {{ title }}
      </v-card-title>

      <v-card-subtitle
        class="subtitle-2 font-weight-medium font-italic"
        v-if="!!subtitle"
      >
        {{ subtitle }}
      </v-card-subtitle>

      <v-card-text>
        <slot />
      </v-card-text>
    </div>

    <v-card-actions class="align-end">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false,
      default: "secondary"
    },
    maxWidth: {
      type: String,
      required: false
    },
    minHeight: {
      type: String,
      required: false
    },
    classname: {
      type: String,
      required: false,
      default: ""
    }
  }
});
</script>

<style>
.v-card__title {
  text-align: center !important;
  text-transform: uppercase !important;
  display: block;
}
.v-card__subtitle {
  text-align: center !important;
  text-transform: uppercase !important;
  display: block;
}
</style>
