<template>
  <v-row no-gutters align="center" align-content="center" justify="start">
    <v-col align="center" class="flex-grow-0" v-if="image">
      <report-icon color="var(--v-secondary-base)" :image="image" />
    </v-col>
    <v-col class="pl-3" align="left">
      <div class="primary--text text">
        {{ text }}
      </div>
      <div class="font-weight-light mr-1 subtext" v-if="!!subtext">
        {{ subtext }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReportSectionSubtitle",
  props: {
    text: {
      type: String,
      required: true
    },
    subtext: {
      type: String,
      required: false
    },
    image: {
      type: String,
      required: false
    }
  }
});
</script>

<style scoped>
.text {
  font-size: 1.4em;
  font-weight: 600;
  text-transform: uppercase;
}
.subtext {
  font-size: 1em;
  background: linear-gradient(
    150deg,
    var(--v-primary-base) 0%,
    var(--v-primary-lighten3) 100%
  );
  color: #fff;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  text-align: left;
  padding: 0 2em 0 0.5em;
  display: inline-block;
}

@media print {
  .text {
    font-size: 1.1em;
  }
}
</style>
